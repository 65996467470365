import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMinMaxBarrier]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxBarrierDirective, multi: true }]
})
export class MinMaxBarrierDirective {

  constructor() { }
  @Input('appMinMaxBarrier') minMaxValue!: { min: number, max: number };

  validate(control: AbstractControl): { [key: string]: any } | null {
    const value = parseFloat(control.value);
    if (isNaN(value) || value < this.minMaxValue.min || value > this.minMaxValue.max) {
      return { 'minMaxValue': true };
    }
    return null;
  }

}
