import { Component, ElementRef } from '@angular/core';
import { DashboardService } from '../../../services/DashboardService/dashboard-service.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrl: './index.component.css',
})
export class IndexComponent {
  spotRateList!: Observable<any>;
  dataLoaded = false;

  constructor(private dashboardService: DashboardService,private el:ElementRef) {}

  ngOnInit() {
    this.getSpotRatesListForClient();
  }

  getSpotRatesListForClient() {
    this.dataLoaded = false;
    this.dashboardService.getSpotRateList().subscribe({
      next: (data) => {
        
        this.spotRateList = of(data);
        this.dataLoaded = true;
        
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  changeSize(event:any){
    if(event){
      const parentElement = this.el.nativeElement.querySelector('.row');
      const element=this.el.nativeElement.querySelector('#dvRateCharts');

      const element2 = this.el.nativeElement.querySelector('#dvSpotRates');
      parentElement.insertBefore(element,element2);
      
      element.classList.remove('col-xl-6');
      element.classList.add('col-xl-12');

      



    }
    else{
      const parentElement = this.el.nativeElement.querySelector('.row');
      const element=this.el.nativeElement.querySelector('#dvRateCharts');
      const element2 = this.el.nativeElement.querySelector('#dvSpotRates');
      parentElement.insertBefore(element2,element);
      element.classList.remove('col-xl-12');
      element.classList.add('col-xl-6');

    }
  }
}
