import { Component, OnInit } from '@angular/core';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { FieldConstant, SystemConstant } from '../../../global/global.constant';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-common-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.css'
})
export class ReportsComponent implements OnInit {

  isPosition_Summary_Report: boolean = false;
  isDelta_Ladder: boolean = false;
  isFactsheets: boolean = false;
  isConfirmation_Reports: boolean = false;
  isSales_Revenue_Dash: boolean = false;
  isSales_NGP_Org_Dashboard: boolean = false;
  isSettlements_Report: boolean = false;
  isAdmin: boolean = true;

  constructor (
    private encryptPermission: EncryptRolePermissionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  
  ngOnInit(): void {
    this.checkUser();
  }

  private checkUser = (): void => {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user == SystemConstant.Admin) {
      this.isAdmin = true;
      this.checkAdminFieldPermission();
    } else {
      this.isAdmin = false;
      this.checkClientFieldPermission();
    }
  }

  private checkClientFieldPermission = (): void => {
    const clientRoleData = this.encryptPermission.getDecryptedPermissions('crole')?.modulePermissions;
    if (clientRoleData) {
      //this.isPosition_Summary_Report = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Position_Summary_Report).canView;
      //this.isDelta_Ladder = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Delta_Ladder).canView;
      //this.isFactsheets = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Factsheets).canView;
      const confirmationReportData = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Confirmation_Reports);
      this.isConfirmation_Reports = confirmationReportData ? confirmationReportData.canView : false;
      
      //this.isSales_NGP_Org_Dashboard = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Sales_NGP_Org_Dashboard).canView;
      //this.isSettlements_Report = clientRoleData.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Settlements_Report).canView;
    }
  }

  private checkAdminFieldPermission = (): void => {
    const fieldPermission = this.encryptPermission.getDecryptedPermissions('roleNgp').fieldPermissions;
    if (fieldPermission) {
      this.isPosition_Summary_Report = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Position_Summary_Report).canView;
      this.isDelta_Ladder = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Delta_Ladder).canView;
      this.isFactsheets = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Factsheets).canView;
      this.isConfirmation_Reports = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Confirmation_Reports).canView;
      this.isSales_NGP_Org_Dashboard = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Sales_NGP_Org_Dashboard).canView;
      this.isSettlements_Report = fieldPermission.find((x: { fieldID: number; }) => x.fieldID == FieldConstant.Settlements_Report).canView;
    }
  }

  goToConfirmationReportPage = (): void => {
    if (this.isAdmin) {
      this.router.navigate(["Admin/confirmation-reports"]);
    } else {
      this.router.navigate(["Client/confirmation-reports"]);
    }
  }

}
