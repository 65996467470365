import { Component, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OpenDealSFService } from '../../services/openDealSF/open-deal-sf.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SweetalertService } from '../../../services/SweetAlert/sweetalert.service';
import swal from 'sweetalert2';
import { PageEvent } from '@angular/material/paginator';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { ErrorMessages, SystemConstant } from '../../../global/global.constant';

@Component({
  selector: 'app-open-deal-sf',
  templateUrl: './open-deal-sf.component.html',
  styleUrl: './open-deal-sf.component.css'
})
export class OpenDealSFComponent {
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  paginatedData:any[]=[];
  pageEvent: PageEvent | undefined;  
  beneficiaryID!:Number;
  paymanetReference!: String;
  loadingMessage="Loading";
  deliverabilityType:string='deliverabilityType';
  accountType:String='Beneficiary';
  beneficiaryFundingAccountID!:Number;
  internalFundingAccountTypeID!:Number;
  dataLoaded:boolean=true;
  tenentCarPartners:any[]=[];
  clients:any[]=[];
  createdby:any[]=[];
  currencyPairs :any[]=[];
  isAdmin: boolean=true;
  selectedTenant: any;
  selectedClient: any;
  selectedCreatedBy:number=0;
  noRecordFound:boolean=false;
  viewdTradeID: number=0;
  pdfSrc: SafeResourceUrl=[];
  DealDetailData: any = {};
  errormsg: any;
  constructor(
    private fb: FormBuilder,
    private openDealService:OpenDealSFService,    
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private alert:SweetalertService,
    private encryptPermission: EncryptRolePermissionService,
    private renderer: Renderer2

  ) {}

  ngOnInit() {
    this.openDealForm.reset();
    const user=this.route.snapshot.pathFromRoot[1].url[0].path;
    if(user===SystemConstant.Admin){
      this.isAdmin=true;
    }
    else if(user===SystemConstant.Client){
      this.isAdmin=false;
      this.search();
    }
    this.getTenantList();
  }
  openDealForm: FormGroup = this.fb.group({
    tenentCarPartner: [0],
    clients:['', [Validators.required]],
    createdby:[0]
   
  });
  getTenantList() {
    if(this.isAdmin){
      this.dataLoaded=false;
    this.openDealService.getTenantCarList().subscribe({
      next: (data: any) => {
       this.tenentCarPartners=data;       
       const userResponse=this.encryptPermission.getDecryptedPermissions('userResponse');
       if(userResponse){
         let tenantCarname=this.tenentCarPartners.find(
           (t) => t.name == userResponse.tenantCARPartnerName
         );       
         if(tenantCarname){
         this.openDealForm.get('tenentCarPartner')?.setValue(tenantCarname.name);
         this.getClients()
         }
        }
       this.dataLoaded=true;
      },
      error: () => {
      },
    });
  }
}
getClients(){
  if(this.isAdmin){
    this.dataLoaded=false;
    this.selectedTenant=this.openDealForm.get('tenentCarPartner')?.value;
    this.openDealService.getClientList(this.selectedTenant).subscribe({
      next: (data: any) => {
      this.dataLoaded=true;
      this.clients=data;
      },
      error: () => {
        this.dataLoaded=true;
      },
    });
  }
}
getCreatedBy(){
  this.selectedClient=this.openDealForm.get('clients')?.value;
  this.dataLoaded=false;
    this.openDealService.getCreatedByList(this.selectedClient).subscribe({
      next: (data: any) => {
       this.createdby=data;       
        let createdBy=this.createdby.find(
          (t) => t.name == "All"
        );
        if(createdBy){
        this.openDealForm.get('createdby')?.setValue(createdBy.contactID);
        }              
       this.dataLoaded=true;
       this.search();
      },
      error: () => {
        this.dataLoaded=true;
      },
    });
  
}
search(){

  if(this.isAdmin){
    if (this.openDealForm.valid){
  this.selectedCreatedBy=this.openDealForm.get('createdby')?.value;
  this.dataLoaded=false;
  this.loadingMessage="Loading";
  this.openDealService.search(this.selectedTenant,this.selectedClient,this.selectedCreatedBy,this.pageIndex+1,this.pageSize).subscribe({
    next: (data: any) => {
     this.currencyPairs=data.currencyPairs;
     this.length=this.currencyPairs.length;
     this.addshowDeatils()
     if(this.currencyPairs.length>0) {
      this.noRecordFound=false;
     }else{
      this.noRecordFound=true;
     }
     this.dataLoaded=true;
    },
    error: () => {
      this.noRecordFound=true;
    },
  });
  }
  else{
    this.markFormGroupTouched(this.openDealForm);
  }
  }
  else{
  
  this.dataLoaded=false;
  this.loadingMessage="Loading";
  this.openDealService.searchClientTrade(this.pageIndex+1,this.pageSize).subscribe({
    next: (data: any) => {
     this.currencyPairs=data.currencyPairs;
     this.addshowDeatils()
     if(this.currencyPairs.length>0) {
      this.noRecordFound=false;
     }else{
      this.noRecordFound=true;
     }
     this.dataLoaded=true;
    },
    error: () => {
      this.noRecordFound=true;
    },
  });
  }

}
markFormGroupTouched(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    if (control instanceof FormGroup) {
      this.markFormGroupTouched(control);
    }
  });
}
reset(){
  this.openDealForm.reset();
  this.clients=[];
  this.createdby=[];
  this.search();
}
addshowDeatils(){
  this.currencyPairs.forEach(currencyPair => {    
    currencyPair.deliveryDates.forEach((deliveryDate: { showDetails: boolean; }) => {    
        deliveryDate.showDetails = false;
    });
});
}
toggleShowdetails(currencyPairIndex:number,index:number){

  if (this.currencyPairs.length > 0 && this.currencyPairs[0].deliveryDates.length > 0) {    
    this.currencyPairs[currencyPairIndex].deliveryDates[index].showDetails =!this.currencyPairs[currencyPairIndex].deliveryDates[index].showDetails
  }
 
}
viewTradeDetails(tradeId:number){
  this.dataLoaded=false
  this.viewdTradeID=tradeId;
  this.openModal();
  this.openDealService.gettradeDetails(tradeId).subscribe({
    next: (data: any) => {
      var blob = new Blob([data], { type: 'application/pdf' });             
        const url = window.URL.createObjectURL(blob);
        this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);     
        this.dataLoaded=true;       
       
    },
    error: () => {
        this.dataLoaded=true;
        this.noRecordFound=true;
    },
  });
}
openModal( ) {

  
  const modal = this.elementRef.nativeElement.querySelector(
    '#TradeView'
  );
  const backdrop = document.createElement('div');
  backdrop.classList.add('backdrop');

  if (modal) {
    modal.classList.add('show');
    modal.style.display = 'block';
    document.body.appendChild(backdrop);
    modal.focus();
  }

}

close() {
  this.search();   
  this.pdfSrc='';
  const modal = this.elementRef.nativeElement.querySelector(
    '#TradeView'
  );
  modal.classList.remove('show');
  modal.style.display = 'none';
  
}
openEditModal( ) {  
  const modal = this.elementRef.nativeElement.querySelector(
    '#EditSFDeal-modal'
  );
  const backdrop = document.createElement('div');
  backdrop.classList.add('backdrop');

  if (modal) {
    modal.classList.add('show');
    modal.style.display = 'block';
    document.body.appendChild(backdrop);
    modal.focus();
  }
}
closeEditModel() {
  this.search();   
  
  const modal = this.elementRef.nativeElement.querySelector(
    '#EditSFDeal-modal'
  );
  modal.classList.remove('show');
  modal.style.display = 'none';
  
}
editTrade(trade :any){
   this.dataLoaded=false;

  this.viewdTradeID=trade.tradeId;
   this.openDealService.getEditTradeDetails(this.viewdTradeID).subscribe({
    next: (data: any) => {     
        this.DealDetailData = data;
        this.paymanetReference = this.DealDetailData.paymentReference;
        this.deliverabilityType = this.DealDetailData.selectedPaymentOption;
        this.beneficiaryID=this.DealDetailData.beneficiaryAccountID;      
        if (this.DealDetailData.fundingAccountType) {
          this.accountType = this.DealDetailData.fundingAccountType;
        }
        if (this.accountType == 'Beneficiary') {
          this.beneficiaryFundingAccountID =
            this.DealDetailData.fundingAccountID;
        } else if(this.accountType=='InternalTransfer')        
        {
          this.internalFundingAccountTypeID=this.DealDetailData.fundingAccountID;
        }
        this.openEditModal();

      this.dataLoaded=true;
    },
    error: (error) => {if (error.error && Array.isArray(error.error)) {
      const description = error.error[0].description;
      this.errormsg = description;
    } else {
      this.errormsg = ErrorMessages.Trade_data;
    }
      swal.fire({
        icon: 'warning',
        title: this.errormsg,
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500,
      });
      this.closeEditModel();
      
      this.dataLoaded=true;
    },
  });
}

DownloadSSIPDF(tradeID:number){
  this.dataLoaded=false;
  this.openDealService.getSSI().subscribe({
  next: (data) => {
    var blob = new Blob([data], { type: 'application/pdf' });

    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${tradeID}.pdf`;
    link.click();
    this.dataLoaded=true;
  },
  error: (error) => {
    swal.fire({
      icon: 'warning',
      title: 'PDF not Found',
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    }); 
    this.dataLoaded=true;
  },
});
}

DownloadFundPDF(tradeID:number){
  this.dataLoaded=false;
  this.openDealService.gettradeDetails(tradeID).subscribe({
  next: (data) => {
    var blob = new Blob([data], { type: 'application/pdf' });

    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${tradeID}.pdf`;
    link.click();
    this.dataLoaded=true;
  },
  error: (error) => {
    swal.fire({
      icon: 'warning',
      title: ErrorMessages.PDF_Not_Fount,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    }); 
    this.dataLoaded=true;
  },
});
}
saveEditChanges(){
  let payload = {
    tradeID: this.DealDetailData.tradeID,
    paymentOption: this.deliverabilityType,
    beneficiaryAccountID: this.beneficiaryID,
    paymentReference: this.paymanetReference,
    accountType: this.accountType,
    beneficiaryFundingAccountID: this.beneficiaryFundingAccountID?this.beneficiaryFundingAccountID:null,
    internalFundingAccountTypeID: this.internalFundingAccountTypeID?this.internalFundingAccountTypeID:null,
    confirmOrSkip: 'Confirm',
  }; 
  this.openDealService.saveChangesTrade(payload).subscribe({
    next: () => {
      this.dataLoaded = true;
      this.loadingMessage="Updating";
      this.alert.fireAlert('Saved');
      this.closeEditModel();
    },
    error: (error) => {
      this.dataLoaded = true;
      if (error.error && Array.isArray(error.error)) {
        const description = error.error[0].description;
        this.errormsg = description;
      } else {
        this.errormsg = ErrorMessages.Update_trade_Failed;
      }
      swal.fire({
        icon: 'warning',
        title: this.errormsg,
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500,
      });
      

    },
  });
}
handlePageEvent(e: PageEvent) {
  this.pageEvent = e;
  this.pageSize = e.pageSize;
  this.pageIndex = e.pageIndex;
  let startIndex = 0;
  let endIndex = this.pageSize;
  if (this.pageIndex != 0) {
    startIndex = this.pageIndex * this.pageSize;
    endIndex = startIndex + this.pageSize;
  }
  this.length=this.currencyPairs.length;

}

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') { 
      this.openCloseDropdwon("0");
    } 
    else {
      this.openCloseDropdwon("1");
    }
  }

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
      if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
        this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
      }
  }
}
