<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">{{loadingMessage}}</div>
    </div>
  </div>
  
  <div class="">
    <div class="inner user-page">
      <div class="title">
        <a (click)="addButton()" *ngIf="!hideAddButton" id="btnClientAddNew" class="btn btn-success">
          <em
            ><img src="../../../assets/plus.svg" alt=""
          /></em>
          Add
        </a>
      </div>
  
      <div>          
        <div class="GridClass custom-container">
          <div class="table-responsive" *ngIf="clients.length>0;else notFound">
            <table class="table custom-grid">
                <colgroup>
                    <col style="width:15%">
                    <col style="width:15%">
                    <col style="width:20%">
                </colgroup>
  
              <thead>
                <tr class="Row123">
                  <th>
                    <a
                      class="sort active ts-asc"
                      (click)="sortTable('referredClientName')"
                      unselectable="on"
                      data-i="0">Client Name<span class="ts-sord"></span>
                    </a>
                  </th>

                  <th>
                    <a
                      class="sort active ts-asc"
                      (click)="sortTable('periodInMonths')"
                      unselectable="on"
                      data-i="1">Period (in Months)<span class="ts-sord"></span>
                    </a>
                  </th>
                  
                  <th>
                    <a class="sort" unselectable="on" data-i="8">
                      <span class="action">Action</span>
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of paginatedData">
                  <td>{{row.referredClientName}}</td>
                  <td>{{row.periodInMonths}}</td>                  
                  <td>
                    <div class="action-btns">
                      <a (click)="editButton(row)" title="Edit"
                        ><img
                          src="../../../assets/edit.svg"
                          alt=""
                      /></a>

                      <a (click)="deleteData(row)" title="Delete"
                        ><img
                          src="../../../assets/delete.svg"
                          alt=""
                      /></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
  
            <mat-paginator *ngIf="clients.length>0"
              #paginator
              (page)="handlePageEvent($event)"
              [length]="length"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [disabled]="disabled"
              [showFirstLastButtons]="showFirstLastButtons"
              [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
              [hidePageSize]="hidePageSize"
              [pageIndex]="pageIndex"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade backdrop" #savesale id="save-sales-margin" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="max-height: 500px !important; min-width: 620px !important;">
            <div class="modal-header" style="position: sticky; top: 0; background-color: white;">
              <h1 class="modal-title" id="staticBackdropLabel">{{modalTitle}}</h1>
              <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                <!-- <span aria-hidden="true">&times;</span> -->
                <span aria-hidden="true"><img src="../../../assets/cross.svg" alt=""></span>
              </button>
            </div>

            <form [formGroup]="referredClientForm" (submit)="submitReferredPartner()"> 
                <div class="modal-body">    
                    <div class="d-flex flex-row">
                        <div class="col-sm-6">
                            <div class="form-group clientselect" [ngClass]="{'newTooltip expand has-error': (referredClientForm.controls['ReferredClientID'].touched || referredClientForm.controls['ReferredClientID'].dirty || submitted) && (referredClientForm.get('PeriodInMonths').hasError('required')) }"
                            title="Client Name is Required">
                                <label class="mb-2" style="font-weight: 600;">Client Name <em style="color: red">*</em></label>
                                <ng-select [items]="clientList" bindLabel="clientName" class="select-control errorSelect" formControlName="ReferredClientID"
                                    bindValue="clientID" [searchable]="true" [multiple]="false" (change)="clientDropdown()"
                                    placeholder="Select" [class.disableClientName]="editReferredMode">
                                  </ng-select>
                            </div>
                            <span class="text-danger" [class.d-none]="!hasError">{{errorMessage}}</span>
                        </div> 
                         
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{'newTooltip expand has-error': (referredClientForm.controls['PeriodInMonths'].touched || referredClientForm.controls['PeriodInMonths'].dirty || submitted) && (referredClientForm.get('PeriodInMonths').hasError('min') || referredClientForm.get('PeriodInMonths').hasError('max') || referredClientForm.get('PeriodInMonths').hasError('pattern')) }"
                            title="Periods must between 0 to 60">                                
                                <label class="mb-2" style="font-weight: 600;">Period (in Months)</label>
                                <input class="form-control" formControlName="PeriodInMonths" data-val="true" placeholder="Period In Months" type="number"
                                >
                            </div>                            
                        </div> 
                    </div>                    
                </div>
                <div class="modal-footer" style="position: sticky; bottom: 0;">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-success">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
  </div>
  
  <ng-template #notFound>
      <div class="alert alert-info">
          <span>No record found</span>
      </div>
  </ng-template>