<div *ngIf="isLoading">
    <div class="loader-block">
        <div class="inner-loader">
        </div>
    </div>
</div>
<div class="">
    <div class="compress-inputs-page">
        <div class="inner add-user">
            <div class="backButtonStyle">
                <h3>&nbsp;</h3>
                <a (click)="backToManageCurrencies()" style="cursor: pointer;" class="btn btn-outline-secondary btn-sp">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>
            </div>

            <div class="card">
                <form [formGroup]="manageCurrencyForm" (ngSubmit)="submitForm()" class="form ml-3 mt-4">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Currency Name <em style="color:red;">*</em></label>
                                <div class="showhide"
                                    [ngClass]="{'newTooltip expand has-error': (manageCurrencyForm.controls['currencyName'].touched) && manageCurrencyForm.controls['currencyName'].invalid }"
                                    datatitle="Currency name is required">
                                    <input matInput formControlName="currencyName" type="text" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <label class="patterValidationMessage" *ngIf="(manageCurrencyForm.controls['currencyName'].errors?.['pattern'])">Only character allowed</label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Currency Abbreviation <em style="color:red;">*</em></label>
                                <div class="showhide" datatitle="Currency abbreviation is required."
                                    [ngClass]="{'newTooltip expand has-error': (manageCurrencyForm.controls['currencyAbbreviation'].touched) && manageCurrencyForm.controls['currencyAbbreviation'].invalid }">

                                    <input formControlName="currencyAbbreviation" type="text" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <label class="patterValidationMessage" *ngIf="(manageCurrencyForm.controls['currencyAbbreviation'].errors?.['pattern'])">Only character allowed</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Currency Symbol <em style="color:red;">*</em></label>
                                <div class="showhide" datatitle="Currency symbol is required."
                                    [ngClass]="{'newTooltip expand has-error': (manageCurrencyForm.controls['currencySymbol'].touched) && manageCurrencyForm.controls['currencySymbol'].invalid }">
                                    <input formControlName="currencySymbol" type="text" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <label class="patterValidationMessage" *ngIf="(manageCurrencyForm.controls['currencySymbol'].errors?.['pattern'])">Only character allowed</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Country <em style="color:red;">*</em></label>
                                <div class="showhide" datatitle="Country name is required."
                                    [ngClass]="{'newTooltip redborder expand has-error': (manageCurrencyForm.controls['countryID'].touched)
                                     && manageCurrencyForm.controls['countryID'].invalid }">
                                    <ng-select class="dropdown" [items]="countries" [closeOnSelect]="true"
                                        [searchable]="true" bindLabel="countryName" bindValue="countryID"
                                       
                                        placeholder="Select" formControlName="countryID">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-6">
                            <div class="inline-element d-block text-center">

                                <button id="aSaveDetails" class="btn btn-success" type="submit"
                                    style="margin-right: 10px;">Save</button>
                                <button (click)="backToManageCurrencies()" id="aCancelDetails" class="btn btn-secondary" type="button">Cancel</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>