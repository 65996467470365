export const PathMapping = {
    /* MenuId: menuid is the counter no of the module which has children i.e. Client master: 0, Manage master: 1, Settings: 2 etc... */
    Path: [ 

        /* Quick Quote */
        {
            pageID: 25,
            moduleName: 'QuickQuote',
            path: '/Admin/quickquote', 
            menuId: -1,  
            extraModule: false       
        }, 

        /* Client Management */
        {
            pageID: 3,
            moduleName: 'Manage Clients',
            path: '/Admin/manageclient', 
            menuId: 0, 
            extraModule: false            
        },
        {
            pageID: 4,
            moduleName: 'Manage Contacts',
            path: '/Admin/managecontact',
            menuId: 0,
            extraModule: false              
        },
        {
            pageID: 3,
            moduleName: 'Add Client',
            path: '/Admin/getclienttab',
            menuId: 0,  
            extraModule: true
        },
        {
            pageID: 3,
            moduleName: 'Edit Client',
            path: 'getclienttab/:ClientID',
            menuId: 0,  
            extraModule: true
        },
        {
            pageID: 4,
            moduleName: 'Add Contact',
            path: '/Admin/addContact',
            menuId: 0,  
            extraModule: true
        },
        {
            pageID: 4,
            moduleName: 'Edit Contact',
            path: 'addContact/:contactId/:clientID',
            menuId: 0,  
            extraModule: true
        },

        /* Manage Masters */
        {
            pageID: 5,
            moduleName: 'Manage Currencies',
            path: '/Admin/ManageCurrencies', 
            menuId: 1,
            extraModule: false             
        },
        {
            pageID: 6,
            moduleName: 'Manage Currency Pairs',
            path: '/Admin/ManageCurrencyPair', 
            menuId: 1,
            extraModule: false             
        },
        {
            pageID: 6,
            moduleName: 'Add Currency Pairs',
            path: '/Admin/addeditcurrencypair', 
            menuId: 1,
            extraModule: true             
        },
        {
            pageID: 6,
            moduleName: 'Edit Currency Pairs',
            path: '/Admin/addeditcurrencypair/:id', 
            menuId: 1,
            extraModule: true             
        },
        {
            pageID: 7,
            moduleName: 'Manage Products',
            path: '/Admin', 
            menuId: 1, 
            extraModule: false            
        },
        {
            pageID: 8,
            moduleName: 'Manage Holidays',
            path: '/Admin', 
            menuId: 1,
            extraModule: false             
        },
        {
            pageID: 10,
            moduleName: 'Statement Tiers',
            path: '/Admin', 
            menuId: 1,
            extraModule: false             
        },
        {
            pageID: 11,
            moduleName: 'Adjustment Configuration',
            path: '/Admin', 
            menuId: 1,
            extraModule: false             
        },
        {
            pageID: 12,
            moduleName: 'Client Account',
            path: '/Admin/clientaccount', 
            menuId: 1, 
            extraModule: false            
        },
        {
            pageID: 13,
            moduleName: 'Product Email Template',
            path: '/Admin', 
            menuId: 1, 
            extraModule: false            
        },
        {
            pageID: 14,
            moduleName: 'Client Tiers',
            path: '/Admin', 
            menuId: 1,  
            extraModule: false           
        },
        {
            pageID: 15,
            moduleName: 'Email Template',
            path: '/Admin', 
            menuId: 1, 
            extraModule: false            
        },
        {
            pageID: 18,
            moduleName: 'Manage Strike Range',
            path: '/Admin', 
            menuId: 1, 
            extraModule: false            
        },
        {
            pageID: 19,
            moduleName: 'Application Setting',
            path: '/Admin', 
            menuId: 1,  
            extraModule: false           
        },
        {
            pageID: 22,
            moduleName: 'Sales Margin',
            path: '/Admin/ManageSalesMargin', 
            menuId: 1,    
            extraModule: false         
        },
        {
            pageID: 26,
            moduleName: 'Manage Tenants',
            path: '/Admin/managetenants', 
            menuId: 1,    
            extraModule: false         
        },
        {
            pageID: 36,
            moduleName: 'Manage CAR Partners',
            path: '/Admin/manageCarpartner', 
            menuId: 1,   
            extraModule: false          
        },


        {
            pageID: 26,
            moduleName: 'Add Tenants',
            path: '/Admin/addedittenant', 
            menuId: 1,    
            extraModule: true         
        },
        {
            pageID: 26,
            moduleName: 'Edit Tenants',
            path: 'addeditenant/:tenantId', 
            menuId: 1,    
            extraModule: true         
        },

        // {
        //     pageID: 36,
        //     moduleName: 'Add CAR Partners',
        //     path: '/Admin/manageCarpartner', 
        //     menuId: 1,   
        //     extraModule: true          
        // },
        // {
        //     pageID: 36,
        //     moduleName: 'Edit CAR Partners',
        //     path: '/Admin/manageCarpartner', 
        //     menuId: 1,   
        //     extraModule: true          
        // },


        /* Settings */
        {
            pageID: 1,
            moduleName: 'Manage Users',
            path: '/Admin/ManageUsers',  
            menuId: 2, 
            extraModule: false           
        },
        {
            pageID: 2,
            moduleName: 'Manage Roles',
            path: '/Admin/manageroles',
            menuId: 2, 
            extraModule: false             
        },

        {
            pageID: 1,
            moduleName: 'Add Users',
            path: '/Admin/addEditUser',  
            menuId: 2, 
            extraModule: true           
        },
        {
            pageID: 1,
            moduleName: 'Edit User',
            path: 'addEditUser/:userID',
            menuId: 2, 
            extraModule: true             
        },

        {
            pageID: 2,
            moduleName: 'Add Roles',
            path: '/Admin/addEditRole',  
            menuId: 2, 
            extraModule: true           
        },
        {
            pageID: 2,
            moduleName: 'Edit Roles',
            path: 'addEditRole/:roleID',
            menuId: 2, 
            extraModule: true             
        },


        /* Finance */
        {
            pageID: 30,
            moduleName: 'Target Revenue',
            path: '/Admin',  
            menuId: 3, 
            extraModule: false           
        },
        {
            pageID: 31,
            moduleName: 'Referral Partners',
            path: '/Admin/ReferralPartner',
            menuId: 3,     
            extraModule: false         
        },
        {
            pageID: 32,
            moduleName: 'Sales Revenue',
            path: '/Admin',
            menuId: 3,     
            extraModule: false         
        },
        {
            pageID: 33,
            moduleName: 'Deal Settlement',
            path: '/Admin',
            menuId: 3,   
            extraModule: false           
        },

        {
            pageID: 31,
            moduleName: 'Add Referral Partners',
            path: '/Admin/referralPartnertab',
            menuId: 3,   
            extraModule: true           
        },

        {
            pageID: 31,
            moduleName: 'Edit Referral Partner General',
            path: 'referralPartnertab/:ReferralPartnerId',
            menuId: 3,   
            extraModule: true           
        },


        /* Deals */
        {
            pageID: 16,
            moduleName: 'Deals',
            path: '/Admin',
            menuId: 4,  
            extraModule: false            
        },
        {
            pageID: 20,
            moduleName: 'Spot',
            path: '/Admin/openDealSF',
            menuId: 4,  
            extraModule: false            
        },
        
        {
            pageID: 21,
            moduleName: 'Forward',
            path: '/Admin',
            menuId: 4,  
            extraModule: false            
        },
        {
            pageID: 37,
            moduleName: 'Predelivery',
            path: '/Admin',
            menuId: 4,  
            extraModule: false            
        },
        {
            pageID: 38,
            moduleName: 'Extension',
            path: '/Admin',
            menuId: 4,  
            extraModule: false            
        },
        {
            pageID:39,
            moduleName: 'Option Hedge',
            path: '/Admin/optionhedge',
            menuId: 4,  
            extraModule: false  

        },


        /* Deal History */
        {
            pageID: 28,
            moduleName: 'Deal History',
            path: '/Admin/dealhistory',
            menuId: -1,  
            extraModule: false            
        },

        /* Beneficiaries */
        {
            pageID: 23,
            moduleName: 'View Beneficiaries',
            path: '/Admin/viewBeneficiaries',  
            menuId: 5,  
            extraModule: false          
        },
        {
            pageID: 24,
            moduleName: 'Add Beneficiary',
            path: '/Admin/addBeneficiary',  
            menuId: 5,     
            extraModule: false       
        },
        {
            pageID: 27,
            moduleName: 'Import Beneficiary',
            path: '/Admin',  
            menuId: 5,
            extraModule: false            
        },


        /* All Deal Listing */
        {
            pageID: 9,
            moduleName: 'All Deal Listing',
            path: '/Admin',
            menuId: -1,  
            extraModule: false            
        },


        /* Reports */
        {
            pageID: 17,
            moduleName: 'Reports',
            path: '/Admin/reports',
            menuId: -1,  
            extraModule: false            
        },
        {
            pageID: 40,
            moduleName: 'SpotDeal',
            path: '/Admin/SpotDeal',
            menuId: -1,  
            extraModule: false            
        },
        {
            pageID: 40,
            moduleName: 'Deal Detail',
            path: '/Admin/SpotDeal/DealDetail',
            menuId: -1,  
            extraModule: true 

        },
        {
            pageID: 41,
            moduleName: 'Forward Hedge',
            path: '/Admin/ForwardHedge',
            menuId: -1,  
            extraModule: false            
        },
        {
            pageID: 41,
            moduleName: 'Deal Detail',
            path: '/Admin/ForwardHedge/DealDetail',
            menuId: -1,  
            extraModule: true 

        },
        {
            pageID: 42,
            moduleName: 'Option Hedge',
            path: '/Admin/optionhedge',
            menuId: -1,  
            extraModule: false            
        },

        /* Support */
        {
            pageID: 34,
            moduleName: 'Support Utility',
            path: '/Admin',
            menuId: -1,  
            extraModule: false            
        },
        {
            pageID: 43,
            moduleName: 'Support',
            path: '/Admin/auditLogs',
            menuId: 2,  
            extraModule: false            
        },


        /* Swaps History */
        {
            pageID: 39,
            moduleName: 'Swaps History',
            path: '/Admin',
            menuId: -1,  
            extraModule: false            
        },
        
        
        /* Swap History */
        {
            pageID: 39,
            moduleName: 'Swaps History',
            path: '/Admin',       
            menuId: -1,   
            extraModule: false    
        },         
    ]
}
export const ClientPathMapping={
    Path:[
        {
            pageID: 1,
            moduleName: 'Dashboard ',
            path: '/Client/Dashboard',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 53,
            moduleName: 'Portfolio',
            path: '/Client',       
            menuId: -1,   
            extraModule: false    
        },   
        {
            pageID: 45,
            moduleName: 'Spot Deal',
            path: '/Client/SpotDeal',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 45,
            moduleName: 'Spot Deal',
            path: '/Client/SpotDeal/DealDetail',       
            menuId: -1,   
            extraModule: true    
        },
        {
            pageID: 46,
            moduleName: 'Forward Hedge',
            path: '/Client/ForwardHedge',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 46,
            moduleName: 'Deal Detail',
            path: '/Client/ForwardHedge/DealDetail',       
            menuId: -1,   
            extraModule: true    
        },
        {
            pageID: 47,
            moduleName: 'Option Hedge',
            path: '/Client/optionhedge',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 48,
            moduleName: 'Predelivery',
            path: '/Client',       
            menuId: -1,   
            extraModule: false    
        },       
        {
            pageID: 49,
            moduleName: 'Extension',
            path: '/Client',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 4,
            moduleName: 'Open Deals',
            path: '/Client/openDealSF',       
            menuId: 0,   
            extraModule: false    
        },
        {
            pageID: 4,
            moduleName: 'Spot / Forward',
            path: '/Client',       
            menuId: 0,   
            extraModule: false    
        },
        {
            pageID: 52,
            moduleName: 'Option',
            path: '/Client',       
            menuId: 0,   
            extraModule: false    
        },
        {
            pageID: 3,
            moduleName: 'Deal History',
            path: '/Client/dealhistory',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 51,
            moduleName: 'Swaps History',
            path: '/Client',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 44,
            moduleName: 'Order Book',
            path: '/Client',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 9,
            moduleName: 'Beneficiary',
            path: '/Client',       
            menuId: 1,   
            extraModule: false    
        },
        {
            pageID: 56,
            moduleName: 'View Beneficiaries',
            path: '/Client/viewBeneficiaries',       
            menuId: 1,   
            extraModule: false    
        },
        {
            pageID: 57,
            moduleName: 'Add Beneficiary',
            path: '/Client/addBeneficiary',       
            menuId: 1,   
            extraModule: false    
        },
        {
            pageID: 13,
            moduleName: 'Import Beneficiary',
            path: '/Client',       
            menuId: 1,   
            extraModule: false    
        },
        {
            pageID: 11,
            moduleName: 'Account Balances',
            path: '/Client/accountbalances',       
            menuId: -1,   
            extraModule: false    
        },
        {
            pageID: 99,
            moduleName: 'Reports',
            path: '/Client/reports',       
            menuId: -1,   
            extraModule: false    
        }, {
            pageID: 2,
            moduleName: 'Client Management',
            path: '/Client/Clientmanagement',       
            menuId: -1,   
            extraModule: false    
        }, {
            pageID: 7,
            moduleName: 'User Roles ',
            path: '/Client/userroles',       
            menuId: -1,   
            extraModule: false    
        },
        

    ]
};
export const clintSidenavData=[
    
        {
            "pageID": 1,
            "moduleName":"Dashboard",
            "moduleID": 1,
            "pageName": "Dashboard",
            "orderNo": 1,
            "moduleOrderNo": 1
        },
        {
            "pageID": 53,
            "moduleName": "Portfolio",
            "moduleID": 18,
            "pageName": "Portfolio",
            "orderNo": 1,
            "moduleOrderNo": 2
        },   
        {
            "pageID": 45,
            "moduleName": "Spot Deal",
            "moduleID": 11,
            "pageName": "Spot Deal",
            "orderNo": 1,
            "moduleOrderNo": 3
        },
        {
            "pageID": 46,
            "moduleName": "Forward Hedge",
            "moduleID": 12,
            "pageName": "Forward Hedge",
            "orderNo": 1,
            "moduleOrderNo": 4  
        },
        {
            
            "pageID": 47,
            "moduleName": "Option Hedge",
            "moduleID": 13,
            "pageName": "Option Hedge",
            "orderNo": 1,
            "moduleOrderNo": 5   
        },
        {
            
            "pageID": 48,
            "moduleName": "Predelivery",
            "moduleID": 14,
            "pageName": "Predelivery",
            "orderNo": 1,
            "moduleOrderNo": 6    
        },       
        {
            "pageID": 49,
            "moduleName": "Extension",
            "moduleID": 15,
            "pageName": "Extension",
            "orderNo": 1,
            "moduleOrderNo": 7    
        },
       
        {
           
            "pageID": 4,
            "moduleName": "Open Deals",
            "moduleID": 4,
            "pageName": "Spot/Forward",
            "orderNo": 1,
            "moduleOrderNo": 8
        },
        {
            
            "pageID": 52,
            "moduleName": "Open Deals",
            "moduleID": 4,
            "pageName": "Option Hedge",
            "orderNo": 2,
            "moduleOrderNo": 8 
        },
        {
            "pageID": 3,
            "moduleName": "Deal History",
            "moduleID": 3,
            "pageName": "Deal History",
            "orderNo": 1,
            "moduleOrderNo": 9    
        },
        
        {           
            "pageID": 51,
            "moduleName": "Swaps History",
            "moduleID": 17,
            "pageName": "Swaps History",
            "orderNo": 1,
            "moduleOrderNo": 9
        },
        {
            "pageID": 44,
            "moduleName": "Order Book",
            "moduleID": 10,
            "pageName": "Order Book",
            "orderNo": 1,
            "moduleOrderNo": 10  
        },
       
        {
            
            "pageID": 56,
            "moduleName": "Beneficiary",
            "moduleID": 9,
            "pageName": "View Beneficiaries",
            "orderNo": 1,
            "moduleOrderNo": 12     
        },
        {
          
            "pageID": 57,
            "moduleName": "Beneficiary",
            "moduleID": 9,
            "pageName": "Add Beneficiary",
            "orderNo": 2,
            "moduleOrderNo": 12    
        },
        {
            "pageID": 13,
            "moduleName": "Beneficiary",
            "moduleID": 9,
            "pageName": "Import Beneficiary",
            "orderNo": 3,
            "moduleOrderNo": 12      
        },
        {
            
            "pageID": 11,
            "moduleName": "Account Balances",
            "moduleID": 7,
            "pageName": "Account Balances",
            "orderNo": 1,
            "moduleOrderNo": 13   
        },
        {
            "pageID": 99,
            "moduleName": "Reports",
            "moduleID": 5,
            "pageName": "Reports",
            "orderNo": 1,
            "moduleOrderNo": 14  
        }, 
        {        
            "pageID": 2,
            "moduleName": "Client Management",
            "moduleID": 2,
            "pageName": "Client Management",
            "orderNo": 1,
            "moduleOrderNo": 15    
        }, 
		{           
            "pageID": 7,
            "moduleName": "User Roles",
            "moduleID": 6,
            "pageName": "User Roles",
            "orderNo": 1,
            "moduleOrderNo": 16    
        }
       

    ]