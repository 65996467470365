<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>

<div class="card secondary">
 

    <div id="NPConfirmdiv" style="margin-bottom:10px;">


        <div class="card">



            <div class="title">

                <h4>Deal Detail</h4>


                <a (click)="back()" id="btnBackToNPManageQuote" class="btn btn-outline-secondary btn-sp">
                    <em><img src="../../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>

            </div>

            <div class="row">
                <div class="col-md-2" style="padding: 10px 11px;">
                    <label> Trade ID </label>
                </div>
                <div class="col-md-5">
                    <span id="spanTradeID" class="badge secondery">{{DealDetailData?.tradeID}}</span>
                </div>
            </div>

            <div class="row" id="DealSpotDiv">
                <div class="col-md-2" style="padding: 10px 11px;">


                    <ng-content select="[deal]"></ng-content>

                </div>
                <div class="col-md-5">

                    <span class="badge secondery" id="spnDealSpotReference">{{dataFromQuoteApi?.rate}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2" style="padding: 10px 11px;">
                    <label> Value Date </label>
                </div>

                <div class="col-md-5">
                    <span class="badge secondery">{{dataFromQuoteApi.valueDate | date:'dd/MM/yyyy'}}</span>
                </div>
            </div>



            <div class="row">
                <div class="col-md-2" style="padding: 10px 11px;">


                    <label>Client Buys</label>





                </div>
                <div class="col-md-5">

                    <span class="badge secondery" id="basecurrency"> {{dataFromQuoteApi?.BuyCurrency}}
                        {{dataFromQuoteApi.clientBuyAmount | number:'1.2-2'}} </span>


                </div>
            </div>

            <div class="row">
                <div class="col-md-2" style="padding: 10px 11px;">

                    <label>Client Sells</label>


                </div>
                <div class="col-md-5">
                    <!-- @*<span class="badge secondery " id="termcurrency">@Model.Trade.TermCurrencySymbol @Model.Ticket.NotionalTermAmount.ToString(Helper.ThousandWith2Floating)</span>*@ -->
                    <span class="badge secondery " id="termcurrency">
                        {{dataFromQuoteApi?.clientSellCurrency}}
                        {{dataFromQuoteApi?.clientSellAmount | number:'1.2-2'}}
                    </span>

                </div>
            </div>




        </div>

    </div>

    <div id="NPpaymentmodediv" class="card hidden">

        <div class="title">
            <h4>PAYMENT OPTION</h4>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">

                    <div class="checkbox-currency">
                        <div class="custom-radio secondary">
                            <!-- @Html.RadioButtonFor(c => c.Ticket.PaymentMode, EnumNPPaymentMode.A.ToString(), new { id = "A", @class = "radio-input", @onchange = "OnChangePaymentMode(this);" }) -->
                            <input type="radio" value="AutoRoll" [(ngModel)]="paymentOption" (change)="changepayment()"
                                name="paymentoption" class="radio-input">

                            <span class="radio-label">Auto Roll</span>
                        </div>

                        <div class="custom-radio secondary">
                            <!-- @Html.RadioButtonFor(c => c.Ticket.PaymentMode, EnumNPPaymentMode.D.ToString(), new { id = "D", @class = "radio-input", @onchange = "OnChangePaymentMode(this);" }) -->
                            <input type="radio"  value="Deliver" [(ngModel)]="paymentOption" (change)="changepayment()"
                                name="paymentoption" class="radio-input">
                            <span class="radio-label">Deliver</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="paymentOption==='Deliver'" id="NPBeneficiarydiv" class="card hidden" style="margin-top:10px;">
        <div class="has-sub-title">
            <div class="title">
                <h4>PAYMENT DETAILS</h4>
            </div>
            <!-- @*<a href="#" onclick="TradeAddBeneficiary(this)" class="btn btn-success" id="btnBeneficiarySearch">Add Beneficiary</a>*@ -->
        </div>



        <div id="dvpaymentcontent">

            <div style="margin-bottom:5px;">



                <div id="Incomingdiv">
                    <div class="card">

                        <div class="title">
                            <h4> BENEFICIARY ACCOUNT </h4>
                        </div>



                        <div class="row" style="margin-bottom:7px;">

                            <div class="col-md-3">
                                <label> Beneficiary Account</label>
                            </div>
                            <div id="Blistdiv" class="col-md-6">
                                <ng-select [items]="DealDetailData?.beneficiaryAccount" [closeOnSelect]="true"
                                    [(ngModel)]="beneficiaryID"
                                    placeholder="Select" bindLabel="beneficiaryAccountName" bindValue="beneficiaryID">
                                </ng-select>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-3 form-group label-inline">
                                <label>Amount</label>
                            </div>
                            <div class="col-md-5" style="margin-bottom:7px;">

                                <span class="badge secondery">{{DealDetailData?.clientBuyCurrency}}
                                    {{DealDetailData.clientBuyAmount | number:'1.2-2'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 form-grou label-inlinep">
                                <label>Payment Reference</label>

                            </div>
                            <div class="col-md-5" style="margin-bottom:7px;">
                                <input type="text" class="form-control" [(ngModel)]="paymanetReference">

                            </div>
                        </div>






                    </div>
                </div>
                <div id="outgoingdiv">
                    <div class="card">

                        <div class="title">
                            <h4>Funding Account </h4>
                        </div>

                        <div class="row" style="margin-bottom:10px;">
                            <div class="col-md-3" style="padding-top:5px;">
                                <label>Account Type </label>
                            </div>

                            <div class="col-md-6 checkbox-currency">
                                <div class="custom-radio secondary">
                                    <input type="radio" value="Beneficiary" [(ngModel)]="accountType"  name="FundingAccountType"
                                        class="radio-input">

                                    <span class="radio-label">Beneficiary</span>
                                </div>
                                <div class="custom-radio secondary">
                                    <input type="radio" value="InternalTransfer" [(ngModel)]="accountType" name="FundingAccountType" class="radio-input">

                                    <span class="radio-label">Internal Transfer</span>
                                </div>

                            </div>
                        </div>
                        <div class="row" style="margin-bottom:7px;">
                            <div class="col-md-3">
                                <label>Funding Account</label>
                            </div>

                            <div id="BFlistdiv" class="col-md-6">
                                <ng-select *ngIf="accountType==='Beneficiary'" [items]=" DealDetailData?.beneficiaryFundingAccount" bindLabel="beneficiaryAccountName"
                                    bindValue="beneficiaryID" placeholder="Select" [(ngModel)]="beneficiaryFundingAccountID"></ng-select>
                                <ng-select *ngIf="accountType==='InternalTransfer'" [items]=" DealDetailData?.internalTransferFundingAccount" bindLabel="internalTransferFundAccountName"
                                    bindValue="internalTransferFundAccID" placeholder="Select" [(ngModel)]="internalFundingAccountTypeID"></ng-select>

                            </div>


                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <label>Amount</label>
                            </div>
                            <div class="col-md-5" style="margin-bottom:7px;">

                                <span class="badge secondery">
                                    {{DealDetailData?.clientSellCurrency}}
                                    {{DealDetailData.clientSellAmount | number:'1.2-2'}}

                                </span>
                            </div>
                        </div>

                        <hr />
                        <div class='row'>
                            <div class="col-md-3">
                                <label>Navinci Funding Detail</label>

                            </div>
                            <div class="col-md-5">
                                <a (click)='DownloadFundPDF(DealDetailData?.tradeID)'
                                    title='Download Funding detail PDF'><img src="../../../../assets/PDF.png"
                                        style="height:30px;width:30px;" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>


    </div>

    <div class="inline-element" style="margin-top : 10px;">


        <button id="btnNPConfirm" type="button" class="btn btn-success " value="Quote" (click)="redirectToPayment('Confirm')">Confirm </button>

        <button id="btnNPTBA" type="button" class="btn btn-success " value="Quote" (click)="redirectToPayment('Skip')"
            *ngIf="paymentOption==='Deliver'" >Skip Payment detail</button>


    </div>


</div>