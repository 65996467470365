<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">
      {{loadingMessage}}...
    </div>
  </div>
</div>

<div *ngIf="canView">
  <app-upload-file [clientId]="clientId" [canAddEdit]="canAddEdit" (isNewFileUpload)="fileUpload($event)"></app-upload-file>
  
  <div class="address-block mt-4">
    <h6>Uploaded Attachments</h6>
    <div class="mb-3">
      <button type="button"  class="btn btn-outline-secondary" (click)="openModal()">Email</button>
      <button type="button"  class="btn btn-outline-secondary ml-2"
        (click)="downloadClientAttachments(clientFileIds)">
        Download
      </button>
      <button type="button" [disabled]="!canAddEdit" class="btn btn-outline-danger ml-2" (click)="detachFiles(clientFileIds)">
        Detach
      </button>
    </div>
    <div *ngIf="attachments.length > 0; else notFound" class="GridClass custom-container">
      <div class="table-responsive">
        <table class="table custom-grid centered-content">
          <colgroup>
            <col style="width: 7%" />
            <col style="width: 28%" />
            <col style="width: 20%" />
            <col style="width: 20%" />
            <col style="width: 25%" />
          </colgroup>
          <thead>
            <tr class="Row123">
              <th>
                <input #checkboxes type="checkbox" id="isSelected" (change)="toggleAllCheckbox($event)"/>
              </th>
              <th>
                <a (click)="sortTable('address')" class="sort active ts-asc" unselectable="on" data-i="0">Document
                  Name<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a (click)="sortTable('city')" class="sort active ts-asc" unselectable="on" data-i="1">Description<span
                    class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a (click)="sortTable('province')" class="sort active ts-asc" unselectable="on" data-i="2">Uploaded
                  By<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a (click)="sortTable('zip')" class="sort active" unselectable="on" data-i="3">
                  Uploaded Date<span class="ts-sord"></span>
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of paginatedData" >
              <td><input #checkboxes type="checkbox"  [checked]="allChecked" (change)="fileChecked($event, row)" /></td>
              <td class="fileName ">{{displayRowDetails(row.fileName)}}</td>
              <td>{{ row.description }}</td>
              <td>{{ row.createdByUserFullName }}</td>
              <td>{{ convertUTCToLocalWithTimezone(row.creationDate) | date : "dd/MM/yyyy h:mm a"  }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="attachments.length" [pageSize]="pageSize"
        [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <ng-template #notFound>
    <div class="alert alert-info">
      <span>No record found</span>
    </div>
  </ng-template>
</div>

<div *ngIf="!canView" class="notHavePermission">
  <h3>You don't have permission for this View</h3>
</div>