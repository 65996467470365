<!-- <app-header></app-header> -->

<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            {{loaderText}}
        </div>
    </div>
</div>

<div [ngClass]="{'collapseNav':!isNavBarOpen}">
    <div class="inner user-page">
        <div class="title">
            <span class="titleHeader mt-2">Manage Sales Margin</span>
            <h3>&nbsp;</h3>
            <a *ngIf="!hideAddButton" id="btnClientAddNew" class="btn btn-success" (click)="openModal()">
                <em><img src="../../../assets/plus.svg" alt=""></em>
                Add
            </a>
        </div>

        <div class="card">
            <div *ngIf="clients.length>0;else notFound" class="GridClass custom-container">
                <div class="table-responsive" >
                    <table class="table custom-grid">
                        <colgroup>
                            <col style="width: 14%">
                            <col style="width: 14%">
                            <col style="width: 14%">
                            <col style="width: 14%">
                            <col style="width: 14%">
                            <col style="width: 15%">
                            <col style="width: 15%">
                        </colgroup>

                        <thead>
                            <tr class="Row123">
                                <th><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="0">Tier<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="1">Spot<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  " unselectable="on"
                                        data-i="3">Forward<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active" unselectable="on"
                                        data-i="4">Predelivery<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  " unselectable="on"
                                            data-i="3">Extension<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active" unselectable="on"
                                            data-i="4">Options<span class="ts-sord"></span> </a></th>
                                <th><a class="sort" unselectable="on" data-i="8">
                                        <span class="action">Action</span>
                                    </a></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td>{{ row.tierName }}</td>
                                <td>{{ row.spot }}</td>
                                <td>{{ row.forward }}</td>
                                <td>{{ row.predelivery }}</td>
                                <td>{{ row.extension }}</td>
                                <td>{{ row.options }}</td>
                                <td>
                                    <div class="action-btns">
                                        <a style="cursor: pointer;" title="Edit" (click)="editSalesMargin(row.id)"
                                          ><img
                                            src="../../../assets/edit.svg"
                                            alt=""
                                        /></a>
                                      </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>                                    
                </div>
                <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex" aria-label="Select page">
            </mat-paginator>  
            </div>
        </div>
    </div>


    <div class="modal fade backdrop" #savesale id="save-sales-margin" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style="max-height: 500px !important;">
            <div class="modal-header">
              <h1 class="modal-title" id="staticBackdropLabel">{{modalTitle}}</h1>
              <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                <!-- <span aria-hidden="true">&times;</span> -->
                <span aria-hidden="true"><img src="../../../assets/cross.svg" alt=""></span>
              </button>
            </div>

            <form [formGroup]="salesMarginForm" (submit)="saveSalesMargin()"> 
                <div class="modal-body">    
                        <div class="row">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Tier <em style="color: red">*</em></label>
                            </div>
                            <ng-container *ngIf="!editMode;else label">
                                <div class="col-md-9">
                                    <input type="text" formControlName="tierName" class="form-control" placeholder="Tier Name" minlength="1" maxlength="75"/>
                                    <span class="text-danger" *ngIf="(salesMarginForm.controls['tierName'].touched || submitted) && salesMarginForm.controls['tierName'].errors?.required">
                                        Tier Name is required.
                                    </span>
                                    <span class="text-danger" *ngIf="(salesMarginForm.controls['tierName'].touched || submitted) && salesMarginForm.controls['tierName'].errors?.minLength">
                                        Tier Name have minimum one character.
                                    </span>
                                    <span class="text-danger" *ngIf="(salesMarginForm.controls['tierName'].touched || submitted) && salesMarginForm.controls['tierName'].errors?.maxLength">
                                        Tier Name have maximum 75 characters.
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #label>
                                <div class="col-md-9">
                                    <label class="strong labelVertical" style="font-size: 17px;">{{salesMarginForm.get('tierName')?.value}}</label>
                                </div>
                                
                            </ng-template>
                        </div>
    
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Spot</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="spot" class="form-control" placeholder="Spot"/>

                                <ng-container class="remove-space" *ngIf="(salesMarginForm.controls['spot'].touched || submitted || salesMarginForm.controls['spot'].dirty)">
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['spot'].errors?.min">
                                        Miminum spot value is 0.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['spot'].errors?.max">
                                        Maximum spot value is 100.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['spot'].errors?.pattern">
                                        Spot only contains decimal values.
                                    </p>
                                </ng-container>                               
                                
                            </div>
                        </div>                        
    
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Forward</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="forward" class="form-control" placeholder="Forward" pattern="^[0-9]*(?:\.[0-9]*)?$"/>
                                <ng-container class="remove-space" *ngIf="(salesMarginForm.controls['forward'].touched || submitted || salesMarginForm.controls['forward'].dirty)">
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['forward'].errors?.min">
                                        Miminum forward value is 0.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['forward'].errors?.max">
                                        Maximum forward value is 100.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['forward'].errors?.pattern">
                                        Forward only contains decimal values.
                                    </p>
                                </ng-container>
                            </div>
                        </div>
    
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Predelivery</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="predelivery" class="form-control" placeholder="Predelivery" pattern="^[0-9]*(?:\.[0-9]*)?$"/>
                                <ng-container class="remove-space" *ngIf="(salesMarginForm.controls['predelivery'].touched || submitted || salesMarginForm.controls['predelivery'].dirty)">
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['predelivery'].errors?.min">
                                        Miminum predelivery value is 0.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['predelivery'].errors?.max">
                                        Maximum predelivery value is 100.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['predelivery'].errors?.pattern">
                                        Predelivery only contains decimal values.
                                    </p>
                                </ng-container>
                            </div>
                        </div>
    
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Extension</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="extension" class="form-control" placeholder="Extension" pattern="^[0-9]*(?:\.[0-9]*)?$"/>
                                <ng-container class="remove-space" *ngIf="(salesMarginForm.controls['extension'].touched || submitted || salesMarginForm.controls['extension'].dirty)">
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['extension'].errors?.min">
                                        Miminum Extension value is 0.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['extension'].errors?.max">
                                        Maximum Extension value is 100.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['extension'].errors?.pattern">
                                        Extension only contains decimal values.
                                    </p>
                                </ng-container>
                            </div>
                        </div>
    
                        <div class="row mt-3">
                            <div class="col-md-3">
                                <label class="strong labelVertical">Options</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="options" class="form-control" placeholder="Options" pattern="^[0-9]*(?:\.[0-9]*)?$"/>
                                <ng-container class="remove-space" *ngIf="(salesMarginForm.controls['options'].touched || submitted || salesMarginForm.controls['options'].dirty)">
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['options'].errors?.min">
                                        Miminum options value is 0.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['options'].errors?.max">
                                        Maximum options value is 100.00
                                    </p>
                                    <p class="remove-space text-danger" *ngIf="salesMarginForm.controls['options'].errors?.pattern">
                                        options only contains decimal values.
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-success" [disabled]="((!isValueChanged && editMode) || salesMarginForm.invalid)">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
</div>

<ng-template #notFound>
    <div class="alert alert-info">
        <span>No record found</span>
    </div>
</ng-template>