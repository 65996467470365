<div class="row" *ngIf="newsItems.length>0;else notfound">

    <div class="col-md-6 col-sm6 moreBox" *ngFor="let newsFeed of newsItemsToShow">
        <div class="news">
            <div class="news-header">
                <h6>
                    <a href="{{newsFeed.link}}" target="_blank">{{newsFeed.title}}</a>
                </h6>
                <p>{{newsFeed.pubDate}}</p>
            </div>
            <div class="content-block">

                <div class="content">
                    <p>{{newsFeed.description}}</p>
                </div>
            </div>
        </div>
    </div>

</div>
<div style="display: flex; justify-content: flex-end">
    <a (click)="viewLess()"  id="loadLess" class="btn btn-outline-secondary btn-sp">
        View less
    </a> &nbsp;&nbsp;
    <a (click)="viewMore()" id="loadMore" class="btn btn-outline-secondary btn-sp">
        View more
    </a>
</div>

<ng-template #notfound>
    <p>News Details not available..</p>
</ng-template> 

