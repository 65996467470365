import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RelationshipManager, RequestQuote } from '../../components/spot-deal/models/spotdeal.model';
@Injectable({
  providedIn: 'root',
})
export class SpotDealService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRelationshipmanager(): Observable<RelationshipManager[]> {
    let url: string = this.apiUrl + 'Quote/GetRMs';
    return this.http.get<RelationshipManager[]>(url);
  }
  getClients(managerID: number | null): Observable<any> {
    let url=this.apiUrl+'Quote/GetClientsOfRM';    
    if(managerID)
    url =  url+'?managerID=' + managerID;   
    return this.http.get(url);
  }
  getForwardList(clientId: number): Observable<any> {
    let url = this.apiUrl + 'Quote/Admin/GetSpotForwardLists?clientID=' + clientId;
    return this.http.get(url);
  }
  convertPercentageTics(payload: any): Observable<any> {
    let url = this.apiUrl + 'Quote/ConvertPerCentageTics';
    return this.http.post(url, payload);
  }

  checkIsPartner(clientID: number) {
    let url = this.apiUrl + 'Client/Trading/' + clientID;
    return this.http.get(url);
  }

  calculateSalesMarginAmount(payload: any) {
    let url = this.apiUrl + 'Quote/GetSalesMarginAmount';
    return this.http.post(url, payload);
  }

  getCurrencyPairId() {
    return this.http.get(this.apiUrl + 'CurrencyPair');
  }

  requestQuote(payload: any, name: string) :Observable<RequestQuote> {
    return this.http.post<RequestQuote>(this.apiUrl + `Quote/${name}/RequestQuote`, payload);
  }

  getForwardListForClient(name: string): Observable<any> {
    let url = this.apiUrl + `Quote/${name}/GetSpotForwardLists`;
    return this.http.get(url);
  }
}
