import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: OnlyNumberDirective, multi: true }]
})
export class OnlyNumberDirective {

  constructor() { }

  validate(control: FormControl) {
    if (control.value) {
      const numberRegex: RegExp = /^[0-9]+$/;
      const valid: boolean = numberRegex.test(control.value);
      return valid ? null : { invalid: true };
    } else {
      return null;
    }
  }

}
