import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ManageTenantsService } from '../../services/ManageTenants/manage-tenants.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-manage-tenants',
  templateUrl: './manage-tenants.component.html',
  styleUrl: './manage-tenants.component.css'
})
export class ManageTenantsComponent implements OnInit {
  hideAddButton: boolean = false;
  hideEditButton: boolean = false;
  isNavBarOpen: boolean = true;
  loading: boolean = false;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  disableSuperAdmin: boolean = true;

  pageEvent: PageEvent | undefined;

  sortedColumn: string = '';
  isAscending: boolean = true;

  searchTenantName: string = '';
  searchPhone: string = '';
  searchEmail: string = '';
  selectedStatus: string = 'Both';

  paginatedData: any[] = [];
  tenantData: any[] = [];

  constructor(private manageTenantService: ManageTenantsService,
    private encryptPermission: EncryptRolePermissionService,
    private router:Router
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 26
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }

      if (pagePermission && (pagePermission.canUpdate === 0 && pagePermission.canInsert == 0)) {
        this.hideEditButton = true;
      } else {
        this.hideEditButton = false;
      }
    }
  }

  ngOnInit(): void {
    this.search();
  }

  sort(columnName: string): void {
    this.tenantData.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {


    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.tenantData.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.tenantData.slice(startIndex, endIndex);

  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.tenantData.slice(startIndex, endIndex);
  }

  onRadioChange(status: string) {
    this.selectedStatus = status;
  }

  toggleSwitch(tenant: any) {
    tenant.isActive = !tenant.isActive;
    this.manageTenantService.setPrimaryTenantFlag(tenant.tenantID, tenant.isActive).subscribe({
      next: () => {
        this.search();
      },
      error: () => {
      },
    });
  }


  getTenantData(selectedStatus: string, searchTenantName: string, searchPhone: string, searchEmail: string) {
    const payload = {
      status: selectedStatus,
      tenantName: searchTenantName,
      phone: searchPhone,
      email: searchEmail
    };
    this.loading = true;
    this.manageTenantService.tenantSearch(payload).subscribe({
      next: (data: any) => {
        this.tenantData = data;
        this.length = this.tenantData.length;
        this.paginatedData = this.tenantData.slice(0, this.pageSize);
      },
      error: (e) => {

      },
      complete: () => {
        this.loading = false;
      }
    })

  }

  search() {
    this.getTenantData(this.selectedStatus, this.searchTenantName, this.searchPhone, this.searchEmail);

  }

  reset(){
    this.searchEmail='';
    this.searchPhone='';
    this.searchTenantName='';
    this.selectedStatus='Both';
    this.search();
  }

  addTenant(){
    this.router.navigate(['/Admin/addedittenant']);
  }

  editTenant(tenantID:number){
    // this.router.navigate(['/addeditenant',tenantID]);
    this.router.navigate(['/Admin/addeditenant',tenantID]);
  }

}
