import { Directive,  ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCommaSeprater]'
})
export class CommaSepraterDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/[^0-9.]/g, ''); 
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); 


    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 4);
    }

    value = parts.join('.');

    input.value = value;
  }

}
