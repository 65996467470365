<div class="tradingview-widget-container tradingview-widget-light">

    <div class="tradingview-widget-container__widget"></div>

    <div class="tradingview-widget-copyright">

        <a href="https://www.tradingview.com/markets/currencies/" rel="noopener" target="_blank"><span
                class="blue-text">Forex</span></a> by TradingView

    </div>

</div>