import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getusertypes(): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/getusertypes';
    return this.http.get<any>(url);
  }
  getuserstatuses(): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/getuserstatuses';
    return this.http.get<any>(url);
  }
  getthemes(): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/getthemes';
    return this.http.get<any>(url);
  }
  getlocations(): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/getlocations';
    return this.http.get<any>(url);
  }
  getDropdownLists(): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/getlists';
    return this.http.get<any>(url);
  }
  checkEmail(inputEmail: string): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/validateemail';
    let payLoad = {
      email: inputEmail,
      userID: 0,
    };
    return this.http.post<any>(url, payLoad);
  }
  checkUsername(inputUsername: string): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/validateusername';
    let payLoad = {
      userID: 0,
      username: inputUsername,
    };
    return this.http.post<any>(url, payLoad);
  }
  addNewUser(userForm: any): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/add';
    return this.http.post<any>(url, userForm);
  }
  updateUser(userForm: any): Observable<any> {
    let url = this.apiUrl + 'ManageUsers/update';
    let payLoad = {
      userID: userForm.userID,
      firstName: userForm.firstName,      
      lastName: userForm.lastName,
      phoneNumber: userForm.phoneNumber,
      emailAddress: userForm.emailAddress,
      userName: userForm.userName,
      roleID: userForm.roleID,
      themeID: userForm.themeID,
      userType: userForm.userType,
      tenantID: userForm.tenantID,
      locationID: userForm.locationID,
      managerID: userForm.managerID,
      carPartnerID: userForm.carPartnerID,
      status: userForm.status,
    };    
    return this.http.patch<any>(url, payLoad);
  }
  getUserDetails(userID:number):Observable<any>{
    let url = this.apiUrl + 'ManageUsers?userID='+userID;
    return this.http.get<any>(url);
  }
}
