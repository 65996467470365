import { Component, OnInit } from '@angular/core';
import { ManageCurrenciesService } from '../../services/ManageCurrencies/manage-currencies.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ManageCurrencies, SearchParams } from './models/manage-currencies.model';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { ModulePermission } from '../global-models/module-permission.model';
import { Router } from '@angular/router';
import { AdminInfo } from '../models/common.model';
import { SystemConstant } from '../../global/global.constant';


@UntilDestroy()
@Component({
  selector: 'app-manage-currencies',
  templateUrl: './manage-currencies.component.html',
  styleUrl: './manage-currencies.component.css'
})
export class ManageCurrenciesComponent implements OnInit {
 
  manageCurrenciesList: ManageCurrencies[] = [];
  displayedColumns: string[] = ['CurrencyName', 'CurrencyAbbreviation', 'CurrencySymbol', 'CountryName'];
  isNavBarOpen: boolean = true;
  isLoading: boolean = false;
  paginatedData: ManageCurrencies[] = [];
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;
  pageIndex = 0;
  pageEvent: PageEvent | undefined;
  showPageSizeOptions = true;
  manageCurrenciesListLength: number = 0;
  searchParams: SearchParams = {
    currencyName: '',
    currencyAbbreviation: ''
  };
  manageCurrenciesPermission: ModulePermission | undefined;
  showFirstLastButtons = true;
  canInsert: number = 1;
  canUpdate: number = 1;
  isNavinciUser = false;

  constructor (
    private manageCurrenciesService: ManageCurrenciesService,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.checkForNavinciUser();
  }
  
  private checkForNavinciUser = (): void => {
    var savedLogin: AdminInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    if (savedLogin.userType == SystemConstant.Navinci) {
      this.isNavinciUser = true;
      this.checkModulePermission();   
    } else {
      this.isNavinciUser = false;
    }
  }

  private checkModulePermission = (): void => {
    const modulePermission = this.encryptPermission.getDecryptedPermissions('roleNgp').modulePermissions;
    if (modulePermission) {
      this.manageCurrenciesPermission = modulePermission.find((x: { pageID: number; })=> x.pageID == 5);
      this.canInsert = this.manageCurrenciesPermission?.canInsert !== undefined ? this.manageCurrenciesPermission?.canInsert :0;
      this.canUpdate = this.manageCurrenciesPermission?.canUpdate !== undefined ? this.manageCurrenciesPermission?.canUpdate :0;
      if (this.manageCurrenciesPermission && this.manageCurrenciesPermission.canView == 1) {
        const params: SearchParams = {
          currencyAbbreviation: "",
          currencyName: ""
        }
        this.getManageCurrenciesList(params);
      }
    }
  }

  private getManageCurrenciesList = (searchParams: SearchParams): void => {
    this.isLoading = true;
    this.manageCurrenciesService.getManageCurrencyList(searchParams).pipe(untilDestroyed(this)).subscribe({
      next: (response: ManageCurrencies[]) => {
        this.manageCurrenciesList = response;
        this.manageCurrenciesListLength = response.length;
        this.paginatedData = response.slice(0, this.pageSize);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        Swal.fire({
          icon: 'warning',
          title: "Something goes wrong.",
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500
        });
      }
    });
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageEvent = pageEvent;
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.manageCurrenciesList.slice(startIndex, endIndex);
  }

  searchManageCurrencies = (): void => {
    const params: SearchParams = {
      currencyAbbreviation: this.searchParams.currencyAbbreviation,
      currencyName: this.searchParams.currencyName
    }
    this.getManageCurrenciesList(params);
  }

  resetSearchParams = (): void => {
    this.searchParams.currencyAbbreviation = "";
    this.searchParams.currencyName = "";
    this.searchManageCurrencies();
  }

  addManageCurrencies = (): void => {
    if(this.manageCurrenciesPermission && this.manageCurrenciesPermission?.canInsert == 1) {
     this.router.navigate(['Admin/managecurrency']);
    } else {
      Swal.fire({
        icon: 'warning',
        title: "You don't have permission to access this page.",
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500
      });
    }
  }

  openEditManageCurrency = (currencyID: number): void => {
    this.router.navigate(['Admin/managecurrency', currencyID]);
  }

}
