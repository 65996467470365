<div *ngIf="isLoading">
    <div class="loader-block">
        <div class="inner-loader"></div>
    </div>
</div>
<div class="compress-inputs-page">
    <div class="inner secondary">
        <form [formGroup]="userProfileForm" class="form">
            <div class="card">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Salutation</label>
                            <div class="value-wrapper">
                                <input formControlName="salutation" class="form-control" placeholder="Salutation">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">First Name</label>
                            <div class="value-wrapper">
                                <input formControlName="firstName" class="form-control" placeholder="First Name">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Last Name</label>
                            <div class="value-wrapper">
                                <input formControlName="lastName" class="form-control" placeholder="Last Name">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Alias</label>
                            <div class="value-wrapper">
                                <input formControlName="alias" class="form-control" placeholder="Alias">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Initial</label>
                            <div class="value-wrapper">
                                <input formControlName="initial" class="form-control" placeholder="Initial">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Designation</label>
                            <div class="value-wrapper">
                                <input formControlName="designation" class="form-control" placeholder="Designation">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Email</label>
                            <div class="value-wrapper">
                                <input formControlName="emailAddress" class="form-control" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Phone</label>
                            <div class="value-wrapper">
                                <input formControlName="phoneNumber" class="form-control" placeholder="Phone">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Status</label>
                            <div class="value-wrapper">
                                <input formControlName="status" class="form-control" placeholder="Status">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">User Name </label>
                            <div class="value-wrapper">
                                <input formControlName="userName" class="form-control" placeholder="User Name ">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label>Is Active </label>
                            <div class="client custom-switch">
                                <input formControlName="isActive" type="checkbox" class="switch-control-input" />
                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="FW600">Theme </label>
                            <div class="value-wrapper">
                                <div class="showhide">
                                    <input formControlName="themeID" class="form-control" placeholder="Theme ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>