<!-- <app-header></app-header> -->
<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">Loading...</div>
  </div>
</div>


<div [ngClass]="{ collapseNav: !isNavBarOpen }">
  <div class="inner user-page">
    <div class="title">
      <span class="titleHeader mt-2">Manage Users</span>
      <h3>&nbsp;</h3>
      <a *ngIf="!hideAddButton" id="btnClientAddNew" (click)="addManageUser()" class="btn btn-success">
        <em
          ><img src="../../../assets/plus.svg" alt=""
        /></em>
        Add
      </a>
    </div>
    <div class="card maindiv">
      <div class="row search-criteria ">
        <div class="col-md-3 col-sm-3">
          <div class="form-group ">
            <label class="textstyle">Name/Email/Phone/Tenant</label>
            <input
              type="text"
              class="form-control"
              id="txtRoleName"
              tabindex="1"
              [(ngModel)]="searchString"
              (keydown.enter)="search()"
            />
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="form-group">
            <label class="textstyle">Role</label>
            <ng-select
              [items]="roleList"
              [closeOnSelect]="false"
              [searchable]="true"
              [(ngModel)]="filterRoleId"
              #roleIdComponent
              bindLabel="roleName"
              bindValue="roleID"
              placeholder="Select"
              (keydown.enter)="search()"
              (keydown)="closeDropdwon($event)"
              (click)="closeDropdwon($event)"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="form-group ">
            <label class="textstyle">Status</label>
            <ng-select
              [items]="status"
              [closeOnSelect]="false"
              [searchable]="true"
              [(ngModel)]="filterStatus"
              #statusComponent
              bindLabel="statusName"
              bindValue="statusID"
              placeholder="Select"
              (keydown.enter)="search()"
              (keydown)="closeDropdwon($event)"
              (click)="closeDropdwon($event)"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="inline-element" style="padding-top: 25px">
            <a class="btn btn-success" id="btnSearch" (click)="search()"
              >Search</a
            >
            <a class="btn btn-secondary" id="btnReset" (click)="reset()"
              >Reset</a
            >
          </div>
        </div>
      </div>

      <div class="nth-last-child-2-center"></div>

      <div *ngIf="paginatedData.length>0;else notFound" class="GridClass custom-container">
        <div class="table-responsive">
          <table class="table custom-grid">
            <colgroup>
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 15%" />
              <col style="width: 25%" />
              <col style="width: 20%" />
              <col style="width: 15%" />
              <col style="width: 10%" />
            </colgroup>

            <thead>
              <tr class="Row123 textstyle">
                <th>
                  <a
                    class="sort active ts-asc"
                    (click)="sortTable('fullName')"
                    unselectable="on"
                    data-i="0"
                    >Name<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active ts-asc"
                    (click)="sortTable('phoneNumber')"
                    unselectable="on"
                    data-i="1"
                    >Phone Number<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('roleName')"
                    unselectable="on"
                    data-i="3"
                  >
                    Role<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('tenantCarPartner')"
                    unselectable="on"
                    data-i="4"
                    >Tenant / CAR Partner<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('lastLogin')"
                    unselectable="on"
                    data-i="4"
                    >Last Login On (UTC)<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('status')"
                    unselectable="on"
                    data-i="4"
                    >Status<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a class="sort" unselectable="on" data-i="8">
                    <span class="action">Action</span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of paginatedData">
                <td>{{ row.fullName }}</td>
                <td>{{ row.phoneNumber }}</td>
                <td>{{ row.roleName }}</td>
                <td>{{ row.tenantCarPartner }}</td>
                <td>{{ row.lastLogin | date : "dd/MM/yyyy hh:mm a" }}</td>
                <td>
                  <span
                    class="badge"
                    [class.badge-success]="row.status == 'Active'"
                    [class.badge-inactive]="row.status == 'Inactive'"
                    [class.badge-warning]="row.status == 'Pending Authorization'"
                  >
                    {{ row.status }}
                  </span>
                </td>
                <td>
                  <div class="action-btns">
                    <a style="cursor: pointer;" (click)="editManageUser(row.userID)" title="Edit"
                      ><img
                        src="../../../assets/edit.svg"
                        alt=""
                    /></a>
                  </div>
                </td>
              </tr>              
            </tbody>
          </table>
         
        </div>
        <mat-paginator
        #paginator
        (page)="handlePageEvent($event)"
        [length]="length"
        [pageSize]="pageSize"
        [disabled]="disabled"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex"
        aria-label="Select page"
      >
      </mat-paginator>
      </div>
      <ng-template #notFound>
        <div class="alert alert-info">
            <span>No record found</span>
        </div>
        </ng-template>
    </div>
  </div>
</div>
