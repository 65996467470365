import { NgSwitchCase } from '@angular/common';
import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { RolePermissonService } from '../../../services/Permission/role-permisson.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
})
export class NavigationComponent implements OnInit, OnDestroy {
  roleData: any;
  roleID: number = 13;
  hide: boolean = true;
  viewFlags: boolean[] = new Array(40).fill(true);
  hasMenuflag: boolean[] = new Array(7).fill(false);
  constructor(
    private roleService: RolePermissonService,
    private encryptPermission: EncryptRolePermissionService
  ) {
    this.roleData = encryptPermission.getDecryptedPermissions('roleNgp');
  }
  ngOnInit(): void {   
    this.setRolePermission();
  }

  isCollapsed: boolean = false;
  isPayee: boolean = true;
  isMenuOpen: boolean[] = Array(7).fill(false);
  isNavOpen: boolean = false;
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }
  toggleMenu(index: number): void {
    switch (index) {
      case 0:
        this.isMenuOpen[0] = !this.isMenuOpen[0];
        break;
      case 1:
        this.isMenuOpen[1] = !this.isMenuOpen[1];
        break;
      case 2:
        this.isMenuOpen[2] = !this.isMenuOpen[2];
        break;
      case 3:
        this.isMenuOpen[3] = !this.isMenuOpen[3];
        break;
      case 4:
        this.isMenuOpen[4] = !this.isMenuOpen[4];
        break;
      case 5:
        this.isMenuOpen[5] = !this.isMenuOpen[5];
        break;
      case 6:
        this.isMenuOpen[6] = !this.isMenuOpen[6];
        break;
    }
  }
  setRolePermission() {
    if (this.roleData != null) {
      if (
        this.roleData.modulePermissions &&
        this.roleData.modulePermissions.length > 0
      ) {
        for (const permission of this.roleData.modulePermissions) {
          const pageID = permission.pageID;
          const canView = permission.canView;
          if (canView == 1) {
            this.viewFlags[pageID] = false;
          }
        }

        if (this.viewFlags[3] && this.viewFlags[4]) {
          this.hasMenuflag[0] = true;
        }
        if (
          this.viewFlags[5] &&
          this.viewFlags[6] &&
          this.viewFlags[7] &&
          this.viewFlags[8] &&
          this.viewFlags[10] &&
          this.viewFlags[11] &&
          this.viewFlags[12] &&
          this.viewFlags[14] &&
          this.viewFlags[13] &&
          this.viewFlags[15] &&
          this.viewFlags[18] &&
          this.viewFlags[19] &&
          this.viewFlags[22] &&
          this.viewFlags[26] &&
          this.viewFlags[36]
        ) {
          this.hasMenuflag[1] = true;
        }
        if (this.viewFlags[1] && this.viewFlags[2]) {
          this.hasMenuflag[2] = true;
        }
        if (this.viewFlags[30] && this.viewFlags[31] && this.viewFlags[33]) {
          this.hasMenuflag[3] = true;
        }
        if (this.viewFlags[9] && this.viewFlags[20]) {
          this.hasMenuflag[4] = true;
        }
        if (this.viewFlags[23] && this.viewFlags[24] && this.viewFlags[27]) {
          this.hasMenuflag[5] = true;
        }
        if (this.viewFlags[32] && this.viewFlags[34]) {
          this.hasMenuflag[6] = true;
        }
      }
    }
  }
  ngOnDestroy(): void {}
}
