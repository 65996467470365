import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component';
import { HeaderComponent } from './components/Home/header/header.component';
import { ClientGeneralComponent } from './components/client-general/client-general.component';
import { ManageclientsComponent } from './components/manageclients/manageclients.component';
import { ClientAccountGridComponent } from './components/client-account-grid/client-account-grid.component';
import { AddcontactComponent } from './components/addcontact/addcontact.component';
import { ManageContactsComponent } from './components/manage-contacts/manage-contacts.component';
import { ClientcrmComponent } from './components/clientcrm/clientcrm.component';
import { ClientContactsComponent } from './components/client-contacts/client-contacts.component';
import { TabnavigationComponent } from './components/tabnavigation/tabnavigation.component';
import { QuickQuoteComponent } from './components/quick-quote/quick-quote.component';
import { ClientSalesMarginTierComponent } from './components/client-sales-margin-tier/client-sales-margin-tier.component';
import { AddressComponent } from './components/address-tab/address.component';
import { ValidateOtpComponent } from './components/validate-otp/validate-otp.component';
import { DealHistoryComponent } from './components/deal-history/deal-history.component';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';
import { ClientaccountComponent } from './components/clientaccount/clientaccount.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { AddusersComponent } from './components/addUpdateUsers/addusers.component';
import { AddeditRoleComponent } from './components/addedit-role/addedit-role.component';
import { ManageSalesMarginComponent } from './components/manage-sales-margin/manage-sales-margin/manage-sales-margin.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ManageTenantsComponent } from './components/manage-tenants/manage-tenants.component';
import { ReferralPartnerComponent } from './components/Referral-Partner/referral-partner/referral-partner.component';
import { AddEditTenantComponent } from './components/add-edit-tenant/add-edit-tenant.component';
import { ManageCARPartnerComponent } from './components/manage-carpartner/manage-carpartner.component';
import { ReferralPrtnerTabnavigationComponent } from './components/referral-prtner-tabnavigation/referral-prtner-tabnavigation.component';
import { ReferralPartnerGeneralTabComponent } from './components/referral-partner-general-tab/referral-partner-general-tab.component';
import { DashboardComponent } from './components/Home/dashboard/dashboard.component';
import { AddEditCarPartnerComponent } from './components/add-edit-car-partner/add-edit-car-partner.component';
import { authGuard } from './Guards/AuthGuard/auth.guard';
import { DeltalimitComponent } from './components/deltalimit/deltalimit.component';
import { ReferredClientComponent } from './components/referred-client/referred-client.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { OptionHedgeComponent } from './common/components/option-hedge/option-hedge.component';
import { SpotDealComponent } from './common/components/spot-deal/spot-deal.component';
import { RHSCollarComponent } from './common/components/rhscollar/rhscollar.component';
import { ForwardHedgeComponent } from './common/components/forward-hedge/forward-hedge.component';
import { RHSknockOutCollarComponent } from './common/components/rhsknock-out-collar/rhsknock-out-collar.component';
import { VanillaOptionHedgeComponent } from './common/components/vanilla-option-hedge/vanilla-option-hedge.component';
import { LHSCollarComponent } from './common/components/lhscollar/lhscollar.component';
import { LHSKnockOutCollarComponent } from './common/components/lhsknock-out-collar/lhsknock-out-collar.component';

import { ViewBeneficiaryComponent } from './common/components/view-beneficiary/view-beneficiary.component';
import { ManageCurrencyPairsComponent } from './components/manage-currency-pairs/manage-currency-pairs.component';
import { AddBeneficiaryComponent } from './common/components/add-beneficiary/add-beneficiary.component';
import { AddEditCurrencyPairsComponent } from './components/add-edit-currency-pairs/add-edit-currency-pairs.component';

import { SpotDealDetailComponent } from './common/components/spot-deal-detail/spot-deal-detail.component';
import { ForwardDealDetailComponent } from './common/components/forward-deal-detail/forward-deal-detail.component';
import { SummaryDealDetailsComponent } from './common/components/summary-deal-details/summary-deal-details.component';
import { ResetPasswardComponent } from './client/components/reset-passward/reset-passward.component';
import { VerifyEmailAdminComponent } from './components/verify-email-admin/verify-email-admin.component';
import { ManageCurrenciesComponent } from './components/manage-currencies/manage-currencies.component';
import { ManageCurrencyComponent } from './components/manage-currency/manage-currency.component';
import { ReportsComponent } from './common/components/reports/reports.component';
import { OpenDealSFComponent } from './common/components/open-deal-sf/open-deal-sf.component';
import { ConfirmationReportsComponent } from './common/components/confirmation-reports/confirmation-reports.component';
import { ClientTradingComponent } from './components/client-trading/client-trading.component';
import { AttachmentsTabComponent } from './components/attachments-tab/attachments-tab.component';
import { ClientNoteTabComponent } from './components/client-note-tab/client-note-tab.component';
import { BankingTabComponent } from './components/banking-tab/banking-tab.component';
import { ClientBeneficiaryComponent } from './components/client-beneficiary/client-beneficiary.component';
import { AuditLogComponent } from './components/auditLog/audit-log.component';

const routes: Routes = [
  { path: 'admin', component: SigninComponent, data: { isAdmin: true } },
  {
    path: 'Admin/Account/SignIn/:id',
    component: SigninComponent,
    data: { isAdmin: true },
  },
  {
    path: 'admin/dashboard',
    component: HeaderComponent,
    canActivate: [authGuard],
  },
  { path: 'validateotp', component: ValidateOtpComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  {
    path: 'Admin/Account/ResetPassword/:tokenID',
    component: ResetPasswardComponent,
  },  
  { path: 'Admin/VerifyEmail/:id', component: VerifyEmailAdminComponent },
  {
    path: 'Admin',
    component: DashboardComponent,
    title: 'NaviHedge - Home Page',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        component: QuickQuoteComponent,
        title: 'NaviHedge - Quick Quotes',
      },
      { path: 'Unauthorized', component: NotAuthorizedComponent },
      {
        path: 'viewBeneficiaries',
        component: ViewBeneficiaryComponent,
        title: 'NaviHedge - View Beneficiaries',
        data: {
          pageID: 23,
        },
      },
      {
        path: 'addBeneficiary',
        component: AddBeneficiaryComponent,
        title: 'NaviHedge - Add Beneficiaries',
        data: {
          pageID: 24,
        },
      },
      {
        path: 'quickquote',
        component: QuickQuoteComponent,
        title: 'NaviHedge - Quick Quotes',
        data: {
          pageID: 25,
        },
      },
      {
        path: 'openDealSF',
        component: OpenDealSFComponent,
        title: 'NaviHedge - OpenDeal SF',
      },

      {
        path: 'manageclient',
        component: ManageclientsComponent,
        title: 'NaviHedge - Manage Clients',
        data: {
          pageID: 3,
        },
      },
      {
        path: 'ManageCurrencies',
        component: ManageCurrenciesComponent,
        title: 'Manage Currencies',
        data: {
          PageID: 5,
        },
      },
      {
        path: 'managecurrency/:id',
        component: ManageCurrencyComponent,
        title: 'NaviHedge - Edit Currency',
      },
      {
        path: 'managecurrency',
        component: ManageCurrencyComponent,
        title: 'NaviHedge - Add Currency',
      },
      {
        path: 'managecontact',
        component: ManageContactsComponent,
        title: 'NaviHedge - Manage Contacts',
        data: {
          pageID: 4,
        },
      },
      { path: 'auditLogs', component: AuditLogComponent },

      {
        path: 'clientaccount',
        component: ClientaccountComponent,
        title: 'NaviHedge - Client Account',
        data: {
          pageID: 12,
        },
      },
      {
        path: 'ManageSalesMargin',
        component: ManageSalesMarginComponent,
        title: 'NaviHedge - Manage Sales Margin',
        data: {
          pageID: 22,
        },
      },

      {
        path: 'ManageUsers',
        component: ManageUsersComponent,
        title: 'NaviHedge - Manage User ',
        data: {
          pageID: 1,
        },
      },
      {
        path: 'manageroles',
        component: ManageRolesComponent,
        title: 'NaviHedge - Manage Roles',
        data: {
          pageID: 2,
        },
      },
      {
        path: 'ReferralPartner',
        component: ReferralPartnerComponent,
        title: 'NaviHedge - Referral Partner',
        data: {
          pageID: 31,
        },
      },
      {
        path: 'managetenants',
        component: ManageTenantsComponent,
        title: 'NaviHedge - Manage Tenants',
        data: {
          pageID: 26,
        },
      },
      {
        path: 'addedittenant',
        component: AddEditTenantComponent,
        title: 'NaviHedge - Add Tenants',
      },
      {
        path: 'addeditenant/:tenantId',
        component: AddEditTenantComponent,
        title: 'NaviHedge - Edit Tenants',
      },
      {
        path: 'manageCarpartner',
        component: ManageCARPartnerComponent,
        title: 'NaviHedge - Manage Car Partner',
        data: {
          pageID: 36,
        },
      },
      {
        path: 'addEditUser',
        component: AddusersComponent,
        title: 'NaviHedge - Add User',
      },
      {
        path: 'addEditUser/:userID',
        component: AddusersComponent,
        title: 'NaviHedge - Edit User',
      },
      {
        path: 'addEditRole',
        component: AddeditRoleComponent,
        title: 'NaviHedge - Add Roles',
      },
      {
        path: 'addEditRole/:roleID',
        component: AddeditRoleComponent,
        title: 'NaviHedge - Edit Roles',
      },

      {
        path: 'dealhistory',
        component: DealHistoryComponent,
        title: 'NaviHedge - Deal History',
        data: {
          pageID: 28,
        },
      },
      {
        path: 'getclienttab/:ClientID',
        component: TabnavigationComponent,
        title: 'NaviHedge - Add Client',
      },

      {
        path: 'addContact',
        component: AddcontactComponent,
        title: 'NaviHedge - Add Contacts',
      },

      {
        path: 'addContact/:contactId/:clientID',
        component: AddcontactComponent,
        title: 'NaviHedge - Edit Contacts',
      },

      { path: 'saveCarPartner', component: AddEditCarPartnerComponent },
      {
        path: 'saveCarPartner/:carPartnerId',
        component: AddEditCarPartnerComponent,
      },

      {
        path: 'getclienttab',
        component: TabnavigationComponent,
        children: [
          {
            path: '',
            component: ClientGeneralComponent,
            outlet: 'tabnavigation',
          },
        ],
      },

      {
        path: 'getclienttab/:ClientID',
        component: TabnavigationComponent,
        children: [
          {
            path: '',
            component: ClientGeneralComponent,
            pathMatch: 'full',
            outlet: 'tabnavigation',
          },
          {
            path: 'clientgeneral/:ClientID',
            component: ClientGeneralComponent,
            pathMatch: 'full',
            outlet: 'tabnavigation',
          },
          {
            path: 'clientcrm/:ClientID',
            component: ClientcrmComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'trading/:ClientID',
            component: ClientTradingComponent,
            outlet: 'tabnavigation',
          },

          {
            path: 'clientcontacts/:ClientID',
            component: ClientContactsComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'DeltaLimit/:ClientID',
            component: DeltalimitComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientaccountgrid/:ClientID',
            component: ClientAccountGridComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientaddress/:ClientID',
            component: AddressComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientsalesmargin/:ClientID',
            component: ClientSalesMarginTierComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'banking/:ClientID',
            component: BankingTabComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'attachments/:ClientID',
            component: AttachmentsTabComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientnotes/:ClientID',
            component: ClientNoteTabComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientBeneficiaries/:ClientID',
            component: ClientBeneficiaryComponent,
            outlet: 'tabnavigation',
          },
        ],
      },

      {
        path: 'referralPartnertab',
        component: ReferralPrtnerTabnavigationComponent,
        children: [
          {
            path: '',
            component: ReferralPartnerGeneralTabComponent,
            outlet: 'referralPartner',
          },
        ],
      },

      {
        path: 'referralPartnertab/:ReferralPartnerId',
        component: ReferralPrtnerTabnavigationComponent,
        children: [
          {
            path: '',
            component: ReferralPartnerGeneralTabComponent,
            pathMatch: 'full',
            outlet: 'referralPartner',
          },
          {
            path: 'referralPartnerGeneral/:ReferralPartnerId',
            component: ReferralPartnerGeneralTabComponent,
            pathMatch: 'full',
            outlet: 'referralPartner',
          },
          {
            path: 'referredClients/:ReferralPartnerId',
            component: ReferredClientComponent,
            outlet: 'referralPartner',
          },
        ],
      },

      {
        path: 'changepassword',
        component: ChangePasswordComponent,
        title: 'NaviHedge - Change Password',
      },
      {
        path: 'optionhedge',
        component: OptionHedgeComponent,
        title: 'Navihedge',
      },
      {
        path: 'SpotDeal',
        component: SpotDealComponent,
        title: 'SpotDeal',
        data: {
          pageID: 9,
        },
      },
      {
        path: 'RHSCollar/:clientId',
        component: RHSCollarComponent,
        title: 'Navihedge',
      },
      {
        path: 'ForwardHedge',
        component: ForwardHedgeComponent,
        title: 'ForwardHedge',
        data: {
          pageID: 21,
        },
      },
      {
        path: 'RHSKnockOutCollar/:clientId',
        component: RHSknockOutCollarComponent,
        title: 'Navihedge',
      },
      {
        path: 'Vanilla/:clientId',
        component: VanillaOptionHedgeComponent,
        title: 'Navihedge',
      },
      {
        path: 'LHSCollar/:clientId',
        component: LHSCollarComponent,
        title: 'navihedge',
      },
      {
        path: 'LHSKnockOutCollar/:clientId',
        component: LHSKnockOutCollarComponent,
        title: 'Navihedge',
      },
      {
        path: 'SpotDeal/DealDetail',
        component: SpotDealDetailComponent,
        title: 'Navihedge',
      },
      {
        path: 'ForwardHedge/DealDetail',
        component: ForwardDealDetailComponent,
        title: 'Navihedge',
      },
      {
        path: 'ManageCurrencyPair',
        component: ManageCurrencyPairsComponent,
        title: 'Navihedge',
      },
      {
        path: 'ForwardHedge/DealDetail',
        component: ForwardHedgeComponent,
        title: 'ForwardHedge',
        data: {
          pageID: 21,
        },
      },
      {
        path: 'addeditcurrencypair',
        component: AddEditCurrencyPairsComponent,
        title: 'NaviHedge - Add Tenants',
      },
      {
        path: 'addeditcurrencypair/:id',
        component: AddEditCurrencyPairsComponent,
        title: 'NaviHedge - Edit Tenants',
      },

      {
        path: 'SummaryDealDeatils',
        component: SummaryDealDetailsComponent,
        title: 'NaviHedge - PaymentSummary',
      },
      {
        path: 'reports',
        component: ReportsComponent,
        title: 'NaviHedge - Reports',
      },
      {
        path: 'confirmation-reports',
        component: ConfirmationReportsComponent,
        title: 'NaviHedge - Confirmation Reports',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
