import { Component } from '@angular/core';
import {
  CreditLimitScaleMaxLength,
  ErrorMessages,
  SpinnerMessage,
  TradingTabConstant,
} from '../../global/global.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TradingService } from '../../services/tradingtab/trading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import {
  ClientData,
  Country,
  FieldPermission,
  ListData,
  ReferredBy,
  StatementTier,
  TabPermission,
  TradeCurrencyPairs,
  UpdatePayload,
  products,
} from './models/trading.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-client-trading',
  templateUrl: './client-trading.component.html',
  styleUrl: './client-trading.component.css',
})
export class ClientTradingComponent {
  products: products[] = [];
  tradeCurrencyPair: TradeCurrencyPairs[] = [];
  referredBy: ReferredBy[] = [];
  disableTradeCPOrder: boolean = false;
  disableWidgetCPOrder: boolean = false;
  loaderMessage: string = SpinnerMessage.Loading;
  dataloaded: boolean = false;
  statementTiers: StatementTier[] = [];
  clientId: any;
  wiedgetCurrencyPair: TradeCurrencyPairs[] = [];
  destCountryOutPayment: Country[] = [];
  originCountryInPayment: Country[] = [];
  fieldPermissions: FieldPermission[] = [];
  tabPermissions: TabPermission[] = [];
  tradingFieldPermission: FieldPermission[] = [];
  tradingFildsViewFlag: boolean[] = [];
  currentTabPermission: TabPermission = {
    tabID: 0,
    canView: 0,
    canInsert: 0,
  };
  tabCanInsert: boolean = false;
  beforeKeyUpCreditLimitValue: number = 0;
  beforeKeyUpExpectedVolumePerAnnumValue: number = 0;

  constructor(
    private fb: FormBuilder,
    private tradingService: TradingService,
    private route: ActivatedRoute,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router,
    private sweetAlert: SweetalertService
  ) {}
  tradingForm: FormGroup = this.fb.group({
    clientID: [],
    clientAccountNumber: ['', [Validators.required]],
    creditLimit: [null],
    statementTierID: [0, [Validators.required]],
    clientProducts: [[], [Validators.required]],
    tradeCurrencyPair: [[], [Validators.required]],
    widgetCurrencyPair: [[]],
    isPartner: [false, [Validators.required]],
    referredByID: [null],
    expectedVolumePerAnnum: [null],
    expectedNoOfPaymentPerAnnum: [null],
    destCountryOutPayment: [[]],
    originCountryInPayment: [[]],
  });
  ngOnInit() {
    this.dataloaded = false;
    this.getrolePermission();
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientId = +parameterMap.get('ClientID')!;
      if (this.clientId) {
        this.tradingForm.controls['clientID'].setValue(this.clientId);
        this.getAllListData(this.clientId).then(() => {
          this.getClientDetails(this.clientId);
        });
      }
    });
  }
  isSaveButtonDisabled(): boolean {
    if (this.tabCanInsert) {
      return !this.tradingForm.valid;
    } else {
      return true;
    }
  }
  getrolePermission(): void {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      this.fieldPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).fieldPermissions;
      this.tabPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).tabPermissions;
      this.setRolePermission();
    }
  }
  setRolePermission() {
    let permission = this.tabPermissions.find(
      (tab: TabPermission) => tab.tabID == TradingTabConstant.tabId
    );
    if (permission && permission.canInsert == TradingTabConstant.disable) {
      this.tabCanInsert = false;
      this.tradingForm.reset();
      this.tradingForm.setErrors({ noPermission: true });
      this.tradingForm.disable();
    } else {
      this.tabCanInsert = true;
    }
    this.tradingFieldPermission = this.fieldPermissions.filter(
      (item: { fieldID: number }) =>
        (item.fieldID >= TradingTabConstant.firstFieldId &&
          item.fieldID <= TradingTabConstant.lastFieldId) ||
        (item.fieldID >= TradingTabConstant.firstFieldId2 &&
          item.fieldID <= TradingTabConstant.lastFieldId2) ||
        [
          TradingTabConstant.fieldID2,
          TradingTabConstant.fieldID4,
          TradingTabConstant.fieldID3,
        ].includes(item.fieldID)
    );
    this.tradingFieldPermission.forEach(
      (field: { fieldID: number; canView: number; canInsert: number }) => {
        switch (field.fieldID) {
          case 7:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[4] = true)
              : (this.tradingFildsViewFlag[4] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['creditLimit'].disable();
            }
            break;
          case 8:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[5] = true)
              : (this.tradingFildsViewFlag[5] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['clientProducts'].disable();
            }
            break;

          case 10:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[7] = true)
              : (this.tradingFildsViewFlag[7] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.disableTradeCPOrder = true;
              this.tradingForm.controls['tradeCurrencyPair'].disable();
            }
            break;
          case 11:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[8] = true)
              : (this.tradingFildsViewFlag[8] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.disableWidgetCPOrder = true;
              this.tradingForm.controls['widgetCurrencyPair'].disable();
            }
            break;
          case 12:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[9] = true)
              : (this.tradingFildsViewFlag[9] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['statementTierID'].disable();
            }
            break;

          case 16:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[10] = true)
              : (this.tradingFildsViewFlag[10] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['isPartner'].disable();
            }
            break;
          case 27:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[11] = true)
              : (this.tradingFildsViewFlag[11] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['referredByID'].disable();
            }
            break;
          case 30:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[12] = true)
              : (this.tradingFildsViewFlag[12] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['clientAccountNumber'].disable();
            }
            break;
          case 57:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[0] = true)
              : (this.tradingFildsViewFlag[0] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['expectedVolumePerAnnum'].disable();
            }
            break;
          case 58:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[1] = true)
              : (this.tradingFildsViewFlag[1] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls[
                'expectedNoOfPaymentPerAnnum'
              ].disable();
            }
            break;
          case 59:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[2] = true)
              : (this.tradingFildsViewFlag[2] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['destCountryOutPayment'].disable();
            }
            break;
          case 60:
            field.canView == TradingTabConstant.canView
              ? (this.tradingFildsViewFlag[3] = true)
              : (this.tradingFildsViewFlag[3] = false);
            if (field.canInsert == TradingTabConstant.disable) {
              this.tradingForm.controls['originCountryInPayment'].disable();
            }
            break;
        }
      }
    );
  }
  dropTradeCurrencyPair(event: CdkDragDrop<string[]>) {
    var currencyPair = this.tradingForm.value.tradeCurrencyPair;
    moveItemInArray(currencyPair, event.previousIndex, event.currentIndex);
    this.tradingForm.patchValue({
      tradeCurrencyPair: currencyPair,
    });
  }
  dropWiedgetTradeCurrencyPair(event: CdkDragDrop<string[]>) {
    var currencyPair = this.tradingForm.value.widgetCurrencyPair;
    moveItemInArray(currencyPair, event.previousIndex, event.currentIndex);

    this.tradingForm.patchValue({
      widgetCurrencyPair: currencyPair,
    });
  }
  getAllListData(clientId: number) {
    const promise = new Promise<void>((resolve, reject) => {
      this.tradingService
        .getAllListData(clientId)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response: ListData) => {
            this.statementTiers = response.statementTier;
            this.referredBy = response.referredBy;
            this.tradeCurrencyPair = Object.assign(
              [],
              response.tradeCurrencyPair
            );
            this.products = Object.assign([], response.products);
            this.wiedgetCurrencyPair = Object.assign(
              [],
              response.widgetCurrencyPair
            );
            this.destCountryOutPayment = response.destCountryOutPayment;
            this.originCountryInPayment = response.originCountryInPayment;
            resolve();
          },
          error: (e) => {},
        });
    });

    return promise;
  }
  cancel() {
    this.getClientDetails(this.clientId);
  }

  onSave() {
    this.dataloaded = false;
    if (this.tradingForm.valid) {
      let formvalue = this.tradingForm.getRawValue();
      let payload: UpdatePayload = {
        clientID: this.clientId,
        clientAccountNumber: formvalue.clientAccountNumber,
        creditLimit: formvalue.creditLimit == null || formvalue.creditLimit == "" ? null : formvalue.creditLimit.replace(/,/g, ''),
        statementTierID: formvalue.statementTierID,
        clientProducts: formvalue.clientProducts?.map((obj: any) => ({
          productID: obj.productID,
        })),

        tradeCurrencyPair: formvalue.tradeCurrencyPair?.map(
          (obj: any, index: number) => ({
            currencyPairID: obj.currencyPairID,
            orderNo: index + 1,
          })
        ),
        widgetCurrencyPair: formvalue.widgetCurrencyPair?.map(
          (obj: any, index: number) => ({
            currencyPairID: obj.currencyPairID,
            orderNo: index + 1,
          })
        ),
        isPartner: formvalue.isPartner,
        referralPartnerID: formvalue.referredByID,
        expectedVolumePerAnnum: formvalue.expectedVolumePerAnnum == null || formvalue.expectedVolumePerAnnum == "" ? null : formvalue.expectedVolumePerAnnum.replace(/,/g, ''),
        expectedNoOfPaymentPerAnnum: formvalue.expectedNoOfPaymentPerAnnum == null || formvalue.expectedNoOfPaymentPerAnnum == "" ? null : formvalue.expectedNoOfPaymentPerAnnum,
        destCountryOutPayment: formvalue.destCountryOutPayment?.map(
          (obj: any) => ({
            countryID: obj.countryID,
          })
        ),
        originCountryInPayment: formvalue.originCountryInPayment?.map(
          (obj: any) => ({
            countryID: obj.countryID,
          })
        ),
        applyUnlimitedTradeAccess: false,
        referredByID: 0
      };
      this.tradingService
        .updateTradingtab(payload)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (result: any) => {
            this.dataloaded = true;
            this.sweetAlert.fireAlert('Updated').then((_) => {});
          },
          error: (e) => {
            this.sweetAlert.showError(e, ErrorMessages.UpdateFailed);
            this.dataloaded = true;
          },
        });
    } else {
      this.dataloaded = true;
      this.markFormGroupTouched(this.tradingForm);
    }
  }

  private getClientDetails(clientId: number) {
    this.tradingService
      .getClientDetails(clientId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: UpdatePayload) => {
          this.dataloaded = true;
          if (result.creditLimit) {
            if (result.creditLimit.length <= 16) {
              result.creditLimit = this.formatNumberWithCommas(
                result.creditLimit
              );
            }
          }
          if (result.expectedVolumePerAnnum) {
            if (result.expectedVolumePerAnnum.toString().length <= 16) {
              result.expectedVolumePerAnnum = this.formatNumberWithCommas(
                result.expectedVolumePerAnnum
              );
            }
          }
          this.tradingForm.patchValue(result);
        },
      });
  }

  formatNumberWithCommas(value: string): string {
    let numberValue = typeof value === 'string' ? Number(value) : value;
    if (value)
      return numberValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    else return '';
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  saveCreditLimitValue = (): void => {
    this.beforeKeyUpCreditLimitValue =
      this.tradingForm.controls['creditLimit'].value;
  };

  checkCreditLimitValidation = (): void => {
    if (this.tradingForm.controls['creditLimit'].value) {
      const creditLimitValue: number = this.tradingForm.controls[
        'creditLimit'
      ].value
        .toString()
        .replace(/,/g, '');
      const splitCreditLimit = creditLimitValue.toString().split('.');
      if (splitCreditLimit && splitCreditLimit.length > 0) {
        const creditLimitMaxLength: number = splitCreditLimit[1] ? 26 : 30;
        if (
          splitCreditLimit[0] &&
          splitCreditLimit[0].length > creditLimitMaxLength
        ) {
          this.sweetAlert.showError(
            'error',
            ErrorMessages.CreditLimitMaxLengthMessage
          );
          this.tradingForm.controls['creditLimit'].setValue(
            this.beforeKeyUpCreditLimitValue
          );
          return;
        }
        if (
          splitCreditLimit[1] &&
          splitCreditLimit[1].length > CreditLimitScaleMaxLength
        ) {
          this.sweetAlert.showError(
            'error',
            ErrorMessages.CreditLimitScaleMaxLengthMessage
          );
          this.tradingForm.controls['creditLimit'].setValue(
            this.beforeKeyUpCreditLimitValue
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          );
          return;
        }
      }
    }
  };

  saveExpectedVolumePerAnnumValue = (): void => {
    this.beforeKeyUpExpectedVolumePerAnnumValue =
      this.tradingForm.controls['expectedVolumePerAnnum'].value;
  };

  checkExpectedVolumePerAnnumValidation = (): void => {
    if (this.tradingForm.controls['expectedVolumePerAnnum'].value) {
      const expectedVolumePerAnnumValue: number = this.tradingForm.controls[
        'expectedVolumePerAnnum'
      ].value
        .toString()
        .replace(/,/g, '');
      const splitExpectedVolumePerAnnum = expectedVolumePerAnnumValue
        .toString()
        .split('.');
      if (
        splitExpectedVolumePerAnnum &&
        splitExpectedVolumePerAnnum.length > 0
      ) {
        const expectedVolumePerAnnumMaxLength = splitExpectedVolumePerAnnum[1]
          ? 18
          : 22;
        if (
          splitExpectedVolumePerAnnum[0] &&
          splitExpectedVolumePerAnnum[0].length >
            expectedVolumePerAnnumMaxLength
        ) {
          this.sweetAlert.showError(
            'error',
            ErrorMessages.ExpectedVolumePerAnnumMaxLengthMessage
          );
          this.tradingForm.controls['expectedVolumePerAnnum'].setValue(
            this.beforeKeyUpExpectedVolumePerAnnumValue
          );
          return;
        }
        if (
          splitExpectedVolumePerAnnum[1] &&
          splitExpectedVolumePerAnnum[1].length > CreditLimitScaleMaxLength
        ) {
          this.sweetAlert.showError(
            'error',
            ErrorMessages.ExpectedVolumePerAnnumScaleMaxLengthMessage
          );
          this.tradingForm.controls['expectedVolumePerAnnum'].setValue(
            this.beforeKeyUpExpectedVolumePerAnnumValue
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          );
          return;
        }
      }
    }
  };
}
