import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  CurrecyPairData,
  CurrecyPairUpdateResponse,
  CurrypairAddPayload,
  CurrypairUpdatePayload,
} from '../../components/add-edit-currency-pairs/models/currencypair.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyPairService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  searchCurrencyPair(payload: any) {
    return this.http.post(this.apiUrl + 'CurrencyPair/Search', payload);
  }

  changeStatus(currencyPairId: any, isactive: any) {
    const payload = {
      currencyPairID: currencyPairId,
      isActive: isactive,
    };
    return this.http.patch(this.apiUrl + 'CurrencyPair/SetActiveFlag', payload);
  }

  getCurrencyList() {
    return this.http.get(this.apiUrl + 'CurrencyPair/GetLists');
  }

  getCurrencyDetails(id: number): Observable<CurrecyPairData> {
    return this.http.get<CurrecyPairData>(this.apiUrl + 'CurrencyPair/' + id);
  }

  updateCurrencyPair(
    payload: CurrypairUpdatePayload
  ): Observable<CurrecyPairUpdateResponse> {
    return this.http.patch<CurrecyPairUpdateResponse>(
      this.apiUrl + 'CurrencyPair/Update',
      payload
    );
  }

  addCurrencyPair(
    payload: CurrypairAddPayload
  ): Observable<CurrecyPairUpdateResponse> {
    return this.http.post<CurrecyPairUpdateResponse>(
      this.apiUrl + 'CurrencyPair/Add',
      payload
    );
  }
}
