import { Component, ElementRef, Input } from '@angular/core';

import { DealDetailService } from '../../services/DealDetail/deal-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { data } from 'jquery';

@Component({
  selector: 'app-common-deal-detail',
  templateUrl: './common-deal-detail.component.html',
  styleUrl: './common-deal-detail.component.css',
})
export class CommonDealDetailComponent {
  @Input('dataFromQuoteApi') dataFromQuoteApi: any = {};
  receivedData: any;
  // direction: any;
  paymentOption = 'AutoRoll';
  dataLoaded = false;

  remainingTime!: number;
  minutes!: number;
  seconds!: number;
  timer: any;
  spotRateExpiration: any;
  message = '';
  interval: any;
  display: any;
  timeoutExpired = false;
  timeInSeconds: any;
  beneficiaryID!:Number;
  @Input('isAdmin') isAdmin = true;
  @Input('productName') productName: any;
  @Input('direction') direction: any;

  paymanetReference!: String;
  beneficiaryFundingAccountID!:Number;
  internalFundingAccountTypeID!:Number;
  accountType:String='Beneficiary';
  DealDetailData: any = {};

  paymentData!:paymentModel;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private dealDetailService: DealDetailService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    this.isAdmin = user === 'Admin' ? true : false;    
    this.execute();
  }

  changepayment() {
  }

  calculateRemainingTime() {
    if (this.timeInSeconds !== 0) {
      this.timeInSeconds -= 1;
    }

    this.minutes = Math.floor(this.timeInSeconds / 60);
    const prefixForMinute = this.minutes < 10 ? '0' : '';
    this.seconds = Math.floor(this.timeInSeconds % 60);
    const prefixForSeconds = this.seconds < 10 ? '0' : '';

    if (this.minutes > 0) {
      this.display = `${prefixForMinute}${this.minutes}:${prefixForSeconds}${this.seconds}`;
    } else {
      this.display = `${prefixForSeconds}${this.seconds}`;
    }
    alert(this.display);
    if (this.seconds == 0 && this.minutes == 0) {
      this.timeoutExpired = true;
      clearInterval(this.timer);
      Swal.fire({
        title: 'Redirecting to Request Quote',
        text: 'Spot Rate timer is over please quote new Rate',
        timer: 2000,
        timerProgressBar: true,
        icon: 'warning',
      }).then(() => {
        if (this.isAdmin) {
          this.router.navigate([`Admin/${this.productName}`]);
        } else {
          this.router.navigate([`Client/${this.productName}`]);
        }
      });
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  }

  execute() {
    this.dataLoaded = false;    
    const payload = {
      quoteID: this.dataFromQuoteApi.quoteID,
    };
    this.dealDetailService.executeQuote(payload).subscribe({
      next: (response: any) => {
        this.dataLoaded = true;   
        if (response.isError) {

          Swal.fire({
            html: `<strong>${response.message}</strong>`,
            icon: 'error',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.back();
          });
        } else {
          this.DealDetailData = response;        
          
          Swal.fire({
            html: `<strong>Trade Booked Successfully With Trade ID ${response.tradeID}</strong>`,
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        }
      },
    });
  }

  back() {
    if (this.isAdmin) {
      this.router.navigate([`Admin/${this.productName}`]);
    } else {
      this.router.navigate([`Client/${this.productName}`]);
    }
  }

  DownloadFundPDF(tradeID: any) {
    this.dealDetailService.dealHistoryGetTradeConfo(tradeID).subscribe({
      next: (data) => {
        var blob = new Blob([data], { type: 'application/pdf' });

        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${tradeID}.pdf`;
        link.click();
      },
    });
  }


  redirectToPayment(choice:string){
    this.paymentData={
      tradeID: this.DealDetailData?.tradeID,
      paymentOption: this.paymentOption,
      beneficiaryAccountID: this.beneficiaryID? this.beneficiaryID:null,
      paymentReference: this.paymanetReference?this.paymanetReference:null,
      accountType: this.accountType,
      beneficiaryFundingAccountID: this.beneficiaryFundingAccountID?this.beneficiaryFundingAccountID:null,
      internalFundingAccountTypeID: this.internalFundingAccountTypeID ?this.internalFundingAccountTypeID:null,
      confirmOrSkip: 'Confirm'
    };
    if(choice=='Skip'){
      this.paymentData.confirmOrSkip='Skip';
    }
    if(this.isAdmin){
      this.router.navigate(['Admin/SummaryDealDeatils'], { state: { data: this.paymentData ,admin:false,direction:this.direction} });
    }
    else{
      this.router.navigate(['Client/SummaryDealDeatils'], { state: { data: this.paymentData ,admin:false,direction:this.direction} });
    }
     



  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}

export interface paymentModel {
  tradeID: Number,
  paymentOption: String,
  beneficiaryAccountID: Number |null,
  paymentReference: String|null,
  accountType: String,
  beneficiaryFundingAccountID: Number |null,
  internalFundingAccountTypeID: Number  | null,
  confirmOrSkip: String,
};
