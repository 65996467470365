import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageContactsService } from '../../services/managecontacts/manage-contacts.service';
import { HttpResponse } from '@angular/common/http';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import {
  ClientContact_TabID,
  ContactTabConstant,
  SystemConstant,
} from '../../global/global.constant';
import { AdminInfo } from '../models/common.model';
import { SearchParams } from '../manage-currencies/models/manage-currencies.model';
import { FieldPermission } from '../client-contacts/models/client-contacts.model';

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrl: './manage-contacts.component.css',
})
export class ManageContactsComponent implements OnInit {
  sortedColumn: string = '';
  statusName: string = '';
  isAscending: boolean = true;
  tenantList: any[] = [];
  status: any[] = [];
  contacts: any[] = [];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hideAddButton = true;
  dataLoaded: boolean = false;
  tabPermission: any;
  canAddEdit: boolean = false;
  clientMode: boolean = false;
  isNavinciUser: boolean = false;
  manageContactPermission: any;
  canInsert: any;
  canUpdate: any;
  combineFP:boolean=false;
  primaryContactFP: boolean=false;
  fieldPermissions: FieldPermission[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private contactService: ManageContactsService,
    private encryptPermission: EncryptRolePermissionService,
    private renderer: Renderer2,
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 4
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
    }
  }
  contactsSearchForm: FormGroup = this.fb.group({
    selectedTenatCarpartner: [null],
    selectedclinetName: [null],
    selectedContacttName: [null],
    selectedstatus: [null],
  });

  ngOnInit(): void {
    this.checkForNavinciUser();
  }

  private checkForNavinciUser = (): void => {
    var savedLogin: AdminInfo =
      this.encryptPermission.getDecryptedPermissions('userResponse');
    if (savedLogin.userType == SystemConstant.Navinci) {
      this.isNavinciUser = true;
      this.checkModulePermission();
    } else {
      this.isNavinciUser = false;
      this.checkModulePermission();      
    }
  };

  private checkModulePermission = (): void => {
    const modulePermission =
      this.encryptPermission.getDecryptedPermissions(
        'roleNgp'
      ).modulePermissions;
    if (modulePermission) {
      this.manageContactPermission = modulePermission.find(
        (x: { pageID: number }) => x.pageID == 4
      );
      this.canInsert =
        this.manageContactPermission?.canInsert !== undefined
          ? this.manageContactPermission?.canInsert
          : 0;
      this.canUpdate =
        this.manageContactPermission?.canUpdate !== undefined
          ? this.manageContactPermission?.canUpdate
          : 0;
          this.fieldPermissions=this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).fieldPermissions;
      this.primaryContactFP=this.fieldPermissions.find(FieldPermission => FieldPermission.fieldID === ContactTabConstant.primaryContact)?.canInsert?true:false;      
      this.combineFP= !(this.canUpdate?true:false) || !this.primaryContactFP ?true :false;

      if (
        this.manageContactPermission &&
        this.manageContactPermission.canView == 1
      ) {
        const params: SearchParams = {
          currencyAbbreviation: '',
          currencyName: '',
        };
        this.getTanentCarPartnerList();
        this.getstatusList();
        this.searchContact();
        this.length = this.contacts.length;
        this.paginatedData = this.contacts.slice(0, this.pageSize);
      }
      else{
        this.dataLoaded = true;
      }
    }
  };

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.contacts.slice(startIndex, endIndex);
  }
  sort(columnName: string): void {
    this.contacts.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }
  sortTable(columnName: string) {
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.contacts.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.contacts.slice(startIndex, endIndex);
  }

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') {
      this.openCloseDropdwon('0');
    } else {
      this.openCloseDropdwon('1');
    }
  };

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
    if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
      this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
    }
  };

  searchContact() {
    this.dataLoaded = false;
    this.contactService.searchContact(this.contactsSearchForm.getRawValue()).subscribe({
      next: (data: any) => {
        this.contacts = data;
        this.length = this.contacts.length;
        this.paginatedData = this.contacts.slice(0, this.pageSize);
        this.dataLoaded = true;
      },
      error: (error) => {
      },
    });
  }
  reset() {
    this.contactsSearchForm.reset();
    this.searchContact();
  }
  getstatus(id: number): string {
    const stsusLable = this.status.find(
      (status) => status.contactStatusId === id
    );
    return stsusLable ? stsusLable.contactStatusValue : undefined;
  }
  toggleSwitch(contact: any) {
    contact.isPrimaryContact = !contact.isPrimaryContact;
    this.contactService
      .setPrimaryContactFlag(contact.contactID, contact.isPrimaryContact)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.searchContact();
        },
        error: (error) => {
        },
      });
  }
  editContact(contact: any) {
    this.router.navigate([
      '/Admin/addContact',
      contact.contactID,
      contact.clientID,
    ]);
  }
  addContact() {
    this.router.navigate(['/Admin/addContact']);
  }
  getstatusList() {
    this.contactService.getstatusList().subscribe({
      next: (data: any) => {
        this.status = data.status;
      },
      error: (error) => {
      },
    });
  }
  getTanentCarPartnerList() {
    if(this.isNavinciUser){
    this.contactService.getdropDownList().subscribe({
      next: (data: any) => {
        this.tenantList = data[0].tenantCARs;
      },
      error: (error) => {
      },
    });
  }
  else{
    this.contactService.getTanentCarPartnerDropDownList().subscribe({
      next: (data: any) => {
        this.tenantList = data[0].tenantCARs;
      },
      error: (error) => {
      },
    });
  }
  }
  statusClass(row: any): string {
    var statusClass: string = '';
    switch (row.status) {
      case 'Inactive': {
        statusClass = 'badge-secondary';
        this.statusName = 'Inactive';
        break;
      }
      case 'Active': {
        statusClass = 'badge-success';
        this.statusName = 'Active';
        break;
      }
      case 'Locked': {
        statusClass = 'badge-warning';
        this.statusName = 'Locked';
        break;
      }
      case 'PendingAuthorization': {
        statusClass = 'badge-secondary';
        this.statusName = 'Pending Authorization';
        break;
      }
      case 'blocked': {
        statusClass = 'badge-danger';
        this.statusName = 'blocked';
        break;
      }
    }
    return statusClass;
  }
}
