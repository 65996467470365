import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ClientacccountgridService {
  apiUrl=environment.apiUrl

  constructor(private http:HttpClient) { }

  getClientAccount(clientId:number){
    return this.http.get(this.apiUrl+'Client/account?clientID='+clientId);
  }
}
