import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    const isControlKey = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key);

    if (!(isNumber || isControlKey)) {
      event.preventDefault();
    }
  }
}