<!-- Tab panes -->
<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">{{ loadingMessage }}...</div>
  </div>
</div>
<div class="compress-inputs-page">
  <div class="tab-content">
    <!-- General Body -->
    <div class="tab-pane active" id="CARPartnerGeneral">
      <div class="tab-body-wrapper">
        <div *ngIf="canAddEdit" class="title">
          <h3>&nbsp;</h3>

          <a [hidden]="addButtonHidden" class="btn btn-success" (click)="openModal()">
            <em><img src="../../../assets/plus.svg" alt="" /></em>
            Add
          </a>
        </div>

        <div
          *ngIf="authorisedSignatoryList.length > 0 || authorisedToTradeList.length > 0 || beneficialOwnerList.length > 0|| authorisedUserList.length > 0; else notFound"
          class="table-responsive">
          <table class="table custom-grid">
            <thead>
              <tr class="Row123">
                <th style="width: 10%"></th>
                <th style="width: 15%">
                  <a (click)="sortTable('firstName')" class="sort active ts-asc" unselectable="on"
                    data-i="0">Name<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 10%">
                  <a (click)="sortTable('position')" class="sort active ts-asc" unselectable="on"
                    data-i="1">Position<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 10%">
                  <a (click)="sortTable('userName')" class="sort active ts-asc" unselectable="on"
                    data-i="2">Username<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 15%">
                  <a (click)="sortTable('phoneNumber')" class="sort active ts-asc" unselectable="on"
                    data-i="3">Mobile<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 15%">
                  <a (click)="sortTable('emailAddress')" class="sort active ts-asc" unselectable="on"
                    data-i="4">Email<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 15%">
                  <a (click)="sortTable('status')" class="sort active ts-asc" unselectable="on" data-i="5">Status<span
                      class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 5%">
                  <a (click)="sortTable('isPrimaryContact')" class="sort active ts-asc" unselectable="on"
                    data-i="7">Primary&nbsp;Contact<span class="ts-sord"></span>
                  </a>
                </th>
                <th *ngIf="editButtonhidden" style="width: 5%">
                  <a class="sort active ts-asc" unselectable="on" data-i="8">Action<span
                      class="ts-sord"></span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Authorised Signatory -->
              <tr class="category-row" (click)="showhide('Authorised Signatory')">
                <td colspan="8" class="text-left">Authorised Signatory ({{authorisedSignatoryList.length}})</td>
                <td *ngIf="isShowAuthorisedSignatory"> <i class="arrow up"></i> </td>
                <td *ngIf="!isShowAuthorisedSignatory"> <i class="arrow down"></i> </td>
              </tr>
              <ng-container *ngIf="isShowAuthorisedSignatory">
                <tr *ngFor="let row of paginatedDataOfAS">

                  <td></td>
                  <td (click)="openModal(row)"  class="wrapword">
                    {{ row.firstName + ' ' + row.lastName }}
                  </td>
                  <td (click)="openModal(row)"  class="wrapword">{{ row.position }}</td>
                  <td (click)="openModal(row)"  class="wrapword">{{ row.userName }}</td>
                  <td (click)="openModal(row)"  class="wrapword">{{ row.phoneNumber }}</td>
                  <td (click)="openModal(row)"  class="wrapword" style="text-align: left">
                    {{ row.emailAddress }}
                  </td>
                  <td (click)="openModal(row)" >
                    <span class="badge" [ngClass]="statusClass(row)">{{ getstatus(row.status) }}
                    </span>
                  </td>
                  <td>
                    <label class="custom-switch">
                      <input type="checkbox" [checked]="row.isPrimaryContact" [disabled]="combineFP"
                        (change)="toggleSwitch(row)" class="switch-control-input" />
                      <span class="switch-control-label"></span>
                    </label>
                  </td>
                  <td *ngIf="editButtonhidden">
                    <div class="action-btns">
                      <a (click)="openModal(row)" title="Edit"><img src="../../../assets/edit.svg" alt="" /></a>
                    </div>
                  </td>

                </tr>
                <tr>
                  <td colspan="9">
                    <mat-paginator #paginator (page)="handlePageEventOfAS($event)" [length]="lengthOfAS"
                      [pageSize]="pageSizeOfAS" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                      [pageIndex]="pageIndexOfAS" aria-label="Select page">
                    </mat-paginator>
                  </td>
                </tr>
              </ng-container>

              <!-- Authorised To Trade -->
              <tr class="category-row" (click)="showhide('Authorised To Trade')">
                <td colspan="8" class="text-left">Authorised To Trade ({{authorisedToTradeList.length}})</td>
                <td *ngIf="isShowAuthorisedToTrade"> <i class="arrow up"></i> </td>
                <td *ngIf="!isShowAuthorisedToTrade"> <i class="arrow down"></i> </td>
              </tr>
              <ng-container *ngIf="isShowAuthorisedToTrade">
                <tr *ngFor="let row of authorisedToTradeList">
                  <td></td>
                  <td (click)="openModal(row)"  class="wrapword">
                    {{ row.firstName + ' ' + row.lastName }}
                  </td>
                  <td (click)="openModal(row)"  class="wrapword">{{ row.position }}</td>
                  <td (click)="openModal(row)"  class="wrapword">{{ row.userName }}</td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.phoneNumber }}</td>
                  <td (click)="openModal(row)" class="wrapword" style="text-align: left">
                    {{ row.emailAddress }}
                  </td>
                  <td (click)="openModal(row)" >
                    <span class="badge" [ngClass]="statusClass(row)">{{ getstatus(row.status) }}
                    </span>
                  </td>
                  <td>
                    <label class="custom-switch">
                      <input type="checkbox" [checked]="row.isPrimaryContact" [disabled]="combineFP" (change)="toggleSwitch(row)"
                        class="switch-control-input" [readOnly]="combineFP" />
                      <span class="switch-control-label"></span>
                    </label>
                  </td>
                  <td *ngIf="editButtonhidden">
                    <div class="action-btns">
                      <a (click)="openModal(row)" title="Edit"><img src="../../../assets/edit.svg" alt="" /></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <mat-paginator #paginator (page)="handlePageEventOfAT($event)" [length]="lengthOfAT"
                      [pageSize]="pageSizeOfAT" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                      [pageIndex]="pageIndexOfAT" aria-label="Select page">
                    </mat-paginator>
                  </td>
                </tr>
              </ng-container>

              <!-- Authorised User -->
              <tr class="category-row" (click)="showhide('Authorised User')">
                <td colspan="8" class="text-left">Authorised User ({{authorisedUserList.length}})</td>
                <td *ngIf="isShowAuthorisedUser"> <i class="arrow up"></i> </td>
                <td *ngIf="!isShowAuthorisedUser"> <i class="arrow down"></i> </td>
              </tr>
              <ng-container *ngIf="isShowAuthorisedUser">
                <tr *ngFor="let row of authorisedUserList">
                  <td></td>
                  <td class="wrapword">
                    {{ row.firstName + ' ' + row.lastName }}
                  </td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.position }}</td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.userName }}</td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.phoneNumber }}</td>
                  <td (click)="openModal(row)" class="wrapword" style="text-align: left">
                    {{ row.emailAddress }}
                  </td>
                  <td (click)="openModal(row)" >
                    <span class="badge" [ngClass]="statusClass(row)">{{ getstatus(row.status) }}
                    </span>
                  </td>
                  <td>
                    <label class="custom-switch">
                      <input type="checkbox" [checked]="row.isPrimaryContact" [disabled]="combineFP" (change)="toggleSwitch(row)"
                        class="switch-control-input" />
                      <span class="switch-control-label"></span>
                    </label>
                  </td>
                  <td *ngIf="editButtonhidden">
                    <div class="action-btns">
                      <a (click)="openModal(row)" title="Edit"><img src="../../../assets/edit.svg" alt="" /></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <mat-paginator #paginator (page)="handlePageEventOfAU($event)" [length]="lengthOfAU"
                      [pageSize]="pageSizeOfAU" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                      [pageIndex]="pageIndexOfAU" aria-label="Select page">
                    </mat-paginator>
                  </td>
                </tr>
              </ng-container>

              <!-- Beneficial Owner -->
              <tr class="category-row" (click)="showhide('Beneficial Owner')">
                <td colspan="8" class="text-left">Beneficial Owner ({{beneficialOwnerList.length}})</td>
                <td *ngIf="isShowBeneficiaryOwner"> <i class="arrow up"></i> </td>
                <td *ngIf="!isShowBeneficiaryOwner"> <i class="arrow down"></i> </td>
              </tr>
              <ng-container *ngIf="isShowBeneficiaryOwner">
                <tr *ngFor="let row of beneficialOwnerList">
                  <td></td>
                  <td (click)="openModal(row)"  class="wrapword">
                    {{ row.firstName + ' ' + row.lastName }}
                  </td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.position }}</td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.userName }}</td>
                  <td (click)="openModal(row)" class="wrapword">{{ row.phoneNumber }}</td>
                  <td (click)="openModal(row)" class="wrapword" style="text-align: left">
                    {{ row.emailAddress }}
                  </td>
                  <td (click)="openModal(row)" >
                    <span class="badge" [ngClass]="statusClass(row)">{{ getstatus(row.status) }}
                    </span>
                  </td>
                  <td>
                    <label class="custom-switch">
                      <input type="checkbox" [checked]="row.isPrimaryContact" [disabled]="combineFP " (change)="toggleSwitch(row)"
                        class="switch-control-input" />
                      <span class="switch-control-label"></span>
                    </label>
                  </td>
                  <td *ngIf="editButtonhidden">
                    <div class="action-btns">
                      <a (click)="openModal(row)" title="Edit"><img src="../../../assets/edit.svg" alt="" /></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="9">
                    <mat-paginator #paginator (page)="handlePageEventOfBO($event)" [length]="lengthOfBO"
                      [pageSize]="pageSizeOfBO" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                      [pageIndex]="pageIndexOfBO" aria-label="Select page">
                    </mat-paginator>
                  </td>
                </tr>
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #notFound>
    <div class="alert alert-info">
      <span>No record found</span>
    </div>
  </ng-template>

  <div class="modal secondary backdrop" id="updateClientContact-modal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">&nbsp;</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="close()">
            <img src="../../../assets/cross.svg" alt="" />
          </button>
        </div>
        <div class="compress-input-pages modal-body">
          <form class="form" [formGroup]="contactForm" (ngSubmit)="onSave()">
            <div class="card">
              <div class="row">
                <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Authorised Signatory</label>
                    <div class="value-wrapper">
                      <div class="">
                        <input type="checkbox" formControlName="isAuthorisedSignatory"
                          (change)="checkRoleOfContact()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Authorised User</label>
                    <div class="value-wrapper">
                      <div class="">
                        <input type="checkbox" formControlName="isAuthorisedUser"
                          (change)="checkRoleOfContact()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Authorised to Trade</label>
                    <div class="value-wrapper">
                      <div class="">
                        <input type="checkbox" formControlName="isAuthorisedToTrade"
                          (change)="checkRoleOfContact()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Beneficial Owner</label>
                    <div class="value-wrapper">
                      <div class="">
                        <input type="checkbox" formControlName="isBeneficialOwner"
                          (change)="checkRoleOfContact()" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[15]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Title<em style="color: red">*</em></label>
                    <div class="value-wrapper">
                      <div class="showhide" [ngClass]="{
                          'newTooltip redborder expand has-error':
                            contactForm.controls['title'].touched &&
                            contactForm.controls['title'].invalid
                        }" datatitle="title Is Required">
                        <ng-select formControlName="title" [items]="salutationList" bindLabel="salutaionName"                       
                          [closeOnSelect]="true" [searchable]="true" placeholder="Select">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[16]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">User Name<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                        'newTooltip expand has-error':
                          contactForm.controls['userName'].touched &&
                          contactForm.controls['userName'].invalid
                      }" datatitle="Username Is Required">
                      <input type="text" class="form-control" formControlName="userName" />
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[5]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">First Name<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                        'newTooltip expand has-error':
                          contactForm.controls['firstName'].touched &&
                          contactForm.controls['firstName'].invalid
                      }" datatitle="First Name Is Required">
                      <input type="text" class="form-control" formControlName="firstName" />
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[2]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Alias</label>
                    <input type="text" class="form-control" formControlName="alias" />
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[7]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Middle Name</label>
                    <input type="text" class="form-control" formControlName="middleName" />
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[0]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Role<em style="color: red">*</em></label>
                    <div class="value-wrapper showhide" [ngClass]="{
                        'newTooltip redborder expand has-error':
                          contactForm.controls['clientRoleID'].touched &&
                          contactForm.controls['clientRoleID'].invalid
                      }" datatitle="Role Is Required">
                      <ng-select [items]="roleList" bindLabel="clientRoleName" bindValue="clientRoleID"
                        formControlName="clientRoleID" [closeOnSelect]="true" [searchable]="true" placeholder="Select">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[6]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Last Name<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                        'newTooltip expand has-error':
                          contactForm.controls['lastName'].touched &&
                          contactForm.controls['lastName'].invalid
                      }" datatitle="Last Name Is Required">
                      <input type="text" class="form-control" formControlName="lastName" />
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[12]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Status</label>
                    <div class="showhide" [ngClass]="{
                        'newTooltip expand has-error':
                          contactForm.controls['status'].touched &&
                          contactForm.controls['status'].invalid
                      }" datatitle="status Is Required">
                      <ng-select *ngIf="!pendingAuth" [items]="statusList" bindLabel="contactStatusValue"
                        bindValue="contactStatusId" formControlName="status" [closeOnSelect]="true" [searchable]="true"
                        placeholder="Select">
                      </ng-select>
                      <input *ngIf="pendingAuth" type="text" [value]="pendingAuthValue" class="form-control" readonly />
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[10]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Position</label>
                    <input type="text" class="form-control" formControlName="position" />
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[13]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Theme<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                        'newTooltip redborder expand has-error':
                          contactForm.controls['themeID'].touched &&
                          contactForm.controls['themeID'].invalid
                      }" datatitle="Theme Is Required">
                      <ng-select [items]="themeList" bindLabel="themeName" bindValue="themeID" formControlName="themeID"
                        [closeOnSelect]="true" [searchable]="true" placeholder="Select">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[3]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label>Date Of Birth</label>
                    <div class="input-icons showhide"
                      [ngClass]=" {'newTooltip expand has-error':contactForm.controls['dateOfBirth'].invalid }"
                      [attr.datatitle]="dobValidationTooltip">
                      <input formControlName="dateOfBirth" (blur)="checkDOB()" [max]="maxDate"
                        [matDatepicker]="pickerDOB" id="DOB" placeholder="DD/MM/YYYY" class="input-field form-control">
                      <span class="icon">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerDOB"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDOB></mat-datepicker>
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[17]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Primary Contact</label>
                    <label class="custom-switch">
                      <input type="checkbox" class="switch-control-input" formControlName="isPrimaryContact" />
                      <span class="switch-control-label"></span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="contactFieldsViewFlag[8]">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Nationality</label>
                    <div class="showhide" [ngClass]="{
                          'newTooltip redborder expand has-error':
                              contactForm.controls['nationalityID'].touched &&
                              contactForm.controls['nationalityID'].invalid
                      }" datatitle="Nationality Is Required">

                      <ng-select [items]="countryList" bindLabel="countryName" bindValue="countryID"
                        formControlName="nationalityID" [closeOnSelect]="true" [searchable]="true" placeholder="Select">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Beneficiary %<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error':
                              contactForm.controls['beneficiaryPercentage'].touched &&
                              contactForm.controls['beneficiaryPercentage'].invalid
                      }" datatitle="Beneficiary is Required">
                      <input type="text" class="form-control" formControlName="beneficiaryPercentage"
                        [readOnly]="!this.contactForm.controls['isBeneficialOwner'].value" appBeneficiaryPercent />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="contactFieldsViewFlag[1]">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Address</label>
                    <input type="text" class="form-control" formControlName="address" />
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="contactFieldsViewFlag[4]">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Email<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error':
                              contactForm.controls['emailAddress'].touched &&
                              contactForm.controls['emailAddress'].invalid
                      }" datatitle="Email Is Required">
                      <input type="email" class="form-control" formControlName="emailAddress" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="contactFieldsViewFlag[9]">
                  <div class="form-group label-inline">
                    <label for="" class="strong">Phone/Mobile No.<em style="color: red">*</em></label>
                    <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error':
                              contactForm.controls['phoneNumber'].touched &&
                              contactForm.controls['phoneNumber'].invalid
                      }" datatitle="Phone/Mobile No. Is Required">
                      <input type="text" class="form-control" formControlName="phoneNumber" appPhoneNumber />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div *ngIf="canAddEdit" class="inline-element d-block text-center">
                    <button type="submit" class="btn btn-success">Save</button>
                    <button type="button" (click)="close()" class="btn btn-secondary">
                      Cancel
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>