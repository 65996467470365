import { Component } from '@angular/core';
import { SignalRService } from '../../../services/signalR/signal-r.service';

@Component({
  selector: 'app-testcomponent',
  templateUrl: './testcomponent.component.html',
  styleUrl: './testcomponent.component.css'
})
export class TestcomponentComponent {

  receivedMessage: string='';

constructor(private signalRService: SignalRService){

}

ngOnInit() {
  this.signalRService.startchatConnection().subscribe(() => {
    this.signalRService.getChatmessage().subscribe((user) => {
      this.receivedMessage = user;
    });
  });
}
invoke() {
this.signalRService.sendChathubmessage();
}
}
