<div class="loader-block" *ngIf="!dataloaded">
    <div class="inner-loader">
        {{loaderMessage}}...
    </div>
</div>

<form [formGroup]="tradingForm" class="form" (ngSubmit)="onSave()">
    <div class="row">
        <input type="hidden" formControlName="clientID">
        <div *ngIf="tradingFildsViewFlag[12]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Client Account Number<em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]="{'newTooltip expand has-error': (tradingForm.controls['clientAccountNumber'].touched) && tradingForm.controls['clientAccountNumber'].invalid }"
                    title="Client Account Number is required">
                    <input type="text" formControlName="clientAccountNumber" class="form-control"
                        placeholder="Client Account Number" maxlength="255">
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[4]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Credit Limit </label>
                <div class="showhide" [ngClass]="{'newTooltip expand has-error': tradingForm.controls['creditLimit'].invalid }"
                title="Credit Limit is required">
                    <input 
                     type="text" 
                     formControlName="creditLimit" 
                     class="form-control" 
                     placeholder="Credit Limit"
                     appCommaSeprater 
                     (keyup)="checkCreditLimitValidation()"
                     (keypress)="saveCreditLimitValue()"
                    >
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[9]" class="col-sm-6 ">
            <div class="form-group label-inline">
                <label class="strong">Statement Tier <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]="{'newTooltip expand has-error': (tradingForm.controls['statementTierID'].touched) && tradingForm.controls['statementTierID'].invalid }"
                    title="Type Is Required">
                    <ng-select [items]="statementTiers" bindLabel="tier" bindValue="statementTierID"
                        [closeOnSelect]="true" [searchable]="true" formControlName="statementTierID"
                        placeholder="Select">
                    </ng-select>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[5]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Products </label>
                <div class="value-wrapper">
                    <div class="showhide">
                        <ng-select [items]="products" bindLabel="productName" [multiple]="true" [closeOnSelect]="false"
                            [searchable]="true" placeholder="Select" formControlName="clientProducts">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[7]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Trade Currency Pair <em style="color:red;">*</em></label>
                <div class="value-wrapper">
                    <div class="showhide"
                        [ngClass]="{'newTooltip expand has-error': (tradingForm.controls['tradeCurrencyPair'].touched) && tradingForm.controls['tradeCurrencyPair'].invalid }"
                        title="Trade Currency Pair Is Required">

                        <ng-select [items]="tradeCurrencyPair" bindLabel="currencyPairFormed" [multiple]="true"
                            [closeOnSelect]="false" [searchable]="true" placeholder="Select"
                            formControlName="tradeCurrencyPair">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[8]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Widget Currency Pair</label>
                <div class="value-wrapper">
                    <div class="showhide">
                        <ng-select [items]="wiedgetCurrencyPair" bindLabel="currencyPairFormed" [multiple]="true"
                            [closeOnSelect]="false" [searchable]="true" placeholder="Select"
                            formControlName="widgetCurrencyPair">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[7]" class="col-sm-6">
            <div class="form-group label-inline" style="align-items:end;">
                <label class="strong">Trade Currency Pair Order</label>
                <div class="value-wrapper has-image">
                    <div [cdkDropListDisabled]="disableTradeCPOrder" cdkDropList class="example-list" (cdkDropListDropped)="dropTradeCurrencyPair($event)">
                        @for (pairTradeCurrency of
                        this.tradingForm.value.tradeCurrencyPair; track
                        pairTradeCurrency) {
                        <div class="example-box" cdkDrag>
                            {{pairTradeCurrency['currencyPairFormed']}}</div>
                        }
                    </div>
                    <div class="tooltip-wrapper">
                        <img src="../../../assets/info.svg" class="image-light" />
                        <img src="../../../assets/info-white.svg" class="image-dark" />
                        <div class="custom-tooltip rhs-tooltip">
                            Use drag and drop to change Order.
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div *ngIf="tradingFildsViewFlag[8]" class="col-sm-6">
            <div class="form-group label-inline" style="align-items:end;">
                <label class="strong">Widget Currency Pair Order</label>
                <div class="value-wrapper has-image">
                    <div cdkDropList [cdkDropListDisabled]="disableWidgetCPOrder" class="example-list" (cdkDropListDropped)="dropWiedgetTradeCurrencyPair($event)">
                        @for (pairTradeCurrency of
                        this.tradingForm.value.widgetCurrencyPair; track
                        pairTradeCurrency) {
                        <div class="example-box" cdkDrag>
                            {{pairTradeCurrency['currencyPairFormed']}}</div>
                        }
                    </div>
                    <div class="tooltip-wrapper">
                        <img src="../../../assets/info.svg" class="image-light" />
                        <img src="../../../assets/info-white.svg" class="image-dark" />
                        <div class="custom-tooltip rhs-tooltip">
                            Use drag and drop to change Order.
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-sm-6">

            <div *ngIf="tradingFildsViewFlag[10]" class="form-group label-inline">
                <label class="strong">Is Partner</label>
                <div class="client custom-switch">
                    <input type="checkbox" formControlName="isPartner" class="switch-control-input">
                    <span class="switch-control-label"></span>

                </div>
            </div>

        </div>
        <div *ngIf="tradingFildsViewFlag[11]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Referred By</label>
                <div style="width: 100%;">
                    <ng-select [items]="referredBy" bindLabel="referralPartnerName" bindValue="referralPartnerID"
                        formControlName="referredByID" placeholder="Select">
                    </ng-select>

                </div>

            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[0]" class="col-sm-6">
            <div  class="form-group label-inline">
                <label class="strong">Expected Volume per annum (AUD)</label>
                <div class="showhide">
                    <input type="text" formControlName="expectedVolumePerAnnum" maxlength="22" class="form-control"
                        placeholder="AUD Amount" 
                        appCommaSeprater appTwoDigitDecimaNumber
                        (keypress)="saveExpectedVolumePerAnnumValue()"
                    >
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[1]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Expected Number of payment per annum</label>
                <div class="showhide">
                    <input type="text" formControlName="expectedNoOfPaymentPerAnnum" class="form-control"
                        placeholder="Number of Payment" appNumberOnly>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[2]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Destination Countries of Outgoing Payment </label>
                <div class="value-wrapper">
                    <div class="showhide">
                        <ng-select [items]="destCountryOutPayment" bindLabel="countryName" [multiple]="true"
                            [closeOnSelect]="false" [searchable]="true" placeholder="Select"
                            formControlName="destCountryOutPayment">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tradingFildsViewFlag[3]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Origin Countries of Incoming Payment </label>
                <div class="value-wrapper">
                    <div class="showhide">
                        <ng-select [items]="originCountryInPayment" bindLabel="countryName" [multiple]="true"
                            [closeOnSelect]="false" [searchable]="true" placeholder="Select"
                            formControlName="originCountryInPayment">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="inline-element d-block text-center">
            <button type="submit" class="btn btn-success" [disabled]="isSaveButtonDisabled()" #saveButton>Save</button>
            <button type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button>
        </div>
    </div>
</form>