import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forward-deal-detail',
  templateUrl: './forward-deal-detail.component.html',
  styleUrl: './forward-deal-detail.component.css'
})
export class ForwardDealDetailComponent {

  dataFromQuoteApi: any;
  isAdmin: any;
  direction: any;
  productName='ForwardHedge';
  constructor(private router:Router){
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      this.dataFromQuoteApi = navigation.extras.state['data'];
      this.direction = navigation.extras.state['direction'];
      this.isAdmin = navigation.extras.state['admin'];
    }
  }

}
