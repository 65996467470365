import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AddEditRoleService } from '../../services/addEditRole/add-edit-role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { SpinnerMessage } from '../../global/global.constant';

@Component({
  selector: 'app-addedit-role',
  templateUrl: './addedit-role.component.html',
  styleUrl: './addedit-role.component.css',
})
export class AddeditRoleComponent implements OnInit {
  dataLoaded: boolean = true;
  loadingMessage = SpinnerMessage.Loading;
  distinctModule: any[] = [];
  responsedata: any[] = [];
  fieldsData: any[] = [];
  tabsData: any[] = [];
  modulePermissions: any[] = [];
  fieldPermissions: any[] = [];
  tabPermissions: any[] = [];
  selectedModulePermission: any[] = [];
  selectedFieldPermission: any[] = [];
  selectedTabpermission: any[] = [];
  checkbox: boolean = true;
  editMode: boolean = false;
  rolePermissionForm: FormGroup;
  commonindex: number[] = [];
  roleID: number = 0;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private addEditRoleservice: AddEditRoleService,
    private router: Router,
    private alert: SweetalertService
  ) {
    this.rolePermissionForm = this.fb.group({
      roleName: '',
      description: '',
      isActive: false,
      isSuperAdmin: false,
      chkEditAll: false,
      chkAddAll: false,
      chkViewAll: false,
    });
  }

  ngOnInit(): void {
    this.dataLoaded = false;
    this.getTabs().then((data) => {
      this.getpages().then((data) => {
        this.getFields().then((data) => {
          this.route.paramMap.subscribe((parameterMap) => {
            const roleId = +parameterMap.get('roleID')!;
            if (roleId) {
              this.roleID = roleId;
              this.editMode = true;
              this.getroledetails(roleId);
            }
          });
          this.dataLoaded=true;
        });
      });
    });
  }

  private setRoleDetails(): void {
    this.modulePermissions.forEach((module, index) => {
      let Moduleindex = this.responsedata.findIndex(
        (item) => item.pageID === module.pageID
      );
      if (module.canView) {
        let controlername = 'chkView_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
      if (module.canInsert) {
        let controlername = 'chkAdd_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
      if (module.canUpdate) {
        let controlername = 'chkEdit_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
    });

    this.fieldPermissions.forEach((field, index) => {
      let Fieldindex = this.fieldsData.findIndex(
        (item) => item.adminFieldID === field.fieldID
      );
      if (field.canView) {
        let controlername = 'chkFieldView_' + Fieldindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
      if (field.canInsert) {
        let controlername = 'chkFieldAdd_' + Fieldindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
    });
    this.tabPermissions.forEach((tab, index) => {
      let tabindex = this.tabsData.findIndex(
        (item) => item.adminTabID === tab.tabID
      );
      if (tab.canView) {
        let controlername = 'chkTabView_' + tabindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
      if (tab.canInsert) {
        let controlername = 'chkTabAdd_' + tabindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      }
    });
  }

  getroledetails(roleID: number) {
    this.dataLoaded = false;
    this.addEditRoleservice.getRoledetails(roleID).subscribe({
      next: (data: any) => {
        this.modulePermissions = data.modulePermissions;
        this.fieldPermissions = data.fieldPermissions;
        this.tabPermissions = data.tabPermissions;
        this.rolePermissionForm.get('roleName')?.setValue(data.roleName);
        this.rolePermissionForm.get('description')?.setValue(data.description);
        this.rolePermissionForm.get('isActive')?.setValue(data.isActive);
        this.rolePermissionForm
          .get('isSuperAdmin')
          ?.setValue(data.isSuperAdmin);
        this.setRoleDetails();
        this.dataLoaded = true;
      },
      error: (error) => {
      },
    });
  }

  getpages(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.addEditRoleservice.getPages().subscribe({
        next: (data: any) => {
          this.responsedata = data;
          this.responsedata.forEach((module, index) => {
            this.rolePermissionForm.addControl(
              `chkView_${index}`,
              new FormControl(false)
            );
            this.rolePermissionForm.addControl(
              `chkAdd_${index}`,
              new FormControl(false)
            );
            this.rolePermissionForm.addControl(
              `chkEdit_${index}`,
              new FormControl(false)
            );
          });
          data.forEach((item: { moduleName: any }) => {
            if (!this.distinctModule.includes(item.moduleName)) {
              this.distinctModule.push(item.moduleName);
            }
          });
          resolve();
        },
        error: (error) => {
        },
      });
    });
  }

  getFields(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.addEditRoleservice.getfields().subscribe({
        next: (data: any) => {
          this.fieldsData = data;
          this.fieldsData.forEach((fields, index) => {
            this.rolePermissionForm.addControl(
              `chkFieldView_${index}`,
              new FormControl(false)
            );
            this.rolePermissionForm.addControl(
              `chkFieldAdd_${index}`,
              new FormControl(false)
            );
          });
          resolve();
        },
        error: (error) => {
        },
      });
    });
  }

  getTabs(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.addEditRoleservice.getTabs().subscribe({
        next: (data: any) => {
          this.tabsData = data;
          this.tabsData.forEach((Tabs, index) => {
            this.rolePermissionForm.addControl(
              `chkTabView_${index}`,
              new FormControl(false)
            );
            this.rolePermissionForm.addControl(
              `chkTabAdd_${index}`,
              new FormControl(false)
            );
            resolve();
          });
        },
        error: (error) => {
          reject();
        },
      });
    });
  }

  getLength(id: string): number {
    return this.responsedata.filter((module) => module.moduleName === id)
      .length;
  }

  checkAllViews(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkView_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.tabsData.forEach((tab, index) => {
      let controlername = 'chkTabView_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.fieldsData.forEach((fields, index) => {
      let controlername = 'chkFieldView_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.changeAllAdd();
    this.changeAllEdit();
  }

  checkAllAdd(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkAdd_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.tabsData.forEach((tab, index) => {
      let controlername = 'chkTabAdd_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.fieldsData.forEach((fields, index) => {
      let controlername = 'chkFieldAdd_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.changeAllView();
  }
  checkAllEdit(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkEdit_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.changeAllView();
  }

  changeAllView() {
    if (
      this.rolePermissionForm.get('chkAddAll')?.value ||
      this.rolePermissionForm.get('chkEditAll')?.value
    ) {
      this.rolePermissionForm.get('chkViewAll')?.setValue(true);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkView_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      });
      this.tabsData.forEach((tab, index) => {
        let controlername = 'chkTabView_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      });
      this.fieldsData.forEach((fields, index) => {
        let controlername = 'chkFieldView_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      });
    }
  }

  changeAllAdd() {
    if (!this.rolePermissionForm.get('chkViewAll')?.value) {
      this.rolePermissionForm.get('chkAddAll')?.setValue(false);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkAdd_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
      this.tabsData.forEach((tab, index) => {
        let controlername = 'chkTabAdd_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
      this.fieldsData.forEach((fields, index) => {
        let controlername = 'chkFieldAdd_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
    }
  }

  changeAllEdit() {
    if (!this.rolePermissionForm.get('chkViewAll')?.value) {
      this.rolePermissionForm.get('chkEditAll')?.setValue(false);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkEdit_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
    }
  }

  supereadmin(checkboxEvent: Event) {
    const isSuperAdmin = (checkboxEvent.target as HTMLInputElement).checked;
    this.rolePermissionForm.get('chkEditAll')?.setValue(isSuperAdmin);
    this.rolePermissionForm.get('chkAddAll')?.setValue(isSuperAdmin);
    this.rolePermissionForm.get('chkViewAll')?.setValue(isSuperAdmin);
    const syntheticEvent = new Event('change');
    Object.defineProperty(syntheticEvent, 'target', {
      value: { checked: isSuperAdmin },
      enumerable: true,
    });
    this.checkAllAdd(syntheticEvent);
    this.checkAllEdit(syntheticEvent);
    this.checkAllViews(syntheticEvent);
  }

  save() {
    this.selectedModulePermission = [];
    this.selectedFieldPermission = [];
    this.selectedTabpermission = [];
    this.responsedata.forEach((module, index) => {
      let canViewValue = 0;
      let canInsertValue = 0;
      let canUpdateValue = 0;
      if (this.rolePermissionForm.get('chkView_' + index)?.value) {
        canViewValue = 1;
      }
      if (this.rolePermissionForm.get('chkAdd_' + index)?.value) {
        canInsertValue = 1;
      }
      if (this.rolePermissionForm.get('chkEdit_' + index)?.value) {
        canUpdateValue = 1;
      }
      if (canViewValue || canInsertValue || canUpdateValue) {
        this.selectedModulePermission.push({
          pageID: module.pageID,
          canView: canViewValue,
          canInsert: canInsertValue,
          canUpdate: canUpdateValue,
        });
      }
    });
    this.fieldsData.forEach((field, index) => {
      let canViewValue = 0;
      let canInsertValue = 0;

      if (this.rolePermissionForm.get('chkFieldView_' + index)?.value) {
        canViewValue = 1;
      }
      if (this.rolePermissionForm.get('chkFieldAdd_' + index)?.value) {
        canInsertValue = 1;
      }
      if (canViewValue || canInsertValue) {
        this.selectedFieldPermission.push({
          fieldID: field.adminFieldID,
          canView: canViewValue,
          canInsert: canInsertValue,
        });
      }
    });
    this.tabsData.forEach((tab, index) => {
      let canViewValue = 0;
      let canInsertValue = 0;

      if (this.rolePermissionForm.get('chkTabView_' + index)?.value) {
        canViewValue = 1;
      }
      if (this.rolePermissionForm.get('chkTabAdd_' + index)?.value) {
        canInsertValue = 1;
      }
      if (canViewValue || canInsertValue) {
        this.selectedTabpermission.push({
          tabID: tab.adminTabID,
          canView: canViewValue,
          canInsert: canInsertValue,
        });
      }
    });
    if (this.editMode) {
      this.dataLoaded = false;
      this.loadingMessage = 'Updating...';
      let payload = {
        roleID: this.roleID,
        roleName: this.rolePermissionForm.get('roleName')?.value,
        description: this.rolePermissionForm.get('description')?.value,
        isActive: this.rolePermissionForm.get('isActive')?.value,
        isSuperAdmin: this.rolePermissionForm.get('isSuperAdmin')?.value,
        modulePermissions: this.selectedModulePermission,
        fieldPermissions: this.selectedFieldPermission,
        tabPermissions: this.selectedTabpermission,
      };

      this.addEditRoleservice.updateRole(payload).subscribe({
        next: (data: any) => {
          this.dataLoaded = true;
          this.alert.fireAlert('Updated').then(() => {
            this.back();
          });
        },
        error: (error) => {
          this.dataLoaded = true;

        },
      });
    } else {
      this.dataLoaded = false;
      this.loadingMessage = 'Saving...';
      let payload = {
        roleName: this.rolePermissionForm.get('roleName')?.value,
        description: this.rolePermissionForm.get('description')?.value,
        isActive: this.rolePermissionForm.get('isActive')?.value,
        isSuperAdmin: this.rolePermissionForm.get('isSuperAdmin')?.value,
        modulePermissions: this.selectedModulePermission,
        fieldPermissions: this.selectedFieldPermission,
        tabPermissions: this.selectedTabpermission,
      };

      this.addEditRoleservice.addRole(payload).subscribe({
        next: (data: any) => {
          this.dataLoaded = true;
          this.alert.fireAlert('Saved').then(() => {
            this.back();
          });
        },
        error: (error) => {
          this.dataLoaded = true;
        },
      });
    }
  }

  cancel() {
    if (this.editMode) {
      this.getroledetails(this.roleID);
    } else {
      this.rolePermissionForm.reset();
    }
  }
  changeView(index: number) {
    if (
      this.rolePermissionForm.get('chkAdd_' + index)?.value ||
      this.rolePermissionForm.get('chkEdit_' + index)?.value
    ) {
      this.rolePermissionForm.get('chkView_' + index)?.setValue(true);
    }
  }

  changeModuleAddEdit(index: number) {
    if (
      this.rolePermissionForm.get('chkView_' + index)?.value === false &&
      this.rolePermissionForm.get('chkAdd_' + index)?.value
    ) {
      this.rolePermissionForm.get('chkAdd_' + index)?.setValue(false);
    }
    if (
      this.rolePermissionForm.get('chkEdit_' + index)?.value &&
      !this.rolePermissionForm.get('chkView_' + index)?.value
    ) {
      this.rolePermissionForm.get('chkEdit_' + index)?.setValue(false);
    }
  }

  changeFieldView(index: number) {
    if (this.rolePermissionForm.get('chkFieldAdd_' + index)?.value) {
      this.rolePermissionForm.get('chkFieldView_' + index)?.setValue(true);
    }
  }

  changeFieldAddEdit(index: number) {
    if (
      this.rolePermissionForm.get('chkFieldView_' + index)?.value === false &&
      this.rolePermissionForm.get('chkFieldAdd_' + index)?.value
    ) {
      this.rolePermissionForm.get('chkFieldAdd_' + index)?.setValue(false);
    }
  }

  changeTabView(index: number) {
    if (this.rolePermissionForm.get('chkTabAdd_' + index)?.value) {
      this.rolePermissionForm.get('chkTabView_' + index)?.setValue(true);
    }
  }

  changeTabAddEdit(index: number) {
    if (
      this.rolePermissionForm.get('chkTabView_' + index)?.value === false &&
      this.rolePermissionForm.get('chkTabAdd_' + index)?.value
    ) {
      this.rolePermissionForm.get('chkTabAdd_' + index)?.setValue(false);
    }
  }

  back() {
    this.router.navigate(['/Admin/manageroles']);
  }
}
