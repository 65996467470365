import { Component, OnInit } from '@angular/core';
import { OptionHedgeService } from '../../services/OptionHedge/option-hedge.service';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-option-hedge',
  templateUrl: './option-hedge.component.html',
  styleUrl: './option-hedge.component.css',
})
export class OptionHedgeComponent implements OnInit {
  optionHedgeRMList$!: Observable<any[]>;
  clientOfRMList$!: Observable<any[]>;
  contactOfclient$!: Observable<any[]>;
  structures: ProductStructure[] = [
    {
      list: [],
      name: 'LHS Structures (Importer)',
      description: 'For protection against a depreciating AUD',
    },
    {
      list: [],
      name: 'RHS Structures (Exporter)',
      description: 'For protection against an appreciating AUD',
    },
  ];

  selectedRelationshipManager: any;
  selectedClient: any;
  selectedContact: any;
  NormalProductList: any = [];
  isAdmin=true;
  constructor(
    private optionHedgeService: OptionHedgeService,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService,
    private route:ActivatedRoute
  ) {}

  ngOnInit(): void {
    const user=this.route.snapshot.pathFromRoot[1].url[0].path;
    if(user==='Admin'){
      this.isAdmin=true;
      this.getOptionHedgeRMsList();
    }
    else if(user==='Client'){
      this.isAdmin=false;
      this.getProductListForClient();

    }
  }
  
  getProductListForClient(){
    const clientInfo=this.encryptPermission.getDecryptedPermissions('usercrd');
    const clientID=clientInfo?.clientID;
    this.optionHedgeService.getProductListForClient(clientID).subscribe({
      next: (response: any) => {
        const products = response.products;
        
        products.forEach((product: any) => {
          const name: string = product.productName;
          if (name[0] === 'L') {
            this.structures[0].list.push(name);
          } else if (name[0] === 'R') {
            this.structures[1].list.push(name);
          } else {
            this.NormalProductList.push(name);
          }
        });
      },

    })
  }


  getOptionHedgeRMsList() {
    this.optionHedgeService.getOptionHedgeRM().subscribe({
      next: (response: any) => {
        this.optionHedgeRMList$ = of(response);
      },
    });
  }

  getClientOfRMList() {
    this.selectedClient = null;
    this.structures[0].list = [];
    this.structures[1].list = [];
    this.NormalProductList = [];
    if (this.selectedRelationshipManager) {
      this.optionHedgeService
        .getClientsOfRM(this.selectedRelationshipManager)
        .subscribe({
          next: (response: any) => {
            this.clientOfRMList$ = of(response);
          },
        });
    }
  }

  getContactsOfClient() {
    this.selectedContact = null;
    this.structures[0].list = [];
    this.structures[1].list = [];
    this.NormalProductList = [];
    if (this.selectedClient) {
      this.encryptPermission.encryption(this.selectedClient.clientName,'selectedName');
      // this.optionHedgeService.clientName = of(this.selectedClient.clientName);
      this.optionHedgeService
        .getConactOfClient(this.selectedClient.clientID)
        .subscribe({
          next: (response: any) => {
            const products = response.products;
            this.contactOfclient$ = of(response.contacts);
            products.forEach((product: any) => {
              const name: string = product.productName;
              if (name[0] === 'L') {
                this.structures[0].list.push(name);
              } else if (name[0] === 'R') {
                this.structures[1].list.push(name);
              } else {
                this.NormalProductList.push(name);
              }
            });
          },
        });
    }
  }
  redirectToSpecificProductPage(productName: string) {
    const newProductName = productName.replaceAll(/\s/g, '');
    if(this.isAdmin){
      this.router.navigate([
        `Admin/${newProductName}`,
        this.selectedClient.clientID,
      ]);

    }
    else{
      this.router.navigate([
        `Client/${newProductName}`
      ]);
    }
    
  }

  changeURL(product: string) {
    const spaceRemovedProduct = product.replaceAll(/\s/g, '').toLowerCase();
    const baseSrc = '../../../../assets/';
    return baseSrc + spaceRemovedProduct + '.svg';
  }
}

interface ProductStructure {
  list: any[];
  name: string;
  description: string;
}
