<div id="AdminClientlistOptiondiv">
    <main>
        <div class="inner secondary user-dashboard">
            <div class="card-wrapper">
                <div class="card is-quote-listing">
                    <div *ngIf="isAdmin" class="row">
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label>Relationship Manager</label>

                                <ng-select [items]="optionHedgeRMList$ | async" bindLabel="managerName"
                                    bindValue="managerID" [(ngModel)]="selectedRelationshipManager"
                                    (change)="getClientOfRMList()">

                                </ng-select>



                            </div>

                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label>Client</label>
                                <ng-select [items]="clientOfRMList$ | async" bindLabel="clientName" 
                                    [(ngModel)]="selectedClient" (change)="getContactsOfClient()">

                                </ng-select>
                            </div>

                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group">
                                <label>Contact</label>
                                <ng-select [items]="contactOfclient$ | async" bindLabel="fullName"
                                    bindValue="contactID">

                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div id="productDetails">
                        <div class="is-quote-listing">
                            <h4 class="title">Strategy</h4>
                            <div class="card-listing">
                                <div class="row">
                                    <div class="col-lg-6">

                                        <ul class="lhs-rhs-listing" *ngIf="NormalProductList.length>0">

                                            <li *ngFor="let Product of NormalProductList;index as i">
                                                <a (click)="redirectToSpecificProductPage(Product)"
                                                    class="btn  btn-success">


                                                    <em>
                                                        <img src='' alt="" class="image-light">
                                                        <img src='' alt="" class="image-dark">
                                                    </em>

                                                    {{Product}}

                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <div class="row">

                                    <div class="col-lg-6" *ngFor="let structure of structures;index as i">
                                        <div class="card is-blue">
                                            <div class="card-header">
                                                <div class="header-inner">
                                                    <h6>{{structure.name}}</h6>
                                                    <div class="tooltip-wrapper" tabindex="1">
                                                        <img src="../../../../assets/info-white.svg" />
                                                        <div class="custom-tooltip rhs-tooltip">
                                                            {{structure.description}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">

                                                <ul class="lhs-rhs-listing" *ngIf="structure.list.length>0">

                                                    <li *ngFor="let Product of structure.list;index as i">
                                                        <a (click)="redirectToSpecificProductPage(Product)"
                                                            class="btn  btn-success">


                                                            <em>
                                                                <img [src]="changeURL(Product)" alt="" class="image-light">
                                                                 
                                                            </em>

                                                            {{Product}}

                                                        </a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
</div>