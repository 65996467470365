import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ClientNoteRequest } from '../../components/client-note-tab/models/ClientNote.model';

@Injectable({
  providedIn: 'root',
})
export class ClientNoteService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getClientNotes(clientID: number) {
    return this.http.get(this.apiUrl + 'Client/Notes/Lists/' + clientID);
  }

  updateClientNote(payload: ClientNoteRequest) {
    return this.http.patch(this.apiUrl + 'Client/Notes', payload);
  }

  addClientNote(payload: ClientNoteRequest) {
    return this.http.post(this.apiUrl + 'Client/Notes', payload);
  }
}
