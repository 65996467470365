import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ContactRole } from '../../components/user-roles/user-roles.component';
@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getContactRole(payload: any): Observable<ContactRole[]> {
    return this.http.post<ContactRole[]>(this.apiUrl + 'ContactRole/Search', payload);
  }

  deleteRole(id: any) {
    return this.http.delete(
      this.apiUrl + 'ContactRole/delete?clientRoleID=' + id
    );
  }

  changeStatus(roleid:any,isactive:any) {
    const payload = {
      clientRoleID: roleid,
      isActive:isactive,
    };
    return this.http.patch(this.apiUrl + 'ContactRole/updatestatusflag',payload);
  }
}
