import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientCRMService } from '../../services/ClientCRM/client-crm.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import {
  CRMFieldPermission,
  ErrorMessages,
  Percent,
  SystemConstant,
} from '../../global/global.constant';
import Swal from 'sweetalert2';
import { CRMDetails } from './models/CRM.model';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-clientcrm',
  templateUrl: './clientcrm.component.html',
  styleUrl: './clientcrm.component.css',
})
export class ClientcrmComponent implements OnInit {
  originalSE = [];
  AE = [];
  SE = [];
  BDM = [];
  Dealer = [];
  clientID: any;
  clientCRMData = {};
  tabpermission!: any;
  fieldsPermissions!: any;
  crmFields: any = [];
  loading = false;
  aeViewFlag: boolean[] = [false, false, false, false];
  seViewFlag: boolean[] = [false, false, false, false];
  bdmViewFlag: boolean[] = [false, false, false, false];
  dealerViewFlag: boolean[] = [false, false, false, false];
  originalSEViewFlag: boolean = false;
  onboardedDateFlag: boolean = false;
  firstTradeDateFlag: boolean = false;
  lastTradeDateFlag: boolean = false;
  daysLastTradeDateFlag: boolean = false;
  totalTradeDateFlag: boolean = false;
  revenueGeneratedFlag: boolean = false;
  canIsertTabPermission: boolean = false;
  percentMin: number = Percent.percentMin;
  percentMax: number = Percent.percentMax;
  constructor(
    private fb: FormBuilder,
    private clientcrmservice: ClientCRMService,
    private encryptPermission: EncryptRolePermissionService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private sweetAlert: SweetalertService
  ) {}
  CRMform: FormGroup = this.fb.group({
    clientID: [],
    originalSEUserID: [null],
    originalSEUserName: [null],
    aE1UserID: [null],
    aE1UserName: [null],
    aE2UserID: [null],
    aE2UserName: [null],
    sE1UserID: [null],
    sE1UserName: [null],
    sE2UserID: [null],
    sE2UserName: [null],
    bdm1UserID: [null],
    bdm1UserName: [null],
    bdm2UserID: [null],
    bdm2UserName: [null],
    dealer1UserID: [null, [Validators.required]],
    dealer1UserName: [null],
    dealer2UserID: [null],
    dealer2UserName: [null],
    aE1Percent: [null],
    aE2Percent: [null],
    sE1Percent: [null],
    sE2Percent: [null],
    bdm1Percent: [null],
    bdm2Percent: [null],
    dealer1Percent: [null],
    dealer2Percent: [null],
    onboardedDate: [{ value: null, disabled: true }],
    totalNoOfTrades: [{ value: null, disabled: true }],
    firstTradeDate: [{ value: null, disabled: true }],
    lastTradeDate: [{ value: null, disabled: true }],
    revenueGenerated: [{ value: null, disabled: true }],
    noOfDaysSinceLastTraded: [{ value: null, disabled: true }],
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
    });
    this.intialFormdata();
    this.getCRMListData();
    this.getFieldsTabPermission();
  }

  getFieldsTabPermission(): void {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      this.tabpermission =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).tabPermissions;

      if (this.tabpermission) {
        var permission = this.tabpermission[0]?.canInsert;
        if (!permission) {
          this.CRMform.disable();
          this.canIsertTabPermission = false;
        } else {
          this.canIsertTabPermission = true;
        }
      }
      this.fieldsPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).fieldPermissions;
      this.setFieldPermission();
    }
  }
  setFieldPermission() {
    this.crmFields = this.fieldsPermissions.filter(
      (item: { fieldID: number }) =>
        item.fieldID >= CRMFieldPermission.firstFieldId &&
        item.fieldID <= CRMFieldPermission.lastFieldId
    );
    this.crmFields.forEach(
      (field: { fieldID: number; canView: number; canInsert: number }) => {
        switch (field.fieldID) {
          case 34:
            field.canView == CRMFieldPermission.canView
              ? (this.originalSEViewFlag = true)
              : (this.originalSEViewFlag = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['originalSEUserID'].disable();
            }
            break;
          case 35:
            field.canView == CRMFieldPermission.canView
              ? (this.aeViewFlag[0] = true)
              : (this.aeViewFlag[0] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['aE1UserID'].disable();
            }
            break;
          case 36:
            field.canView == CRMFieldPermission.canView
              ? (this.aeViewFlag[1] = true)
              : (this.aeViewFlag[1] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['aE1Percent'].disable();
            }
            break;
          case 37:
            field.canView == CRMFieldPermission.canView
              ? (this.seViewFlag[0] = true)
              : (this.seViewFlag[0] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['sE1UserID'].disable();
            }
            break;
          case 38:
            field.canView == CRMFieldPermission.canView
              ? (this.seViewFlag[1] = true)
              : (this.seViewFlag[1] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['sE1Percent'].disable();
            }
            break;
          case 39:
            field.canView == CRMFieldPermission.canView
              ? (this.bdmViewFlag[0] = true)
              : (this.bdmViewFlag[0] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['bdm1UserID'].disable();
            }
            break;
          case 40:
            field.canView == CRMFieldPermission.canView
              ? (this.bdmViewFlag[1] = true)
              : (this.bdmViewFlag[1] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['bdm1Percent'].disable();
            }
            break;
          case 41:
            field.canView == CRMFieldPermission.canView
              ? (this.dealerViewFlag[0] = true)
              : (this.dealerViewFlag[0] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['dealer1UserID'].disable();
            }
            break;
          case 42:
            field.canView == CRMFieldPermission.canView
              ? (this.dealerViewFlag[1] = true)
              : (this.dealerViewFlag[1] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['dealer1Percent'].disable();
            }
            break;
          case 43:
            field.canView == CRMFieldPermission.canView
              ? (this.aeViewFlag[2] = true)
              : (this.aeViewFlag[0] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['aE2UserID'].disable();
            }
            break;
          case 44:
            field.canView == CRMFieldPermission.canView
              ? (this.aeViewFlag[3] = true)
              : (this.aeViewFlag[4] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['aE2Percent'].disable();
            }
            break;
          case 45:
            field.canView == CRMFieldPermission.canView
              ? (this.seViewFlag[2] = true)
              : (this.seViewFlag[2] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['sE2UserID'].disable();
            }
            break;
          case 46:
            field.canView == CRMFieldPermission.canView
              ? (this.seViewFlag[3] = true)
              : (this.seViewFlag[3] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['sE2Percent'].disable();
            }
            break;
          case 47:
            field.canView == CRMFieldPermission.canView
              ? (this.bdmViewFlag[2] = true)
              : (this.bdmViewFlag[2] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['bdm2UserID'].disable();
            }
            break;
          case 48:
            field.canView == CRMFieldPermission.canView
              ? (this.bdmViewFlag[3] = true)
              : (this.bdmViewFlag[3] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['bdm2Percent'].disable();
            }
            break;
          case 49:
            field.canView == CRMFieldPermission.canView
              ? (this.dealerViewFlag[2] = true)
              : (this.dealerViewFlag[2] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['dealer2UserID'].disable();
            }
            break;
          case 50:
            field.canView == CRMFieldPermission.canView
              ? (this.dealerViewFlag[3] = true)
              : (this.dealerViewFlag[3] = false);
            if (field.canInsert == 0) {
              this.CRMform.controls['dealer2Percent'].disable();
            }
            break;
          case 51:
            field.canView == CRMFieldPermission.canView
              ? (this.onboardedDateFlag = true)
              : (this.onboardedDateFlag = false);
            break;

          case 52:
            field.canView == CRMFieldPermission.canView
              ? (this.firstTradeDateFlag = true)
              : (this.firstTradeDateFlag = false);
            break;

          case 53:
            field.canView == CRMFieldPermission.canView
              ? (this.lastTradeDateFlag = true)
              : (this.lastTradeDateFlag = false);
            break;

          case 54:
            field.canView == CRMFieldPermission.canView
              ? (this.daysLastTradeDateFlag = true)
              : (this.daysLastTradeDateFlag = false);
            break;

          case 55:
            field.canView == CRMFieldPermission.canView
              ? (this.totalTradeDateFlag = true)
              : (this.totalTradeDateFlag = false);
            break;

          case 56:
            field.canView == CRMFieldPermission.canView
              ? (this.revenueGeneratedFlag = true)
              : (this.revenueGeneratedFlag = false);
            break;
        }
      }
    );
  }

  getCRMListData() {
    this.clientcrmservice.getClientCRMLists().subscribe({
      next: (data: any) => {
        this.originalSE = data.originalSE;
        this.AE = data.ae;
        this.SE = data.se;
        this.BDM = data.bdm;
        this.Dealer = data.dealer;
      },
      error: (error) => {},
    });
  }
  intialFormdata() {
    this.loading = true;
    this.clientcrmservice.getCRMClientData(this.clientID).subscribe({
      next: (data: CRMDetails) => {
        if (data.onboardedDate != null) {
          data.onboardedDate = this.datePipe.transform(
            data.onboardedDate,
            'dd/MM/yyyy',
            'en-US'
          );
        }
        if (data.firstTradeDate != null) {
          data.firstTradeDate = this.datePipe.transform(
            data.firstTradeDate,
            'dd/MM/yyyy',
            'en-US'
          );
        }
        if (data.lastTradeDate != null) {
          data.lastTradeDate = this.datePipe.transform(
            data.lastTradeDate,
            'dd/MM/yyyy',
            'en-US'
          );
        }
        this.CRMform.controls['bdm1UserID'].setValue(data.bdM1UserID);
        this.CRMform.controls['bdm2UserID'].setValue(data.bdM2UserID);
        this.CRMform.controls['bdm1Percent'].setValue(data.bdM1Percent);
        this.CRMform.controls['bdm2Percent'].setValue(data.bdM2Percent);
        this.CRMform.controls['aE2UserID'].setValue(data.aE2UserID);
        this.CRMform.controls['aE2Percent'].setValue(data.aE2Percent);
        this.CRMform.controls['aE1UserID'].setValue(data.aE1UserID);
        this.CRMform.controls['aE1Percent'].setValue(data.aE1Percent);
        this.CRMform.controls['dealer1UserID'].setValue(data.dealer1UserID);
        this.CRMform.controls['dealer1Percent'].setValue(data.dealer1Percent);
        this.CRMform.controls['dealer2UserID'].setValue(data.dealer2UserID);
        this.CRMform.controls['dealer2Percent'].setValue(data.dealer2Percent);
        this.CRMform.controls['sE2UserID'].setValue(data.sE2UserID);
        this.CRMform.controls['sE2Percent'].setValue(data.sE2Percent);
        this.CRMform.controls['sE1UserID'].setValue(data.sE1UserID);
        this.CRMform.controls['sE1Percent'].setValue(data.sE1Percent);
        this.CRMform.patchValue(data);

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
  getParsedValue(value: any) {
    if (value === '') {
      return null;
    } else if (parseInt(value) === 0) {
      return null;
    }
    return value;
  }
  onSave() {
    if (this.CRMform.valid) {
      this.loading = true;
      var formValue = this.CRMform.getRawValue();
      const payload = {
        clientID: formValue.clientID,
        originalSEUserID: this.getParsedValue(
          this.CRMform.controls['originalSEUserID'].value
        ),
        aE1UserID: this.getParsedValue(
          this.CRMform.controls['aE1UserID'].value
        ),
        aE2UserID: this.getParsedValue(
          this.CRMform.controls['aE2UserID'].value
        ),
        sE1UserID: this.getParsedValue(
          this.CRMform.controls['sE1UserID'].value
        ),
        sE2UserID: this.getParsedValue(
          this.CRMform.controls['sE2UserID'].value
        ),
        bdm1UserID: this.getParsedValue(
          this.CRMform.controls['bdm1UserID'].value
        ),
        bdm2UserID: this.getParsedValue(
          this.CRMform.controls['bdm2UserID'].value
        ),
        dealer1UserID: this.getParsedValue(
          this.CRMform.controls['dealer1UserID'].value
        ),
        dealer2UserID: this.getParsedValue(
          this.CRMform.controls['dealer2UserID'].value
        ),
        aE1Percent:
          formValue.aE1Percent === '' ? null : parseFloat(formValue.aE1Percent),
        aE2Percent:
          formValue.aE2Percent === '' ? null : parseFloat(formValue.aE2Percent),
        sE1Percent:
          formValue.sE1Percent === '' ? null : parseFloat(formValue.sE1Percent),
        sE2Percent:
          formValue.sE2Percent === '' ? null : parseFloat(formValue.sE2Percent),
        bdm1Percent:
          formValue.bdm1Percent === ''
            ? null
            : parseFloat(formValue.bdm1Percent),
        bdm2Percent:
          formValue.bdm2Percent === ''
            ? null
            : parseFloat(formValue.bdm2Percent),
        dealer1Percent:
          formValue.dealer1Percent === ''
            ? null
            : parseFloat(formValue.dealer1Percent),
        dealer2Percent:
          formValue.dealer2Percent === ''
            ? null
            : parseFloat(formValue.dealer2Percent),
      };
      this.clientcrmservice.updateCRMClientData(payload).subscribe({
        next: () => {
          this.loading = false;
          Swal.fire({
            icon: 'success',
            title: SystemConstant.SuccessMessages,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          });
        },
        error: (error) => {
          this.loading = false;
          this.sweetAlert.showError(error, ErrorMessages.UpdateFailed);
        },
      });
    } else {
      this.markFormGroupTouched(this.CRMform);
    }
  }

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  };

  dealerValidation = (event: any, propertyName: string): void => {
    if (event) {
      var dealer1 = this.CRMform.value.dealer1UserID;
      var dealer2 = this.CRMform.value.dealer2UserID;
      if (dealer1 && dealer2 && dealer1 == dealer2) {
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.AvoidDuplicateDealer,
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
        this.CRMform.controls[propertyName].setValue(null);
      }
    }
  };

  AEValidation = (event: any, propertyName: string): void => {
    if (event) {
      var AE1 = this.CRMform.value.aE1UserID;
      var AE2 = this.CRMform.value.aE2UserID;
      if (AE1 && AE2 && AE1 == AE2) {
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.AvoidDuplicateAE,
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
        this.CRMform.controls[propertyName].setValue(null);
      }
    }
  };

  SEValidation = (event: any, propertyName: string): void => {
    if (event) {
      var SE1 = this.CRMform.value.sE1UserID;
      var SE2 = this.CRMform.value.sE2UserID;
      if (SE1 && SE2 && SE1 == SE2) {
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.AvoidDuplicateSE,
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
        this.CRMform.controls[propertyName].setValue(null);
      }
    }
  };

  BDM1Validation = (event: any, propertyName: string): void => {
    if (event) {
      var BDM1 = this.CRMform.value.bdm1UserID;
      var BDM2 = this.CRMform.value.bdm2UserID;
      if (BDM1 && BDM2 && BDM1 == BDM2) {
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.AvoidDuplicateBDM,
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
        this.CRMform.controls[propertyName].setValue(null);
      }
    }
  };
}
