import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  
  constructor(private http:HttpClient,@Inject(DOCUMENT) private document: Document){
    
    

  }
  
  
   
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const jwtToken=this.document.defaultView?.localStorage?.getItem('jwtToken');
    
    
      
      req = req.clone({
        headers: req.headers.set("Authorization",
        `Bearer ${jwtToken}`),
        
        
      });
      
      return next.handle(req);
  }
}