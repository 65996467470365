import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class RolePermissonService {
   private apiUrl= environment.apiUrl+"AdminRole/" ;
  constructor(private http: HttpClient) { }
  getRolePermissionData(id:number): Observable<any> {
    return this.http.get<any>(this.apiUrl+id); 
  }

  getSidebarData(): Observable<any>{
    return this.http.get<any>(this.apiUrl + "getpages");    
  }
}
