import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import {
  ClientPathMapping,
  clintSidenavData,
} from '../../../../Constants/constants';
import { EncryptRolePermissionService } from '../../../../services/rolePermissions/role-permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '../../../services/login/login.service';
import { jwtDecode } from 'jwt-decode';
import { ClientInfo, JWTTokenInfo } from '../../../../components/models/common.model';
import { AdminUser_ById } from '../../../../global/global.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tenant } from '../../../../components/add-edit-tenant/models/tenant.model';
import { CARPartner } from '../../../../components/add-edit-car-partner/models/CarPartner.model';

@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {
  dataLoaded: boolean = false;
  mode: any = 'side';
  isMenubarOpen = true;
  contentMargin = 300;
  openSidebarover: boolean = true;
  backupMenuFlag: boolean = true;
  currentYear = new Date().getFullYear();
  roleData: any = {
    clientRoleID: 0,
    roleName: '',
    description: '',
    isActive: true,
    modulePermissions: [
      {
        clientPageID: 0,
        canView: 0,
        canInsert: 0,
        canUpdate: 0,
        canDelete: 0,
      },
    ],
  };
  @ViewChild('sidenav') sidenav!: any;

  roleID: number = 0;
  hide: boolean = true;
  viewFlags: boolean[] = new Array(40).fill(true);
  hasMenuflag: boolean[] = new Array(7).fill(false);
  activationFlag: boolean[] = new Array(100).fill(false);

  sidebarData: any[] = [];
  tempDataValue: any[] = [];

  currentSelected: number =
    this.activationFlag.findIndex((x) => x == true) == undefined || null
      ? -1
      : this.activationFlag.findIndex((x) => x == true);
  previousSelected: number =
    this.activationFlag.findIndex((x) => x == true) == undefined || null
      ? -1
      : this.activationFlag.findIndex((x) => x == true);

  childrenActivatedId: number = -1;

  isCollapsed: boolean = false;
  isPayee: boolean = true;
  isMenuOpen: boolean[] = Array(7).fill(false);
  isNavOpen: boolean = false;
  currentToggleId: any;
  prevToggleId: any;
  hidehamburger: boolean = false;
  updatedRoleData: any[] = [];
  sidePanelLogo: string = "../../../../assets/logo-dashboard.png"

  constructor(
    private encryptPermission: EncryptRolePermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private loginservice: LoginService,

    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    
    const clientinfo: ClientInfo = this.encryptPermission.getDecryptedPermissions('usercrd');
    this.roleData = this.encryptPermission.getDecryptedPermissions('crole');
    const tokenDecoded: JWTTokenInfo = jwtDecode(clientinfo.jwtToken);
    this.displaySidePanelLogo(tokenDecoded);
    this.Username = clientinfo?.fullName;
    this.dataLoaded = false;
    this.getSidebarData().then((_) => {
      this.setRolePermission();
      const urlPath = this.router.url.toString();
      let activatedLink = ClientPathMapping.Path.filter(
        (path) => path.path == urlPath
      )[0]?.pageID;
      let openMenuId = ClientPathMapping.Path.filter(
        (path) => path.path == urlPath
      )[0]?.menuId;
      let extraField = ClientPathMapping.Path.filter(
        (path) => path.path == urlPath
      )[0]?.extraModule;

      if (activatedLink == undefined || activatedLink == null) {
        const tempPath =
          this.route.snapshot.children[0].routeConfig?.path?.toString();
        activatedLink = ClientPathMapping.Path.filter(
          (path) => path.path == tempPath
        )[0]?.pageID;
        openMenuId = ClientPathMapping.Path.filter(
          (path) => path.path == tempPath
        )[0]?.menuId;
        extraField = ClientPathMapping.Path.filter(
          (path) => path.path == tempPath
        )[0]?.extraModule;
      }

      if (activatedLink != undefined && openMenuId != undefined) {
        this.currentSelected = activatedLink;
        this.activationRedirection(
          activatedLink,
          Number(openMenuId),
          'indirect',
          extraField,
          urlPath
        );
      } else {
      }
    });
  }

  activationRedirection(
    index: number,
    toggleId: number,
    routeDirection: string,
    hasSubField: any,
    subPath: string
  ) {
    if (toggleId != -1) {
      this.tempDataValue.map((item) => {
        for (var i = 0, l = item.elements.length; i < l; i++) {
          if (item.elements[i].pageID == index) {
            this.childrenActivatedId = item.id;
          }
        }
      });
      const active = this.document.getElementById(
        String(this.childrenActivatedId)
      );
      active?.classList.add('highlightActivated');
    } else {
      const prevactive = this.document.getElementById(
        String(this.prevToggleId)
      );
      prevactive?.classList.remove('highlightActivated');
    }

    this.previousSelected = this.currentSelected;
    this.currentSelected = index;

    if (this.activationFlag.filter((x) => x == false).length != 100) {
      this.activationFlag[this.activationFlag.findIndex((x) => x == true)] =
        false;
    }
    this.activationFlag[index] = true;

    if (toggleId != -1) {
      this.toggleMenu(toggleId);
    }

    if (routeDirection == 'direct') {
      this.toggleMenu(toggleId);
    }

    if (!hasSubField) {
      const pathValue = ClientPathMapping.Path.filter(
        (path) => path.pageID == index
      )[0]?.path;
      if (pathValue != undefined || pathValue != null) {
        this.router.navigate([pathValue]);
      }
    } else {
      const pathValue = subPath;
      this.router.navigate([pathValue]);
    }
  }

  getSidebarData() {
    
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      let toggleCount = 0;
      this.sidebarData = clintSidenavData;
      this.setRolePermission();
      this.sidebarData = this.updatedRoleData;
      this.sidebarData
        .sort((a, b) => a.moduleOrderNo - b.moduleOrderNo)
        .forEach((element: any) => {
          if (
            this.tempDataValue.filter((j) => j.ParentName == element.moduleName)
              .length == 0
          ) {
            let check = this.sidebarData.filter(
              (x) => x.moduleName == element.moduleName
            );

            if (check.length > 0 && element.moduleName != element.pageName) {
              let child: any = [];
              check.forEach((data: any) => {
                child.push(data.pageName);
              });

              if (child.length > 0 && element.moduleName != element.pageName) {
                let temp = {
                  id: toggleCount,
                  singleElement: false,
                  ParentName: element.moduleName,
                  ParentId: element.moduleID,
                  child: child,
                  elements: check,
                };
                toggleCount++;
                this.tempDataValue.push(temp);
              }
            } else {
              if (check.length == 1 && element.moduleName == element.pageName) {
                let temp = {
                  id: -1,
                  singleElement: true,
                  ParentName: element.moduleName,
                  ParentId: element.moduleID,
                  child: [],
                  elements: element,
                };
                this.tempDataValue.push(temp);
              }
            }
          }
        });
      this.dataLoaded = true;

      resolve();
    });
    return promise;
  }

  setRolePermission() {
    if(this.roleData){
    this.roleData.modulePermissions.forEach(
      (
        item: {
          clientPageID: any;
          clientPage: any;
        },
        index: any
      ) => {
        if(item.clientPageID==6 || item.clientPageID==5){
          const temp = this.sidebarData.find(
            (page) => page.pageName === "Reports"
          );
          if (temp) {
           if(!this.updatedRoleData.find((page) => page.pageName === "Reports"))
            this.updatedRoleData.push(temp);          }
        }
        else{
        const temp = this.sidebarData.find(
          (page) => page.pageName === item.clientPage.pageName
        );
        if (temp) this.updatedRoleData.push(temp);
        }
      }
    ); 
  }
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }
  toggleMenu(index: number): void {
    const tempPrev = this.prevToggleId;
    this.prevToggleId = this.currentToggleId;
    this.currentToggleId = index;
    const prevactive = this.document.getElementById(String(this.prevToggleId));
    prevactive?.classList.remove('highlightActivated');
    const active = this.document.getElementById(String(this.currentToggleId));
    active?.classList.add('highlightActivated');
    if (index == this.childrenActivatedId) {
      const active = this.document.getElementById(String(this.currentToggleId));
      active?.classList.add('highlightActivated');
    } else {
      const prevactive = this.document.getElementById(
        String(this.prevToggleId)
      );
      prevactive?.classList.remove('highlightActivated');
    }
    this.isMenuOpen[index] = !this.isMenuOpen[index];
    if (this.currentToggleId != this.prevToggleId) {
      this.isMenuOpen[this.prevToggleId] = false;
    }
  }
  visitLink(routerPath: string) {
    this.router.navigate([routerPath]);
  }
  ngOnDestroy(): void {}
  onToolbarMenuToggle() {
    this.isMenubarOpen = !this.isMenubarOpen;
    const active = this.document.getElementById(String(this.currentToggleId));
    const prevactive = this.document.getElementById(String(this.prevToggleId));
    if (!this.isMenubarOpen) {
      prevactive?.classList.remove('highlightActivated');
      active?.classList.add('highlightActivated');
      this.contentMargin = 50;
      this.backupMenuFlag = this.isMenuOpen[this.currentToggleId];
      this.isMenuOpen[this.currentToggleId] = false;
    } else {
      this.contentMargin = 300;
      this.isMenuOpen[this.currentToggleId] = this.backupMenuFlag;
    }
  }
  mouseEnter() {
    if (!this.isMenubarOpen) {
      let element = this.document.getElementById('sidenav');
      if (element) {
        const active = this.document.getElementById(
          String(this.currentToggleId)
        );
        const prevactive = this.document.getElementById(
          String(this.prevToggleId)
        );

        prevactive?.classList.remove('highlightActivated');
        active?.classList.add('highlightActivated');

        this.isMenuOpen[this.currentToggleId] = this.backupMenuFlag;
        this.openSidebarover = true;
        this.mode = 'over';
        this.contentMargin = 300;
        element.classList.add('menu-open');
        element.classList.remove('menu-close');
      }
    }
  }

  mouseLeave() {
    if (!this.isMenubarOpen) {
      let element = this.document.getElementById('sidenav');
      if (element) {
        const active = this.document.getElementById(
          String(this.currentToggleId)
        );
        const prevactive = this.document.getElementById(
          String(this.prevToggleId)
        );

        prevactive?.classList.remove('highlightActivated');
        active?.classList.add('highlightActivated');

        this.isMenuOpen[this.currentToggleId] = false;
        this.openSidebarover = false;
        this.contentMargin = 50;
        element.classList.remove('menu-open');
        element.classList.add('menu-close');
        this.mode = 'side';
      }
    }
  }

  //Toggel navigation bar based on screen width
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 1024) {
      this.isMenubarOpen = true;
      this.hidehamburger = true;
    } else {
      this.isMenubarOpen = false;
      this.hidehamburger = false;
    }
    this.onToolbarMenuToggle();
  }

  /* Header Module */
  Username: string = '';
  isProfileMenuOpen: boolean = false;
  isProfileNavOpen: boolean = false;

  toggleProfileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  changePassword() {
    this.activationFlag[this.currentSelected] = false;
    this.router.navigate(['/Client/changepassword']);
  }

  navigateToMyProfile(): void {
    this.router.navigate(['/Client/userprofile']);
  }

  logout() {
    const localStorage = this.document.defaultView?.localStorage;
    var savedLogin = this.encryptPermission.getDecryptedPermissions('logcred');
    localStorage?.clear();
    this.encryptPermission.encryption(savedLogin, 'logcred');
    this.router.navigate(['']);
    this.loginservice.logout().subscribe({
      next(data: any) {},
      error() {},
    });
  }

  private displaySidePanelLogo = (token: JWTTokenInfo): void => {
    if (token.tenantID && token.tenantID == AdminUser_ById.Tenant) {
      this.loginservice.getTenant(token.tenantID).pipe(untilDestroyed(this)).subscribe({
        next: (result: Tenant) => {
          this.sidePanelLogo = result.sidePanelLogoLight;
        },
        error: (error) => {

        }
      });
    }
    else if (token.carPartnerID && token.carPartnerID == AdminUser_ById.CARPArtner){
      this.loginservice.getCarPartner(token.carPartnerID).pipe(untilDestroyed(this)).subscribe({
        next: (result: CARPartner) => {
          this.sidePanelLogo = result.sidePanelLogoLight;
        },
        error: (error) => {

        }
      });
    }
    else {
      this.sidePanelLogo = "../../../../assets/logo-dashboard.png";
    }
  }
}
