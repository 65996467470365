import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CRMDetails } from '../../components/clientcrm/models/CRM.model';
@Injectable({
  providedIn: 'root'
})
export class ClientCRMService {

  constructor(private http:HttpClient) { }

  apiUrl=environment.apiUrl;
  getClientCRMLists(){
    return this.http.get(this.apiUrl+'Client/crm/lists');
  }

  getCRMClientData(clientID:number): Observable<CRMDetails>{
    return this.http.get<CRMDetails>(this.apiUrl+'Client/crm/'+clientID);
  }

  updateCRMClientData(payload:any){
    return this.http.patch(this.apiUrl+'Client/crm',payload);

  }
}
