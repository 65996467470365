import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientData, ListData, UpdatePayload } from '../../components/client-trading/models/trading.model';
@Injectable({
  providedIn: 'root'
})
export class TradingService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAllListData(clientId: number): Observable<ListData>{
    return this.http.get<ListData>(this.apiUrl+'Client/Trading/GetList/'+ clientId);
  }

  getClientDetails(id: number): Observable<UpdatePayload>{
    return this.http.get<UpdatePayload>(this.apiUrl+'Client/Trading/'+id)
  }

  updateTradingtab(clientData: UpdatePayload){
    return this.http.patch(this.apiUrl+'Client/Trading/Update',clientData);
  }
}
