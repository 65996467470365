import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root',
})
export class ManageContactsService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  searchContact(contactForm: any): Observable<any> {
    if (
      contactForm.selectedTenatCarpartner === null ||
      contactForm.selectedTenatCarpartner === undefined ||
      contactForm.selectedTenatCarpartner === ''
    ) {
      contactForm.selectedTenatCarpartner = 0;
    }
    if (
      contactForm.selectedclinetName === null ||
      contactForm.selectedclinetName === undefined ||
      contactForm.selectedclinetName === ''
    ) {
      contactForm.selectedclinetName = '';
    }
    if (
      contactForm.selectedstatus === null ||
      contactForm.selectedstatus === undefined ||
      contactForm.selectedstatus === ''
    ) {
      contactForm.selectedstatus = 0;
    }
    if (
      contactForm.selectedContacttName === null ||
      contactForm.selectedContacttName === undefined ||
      contactForm.selectedContacttName === ''
    ) {
      contactForm.selectedContacttName = '';
    }
    let payload = {
      tenantOrCARPartnerID: contactForm.selectedTenatCarpartner,
      clientName: contactForm.selectedclinetName,
      contactName: contactForm.selectedContacttName,
      status: contactForm.selectedstatus,
    };
    
    if (payload.status == 'Pendig Authorization') {
      payload.status = 'Pendig_Authorization';
    }
    if(payload.status== 0 ){
      payload.status=null;//setting null for all list
    }
    let url = this.apiUrl + 'Client/contacts/search';
    return this.http.post<any>(url, payload);
  }
  getstatusList(): Observable<any> {   
    let url = this.apiUrl + 'Client/contacts/lists/0';   
    return this.http.get<any>(url);
  }
  getdropDownList(): Observable<any> {   
    let url = this.apiUrl + 'Client/manageclients/getlists';   
    return this.http.get<any>(url);
  }
  getTanentCarPartnerDropDownList(): Observable<any> {   
    let url = this.apiUrl + 'Client/contacts/lists';   
    return this.http.get<any>(url);
  }
  setPrimaryContactFlag(id: number, primaryContactstatus: boolean):Observable<any>{
    let payload = {
      contactID: id,
      isPrimary: primaryContactstatus,
    };
    let url = this.apiUrl + 'Client/contacts/setprimaryflag';
    return this.http.patch<any>(url, payload);
  }
}
