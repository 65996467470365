import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http:HttpClient) { }
  apiurl=environment.apiUrl;

  forgotPassword(payload:any){
    return this.http.post(this.apiurl+'ContactAccount/forgotpassword',payload);
  }
}
