import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { DealhistoryService } from '../../services/DealHistory/dealhistory.service';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreatedByData } from '../../common/components/confirmation-reports/models/confirmation-reports.models';
import Swal from 'sweetalert2';
import { AdminTradeList, Client, ClientTradeList, Product, TradeStatus, tenantCarPartner } from './model/deal-history.model';
import { SystemConstant } from '../../global/global.constant';
import { ActivatedRoute } from '@angular/router';
import { AdminInfo } from '../models/common.model';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { ClientInfo } from '../../client/models/client-info.model';

@UntilDestroy()
@Component({
  selector: 'app-deal-history',
  templateUrl: './deal-history.component.html',
  styleUrl: './deal-history.component.css'
})
export class DealHistoryComponent implements OnInit {
  
  pageSizeOptions = [5, 10, 20, 100, 500];
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginatedData: any[] = [];
  clientTradeData: ClientTradeList[] = [];
  pageEvent: PageEvent | undefined;
  disabled = false;
  loading = false;
  clientPDFHead = ['Trade ID','Product Name', 'Trade Date', 'Value Date','Buy currency','Sell Currency', 'Buy Amount','Sell Amount','Exch Rate'];
  adminPDFHead = ['Tenant Name/CAR Partner', 'Client', 'Trade ID', 'ICETradeID', 'DSDeal ID','DS Error',
    'Product Name', 'Trade Date', 'Value Date', 'buy direction','Buy currency','Sell Currency', 'Buy Amount','Sell Amount','Trade Amount','Exch Rate'
  ];
  tenantCARPartnerObject: tenantCarPartner = {
    id: 0,
    name: '',
    type: ''
  };

  dealHistoryData: any[] = [];
  @ViewChild('content') content!: ElementRef;

  dealHistoryForm: FormGroup = this.fb.group({
    tenantCARPartnerName: [],
    clientName: [],
    tradeIDLike: [],
    tradeStartDate: [],
    tradeEndDate: [],
    productID: new FormControl('All'),
    createdByContactName: [],
    tradeStatus: []
  });

  tenantCARs: tenantCarPartner[] = [];
  clients: Client[] = [];
  products: Product[] = [];
  createdBys: CreatedByData[] = [];
  tradeStatuses: TradeStatus[] = [];
  isAdmin: boolean = false;
  isNavinciUser: boolean = false;
  selectedClientName: string = "";
  selectedCreatedByContact: string = "";
  selectedProductName: string = "";

  constructor(
    private fb: FormBuilder, 
    private route: ActivatedRoute,
    private dealHistoryService: DealhistoryService,
    private renderer: Renderer2,
    private encryptPermission: EncryptRolePermissionService,
  ) { }


  ngOnInit(): void {
    this.checkUser();
    this.getDropdownListData();
    this.search();
  }

  private checkUser = (): void => {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    this.isAdmin = user === SystemConstant.Admin ? true : false;
    this.checkForNavinciUser();
  }

  private checkForNavinciUser = (): void => {
    var savedLogin: AdminInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    this.isNavinciUser =  savedLogin && savedLogin.userType == SystemConstant.Navinci ? true : false;
  }
  

  datepick(event: any) {
    event.target.classList.add('datepicker-dropdown');
    event.target.classList.add('dropdown-menu');
    event.target.classList.add('datepicker-orient-left');
    event.target.classList.add('datepicker-orient-bottom');
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageEvent = pageEvent;
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.dealHistoryData.slice(startIndex, endIndex);
  }

  
  private setClientTradeList = (response: AdminTradeList[]): void => {
    if (response) {
      response.forEach((element: ClientTradeList)  => {
        let tradelist: ClientTradeList = {
          tradeID: element.tradeID,
          productName: element.productName,
          tradeDate: element.tradeDate,
          valueDate: element.valueDate,
          buyCurrency: element.buyCurrency,
          sellCurrency: element.sellCurrency,
          buyAmount: element.buyAmount,
          sellAmount: element.sellAmount,
          rate: element.rate
        }
        this.clientTradeData.push(tradelist);
      });
    }
  }

  search() {
    this.loading = true;
    var formValue = this.dealHistoryForm.value;
    var payload = {
      tenantCARPartnerName: formValue.tenantCARPartnerName ? formValue.tenantCARPartnerName : 'All',
      clientID: formValue.clientName ? formValue.clientName : 0,
      tradeID: formValue.tradeIDLike ? formValue.tradeIDLike : null,
      tradeStartDate: formValue.tradeStartDate ? formValue.tradeStartDate:new Date('2023/01/01'),
      tradeEndDate: formValue.tradeEndDate ? formValue.tradeEndDate:new Date(),
      productID: formValue.productID ? formValue.productID : null,
      createdByID: formValue.createdByContactName ? formValue.createdByContactName : 0,
      tradeStatus: formValue.tradeStatus ? formValue.tradeStatus : 'All'
    };
    this.dealHistoryService.dealHistorySearch(payload, this.isAdmin).subscribe({
      next: (data: AdminTradeList[]) => {
        this.dealHistoryData = data;
        this.paginatedData = data.slice(0, 10);
        this.length = data.length;
        this.pageIndex = 0;
        this.setClientTradeList(data);
        this.loading = false;
      },
      error: (response) => {
        this.loading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      },
    });
  }

  reset() {
    var tenantCarPartnerName: string = "";
    if (this.isAdmin && !this.isNavinciUser) {
      tenantCarPartnerName = this.dealHistoryForm.controls[`tenantCARPartnerName`].value;
    }
    this.dealHistoryForm.reset();
    this.dealHistoryForm.controls[`tenantCARPartnerName`].setValue(tenantCarPartnerName);
    this.paginatedData = [];
    this.dealHistoryData = [];
  }

  getTradeConfo(tradeID: number) {
    this.dealHistoryService.dealHistoryGetTradeConfo(tradeID).subscribe({
      next: (data) => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${tradeID}.pdf`;
        link.click();
      }
    });
  }

  getDropdownListData() {
    this.productListAPICall();
    if (this.isAdmin) {
      this.dealHistoryService.tenantCarpartnerLists().subscribe({
        next: (result: tenantCarPartner[]) => {
          this.tenantCARs = result;
          this.dealHistoryForm.controls["tenantCARPartnerName"].setValue(result[2].name);
          this.tenantCARPartnerObject = result[2];
          this.clientListAPICall();
          //createdBy for admin user
          if (this.isAdmin && !this.isNavinciUser) {
            this.createdByListAPICall(0, this.tenantCARPartnerObject?.id, this.tenantCARPartnerObject?.type);
          }
        }
      });
    } else {
     //createdBy for client user
     //product for client user
      const clientInfo: ClientInfo = this.encryptPermission.getDecryptedPermissions('usercrd');
      if (clientInfo) {
        this.createdByListAPICall(clientInfo.clientID, 0, SystemConstant.Client);
      }
    }
    this.tradeStatusList();
  }
  
  async exportToPdf() {
    this.loading = true;
    const doc = new jsPDF('p', 'mm', 'a4');

    const formattedData: any = this.isAdmin ?  this.dealHistoryData.map(obj => Object.values(obj))
    : this.clientTradeData.map(obj => Object.values(obj));
    var formValue = this.dealHistoryForm.value;
    const appliedFilter = `
      Tenant/CAR partner: ${formValue.tenantCARPartnerName ? formValue.tenantCARPartnerName : 'Not selected'}
      Client: ${formValue.clientName ? this.selectedClientName : 'Not selected' }
      Trade Id: ${formValue.tradeIDLike ? formValue.tradeIDLike : 'Not selected'}
      Trade Start Date: ${formValue.tradeStartDate ? formValue.tradeStartDate : 'Not selected'}
      Trade End Date: ${formValue.tradeEndDate ? formValue.tradeEndDate : 'Not selected'}
      Product: ${formValue.productID ? this.selectedProductName : 'Not selected'}
      Created By: ${formValue.createdByContactName ? this.selectedCreatedByContact : 'Not selected'}
      Trade Status: ${formValue.tradeStatus ? formValue.tradeStatus : 'Not selected'}
    `;
    doc.setFontSize(5);
    doc.text(appliedFilter, 5, 5);
    autoTable(doc, {
      startY: 50,
      head: this.isAdmin ? [this.adminPDFHead] : [this.clientPDFHead],

      tableWidth: 'auto',
      theme: 'plain',
      margin: { left: 0, right: 0, top: 10, bottom: 10 },
      body: formattedData,
      styles: { cellPadding: 0.5, fontSize: 4, cellWidth: 'wrap' },
    });
    doc.save(`Deal Histroy Report.pdf`);
    this.loading = false;
  }

  exportexcel(): void {
    /* table id is passed over here */
    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dealHistoryData);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    var start = this.dealHistoryForm.value.tradeStartDate;
    var end = this.dealHistoryForm.value.tradeEndDate;
    XLSX.writeFile(wb, `Deal Histroy Report.xlsx`);

  }

  onChangeProduct = (event: any): void => {
    if (event) {
      this.selectedProductName = event.productName;
    }
  }

  onChangeCreatedByContact = (event: any): void => {
    if (event) {
      this.selectedCreatedByContact = event.name;
    }
  }

  printPage() {
    const printWindow = window.open('', '_blank', 'width=700,height=600')!;
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write('<table>');
    printWindow.document.write(document.getElementById('dealHistory')!.innerHTML);
    printWindow.document.write('</table></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') { 
      this.openCloseDropdwon("0");
    } 
    else {
      this.openCloseDropdwon("1");
    }
  }

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
      if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
        this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
      }
  }

  private createdByListAPICall = (clientID: number, tenantCarPartnerId: number, userType: string): void => {
    //API call for created by
    this.dealHistoryService.getCreatedByList(clientID, tenantCarPartnerId, userType).pipe((untilDestroyed(this))).subscribe({
      next: (createdByData: CreatedByData[] ) => {
        this.createdBys = createdByData;
        this.loading = false;
      },
      error: (response) => {
        this.loading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  private productListAPICall = (): void => {
    //API call for product
    this.loading = true;
    this.dealHistoryService.getProductList().pipe((untilDestroyed(this))).subscribe({
      next: (result: Product[] ) => {
        this.products = result;
        this.loading = false;
      },
      error: (response) => {
        this.loading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  clientChanged = (event: any): void => {
    if (event && event.clientID >= 0) {
      this.createdByListAPICall(event.clientID, this.tenantCARPartnerObject?.id, this.tenantCARPartnerObject?.type);
      this.selectedClientName = event.name;
    }
    else {
      this.createdBys = [];
      this.dealHistoryForm.controls["createdByContactName"].setValue(undefined);

      this.products = [];
      this.dealHistoryForm.controls["productID"].setValue(null);
    }
  }

  tenantCARsChanged = (event: any): void => {
    if (event) {
      this.tenantCARPartnerObject = event;
      this.clientListAPICall();
    }
    else {
      this.clients = [];
      this.dealHistoryForm.controls["clientName"].setValue(undefined);

      this.createdBys = [];
      this.dealHistoryForm.controls["createdByContactName"].setValue(undefined);

      this.products = [];
      this.dealHistoryForm.controls["productID"].setValue(null);
    }
  }
 
  private clientListAPICall = (): void => {
    this.loading = true;
    this.dealHistoryService.clientLists(this.dealHistoryForm.value.tenantCARPartnerName).subscribe({
      next: (result: Client[]) => {
         this.clients = result;
         this.loading = false;
      },
      error: () =>  {
        this.loading = false;
      }
    });
  }

  tradeStatusList = (): void => {
    this.loading = true;
    this.dealHistoryService.getTradeStatusList(this.isAdmin).subscribe({
      next: (result: any) => {
         this.tradeStatuses = result.tradeStatuses;
         this.loading = false;
      },
      error: () =>  {
        this.loading = false;
      }
    });
  }
}






