import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appMinMaxValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxValidatorDirective, multi: true }]
})
export class MinMaxValidatorDirective {

  constructor() { }
  @Input('appMinMaxValidator') minMaxValue!: { min: number, max: number };

  validate(control: AbstractControl): { [key: string]: any } | null {
    const value = parseInt(control.value?.replace(/,/g, ''), 10);
    if (isNaN(value) || value < this.minMaxValue.min || value > this.minMaxValue.max) {
      return { 'minMaxValue': true };
    }
    return null;
  }

}
