<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>
<div id="NpQuotediv">
    <div class="secondary card">
        <div class="has-sub-title">
            <h4 class="title">Quote Details</h4>
            <div class="right-title">
                Spot Reference<span class="badge secondery badge-primary" id="spnSpotReference">{{spotReference}}</span>
            </div>
        </div>

        <form [formGroup]="quoteForm" (ngSubmit)="requestQuote()">
            <div class="row" *ngIf="isAdmin">

                <div class="col-md-4">
                    <div class="form-group" style="width: 90%;">
                        <label>Dealer</label>
                        <ng-select [clearable]="false" [items]="relationshipManager" [closeOnSelect]="true"
                            [searchable]="true" bindLabel="managerName" bindValue="managerID" placeholder="Select"
                            formControlName="selectedRM" (change)="onRMselect($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Client</label>
                        <ng-select [clearable]="false" [items]="rmClients" [closeOnSelect]="true" [searchable]="true"
                            bindLabel="clientName" bindValue="clientID" placeholder="Select"
                            (change)="onClientSelect($event)" formControlName="selectedclientID">
                        </ng-select>

                    </div>
                </div>
                <div class="col-md-3" style="margin-left:60px;">
                    <div class="form-group">
                        <label>Contact</label>
                        <ng-select [clearable]="false" [items]="rmContacts" [closeOnSelect]="true" [searchable]="true"
                            bindLabel="fullName" bindValue="contactID" placeholder="Select"
                            formControlName="selectedContactID">
                        </ng-select>

                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-4" style="margin-right:5px;">
                    <div class="form-group" style="width: 90%;">
                        <label>Currency Pair <em style="color:red;">*</em></label>
                        <div class="showhide"
                            [ngClass]=" {'newTooltip expand has-error': (quoteForm.controls['currencyPair'].invalid && quoteForm.controls['currencyPair'].touched)}"
                            datatitle="Currency pair is Required">
                            <ng-select [items]="currencyPairs" [closeOnSelect]="true" [searchable]="true"
                                [clearable]="false" [clearable]="false" bindLabel="currencyCode" placeholder="Select"
                                (change)="onCurrencyPairSelect($event)" formControlName="currencyPair">
                            </ng-select>
                        </div>

                    </div>
                </div>

                <div class="col-md-3" style="margin-right: 20px;">
                    <div class="form-group">
                        <label>Value Date <em style="color:red;">*</em></label>
                        <div class="input-icons showhide"
                            [ngClass]=" {'newTooltip expand has-error': (quoteForm.controls['valueDate'].invalid && quoteForm.controls['valueDate'].touched)}"
                            datatitle="Value Date is Required">
                            <input formControlName="valueDate" [matDatepickerFilter]="myFilter" [matDatepicker]="picker"
                                id="datepicker" placeholder="DD/MM/YYYY" class="input-field form-control"
                                (change)="invokeSpotRate()">
                            <span class="icon">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Direction</label>
                        <div class="checkbox-currency">
                            <div class="custom-radio secondary" style="width:45%;text-align:center;">
                                <input type="radio" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="selectedDirection" value="B" id="rbDirectionBuy" class="radio-input"
                                    name="buy-sell" (change)="invokeSpotRate()" checked>
                                <span class="radio-label">Buy</span>
                            </div>
                            <div class="custom-radio secondary" style="width:45%;text-align:center;">
                                <input type="radio" id="rbDirectionSell" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="selectedDirection" value="S" class="radio-input" name="buy-sell"
                                    (change)="invokeSpotRate()">
                                <span class="radio-label">Sell</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" style="margin-right: 15px;">
                    <div *ngIf="baseCurrency.name" class="form-group">
                        <label>Notional Currency</label>
                        <div id="divNotionalCurrency">
                            <div class="checkbox-currency">
                                <div class="custom-radio secondary" style="width:45%;text-align:center;">
                                    <input type="radio" id="BaseNotionalCurrency" class="radio-input"
                                        name="currencyPair" (change)="currencySelect('base')"
                                        [ngModelOptions]="{standalone: true}" [value]="baseCurrency"
                                        [(ngModel)]="notionalCurrency">
                                    <span class="radio-label" id="basecurrspan">{{baseCurrency.name}}</span>

                                </div>
                                <div class="custom-radio secondary" style="width:45%;text-align:center;">
                                    <input type="radio" id="TermNotionalCurrency" class="radio-input"
                                        (change)="currencySelect('term')" name="currencyPair"
                                        [ngModelOptions]="{standalone: true}" [value]="termCurrency"
                                        [(ngModel)]="notionalCurrency">
                                    <span class="radio-label" id="termcurrspan">{{termCurrency.name}}</span>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="form-group ">
                        <label>Notional Amount</label>

                        <div class="has-sub-field">
                            <div class="range-slider-content" style="float:left;">
                                <div class="showhide"
                                    [ngClass]=" {'newTooltip expand has-error': (quoteForm.controls['notionalAmount'].invalid && quoteForm.controls['notionalAmount'].touched)}"
                                    datatitle="Notional Amount is Required">
                                    <span class="cur-symbol">{{notionalCurrency.name}}</span>

                                    <input type="text" (blur)="CheckPercentOrtics()" id="txtnotional"
                                        name="notionalAmount" placeholder="0.00" maxlength="20" class="form-control"
                                        formControlName="notionalAmount" appTwoDigitDecimaNumber />
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div>

            <div class="row" *ngIf="isPartner">
                <div class="col-md-12">
                    <div class="form-group" style="float:left;margin-right:20px;">
                        <label *ngIf="isAdmin">Sales Margin </label>
                        <label *ngIf="!isAdmin">Margin</label>
                        <div class="checkbox-currency">
                            <div class="custom-radio secondary">

                                <input type="radio" id="salemargintypep" class="radio-input"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedMarginType"
                                    value="Percentage" name="ISClientTmargin" (change)="pecentageChecked()" checked>
                                <span class="radio-label">Percentage</span>
                            </div>
                            <div class="custom-radio secondary" style="margin-right:15px;">
                                <input type="radio" id="salemargintypet" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="selectedMarginType" value="Tics" (change)="pecentageChecked()"
                                    class="radio-input" name="ISClientTmargin">

                                <span class="radio-label">Tics</span>
                            </div>
                            <div class="range-slider-content" style="margin-right:65px;">
                                <input type="text" id="txtSalesmargin" name="SalesTicsmargin"
                                    (blur)="formatSalesMargin()" [readOnly]="!isAdmin" formControlName="salesMargin"
                                    maxlength="10" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Margin Amount</label>
                        <div class="has-sub-field" style="margin: 0px !important;">
                            <div class="range-slider-content" style="float:left;">
                                <span style="font-size: 16px; position: absolute; left: 11px; top: 10px;">AUD</span>
                                <input type="text" formControlName="marginAmount" (blur)="formatMarginAmount()"
                                    id="txtsalesamount" name="Amount" maxlength="10" [readOnly]="true"
                                    class="form-control" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="float:left;margin-top:20px;" id="divRequestquotebtn">
                <button (click)="requestQuote()" id="btnNPQuote" type="button" class="btn btn-success"
                    [disabled]="quoteForm.invalid" value="Quote">Request
                    Quote</button>
            </div>
        </form>
    </div>
    <div class="card" style="margin-top:10px;">
        <div class="graph-block text-center">
            <div id="divProductRateChart">
                <div class="tradingview-widget-container" #tradingViewContainer style="height: 100%;width: 100%;">
                    <div id="tradingview_702f2_light" class="tradingview-widget-container__widget"
                        style="height: calc(100% - 32px); width: 100% "></div>
                    <div class="tradingview-widget-copyright">
                        <a href="https://www.tradingview.com/symbols/{{selectedCurrencyPair}}/?exchange=FX"
                            rel="noopener nofollow" target="_blank">
                            <span class="blue-text">{{selectedCurrencyPair}}</span>
                        </a>
                        <span>On TradingView</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="secondary card">
        <!-- @Html.Raw("There are no Clients/Contacts setup. Please setup some clients to book a Forward Hedge deal.") -->
    </div>


</div>

<div id="NPTradeBookdiv">
</div>

<div class="modal secondary backdrop" id="confirmpopup-modal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div id="divconfirmquotepopup" class="modal-body">
                <div style="padding:50px;">

                    <div class="title" style="margin-bottom:20px;margin-left:-5px;">
                        <h4>Accept Quote </h4>
                    </div>

                    <div class="row">
                        <div class="col-md" style="padding :10px 11px;">
                            Forward Rate
                        </div>

                        <div class="col-md">
                            <span class="badge secondery" id="spnconfirmSpotReference">{{dataFromQuoteApi?.rate}}</span>
                        </div>
                        <div *ngIf="!timeoutExpired" class="col-md" style="padding-top :10px;">
                            <span>Valid For {{this.display}}</span>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4" style="padding: 10px 11px;">
                            <label> Value Date </label>
                        </div>
                        <div class="col-md-5">
                            <span class="badge secondery">{{dataFromQuoteApi?.valueDate | date:'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding: 10px 11px;">
                            <div>
                                <label>Client Buys</label>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <span class="badge secondery " id="basecurrencypop">{{dataFromQuoteApi?.clientBuyCurrency}}
                                {{dataFromQuoteApi?.clientBuyAmount | number:'1.2-2'}}</span>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-4" style="padding: 10px 11px;">
                            <label>Client Sells</label>
                        </div>
                        <div class="col-md-5">
                            <span class="badge secondery " id="termcurrencypop">{{dataFromQuoteApi?.clientSellCurrency}}
                                {{dataFromQuoteApi?.clientSellAmount | number:'1.2-2'}}</span>

                        </div>
                    </div>
                    <div class="title" style="margin-top:15px;">
                        <h6> </h6>
                    </div>
                    <table class="table custom-grid">

                        <colgroup>
                            <col style="width: 20%" />
                            <col style="width: 15%" />
                            <col style="width: 15%" />

                        </colgroup>
                        <thead>

                            <tr>
                                <th> <span class="badge secondery" style="color: dodgerblue;">Portfolio</span> </th>
                                <th> <span class="badge secondery" style="color: dodgerblue;">Pre Trade</span> </th>
                                <th> <span class="badge secondery" style="color: dodgerblue;"> Post Trade </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span style="font-size:17px;">Delta</span>


                                </td>
                                <td>
                                    <span class="badge secondery" id="predelta"> AUD
                                        {{dataFromQuoteApi?.preTradeDelta | number:'1.2-2'}}</span>


                                </td>
                                <td>
                                    <span class="badge secondery" id="postDelta"> AUD
                                        {{dataFromQuoteApi?.preTradeDelta | number:'1.2-2'}}</span>

                                </td>
                            </tr>
                            <tr *ngIf="dataFromQuoteApi?.specialAccessClient">
                                <td>

                                    <span style="font-size:17px;"> Available Credit</span>

                                </td>
                                <td>
                                    <span class="badge secondery"> {{dataFromQuoteApi?.preTradeCreditLimit |
                                        number:'1.2-2'}} </span>


                                </td>
                                <td>
                                    <span class="badge secondery">{{dataFromQuoteApi?.postTradeCreditLimit |
                                        number:'1.2-2'}}</span>

                                </td>
                            </tr>
                    </table>
                    <div class="inline-element" id="QuoteConfirmbuttondiv" style="margin-top:10px;">
                        <hr />
                        <div *ngIf="dataFromQuoteApi?.isValidQuote;else errorMessage">
                            <button id="btnNPQuoteReturn" type="button" class="btn btn-secondary" value="Quote"
                                (click)="close()">Return</button>

                            <button id="btnNPQuoteExecute" type="button" class="btn btn-success float-right"
                                value="Quote" (click)="execute()">Execute</button>

                        </div>
                        <ng-template #errorMessage class="row" style="color:red;">
                            <button id="btnNPQuoteReturn" type="button" style="margin-top:12px;"
                                class="btn btn-secondary" value="Quote" (click)="close()">Return</button>
                            <label style="color:red;">{{dataFromQuoteApi?.errorMessage}}</label>
                        </ng-template>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>