import { Component, ElementRef, Input } from '@angular/core';
import { RhsCollarService } from '../../services/RHSCollar/rhs-collar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionHedgeService } from '../../services/OptionHedge/option-hedge.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Observable, first, of, take } from 'rxjs';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-common-collar',
  templateUrl: './common-collar.component.html',
  styleUrl: './common-collar.component.css',
})
export class CommonCollarComponent {
  constructor(
    private el: ElementRef,
    private rhsCollarService: RhsCollarService,
    private route: ActivatedRoute,
    private router: Router,
    private optionHedgeService: OptionHedgeService,
    private fb: FormBuilder,
    private encryptPermission: EncryptRolePermissionService
  ) {}
  @Input('clientId') clientId: any = null;
  @Input('isAdmin') isAdmin: any = null;

  @Input('productID') productID!: number;
  rhsCollarForm: FormGroup = this.fb.group({});

  ngOnInit(): void {
    this.getCurrencyPairInfo().then((_) => {
      if (this.isAdmin) {
        this.getCurrencyList();
      } else if (!this.isAdmin) {
        this.getCurrencyListForClient();
      }

      this.getCollarLists();
      this.getCurrencyPairInfo();
    });
  }
  notionPerLegList = [];
  noOfLegsList = [];
  PremiumList = [];
  currencyPairs!: Observable<any>;
  spotReference = '0.0000';

  selectedCurrencyPair: any = {};
  firstExpiryDate!: Date;

  premium: any = 'Zero Cost';
  premiumAmount: string = '0';
  premiumAmountInt: number = 0;
  premiumDisabled = true;
  notionalPerLeg: string = '0';
  notionalPerlegInt: number = 0;
  baseCurrency: any = {};
  termCurrency: any = {};
  notionalCurrency: any = {};
  salesMarginAmount: number = parseFloat('0');
  salesMarginPercentage: number = parseFloat('0');
  callStrike: number = 0;
  dataLoaded = false;
  expiryData: any = [];
  noOfleg = 1;
  clienSalesPutParticipation = 0;
  delta = 0;

  minMaxNoOfLeg = [0, 0];
  minMaxNotional = [0, 0];
  minMaxpremium = [0, 0];
  minMaxCallStrike = [0, 0];
  notionalSliderIndex = 0;
  premiumSliderIndex = 0;

  currencyPairsInfo: any = [];

  ngAfterViewInit() {
    this.showTradingViewChart();
  }

  getCurrencyPairInfo() {
    const promise = new Promise<void>((resolve, reject) => {
      this.rhsCollarService.getCurrencyPairId().subscribe({
        next: (response) => {
          this.currencyPairsInfo = response;
          resolve();
        },
      });
    });
    return promise;
  }

  onCurrencyPairChange() {
    this.dataLoaded = false;
    this.showTradingViewChart();
    const currencyCode = this.selectedCurrencyPair.currencyCode;
    const currencyInfo = this.currencyPairsInfo.find(
      (currency: { currencyPairID: number }) =>
        currency.currencyPairID === this.selectedCurrencyPair.id
    );
    this.baseCurrency = {
      id: currencyInfo?.baseCurrencyID,
      name: currencyCode.substring(0, 3),
    };
    this.notionalCurrency = this.baseCurrency;
    this.termCurrency = {
      id: currencyInfo?.termCurrencyID,
      name: currencyCode.substring(3, 6),
    };
    this.getBarriers();
    this.dataLoaded = true;
  }

  changeCurrency() {
    this.calculateSalesMarginAmount();
  }
  showTradingViewChart() {
    const container = this.el.nativeElement.querySelector(
      '.tradingview-widget-container'
    );
    if (!container) {
      return;
    }
    // Clear existing content
    container.innerHTML = '';
    const script = document.createElement('script');
    let symbol = this.selectedCurrencyPair.currencyCode;
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    {
      "width": "100%",
      "height": "400",
      "autosize": true,
      "symbol": "${symbol}",
      "interval": "5",
      "timezone": "Australia/Sydney",
      "theme": "light",
      "style": "1",
      "locale": "en",
      "allow_symbol_change": true
     
    }`;
    this.el.nativeElement
      .querySelector('.tradingview-widget-container')
      .appendChild(script);
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();

    return day !== 0 && day !== 6;
  };
  minDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }

  checkDate(input: any) {
    const selectedDate = new Date(input.target.value);
    const dayOfWeek = selectedDate.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      input.target.value = new Date().toISOString().split('T')[0];
    }
  }

  checkPremiumDisable() {
    if (this.premium === 'Zero Cost') {
      this.premiumDisabled = true;
      this.premiumAmount = '0';

      this.premiumAmountInt = 0;
    } else {
      this.premiumDisabled = false;
    }
  }

  getCollarLists() {
    this.rhsCollarService.getList().subscribe({
      next: (response: any) => {
        this.notionPerLegList = response.notionalPerLeg;
        this.noOfLegsList = response.noOfLegs;
        this.PremiumList = response.premium;
        this.minMaxNotional[0] = this.notionPerLegList[0];
        this.minMaxNotional[1] =
          this.notionPerLegList[this.notionPerLegList.length - 1];
        this.minMaxNoOfLeg[0] = this.noOfLegsList[0];
        this.minMaxNoOfLeg[1] = this.noOfLegsList[this.noOfLegsList.length - 1];
        this.minMaxpremium[0] = this.PremiumList[0];
        this.minMaxpremium[1] = this.PremiumList[this.PremiumList.length - 1];
      },
    });
  }

  getBarriers() {
    if (this.firstExpiryDate) {
      const modal = this.el.nativeElement.querySelector('#firstExpiryError');

      modal.classList.remove('newTooltip');
      modal.classList.remove('expand');
      modal.classList.remove('has-error');

      const payload = {
        productId: this.productID,
        currencyPairID: this.selectedCurrencyPair.id,
        baseCurrencyID: this.baseCurrency.id,
        firstExpiryDate: new Date(
          this.firstExpiryDate.getTime() -
            this.firstExpiryDate.getTimezoneOffset() * 60000
        ).toISOString(),
        spotReference: 100,
      };
      this.rhsCollarService.getBarrierList(payload).subscribe({
        next: (response: any) => {
          this.minMaxCallStrike[0] = response.strikeMinimum;
          this.minMaxCallStrike[1] = response.strikeMaximum;
        },
      });
    }
  }

  getCurrencyList() {
    this.optionHedgeService.getConactOfClient(this.clientId).subscribe({
      next: (response: any) => {
        this.currencyPairs = of(response.currencyPairs);
        this.currencyPairs.pipe(take(1)).subscribe({
          next: (result) => {
            this.selectedCurrencyPair = result[0];
          },
        });
        this.onCurrencyPairChange();
      },
    });
  }

  getCurrencyListForClient() {
    this.optionHedgeService.getProductListForClient(this.clientId).subscribe({
      next: (response: any) => {
        this.currencyPairs = of(response.currencyPairs);
        this.currencyPairs.pipe(take(1)).subscribe({
          next: (result) => {
            this.selectedCurrencyPair = result[0];
          },
        });
        this.onCurrencyPairChange();
      },
    });
  }

  calculateSalesMarginAmount(changeSliderName?: string) {
    if (changeSliderName === 'notional') {
      const convertedNotional = parseInt(
        this.notionalPerLeg.replace(/,/g, ''),
        10
      );
      this.notionalPerlegInt = convertedNotional;
      this.notionalPerLeg = convertedNotional.toString();
    } else if (changeSliderName === 'premium') {
      const convertedPremium = parseInt(
        this.premiumAmount.replace(/,/g, ''),
        10
      );
      this.premiumAmountInt = convertedPremium;
      this.premiumAmount = convertedPremium.toString();

      return;
    }
    if (this.firstExpiryDate) {
      const payload: any = {
        firstExpiryDate: new Date(
          this.firstExpiryDate.getTime() -
            this.firstExpiryDate.getTimezoneOffset() * 60000
        ).toISOString(),
        currencyPairID: this.selectedCurrencyPair.id,
        notionalCurrencyID: this.notionalCurrency.id,
        noofLeg: this.noOfleg,
        productID: this.productID,
        isForAdmin: this.isAdmin,
        spotref: parseFloat(this.spotReference),
        baseCurrencyId: this.baseCurrency.id,
        notionalAmount: parseFloat(this.notionalPerLeg.replace(/,/g, '')),
        salesMarginPercentage: parseFloat(
          this.selectedCurrencyPair.optionHedgeMarginPerc
        ),
      };

      this.rhsCollarService.getSalesMarginAmount(payload).subscribe({
        next: (response: any) => {
          this.salesMarginAmount = response;
        },
      });
    } else {
      const modal = this.el.nativeElement.querySelector('#firstExpiryError');
      modal.classList.add('newTooltip');
      modal.classList.add('expand');
      modal.classList.add('has-error');
    }
  }

  onInputFieldChange() {
    let index = (this.notionPerLegList as any).indexOf(this.notionalPerLeg);

    return index;
  }

  stepIndex: number = 0;

  onInputChangeNotionalPerLeg($event: any) {
    this.stepIndex = 0;
    this.stepIndex = +$event.target.value;
    this.notionalPerLeg = this.stepIndex.toString();
  }

  stepIndexPremium = 0;

  onPremiumInputChange($event: any) {
    this.stepIndexPremium = +$event.target.value;
    this.premiumAmount = (this.stepIndexPremium as Number).toString();
  }

  openModal(): void {
    if (this.firstExpiryDate) {
      const payload = {
        firstExpiryDate: new Date(
          this.firstExpiryDate.getTime() -
            this.firstExpiryDate.getTimezoneOffset() * 60000
        ).toISOString(),
        noOfLegs: +this.noOfleg,
        baseCurrencyId: 0,
        holidayIDs: [0],
      };
      this.rhsCollarService.getExpiryData(payload).subscribe({
        next: (response: any) => {
          this.expiryData = response;
          const modal = this.el.nativeElement.querySelector(
            '#divRevenueSharingDetailsModal'
          );
          modal.classList.add('show');
          modal.style.display = 'block';
          modal.focus();
        },
      });
    } else {
      const modal = this.el.nativeElement.querySelector('#firstExpiryError');

      modal.classList.add('newTooltip');
      modal.classList.add('expand');
      modal.classList.add('has-error');
      modal.focus();
    }
  }
  close() {
    const modal = this.el.nativeElement.querySelector(
      '#divRevenueSharingDetailsModal'
    );
    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  back() {
    if (this.isAdmin) {
      this.router.navigate(['/Admin/optionhedge']);
    } else {
      this.router.navigate(['Client/optionhedge']);
    }
  }
}
