<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div>

<div class="tab-content">
    <!-- General Body -->
    <div class="tab-pane active" id="CARPartnerGeneral">
        <div class="tab-body-wrapper">
            <form action="" [formGroup]="CRMform" (ngSubmit)="onSave()">
                <input type="hidden" formControlName="clientID">
                <div class="row">

                    <div *ngIf="originalSEViewFlag" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">Original SE</label>
                            <div class="value-wrapper">
                                <ng-select formControlName="originalSEUserID" bindLabel="fullName" bindValue="userID"
                                    [items]="originalSE" [closeOnSelect]="true" [searchable]="true"
                                    placeholder="Select">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-4">&nbsp;</div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label class="strong">Revenue Split Share %</label>
                                </div>
                            </div>

                            <div class="col-sm-4">&nbsp;</div>

                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label class="strong">Revenue Split Share %</label>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div *ngIf="aeViewFlag[0] || aeViewFlag[1]" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">AE 1 </label>
                            <div *ngIf="aeViewFlag[0]" style="width: 100%;">
                                <ng-select formControlName="aE1UserID" [items]="AE" bindLabel="fullName"
                                    bindValue="userID" [closeOnSelect]="true" [searchable]="true" [multiple]="false"
                                    (change)="AEValidation($event, 'aE1UserID')" placeholder="Select">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="aeViewFlag[1]" class="form-control margin-left-10"
                                formControlName="aE1Percent" value="0.00"   appNumberRange appTwoDigitDecimaNumber >

                        </div>
                    </div>
                    <div *ngIf="aeViewFlag[2] || aeViewFlag[3]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">AE 2 </label>
                            <div style="width: 100%;">
                                <ng-select *ngIf="aeViewFlag[2]" formControlName="aE2UserID" bindLabel="fullName"
                                    bindValue="userID" [items]="AE" [closeOnSelect]="true" [searchable]="true"
                                    (change)="AEValidation($event, 'aE2UserID')" placeholder="Select">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="aeViewFlag[3]" class="form-control margin-left-10"
                                formControlName="aE2Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>
                    <div *ngIf="seViewFlag[0] || seViewFlag[1]" class="col-sm-6">

                        <div *ngIf="seViewFlag[0]" class="form-group label-inline">
                            <label class="strong">SE 1 </label>
                            <div style="width: 100%;">
                                <ng-select [items]="SE" bindLabel="fullName" bindValue="userID" [closeOnSelect]="true"
                                    [searchable]="true" placeholder="Select" (change)="SEValidation($event, 'sE1UserID')" 
                                    formControlName="sE1UserID">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="seViewFlag[1]" class="form-control margin-left-10"
                                formControlName="sE1Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>
                    <div *ngIf="seViewFlag[2] || seViewFlag[3]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">SE 2 </label>
                            <div style="width: 100%;">
                                <ng-select *ngIf="seViewFlag[2]" [items]="SE" [closeOnSelect]="true" [searchable]="true"
                                    placeholder="Select" bindLabel="fullName" bindValue="userID"
                                    (change)="SEValidation($event, 'sE2UserID')" formControlName="sE2UserID">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="seViewFlag[3]" class="form-control margin-left-10"
                                formControlName="sE2Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>

                    <div *ngIf="bdmViewFlag[0] || bdmViewFlag[1]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">BDM 1 </label>
                            <div style="width: 100%;">
                                <ng-select *ngIf="bdmViewFlag[0]" formControlName="bdm1UserID" [items]="BDM"
                                    [closeOnSelect]="true" [searchable]="true" [multiple]="false" placeholder="Select"
                                    (change)="BDM1Validation($event, 'bdm1UserID')" bindValue="userID" bindLabel="fullName">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="bdmViewFlag[1]" class="form-control margin-left-10"
                                formControlName="bdm1Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>
                    <div *ngIf="bdmViewFlag[2] || bdmViewFlag[3]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">BDM 2 </label>
                            <div style="width: 100%;">
                                <ng-select *ngIf="bdmViewFlag[2]" [items]="BDM" [closeOnSelect]="true"
                                    [searchable]="true" [multiple]="false" placeholder="Select"
                                    (change)="BDM1Validation($event, 'bdm2UserID')" formControlName="bdm2UserID" bindValue="userID" bindLabel="fullName">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="bdmViewFlag[3]" class="form-control margin-left-10"
                                formControlName="bdm2Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>
                    <div *ngIf="dealerViewFlag[0]||dealerViewFlag[1]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">Dealer 1 <em style="color:red;">*</em></label>
                            <div style="width: 100%;"
                                [ngClass]="{'newTooltip redborder expand has-error': (CRMform.controls['dealer1UserID'].touched)
                                && CRMform.controls['dealer1UserID'].invalid }"
                            >
                                <ng-select *ngIf="dealerViewFlag[0]" [items]="Dealer" [closeOnSelect]="true"
                                    [searchable]="true" [multiple]="false" placeholder="Select" bindLabel="fullName"
                                    bindValue="userID" (change)="dealerValidation($event, 'dealer1UserID')" formControlName="dealer1UserID">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="dealerViewFlag[1]" class="form-control margin-left-10"
                                formControlName="dealer1Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber>

                        </div>
                    </div>
                    <div *ngIf="dealerViewFlag[2] || dealerViewFlag[3]" class="col-sm-6">

                        <div class="form-group label-inline">
                            <label class="strong">Dealer 2 </label>
                            <div style="width: 100%;">
                                <ng-select *ngIf="dealerViewFlag[2]" [items]="Dealer" [closeOnSelect]="true"
                                    [searchable]="true" bindLabel="fullName" bindValue="userID" placeholder="Select"
                                    (change)="dealerValidation($event, 'dealer2UserID')" formControlName="dealer2UserID">
                                </ng-select>

                            </div>
                            <input type="text" *ngIf="dealerViewFlag[3]" class="form-control margin-left-10"
                                formControlName="dealer2Percent" value="0.00" appNumberRange appTwoDigitDecimaNumber >

                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group label-inline">
                            <label class="strong">Total</label>
                            <span id="totalRevenueSharingPerc" class="form-control-plaintext">

                            </span>
                        </div>
                    </div>
                    <div class="col-sm-8">&nbsp;</div>
                    <div *ngIf="onboardedDateFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Onboarded Date</label>
                            <input type="text" class="form-control" formControlName="onboardedDate">

                        </div>
                    </div>
                    <div *ngIf="totalTradeDateFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Total No. of Trades</label>
                            <input type="number" class="form-control number" formControlName="totalNoOfTrades">

                        </div>
                    </div>
                    <div *ngIf="firstTradeDateFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">First Trade Date</label>
                            <input type="text" class="form-control" formControlName="firstTradeDate">

                        </div>
                    </div>
                    <div *ngIf="revenueGeneratedFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Revenue Generated</label>
                            <input type="text" class="form-control" formControlName="revenueGenerated">

                        </div>
                    </div>

                    <div *ngIf="lastTradeDateFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Last Trade Date</label>
                            <input type="text" [value]="CRMform.value.lastTradeDate | date:'medium'"
                                class="form-control" formControlName="lastTradeDate">

                        </div>
                    </div>
                    <div class="col-sm-6">
                        &nbsp;
                    </div>

                    <div *ngIf="daysLastTradeDateFlag" class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">No. of Days Since Last Traded</label>
                            <input type="text" class="form-control" formControlName="noOfDaysSinceLastTraded">

                        </div>
                    </div>
                    <div *ngIf="canIsertTabPermission" class="col-md-12">
                        <div class="inline-element d-block text-center">
                            <button type="submit" class="btn btn-success" name="saveButton"
                                [disabled]="CRMform.invalid">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>