<!-- <app-header></app-header> -->
<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            {{loadingMessage}}...
        </div>
    </div>
</div>
<div class="">
    <div class="compress-inputs-page">
        <div class="inner add-user">
            <div class="title">
                <h3>&nbsp;</h3>
                <a class="btn btn-outline-secondary btn-sp" (click)="back()">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>
            </div>

            <div class="card">
                <form [formGroup]="contactsForm" (ngSubmit)="onSave()">
                    <div class="row">
                        <div class="col-sm-3">
                            <div *ngIf="contactFieldsViewFlag[19]" class="form-group label-inline">
                                <label for="" class="strong">Authorised Signatory</label>
                                <div class="value-wrapper">
                                    <div class="">
                                        <input type="checkbox" formControlName="isAuthorisedSignatory"
                                            (change)="checkRoleOfContact()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[19]" class="col-sm-3">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Authorised User</label>
                                <div class="value-wrapper">
                                    <div class="">
                                        <input type="checkbox" formControlName="isAuthorisedUser"
                                            (change)="checkRoleOfContact()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[19]" class="col-sm-3">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Authorised to Trade</label>
                                <div class="value-wrapper">
                                    <div class="">
                                        <input type="checkbox" formControlName="isAuthorisedToTrade"
                                            (change)="checkRoleOfContact()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[19]" class="col-sm-3">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Beneficial Owner</label>
                                <div class="value-wrapper">
                                    <div class="">
                                        <input type="checkbox" formControlName="isBeneficialOwner"
                                            (change)="checkRoleOfContact()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[17]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Client Name<em style="color: red">*</em></label>
                                <div class="value-wrapper">
                                    <div class="showhide" [ngClass]="{
                                  'newTooltip redborder expand has-error':
                                    contactsForm.controls['clientName'].touched &&
                                    contactsForm.controls['clientName'].invalid
                                }" datatitle="Client Name Is Required">
                                        <ng-select formControlName="clientName" [items]="clientList"
                                            bindLabel="clientName" [closeOnSelect]="true" [searchable]="true"
                                            placeholder="Select" (change)="getRoles()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[16]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">User Name<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['userName'].touched &&
                                  contactsForm.controls['userName'].invalid
                              }" datatitle="Username Is Required">
                                    <input type="text" class="form-control" formControlName="userName" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[15]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Title<em style="color: red">*</em></label>
                                <div class="value-wrapper">
                                    <div class="showhide" [ngClass]="{
                                  'newTooltip redborder expand has-error':
                                    contactsForm.controls['title'].touched &&
                                    contactsForm.controls['title'].invalid
                                }" datatitle="title Is Required">
                                        <ng-select formControlName="title" [items]="salutationList"
                                            bindLabel="salutaionName" [closeOnSelect]="true" [searchable]="true"
                                            placeholder="Select">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[2]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Alias</label>
                                <input type="text" class="form-control" formControlName="alias" />
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[5]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">First Name<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['firstName'].touched &&
                                  contactsForm.controls['firstName'].invalid
                              }" datatitle="First Name Is Required">
                                    <input type="text" class="form-control" formControlName="firstName" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[0]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Role<em style="color: red">*</em></label>
                                <div class="value-wrapper showhide" [ngClass]="{
                                'newTooltip redborder expand has-error':
                                  contactsForm.controls['clientRoleID'].touched &&
                                  contactsForm.controls['clientRoleID'].invalid
                              }" datatitle="Role Is Required">
                                    <ng-select [items]="roleList" bindLabel="clientRoleName" bindValue="clientRoleID"
                                        formControlName="clientRoleID" [closeOnSelect]="true" [searchable]="true"
                                        placeholder="Select">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[7]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Middle Name</label>
                                <input type="text" class="form-control" formControlName="middleName" />
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[12]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Status</label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['status'].touched &&
                                  contactsForm.controls['status'].invalid
                              }" datatitle="status Is Required">
                                    <ng-select *ngIf="!pendingAuth" [items]="statusList" bindLabel="contactStatusValue" bindValue="contactStatusId"                                      formControlName="status" [closeOnSelect]="true" [searchable]="true"
                                        placeholder="Select">
                                    </ng-select>
                                    <input *ngIf="pendingAuth" type="text" [value]="pendingAuthValue"
                                        class="form-control" readonly />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[6]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Last Name<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['lastName'].touched &&
                                  contactsForm.controls['lastName'].invalid
                              }" datatitle="Last Name Is Required">
                                    <input type="text" class="form-control" formControlName="lastName" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[13]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Theme<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip redborder expand has-error':
                                  contactsForm.controls['themeID'].touched &&
                                  contactsForm.controls['themeID'].invalid
                              }" datatitle="Theme Is Required">
                                    <ng-select [items]="themeList" bindLabel="themeName" bindValue="themeID"
                                        formControlName="themeID" [closeOnSelect]="true" [searchable]="true"
                                        placeholder="Select">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[10]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Position</label>
                                <input type="text" class="form-control" formControlName="position" />
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[18]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Primary Contact</label>
                                <label class="custom-switch">
                                    <input type="checkbox" class="switch-control-input"
                                        formControlName="isPrimaryContact" />
                                    <span class="switch-control-label"></span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[3]" class="col-sm-6">
                            <div class="form-group label-inline">
                              <label>Date Of Birth</label>
                              <div class="input-icons showhide"
                                [ngClass]=" {'newTooltip expand has-error': contactsForm.controls['dateOfBirth'].invalid }"
                                [attr.datatitle]="dobValidationTooltip">
                                <input formControlName="dateOfBirth" (blur)="checkDOB()" [max]="maxDate" [matDatepicker]="pickerLei" id="DOB"
                                  placeholder="DD/MM/YYYY" class="input-field form-control">
                                <span class="icon">
                                  <mat-datepicker-toggle matIconSuffix [for]="pickerLei"></mat-datepicker-toggle>
                                  <mat-datepicker #pickerLei></mat-datepicker>
                                </span>
                              </div>
                            </div>
                          </div>
                        <div  class="col-sm-6">
                            <div *ngIf="contactFieldsViewFlag[19]" class="form-group label-inline">
                                <label for="" class="strong">Beneficiary %<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['beneficiaryPercentage']
                                    .touched &&
                                  contactsForm.controls['beneficiaryPercentage'].invalid
                              }" datatitle="Beneficiary is Required">
                                    <input type="text" class="form-control" formControlName="beneficiaryPercentage"
                                        [readOnly]="
                                  !this.contactsForm.controls['isBeneficialOwner'].value
                                " appDecimalNumber />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactFieldsViewFlag[8]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Nationality</label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip redborder expand has-error':
                                  contactsForm.controls['nationalityID'].touched &&
                                  contactsForm.controls['nationalityID'].invalid
                              }" datatitle="Nationality Is Required">
                                    <ng-select [items]="countryList" bindLabel="countryName" bindValue="countryID"
                                        formControlName="nationalityID" [closeOnSelect]="true" [searchable]="true"
                                        placeholder="Select">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6"></div>
                        <div *ngIf="contactFieldsViewFlag[1]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Address</label>
                                <input type="text" class="form-control" formControlName="address" />
                            </div>
                        </div>
                        <div class="col-sm-6"></div>
                        <div *ngIf="contactFieldsViewFlag[4]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Email<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['emailAddress'].touched &&
                                  contactsForm.controls['emailAddress'].invalid
                              }" datatitle="Email Is Required">
                                    <input type="email" class="form-control" formControlName="emailAddress" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6"></div>
                        <div *ngIf="contactFieldsViewFlag[9]" class="col-sm-6">
                            <div class="form-group label-inline">
                                <label for="" class="strong">Phone/Mobile No.<em style="color: red">*</em></label>
                                <div class="showhide" [ngClass]="{
                                'newTooltip expand has-error':
                                  contactsForm.controls['phoneNumber'].touched &&
                                  contactsForm.controls['phoneNumber'].invalid
                              }" datatitle="Phone/Mobile No. Is Required">
                                    <input type="text" class="form-control" formControlName="phoneNumber"
                                        appPhoneNumber />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="inline-element d-block text-center" *ngIf="canUpdate || canInsert">
                                <button type="submit" class="btn btn-success">Save</button>
                                <button type="button" (click)="cancle()" class="btn btn-secondary">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>