<!-- <app-header></app-header> -->
<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        {{loadingMessage}}
    </div>
</div>
<div class="">
    <div class="inner user-page">
        <div class="title">
            <h3>&nbsp;</h3>
            <div >
                <a (click)="downloadTemplate()" *ngIf="rolePermissioncanUpdate" id="btnClientAddNew" class="btn btn-success" style="margin-right:10px ;">
                    <em><img width="15" height="15" src="../../../assets/Excelicon.png" alt=""></em>
                    Download Sample Template
                </a>
    
                <a *ngIf="rolePermissioncanUpdate" (click)="openFileUpload()" id="btnClientAddNew" class="btn btn-success">
                    <em><img src="../../../assets/plus.svg" alt=""></em>
                    Upload
                </a>
            </div>
        </div>

        <div class="card maindiv">
            
            
            <div class="GridClass custom-container" *ngIf="paginatedData.length>0 else notFound ">
                <div class="table-responsive">
                    <table class="table custom-grid">
                        <thead>
                            <tr class="Row123">
                                <th *ngIf="!isClient">
                                    <a (click)="sortTable('clientName')" class="sort  active  ts-asc" unselectable="on" data-i="0">
                                        Client&nbsp;Name<span class="ts-sord"></span> 
                                    </a>
                                </th>
                                <th >
                                    <a (click)="sortTable('description')" class="sort  active  ts-asc" unselectable="on" data-i="0">
                                        Account<span class="ts-sord"></span> 
                                    </a>
                                </th>
                                <th >
                                    <a (click)="sortTable('isActive')" class="sort  active  ts-asc" unselectable="on" data-i="0">
                                        Status<span class="ts-sord"></span> 
                                    </a>
                                </th>
                                <th >
                                    <a (click)="sortTable('currencyName')" class="sort  active  ts-asc" unselectable="on" data-i="1">
                                        Currency <span class="ts-sord"></span> 
                                    </a>
                                </th>
                                <th >
                                    <a (click)="sortTable('accountTypeName')" class="sort  active  ts-asc"  unselectable="on" data-i="2">
                                        Account&nbsp;Type<span class="ts-sord"></span> 
                                    </a>
                                </th>
                                <th>
                                    <a (click)="sortTable('balance')" class="sort  active  " unselectable="on" data-i="3">
                                        Balance<span class="ts-sord"></span> 
                                    </a>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td *ngIf="!isClient">{{ row.clientName }}</td>
                                <td >{{ row.description }}</td>
                                <td >{{ row.isActive ? 'Active' : 'Inactive' }}</td>
                                <td >{{ row.currencyName }}</td>
                                <td >{{ row.accountTypeName }}</td>
                                <td>{{row.balance | number: '1.2-2' }}</td>

                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>
            </div>
        </div>

    </div>

    <ng-template #notFound>
        <div class="alert alert-info">
            <span>No record found</span>
        </div>
    </ng-template> 

    <div class="modal secondary backdrop" id="fileUpload-modal">
        <div class="modal-dialog modal-dialog-centered ">
            
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">&nbsp;</h4>
                    <button (click)="close()" type="button" class="close" data-dismiss="modal"><img src="../../../assets/cross.svg" alt=""></button>
                </div>
                
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label>File <em style="color:red;">*</em></label>
                                <input #fileInput type="file" id="UploadedFile" name="UploadedFile" class="form-control" (change)="onChange($event)" />
                            </div>
                        </div>
            
                        <div class="col-md-12">
                            <div class="inline-element">
                                <input type="button" name="Submit" value="Submit" id="btnUploadFile" (click)="fileUpload()" class="btn btn-success" />
                            </div>
                        </div>
                        <section [ngSwitch]="status" class="form-group">
                            <p style="margin: 18px;"  *ngSwitchCase="'uploading'">⏳ Uploading...</p>
                            <p style="margin: 18px;" *ngSwitchCase="'success'">✅ File Uploaded Successfully!!</p>
                            <p style="margin: 18px;" *ngSwitchCase="'fail'">❌ Error!</p>
                            <p style="margin: 18px;" *ngSwitchDefault>Waiting to upload...</p>
                        </section>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>