import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ExecuteDealResponse,
  RequestResponse,
} from '../../components/spot-deal/models/spotdeal.model';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private chathubConnection: signalR.HubConnection;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()

      .withUrl(environment.hubUrl)
      .build();
    this.chathubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://rates.navihedge.com.au/chathub') // SignalR hub URL

      .build();
  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection established with SignalR hub');
          observer.next();
          observer.complete();
        })
        .catch((error: any) => {
          console.error('Error connecting to SignalR hub:', error);
          observer.error(error);
        });
    });
  }
  startchatConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.chathubConnection
        .start()
        .then(() => {
          console.log('Connection chathub established with SignalR hub');
          observer.next();
          observer.complete();
        })
        .catch((error: any) => {
          console.error('Error connecting to SignalR hub:', error);
          observer.error(error);
        });
    });
  }
  getChatmessage(): Observable<string> {
    return new Observable<string>((observer) => {
      this.chathubConnection.on(
        'ReceiveMessage',
        (user: string, message: string) => {
          observer.next(user);
        }
      );
    });
  }
  getRates(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on(
        'PublishRate',
        (rate: string, requestID: string) => {
          observer.next(rate);
        }
      );
    });
  }
  sendChathubmessage() {
    this.chathubConnection.invoke('SendMessage', 'hello', 'navinci');
  }

  sendMessage(message: string, payload: any): void {
    this.hubConnection.invoke(
      'RequestRate',
      payload.currencyPair,
      payload.notionalAmount,
      payload.direction,
      payload.notionalCurrency,
      payload.valueDate,
      payload.clientID,
      payload.percentageOrTics,
      payload.salesMarginValue
    );
  }

  requestQuote(payload: any) {
    this.hubConnection.invoke('RequestQuote', payload);
  }
  getRequestQuoteStatus(): Observable<RequestResponse> {
    return new Observable<RequestResponse>((observer) => {
      this.hubConnection.on(
        'PublishRequestQuote',
        (RequestQuoteResponse: RequestResponse) => {
          observer.next(RequestQuoteResponse);
        }
      );
    });
  }

  executeDeal() {
    this.hubConnection.invoke('ExecuteDeal');
  }

  getDealStatus(): Observable<ExecuteDealResponse> {
    return new Observable<ExecuteDealResponse>((observer) => {
      this.hubConnection.on(
        'PublishExecuteRequest',
        (executeDealResponse: ExecuteDealResponse) => {
          observer.next(executeDealResponse);
        }
      );
    });
  }

  cancelQuote() {
    this.hubConnection.invoke('CancelRequestQuote');
  }

  cancelQuoteResponse(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.hubConnection.on(
        'PublishCancelRequestQuote',
        (cancelQuoteResponse: boolean) => {
          observer.next(cancelQuoteResponse);
        }
      );
    });
  }
}
