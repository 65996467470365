import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class forgotpasswordService {

  constructor(private http:HttpClient) { }
  recaptchaKey=environment.recaptchaKey;
  apiUrl=environment.apiUrl;
  verifyCaptchaUrl=`https://recaptchaenterprise.googleapis.com/v1/projects/navinci-demo/assessments?key=AIzaSyA4C5FVypaJGo9gO67z2rNcQvRbDvNsdxo`

  verifyCaptcha(request:any){

    return this.http.post(this.verifyCaptchaUrl,request);

  }

  forgotPassword(payload:string){
    return this.http.post(this.apiUrl+'Account/forgotpassword',payload);
  }

  
}
