<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">{{loadingMessage}}</div>
    </div>
  </div>
<div class="inner manage-clientrole">
    <div class="title">
        <h3>&nbsp;</h3>

        <a (click)="Add()" id="btnClientRoleAddNew" class="btn btn-success">
            <em><img src="../../../../assets/plus.svg" alt=""></em>
            Add
        </a>

    </div>
    <div class="card maindiv">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="form-group" id="ClientRoleSearch">
                    <label>Role Name</label>
                    <input 
                        (keydown.enter)="search()" 
                        type="text" 
                        class="form-control" 
                        [(ngModel)]="roleName" 
                        maxlength="100" 
                        id="txtClientRoleName"
                    >
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Status</label>
                    <div class="checkbox-block">
                        <div class="form-check-inline">
                            <div class="custom-radio">
                                <input type="radio" (keydown.enter)="search()"
                                    [(ngModel)]="selectedStatus" id="rbStatusActive"
                                    name="selectedstatus" value="Both" class="radio-input"
                                    (change)="onRadioChange('All')" [tabindex]="1">
                                <span class="radio-label">All</span>
                            </div>
                        </div>
                        <div class="custom-radio">
                            <div class="form-check-inline">
                                <input type="radio" id="rbStatusActive" 
                                    [(ngModel)]="selectedStatus" (keydown.enter)="search()"
                                    name="selectedstatus" value="Active" class="radio-input"
                                    (change)="onRadioChange('Active')" [tabindex]="2">
                                <span class="radio-label">Active</span>
                            </div>
                        </div>
                        <div class="custom-radio">
                            <div class="form-check-inline">
                                <input type="radio" id="rbStatusActive" 
                                    [(ngModel)]="selectedStatus" (keydown.enter)="search()"
                                    name="selectedstatus" value="Inactive" class="radio-input"
                                    (change)="onRadioChange('Inactive')" [tabindex]="3">
                                <span class="radio-label">Inactive</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="inline-element" style="padding-top:25px;">
                    <a (click)="search()" class="btn btn-success" id="btnClientRoleSearch">Search</a>
                    <a (click)="reset()" class="btn btn-secondary" id="btnClientRoleReset">Reset</a>
                </div>
            </div>
        </div>
        <div class="nth-last-child-2-center">
        </div>

        <div *ngIf="userRoleData.length>0;else notFound" class="GridClass custom-container">
            <div class="table-responsive">

                <table class="table custom-grid">
                    <thead>
                        <tr class="Row123">
                            <th><a class="sort  active  ts-asc" unselectable="on" data-i="0">Role Name<span
                                        class="ts-sord"></span> </a></th>
                            <th><a class="sort  active  ts-asc" unselectable="on" data-i="1">Description<span
                                        class="ts-sord"></span> </a></th>
                            <th><a class="sort  active  ts-asc" unselectable="on" data-i="1">Active/Inactive<span
                                        class="ts-sord"></span> </a></th>
                            <th><a class="sort" unselectable="on" data-i="8">
                                    <span class="action">Action</span>
                                </a></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of paginatedData">
                            <td>{{row.roleName}}</td>
                            <td>{{row.description}}</td>

                            <td >
                                <div *ngIf="!row.isSystemGenerated">
                                    <label class="switch">
                                        <input  type="checkbox" [checked]="row.isActive"
                                            (change)="toggleSwitch(row)">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="action-btns" ><a *ngIf="!row.isSystemGenerated" (click)="editRole(row.clientRoleID)"title="Edit"><img
                                            src="../../../../assets/edit.svg" alt=""></a>
                                    <a *ngIf="!row.isSystemGenerated" (click)="deleteData(row)" title="Delete"><img src="../../../../assets/delete.svg"
                                            alt="" /></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex" aria-label="Select page">
            </mat-paginator>
        </div>


    </div>
</div>
<ng-template #notFound>
    <div class="alert alert-info">
        <span>No record found</span>
    </div>
</ng-template>