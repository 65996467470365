<!-- <app-header></app-header> -->
<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="">
    <div class="inner secondary">
        <div class="card ">
            <form [formGroup]="dealHistoryForm" id="dealHistoryForm" class="page">
                <div *ngIf="isAdmin" class="row">

                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Tenant/CAR Partner</label>
                            <ng-select [items]="tenantCARs" bindLabel="name"
                                bindValue="name" placeholder="Select" [searchable]="true"
                                [closeOnSelect]="false" formControlName="tenantCARPartnerName"
                                (keydown.enter)="search()"
                                (keydown)="closeDropdwon($event)"
                                (click)="closeDropdwon($event)"
                                (change)="tenantCARsChanged($event)"
                                [readonly]="isNavinciUser == true ? false : true"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Client</label>
                            <ng-select [items]="clients" bindLabel="name" bindValue="clientID"
                                placeholder="Select" [searchable]="true" [closeOnSelect]="false"
                                (keydown.enter)="search()"
                                (keydown)="closeDropdwon($event)"
                                (click)="closeDropdwon($event)"
                                (change)="clientChanged($event)"
                                formControlName="clientName"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group" id="ManageTrade">
                            <label>Trade Id</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="txtTradeNumber" 
                                (keydown.enter)="search()"
                                formControlName="tradeIDLike"
                            >
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Trade Start Date</label>

                            <input type="date" class="form-control" formControlName="tradeStartDate" id="txtTradeDate"
                                placeholder="DD/MM/YYYY" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Trade End Date</label>
                            <input type="date" class="form-control " id="txtTradeDate2" formControlName="tradeEndDate"
                                placeholder="DD/MM/YYYY" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Product</label>
                            <ng-select [items]="products" 
                                bindLabel="productName" 
                                bindValue="productID"
                                [searchable]="true"
                                (keydown.enter)="search()"
                                (keydown)="closeDropdwon($event)"
                                (click)="closeDropdwon($event)"
                                [closeOnSelect]="false" 
                                formControlName="productID"
                                (change)="onChangeProduct($event)"
                                placeholder="Select" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Created By </label>

                            <ng-select [items]="createdBys" 
                                bindLabel="name" 
                                bindValue="contactID"
                                [searchable]="true"
                                (keydown.enter)="search()"
                                (keydown)="closeDropdwon($event)"
                                (click)="closeDropdwon($event)"
                                [closeOnSelect]="false" 
                                placeholder="Select"
                                (change)="onChangeCreatedByContact($event)"
                                formControlName="createdByContactName" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group">
                            <label>Trade Status </label>
                            <ng-select [items]="tradeStatuses"
                             bindLabel="tradeStatusName" 
                             bindValue="tradeStatusID"
                            formControlName="tradeStatus" 
                            (keydown.enter)="search()"
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                            [closeOnSelect]="false" 
                            placeholder="Select">
                        </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="inline-element">
                            <a class="btn btn-success" id="btnTradeSearch" (click)="search()">Search</a>
                            <a class="btn btn-secondary" id="btnTradeReset" (click)="reset()">Reset</a>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row top-icon">
                <div class="col-md-4 col-sm-4 text-center">
                </div>
                <div class="col-md-4 col-sm-4 text-center">
                    <h3><br />Deal History</h3>
                </div>
                <div class="col-md-4 col-sm-4" style="text-align:right!important">
                    <br />
                    <a (click)="exportexcel()" style="cursor:pointer" class="dvExport">
                        <img width="30" height="30" src="../../../assets/Excelicon.png"
                            style="height:30px;width:30px;" />
                    </a>
                    <a (click)="exportToPdf()" style="cursor:pointer" class="dvExport">
                        <img src="../../../assets/PDF.png" style="height:30px;width:30px;" />
                    </a>
                    <a (click)="printPage()" style="cursor:pointer" class="dvExport">
                        <img src="../../../assets/Printer.png" style="height:30px;width:30px;" />
                    </a>
                </div>
            </div>
            <hr />
            <div class="nth-last-child-2-center nth-last-child-3-center fixed-header-table">
                <div id="NestedGrid" *ngIf="dealHistoryData.length>0;else notFound"class="GridClass">
                    <div  class="table-responsive" >
                        <table class="table custom-grid page" id="dealHistory">
                            <colgroup>
                                <col style="width:14%">
                                <col style="width:16%">
                                <col style="width:8%">
                                <col style="width:10%">
                                <col style="width:14%">
                                <col style="width:14%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:9%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:5%">
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:3%">
                            </colgroup>
                            <thead>
                                <tr class="Row123">
                                    <th *ngIf="isAdmin" style="width:14%"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="0">Tenant&nbsp;Name/CAR Partner<span class="ts-sord"></span> </a>
                                    </th>
                                    <th *ngIf="isAdmin" style="width:16%"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="1">Client<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width:8%"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="2">Trade&nbsp;ID<span class="ts-sord"></span>
                                        </a></th>
                                    <th *ngIf="isAdmin" style="width:10%"><a class="sort  active  " unselectable="on"
                                            data-i="3">ICE&nbsp;Trade&nbsp;ID<span class="ts-sord"></span> </a></th>
                                    <th *ngIf="isAdmin" style="width:14%"><a class="sort  active  " unselectable="on"
                                            data-i="4">DS&nbsp;Deal ID<span class="ts-sord"></span> </a></th>
                                    <th *ngIf="isAdmin"style="width:14%"><a class="sort  active  " unselectable="on"
                                            data-i="5">DS&nbsp;Error<span class="ts-sord"></span> </a></th>
                                    <th *ngIf="isAdmin" style="width:10%"><a class="sort  active  " unselectable="on"
                                            data-i="6">Source&nbsp;ID<span class="ts-sord"></span> </a></th>
                                    
                                    <th *ngIf="isAdmin" style="width:9%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Source</span>
                                        </a></th>
                                    <th style="width:10%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Product Name</span>
                                        </a></th>
                                    <th style="width:10%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Trade Date</span>
                                        </a></th>
                                    <th style="width:5%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Value Date</span>
                                        </a></th>
                                    <th style="width:5%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Buy Currency</span>
                                        </a></th>
                                    <th style="width:10%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Sell Currency</span>
                                        </a></th>
                                    <th style="width:10%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Buy Amount</span>
                                        </a></th>
                                    <th style="width:10%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Sell Amount</span>
                                        </a></th>
                                    <th style="width:3%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Exch Rate</span>
                                        </a></th>
                                    <th style="width:3%"><a class="sort" unselectable="on" data-i="8">
                                            <span class="action">Confirmation</span>
                                        </a></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of paginatedData">
                                    <td *ngIf="isAdmin" class="wrapword">{{ row.tenantCARPartnerName }}</td>
                                    <td *ngIf="isAdmin" class="wrapword">{{ row.clientName }}</td>
                                    <td  class="wrapword">{{ row.tradeID }}</td>
                                    <td *ngIf="isAdmin" class="wrapword">{{row.iceTradeID}}</td>
                                    <td *ngIf="isAdmin"class="wrapword">{{ row.datasoftDealID }}</td>
                                    <td *ngIf="isAdmin" class="wrapword">{{row.datasoftError}}</td>
                                    <td *ngIf="isAdmin" class="wrapword"></td>
                                    <td *ngIf="isAdmin"class="wrapword"></td>
                                   
                                    <td class="wrapword">{{row.productName}}</td>
                                    <td class="">{{row.tradeDate | date:"dd/MM/yyyy"}}</td>
                                    <td class="">
                                        {{row.valueDate |date:"dd/MM/yyyy"}}
                                    </td>
                                    <td class="wrapword">{{row.buyCurrency}}</td>
                                    <td class="wrapword">{{row.sellCurrency}}</td>
                                    <td class="wrapword">{{row.buyAmount}}</td>
                                    <td class="wrapword">{{row.sellAmount}}</td>
                                    <td class="wrapword">{{row.rate}}</td>
                                    <td class="wrapword">
                                        <div><a (click)="getTradeConfo(row.tradeID)" style="cursor:pointer"><img
                                                    src="../../../assets/PDF.png" alt=""></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #notFound>
    <div  class="alert alert-info">
      <span>No record found</span>
    </div>
  </ng-template>