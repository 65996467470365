import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { forgotpasswordService } from '../../services/ForgotPassword/forgotpassword.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var grecaptcha: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css',
})
export class ForgotPasswordComponent implements OnInit {
  loading = false;
  recaptchaKey = environment.recaptchaKey;
  isValid = false;
  error = false;
  successFull: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private forgotPasswordService: forgotpasswordService,
    private fb: FormBuilder
  ) {}

  @ViewChild('recaptchaContainer') recaptchaContainer!: ElementRef;

  forgetPasswordForm: FormGroup = this.fb.group({
    username: ['', [Validators.required]],
  });
  ngOnInit() {
    this.renderReCAPTCHA();
  }
  loadReCAPTCHAScript() {
    return new Promise<void>((resolve, reject) => {
      const script = this.document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/enterprise.js';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = reject;
      this.document.body.appendChild(script);
    });
  }
  renderReCAPTCHA() {
    this.loadReCAPTCHAScript().then((_) => {
      grecaptcha?.enterprise?.render(this.recaptchaContainer.nativeElement, {
        sitekey: environment.recaptchaKey,
        callback: this.verifyCallback.bind(this),
        'expired-callback': this.onExpired.bind(this),
        action: 'password_reset',

        theme: 'light', // or 'dark'
      });
    });
  }

  verifyCallback(response: any) {
    // const request={
    //   "event": {
    //     "token": response,
    //     "expectedAction": "password_reset",
    //     "siteKey": environment.recaptchaKey,
    //   }
    // }
    // this.forgotPasswordService.verifyCaptcha(request).subscribe({
    // })

    this.isValid = true;
  }
  onExpired() {
    this.isValid = false;
  }
  onSubmit() {
    if (this.forgetPasswordForm.valid && this.isValid) {
      const username = this.forgetPasswordForm.value.username;
      this.loading = true;
      this.forgotPasswordService
        .forgotPassword(this.forgetPasswordForm.value)
        .subscribe({
          next: () => {
            this.loading = false;
            this.successFull = true;
          },
          error: () => {
            this.loading = false;
            this.error = true;
          },
        });
    }
  }
}
