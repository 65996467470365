import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageTenantsService } from '../../services/ManageTenants/manage-tenants.service';
import { CarPartnerService } from '../../services/carPartner/car-partner.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { DOCUMENT } from '@angular/common';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { jwtDecode } from 'jwt-decode';
import { SpinnerMessage } from '../../global/global.constant';

@Component({
  selector: 'app-add-edit-car-partner',
  templateUrl: './add-edit-car-partner.component.html',
  styleUrl: './add-edit-car-partner.component.css',
})
export class AddEditCarPartnerComponent {
  dataLoaded: boolean = true;
  loadingMessage = SpinnerMessage.Loading;
  editMode: boolean = false;
  isGeneralTabActive: boolean = true;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

  products: any[] = [];
  statementTiers: any[] = [];
  tradeCurrencyPairs: any[] = [];
  relationshipManagers: any[] = [];

  carPartnerID = 0;

  uniqueCfcp: any = true;
  logos: string[] = [];
  logoPreviews: any[] = [];
  token: any;
  loggedInUser: any;
  useResponse: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private tenantService: ManageTenantsService,
    private carPartnerService: CarPartnerService,
    private route: ActivatedRoute,
    private alert: SweetalertService,
    @Inject(DOCUMENT) private document: Document,
    private encryptPermission: EncryptRolePermissionService
  ) {}

  carPartnerForm: FormGroup = this.fb.group({
    partnerName: ['', [Validators.required]],
    isActive: [false],
    phone: ['', [Validators.required, Validators.maxLength(15)]],
    contactEmail: [
      '',
      [Validators.required, Validators.pattern(this.emailPattern)],
    ],
    productIDs: [[]],
    statementTierID: [[], [Validators.required]],
    currencyPairIDs: [[]],
    relationshipManagerID: [],
    changeBeneficiaryToPayee: [false],
    filterOnRelationshipManager: [false],

    confosPrintName: ['', [Validators.required, Validators.maxLength(150)]],
    confoUse: ['', [Validators.required]],
    address: [],
    disclaimer: [],
    confoLogoLight: [null, [Validators.required]],
    confoLogoDark: [null, [Validators.required]],
    factSheetLogoLight: [null, [Validators.required]],
    factSheetLogoDark: [null, [Validators.required]],
    sidePanelLogoLight: [null, [Validators.required]],
    sidePanelLogoDark: [null, [Validators.required]],

    revenueSharingPercSpot: [+'', [Validators.required]],
    revenueSharingPercForward: [+'', [Validators.required]],
    revenueSharingPercOptions: [+'', [Validators.required]],
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      const carPartnerId = +parameterMap.get('carPartnerId')!;
      if (carPartnerId) {
        this.carPartnerID = carPartnerId;
        this.editMode = true;
        this.getDropDownList();
        this.getCARPartnerDetails(this.carPartnerID);
      } else {
        this.getDropDownList();
      }
    });
  }

  getDropDownList() {
    this.tenantService.tenantList().subscribe({
      next: (result: any) => {
        this.products = result.products;
        this.statementTiers = result.statementTiers;
        this.relationshipManagers = result.relationshipManagers;
        this.tradeCurrencyPairs = result.tradeCurrencyPairs;
      },
    });
  }

  getCARPartnerDetails(carPartnerId: number) {
    this.dataLoaded = false;
    this.carPartnerService.getCarPartnerData(carPartnerId).subscribe({
      next: (data: any) => {
        this.carPartnerForm.patchValue({
          productIDs: data.products,
          currencyPairIDs: data.currencyPairs,
          PartnerName: data.partnerName,

          ...data,
        });
        this.logos.push(
          data.confoLogoLight,
          data.confoLogoDark,
          data.factSheetLogoLight,
          data.factSheetLogoDark,
          data.sidePanelLogoLight,
          data.sidePanelLogoDark
        );
        this.dataLoaded = true;
      },
      error: (error) => {
        this.dataLoaded = true;
      },
    });
  }

  onFileSelected(event: any, controlName: string, index: number) {
    const file = event.target.files[0];
    this.carPartnerForm.patchValue({ [controlName]: file });

    const reader = new FileReader();
    reader.onload = () => {
      this.logoPreviews[index] = reader.result;
    };
    reader.readAsDataURL(file);
  }

  back() {
    this.router.navigate(['/Admin/manageCarpartner']);
  }

  onSave() {
    if (this.carPartnerForm.valid) {
      const carPartnerValue = this.carPartnerForm.value;

      const payload: any = {
        PartnerName: carPartnerValue.partnerName,
        IsActive: carPartnerValue.isActive,
        Phone: carPartnerValue.phone,

        ContactEmail: carPartnerValue.contactEmail,
        ProductIDs: carPartnerValue.productIDs.map((obj: any) => obj.productID),
        StatementTierID: carPartnerValue.statementTierID,

        CurrencyPairIDs: carPartnerValue.currencyPairIDs.map(
          (obj: any) => obj.currencyPairID
        ),
        RelationshipManagerID: carPartnerValue.relationshipManagerID,

        ChangeBeneficiaryToPayee: carPartnerValue.changeBeneficiaryToPayee,
        FilterOnRelationshipManager:
          carPartnerValue.filterOnRelationshipManager,
        RevenueSharingPercSpot: carPartnerValue.revenueSharingPercSpot,

        RevenueSharingPercForward: carPartnerValue.revenueSharingPercForward,

        RevenueSharingPercOptions: carPartnerValue.revenueSharingPercOptions,

        ConfosPrintName: carPartnerValue.confosPrintName,
        ConfoUse: carPartnerValue.confoUse,
        Address: carPartnerValue.address,
        Disclaimer: carPartnerValue.disclaimer,
        Logo: carPartnerValue.confoLogoLight,

        LogoForDark: carPartnerValue.confoLogoDark,

        FactSheetLogo: carPartnerValue.factSheetLogoLight,

        FactSheetLogoForDark: carPartnerValue.factSheetLogoDark,

        SidePanelLogo: carPartnerValue.sidePanelLogoLight,

        SidePanelLogoForDark: carPartnerValue.sidePanelLogoDark,
      };

      if (this.editMode) {
        this.dataLoaded = false;
        this.loadingMessage = SpinnerMessage.Update;
        const formValue: any = {
          carPartnerId: this.carPartnerID,
          ...payload,
        };

        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
          const value = formValue[key];
          if (key === 'ProductIDs' || key === 'CurrencyPairIDs') {
            value.forEach((element: any) => {
              formData.append(key, element);
            });
          } else {
            formData.append(key, value);
          }
        }

        this.carPartnerService.updateCarPartner(formData).subscribe({
          next: (response: any) => {
            this.dataLoaded = true;
            this.token =
              this.document.defaultView?.localStorage?.getItem('jwtToken');
            this.loggedInUser = jwtDecode(this.token);
            if (this.loggedInUser.carPartnerID == response.carPartnerID) {
              this.useResponse =
                this.encryptPermission.getDecryptedPermissions('userResponse');
              this.useResponse.enablePricerDealer =
                response.filterOnRelationshipManager;
              this.encryptPermission.encryption(
                this.useResponse,
                'userResponse'
              );
            }
            this.alert.fireAlert('Updated').then(() => {
              this.back();
            });
          },
          error: (error) => {
          },
        });
      } else {
        this.dataLoaded = false;
        this.loadingMessage = SpinnerMessage.Saving;
        const formValue = payload;
        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
          const value = formValue[key];

          if (key === 'ProductIDs' || key === 'CurrencyPairIDs') {
            value.forEach((element: any) => {
              formData.append(key, element);
            });
          } else {
            formData.append(key, value);
          }
        }

        this.carPartnerService.addCarPartner(formData).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then(() => {
              this.back();
            });
          },
          error: (error) => {
          },
        });
      }
    }
  }
  cancel() {
    if (this.editMode) {
      this.getCARPartnerDetails(this.carPartnerID);
    } else {
      this.carPartnerForm.reset();
    }
  }

  errorArray: any = Array(5).fill('');
  errorChecking() {
    if (
      this.carPartnerForm.controls['partnerName'].touched ||
      this.carPartnerForm.controls['partnerName'].dirty
    ) {
      if (this.carPartnerForm.controls['partnerName'].errors?.['required']) {
        this.errorArray[0] = 'Partner Name is Required.';
      } else {
        this.errorArray[0] = '';
      }
    }
    if (
      this.carPartnerForm.controls['phone'].touched ||
      this.carPartnerForm.controls['phone'].dirty
    ) {
      if (this.carPartnerForm.controls['phone'].errors?.['required']) {
        this.errorArray[1] = 'Phone is Required.';
      } else if (this.carPartnerForm.controls['phone'].errors?.['maxlength']) {
        this.errorArray[1] = 'phone should be of maximum 15 numbers';
      } else {
        this.errorArray[1] = '';
      }
    }
    if (
      this.carPartnerForm.controls['contactEmail'].touched ||
      this.carPartnerForm.controls['contactEmail'].dirty
    ) {
      if (
        this.carPartnerForm.controls['contactEmail'].errors?.['contactEmail']
      ) {
        this.errorArray[2] = ' Email is Required.';
      } else if (
        this.carPartnerForm.controls['contactEmail'].errors?.['pattern']
      ) {
        this.errorArray[2] = 'Email Address must be Valid Email Address.';
      } else {
        this.errorArray[2] = '';
      }
    }
    if (
      this.carPartnerForm.controls['statementTierID'].touched ||
      this.carPartnerForm.controls['statementTierID'].dirty
    ) {
      if (
        this.carPartnerForm.controls['statementTierID'].errors?.['required']
      ) {
        this.errorArray[3] = 'Statement Tier is Required.';
      } else {
        this.errorArray[3] = '';
      }
    }
    if (
      this.carPartnerForm.controls['confosPrintName'].touched ||
      this.carPartnerForm.controls['confosPrintName'].dirty
    ) {
      if (
        this.carPartnerForm.controls['confosPrintName'].errors?.['required']
      ) {
        this.errorArray[4] = 'Print Name is Required.';
      } else {
        this.errorArray[4] = '';
      }
    }
  }

  compareProduct(item: any, selected: any) {
    return item.productID === selected.productID;
  }

  compareTradeCurrencyPair(item: any, selected: any) {
    return item.currencyPairID === selected.currencyPairID;
  }
}
