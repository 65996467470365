import { Component, Inject, OnInit } from '@angular/core';
import { SigninService } from './services/signin/signin.service';
import { CheckIdleService } from './services/CheckIdle/check-idle.service';
import { LoginService } from './client/services/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'Navihedge';

  constructor(private signInService:SigninService,private checkIdleService:CheckIdleService,private clientloginServices :LoginService){

  }

  ngOnInit(): void {
    this.signInService.isLoggedIn.subscribe((isLoggedIn) => {
      this.checkIdleService.setLoginState(isLoggedIn);
    });
   
    this.clientloginServices.isLoggedIn.subscribe((isLoggedIn) => {
      this.checkIdleService.setLoginState(isLoggedIn);
    });
  }

  
}
