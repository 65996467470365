import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AddEditContactService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getDropDownList(clientId:number): Observable<any> {
    let url = this.apiUrl + 'Client/contacts/lists/'+clientId;
    return this.http.get<any>(url);
  }
  getContactDetails(contactId :number): Observable<any> {
    let url = this.apiUrl + 'Client/contact/'+contactId;
    return this.http.get<any>(url);
  }
  updateContact(formValue :any): Observable<any> {
    let payLoad = {
      contactID: formValue.contactID,
      clientID: formValue.clientID,
      clientName:formValue.clientName,
      title: formValue.title?.salutaionName?formValue.title.salutaionName:formValue.title,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      alias: formValue.alias,
      emailAddress: formValue.emailAddress,
      phoneNumber: formValue.phoneNumber,
      status: formValue.status,
      userName: formValue.userName,
      isPrimaryContact: formValue.isPrimaryContact ? formValue.isPrimaryContact : false,
      clientRoleID:formValue.clientRoleID,
      themeID: formValue.themeID,
      address: formValue.address,
      beneficiaryPercentage: formValue.beneficiaryPercentage,
      dateOfBirth: formValue.dateOfBirth,
      isAuthorisedSignatory: formValue.isAuthorisedSignatory ? formValue.isAuthorisedSignatory : false,
      isAuthorisedToTrade: formValue.isAuthorisedToTrade ? formValue.isAuthorisedToTrade : false,
      isAuthorisedUser: formValue.isAuthorisedUser ? formValue.isAuthorisedUser : false,
      isBeneficialOwner: formValue.isBeneficialOwner ? formValue.isBeneficialOwner : false,
      middleName: formValue.middleName,
      nationalityID: formValue.nationalityID,
      position: formValue.position,
    };
    
    let url = this.apiUrl + 'Client/contacts';
    return this.http.patch<any>(url, payLoad);
  }
  addContact(formValue :any): Observable<any> {
    if(formValue.clientRoleID =="" && formValue.clientRoleID ==null){
      formValue.clientRoleID=0;
    }
    let payLoad = {      
      clientID: formValue.clientName?.clientID,      
      clientName:formValue.clientName?.clientName,
      title: formValue.title?.salutaionName,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      alias: formValue.alias,
      emailAddress: formValue.emailAddress,
      phoneNumber: formValue.phoneNumber,
      status: formValue.status,
      userName: formValue.userName,
      isPrimaryContact: formValue.isPrimaryContact ? formValue.isPrimaryContact : false,
      clientRoleID:formValue.clientRoleID,
      themeID: formValue.themeID,
      address: formValue.address,
      beneficiaryPercentage: formValue.beneficiaryPercentage,
      dateOfBirth: formValue.dateOfBirth,
      isAuthorisedSignatory: formValue.isAuthorisedSignatory ? formValue.isAuthorisedSignatory : false,
      isAuthorisedToTrade: formValue.isAuthorisedToTrade ? formValue.isAuthorisedToTrade : false,
      isAuthorisedUser: formValue.isAuthorisedUser ? formValue.isAuthorisedUser : false,
      isBeneficialOwner: formValue.isBeneficialOwner ? formValue.isBeneficialOwner : false,
      middleName: formValue.middleName,
      nationalityID: formValue.nationalityID,
      position: formValue.position,
    };    
    
    let url = this.apiUrl + 'Client/contacts/add';
    return this.http.post<any>(url, payLoad);
  }

  getRoleWithClientID(clientID: number){
    return this.http.get(this.apiUrl + `Common/ClientRoles?clientID=${clientID}`);
  }

}
