<!-- <app-header></app-header> -->
<div class="loader-block" *ngIf="loading">
  <div class="inner-loader">{{loadingMesage}}</div>
</div>
<div class="">
  <div class="compress-inputs-page">
    <div class="inner secondary">
      <div class="title">
        <h3>&nbsp;</h3>

        <a (click)="back()" class="btn btn-outline-secondary btn-sp">
          <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt="" /></em>
          <!-- <em class="arrow-back-white"><img src="../../../assets/left-arrow-white.svg"
                            alt=""></em> -->
          Back
        </a>
      </div>
      <div class="card secondary">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs">
          <li id="liGeneral" class="nav-item">
            <a class="nav-link" data-toggle="tab" [ngClass]="{ active: isGeneralTabActive }">General</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="CARPartnerGeneral">
            <div class="tab-body-wrapper">
              <form action="" class="form" [formGroup]="tenantForm" (ngSubmit)="onSave()">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel" for="tenantName">Tenant Name<em style="color: red">*</em></label>
                      <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error': errorArray[0] != ''
                        }" title="{{ errorArray[0] }}">
                        <input type="text" class="form-control" placeholder="Tenant Name" id="tenantName"
                          formControlName="tenantName" (input)="errorChecking()" (blur)="errorChecking()" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Is Active </label>
                      <div class="client custom-switch">
                        <input type="checkbox" class="switch-control-input" formControlName="isActive" />

                        <span class="switch-control-label"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel" for="phone">Phone<em style="color: red">*</em></label>
                      <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error': errorArray[1] != ''
                        }" title="{{ errorArray[1] }}">
                        <input type="text" class="form-control" placeholder="Phone" id="phone" formControlName="phone"
                          (input)="errorChecking()" (blur)="errorChecking()" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel" for="email">Email<em style="color: red">*</em></label>
                      <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error': errorArray[2] != ''
                        }" title="{{ errorArray[2] }}">
                        <input type="text" class="form-control" placeholder="Email" id="email" formControlName="email"
                          (input)="errorChecking()" (blur)="errorChecking()" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Products</label>

                      <div class="value-wrapper">
                        <ng-select [items]="products" bindLabel="productName"  [searchable]="true"
                          [multiple]="true" placeholder="Select" formControlName="productIDs"
                          [compareWith]="compareProduct" (change)="errorChecking()" (blur)="errorChecking()">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Statement Tier<em style="color: red">*</em></label>
                      <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error': errorArray[3] != ''
                        }" title="{{ errorArray[3] }}">
                        <div class="">
                          <ng-select [items]="statementTiers" [searchable]="true" bindLabel="tierName"
                            bindValue="statementTierID" placeholder="Select" formControlName="statementTierID">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Trade Currency Pairs</label>

                      <div class="value-wrapper">
                        <ng-select [items]="tradeCurrencyPairs" [searchable]="true"
                          bindLabel="currencyPairName" [multiple]="true" placeholder="Select"
                          [compareWith]="compareTradeCurrencyPair" formControlName="currencyPairIDs">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Change Beneficiary to Payee </label>
                      <div class="client custom-switch">
                        <input type="checkbox" class="switch-control-input"
                          formControlName="changeBeneficiaryToPayee" />

                        <span class="switch-control-label"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Primary Dealer<em style="color: red">*</em></label>

                      <div class="value-wrapper">
                        <ng-select [items]="relationshipManagers" [searchable]="true" bindValue="managerID"
                          bindLabel="managerName" placeholder="Select" formControlName="relationshipManagerID">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Filter Clients on Dealer
                      </label>
                      <div class="client custom-switch">
                        <input type="checkbox" class="switch-control-input"
                          formControlName="filterOnRelationshipManager" />

                        <span class="switch-control-label"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel" for="cashflow">Cashflow Counterparty</label>
                      <input type="text" class="form-control" placeholder="Tenant Name" id="cashflow"
                        formControlName="cashflowCounterparty" (blur)="validateCashflowCounterParty()" />
                    </div>
                  </div>
                  <div class="col-sm-12  mt-5">

                    <h4 style="font-size: 26px; margin: 0; letter-spacing: 0" class="mb-4">
                      Logo
                    </h4>

                    <hr />
                  </div>

                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Confos (Light Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="onFileSelected($event, 'confoLogoLight', 0)" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Confos (Dark Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="onFileSelected($event, 'confoLogoDark', 1)" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[0] ? logoPreviews[0] : logos[0]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[1] ? logoPreviews[1] : logos[1]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Factsheet (Light Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="
                          onFileSelected($event, 'factSheetLogoLight', 2)
                        " />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Factsheet (Dark Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="
                          onFileSelected($event, 'factSheetLogoDark', 3)
                        " />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[2] ? logoPreviews[2] : logos[2]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[3] ? logoPreviews[3] : logos[3]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Side Panel (Light Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="
                          onFileSelected($event, 'sidePanelLogoLight', 4)
                        " />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Side Panel (Dark Theme)<em style="color: red">*</em></label>
                      <input type="file" class="form-control" (change)="
                          onFileSelected($event, 'sidePanelLogoDark', 5)
                        " />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[4] ? logoPreviews[4] : logos[4]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group label-inline">
                      <label class="boldLabel"></label>
                      <div class="value-wrapper">
                        <img [src]="logoPreviews[5] ? logoPreviews[5] : logos[5]" id="imgLogo" />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12  mt-5">

                    <h4 style="font-size: 26px; margin: 0; letter-spacing: 0">
                      Confos
                    </h4>

                    <hr />
                  </div>

                  <div class="col-md-12">
                    <div class="form-group label-inline" style="align-items: start; padding-top: 10px">
                      <label class="boldLabel">Print Name<em style="color: red">*</em></label>
                      <div class="showhide" [ngClass]="{
                          'newTooltip expand has-error': errorArray[4] != ''
                        }" title="{{ errorArray[4] }}">
                        <input type="text" class="form-control" formControlName="confosPrintName" maxlength="150"
                          (input)="errorChecking()" />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group label-inline">
                      <label class="boldLabel">Use<em style="color: red">*</em></label>
                      <div class="checkbox-block">
                        <div class="form-check-inline">
                          <div class="custom-radio">
                            <input type="radio" id="rbStatusActive" name="confoUse" value="PrintName"
                              class="radio-input" [tabindex]="1" formControlName="confoUse" />
                            <span class="radio-label">Print Name</span>
                          </div>
                        </div>
                        <div class="custom-radio">
                          <div class="form-check-inline">
                            <input type="radio" id="rbStatusActive" name="confoUse" value="RelationshipManager"
                              class="radio-input" [tabindex]="2" formControlName="confoUse" />
                            <span class="radio-label">Relationship Manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group label-inline" style="align-items: start; padding-top: 10px">
                      <label class="boldLabel">Address</label>
                      <textarea type="text" class="form-control" width="100%" formControlName="address"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group label-inline" style="align-items: start; padding-top: 10px">
                      <label class="boldLabel">Disclaimer</label>
                      <textarea type="text" class="form-control" width="100%" formControlName="disclaimer"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="inline-element d-block text-center">
                      <button type="submit" id="aSaveTenantDetails" class="btn btn-success">
                        Save
                      </button>
                      <button type="button" id="aCancelSaveTenant" class="btn btn-secondary" (click)="cancel()">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>