<div class="compress-inputs-page">
    <div class="inner user-dashboard">
        
        <div class="row">
            <div class="col-xl-6 col-lg-12" id="dvSpotRates">
                <div class="card-wrapper">
                    <div class="card">
                        <h4 class="title">Spot Rates</h4>
                        <app-spot-rates [SpotRateList]="spotRateList"></app-spot-rates>

                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12" id="dvRateCharts">
                <div id="divRateChartsWidget">
                   <app-rate-charts (toggleSize)="changeSize($event)" [SpotRateList]="spotRateList"></app-rate-charts>
                </div>
            </div>
           
            <div class="col-xl-12 col-lg-12">
                <div class="news-block">
                    <div class="card">
                        <h4 class="title">News Feed</h4>
                        
                        <app-news-feed></app-news-feed>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade xlg" id="divFactsheet">
</div>