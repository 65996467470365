<!-- <app-header></app-header> -->
<!-- <div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div> -->
<div class="">
  <div class="compress-inputs-page">
    <div class="inner secondary">
      <div class="title">
        <h3>&nbsp;</h3>

        <a (click)="back()" class="btn btn-outline-secondary btn-sp">
          <em class="arrow-back"
            ><img src="../../../assets/left-arrow.svg" alt=""
          /></em>
          <!-- <em class="arrow-back-white"><img src="../../../assets/left-arrow-white.svg"
                            alt=""></em> -->
          Back
        </a>
      </div>
      <div class="card secondary">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs">
          <li
            *ngIf="uiTabpermission[clientGeneral_TabID]"
            id="liGeneral"
            class="nav-item"
          >
            <a
              *ngIf="editMode"
              class="nav-link"
              routerLinkActive="active"
              data-toggle="tab"
              [routerLink]="[
                { outlets: { tabnavigation: ['clientgeneral', this.clientID] } }
              ]"
              (click)="handleTabClick('General')"
              >General</a
            >
            <a
              *ngIf="!editMode"
              class="nav-link active"
              routerLinkActive="active"
              data-toggle="tab"
              [routerLink]="[{ outlets: { tabnavigation: [''] } }]"
              >General</a
            >
          </li>

          <div *ngIf="editMode" class="nav">
            <li *ngIf="uiTabpermission[3]" id="liAddress" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                (click)="handleTabClick('address')"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: { tabnavigation: ['clientaddress', this.clientID] }
                  }
                ]"
                >Address</a
              >
            </li>
            <li *ngIf="uiTabpermission[clientContact_TabID]" id="liContacts" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientcontacts', this.clientID]
                    }
                  }
                ]"
                (click)="handleTabClick('CRM')"
                >Contacts</a
              >
            </li>
            <li *ngIf="uiTabpermission[9]" id="liTrading" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  { outlets: { tabnavigation: ['trading', this.clientID] } }
                ]"
                (click)="handleTabClick('Trading')"
                >Trading</a
              >
            </li>

            <li *ngIf="uiTabpermission[2]" id="liDeltaLimits" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  { outlets: { tabnavigation: ['DeltaLimit', this.clientID] } }
                ]"
                (click)="handleTabClick('delta')"
                >Delta Limits</a
              >
            </li>

            <li *ngIf="uiTabpermission[11]" id="liBanking" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  { outlets: { tabnavigation: ['banking', this.clientID] } }
                ]"
                (click)="handleTabClick('Banking')"
                >Banking</a
              >
            </li>

            <li *ngIf="uiTabpermission[1]" id="liCRM" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                [routerLink]="[
                  { outlets: { tabnavigation: ['clientcrm', this.clientID] } }
                ]"
                routerLinkActive="active"
                (click)="handleTabClick('CRM')"
                >CRM</a
              >
            </li>

            <li *ngIf="uiTabpermission[6]" id="liSMTier" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientsalesmargin', this.clientID]
                    }
                  }
                ]"
                (click)="handleTabClick('sales')"
                >Sales Margin Settings</a
              >
            </li>

            <li *ngIf="uiTabpermission[4]" id="liAccount" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientaccountgrid', this.clientID]
                    }
                  }
                ]"
                (click)="handleTabClick('accounts')"
                >Accounts</a
              >
            </li>

            <li *ngIf="uiTabpermission[5]" id="liXpresssHedge" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                (click)="handleTabClick('xpress')"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientXpresssHedge', this.clientID]
                    }
                  }
                ]"
                >XpresssHedge</a
              >
            </li>

            <li *ngIf="uiTabpermission[clientOrdersMargin_TabID]" id="liOrdersMargin" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#OrdersMargin"
                (click)="handleTabClick('orders')"
                routerLinkActive="active"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientOrdersMargin', this.clientID]
                    }
                  }
                ]"
                >Orders Margin</a
              >
            </li>

            <li
              *ngIf="uiTabpermission[clientBeneficiaries_TabID]"
              id="liBeneficiaries"
              class="nav-item"
            >
              <!-- *ngIf="uiTabpermission[8]" -->
              <a
                class="nav-link"
                data-toggle="tab"
                href="#Beneficiaries"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientBeneficiaries', this.clientID]
                    }
                  }
                ]"
                routerLinkActive="active"
                (click)="handleTabClick('beneficiaries')"
                >Beneficiaries</a
              >
            </li>

            <li
              *ngIf="uiTabpermission[clientAttachments_TabID]"
              id="liAttachments"
              class="nav-item"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                [routerLink]="[
                  { outlets: { tabnavigation: ['attachments', this.clientID] } }
                ]"
                routerLinkActive="active"
                (click)="handleTabClick('Attachments')"
                >Attachments</a
              >
            </li>

            <li
              *ngIf="uiTabpermission[clientNote_TabID]"
              id="liNotes"
              class="nav-item"
            >
              <!-- *ngIf="uiTabpermission[8]" -->
              <a
                class="nav-link"
                data-toggle="tab"
                href="#Notes"
                [routerLink]="[
                  {
                    outlets: {
                      tabnavigation: ['clientnotes', this.clientID]
                    }
                  }
                ]"
                routerLinkActive="active"
                (click)="handleTabClick('notes')"
                >Notes</a
              >
            </li>
          </div>
        </ul>
        <div class="tab-content">
          <!-- General Body -->
          <div class="tab-pane active" id="CARPartnerGeneral">
            <div class="tab-body-wrapper">
              <router-outlet name="tabnavigation"></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
