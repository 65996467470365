<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">Loading...</div>
    </div>
  </div>
  
<div class="inner secondary">
    <form clas="form-group" [formGroup]="beneficaryForm" (ngSubmit)="search()">
    <div class="card" style="margin-bottom:10px;">
        <div class="row">
            <div class="col-md-2 col-sm-6">
                <div class="form-group" id="ManageBeneficiary">
                    <label>{{user}} Name</label>
                    <input (keydown.enter)="search()" 
                    type="text" class="form-control" formControlName="beneficiaryName" maxlength="50" id="txtBName">
                </div>
            </div>

            <div class="col-md-2 col-sm-6">
                <div class="form-group" id="ManageBeneficiary">
                    <label>{{user}} Country</label>
                    <input type="text" (keydown.enter)="search()"
                    class="form-control" formControlName="beneficiaryCountry" maxlength="50" id="txtBCountry">
                </div>
            </div>

            <div class="col-md-2 col-sm-6">
                <div class="form-group" id="ManageBeneficiary">
                    <label>{{user}} Currency</label>   
                    <ng-select 
                      [items]="currencyPairs" 
                      [closeOnSelect]="false" 
                      [searchable]="true" 
                      bindLabel="currency"
                      bindValue="currencyID" 
                      placeholder="Select" 
                      formControlName="currencyID"
                      (keydown.enter)="search()"
                      (keydown)="closeDropdwon($event)"
                      (click)="closeDropdwon($event)"
                    >
                </ng-select>        
                  
                </div>
            </div>


            <div class="col-md-2 col-sm-6">
                <div class="form-group" id="ManageBeneficiary">
                    <label>Routing Type</label>
                    <ng-select [items]="routingTypes" [closeOnSelect]="false" [searchable]="true" bindLabel="fundtype"
                    bindValue="fundtypeId" placeholder="Select" 
                    (keydown.enter)="search()"
                    (keydown)="closeDropdwon($event)"
                    (click)="closeDropdwon($event)"
                    formControlName="fundtypeId">
                </ng-select>   
                </div>
            </div>



            <div class="col-md-2 col-sm-6">
                <div class="form-group" id="ManageBeneficiary">
                    <label> Status </label>
                    <div class="checkbox-currency">
                        <div class="custom-radio secondary">
                         <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedstatus" value="Active"  class="radio-input" name="Status">

                            <span class="radio-label">Active</span>
                        </div>
                        <div class="custom-radio secondary">
                            <input type="radio" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedstatus" value="Inactive"class="radio-input" name="Status">

                            <span class="radio-label">Inactive</span>
                        </div>
                    </div>

                </div>
            </div>



        </div>

        <div class="row">
            <div class="col-md-2 col-sm-6">
                <div class="inline-element">

                    <button id="btnBeneficiarySearch" type="submit" class="btn btn-success">Search</button>

                    <a  class="btn btn-secondary" (click)="resetButton()" >Reset</a>
                </div>
            </div>
        </div>

    

    </div>
  
    <div class="card">
        <div class="nth-last-child-2-center nth-last-child-3-center fixed-header-table">         
          
                <div class="inline-element" *ngIf="canInsert" style="padding-top:25px;float:right;">
                    <a (click)="addBeneficiary()" class="btn btn-success" id="btnBeneficiarySearch">Add {{user}}</a>
                </div>
          
            <div id="NestedGrid" class="GridClass">

                <div class="row" *ngIf="isAdmin">
                    
                    
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tenant/CAR Partner</label>
                                <ng-select [items]="tenentCarPartners" [closeOnSelect]="true" [searchable]="true" bindLabel="tenantCARPartnerName"
                                  bindValue="tenantCARPartnerName" (change)="getClientList()" placeholder="Select" formControlName="tenantOrCARPartner">
                                 </ng-select>   
                                
                            </div>
                        </div>
                   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Client</label>
                            <ng-select [items]="clients" [closeOnSelect]="true" [searchable]="true" bindLabel="name"
                            bindValue="clientID" placeholder="Select" formControlName="clientID">
                           </ng-select>                            
                        </div>
                    </div>

                </div>

                <div class="table-responsive" id="dvBeneficiarylist" style="overflow-x: auto;">
                    <div *ngIf="paginatedData.length>0;else notFound" class="GridClass custom-container">
                        <div class="table-responsive">
                          <table class="table custom-grid">                          
                
                            <thead>
                              <tr class="Row123 textstyle">
                                <th *ngIf="canUpdate">
                                    <a class="sort" unselectable="on" data-i="8">
                                      <span class="action">Action</span>
                                    </a>
                                  </th>
                                <th>
                                  <a
                                    class="sort active ts-asc"
                                    (click)="sortTable('beneficiaryName')"
                                    unselectable="on"
                                    data-i="0"
                                    >Name<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                  <a
                                    class="sort active ts-asc"
                                    (click)="sortTable('currency')"
                                    unselectable="on"
                                    data-i="1"
                                    >Currency<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                  <a
                                    class="sort active"
                                    (click)="sortTable('bankName')"
                                    unselectable="on"
                                    data-i="3"
                                  >
                                  Bank Name<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                  <a
                                    class="sort active"
                                    (click)="sortTable('type')"
                                    unselectable="on"
                                    data-i="4"
                                    >Type<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                  <a
                                    class="sort active"
                                    (click)="sortTable('bankAccountNo')"
                                    unselectable="on"
                                    data-i="4"
                                    >Account No<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                  <a
                                    class="sort active"
                                    (click)="sortTable('swiftCode')"
                                    unselectable="on"
                                    data-i="4"
                                    >Swift / IBAN<span class="ts-sord"></span>
                                  </a>
                                </th>
                                <th>
                                    <a
                                      class="sort active"
                                      (click)="sortTable('reference')"
                                      unselectable="on"
                                      data-i="4"
                                      >Internal Reference<span class="ts-sord"></span>
                                    </a>
                                  </th>
                                  <th>
                                    <a
                                      class="sort active"
                                      (click)="sortTable('status')"
                                      unselectable="on"
                                      data-i="4"
                                      >Status<span class="ts-sord"></span>
                                    </a>
                                  </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let row of paginatedData">
                                <td *ngIf="canUpdate">
                                    <div class="action-btns">
                                      <a  (click)="openModal(row.beneficiaryID)" style="cursor: pointer;"  title="Edit"
                                        ><img
                                          src="../../../../assets/edit.svg"
                                          alt=""
                                      /></a>
                                    </div>
                                  </td>
                                <td>{{ row.beneficiaryName }}</td>
                                <td>{{ row.currency }}</td>
                                <td>{{ row.bankName }}</td>
                                <td>{{ row.type }}</td>
                                <td>{{ row.bankAccountNo }}</td>
                                <td>{{ row.swiftCode }}</td>
                                <td>{{ row.reference }}</td>
                                <td>
                                  <span
                                  class="badge"
                                  [class.badge-success]="row.status === 'Y'"
                                  [class.badge-inactive]="row.status === 'N'"                                
                                  >
                                  {{ row.status === 'Y' ? 'Active' : 'Inactive' }}
                                  </span>
                                </td>
                                
                              </tr>              
                            </tbody>
                          </table>
                          <mat-paginator
                          #paginator
                          (page)="handlePageEvent($event)"
                          [length]="length"
                          [pageSize]="pageSize"
                          [disabled]="disabled"
                          [showFirstLastButtons]="showFirstLastButtons"
                          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                          [hidePageSize]="hidePageSize"
                          [pageIndex]="pageIndex"
                          aria-label="Select page"
                        >
                        </mat-paginator>
                        </div>
                        
                      </div>
                      <ng-template #notFound>
                        <div class="alert alert-info">
                            <span>No record found</span>
                        </div>    
                        </ng-template>  
                </div>
               
            </div>

        </div>

    </div>
</form>
</div>

<div class="modal fade secondary backdrop" id="EditBeneficiary-modal" style="overflow-y: auto;">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div id="divEditBeneficiarypopup" >
            <app-add-beneficiary (onCloseModal)="close()"></app-add-beneficiary>
          </div>
      </div>
  </div>
</div>
