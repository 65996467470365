import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPairService } from '../../services/CurrencyPair/currency-pair.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import swal from 'sweetalert2';
import {
  CurrecyPairData,
  CurrypairAddPayload,
  CurrypairUpdatePayload,
} from './models/currencypair.model';
import { ErrorMessages, SpinnerMessage } from '../../global/global.constant';

@Component({
  selector: 'app-add-edit-currency-pairs',
  templateUrl: './add-edit-currency-pairs.component.html',
  styleUrl: './add-edit-currency-pairs.component.css',
})
export class AddEditCurrencyPairsComponent {
  editMode = false;
  currencyPairId: any;
  currencyList: any;
  dataLoaded = false;
  loadingMessage = 'Loading';
  @ViewChild('baseInput') baseInput!: ElementRef;
  @ViewChild('termInput') termInput!: ElementRef;
  baseCurrency: any;
  termCurrency: any;
  baseCurrencyID: any;
  termCurrencyID: any;
  errorForCurrencyPair: any;
  addEditCurrencyPairForm: FormGroup = this.fb.group({
    currencyPairID: [''],
    priceIncrement: [''],
    spotForwardTreasuryMarginType: ['Percentage'],
    baseCurrencyID: ['', [Validators.required]],
    baseCurrencyName: [''],
    termCurrencyID: ['', [Validators.required]],
    termCurrencyName: [''],
    currencyPairFormed: new FormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    spotsForwards: [false],
    options: [false],
    deltaBaseCurrencyID: [''],
    deltaBaseCurrency: [''],
    iceBook: [''],
    isActive: [false],
    spotForwardTreasuryMargin: ['', [Validators.required]],
    treasuryMarginPercent: [''],
  });
  errormsg: any;
  spotForwardTreasuryMarginType: string = 'Percentage';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private currencyService: CurrencyPairService,
    private alert: SweetalertService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((parameterMap) => {
      const id = +parameterMap.get('id')!;
      if (id) {
        this.currencyPairId = id;
        this.editMode = true;
        this.getDropDownList();
        this.getCurrencyPairDetail(this.currencyPairId);
      } else {
        this.getDropDownList();
      }
    });
  }

  getDropDownList() {
    this.dataLoaded = false;
    this.currencyService.getCurrencyList().subscribe({
      next: (response: any) => {
        this.currencyList = response.currencies;

        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  marginTypeChange(type: string): void {
    this.addEditCurrencyPairForm.get('spotForwardTreasuryMargin')?.reset();
    this.spotForwardTreasuryMarginType = type;
  }

  getCurrencyPairDetail(id: number) {
    this.dataLoaded = false;
    this.currencyService.getCurrencyDetails(this.currencyPairId).subscribe({
      next: (response: CurrecyPairData) => {
        this.addEditCurrencyPairForm.patchValue(response);
        this.baseCurrency = response.currencyPairFormed.substring(0, 3);
        this.termCurrency = response.currencyPairFormed.substring(3, 6);
        this.baseCurrencyID = response.baseCurrencyID;
        this.termCurrencyID = response.termCurrencyID;
        this.spotForwardTreasuryMarginType = response.spotForwardTreasuryMarginType;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }
  
  onChangeOfdropDownbase(event: any) {
    this.errorForCurrencyPair = false;
    const base = event?.currencySymbol;
    const term =
      this.addEditCurrencyPairForm.value?.termCurrencyID.currencySymbol;
    const termNew = term ? term : this.termCurrency;
    if (base === termNew) {
      this.errorForCurrencyPair = true;
    } else if (base && termNew) {
      var currencyformed = base + termNew;
      this.addEditCurrencyPairForm
        .get('currencyPairFormed')
        ?.setValue(currencyformed);
    }
  }

  onChangeOfdropDownterm(event: any) {
    this.errorForCurrencyPair = false;
    const term = event?.currencySymbol;
    const base =
      this.addEditCurrencyPairForm.value?.baseCurrencyID.currencySymbol;
    const baseNew = base ? base : this.baseCurrency;
    if (baseNew === term) {
      this.errorForCurrencyPair = true;
    } else if (term && baseNew) {
      var currencyformed = baseNew + term;
      this.addEditCurrencyPairForm
        .get('currencyPairFormed')
        ?.setValue(currencyformed);
    }
  }

  compareBase(item: any, selected: any) {
    return item.currencyID === selected;
  }

  compareterm(item: any, selected: any) {
    return item.currencyID === selected;
  }

  onSave() {
    if (this.addEditCurrencyPairForm.valid) {
      const formValue = this.addEditCurrencyPairForm.getRawValue();
      if (this.editMode) {
        this.dataLoaded = false;
        this.loadingMessage = SpinnerMessage.Update;
        const payload: CurrypairUpdatePayload = {
          currencyPairID: formValue.currencyPairID,

          priceIncrement: formValue.priceIncrement,
          baseCurrencyID: formValue.baseCurrencyID.currencyID
            ? formValue.baseCurrencyID.currencyID
            : this.baseCurrencyID,
          termCurrencyID: formValue.termCurrencyID.currencyID
            ? formValue.termCurrencyID.currencyID
            : this.termCurrencyID,
          currencyPairFormed: formValue.currencyPairFormed,
          spotsForwards: formValue.spotsForwards,
          options: formValue.options,
          iceBook: formValue.iceBook,
          isActive: formValue.isActive,
          spotForwardTreasuryMargin: formValue.spotForwardTreasuryMargin,
          spotForwardTreasuryMarginType:
            formValue.spotForwardTreasuryMarginType,
        };

        this.currencyService.updateCurrencyPair(payload).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.alert.fireAlert('Updated').then(() => {
              this.back();
            });
          },
          error: (error) => {
            this.dataLoaded = true;
            if (error.error && Array.isArray(error.error)) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = 'Update Beneficiary failed';
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });
          },
        });
      } else {
        this.dataLoaded = false;
        this.loadingMessage = SpinnerMessage.Saving;
        const payload: CurrypairAddPayload = {
          baseCurrencyID: formValue.baseCurrencyID.currencyID,
          termCurrencyID: formValue.termCurrencyID.currencyID,
          currencyPairFormed: formValue.currencyPairFormed,
          spotForwardTreasuryMargin: formValue.spotForwardTreasuryMargin,
          options: formValue.options,
          iceBook: formValue.iceBook,
          isActive: formValue.isActive,
          spotForwardTreasuryMarginType:
            formValue.spotForwardTreasuryMarginType,
          priceIncrement: formValue.priceIncrement,
          spotsForwards: formValue.spotsForwards,
        };

        this.currencyService.addCurrencyPair(payload).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then(() => {
              this.back();
            });
          },
          error: (error) => {
            this.dataLoaded = true;
            if (error.error && Array.isArray(error.error)) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = ErrorMessages.Something_goes_wrong;
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });
          },
        });
      }
    } else {
      this.markFormGroupTouched(this.addEditCurrencyPairForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  back() {
    this.router.navigate(['Admin/ManageCurrencyPair']);
  }

  cancel() {
    if (this.editMode) {
      this.addEditCurrencyPairForm.reset();
      this.getCurrencyPairDetail(this.currencyPairId);
    } else {
      this.back();
    }
  }
}
