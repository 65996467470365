<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">Loading...</div>
    </div>
  </div>  
<form class="form">
    <div class="compress-inputs-page quote-detail-page credit-Limit-class" style="padding-bottom: 50px;">
        <div class="inner">
            <div class="title">
                <h3>&nbsp;</h3>
                <div>

                    <a  id="btnBackQuoteDetails" (click)="back()" class="btn btn-outline-secondary btn-sp">
                        Back
                    </a>

                    <a  id="btnBackQuoteDetails" (click)="home()" class="btn btn-outline-secondary btn-sp">
                        Home
                    </a>

                    <a id="btnQuoteConfirm" (click)="openModal()" class="btn btn-outline-secondary btn-sp">
                        Email
                    </a>
                    <a  (click)="print()" class="btn btn-outline-secondary btn-sp">
                        Print 
                    </a>
                    <a (click)="download()" class="btn btn-outline-secondary btn-sp">
                        Download
                    </a>
                </div>
            </div>
            <div class="card-wrapper">
                <div class="card">
                    <div class="has-sub-title">
                        <h4 class="title">Summary of Deal {{tradeId}} ({{product}}) </h4>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="summary-list">
                                <label class="strong">Currency Pair</label>
                                <p class="value">{{summarydata?.currencyPairFormed}} </p>
                            </div>

                            <div class="summary-list">                              
                                    <label class="strong">Client Sells</label>                            
                              
                                    <p class="value">{{summarydata?.clientSellAmount | number:'1.2-2'}}
                                        {{summarydata?.clientSellCurrency}}</p>
                           

                            </div>

                            <div class="summary-list">
                                                     
                             
                                    <label class="strong">Client Buys</label>
                           

                               
                                    <p class="value">{{summarydata?.clientBuyAmount | number:'1.2-2'}}
                                        {{summarydata?.clientBuyCurrency}}</p>
                             
                            </div>

                            <div class="summary-list">
                              
                               
                                    <label class="strong">Spot Rate</label>
                        
                                <p class="value">{{summarydata?.rate}}</p>

                            </div>


                        </div>

                        <div class="col-sm-6">


                            <div class="summary-list">
                                <label class="strong">Value Date</label>
                                <p class="value">{{summarydata?.valueDate |date:'dd/MM/yyyy'}}</p>

                            </div>


                            <div class="summary-list">
                                <label class="strong">Funding Account</label>
                                <p class="value">{{summarydata?.fundingAccount}}</p>
                            </div>

                            <div class="summary-list">
                                <label class="strong">Payee Account</label>
                                <p class="value">{{summarydata?.payeeAccount}}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade secondary backdrop" id="QuoteConfirmMail-modal">
            <div class="modal-dialog modal-dialog-centered">
                <div id="divQuoteConfirmMail" class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Mail</h4>
                        <button type="button" class="close"(click)="close()" data-dismiss="modal"><img src="../../../../assets/cross.svg" alt=""></button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                       
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Mail To<em style="color:red;">*</em></label>
                                        <input type="text" id="SendMail" name="SendMail"  class="form-control" [(ngModel)]="emailString"/>
                                        <span style="font-size: smaller;">(Use Comma(,) to add multiple email address )</span>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="inline-element">
                                        <input type="hidden" value="@Model.Item1.TradeID" id="hdnTradeID" />
                                        <input type="button" name="Submit" value="Submit" (click)="submit()" id="btnQuoteConfirmMail" class="btn btn-success" />
                                    </div>
                                </div>
                                
                            </div>                   

                    </div>
                </div>
            </div>
        </div>
    </div>
