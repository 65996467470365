import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class CarPartnerService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getcarPartner(searchedstring :string ): Observable<any> {
    let payload={
      searchString: searchedstring
     }
    let url = this.apiUrl + 'CARPartner/search';
    return this.http.post<any>(url,payload);
  }

  getCarPartnerData(carPartnerId:number){
    return this.http.get(this.apiUrl+'CARPartner?carPartnerID='+carPartnerId);
  }

  updateCarPartner(payload:any){
    return this.http.patch(this.apiUrl+'CARPartner/update',payload);
  }
  addCarPartner(payload:any){
    return this.http.post(this.apiUrl+'CARPartner/add',payload);
  }
}
