<!-- <app-header></app-header> -->

<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>

<div >
    <div class="inner user-page">
        <div class="title">
            <span class="titleHeader mt-2">Manage Roles</span>
            <h3>&nbsp;</h3>
            <a (click)="addRole()" *ngIf="!hideAddButton" id="btnClientAddNew" class="btn btn-success" >
                <em><img src="../../../assets/plus.svg" alt=""></em>
                Add
            </a>
        </div>

        <div class="card maindiv">
            <div class="row search-criteria">
                <div class="col-md-3 col-sm-3">
                    <div class="form-group textstyle">
                        <label>Role Name</label>
                        <input type="text" class="form-control" id="txtRoleName" tabindex="1"
                            [(ngModel)]="searchString" (keydown.enter)="search()">
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="form-group ">
                        <label class="textstyle">Status</label>
                        <div class="checkbox-block">
                            <div class="form-check-inline">
                                <div class="custom-radio">
                                    <input type="radio" id="rbStatusActive" name="All" value="All"
                                        (change)="onRadioChange('All')" [(ngModel)]="selectedStatus" class="radio-input"
                                        [tabindex]="1" (keydown.enter)="search()"
                                    >
                                    <span class="radio-label">All</span>
                                </div>
                            </div>
                            <div class="custom-radio">
                                <div class="form-check-inline">
                                    <input type="radio" id="rbStatusActive" name="Active" value="Active"
                                        (change)="onRadioChange('Active')" [(ngModel)]="selectedStatus"
                                        class="radio-input" [tabindex]="2" (keydown.enter)="search()">
                                    <span class="radio-label">Active</span>
                                </div>
                            </div>
                            <div class="custom-radio">
                                <div class="form-check-inline">
                                    <input type="radio" id="rbStatusActive" name="Inactive" value="Inactive"
                                        (change)="onRadioChange('Inactive')" [(ngModel)]="selectedStatus"
                                        class="radio-input" [tabindex]="3" (keydown.enter)="search()">
                                    <span class="radio-label">Inactive</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="inline-element" style="padding-top:25px;">
                        <a class="btn btn-success" id="btnSearch" (click)="search()">Search</a>
                        <a class="btn btn-secondary" id="btnReset" (click)="reset()">Reset</a>
                    </div>
                </div>
            </div>

            <div class="nth-last-child-2-center">
            </div>

            <div *ngIf="paginatedData.length>0;else notFound" class="GridClass custom-container">
                <div class="table-responsive">
                    <table class="table custom-grid">
                        <colgroup>
                            <col style="width:20%">
                            <col style="width:20%">
                            <col style="width:10%">
                            <col style="width:10%">
                            <col style="width:5%">
                        </colgroup>

                        <thead>
                            <tr class="Row123">
                                <th><a class="sort  active  ts-asc" (click)="sortTable('roleName')" unselectable="on"
                                        data-i="0">Role Name<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  ts-asc" (click)="sortTable('description')" unselectable="on"
                                        data-i="1">Description<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  " (click)="sortTable('isActive')" unselectable="on"
                                        data-i="3">Active/Inactive<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active" (click)="sortTable('isSuperAdmin')" unselectable="on"
                                        data-i="4">Super Admin<span class="ts-sord"></span> </a></th>
                                <th><a class="sort" unselectable="on" data-i="8">
                                        <span class="action">Action</span>
                                    </a></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td>{{row.roleName}}</td>
                                <td>{{ row.description }}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="row.isActive"
                                            (change)="toggleSwitch(row, 'activation')">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="row.isSuperAdmin"
                                            [disabled]="disableSuperAdmin" (change)="toggleSwitch(row, 'superAdmin')">
                                        <span class="slider round" [class.disabledCursor]="disableSuperAdmin"></span>
                                    </label>
                                </td>
                                <td>
                                    <div class="action-btns"><a (click)="editRole(row.roleID)" title="Edit"><img
                                                src="../../../assets/edit.svg" alt=""></a></div>
                                </td>
                            </tr>

                            <tr *ngIf="paginatedData.length == 0">
                                <td class="text-center py-3 fw-bold boldFonts" colspan="5">No Data Found</td>
                            </tr>
                        </tbody>
                    </table>
                  
                </div>
                <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                [pageIndex]="pageIndex" aria-label="Select page">
            </mat-paginator>
            </div>
            <ng-template #notFound>
                <div class="alert alert-info">
                    <span>No record found</span>
                </div>
                </ng-template>
        </div>
    </div>
</div>