import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageTenantsService } from '../../services/ManageTenants/manage-tenants.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { jwtDecode } from 'jwt-decode';
import { SpinnerMessage } from '../../global/global.constant';
import { DOCUMENT } from '@angular/common';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-add-edit-tenant',
  templateUrl: './add-edit-tenant.component.html',
  styleUrl: './add-edit-tenant.component.css',
})
export class AddEditTenantComponent implements OnInit {
  loading: boolean = false;
  loadingMesage = SpinnerMessage.Loading;
  editMode: boolean = false;
  isGeneralTabActive: boolean = true;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

  products: any[] = [];
  statementTiers: any[] = [];
  tradeCurrencyPairs: any[] = [];
  relationshipManagers: any[] = [];

  tenantID = 0;

  uniqueCfcp: any = true;
  logos: any[] = [];
  logoPreviews: any[] = [];
  token: any;
  loggedInUser: any;
  useResponse: any;
  constructor(
    private fb: FormBuilder,
    private tenantService: ManageTenantsService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: SweetalertService,
    @Inject(DOCUMENT) private document: Document,
    private encryptPermission: EncryptRolePermissionService
  ) {}
  tenantForm: FormGroup = this.fb.group({
    tenantName: ['', [Validators.required]],
    isActive: [false],
    phone: ['', [Validators.required, Validators.maxLength(15)]],
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    productIDs: [[]],
    statementTierID: [[], [Validators.required]],
    currencyPairIDs: [[]],
    relationshipManagerID: [],
    changeBeneficiaryToPayee: [false],
    filterOnRelationshipManager: [false],
    cashflowCounterparty: [],
    confosPrintName: ['', [Validators.required, Validators.maxLength(150)]],
    confoUse: ['', [Validators.required]],
    address: [],
    disclaimer: [],
    confoLogoLight: [null, [Validators.required]],
    confoLogoDark: [null, [Validators.required]],
    factSheetLogoLight: [null, [Validators.required]],
    factSheetLogoDark: [null, [Validators.required]],
    sidePanelLogoLight: [null, [Validators.required]],
    sidePanelLogoDark: [null, [Validators.required]],
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      const tenantId = +parameterMap.get('tenantId')!;
      if (tenantId) {
        this.tenantID = tenantId;
        this.editMode = true;
        this.getDropDownList();
        this.getTenantDetails(tenantId);
      } else {
        this.getDropDownList();
      }
    });
  }

  getDropDownList() {
    this.tenantService.tenantList().subscribe({
      next: (result: any) => {
        this.products = result.products;
        this.statementTiers = result.statementTiers;
        this.relationshipManagers = result.relationshipManagers;
        this.tradeCurrencyPairs = result.tradeCurrencyPairs;
      },
    });
  }

  getTenantDetails(tenantId: number) {
    this.loading = true;
    this.tenantService.tenantDetails(tenantId).subscribe({
      next: (data: any) => {
        data.confoUse =
          data.confoUse === 1 ? 'PrintName' : 'RelationshipManager';

        this.tenantForm.patchValue({
          productIDs: data.products,
          currencyPairIDs: data.tradeCurrencyPairs,

          ...data,
        });
        this.logos.push(
          data.confoLogoLight,
          data.confoLogoDark,
          data.factSheetLogoLight,
          data.factSheetLogoDark,
          data.sidePanelLogoLight,
          data.sidePanelLogoDark
        );

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      },
    });
  }

  async getBase64FromFilePath(filePath: string): Promise<string> {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();

      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result as string);
        });
        reader.onerror = () => {
          reject('Error reading file as Base64.');
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw new Error('Error fetching or processing the file.');
    }
  }

  onFileSelected(event: any, controlName: string, index: number) {
    const file = event.target.files[0];
    this.tenantForm.patchValue({ [controlName]: file });

    const reader = new FileReader();
    reader.onload = () => {
      this.logoPreviews[index] = reader.result;
    };
    reader.readAsDataURL(file);
  }

  onSave() {
    const tenantFormValue = this.tenantForm.value;
    const payload: any = {
      ...tenantFormValue,
    };
    payload.productIDs = tenantFormValue.productIDs.map(
      (obj: any) => obj.productID
    );
    payload.currencyPairIDs = tenantFormValue.currencyPairIDs.map(
      (obj: any) => obj.currencyPairID
    );

    if (this.tenantForm.valid) {
      if (this.editMode) {
        this.loading = true;
        this.loadingMesage = 'Updating...';
        const formValue: any = {
          tenantID: this.tenantID,
          ...payload,
        };
        const formData = new FormData();

        for (const key of Object.keys(formValue)) {
          const value = formValue[key];
          if (key === 'productIDs' || key === 'currencyPairIDs') {
            value.forEach((element: any) => {
              formData.append(key, element);
            });
          } else {
            formData.append(key, value);
          }
        }

        this.tenantService.updateTenant(formData).subscribe({
          next: (data: any) => {
            this.loading = false;
            this.token =
              this.document.defaultView?.localStorage?.getItem('jwtToken');
            this.loggedInUser = jwtDecode(this.token);
            if (this.loggedInUser.tenantID == data.tenantID) {
              this.useResponse =
                this.encryptPermission.getDecryptedPermissions('userResponse');
              this.useResponse.enablePricerDealer =
                data.filterOnRelationshipManager;
              this.encryptPermission.encryption(
                this.useResponse,
                'userResponse'
              );
            }
            this.alert.fireAlert('Updated').then(() => {
              this.back();
            });
          },
          error: () => {
            this.loading = false;
          },
        });
      } else {
        this.loading = true;
        this.loadingMesage = SpinnerMessage.Saving;
        const formData = new FormData();
        const formValue = payload;
        for (const key of Object.keys(formValue)) {
          const value = formValue[key];
          if (key === 'productIDs' || key === 'currencyPairIDs') {
            value.forEach((element: any) => {
              formData.append(key, element);
            });
          } else {
            formData.append(key, value);
          }
        }
        this.tenantService.addTenant(formData).subscribe({
          next: () => {
            this.loading = false;
            this.alert.fireAlert('Saved').then(() => {
              this.back();
            });
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
  }
  cancel() {
    if (this.editMode) {
      this.getTenantDetails(this.tenantID);
    } else {
      this.tenantForm.reset();
    }
  }

  validateCashflowCounterParty() {
    const cashflowCounterparty = this.tenantForm.value.cashflowCounterparty;
    if (cashflowCounterparty.length > 0) {
      this.tenantService
        .validatecfcp(this.tenantID, cashflowCounterparty)
        .subscribe({
          next: (result: any) => {
            this.uniqueCfcp = result;
          },
          error: () => {},
        });
    }
  }

  errorArray: any = Array(5).fill('');
  errorChecking() {
    if (
      this.tenantForm.controls['tenantName'].touched ||
      this.tenantForm.controls['tenantName'].dirty
    ) {
      if (this.tenantForm.controls['tenantName'].errors?.['required']) {
        this.errorArray[0] = 'Tenant Name is Required.';
      } else {
        this.errorArray[0] = '';
      }
    }
    if (
      this.tenantForm.controls['phone'].touched ||
      this.tenantForm.controls['phone'].dirty
    ) {
      if (this.tenantForm.controls['phone'].errors?.['required']) {
        this.errorArray[1] = 'Phone is Required.';
      } else if (this.tenantForm.controls['phone'].errors?.['maxlength']) {
        this.errorArray[1] = 'phone should be of maximum 15 numbers';
      } else {
        this.errorArray[1] = '';
      }
    }
    if (
      this.tenantForm.controls['email'].touched ||
      this.tenantForm.controls['email'].dirty
    ) {
      if (this.tenantForm.controls['email'].errors?.['required']) {
        this.errorArray[2] = ' Email is Required.';
      } else if (this.tenantForm.controls['email'].errors?.['pattern']) {
        this.errorArray[2] = 'Email Address must be Valid Email Address.';
      } else {
        this.errorArray[2] = '';
      }
    }
    if (
      this.tenantForm.controls['statementTierID'].touched ||
      this.tenantForm.controls['statementTierID'].dirty
    ) {
      if (this.tenantForm.controls['statementTierID'].errors?.['required']) {
        this.errorArray[3] = 'Statement Tier is Required.';
      } else {
        this.errorArray[3] = '';
      }
    }
    if (
      this.tenantForm.controls['confosPrintName'].touched ||
      this.tenantForm.controls['confosPrintName'].dirty
    ) {
      if (this.tenantForm.controls['confosPrintName'].errors?.['required']) {
        this.errorArray[4] = 'Print Name is Required.';
      } else {
        this.errorArray[4] = '';
      }
    }
  }

  back() {
    this.router.navigate(['/Admin/managetenants']);
  }

  compareProduct(item: any, selected: any) {
    return item.productID === selected.productID;
  }

  compareTradeCurrencyPair(item: any, selected: any) {
    return item.currencyPairID === selected.currencyPairID;
  }
}
