<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">{{loadingMessage}}...</div>
    </div>
</div>

<div class="inner secondary">
    <div class="card">
        <form [formGroup]="openDealForm" (ngSubmit)="search()" *ngIf="isAdmin">
            <div class="row">
                <div class="col-md-2 col-sm-6">
                    <div class="form-group">
                        <label>Tenant/CAR Partner</label>
                        <ng-select [items]="tenentCarPartners" [clearable]="false" [closeOnSelect]="true"
                            [searchable]="true" bindLabel="name" bindValue="name" placeholder="Select"
                            formControlName="tenentCarPartner" (change)="getClients()"></ng-select>

                    </div>
                </div>
                <div class="col-md-2 col-sm-6" >
                    <div class="form-group" >
                        <label>Client</label>                       
                        <ng-select [ngClass]="{'newTooltip expand has-error': (openDealForm.controls['clients'].touched) && openDealForm.controls['clients'].invalid }"
                        datatitle="Client Is Required" [items]="clients" [clearable]="false" [closeOnSelect]="true" [searchable]="true"
                            bindLabel="name" bindValue="clientID" placeholder="Select" formControlName="clients"
                            (change)="getCreatedBy()"></ng-select>                       
                    </div>
                </div>

                <div class="col-md-2 col-sm-6">
                    <div class="form-group" >
                        <label>Created By </label>
                        <ng-select 
                            [items]="createdby" 
                            [clearable]="false" 
                            [closeOnSelect]="false" 
                            [searchable]="true"
                            bindLabel="name" 
                            bindValue="contactID" 
                            placeholder="Select"
                            formControlName="createdby"
                            (keydown.enter)="search()" 
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                        >
                    </ng-select>

                    </div>
                </div>

                <div class="col-md-2 col-sm-6">
                    <div class="inline-element">
                        <button id="btnSearchOpenDeal" type="submit" class="btn btn-success">Search</button>
                        <a class="btn btn-secondary" (click)="reset()" id="btnSFTradeReset">Reset</a>
                    </div>
                </div>
            </div>
        </form>
        <div class="secondary">
            <div class="GridClass">

                <div id="dvGridPage" *ngIf="!noRecordFound else elseBlock">
                    <ng-container *ngFor="let currencyPair of currencyPairs; let i = index">
                        <h6 class="report-sub-table-title">{{currencyPair.currencyPair}}
                            <div class="target-rem text-success"><strong>Spot Rate</strong>0</div>
                        </h6>
                        <ng-container
                            *ngFor="let deliveryDate of currencyPair.deliveryDates; let deliveryDateIndex= index">
                            <div class="table-responsive custom-scroll" style="overflow-x: auto; margin-bottom:20px;">
                                <table class="table">

                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="text-center">
                                                Delivery
                                            </th>
                                            <th class="text-center" >{{deliveryDate.baseCurrency }} Amount</th>
                                            <th class="text-center">{{deliveryDate.termCurrency }} Amount</th>
                                            <th class="text-center">Strike</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="ts-row1">
                                            <td class="action">
                                                <div>

                                                    <a style="float: right;" *ngIf="!deliveryDate.showDetails"
                                                        (click)="toggleShowdetails(i,deliveryDateIndex)" title="Expand">
                                                        <img src="../../../../assets/details_open.png" />
                                                    </a>

                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <a style="float: left; position: relative;"
                                                    *ngIf="deliveryDate.showDetails"
                                                    (click)="toggleShowdetails(i,deliveryDateIndex)" title="Expand">
                                                    <img src="../../../../assets/details_close.png" />
                                                </a>
                                                {{deliveryDate.deliveryDate | date:'dd-MMM-yyyy'}}

                                            </td>
                                            <td class="text-center" [ngStyle]="{ 'color': deliveryDate.baseAmount < 0 ? 'red' : 'black' }" >{{deliveryDate.baseAmount | number:'1.2-2'}}</td>

                                            <td class="text-center" [ngStyle]="{ 'color': deliveryDate.termAmount < 0 ? 'red' : 'black' }"> {{deliveryDate.termAmount }}
                                            </td>
                                            <td class="text-center"> {{deliveryDate.strike}}</td>

                                        </tr>

                                        <tr class="subTableRow" *ngIf="deliveryDate.showDetails">
                                            <td></td>
                                            <td colspan="8">

                                                <ng-container *ngFor="let trade of deliveryDate.trades">
                                                    <table class="table" id="TradeListSubTable">
                                                        <colgroup>
                                                            <col style="width: 5%" />
                                                            <col style="width: 5%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 10%" />
                                                            <col style="width: 5%" />
                                                            <col style="width: 5%" />
                                                            <col style="width: 5%" />
                                                            <col style="width: 5%" />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">TradeID</th>
                                                                <th class="text-left">ICE Trade ID</th>
                                                                <th class="text-left">DS Deal ID</th>
                                                                <th class="text-left">DS Error</th>
                                                                <th class="text-center">Source ID</th>
                                                                <th class="text-center">Source</th>
                                                                <th class="text-left">TicketID</th>
                                                                <th class="text-left">ICE Ticket ID</th>
                                                                <th class="text-center">Delivery</th>
                                                                <th class="text-left">Instrument</th>
                                                                <th class="text-right">{{deliveryDate.baseCurrency}}
                                                                    Amount</th>
                                                                <th class="text-right"> {{deliveryDate.termCurrency}}
                                                                    Amount</th>
                                                                <th class="text-right">Strike</th>
                                                                <th class="text-center">Deliverability</th>
                                                                <th class="text-center">Payment Detail</th>
                                                                <th class="text-center">Action</th>
                                                                <th class="text-center">Confirmation</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-left">
                                                                    <a sstyle="color: #007bff"
                                                                        title='View'>{{trade.tradeId}}</a>
                                                                </td>
                                                                <td class="text-left">{{trade.iceTradeId}}
                                                                </td>
                                                                <td class="text-left">{{trade.dsDealId}}</td>
                                                                <td class="text-left">{{trade.dsError}}</td>
                                                                <td class="text-right">{{trade.sourceID}}</td>
                                                                <td class="text-right">{{trade.source}}</td>
                                                                <td class="text-left"> {{trade.ticketId}} </td>
                                                                <td class="text-left">{{trade.iceTicketId}} </td>
                                                                <td class="text-center">{{trade.deliveryDate}}</td>

                                                                <td class="text-left">{{trade.instrument}}</td>

                                                             
                                                                <td class="text-right"[ngStyle]="{ 'color': trade.baseAmount < 0 ? 'red' : 'black' }" >{{trade.baseAmount | number:'1.2-2'}}</td>

                                                              
                                                                <td class="text-right" [ngStyle]="{ 'color': trade.termAmount < 0 ? 'red' : 'black' }">{{trade.termAmount | number:'1.2-2'}}</td>


                                                                <td class="text-right"> {{trade.strike}}</td>

                                                            

                                                                <td class="wrapword text-center"><span
                                                                        [title]="trade.deliverability">{{trade.deliverability}}</span>
                                                                </td>

                                                            
                                                                <td class="wrapword text-center"><span
                                                                        title="">{{trade.paymentDetail}}</span>
                                                                </td>


                                                                <td class="text-center ">
                                                                    <a (click)="editTrade(trade)"
                                                                        style="cursor:pointer;" title='Edit Deal'><img
                                                                            src="../../../../assets/edit.svg" /></a>
                                                                </td>
                                                                <td class="action text-center"
                                                                    style="text-align: center;">
                                                                    <a (click)="viewTradeDetails(trade.tradeId)"
                                                                        title="View Trade Confirmation PDF"><img
                                                                            src="../../../../assets/View-green.svg" /></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-container>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                      
                    </ng-container>
                    <mat-paginator
                        #paginator
                        *ngIf="length>pageSize"
                        (page)="handlePageEvent($event)"
                        [length]="length"
                        [pageSize]="pageSize"
                        [disabled]="disabled"
                        [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                        [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex"
                        aria-label="Select page"
                      >
                      </mat-paginator>
                </div>
                <ng-template #elseBlock>
                    <div class="alert alert-info" id="divNorecord">
                        <span>No record found.</span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<div class="modal fade  secondary backdrop" id="TradeView" role="dialog">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content"  >
            <div class="modal-header">
                <h4 class="modal-title">Confirmed Trade View for Trade ID:{{viewdTradeID}}</h4>
                <button type="button" class="close" (click)="close()" data-dismiss="modal"><img
                        src="../../../../assets/cross.svg" alt=""></button>
            </div>
            <div class="modal-body">
                <iframe [src]="pdfSrc" width="100%" height="600px"></iframe>
            </div>
        </div>
    </div>


</div>

<div class="modal fade  secondary backdrop" id="EditSFDeal-modal" style="overflow: auto;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content center-modal" >
            <div id="divEditSFOpenDealpopup">
                <div class="card dvcontainer" style="width:150%;">
                    <div class="has-sub-title">
                        <h4 class="title">Edit Trade {{viewdTradeID}}</h4>
                    </div>
                    <div class="card">
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Base</label>
                            </div>
                            <div class="col-md-6">
                                <span title="Buy"> Client Buys {{DealDetailData.clientBuyAmount | number:'1.2-2'}}
                                    {{DealDetailData.clientBuyCurrency}}</span>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Strike</label>
                            </div>
                            <div class="col-md-6">
                                <label>{{DealDetailData.rate}}</label>
                            </div>

                        </div>
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Term</label>
                            </div>
                            <div class="col-md-6">
                                <span title="Sell">Client Sells {{DealDetailData.clientSellAmount | number:'1.2-2'}}
                                    {{DealDetailData.clientSellCurrency}} </span>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3">
                                <label>Value Date</label>
                            </div>
                            <div class="col-md-6">
                                <span title="">
                                    {{DealDetailData.valueDate | date:'dd/MM/yyyy' }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label>Deliverability</label>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="checkbox-currency">
                                        <div class="custom-radio secondary">
                                            <input type="radio" value="AutoRoll" [(ngModel)]="deliverabilityType"
                                                name="Deliverability" class="radio-input">

                                            <span class="radio-label">Auto Roll</span>
                                        </div>

                                        <div class="custom-radio secondary">
                                            <input type="radio" value="Deliver" [(ngModel)]="deliverabilityType"
                                                name="Deliverability" class="radio-input">

                                            <span class="radio-label">Deliver</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div id="dvpaymentcontent" *ngIf="deliverabilityType==='Deliver'">
                        <div style="margin-bottom:5px;">
                            <div id="Incomingdiv">
                                <div class="card">
                                    <div class="title">
                                        <h4> BENEFICIARY ACCOUNT </h4>
                                    </div>
                                    <div class="row" style="margin-bottom:7px;">

                                        <div class="col-md-3">
                                            <label> Beneficiary Account </label>
                                        </div>
                                        <div id="Blistdiv" class="col-md-6">
                                            <ng-select [items]="DealDetailData?.beneficiaryAccount"
                                                [closeOnSelect]="true" [(ngModel)]="beneficiaryID" placeholder="Select"
                                                bindLabel="beneficiaryAccountName" bindValue="beneficiaryID">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-group label-inline">
                                            <label>Amount</label>
                                        </div>
                                        <div class="col-md-5" style="margin-bottom:7px;">

                                            <span class="badge secondery">{{DealDetailData?.clientBuyCurrency}}
                                                {{DealDetailData.clientBuyAmount | number:'1.2-2'}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-grou label-inlinep">
                                            <label>Payment Reference</label>

                                        </div>
                                        <div class="col-md-5" style="margin-bottom:7px;">
                                            <input type="text" class="form-control" [(ngModel)]="paymanetReference">

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="outgoingdiv">
                                <div class="card">

                                    <div class="title">
                                        <h4>Funding Account </h4>
                                    </div>

                                    <div class="row" style="margin-bottom:10px;">
                                        <div class="col-md-3" style="padding-top:5px;">
                                            <label>Account Type </label>
                                        </div>

                                        <div class="col-md-6 checkbox-currency">
                                            <div class="custom-radio secondary">
                                                <input type="radio" value="Beneficiary" [(ngModel)]="accountType"
                                                    name="FundingAccountType" class="radio-input">

                                                <span class="radio-label">Beneficiary</span>
                                            </div>
                                            <div class="custom-radio secondary">
                                                <input type="radio" value="InternalTransfer" [(ngModel)]="accountType"
                                                    name="FundingAccountType" class="radio-input">

                                                <span class="radio-label">Internal Transfer</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row" style="margin-bottom:7px;">
                                        <div class="col-md-3">
                                            <label>Funding Account</label>
                                        </div>

                                        <div id="BFlistdiv" class="col-md-6">
                                            <ng-select *ngIf="accountType==='Beneficiary'"
                                                [items]=" DealDetailData?.beneficiaryFundingAccount"
                                                bindLabel="beneficiaryAccountName" bindValue="beneficiaryID"
                                                placeholder="Select"
                                                [(ngModel)]="beneficiaryFundingAccountID"></ng-select>
                                            <ng-select *ngIf="accountType==='InternalTransfer'"
                                                [items]=" DealDetailData?.internalTransferFundingAccount"
                                                bindLabel="internalTransferFundAccountName"
                                                bindValue="internalTransferFundAccID" placeholder="Select"
                                                [(ngModel)]="internalFundingAccountTypeID"></ng-select>

                                        </div>


                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>Amount</label>
                                        </div>
                                        <div class="col-md-5" style="margin-bottom:7px;">

                                            <span class="badge secondery">
                                                {{DealDetailData?.clientSellCurrency}}
                                                {{DealDetailData.clientSellAmount | number:'1.2-2'}}

                                            </span>
                                        </div>
                                    </div>

                                    <hr />
                                    <div class='row'>
                                        <div class="col-md-3">
                                            <label>Navinci Funding Detail</label>

                                        </div>
                                        <div class="col-md-5">
                                            <a (click)='DownloadSSIPDF(DealDetailData?.tradeID)'
                                                title='Download Funding detail PDF'><img
                                                    src="../../../../assets/PDF.png"
                                                    style="height:30px;width:30px;" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div class="inline-element" id="QuoteConfirmbuttondiv" style="margin-top:10px;">
                        <hr />
                        <button id="btnEditNPQuoteReturn" (click)="closeEditModel()" type="button"
                            class="btn btn-secondary" value="Quote">Return</button>
                        <button id="btnEditNPQuoteok" (click)="saveEditChanges()" type="button" class="btn btn-success float-right "
                            value="Quote">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>