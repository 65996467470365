import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralPartnerService } from '../../services/referralPartner/referral-partner.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { HeaderComponent } from '../Home/header/header.component';
import swal from 'sweetalert2';
import { ReferredClientService } from '../../services/referredClient/referred-client.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-referred-client',
  templateUrl: './referred-client.component.html',
  styleUrl: './referred-client.component.css',
})
export class ReferredClientComponent {
  searchString: string = '';
  referralPartnerID: number = 0;

  sortedColumn: string = '';
  isAscending: boolean = true;
  clients: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  isNavBarOpen: boolean = true;
  hideAddButton = false;

  /* Default Table options */
  pageSizeOptions = [5, 10, 20];
  pageSize = 5;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  dataLoaded: boolean = true;
  loadingMessage='Loading...';
  modalTitle: string = 'Add Referred Client';
  referredClientForm: any = [];
  clientList: any = [];
  editReferredMode: boolean = false;

  constructor(
    private router: Router,
    private referralPartnerService: ReferralPartnerService,
    private referredClientService: ReferredClientService,
    private encryptPermission: EncryptRolePermissionService,
    private elementRef: ElementRef,
    private route:ActivatedRoute,
    private alert:SweetalertService
  ) {
    this.referredClient();
  }

  referredClient() {
    this.referredClientForm = new FormGroup({
      ReferralPartnerClientID: new FormControl(0),
      ReferralPartnerID: new FormControl(this.referralPartnerID),
      ReferredClientID: new FormControl(null, [Validators.required]),
      PeriodInMonths: new FormControl(null, [
        Validators.min(0),
        Validators.max(60),
        Validators.pattern(/^[0-9]*$/),
      ]),
    });
  }

  ngOnInit() {
    this.editReferredMode = false;
    this.route.paramMap.subscribe(parameterMap => {
      this.referralPartnerID = +parameterMap.get('ReferralPartnerId')!;
   });  
    this.referredClientForm.value.ReferralPartnerID = this.referralPartnerID;
    this.getReferredCliendData(this.referralPartnerID);
  }

  getReferredCliendData(referralPartnerID: number){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.clients = [];
      this.length = this.clients.length;
      this.referredClientService
        .getReferredCliendData(referralPartnerID)
        .subscribe({
          next: (data: any) => {
            this.clients = data;
            this.length = this.clients.length;
            this.pageIndex = 0;
            this.paginatedData = this.clients.slice(0, this.pageSize);
            this.dataLoaded = true;
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;    
  }

  getClientData(){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referredClientService
        .getClientData()
        .subscribe({
          next: (data: any) => {
            this.clientList = data;
            this.dataLoaded = true;
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise; 
  }

  getSpecificReferredClient(referralPartnerClientId: number){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referredClientService
        .getSpecificReferredClient(referralPartnerClientId)
        .subscribe({
          next: (data: any) => {
            this.referredClientForm.patchValue({
              ReferralPartnerClientID: data.referralPartnerClientID,
              ReferredClientID: data.referredClientID,
              PeriodInMonths: data.periodInMonths,
            });
            this.dataLoaded = true;
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  errorMessage: string = "";
  hasError: boolean = false;
  addReferredClientData(){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.hasError = false;
      this.loadingMessage='Saving...';
      this.referredClientService
        .addReferredClientData(this.referredClientForm.value)
        .subscribe({
          next: (data: any) => {
            this.editReferredMode = false;
            this.closeModal();
            this.alert.fireAlert('Saved');
            resolve();
          },
          error: (error) => {
            this.hasError = true;
            const errorDescription = error.error[0].description;
            this.errorMessage = errorDescription; 
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  updateReferredClientData(){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.loadingMessage='Updating...';
      this.referredClientService
        .updateReferredClientData(this.referredClientForm.value)
        .subscribe({
          next: (data: any) => {
            this.editReferredMode = false;
            this.closeModal();
            this.alert.fireAlert('Updated');
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  deleteReferredClient(referralPartnerClientId: number){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referredClientService
        .deleteReferredClient(referralPartnerClientId)
        .subscribe({
          next: (data: any) => {            
            if(data){
              this.clients = [];
              this.length = 0;
              this.getReferredCliendData(this.referralPartnerID).then(_ => {
                this.dataLoaded = true;
                swal.fire({
                  icon: "success",
                  title: "Data Deleted Successfully.",
                  showConfirmButton: false,
                  timer: 1500
                });
              });              
            }
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  clientDropdown(){
    this.hasError = false;
    this.errorMessage = "";
  }

  sort(columnName: string): void {
    this.clients.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    this.dataLoaded = false;

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clients.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.clients.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  staticData() {
    for (let i = 1; i <= 25; i++) {
      let temp = {
        ClientId: i,
        ClientName: 'Test' + i,
        Period: i,
      };
      this.clients.push(temp);
    }
    this.paginatedData = this.clients.slice(0, this.pageSize);
    this.length = this.clients.length;
  }

  openModal(type: number) {
    if(type == 1){
      this.getClientData().then(_ => {
        const modal = this.elementRef.nativeElement.querySelector('#save-sales-margin');
        modal.classList.add('show');
        modal.style.display = 'block';
        modal.focus();
      }); 
    }
    else{
      this.getClientData().then(_ => {
        const modal = this.elementRef.nativeElement.querySelector('#save-sales-margin');
        modal.classList.add('show');
        modal.style.display = 'block';
        modal.focus();
      }); 
    }
       
  }

  closeModal() {
    const modal = this.elementRef.nativeElement.querySelector('#save-sales-margin');
    modal.classList.remove('show');
    modal.style.display = 'none';

    this.modalTitle = 'Add Referred Client';
    this.editReferredMode = false;
    this.dataLoaded = false;
    this.getReferredCliendData(this.referralPartnerID).then(_ => {
      this.dataLoaded = true;
    });
  }

  addButton() {
    this.editReferredMode = false;
    this.referredClientForm.reset();
    this.modalTitle = 'Add Referred Client';
    this.referredClientForm.reset();
    this.openModal(1);
  }

  editButton(editData: any) {
    this.editReferredMode = true;
    this.modalTitle = 'Edit Referred Client';
    this.getSpecificReferredClient(editData.referralPartnerClientID).then(_ => {
      this.openModal(2);
    });    
  }

  submitted: boolean = false;
  submitReferredPartner() {
    this.submitted = true;
    if (this.referredClientForm.valid) {
      this.referredClientForm.value.ReferralPartnerID = this.referralPartnerID;
      if(this.editReferredMode){
        this.updateReferredClientData();
      }
      else{
        this.addReferredClientData();
      }
    } else {
    }
  }

  deleteData(deleteData: any) {
    swal
      .fire({
        title: 'Are you sure you want to remove the client ' + deleteData.referredClientName + ' ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteReferredClient(deleteData.referralPartnerClientID);          
        }
      });
  }
}
