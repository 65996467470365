import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getAdminUsersList(
    searchString: string,
    roleID: number,
    status: string
  ): Observable<any>
  {
    const payload = {
      searchString: searchString,
      roleID: roleID,
      status: status
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ManageUsers/search';
    return this.http.post<any>(url, payload, httpOptions);
  }

  getRoleStatusFilter(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ManageUsers/getsearchlists';
    return this.http.get<any>(url, httpOptions);
  }
}
