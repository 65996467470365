import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientaccountService {

  constructor(private http:HttpClient) { }

  apiUrl=environment.apiUrl;
  getClientAccountData(){
    return this.http.get(this.apiUrl+'ClientAccount/Admin');
  }

  getAccountDataForClient(clientId:number){
    return this.http.get(this.apiUrl+'Client/account?clientID='+clientId);
  }


  fileUpload(file:any){
    return this.http.post(this.apiUrl+'ClientAccount',file);

  }

}
