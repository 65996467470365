import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManageCurrencies, Countries, country } from '../manage-currencies/models/manage-currencies.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageCurrenciesService } from '../../services/ManageCurrencies/manage-currencies.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs/internal/Observable';
import Swal from 'sweetalert2';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { ModulePermission } from '../global-models/module-permission.model';

@UntilDestroy()
@Component({
  selector: 'app-manage-currency',
  templateUrl: './manage-currency.component.html',
  styleUrl: './manage-currency.component.css'
})
export class ManageCurrencyComponent implements OnInit {
  isLoading: boolean = false;
  countries: country[] = [];
  manageCurrenciesPermission: ModulePermission | undefined;
  canInsert: number = 0;
  canUpdate: number = 0;
  isNew: boolean = true;

  manageCurrencyForm: FormGroup = this.formBuilder.group({
    currencyID: new FormControl<number>(0),
    currencyName: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
    currencyAbbreviation: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
    currencySymbol: new FormControl<string>('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
    countryName: new FormControl<string>(''),
    countryID: new FormControl<any>('', [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private currencyService: ManageCurrenciesService,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router
  ) {
    this.getCountryList();
  }
  
  ngOnInit(): void {
    this.checkModulePermission();
    this.getQueryParams();
  }

  private getQueryParams = (): void => {
    this.route.paramMap.subscribe((parameterMap) => {
      const id = +parameterMap.get('id')!;
      if (id) {
        this.isNew = false;
        this.getCurrencyDetails(id);
      } else { 
        this.isNew = true; 
      }
    });
  }

  private checkModulePermission = (): void => {
    const modulePermission = this.encryptPermission.getDecryptedPermissions('roleNgp').modulePermissions;
    if (modulePermission) {
      this.manageCurrenciesPermission = modulePermission.find((x: { pageID: number; })=> x.pageID == 5);
      this.canInsert = this.manageCurrenciesPermission?.canInsert !== undefined ? this.manageCurrenciesPermission?.canInsert : 0;
      this.canUpdate = this.manageCurrenciesPermission?.canUpdate !== undefined ? this.manageCurrenciesPermission?.canUpdate : 0;
    }
  }

  private getCountryList = (): void => {
    this.isLoading = true;
    this.currencyService.getCountryList().pipe((untilDestroyed(this))).subscribe({
      next: (response: Countries) => {
        this.countries = response.countries;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }

  private getCurrencyDetails = (currencyId: number): void => {
    this.isLoading = true;
    this.currencyService.getManageCurrencyDetails(currencyId).pipe((untilDestroyed(this))).subscribe({
      next: (response: ManageCurrencies) => {
        this.manageCurrencyForm.patchValue(response);
        this.isLoading = false;
      },
      error: () => {this.isLoading = false;}
    });
  }

  backToManageCurrencies = (): void => {
    this.router.navigate(['Admin/ManageCurrencies']);
  }

  private saveCurrency = (): void => {
    if (this.manageCurrencyForm.valid) {
      this.isLoading = false;
      const API: Observable<ManageCurrencies> = this.isNew ? this.currencyService.saveManageCurrency(this.manageCurrencyForm.value) : this.currencyService.updateManageCurrency(this.manageCurrencyForm.value);
      const message: string = !this.isNew ? "Currency is successfully updated." : "New currency is successfully added.";
      API.pipe((untilDestroyed(this))).subscribe({
        next: () => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: message,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
          this.router.navigate(['Admin/ManageCurrencies']);
        },
        error: (response) => {
          this.isLoading = false;
          if (response && response.error) {
            Swal.fire({
              icon: 'warning',
              title: response.error[0].description+".",
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: "Error occurred while processing your request.",
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: "Something goes wrong, please check your inputs.",
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500
      });
      this.markFormGroupTouched(this.manageCurrencyForm);
    } 
  }

  submitForm = (): void => { 
    if (this.isNew) {
      if (this.canInsert > 0) {
        this.saveCurrency();
      }
    } else {
      if (this.canUpdate > 0) {
        this.saveCurrency();
      }
    }
  }
    

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
