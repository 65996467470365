import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralPartnerGeneralService } from '../../services/referralPartnerGeneral/referral-partner-general.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import swal from 'sweetalert2';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-referral-partner-general-tab',
  templateUrl: './referral-partner-general-tab.component.html',
  styleUrl: './referral-partner-general-tab.component.css',
})
export class ReferralPartnerGeneralTabComponent {
  addReferralPartnerForm: any;
  submitted: boolean = false;
  relationshipManagerList: any[] = [];
  dataLoaded: boolean = false;
  loadingMessage='Updating...';
  editMode: boolean = false;
  referralPartnerId: number | undefined;
  todayMinDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  constructor(
    private referralPartnerGeneralService: ReferralPartnerGeneralService,
    private router: Router,
    private route: ActivatedRoute,
    private alert:SweetalertService
  ) {}

  addReferralPartner() {
    this.addReferralPartnerForm = new FormGroup({
      referralPartnerID: new FormControl(0),
      partnerName: new FormControl('', [
        Validators.required,
        Validators.minLength(-1),
        Validators.maxLength(200),
      ]),
      contactName: new FormControl('', [
        Validators.required,
        Validators.minLength(-1),
        Validators.maxLength(200),
      ]),
      contactEmail: new FormControl('', [
        Validators.required,
        Validators.minLength(-1),
        Validators.maxLength(200),
        Validators.pattern(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
        ),
      ]),
      revenueSharingSpot: new FormControl(+'', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      revenueSharingForward: new FormControl(+'', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      revenueSharingOptions: new FormControl(+'', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      contractEndDate: new FormControl(null),
      relationshipManagerID: new FormControl(+'', [Validators.required]),
    });
  }

  ngOnInit() {
    this.addReferralPartner();

    this.submitted = false;
    this.dataLoaded = false;

    this.getAllRelationshipManagerList().then((_) => {
      // this.dataLoaded = true;
      this.route.paramMap.subscribe((parameterMap) => {
        this.referralPartnerId = +parameterMap.get('ReferralPartnerId')!;
        if (this.referralPartnerId) {
          this.editMode = true;
          this.getReferralPartnerData(this.referralPartnerId);
        } else {
          this.editMode = false;
        }
      });
    });
    // this.route.paramMap.subscribe((parameterMap) => {
    //   this.referralPartnerId = +parameterMap.get('ReferralPartnerId')!;
    //   if (this.referralPartnerId) {
    //     this.editMode = true;
    //     this.getReferralPartnerData(this.referralPartnerId);
    //   } else {
    //     this.editMode = false;
    //   }
    // });
  }

  getReferralPartnerData(referralPartnerId: any){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referralPartnerGeneralService
        .getReferralPartnerById(referralPartnerId)
        .subscribe({
          next: (data: any) => {
            this.todayMinDate = formatDate(data.contractEndDate, 'yyyy-MM-dd', 'en-US');
            this.addReferralPartnerForm.patchValue(data);
            this.addReferralPartnerForm.patchValue({ contractEndDate: formatDate(data.contractEndDate, 'yyyy-MM-dd', 'en-US') });
            this.dataLoaded = true;
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  errorArray: any = Array(7).fill('');
  errorChecking() {
    if (
      this.addReferralPartnerForm.controls['partnerName'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['partnerName'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['partnerName'].errors?.required
      ) {
        this.errorArray[0] = 'Referral Partner  is Required.';
      } else if (this.addReferralPartnerForm.value.partnerName.length > 200) {
        this.errorArray[0] =
          'Maximum Length of Referral Partner is 200 characters.';
      } else {
        this.errorArray[0] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['contactName'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['contactName'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['contactName'].errors?.required
      ) {
        this.errorArray[1] = 'Referral Partner Contact Name is Required.';
      } else if (this.addReferralPartnerForm.value.contactName.length > 200) {
        this.errorArray[1] =
          'Maximum Length of Referral Partner Contact Name is 200 characters.';
      } else {
        this.errorArray[1] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['contactEmail'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['contactEmail'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['contactEmail'].errors?.required
      ) {
        this.errorArray[2] = 'Contact Email Address is Required.';
      } else if (
        this.addReferralPartnerForm.controls['contactEmail'].errors?.pattern
      ) {
        this.errorArray[2] =
          'Contact Email Address must be Valid Email Address.';
      } else {
        this.errorArray[2] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['revenueSharingSpot'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['revenueSharingSpot'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['revenueSharingSpot'].errors
          ?.required
      ) {
        this.errorArray[3] = 'Revenue Sharing % Spot is Required.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingSpot'].errors
          ?.min ||
        this.addReferralPartnerForm.controls['revenueSharingSpot'].errors?.max
      ) {
        this.errorArray[3] = 'Revenue Sharing % Spot must between 0 to 100.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingSpot'].errors
          ?.pattern
      ) {
        this.errorArray[3] = 'Revenue Sharing % Spot must be number';
      } else {
        this.errorArray[3] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['revenueSharingForward'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['revenueSharingForward'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['revenueSharingForward'].errors
          ?.required
      ) {
        this.errorArray[4] = 'Revenue Sharing % Forward is Required.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingForward'].errors
          ?.min ||
        this.addReferralPartnerForm.controls['revenueSharingForward'].errors
          ?.max
      ) {
        this.errorArray[4] = 'Revenue Sharing % Forward must between 0 to 100.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingForward'].errors
          ?.pattern
      ) {
        this.errorArray[4] = 'Revenue Sharing % Forward must be number';
      } else {
        this.errorArray[4] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['revenueSharingOptions'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['revenueSharingOptions'].dirty
    ) {
      if (
        this.addReferralPartnerForm.controls['revenueSharingOptions'].errors
          ?.required
      ) {
        this.errorArray[5] = 'Revenue Sharing % Options is Required.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingOptions'].errors
          ?.min ||
        this.addReferralPartnerForm.controls['revenueSharingOptions'].errors
          ?.max
      ) {
        this.errorArray[5] = 'Revenue Sharing % Options must between 0 to 100.';
      } else if (
        this.addReferralPartnerForm.controls['revenueSharingOptions'].errors
          ?.pattern
      ) {
        this.errorArray[5] = 'Revenue Sharing % Options must be number';
      } else {
        this.errorArray[5] = '';
      }
    }
    if (
      this.addReferralPartnerForm.controls['relationshipManagerID'].touched ||
      this.submitted ||
      this.addReferralPartnerForm.controls['relationshipManagerID'].dirty
    ) {
      if (
        this.addReferralPartnerForm.value.relationshipManagerID == 0 ||
        this.addReferralPartnerForm.value.relationshipManagerID == null ||
        this.addReferralPartnerForm.value.relationshipManagerID == undefined
      ) {
        this.errorArray[6] = 'Select Valid Relationship Manager';
        this.addReferralPartnerForm.controls['relationshipManagerID'].setErrors(
          { incorrect: true }
        );
      } else {
        this.errorArray[6] = '';
        this.addReferralPartnerForm.controls['relationshipManagerID'].setErrors(
          { incorrect: null }
        );
        this.addReferralPartnerForm.controls[
          'relationshipManagerID'
        ].updateValueAndValidity();
      }
    }
  }

  getAllRelationshipManagerList() {
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referralPartnerGeneralService
        .getAllRelationshipManagerList()
        .subscribe({
          next: (data: any) => {
            data.relationshipManagers.unshift({
              managerID: 0,
              managerName: 'Select',
            });
            this.relationshipManagerList = data.relationshipManagers;
            this.dataLoaded = true;
            resolve();
          },
          error: (error) => {
            this.dataLoaded = true;
          },
        });
    });
    return promise;
  }

  onSave() {
    this.submitted = true;
    this.errorChecking();
    if (this.addReferralPartnerForm.valid) {
      this.dataLoaded = false;

      this.addReferralPartnerForm.value.revenueSharingSpot = parseFloat(
        this.addReferralPartnerForm.value.revenueSharingSpot
      );
      this.addReferralPartnerForm.value.revenueSharingForward = parseFloat(
        this.addReferralPartnerForm.value.revenueSharingForward
      );
      this.addReferralPartnerForm.value.revenueSharingOptions = parseFloat(
        this.addReferralPartnerForm.value.revenueSharingOptions
      );

      if(!this.editMode){
        this.dataLoaded=false;
        this.loadingMessage='Saving...';
        this.referralPartnerGeneralService
        .saveReferralPartner(this.addReferralPartnerForm.value)
        .subscribe({
          next: (data: any) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then(()=>{
              this.router.navigate(['/Admin/ReferralPartner']);

            })
           
          },
          error: (error) => {
            setTimeout(()=>{
              this.dataLoaded = true;
              this.warning();
            }, 1000);
          },
        });
      }
      else{
        this,this.dataLoaded=false;
        this.loadingMessage='Updating...';
        this.referralPartnerGeneralService
        .updateReferralPartner(this.addReferralPartnerForm.value)
        .subscribe({
          next: (data: any) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Updated').then(()=>{
              this.router.navigate(['/Admin/ReferralPartner']);
              
            })
            
          },
          error: (error) => {
            setTimeout(()=>{
              this.dataLoaded = true;
              this.warning();
            }, 1000);            
          },
        });
      }      
    }
  }

  cancel() {
    this.submitted = false;
    if(this.editMode){
      this.getReferralPartnerData(this.referralPartnerId);
    }
    else{
      this.addReferralPartnerForm.reset();
    }
    this.errorArray = ['', '', '', '', '', '', ''];
  }

  success(message: string){
    swal.fire({
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }

  error(message: string){
    swal.fire({
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }

  warning(){
    swal.fire({
      icon: "warning",
      title: "Network Error",
      showConfirmButton: false,
      timer: 1500
    });
  }
}
