import { Component, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewBeneficiaryService } from '../../services/viewBeneficiary/view-beneficiary.service';
import { PageEvent } from '@angular/material/paginator';
import { AddBeneficiaryService } from '../../services/addBeneficiary/add-beneficiary.service';

@Component({
  selector: 'app-view-beneficiary',
  templateUrl: './view-beneficiary.component.html',
  styleUrl: './view-beneficiary.component.css',
})
export class ViewBeneficiaryComponent {
  dataLoaded: boolean = true;
  user: string = 'Beneficiary';
  currencyPairs: any[] = [];
  routingTypes: any[] = [];
  tenentCarPartners: any[] = [];
  clients: any[] = [];
  selectedstatus: string = 'both';
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  sortedColumn: string = '';
  isAscending: boolean = true;
  /* Pagination parameters */
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  beneficiaryData: any[] = [];
  isAdmin: boolean = false;
  beneficiaryID: number = -1;
  rolePermission: any;
  canInsert: boolean = false;
  canUpdate: boolean = false;

  constructor(
    private fb: FormBuilder,
    private beneficiaryServies: ViewBeneficiaryService,
    private addbeneficiaryservice: AddBeneficiaryService,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private encryptPermission: EncryptRolePermissionService,
    private renderer: Renderer2
  ) {}
  beneficaryForm: FormGroup = this.fb.group({
    tenantOrCARPartner: [null],
    clientID: [0],
    beneficiaryName: [null],
    beneficiaryCountry: [null],
    currencyID: [null],
    fundtypeId: [null],
  });
  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
      this.getBeneficiaryList();
      const modulePermission =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).modulePermissions;
      if (modulePermission) {
        this.rolePermission = modulePermission.find(
          (x: { pageID: number }) => x.pageID == 23
        );
        if (this.rolePermission) {
          this.canInsert = this.rolePermission.canInsert == 1 ? true : false;
          this.canUpdate = this.rolePermission.canUpdate == 1 ? true : false;
        } else {
          this.canInsert = false;
          this.canUpdate = false;
        }
      }
    } else if (user === 'Client') {
      this.isAdmin = false;
      const rolePermissions =
        this.encryptPermission.getDecryptedPermissions(
          'crole'
        ).modulePermissions;
      if (rolePermissions) {
        this.rolePermission = rolePermissions.find(
          (x: { clientPageID: number }) => x.clientPageID == 56
        );
        if (this.rolePermission) {
          this.canInsert = this.rolePermission.canInsert == 1 ? true : false;
          this.canUpdate = this.rolePermission.canUpdate == 1 ? true : false;
        } else {
          this.canInsert = false;
          this.canUpdate = false;
        }
      }
      this.getBeneficiaryList();
      this.search();
    }
    this.getBeneficiaryList();
  }
  getBeneficiaryList() {
    if (this.isAdmin) {
      this.beneficiaryServies.getBeneficiarylist().subscribe({
        next: (data: any) => {
          this.currencyPairs = data.beneficiaryCurrency;
          this.routingTypes = data.routingType;
          this.tenentCarPartners = data.tenantCARs;

          const userResponse =
            this.encryptPermission.getDecryptedPermissions('userResponse');
          if (userResponse) {
            let tenantCarname = this.tenentCarPartners.find(
              (t) =>
                t.tenantCARPartnerName === userResponse.tenantCARPartnerName
            );
            if (tenantCarname) {
              this.beneficaryForm
                .get('tenantOrCARPartner')
                ?.setValue(tenantCarname.tenantCARPartnerName);
            }
          }
          this.getClientList();
        },
        error: () => {},
      });
    } else {
      this.beneficiaryServies.getClientBeneficiarylist().subscribe({
        next: (data: any) => {
          this.currencyPairs = data.beneficiaryCurrency;
          this.routingTypes = data.routingType;
        },
        error: () => {},
      });
    }
  }

  getClientList() {
    this.dataLoaded = false;
    const selectedTenant = this.beneficaryForm.get('tenantOrCARPartner')?.value;
    this.beneficiaryServies.getClientList(selectedTenant).subscribe({
      next: (data: any) => {
        this.clients = data;
        this.dataLoaded = true;
      },
      error: () => {},
    });
  }

  search() {
    this.dataLoaded = false;
    let formValue = this.beneficaryForm.value;
    if (this.isAdmin) {
      let payload = {
        tenantOrCARPartner: formValue.tenantOrCARPartner,
        clientID: formValue.clientID,
        beneficiaryName: formValue.beneficiaryName,
        beneficiaryCountry: formValue.beneficiaryCountry,
        currencyID: formValue.currencyID,
        fundtypeId: formValue.fundtypeId,
        status: this.selectedstatus,
      };
      this.beneficiaryServies.search(payload).subscribe({
        next: (data: any) => {
          this.beneficiaryData = data;
          this.pageIndex = 0;
          let startIndex = 0;
          let endIndex = this.pageSize;
          if (this.pageIndex != 0) {
            startIndex = this.pageIndex * this.pageSize;
            endIndex = startIndex + this.pageSize;
          }

          this.length = this.beneficiaryData.length;

          this.paginatedData = this.beneficiaryData.slice(startIndex, endIndex);
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    } else {
      let payload = {
        beneficiaryName: formValue.beneficiaryName,
        beneficiaryCountry: formValue.beneficiaryCountry,
        currencyID: formValue.currencyID,
        fundtypeId: formValue.fundtypeId,
        status: this.selectedstatus,
      };
      this.beneficiaryServies.searchClient(payload).subscribe({
        next: (data: any) => {
          this.beneficiaryData = data;
          this.pageIndex = 0;
          this.length = this.beneficiaryData.length;
          this.paginatedData = this.beneficiaryData.slice(0, this.pageSize);
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    }
  }

  sort(columnName: string): void {
    this.beneficiaryData.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    this.dataLoaded = false;
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.beneficiaryData.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.beneficiaryData.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.beneficiaryData.slice(startIndex, endIndex);
  }
  resetButton() {
    this.selectedstatus = 'Both';
    this.beneficaryForm.reset();
  }
  addBeneficiary() {
    if (this.isAdmin) this.router.navigate(['/Admin/addBeneficiary']);
    else this.router.navigate(['/Client/addBeneficiary']);
  }
  openModal(id: number) {
    this.beneficiaryID = id;
    this.addbeneficiaryservice.triggerInit(id);
    const modal = this.elementRef.nativeElement.querySelector(
      '#EditBeneficiary-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.appendChild(backdrop);
      modal.focus();
    }
  }

  close() {
    this.search();
    const modal = this.elementRef.nativeElement.querySelector(
      '#EditBeneficiary-modal'
    );
    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') {
      this.openCloseDropdwon('0');
    } else {
      this.openCloseDropdwon('1');
    }
  };

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
    if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
      this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
    }
  };
}
