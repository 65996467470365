import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionHedgeService } from '../../services/OptionHedge/option-hedge.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-lhscollar',
  templateUrl: './lhscollar.component.html',
  styleUrl: './lhscollar.component.css',
})
export class LHSCollarComponent {
  isAdmin!: boolean;
  clientId!: number;
  constructor(
    private optionHedgeService: OptionHedgeService,
    private encryptPermission: EncryptRolePermissionService,
    private route:ActivatedRoute,
    private router:Router
  ) {}

  clientName: any = null;
  productID = 6;
  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
      this.clientName =
        this.encryptPermission.getDecryptedPermissions('selectedName');
      this.route.paramMap.subscribe((parameterMap) => {
        this.clientId = +parameterMap.get('clientId')!;
      });
    } else if (user === 'Client') {
      this.isAdmin = false;
      const clientInfo =
        this.encryptPermission.getDecryptedPermissions('usercrd');
      this.clientId = clientInfo?.clientID;
    }
  }
}
