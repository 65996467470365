<div class="loader-block" *ngIf="!dataLoaded">
  <div class="inner-loader">
    Loading...
  </div>
</div>
<div>
  <h1 class="textstyle">Audit log</h1>
  <p>Monitor any changes made to your project,schema and content with audit log.</p>
</div>
<div class="inner secondary">
  <div class="card ">
    <form [formGroup]="auditLogForm" id="dealHistoryForm" (ngSubmit)="search()" class="page">
      <div class="row">
        <div class="col-md-6 col-sm-4">
          <div class="form-group">
            <label>Module</label>
            <ng-select [items]="modules" bindLabel="name" bindValue="name" placeholder="Select" [searchable]="true"
              [closeOnSelect]="true" formControlName="moduleName" (change)="getFields()">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 col-sm-4">
          <div class="form-group">
            <label>Field Name</label>
            <ng-select [items]="fieldNames" bindLabel="name" bindValue="name" placeholder="Select" [searchable]="true"
              [closeOnSelect]="true" formControlName="fieldName">
            </ng-select>
          </div>
        </div>


        <div class="col-md-4 col-sm-4">
          <div class="form-group" id="ManageTrade">
            <label>Date</label>
            <ng-select [items]="dates" bindLabel="name" bindValue="id" placeholder="Select" [searchable]="true"
              [closeOnSelect]="true" formControlName="date" (change)="checkDateCustom()">
            </ng-select>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="form-group">
            <label>From Date</label>
            <input [readOnly]="customDateFlag" type="date" class="form-control" [max]="today" formControlName="fromDate"
              id="txtfromDate" placeholder="DD/MM/YYYY" autocomplete="off" />
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="form-group">
            <label>To Date</label>
            <input [readOnly]="customDateFlag" type="date" class="form-control" [max]="today" id="txttoDate"
              formControlName="toDate" placeholder="DD/MM/YYYY" autocomplete="off" />
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="inline-element">
            <a class="btn btn-success" id="btnTradeSearch" type="submit" (click)="checkDate()">Search</a>
            <a class="btn btn-secondary" id="btnTradeReset" (click)="ResetForm()">Reset</a>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="auditLog.length > 0; else notFound" class="table-responsive">
      <table class="table custom-grid">
        <thead>
          <tr class="Row123">
            <th style="width: 5%">
              <a (click)="sortTable('updatedBy')" class=" textstyle sort active ts-asc" unselectable="on"
                data-i="5">User<span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 5%">
              <a (click)="sortTable('auditType')" class="textstyle sort active ts-asc" unselectable="on"
                data-i="8">Action
                <span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 5%">
              <a (click)="sortTable('moduleName')" class="textstyle sort active ts-asc" unselectable="on"
                data-i="1">Module<span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 10%">
              <a (click)="sortTable('columnName')" class="textstyle sort active ts-asc" unselectable="on"
                data-i="2">Field Name
                <span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 5%">
              <a (click)="sortTable('beforeValue')" class="textstyle sort active ts-asc" unselectable="on"
                data-i="3">Old Value
                <span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 8%">
              <a (click)="sortTable('afterValue')" class="textstyle sort active ts-asc" unselectable="on" data-i="4">New
                Value<span class="ts-sord"></span>
              </a>
            </th>
            <th style="width: 7%">
              <a (click)="sortTable('timeOfEvent')" class="textstyle sort active ts-asc" unselectable="on"
                data-i="0">Date<span class="ts-sord"></span>
              </a>
            </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of paginatedData">
            <td class="wrapword">{{ row.updatedBy }}</td>
            <td *ngIf="row.auditType === null"></td>
            <td class="badge" [ngClass]="actionClass(row.auditType)">{{ row.auditType }}</td>
            <td class="wrapword">{{ row.moduleName }}</td>
            <td class="wrapword">{{ row.columnName }}</td>
            <td class="wrapword">{{ row.beforeValue }}</td>
            <td class="wrapword">{{ row.afterValue }}</td>
            <td class="wrapword">{{ getLocalTimeDate(row.timeOfEvent) | date:'yyyy-MM-dd HH:mm:ss' }}</td>
          </tr>
        </tbody>
      </table>
      <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
    <ng-template #notFound>
      <div class="alert alert-info">
        <span>No record found</span>
      </div>
    </ng-template>
  </div>