<div class="navigation containernav" [ngClass]="{ collapseNav: isNavOpen }" id="navbar">
  <nav class="main-navigation">
    <img src="../../../../assets/logo-dashboard.png" class="logo"
      [ngClass]="{ hidden: isNavOpen }" />
    <ul class="nav-menu maxheight" style="height: 100%" id="myscrl">
      <li [ngClass]="{ hidden: viewFlags[25], reducehight: isNavOpen }">
        <a routerLink="/quickquote" title="Quick Quote" id="25">
          <em><img src="../../../../assets/data-management.svg" alt="" /></em>
          <span>Quick Quote</span>
        </a>
      </li>
      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[0],
          active: isMenuOpen[0],
          reducehight: isNavOpen
        }">
        <a title="Client Management" (click)="toggleMenu(0)">
          <em><img src="../../../../assets/client.svg" alt="" /></em>
          <span>Client Management</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[0] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[0] }">
          <li [ngClass]="{ hidden: viewFlags[3] }">
            <a routerLink="/manageclient" title="Manage Clients" id="3">Manage Clients</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[4] }">
            <a routerLink="/managecontact" title="Manage Contacts" id="4">Manage Contacts</a>
          </li>
        </ul>
      </li>
      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[1],
          active: isMenuOpen[1],
          reducehight: isNavOpen
        }">
        <a title="Manage Masters" (click)="toggleMenu(1)">
          <em><img src="../../../../assets/data-management.svg" alt="" /></em>
          <span>Manage Masters</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[1] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[1] }">
          <li [ngClass]="{ hidden: viewFlags[5] }">
            <a title="Manage Currency" id="5">Manage Currencies</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[6] }">
            <a title="Manage Currency-Pair" id="6">Manage Currency-Pairs</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[7] }">
            <a title="Manage Product" id="7">Manage Products</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[8] }">
            <a title="Manage Holiday" id="8">Manage Holidays</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[10] }">
            <a title="Manage Statement Tiers" id="10">Manage Statement Tiers</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[14] }">
            <a title="Manage Client Tiers" id="14">Manage Client Tiers</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[11] }">
            <a title="Adjustment Configuration" id="11">Adjustment Configuration</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[12] }">
            <a routerLink="/clientaccount" title="Client Account" id="12">Client Account</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[13] }">
            <a title="Product Email Template" id="13">Product Email Template</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[15] }">
            <a title="Email Templates" id="15">Email Templates</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[18] }">
            <a title="Call/Strike Range" id="18">Call/Strike Range</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[19] }">
            <a title="Application Setting" id="19">Application Setting</a>
          </li>
          <li routerLink="/ManageSalesMargin" [ngClass]="{ hidden: viewFlags[22] }">
            <a title="Manage Sales Margin" id="22">Manage Sales Margin</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[26] }">
            <a routerLink="/managetenants" title="Manage Tenants" id="26">Manage Tenants</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[36] }">
            <a title="Manage CAR Partners" id="36" routerLink="/manageCarpartner">Manage CAR Partners</a>
          </li>
        </ul>
      </li>

      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[2],
          active: isMenuOpen[2],
          reducehight: isNavOpen
        }">
        <a title="Settings" (click)="toggleMenu(2)">
          <em><img src="../../../../assets/settings.svg" alt="" /></em>
          <span>Settings</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[2] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[2] }">
          <li [ngClass]="{ hidden: viewFlags[1] }">
            <a routerLink="/ManageUsers" title="Manage Users" id="1">Manage Users</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[2] }">
            <a routerLink="/manageroles" title="Manage Roles" id="2">Manage Roles</a>
          </li>
        </ul>
      </li>

      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[3],
          active: isMenuOpen[3],
          reducehight: isNavOpen
        }">
        <a title="Finance" (click)="toggleMenu(3)">
          <em><img src="../../../../assets/payments.svg" alt="" /></em>
          <span>Finance</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[3] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[3] }">
          <li [ngClass]="{ hidden: viewFlags[30] }">
            <a title="Target Revenue" id="30">Target Revenue</a>
          </li>

          <li [ngClass]="{ hidden: viewFlags[31] }">
            <a routerLink="/ReferralPartner" title="Referral Partners" id="31">Referral Partners</a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[33] }">
            <a title="Deal Settlement" id="33">Deal Settlement</a>
          </li>
        </ul>
      </li>

      <li [ngClass]="{ hidden: viewFlags[20], reducehight: isNavOpen }">
        <a title="Spot Deal" id="20">
          <em><img src="../../../../assets/line-chart.svg" alt="" /></em>
          <span>Spot Deal</span>
        </a>
      </li>

      <li [ngClass]="{ hidden: viewFlags[21], reducehight: isNavOpen }">
        <a title="Forward Hedge" id="21">
          <em><img src="../../../../assets/line-chart.svg" alt="" /></em>
          <span>Forward Hedge</span>
        </a>
      </li>

      <li [ngClass]="{ hidden: viewFlags[37], reducehight: isNavOpen }">
        <a title="Predelivery" id="37">
          <em><img src="../../../../assets/line-chart.svg" alt="" /></em>
          <span>Predelivery</span>
        </a>
      </li>

      <li [ngClass]="{ hidden: viewFlags[38], reducehight: isNavOpen }">
        <a title="Extension" id="38">
          <em><img src="../../../../assets/line-chart.svg" alt="" /></em>
          <span>Extension</span>
        </a>
      </li>

      <li class="" [ngClass]="{ hidden: viewFlags[16], reducehight: isNavOpen }">
        <a title="Option Hedge" id="16">
          <em><img src="../../../../assets/filter.svg" alt="" /></em>
          <span>Option Hedge</span>
        </a>
      </li>

      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[4],
          active: isMenuOpen[4],
          reducehight: isNavOpen
        }">
        <a title="Open Deals" (click)="toggleMenu(4)">
          <em><img src="../../../../assets/payments.svg" alt="" /></em>
          <span>Open Deals</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[4] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[4] }">
          <li [ngClass]="{ hidden: viewFlags[20] }">
            <a title="Open Spot/Forward Deals" id="42">
              <span>Spot / Forward</span>
            </a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[9] }">
            <a title="Open Option Deals" id="9">
              <span>Option</span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{ hidden: viewFlags[28], reducehight: isNavOpen }">
        <a routerLink="/dealhistory" title="Deal History" id="28">
          <em><img src="../../../../assets/payments.svg" alt="" /></em>
          <span>Deal History</span>
        </a>
      </li>
      <li [ngClass]="{ hidden: viewFlags[39], reducehight: isNavOpen }">
        <a title="Swaps History" id="@EnumAdminPages.SwapsHistory.GetHashCode()">
          <em><img src="../../../../assets/payments.svg" alt="" /></em>
          <span>Swaps History</span>
        </a>
      </li>
      <li [ngClass]="{ hidden: viewFlags[35], reducehight: isNavOpen }">
        <a title="Order Book" id="35">
          <em><img class="png" src="../../../../assets/book_order.png" /></em>
          <span>Order Book</span>
        </a>
      </li>

      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[5],
          active: isMenuOpen[5],
          reducehight: isNavOpen
        }">
        <a (click)="toggleMenu(5)">
          <em><img src="../../../../assets/client.svg" alt="" /></em>
          <span>{{ isPayee ? "Payees" : "Beneficiaries" }}</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[5] }" [ngClass]="{ hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[5] }">
          <li [ngClass]="{ hidden: viewFlags[23] }">
            <a [title]="
                isPayee ? 'View Existing Payee' : 'View Existing Beneficiaries'
              " id="23">
              <span>{{
                isPayee ? "View Existing Payee" : "View Existing Beneficiaries"
                }}</span>
            </a>
          </li>

          <li [ngClass]="{ hidden: viewFlags[24] }">
            <a [title]="isPayee ? 'New Payee' : 'New Beneficiary'" id="24">
              <span>{{ isPayee ? "New Payee" : "New Beneficiary" }}</span>
            </a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[27] }">
            <a [title]="isPayee ? 'Import Payee' : 'Import Beneficiary'" id="27">
              <span>{{ isPayee ? "Import Payee" : "Import Beneficiary" }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="" [ngClass]="{ hidden: viewFlags[17], reducehight: isNavOpen }">
        <a title="Reports" id="17">
          <em><img src="../../../../assets/data-management.svg" alt="" /></em>
          <span>Reports</span>
        </a>
      </li>
      <li class="has-menu" [ngClass]="{
          hidden: hasMenuflag[6],
          active: isMenuOpen[6],
          reducehight: isNavOpen
        }">
        <a title="Support" (click)="toggleMenu(6)">
          <em><img src="../../../../assets/client.svg" alt="" /></em>
          <span>Support</span>
          <em class="arrow-icon" [ngClass]="{ rotate: isMenuOpen[6], hidden: isNavOpen }"></em>
        </a>
        <ul [ngClass]="{ expanded: isMenuOpen[6] }">
          <li [ngClass]="{ hidden: viewFlags[32] }">
            <a title="Sales Revenue" id="">
              <span>Sales Revenue</span>
            </a>
          </li>
          <li [ngClass]="{ hidden: viewFlags[34] }">
            <a title="Utilities" id="">
              <span>Utilities</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>