import { Component, ElementRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { BankingService } from '../../services/bankingTab/banking.service';
import { BankAccount, CountryList } from './models/banking.model';
import { AddEditBankingTabComponent } from './add-edit-banking-tab/add-edit-banking-tab.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminTab } from '../../enum/global_enum';

@Component({
  selector: 'app-banking-tab',
  templateUrl: './banking-tab.component.html',
  styleUrl: './banking-tab.component.css',
})
export class BankingTabComponent {
  clientMode: boolean = false;
  countryList: CountryList[] = [];
  clientId: number = 0;
  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  showFirstLastButtons: boolean = true;
  paginatedData: BankAccount[] = [];
  pageEvent: PageEvent | undefined;
  pageSizeOptions: number[] = [5, 10, 20];
  pageSize: number = 20;
  pageIndex: number = 0;
  length: number = 0;
  selectedBankId: number = 0;
  editmode: boolean = false;
  bankForm!: FormGroup;
  dataLoaded: boolean = true;
  tabPermission: any;
  canAddEdit: boolean = false;
  bankList: BankAccount[] = [];
  submitted: boolean = false;
  loadingMessage = 'Loading';
  bankId: number = 0;

  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private encryptPermission: EncryptRolePermissionService,
    private bankingService: BankingService,
    private sweetAlertService: SweetalertService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const user: string = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      const tabPermissions = this.encryptPermission.getDecryptedPermissions('roleNgp').tabPermissions;
      this.tabPermission = tabPermissions.find(
        (x: { tabID: number }) => x.tabID == AdminTab.Banking
      );
      this.canAddEdit = this.tabPermission.canInsert ? true : false;
    } 
    else if (user === 'Client') {
      this.clientMode = true;
      this.canAddEdit = false;
    }

    this.route.paramMap.subscribe((parameterMap) => {
      this.clientId = +parameterMap.get('ClientID')!;
    });
    this.getBankList();
    this.bankForm.reset();
    this.bankForm.value.clientID = this.clientId;
  }

  private getBankList = () => {
    this.loadingMessage='Loading';
    this.dataLoaded = false;
    this.bankingService.getBankList(this.clientId).subscribe({
      next: (data: BankAccount[]) => {
        this.bankList = data;
        this.length = this.bankList.length;
        this.paginatedData = this.bankList.slice(0, this.pageSize);
        this.dataLoaded = true;
      },
      error: (error) => {
        this.dataLoaded = true;
        this.sweetAlertService.showError(error,error.errorMessage);
      },
    });
  }

  addBank() {
    this.editmode = false;
    this.bankId = 0;
    this.openModal(false);
  }

  openModal(rowClicked: boolean): void {
    const bankingModel: MatDialogRef<AddEditBankingTabComponent, any> = this.dialog.open(AddEditBankingTabComponent, {
      width: '40vw',
      disableClose: true,
      data: {
        clientId: this.clientId,
        bankId: this.bankId,
        canAddEdit: this.canAddEdit,
        rowClicked: rowClicked
      }
    });
    bankingModel.afterClosed().subscribe((response: any) => {
      this.getBankList();
      this.dataLoaded = true;
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.bankList.slice(startIndex, endIndex);
  }

  editBank = (clientBankID: number): void => {
    this.bankId = clientBankID;
    this.openModal(false);
  }

  viewBank = (clientBankID: number): void => {
    this.bankId = clientBankID;
    this.openModal(true);
  }

  toggleSwitch = (bankRow: BankAccount, event: any): void => {
    if (this.canAddEdit) {
      this.dataLoaded = false;
      this.bankingService.setActiveFlag(bankRow.clientBankID, event.target.checked).subscribe({
        next: (response: boolean) => {
          this.dataLoaded = true;
          if (response) {
            this.sweetAlertService.messageAlert("Change for Is active successfully applied");
          }
        },
        error: () => {
          this.dataLoaded = true;
        }
      });
    }
  }

}
