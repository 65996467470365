import { Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AttachmentsTabService } from '../../services/CRMAttachmentsTab/attachments-tab.service';
import { AcceptEmail, EmailClientAttachments, GetAttachmentsResponse, UploadAttachmentResponse } from './models/attachments.tab.models';
import Swal from 'sweetalert2';
import { AttachmentTab, ErrorMessages, SpinnerMessage, SuccessMessages, SystemConstant } from '../../global/global.constant';
import { PageEvent } from '@angular/material/paginator';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AcceptEmailIdsModalComponent } from '../../common/components/accept-email-ids-modal/accept-email-ids-modal.component';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { AdminTab } from '../../enum/global_enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-attachments-tab',
  templateUrl: './attachments-tab.component.html',
  styleUrl: './attachments-tab.component.css',
})

export class AttachmentsTabComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;

  clientId: number = 0;
  paginatedData: GetAttachmentsResponse[] = [];
  clientFileIds: number[] = [];
  attachments: GetAttachmentsResponse[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  pageSize: number = 10;
  pageIndex: number = 0;
  pageEvent: PageEvent | undefined;
  showPageSizeOptions: boolean = true;
  showFirstLastButtons: boolean = true;
  dataLoaded: boolean = true;
  loadingMessage: string = SpinnerMessage.Loading;
  errormsg: string = '';
  allChecked: boolean = false;
  tabPermission: any;
  canAddEdit: boolean = false;
  clientMode: boolean = false;
  canView: boolean = false;
  isAscending: boolean = true;
  sortedColumn: string = '';

  constructor(
    private route: ActivatedRoute,
    private attachmentsTabService: AttachmentsTabService,
    private sweetAlert: SweetalertService,
    private dialog: MatDialog,
    private encryptPermission: EncryptRolePermissionService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientId = +parameterMap.get('ClientID')!;
    });
    const user=this.route.snapshot.pathFromRoot[1].url[0].path;
    if(user==='Admin'){
      const tabPermissions = this.encryptPermission.getDecryptedPermissions('roleNgp').tabPermissions;
      this.tabPermission = tabPermissions.find((x: { tabID: number; })=> x.tabID==AdminTab.Attachment);
      this.canAddEdit = this.tabPermission.canInsert ? true : false;
      this.canView = this.tabPermission.canView ? true : false;
      if(this.canView)
      this.getAttachmentList(this.clientId);
    }
  }

  private getAttachmentList = (clientId: number): void => {
    this.dataLoaded = false;
    this.attachmentsTabService
      .getAttachments(clientId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: GetAttachmentsResponse[]) => {
          this.attachments = response;
          this.paginatedData = response.slice(0, this.pageSize);
          this.dataLoaded = true;
        },
        error: (error) => {
          this.dataLoaded = true;
          this.sweetAlert.showError(error, ErrorMessages.NotDetached);
        },
      });
  };

  fileChecked = (event: Event, attachments: GetAttachmentsResponse) => {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.clientFileIds.push(attachments.clientFileID);
    }
    else {
      const index: number = this.clientFileIds.indexOf(attachments.clientFileID);
      if (index > -1) {
        this.clientFileIds.splice(index, 1);
      }
    }
  };

  detachFiles = (ids: number[]) => {
    if(this.clientFileIds.length > 0)
    {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        html: SystemConstant.Delete_warning,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showDenyButton: true,
        denyButtonText: 'Cancel',
        denyButtonColor: 'grey',      
      
      }).then((result) => {
        if (result.isConfirmed) {
        
      this.loadingMessage = SpinnerMessage.Delete;
        this.dataLoaded = false;
        this.attachmentsTabService
          .detachAttachment(ids)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (response: UploadAttachmentResponse) => {
              if (!response.isError) {
                this.sweetAlert.messageAlert(SystemConstant.SuccessFullyDetach);
                this.getAttachmentList(this.clientId);
                this.dataLoaded = true;
              }
            },
            error: (error) => {
              this.dataLoaded = true;
              this.sweetAlert.showError(error, ErrorMessages.NotDetached);
            },
          });
        
        } else if (result.isDenied) {
         
        }
      });




    }
    else{
      this.sweetAlert.showError('Error',ErrorMessages.SelectAtleastOneFile);
    }
  };

  uncheckAll() {
    if(this.checkboxes){
      this.clientFileIds = [];
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
    }
  }

  downloadClientAttachments = (ids:number[]) => {
    if(this.clientFileIds.length > 0){
      this.loadingMessage = SpinnerMessage.Download;
      this.dataLoaded = false;
     
      this.attachmentsTabService.downloadClientAttachment(ids).subscribe({
        next: (response) => {
          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = 'ClientDocuments';
          link.click();
          this.dataLoaded = true;
          this.uncheckAll();
        },
        error: (error) => {
          this.dataLoaded = true;
          this.sweetAlert.showError(error, ErrorMessages.DownloadFailed);
        },
      });
    }
    else{
      this.sweetAlert.showError('Error',ErrorMessages.SelectAtleastOneFile);
      
    }
  }

  handlePageEvent = (pageEvent: PageEvent): void => {
    this.pageEvent = pageEvent;
    this.pageSize = pageEvent.pageSize;
    localStorage.setItem('attachmentTabPageSize', pageEvent.pageSize.toString());
    this.pageIndex = pageEvent.pageIndex;
    let startIndex: number = 0;
    let endIndex: number = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.attachments.slice(startIndex, endIndex);
  };

  fileUpload(isFileUpload: boolean){
    this.dataLoaded = false;
    if(isFileUpload){
      this.pageSize = Number(localStorage.getItem('attachmentTabPageSize')) == 0 ? 10 : Number(localStorage.getItem('attachmentTabPageSize'));
      this.getAttachmentList(this.clientId);
    }
  }

  openModal() {
    if(this.clientFileIds.length > 0){
      const addressModal: MatDialogRef<AcceptEmailIdsModalComponent, any> = this.dialog.open(AcceptEmailIdsModalComponent, {
        width: '40vw',
        disableClose: true
      });
      addressModal.afterClosed().subscribe((response: AcceptEmail) => {
        if (response && response.emailIds) {
         this.emailSent(response.emailIds);
        }
      });
    }
    else{
      this.sweetAlert.showError('Error',ErrorMessages.SelectAtleastOneFile);    }
  }

  private validateEmails = (emailString: string): boolean => {
    const emailRegex: RegExp =
      /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}(?:, ?[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;
    return emailRegex.test(emailString);
  }

  emailSent = (emailIds: string): void => {
    this.loadingMessage = SpinnerMessage.SendMail;
    this.dataLoaded = false;
    if (this.validateEmails(emailIds)) {
      let payload: EmailClientAttachments = {
        clientFileIds: this.clientFileIds,
        emailIDs: emailIds,
      };
      this.attachmentsTabService.sendEmail(payload).subscribe({
        next: () => {
          this.dataLoaded = true;
          this.uncheckAll();
          this.sweetAlert.messageAlert(SuccessMessages.EmailSendSuccessfully);
        },
        error: (error) => {
          this.dataLoaded = true;
          if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
          } else {
            this.errormsg = 'Email sent failed';
          }
          this.sweetAlert.showError(error, this.errormsg);
        },
      });
    }
    else {
      this.dataLoaded = true;
     this.sweetAlert.messageAlert(ErrorMessages.EnterCorrectEmail);
    }
  }

  toggleAllCheckbox(event: Event){
    const checkBox = event.target as HTMLInputElement
    if(checkBox.checked){
      this.allChecked = true;
      for(let i = 0; i < this.paginatedData.length; i++){
        this.clientFileIds.push(this.paginatedData[i].clientFileID);
      }
    }
    else{
      this.allChecked = false;
      this.clientFileIds = [];
    }
  }


  sortTable(columnName: string) {
    var attachmentListWithAnyType: any = this.attachments;
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    attachmentListWithAnyType.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = attachmentListWithAnyType.slice(startIndex, endIndex);
  }

  displayRowDetails(rowDetails: string) {
    return rowDetails.length > 55
      ? rowDetails.substring(0, 55) + '...'
      : rowDetails;
  }
  
  convertUTCToLocalWithTimezone(utcDateStr: Date | null | string): Date | null {
    if (utcDateStr != null) {
      let sDate = new Date(utcDateStr);
      const timezoneOffset = sDate.getTimezoneOffset();
      return new Date(sDate.getTime() - timezoneOffset * 60 * 1000);
    } else {
      return null;
    }
  }
}
