import { Component, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ManageRolesService } from '../../services/manageRoles/manage-roles.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { HeaderComponent } from '../Home/header/header.component';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrl: './manage-roles.component.css',
})
export class ManageRolesComponent {
  searchString: string = '';
  isActive: string = 'Active';
  selectedStatus: string = 'All';

  sortedColumn: string = '';
  isAscending: boolean = true;
  clients: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  @ViewChild(HeaderComponent, { static: false })
  headerComponent!: HeaderComponent;
  isNavBarOpen: boolean = true;
  hideAddButton = false;

  /* Default Table options */
  pageSizeOptions= [5, 10, 20];
  pageSize= 20;
  pageIndex= 0;
  length= 0;
  hidePageSize= false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled= false;

  disableSuperAdmin: boolean = true;
  dataLoaded: boolean = false;

  constructor(
    private manageRolesService: ManageRolesService,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 2
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
    }
  }

  ngOnInit(): void {
    // this.staticData();
    this.dataLoaded = false;
    this.disableSuperAdmin = true;
    this.selectedStatus = 'All';
    this.getAllRoles('', this.selectedStatus).then((_) => {
      this.length = this.clients.length;
      this.paginatedData = this.clients.slice(0, this.pageSize);
    });
  }

  getAllRoles(status: string, roleName: string) {
    const promise = new Promise<void>((resolve, reject) => {
      this.manageRolesService.getRoleList(roleName, status).subscribe({
        next: (data: any) => {
          this.clients = data;
          this.length = this.clients.length;
          this.paginatedData = this.clients.slice(0, this.pageSize);
          this.dataLoaded = true;
          resolve();
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    });
    return promise;
  }

  toggleSwitch(client: any, switchType: string) {
    if (switchType == 'activation') {
      this.dataLoaded = false;
      client.isActive = !client.isActive;

      this.manageRolesService
        .setroleActiveInActive(client.roleID, client.isActive)
        .subscribe({
          next: () => {
            this.dataLoaded = true;
          },
          error: () => {
            this.dataLoaded = true;
          },
        });
    } else {
      client.isSuperAdmin = !client.isSuperAdmin;
    }
  }

  sort(columnName: string): void {
    this.clients.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    this.dataLoaded = false;

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clients.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.clients.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  onRadioChange(status: string) {
    this.selectedStatus = status;
  }

  search() {
    this.dataLoaded = false;
    this.getAllRoles(this.searchString, this.selectedStatus).then((_) => {
      this.dataLoaded = true;
    });
  }

  reset() {
    this.dataLoaded = false;
    this.selectedStatus = 'All';
    this.searchString = '';
    this.ngOnInit();
  }
  addRole(){
    // this.router.navigate(['/addEditRole']);
    this.router.navigate(['/Admin/addEditRole']);
  }
  editRole(roleID: number) {
    // this.router.navigate(['/addEditRole', roleID]);
    this.router.navigate(['/Admin/addEditRole', roleID]);
  }
}
