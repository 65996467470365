<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">{{loadingMessage}}</div>
    </div>
</div>
<div class="compress-inputs-page">
    <div class="inner add-user">
        <div class="title">
            <h3>&nbsp;</h3>
            <a (click)="back()" class="btn btn-outline-secondary btn-sp">
                <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                Back
            </a>
        </div>
        <div class="form" [formGroup]="addEditCurrencyPairForm" (ngSubmit)="onSave()">
            <div class="card secondary">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Base Currency <em style="color:red;">*</em></label>
                            <div class="value-wrapper">
                                <div class="showhide"
                                    [ngClass]="{'newTooltip expand has-error':addEditCurrencyPairForm.controls['baseCurrencyID'].touched && addEditCurrencyPairForm.controls['baseCurrencyID'].invalid}"
                                    datatitle="Base Currency Is Required">
                                    <ng-select #baseInput [items]="currencyList " bindLabel="currencyName"
                                        [compareWith]="compareBase" (change)="onChangeOfdropDownbase($event)"
                                        placeholder="Select" formControlName="baseCurrencyID"
                                        [clearable]="false"></ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Term Currency <em style="color:red;">*</em></label>
                            <div class="value-wrapper">
                                <div class="showhide"
                                    [ngClass]="{'newTooltip expand has-error':addEditCurrencyPairForm.controls['termCurrencyID'].touched && addEditCurrencyPairForm.controls['termCurrencyID'].invalid}"
                                    datatitle="Base Currency Is Required">
                                    <ng-select #termInput (change)="onChangeOfdropDownterm($event)"
                                        [items]="currencyList" [clearable]="false" bindLabel="currencyName"
                                        [compareWith]="compareBase" formControlName="termCurrencyID"></ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Currency Pair Formed <em style="color:red;">*</em></label>
                            <div class="value-wrapper">
                                <div class="showhide" [ngClass]="{'newTooltip expand has-error':errorForCurrencyPair }"
                                    datatitle="Both Currency Cannot be Same">
                                    <input type="text" class="form-control" formControlName="currencyPairFormed">
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Price Increment<em style="color:red;">*</em></label>
                            <div class="value-wrapper">
                                <div class="showhide">
                                    <input type="text" class="form-control" formControlName="priceIncrement" appDecimalnumber>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Spots/Forwards</label>
                            <br />
                            <div class="custom-switch">
                                <input type="checkbox" class="switch-control-input" formControlName="spotsForwards">

                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-sm-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Spots/Forwards Treasury Margins Settings</label>
                            <div class="checkbox-block">
                                <div class="form-check-inline">
                                    <div class="custom-radio">
                                        <input type="radio" id="marginType" value="Percentage"
                                            formControlName="spotForwardTreasuryMarginType"
                                            name="spotForwardTreasuryMarginType" value="Percentage" class="radio-input"
                                            [tabindex]="1" (change)="marginTypeChange('Percentage')" [checked]="true">
                                        <span class="radio-label">Percentage</span>
                                    </div>
                                </div>
                                <div class="custom-radio">
                                    <div class="form-check-inline">
                                        <input type="radio" id="marginType" value="Tics"
                                            formControlName="spotForwardTreasuryMarginType"
                                            name="spotForwardTreasuryMarginType" value="Tics" class="radio-input"
                                            [tabindex]="2" (change)="marginTypeChange('Tics')">
                                        <span class="radio-label">Tics</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Spots/Forwards Treasury Margins<em
                                    style="color:red;">*</em></label>
                            <div class="value-wrapper">
                                <div class="showhide"
                                    [ngClass]="{'newTooltip expand has-error':addEditCurrencyPairForm.controls['spotForwardTreasuryMargin'].touched && addEditCurrencyPairForm.controls['spotForwardTreasuryMargin'].invalid}"
                                    datatitle="Treasury Margin Is Required">
                                    <input *ngIf="spotForwardTreasuryMarginType=='Percentage'" class="form-control"
                                        placeholder="Spots/Forwards Treasury Margins"
                                        formControlName="spotForwardTreasuryMargin" appPercentage>
                                    <input *ngIf="spotForwardTreasuryMarginType=='Tics'" class="form-control"
                                        placeholder="Spots/Forwards Treasury Margins"
                                        formControlName="spotForwardTreasuryMargin" appDecimalnumber>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Options</label>
                            <br />
                            <div class="custom-switch">
                                <input type="checkbox" class="switch-control-input" formControlName="options">

                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">ICE Book Name </label>
                            <input type="text" class="form-control" formControlName="iceBook" id="txICEBook"
                                placeholder="ICE Book Name">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label class="boldLabel">Is Active</label>
                            <br />
                            <div class="custom-switch">
                                <input type="checkbox" class="switch-control-input" formControlName="isActive">
                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"><br /></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="inline-element d-block text-center">
                            <button id="aSaveDetails" (click)="onSave()" class="btn btn-success" type="button"
                                style="margin-right: 10px;;">Save</button>
                            <button (click)="cancel()" id="aCancelDetails" class="btn btn-secondary"
                                type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>