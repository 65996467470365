import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEditContactService } from '../../services/addEditContact/add-edit-contact.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import Swal from 'sweetalert2';
import {
  ClientContact_TabID,
  ContactTabConstant,
  ErrorMessages,
  GeneralTabConstant,
  SpinnerMessage,
  SystemConstant,
} from '../../global/global.constant';
import { ClientContactsService } from '../../services/ClientContacts/client-contacts.service';
import {
  FieldPermission,
  TabPermission,
  clientContactData,
} from '../client-contacts/models/client-contacts.model';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { SearchParams } from '../manage-currencies/models/manage-currencies.model';
import { AdminInfo } from '../models/common.model';

@Component({
  selector: 'app-addcontact',
  templateUrl: './addcontact.component.html',
  styleUrl: './addcontact.component.css',
})

export class AddcontactComponent implements OnInit {
  clients: any[] = [];
  status: any[] = [];
  roles: any[] = [];
  theme: any[] = [];
  salutation: any[] = [];
  isUpdateMode: boolean = false;
  contactId = 0;
  clientID = 0;
  pendingAuth = false;
  pendingAuthValue = '';
  dataLoaded = false;
  loadingMessage = 'Loading';
  salutationList = [];
  themeList = [];
  roleList = [];
  statusList = [];
  countryList = [];
  statusListForlabel = [];
  clientList = [];
  dobValidationTooltip: string = SystemConstant.DOBTitle;
  maxDate: Date = new Date();
  tabPermission: any;
  canAddEdit: boolean = false;
  manageContactPermission: any;
  canInsert: any;
  canUpdate: any;
  isNavinciUser: boolean = false;
  contactFieldsViewFlag: boolean[] = [];
  fieldPermissions: FieldPermission[] = [];
  contactTabFP: any;
  contactType: boolean=true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private contactServices: AddEditContactService,
    private route: ActivatedRoute,
    private alert: SweetalertService,
    private clientContactsService: ClientContactsService,
    private encryptPermission: EncryptRolePermissionService
  ) {
    this.contactFieldsViewFlag = Array(20).fill(false);
  }

  contactsForm: FormGroup = this.fb.group({
    contactID: [],
    clientID: [],
    isAuthorisedSignatory: [false],
    isAuthorisedUser: [false],
    isAuthorisedToTrade: [false],
    isBeneficialOwner: [false],
    title: [null, [Validators.required]],
    clientName: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    middleName: [''],
    lastName: ['', [Validators.required]],
    alias: [],
    position: [],
    dateOfBirth: [],
    beneficiaryPercentage: [null],
    address: [],
    emailAddress: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    status: [null],
    userName: [null, [Validators.required]],
    isPrimaryContact: [false],
    clientRoleID: [null, [Validators.required]],
    themeID: [null, [Validators.required]],
    nationalityID: [null],
  });

  ngOnInit(): void {
    this.checkModulePermission();    
    this.contactsForm.reset();
    this.route.paramMap.subscribe((parameterMap) => {
      const contactID = +parameterMap.get('contactId')!;
      const clientID = +parameterMap.get('clientID')!;
      this.clientID = clientID;
      if (contactID) {
        this.isUpdateMode = true;
        
        this.contactType=this.fieldPermissions.find(field=>field.fieldID==ContactTabConstant.contactType)?.canInsert?true:false;
        this.contactId = contactID;
        this.clientID = clientID;
        this.getContactDetail(this.contactId);
        this.getDropDown();
        this.getrolePermission();
        if (!this.canUpdate) {
          this.contactsForm.disable();
          this.canInsert = false;
        }
      } else {
        this.isUpdateMode=false; 
        this.contactType=true;             
        this.contactsForm.enable();
        this.contactFieldsViewFlag = Array(20).fill(true);
        this.contactsForm.get('status')?.disable();
        this.getContactDetail(this.contactId);
        this.getDropDown();
      }
    });

    this.contactsForm
      .get('isBeneficialOwner')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.contactsForm
            .get('beneficiaryPercentage')
            ?.setValidators([
              Validators.required,
              Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/),
            ]);
        } else {
          this.contactsForm.get('beneficiaryPercentage')?.clearValidators();
        }
        this.contactsForm
          .get('beneficiaryPercentage')
          ?.updateValueAndValidity();
      });

    this.dataLoaded = false;
    this.clientContactsService.getClientContactLists(this.clientID).subscribe({
      next: (data: any) => {
        this.clientList = data.clients;
        this.salutationList = data.salutation;
        this.roleList = data.role;
        this.statusList = data.status;
        this.statusList= data.status.filter((status: any) =>
          !["All", "PendingAuthorization", "PendingEmailVerification"].includes(status.contactStatusId)
        );
        this.statusListForlabel = data.status;
        this.themeList = data.theme;
        this.countryList = data.country;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
    
  }

  private checkModulePermission = (): void => {
    const modulePermission =
      this.encryptPermission.getDecryptedPermissions(
        'roleNgp'
      ).modulePermissions;
    if (modulePermission) {
      this.manageContactPermission = modulePermission.find(
        (x: { pageID: number }) => x.pageID == 4
      );
      this.canInsert =
        this.manageContactPermission?.canInsert !== undefined
          ? this.manageContactPermission?.canInsert
          : 0;
      this.canUpdate =
        this.manageContactPermission?.canUpdate !== undefined
          ? this.manageContactPermission?.canUpdate
          : 0;
      if (
        this.manageContactPermission &&
        this.manageContactPermission.canView == 1
      ) {
        const params: SearchParams = {
          currencyAbbreviation: '',
          currencyName: '',
        };
      }
    }
  };

  getrolePermission(): void {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
        this.fieldPermissions =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).fieldPermissions;
        this.tabPermission =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).tabPermissions;
        this.setRolePermission();
      }
    }
  }

  setRolePermission() {
    let permission = this.tabPermission.find(
      (tab: TabPermission) => tab.tabID == ContactTabConstant.tabId
    );
    if (permission && permission.canInsert == ContactTabConstant.disable) {
      this.contactsForm.reset();
      this.contactsForm.setErrors({ noPermission: true });
      this.contactsForm.disable();
    }

    this.contactTabFP = this.fieldPermissions.filter(
      (item: { fieldID: number }) =>
        item.fieldID >= ContactTabConstant.contactRoleID &&
        item.fieldID <= ContactTabConstant.contactType ||
        [
          GeneralTabConstant.ClientNameFiledID,
        ]
    );
    this.contactTabFP.forEach(
      (field: { fieldID: number; canView: number; canInsert: number }) => {
        switch (field.fieldID) {
          
          case ContactTabConstant.clientName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[17] = true)
              : (this.contactFieldsViewFlag[17] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['clientName'].disable();
            }
            break;

          case ContactTabConstant.contactRoleID:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[0] = true)
              : (this.contactFieldsViewFlag[0] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['clientRoleID'].disable();
            }
            break;

          case ContactTabConstant.contactAddress:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[1] = true)
              : (this.contactFieldsViewFlag[1] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['address'].disable();
            }
            break;

          case ContactTabConstant.contactAlias:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[2] = true)
              : (this.contactFieldsViewFlag[2] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['alias'].disable();
            }
            break;

          case ContactTabConstant.contactDOB:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[3] = true)
              : (this.contactFieldsViewFlag[3] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['dateOfBirth'].disable();
            }
            break;

          case ContactTabConstant.contactEmail:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[4] = true)
              : (this.contactFieldsViewFlag[4] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['emailAddress'].disable();
            }
            break;

          case ContactTabConstant.contactFirstName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[5] = true)
              : (this.contactFieldsViewFlag[5] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['firstName'].disable();
            }
            break;

          case ContactTabConstant.contactLastName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[6] = true)
              : (this.contactFieldsViewFlag[6] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['lastName'].disable();
            }
            break;

          case ContactTabConstant.contactMiddleName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[7] = true)
              : (this.contactFieldsViewFlag[7] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['middleName'].disable();
            }
            break;

          case ContactTabConstant.contactNationality:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[8] = true)
              : (this.contactFieldsViewFlag[8] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['nationalityID'].disable();
            }
            break;

          case ContactTabConstant.contactPhone:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[9] = true)
              : (this.contactFieldsViewFlag[9] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['phoneNumber'].disable();
            }
            break;

          case ContactTabConstant.contactPosition:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[10] = true)
              : (this.contactFieldsViewFlag[10] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['position'].disable();
            }
            break;

          case ContactTabConstant.contactStatusID:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[12] = true)
              : (this.contactFieldsViewFlag[12] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['status'].disable();
            }
            break;

          case ContactTabConstant.contactTheme:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[13] = true)
              : (this.contactFieldsViewFlag[13] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['themeID'].disable();
            }
            break;

          case ContactTabConstant.contactTitle:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[15] = true)
              : (this.contactFieldsViewFlag[15] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['title'].disable();
            }
            break;

          case ContactTabConstant.contactUserName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[16] = true)
              : (this.contactFieldsViewFlag[16] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactsForm.controls['userName'].disable();
            }
            break;
          case ContactTabConstant.primaryContact:
              field.canView == ContactTabConstant.canView
                ? (this.contactFieldsViewFlag[18] = true)
                : (this.contactFieldsViewFlag[18] = false);
  
              if (field.canInsert == ContactTabConstant.disable) {
                this.contactsForm.controls['isPrimaryContact'].disable();
              }
              break;

              case ContactTabConstant.contactType:
                field.canView == ContactTabConstant.canView
                  ? (this.contactFieldsViewFlag[19] = true)
                  : (this.contactFieldsViewFlag[19] = false);
                if (field.canInsert == ContactTabConstant.disable) {
                  this.contactType=false;
                }
                else{
                  this.contactType=true;
                }
                break;
  
        }
      }
    );
  }

  checkDOB() {
    const control = this.contactsForm.controls['dateOfBirth'];
    if (control.touched && control.invalid) {
      if (control.errors?.['matDatepickerParse']) {
        this.dobValidationTooltip = ErrorMessages.Valid_Date;
      } else if (control.errors?.['matDatepickerMax']) {
        this.dobValidationTooltip = ErrorMessages.Future_Date;
      } else {
        this.contactsForm.get('dateOfBirth')?.setErrors(null);
        this.dobValidationTooltip = SystemConstant.DOBTitle;
      }
    }
  }

  getRoles() {
    if (this.contactsForm.controls['clientName'].value.clientID > 0) {
      this.dataLoaded = false;
      this.contactsForm.controls['clientRoleID'].setValue(null);
      const clientID = this.contactsForm.controls['clientName'].value.clientID;
      this.contactServices.getRoleWithClientID(clientID).subscribe({
        next: (data: any) => {
          this.roleList = data;
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    }
  }

  checkRoleOfContact() {
    if(this.contactType){
    let firstTimeFlag: boolean = true;
    let isAuthorisedUser: boolean = this.contactsForm.get('isAuthorisedUser')
      ?.value
      ? this.contactsForm.get('isAuthorisedUser')?.value
      : false;
    let isAuthorisedSignatory: boolean = this.contactsForm.get(
      'isAuthorisedSignatory'
    )?.value
      ? this.contactsForm.get('isAuthorisedSignatory')?.value
      : false;
    let isBeneficialOwner: boolean = this.contactsForm.get('isBeneficialOwner')
      ?.value
      ? this.contactsForm.get('isBeneficialOwner')?.value
      : false;
    let isAuthorisedToTrade: boolean = this.contactsForm.get(
      'isAuthorisedToTrade'
    )?.value
      ? this.contactsForm.get('isAuthorisedToTrade')?.value
      : false;
    if (
      !isAuthorisedSignatory &&
      !isAuthorisedUser &&
      !isAuthorisedToTrade &&
      !isBeneficialOwner
    ) {
      this.contactsForm.get('isAuthorisedUser')?.enable();
      this.contactsForm.get('isAuthorisedSignatory')?.enable();
      this.contactsForm.get('isBeneficialOwner')?.enable();
      this.contactsForm.get('isAuthorisedToTrade')?.enable();
      firstTimeFlag = true;
    } else {
      if (isAuthorisedUser && firstTimeFlag) {
        this.contactsForm.get('isAuthorisedUser')?.enable();
        this.contactsForm.get('isAuthorisedToTrade')?.enable();
        this.contactsForm.get('isAuthorisedSignatory')?.reset();
        this.contactsForm.get('isBeneficialOwner')?.reset();
        this.contactsForm.get('isAuthorisedSignatory')?.disable();
        this.contactsForm.get('isBeneficialOwner')?.disable();
        firstTimeFlag = false;
      }
      if (isAuthorisedSignatory && firstTimeFlag) {
        this.contactsForm.get('isAuthorisedSignatory')?.enable();
        this.contactsForm.get('isBeneficialOwner')?.enable();
        this.contactsForm.get('isAuthorisedToTrade')?.enable();
        this.contactsForm.get('isAuthorisedUser')?.reset();
        this.contactsForm.get('isAuthorisedUser')?.disable();

        firstTimeFlag = false;
      }
      if (isBeneficialOwner && firstTimeFlag) {
        this.contactsForm.get('isAuthorisedSignatory')?.enable();
        this.contactsForm.get('isBeneficialOwner')?.enable();
        this.contactsForm.get('isAuthorisedToTrade')?.reset();
        this.contactsForm.get('isAuthorisedToTrade')?.disable();
        this.contactsForm.get('isAuthorisedUser')?.reset();
        this.contactsForm.get('isAuthorisedUser')?.disable();

        firstTimeFlag = false;
      }
      if (isAuthorisedToTrade && firstTimeFlag) {
        this.contactsForm.get('isAuthorisedUser')?.enable();
        this.contactsForm.get('isAuthorisedToTrade')?.enable();
        this.contactsForm.get('isAuthorisedSignatory')?.reset();
        this.contactsForm.get('isAuthorisedSignatory')?.disable();
        this.contactsForm.get('isBeneficialOwner')?.reset();
        this.contactsForm.get('isBeneficialOwner')?.disable();
        firstTimeFlag = false;
      }
    }}
    else{     
      this.contactsForm.get('isAuthorisedUser')?.disable();
      this.contactsForm.get('isAuthorisedSignatory')?.disable();
      this.contactsForm.get('isBeneficialOwner')?.disable();
      this.contactsForm.get('isAuthorisedToTrade')?.disable();
      this.contactsForm.get('beneficiaryPercentage')?.disable();     
    }
  }

  getToday(): string {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today.toISOString().split('T')[0];
  }

  disableManualInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  getDropDown() {
    this.dataLoaded = false;
    this.contactServices.getDropDownList(this.clientID).subscribe({
      next: (data: any) => {
        this.clients = data.clients;
        this.status = data.status.filter(
          (obj: any) =>
            obj.contactStatusId != 'PendingAuthorization' &&
            obj.contactStatusId != 'PendingEmailVerification' &&
            obj.contactStatusId != 'All'
        );
        this.roles = data.role;
        this.theme = data.theme;
        this.salutation = data.salutation;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  getContactDetail(contactId: number) {
    this.dataLoaded = false;
    this.contactServices.getContactDetails(contactId).subscribe({
      next: (data: clientContactData) => {
        this.contactsForm.patchValue(data);
        const statusValue = this.contactsForm.get('status')?.value;
        if (statusValue == 'PendingAuthorization') {
          this.pendingAuth = true;
          this.pendingAuthValue = 'Pending Authorization';
        } else if (statusValue == 'PendingEmailVerification') {
          this.pendingAuth = true;
          this.pendingAuthValue = 'Pending Email Verification';
        } 
        if(this.canUpdate)
        this.checkRoleOfContact();
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }
  convert(str: string) {
    var date = new Date(str),
      month = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }


  onSave() {
    if (this.contactsForm.valid) {
      let isAuthorisedUser: boolean = this.contactsForm.get('isAuthorisedUser')
      ?.value
      ? this.contactsForm.get('isAuthorisedUser')?.value
      : false;
    let isAuthorisedSignatory: boolean = this.contactsForm.get(
      'isAuthorisedSignatory'
    )?.value
      ? this.contactsForm.get('isAuthorisedSignatory')?.value
      : false;
    let isBeneficialOwner: boolean = this.contactsForm.get('isBeneficialOwner')
      ?.value
      ? this.contactsForm.get('isBeneficialOwner')?.value
      : false;
    let isAuthorisedToTrade: boolean = this.contactsForm.get(
      'isAuthorisedToTrade'
    )?.value
      ? this.contactsForm.get('isAuthorisedToTrade')?.value
      : false;
    if (
      !isAuthorisedSignatory &&
      !isAuthorisedUser &&
      !isAuthorisedToTrade &&
      !isBeneficialOwner
    ) {
      Swal.fire({
        icon: 'warning',
        title: ErrorMessages.Select_role,
        confirmButtonText: 'OK',
        showConfirmButton: true,
         });
      
    } else
      {      
      this.loadingMessage = 'Updating';
      this.dataLoaded = false;
      let formValue=this.contactsForm.getRawValue();
      let dateOFBirth: Date | null = new Date();
      if (formValue.dateOfBirth) {
        if (formValue.dateOfBirth._d) {
          dateOFBirth = new Date(this.convert(formValue.dateOfBirth._d));
        } else {
          dateOFBirth = formValue.dateOfBirth;
        }
      } else {
        dateOFBirth = null;
      }
      formValue.dateOfBirth=dateOFBirth;
      if (this.isUpdateMode) {        
        this.contactServices.updateContact(formValue).subscribe({
          next: (data: any) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Updated').then(() => {
              this.contactsForm.patchValue(data);
              this.back();
            });
          },
          error: (response) => {
            this.dataLoaded = true;
            this.displayBackEndErrorMessage(response);
          },
        });
      } else {
        this.loadingMessage = SpinnerMessage.Saving;
        this.dataLoaded = false;    
        this.contactServices.addContact(formValue).subscribe({
          next: (data: any) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then(() => {
              this.contactsForm.patchValue(data);
              this.back();
            });
          },
          error: (response) => {
            this.dataLoaded = true;
            this.displayBackEndErrorMessage(response);
          },
        });
      }
    }} else {
      this.markFormGroupTouched(this.contactsForm);
    }
  }

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Swal.fire({
      icon: 'warning',
      title: ErrorMessages.Please_fill_required_inputs,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  };

  private displayBackEndErrorMessage = (response: any): void => {
    if (response && response.error && response.error.errors) {
      if (response.error.errors.FirstName) {
        this.showMessage(response.error.errors.FirstName[0]);
      } else if (response.error.errors.LastName) {
        this.showMessage(response.error.errors.LastName[0]);
      } else if (response.error.errors.PhoneNumber) {
        this.showMessage(response.error.errors.PhoneNumber[0]);
      } else if (response.error.errors.EmailAddress) {
        this.showMessage(response.error.errors.EmailAddress[0]);
      } else if (response.error.errors.UserName) {
        this.showMessage(response.error.errors.UserName[0]);
      } else {
        this.showMessage(
          ErrorMessages.Error_occurred_while_processing_your_request
        );
      }
    } else if (response && response.error && response.error[0].description) {
      this.showMessage(response.error[0].description);
    }
  };

  private showMessage = (message: string): void => {
    Swal.fire({
      icon: 'warning',
      title: message,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
  };

  back() {
    // this.router.navigate(['/managecontact']);
    this.router.navigate(['/Admin/managecontact']);
  }

  cancle() {
    if (this.isUpdateMode) {
      this.getContactDetail(this.contactId);
    } else {
      this.contactsForm.reset();
    }
  }

  clientDropdownChange = (): void => {
    const clientId: number =
      this.contactsForm.controls['clientID'].value > 0
        ? this.contactsForm.controls['clientID'].value
        : 0;
    this.contactServices.getDropDownList(clientId).subscribe({
      next: (data: any) => {
        if (data && data.role) {
          this.roles = data.role;
        }
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  };
}
