import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ValidateClientOtpService {

  constructor(private http:HttpClient) { }
  apiUrl=environment.apiUrl;

  validateOtp(payload:any){
    return this.http.post(this.apiUrl+'ContactAccount/validateotp',payload);
  }

  resendOtp(payload:any){
    return this.http.patch(this.apiUrl+'ContactAccount/resendotp',payload);
  }

}
