import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SigninService } from '../../services/signin/signin.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { RolePermissonService } from '../../services/Permission/role-permisson.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.css',
  encapsulation: ViewEncapsulation.Emulated,
})
export class SigninComponent implements OnDestroy {
  public loading: boolean = false;
  public Error: boolean = false;
  fieldTextType: boolean = false;
  subscription!: Subscription;

  form: FormGroup = this.fb.nonNullable.group({
    UserName: ['', [Validators.required, Validators.maxLength(50)]],
    UserPassword: ['', [Validators.required, Validators.maxLength(50)]],
    Remember: [false],
  });
  errormsg: any;
  hashToken: any;
  validToken: boolean = true;
  loginResponse: any;
  @Input() id: string = '';

  constructor(
    private fb: FormBuilder,
    private signInService: SigninService,
    private router: Router,
    private roleService: RolePermissonService,
    private encryptPermission: EncryptRolePermissionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const localStorage = document.defaultView?.localStorage;

    var savedLogin =
      this.encryptPermission.getDecryptedPermissions('logadmincred');
    if (savedLogin) {
      this.form.patchValue(savedLogin);
    }
  }

  ngOnInit() {
    this.hashToken = this.id;
    if (this.hashToken) {
      this.loading = true;
      this.signInService.validateToken(this.hashToken).subscribe({
        next: (data: any) => {
          this.validToken = true;
          this.loading = false;
        },
        error: (error) => {
          this.validToken = false;
          this.loading = false;
        },
      });
    }
  }

  regenerateLink() {
    this.signInService.regenerateURL(this.hashToken).subscribe({
      next: (data: any) => {
        swal.fire({
          icon: 'success',
          title: 'A new link has been sent to your email',
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
      },
      error: (error) => {
        if (
          error.error &&
          Array.isArray(error.error) &&
          error.error[0].errorType === 'Validation'
        ) {
          const description = error.error[0].description;
          this.errormsg = description;
        } else {
          this.errormsg = 'Unable to send mail invalid tokken or Url';
        }
        swal
          .fire({
            icon: 'warning',
            title: this.errormsg,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/Client']);
            }
          });
      },
    });
  }

  onSubmit() {
    this.loading = true;
    const localStorage = document.defaultView?.localStorage;
    if (this.form.value.Remember) {
      this.encryptPermission.encryption(this.form.value, 'logadmincred');
    } else {
      localStorage?.removeItem('logadmincred');
    }
    this.subscription = this.signInService
      .signIn(this.form.value.UserName!, this.form.value.UserPassword!)
      .subscribe({
        next: (result) => {
          if (result.status) {
            this.loading = false;
            localStorage?.setItem('jwtToken', result.jwtToken);
            this.loginResponse = result;
            this.encryptPermission.encryption(
              this.loginResponse,
              'userResponse'
            );
            localStorage?.setItem('loggedInTime', (new Date()).toString());
            this.getrolePermission(result.roleID);
          }
        },
        error: (error) => {
          this.loading = false;
          if (
            error.error &&
            error.error.errors &&
            error.error.errors.Password
          ) {
            const passwordError = error.error.errors.Password[0];
            this.errormsg = passwordError;
          } else if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
          } else {
            this.errormsg = 'Invalid Username or Password';
          }
          this.Error = true;
        },
      });
  }
  login(result: any) {
    if (result.changePasswordAtNextLogon) {
      this.hashToken = encodeURIComponent(this.hashToken);
      const tokenID = this.hashToken;

      this.router.navigateByUrl(`Admin/Account/ResetPassword/${tokenID}`);
    } else if (result.is2FAEnabled) {
      this.router.navigateByUrl('validateotp');
    } else {
      this.router.navigateByUrl('Admin/managecontact');
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  getrolePermission(clientRoleID: number): void {
    this.roleService.getRolePermissionData(clientRoleID).subscribe({
      next: (data: any) => {
        this.encryptPermission.encryption(data, 'roleNgp');
        this.login(this.loginResponse);
      },
      error: () => {
      },
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
