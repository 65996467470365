import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ClientaccountService } from '../../services/ClientAccount/clientaccount.service';
import { throwError } from 'rxjs';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-clientaccount',
  templateUrl: './clientaccount.component.html',
  styleUrl: './clientaccount.component.css',
})
export class ClientaccountComponent implements OnInit {
  loading = false;
  loadingMessage = 'Loading...';
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  searchString: string = '';
  sortedColumn: string = '';
  isAscending: boolean = true;
  clientAccountData: any[] = [];
  rolePermissioncanUpdate: boolean = false;
  isClient = false;
  file: File | null = null;
  status: 'initial' | 'uploading' | 'success' | 'fail' = 'initial';
  @ViewChild('fileInput') fileInput!: ElementRef;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private clientAccountService: ClientaccountService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private encryptPermission: EncryptRolePermissionService,
    private alert: SweetalertService
  ) {}
  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isClient = false;
      if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
        const pagePermission = this.encryptPermission
          .getDecryptedPermissions('roleNgp')
          .modulePermissions.find(
            (permission: { pageID: number }) => permission.pageID === 12
          );

        if (
          (pagePermission && pagePermission.canInsert === 1) ||
          pagePermission.canUpdate === 1
        ) {
          this.rolePermissioncanUpdate = true;
        } else {
          this.rolePermissioncanUpdate = false;
        }
      }
      if (this.encryptPermission.getDecryptedPermissions('userResponse')) {
        const usertype = this.encryptPermission
          .getDecryptedPermissions('userResponse').userType
        if(usertype!= 'Navinci'){
          this.rolePermissioncanUpdate=false;
        }
       
      }
      this.getclientAccountData();
      this.length = this.clientAccountData.length;
      this.paginatedData = this.clientAccountData.slice(0, this.pageSize);
    } else if (user === 'Client') {
      this.rolePermissioncanUpdate = false;
      this.isClient = true;
      const clientinfo =
        this.encryptPermission.getDecryptedPermissions('usercrd');
      if (clientinfo) this.accountDataForClient(clientinfo.clientID);
    }
  }

  getclientAccountData() {
    this.loading = true;
    const promise = new Promise<void>((resolve, reject) => {
      this.clientAccountService.getClientAccountData().subscribe({
        next: (data: any) => {
          this.clientAccountData = data;
          this.length = this.clientAccountData.length;
          this.paginatedData = this.clientAccountData.slice(0, this.pageSize);
          this.loading = false;
          resolve();
        },
      });
    });
    return promise;
  }

  accountDataForClient(clientId: number) {
    this.loading = true;
    this.clientAccountService.getAccountDataForClient(clientId).subscribe({
      next: (data: any) => {
        this.clientAccountData = data;
        this.length = this.clientAccountData.length;
        this.paginatedData = this.clientAccountData.slice(0, this.pageSize);

        this.loading = false;
      },
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clientAccountData.slice(startIndex, endIndex);
  }
  sort(columnName: string): void {
    this.clientAccountData.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clientAccountData.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clientAccountData.slice(startIndex, endIndex);
  }

  openFileUpload() {
    const modal =
      this.elementRef.nativeElement.querySelector('#fileUpload-modal');
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      // Show the modal
      modal.classList.add('show');
      modal.style.display = 'block';

      // Append the backdrop behind the modal
      document.body.appendChild(backdrop);

      // Set focus on the modal
      modal.focus();
    }
  }
  close() {
    const modal =
      this.elementRef.nativeElement.querySelector('#fileUpload-modal');

    modal.classList.remove('show');
    modal.style.display = 'none';
    this.fileInput.nativeElement.value = '';
    this.status = 'initial';
  }

  onChange(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.status = 'initial';
      this.file = file;
    }
  }
  fileUpload() {
    if (this.file) {
      this.loading = true;
      this.loadingMessage = 'Uploading...';
      const formData = new FormData();

      formData.append('file', this.file, this.file.name);

      const upload$ = this.clientAccountService.fileUpload(formData);

      upload$.subscribe({
        next: () => {
          this.loading = false;
          this.status = 'success';
          this.getclientAccountData().then((_) => {
            this.close();
            this.alert.fireAlert('Uploaded');
          });
        },
        error: (error: any) => {
          this.loading = false;
          this.status = 'fail';
          this.alert.showError(error,error.error[0].description);
          return throwError(() => error);
        },
      });
    }
  }

  downloadTemplate(): void {
    const header = [
      'Client Name',
      'Account',
      'Account Status',
      'Description',
      'Currency',
      'Balance',
    ];
    const data = [
      [
        'Client Name',
        'Account',
        'Account Status',
        'Description',
        'Currency',
        'Balance',
      ],
      ['Client A', 'Account-0000046', 'Active', 'Payment', 'USD', 1000],
      ['Client B', 'Account-0000003', 'Active', 'Margin/Holding', 'AUD', 2000],
    ];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'Sample ClientAccount Upload File.xlsx');
  }
}
