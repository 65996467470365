import { ThisReceiver } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ManageSalesMarginService } from '../../../services/ManageSalesMargin/manage-sales-margin.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { HeaderComponent } from '../../Home/header/header.component';
import { SweetalertService } from '../../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-manage-sales-margin',
  templateUrl: './manage-sales-margin.component.html',
  styleUrl: './manage-sales-margin.component.css',
})
export class ManageSalesMarginComponent implements OnInit {
  clients: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  @ViewChild(HeaderComponent, { static: false })
  headerComponent!: HeaderComponent;
  isNavBarOpen: boolean = true;
  hideAddButton = false;
  loaderText: string = 'Loading...';

  /* Default Table options */
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  dataLoaded: boolean = false;
  modalTitle: string = 'Add Sales Margin';

  salesMarginForm: any;

  constructor(
    private router: Router,
    private manageSalesMarginService: ManageSalesMarginService,
    private encryptPermission: EncryptRolePermissionService,
    private elementRef: ElementRef,
    private alert:SweetalertService
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 22
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
    }
  }

  ngOnInit() {
    this.isValueChanged = false;
    this.salesMargin();
    this.getAllSalesMargin();
  }

  salesMargin() {
    this.salesMarginForm = new FormGroup({
      id: new FormControl(Number(0)),
      tierName: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(75),
      ]),
      spot: new FormControl(+'', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      forward: new FormControl(+'', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      predelivery: new FormControl(+'', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      extension: new FormControl(+'', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
      options: new FormControl(+'', [
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^[0-9]*(?:\.[0-9]*)?$/),
      ]),
    });
  }

  getAllSalesMargin() {
    const promise = new Promise<void>((resolve, reject) => {
      this.manageSalesMarginService.getAllSalesMargin().subscribe({
        next: (data: any) => {
          this.clients = data;
          this.length = this.clients.length;
          this.paginatedData = this.clients.slice(0, this.pageSize);
          this.dataLoaded = true;
          resolve();
        },
        error: (error) => {
        },
      });
    });
    return promise;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  salesByIdData: any = [];
  getSalesById(id: number) {
    const promise = new Promise<void>((resolve, reject) => {
      this.manageSalesMarginService.getSalesMargibById(id).subscribe({
        next: (data: any) => {
          this.salesByIdData = [];
          this.salesByIdData = data;
          this.dataLoaded = true;
          resolve();
        },
        error: (error) => {
        },
      });
    });
    return promise;
  }

  @ViewChild('savesale') modal!: ElementRef;
  editMode: boolean = false;
  editSalesMargin(tierId: number) {
    this.modalTitle = 'Edit Sales Margin';
    this.dataLoaded = false;
    this.editMode = true;
    this.isValueChanged = false;
    this.getSalesById(tierId).then((_) => {
      this.salesMarginForm.patchValue(this.salesByIdData);
      this.openModal();
      this.checkChange();
      // this.modal.nativeElement.classList.add('show');
      // this.modal.nativeElement.style.display = 'block';
      // this.modal.nativeElement.focus();
    });
  }

  openModal() {
    const modal =
      this.elementRef.nativeElement.querySelector('#save-sales-margin');
    modal.classList.add('show');
    modal.style.display = 'block';
    modal.focus();
  }

  closeModal() {
    const modal =
      this.elementRef.nativeElement.querySelector('#save-sales-margin');
    modal.classList.remove('show');
    modal.style.display = 'none';

    this.modalTitle = 'Add Sales Margin';
    this.isValueChanged = false;
    this.editMode = false;
    this.salesByIdData = [];
    this.salesMarginForm.reset();
    this.salesMargin();
  }

  isValueChanged: boolean = false;
  checkChange() {
    this.salesMarginForm.valueChanges.subscribe((x: any) => {
      this.isValueChanged = true;
      if (
        parseFloat(x.spot) === parseFloat(this.salesByIdData.spot) &&
        parseFloat(x.forward) === parseFloat(this.salesByIdData.forward) &&
        parseFloat(x.predelivery) === parseFloat(this.salesByIdData.predelivery) &&
        parseFloat(x.extension) === parseFloat(this.salesByIdData.extension) &&
        parseFloat(x.options) === parseFloat(this.salesByIdData.options)
      ) {
        this.isValueChanged = false;
      }
    });
  }

  submitted: boolean = false;
  saveSalesMargin() {
    this.submitted = true;
    if (this.salesMarginForm.valid) {
      

      this.salesMarginForm.value.spot = parseFloat(
        this.salesMarginForm.value.spot
      );
      this.salesMarginForm.value.forward = parseFloat(
        this.salesMarginForm.value.forward
      );
      this.salesMarginForm.value.predelivery = parseFloat(
        this.salesMarginForm.value.predelivery
      );
      this.salesMarginForm.value.extension = parseFloat(
        this.salesMarginForm.value.extension
      );
      this.salesMarginForm.value.options = parseFloat(
        this.salesMarginForm.value.options
      );

      if (!this.editMode) {
        this.dataLoaded = false;
        this.loaderText = 'Saving...';
        this.manageSalesMarginService
          .addSalesMargin(this.salesMarginForm.value)
          .subscribe({
            next: (data: any) => {
              
                this.getAllSalesMargin().then(_ => {
                  this.closeModal();
                  this.isValueChanged = false;
                  this.submitted = false;
                  this.dataLoaded = true;
                  this.loaderText = 'Loading...';
                  this.alert.fireAlert('Updated');
                });
            },
            error: (error) => {
            },
          });
      } else {
        this.dataLoaded = false;
        this.loaderText = 'Updating...';
        this.manageSalesMarginService
          .editSalesMargin(this.salesMarginForm.value)
          .subscribe({
            next: (data: any) => {
              
                this.getAllSalesMargin().then(_ => {
                  this.closeModal();
                  this.isValueChanged = false;
                  this.submitted = false;
                  this.dataLoaded = true;
                  this.loaderText = 'Loading...';
                  this.alert.fireAlert('Updated');
                });

             
                            
            },
            error: (error) => {
              this.dataLoaded=true;
            },
          });
      }
    }
  }
}
