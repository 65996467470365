<div class="card">
    <div  class="dropdown mt-5 ml-3 mb-2">
        <div class="row">
            <div class="backButtonStyle col-md-4">
                <a (click)="backToReportPage()"  style="cursor: pointer;" class="mr-3 btn btn-outline-secondary btn-sp">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>
                <a (click)="backToReportPage()"  style="cursor: pointer;" class="btn btn-outline-secondary btn-sp">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Home
                </a>
            </div>
        </div>
        <div *ngIf="isAdmin" class="row">
            <div *ngIf="isNavinciUser" class="col-md-3">
                <label>Tenant/CARPartner</label>
                <ng-select [(ngModel)]="tenantCar" class="dropdown" [items]="tenantCarList" [closeOnSelect]="true"
                    [searchable]="true" bindLabel="name" bindValue="id" (change)="tenant_carpartnerChanged($event)"
                    placeholder="Select">
                </ng-select>
            </div>
            <div *ngIf="isAdmin" class="col-md-3">
                <label>Client</label>
                <ng-select [(ngModel)]="clientId" class="dropdown" [items]="clientList" [closeOnSelect]="true" [searchable]="true"
                    bindLabel="name" bindValue="clientID" (change)="enableSearchSection()" placeholder="Select">
                </ng-select>
            </div>
            <div style="margin-top: 27px;" class="col-md-6">
                <label class="confirmationReport">Confirmation Reports</label>
            </div>
        </div>
    </div>
    <div *ngIf="isSearchSeactionEnabled" class="card mt-3 ml-4">
        <div class="ml-3 mt-3 mr-3 mb-3">
            <div class="row">
                <div class="col-md-3">
                    <label>Trade Number</label>
                    <input [(ngModel)]="tradeNumber" class="w100" (keydown.enter)="searchTradeData()" />
                </div>
                <div class="col-md-3">
                    <label class="f17">Trade Start Date</label>
                    <input [(ngModel)]="tradeStartDate" type="date" id="tradeStartDate" placeholder="DD/MM/YYYY" class="form-control">
                </div>
                <div class="col-md-3">
                    <label class="f17">Trade End Date</label>
                    <input [(ngModel)]="tradeEndDate" type="date" id="tradeEndDate" placeholder="DD/MM/YYYY" class="form-control">
                </div>
                <div *ngIf="isAdmin" class="col-md-3">
                    <label>Client</label>
                    <ng-select [(ngModel)]="searchParamsClientId" class="dropdown" [items]="clientList" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="name" bindValue="clientID" placeholder="Select"
                        (keydown.enter)="searchTradeData()" (keydown)="closeDropdwon($event)" (click)="closeDropdwon($event)"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label>Product</label>
                    <ng-select [(ngModel)]="productValue" class="dropdown" [items]="productList" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="productName" bindValue="productID" placeholder="Select"
                        (keydown.enter)="searchTradeData()" (keydown)="closeDropdwon($event)" (click)="closeDropdwon($event)"
                    >
                    </ng-select>
                </div>
                <div class="col-md-3">
                    <label>Created By</label>
                    <ng-select [(ngModel)]="createdByValue" class="dropdown" [items]="createdByList" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="name" bindValue="contactID" placeholder="Select"
                        (keydown.enter)="searchTradeData()" (keydown)="closeDropdwon($event)" (click)="closeDropdwon($event)"
                    >
                    </ng-select>
                </div>
                <div class="col-md-3">
                    <label>Trade Status</label>
                    <ng-select [(ngModel)]="tradeStatus" class="dropdown" [items]="tradeStatusList" [closeOnSelect]="false" [searchable]="true"
                        bindLabel="tradeStatusName" bindValue="tradeStatusName" placeholder="Select"
                        (keydown.enter)="searchTradeData()" (keydown)="closeDropdwon($event)" (click)="closeDropdwon($event)"
                    >
                    </ng-select>
                </div>
                <div class="col-md-3 mt-3">
                    <a (click)="searchTradeData()" class="btn btn-success mr-3" id="btnSearch">Search</a>
                    <a  (click)="resetSearchResult()" class="btn btn-secondary">Reset</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 text-center form-group"><h3><br>Confirmation Report</h3></div>
        </div>
        <div *ngIf="tradeList.length>0;else notFound" class="GridClass custom-container">
            <div class="table-responsive">
                <table class="table custom-grid">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Trade ID</th>
                            <th *ngIf="isAdmin">ICE Trade ID</th>
                            <th>Trade Date</th>
                            <th>Product Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of paginatedData">
                            <td><a (click)="downloadDocument(row.tradeID)" class="anchorTag">
                                <img src="../../../../assets/PDF.png" />
                            </a></td>
                            <td><a (click)="downloadDocument(row.tradeID)"  class="anchorTag">{{row.tradeID}}</a></td>
                            <td  *ngIf="isAdmin"><a (click)="downloadDocument(row.tradeID)"  class="anchorTag">{{row.iceTradeID}}</a></td>
                            <td>{{row.tradeDate | date: 'dd/MM/yyyy'}}</td>
                            <td><a (click)="downloadDocument(row.tradeID)" class="anchorTag">{{row.productName}}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator [length]= "tradeListLength" (page)="handlePageEvent($event)"
              [pageSize]="10" showFirstLastButtons = "true"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page">
            </mat-paginator>
          
        </div>
    </div>
    <ng-template #notFound>
        <div class="alert alert-info">
            <span>No record found</span>
        </div>
    </ng-template>
</div>

<div *ngIf="isInnerLoading">
    <div class="loader-block">
        <div class="inner-loader">
        </div>
    </div>
</div>

