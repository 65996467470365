import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeltaLimitPatchRequest, DeltaLimitPatchResponse, DeltaLimitResponse } from '../../components/deltalimit/models/deltalimit.model';
@Injectable({
  providedIn: 'root',
})
export class DeltaLimitService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getDeltaLimits(ClientId: number): Observable<DeltaLimitResponse[]> {
    let url: string = this.apiUrl + 'Client/deltalimits?clientID=' + ClientId;
    return this.http.get<DeltaLimitResponse[]>(url);
  }

  updateDeltaLimit(deltaid: number, limit: number): Observable<DeltaLimitPatchResponse> {
    let payload: DeltaLimitPatchRequest = {
      id: deltaid,
      deltaLimit: limit,
    };
    let url: string = this.apiUrl + 'Client/deltalimit';
    return this.http.patch<DeltaLimitPatchResponse>(url, payload);
  }

  
}
