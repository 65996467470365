<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="new-login-wrapper">
    <div class="login">
        <div class="login-left" style="background-image: url('../../../assets/login-bg.png')">
        </div>
        <div class="login-right">
            <div class="otp">
                <form class="form">

                    <em><img src="../../../assets/Navinci BlackBG Logo.png" alt=""></em>
                    <div class="form-group">
                        <label>Please enter the One-Time Password (OTP) sent to {{otpSendTo}}</label>
                        <span *ngIf="timeoutExpired;else timerMessage" class="font-size-small">{{message}}</span>
                        <ng-template #timerMessage><span class="font-size-small">{{message}}{{this.display}}
                                seconds</span></ng-template>
                        <input type="text" id="txtOTP" maxlength="6" [(ngModel)]="userOtp"
                            [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Enter OTP"
                            autocomplete="off">
                    </div>
                    <button *ngIf="!timeoutExpired; else resetButton" type='button' value='Submit' title="Login"
                        class="btn btn-success" style="margin-right: 10px;" (click)="onSubmit()">Submit</button>
                    <ng-template #resetButton>
                        <button type="button" value="Resend" title="Resend" class="btn btn-success"
                            style="margin-right: 10px;" (click)="resendOtp()">Resend OTP</button>
                    </ng-template>
                    <a id="aRegenerateOTP" href="javascript:void(0)" class="btn btn-outline-secondary"
                        style="display:none">Regenerate OTP</a>
                    <a (click)="backToLogin()" class="btn btn-outline-secondary">Back to Login</a>

                    <div class="@ViewBag.mainclass" style="margin-top: 10px;"></div>

                    <div *ngIf="error" class="alert alert-danger" style="margin-top: 10px;">
                        You have entered the Incorrect OTP
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>