<!-- <app-header></app-header> -->
<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>
<div class="">
    <div class="inner user-page">
        <div class="title">
            <h3>CAR Partners</h3>

            <a (click)="addCarPartner()" id="btnCARPartnersAddNew" data-toggle="modal"
                data-target="#targetRevenue-modal" class="btn btn-success">
                <em><img src="../../../assets/plus.svg" alt=""></em>
                Add
            </a>
        </div>
        <div class="card">
            <div class="row search-criteria">
                <div class="col-md-4 col-sm-4">
                    <div class="form-group">
                        <label>CAR Partner/ Contact Email Address</label>
                        <input type="text" class="form-control" [(ngModel)]="searchString" id="txtCARPartner"
                            tabindex="1" (keydown.enter)="search()">
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="inline-element" style="padding-top:27px;">
                        <a class="btn btn-success" (click)="search()" id="btnRPSearch">Search</a>
                        <a class="btn btn-secondary" (click)="reset()" id="btnRPReset">Reset</a>
                    </div>
                </div>
            </div>

            <div>
            </div>

            <div class="nth-last-child-2-center">
                <div class="GridClass custom-container">
                    <div *ngIf="carPartners.length>0;else notFound" class="table-responsive" style="overflow-x: auto;">

                        <table class="table custom-grid page">
                            <colgroup>
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:15%">
                                <col style="width:10%">
                            </colgroup>
                            <thead>
                                <tr class="Row123">
                                    <th style="width:15%"><a (click)="sortTable('partnerName')"
                                            class="textstyle sort  active  ts-asc" unselectable="on"
                                            data-i="0">CAR&nbsp;Partner<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width:15%"><a (click)="sortTable('contactEmail')"
                                            class="textstyle sort  active  ts-asc" unselectable="on"
                                            data-i="1">Contact&nbsp;Email&nbsp;Address<span class="ts-sord"></span> </a>
                                    </th>
                                    <th style="width:15%"><a (click)="sortTable('revenueSharingPercSpot')"
                                            class=" textstyle sort active  ts-asc" unselectable="on"
                                            data-i="2">Revenue&nbsp;Sharing&nbsp;%&nbsp;Spot<span
                                                class="ts-sord"></span> </a></th>
                                    <th style="width:15%"><a (click)="sortTable('revenueSharingPercForward')"
                                            class="textstyle sort  active  " unselectable="on"
                                            data-i="3">Revenue&nbsp;Sharing&nbsp;%&nbsp;Forward<span
                                                class="ts-sord"></span> </a></th>
                                    <th style="width:15%"><a (click)="sortTable('revenueSharingPercOptions')"
                                            class=" textstyle sort  active  " unselectable="on" data-i="4">Revenue
                                            Sharing % Options<span class="ts-sord"></span> </a></th>
                                    <th style="width:15%"><a (click)="sortTable('relationshipManager')"
                                            class="textstyle sort  active  " unselectable="on" data-i="5">Relationship
                                            Manager<span class="ts-sord"></span> </a></th>
                                    <th style="width:10%"><a class="sort textstyle" unselectable="on" data-i="8">
                                            <span class="action">Action</span>
                                        </a></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of paginatedData">
                                    <td  class="wrapword">{{row.partnerName}}</td>
                                    <td class="wrapword">{{row.contactEmail}}</td>
                                    <td class="wrapword">{{row.revenueSharingPercSpot}}</td>
                                    <td class="wrapword">{{row.revenueSharingPercForward}}</td>
                                    <td class="wrapword">{{row.revenueSharingPercOptions}}</td>
                                    <td class="wrapword">{{row.relationshipManager}}</td>

                                    <td class="wrapword">
                                        <div class="action-btns"><a title="Edit" (click)="editCarPartner(row.carPartnerID)"><img src="../../../assets/edit.svg"
                                                    alt=""></a></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>
                </div>


            </div>
        </div>

    </div>

</div>
<ng-template #notFound>
    <div class="alert alert-info">
        <span>No record found</span>
    </div>
</ng-template>