import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddEditRoleService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getPages(): Observable<any> {
    let url = this.apiUrl + 'ContactRole/getpages';
    return this.http.get<any>(url);
  }
  getRoledetails(roleID :number):Observable<any>{
    let url = this.apiUrl + 'ContactRole/'+roleID;
    
    return this.http.get<any>(url);
  }
  updateRole(payload :any):Observable<any>{
    let url = this.apiUrl + 'ContactRole/update';    
    return this.http.patch<any>(url,payload);
  }
  addRole(payload :any):Observable<any>{
    let url = this.apiUrl + 'ContactRole/add';    
    return this.http.post<any>(url,payload);
  }
}
