<div class="compress-inputs-page quote-detail-page">
    <div class="inner user-dashboard">
        <div class="title">
            <h3>&nbsp;</h3>
            <a (click)="back()" id="btnBackToManageQuote" class="btn btn-outline-secondary btn-sp" tabindex="21">
                <em class="arrow-back"><img src="../../../../assets/left-arrow.svg" alt=""></em>
                <em class="arrow-back-white"><img src="../../../../assets/left-arrow-white.svg" alt=""></em>
                Back
            </a>
        </div>
        <div class="card-wrapper">
            <div>
                <div class="card">
                    <div class="has-sub-title">

                        <h4 class="title">VANILLA </h4>

                        <div class="right-title">
                            Spot Reference<span class="badge secondery badge-primary"
                                id="spnSpotReference">{{spotReference}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label class="strong">Currency Pair <em style="color:red;">*</em></label>
                                <div class="value-wrapper">
                                    <div class="showhide"
                                    [ngClass]=" {'newTooltip expand has-error': currencyPair.errors?.['required'] && (currencyPair.touched || currencyPair.dirty)}"
                                    title="Currency Pair Is required">
                                    <ng-select [items]="currencyPairs | async " bindLabel="currencyCode"
                                        [(ngModel)]="selectedCurrencyPair"
                                        [clearable]="true"
                                        (change)="onCurrencyPairChange()" #currencyPair="ngModel" required></ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group label-inline">

                                <label class="strong" style="padding:0px;">Notional Currency<em
                                        style="color:red;">*</em></label>

                                <div class="value-wrapper">
                                    <div id="divNotionalCurrency">
                                        <div class="checkbox-currency">
                                            <div class="custom-radio secondary">
                                                <input type="radio" name="currency" (change)="changeCurrency()"
                                                    class="radio-input" [tabindex]="1" [value]="baseCurrency" [(ngModel)]="notionalCurrency">
                                                <span class="radio-label">{{baseCurrency.name}}</span>
                                            </div>
                                            <div class="custom-radio secondary">
                                                <input type="radio" name="currency" (change)="changeCurrency()"
                                                    class="radio-input" [tabindex]="1" [value]="termCurrency" [(ngModel)]="notionalCurrency">
                                                <span class="radio-label">{{termCurrency.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline">

                                <label class="strong">First Expiry Date <em style="color:red;">*</em></label>

                                <div class="input-icons">

                                    <div class="showhide" id="firstExpiryError"
                                                [ngClass]=" {'newTooltip expand has-error': firstexpiry.errors?.['required'] && (firstexpiry.touched || firstexpiry.dirty)}"
                                                title="Expiry Date Is required">
                                    <input [(ngModel)]="firstExpiryDate" [matDatepickerFilter]="myFilter"
                                        [matDatepicker]="picker" id="datepicker" placeholder="DD/MM/YYYY"
                                        class="input-field form-control" [min]="minDate()" required #firstexpiry="ngModel">
                                    <span class="icon">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker (closed)="getBarriers()"></mat-datepicker>
                                    </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline range-slider-div">

                                <label class="strong">Notional Per Leg <em style="color:red;">*</em></label>

                                <div class="value-wrapper">
                                    <div class="has-sub-field">

                                        <span class="min-label">{{ minMaxNotional[0] | number }}</span>
                                        <mat-slider class="rhs-slider" [step]="1" [min]="minMaxNotional[0]" [max]="minMaxNotional[1]"
                                            (change)="onInputChangeNotionalPerLeg($event)" >
                                            <input matSliderThumb [(ngModel)]="notionalperlegint" (blur)="calculateSalesMarginAmount()">
                                        </mat-slider>
                                        <span class="max-label">{{ minMaxNotional[1] | number }}</span>

                                        <div class="range-slider-content">
                                            <div class="showhide"
                                                [ngClass]=" {'newTooltip expand has-error': NotionPerLegLabel.errors?.['minMaxValue'] }"
                                                title="Value Should be in Range">
                                                <span class="cur-symbol">{{notionalCurrency.name}}</span>
                                                <input type="text" class="form-control" name="NotionPerLegLabel"
                                                    [min]="minMaxNotional[0]" [max]="minMaxNotional[1]"
                                                      [(ngModel)]="notionalPerLeg"  required #NotionPerLegLabel="ngModel"  (change)="calculateSalesMarginAmount('notional')" appFormatInputNumber [appMinMaxValidator]="{min: minMaxNotional[0], max: minMaxNotional[1] }" >

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group label-inline">

                                <label class="strong">Direction <em style="color:red;">*</em></label>

                                <div class="value-wrapper">
                                    <div class="checkbox-currency">
                                        <div class="custom-radio secondary">
                                            <input type="radio" name="buy-sell" class="radio-input" [tabindex]="1"
                                                checked>
                                            <span class="radio-label">BUY</span>

                                        </div>
                                        <div class="custom-radio secondary">
                                            <input type="radio" name="buy-sell" class="radio-input" [tabindex]="1">
                                            <span class="radio-label">Sell</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline">

                                <label class="strong">No of Legs <em style="color:red;">*</em></label>

                                <div class="value-wrapper">
                                    <div class="has-sub-field">
                                        <span class="min-label">{{ minMaxNoOfLeg[0] |number}}</span>
                                        <mat-slider class="rhs-slider"  [min]="minMaxNoOfLeg[0]" [max]="minMaxNoOfLeg[1]"
                                            [step]="1">
                                            <input matSliderThumb [(ngModel)]="noOfleg" (blur)="calculateSalesMarginAmount()">
                                        </mat-slider>
                                        <span class="max-label">{{ minMaxNoOfLeg[1] |number}}</span>

                                        <div class="range-slider-content padding-xs">

                                            <div class="showhide"
                                                [ngClass]=" {'newTooltip expand has-error': noofleglabel.errors?.['min'] || noofleglabel.errors?.['max'] }"
                                                title="Value Should be in Range">
                                                <input type="number" class="form-control" name="noofleglabel"
                                                    [(ngModel)]="noOfleg" [min]="minMaxNoOfLeg[0]"
                                                    [max]="minMaxNoOfLeg[1]" #noofleglabel="ngModel" (change)="calculateSalesMarginAmount()">
                                            </div>
                                        </div>
                                    </div>
                                    <a id="aShowExpirySettings" title="Show Expiry Settings" (click)="openModal()"
                                        class="text-success float-sm-right mt-2" tabindex="11">Show Expiry Settings</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline">

                                <label class="strong">Call/Put<em style="color:red;">*</em></label>

                                <div class="value-wrapper">
                                    <div class="checkbox-currency">
                                        <div class="custom-radio secondary">
                                            <input type="radio" name="Call-Put" (change)="checkPremiumDisable()"
                                                class="radio-input" value="Zero Cost" [tabindex]="1"
                                                [(ngModel)]="premium">
                                            <span class="radio-label">{{baseCurrency.name}} Call</span>
                                        </div>
                                        <div class="custom-radio secondary">
                                            <input type="radio" name="Call-Put" class="radio-input"
                                                (change)="checkPremiumDisable()" value="Put" [tabindex]="2"
                                                [(ngModel)]="premium">
                                            <span class="radio-label">{{baseCurrency.name}} Put</span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label class="strong">Strike <em style="color:red;">*</em></label>
    
                                <div class="value-wrapper">
                                    <div class="has-sub-field">
                                        <span class="min-label">{{ minMaxCallStrike[0] }}</span>
                                        <mat-slider class="rhs-slider" [min]="minMaxCallStrike[0]" [max]="minMaxCallStrike[1]" [step]="0.0001">
                                            <input matSliderThumb [(ngModel)]="callStrike">
                                        </mat-slider>
                                        <span class="max-label">{{ minMaxCallStrike[1] }}</span>
                                        <div class="range-slider-content padding-xs">
                                            <input type="text" class="form-control" [(ngModel)]="callStrike">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                    
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="has-sub-title" style="float:left;width:100%;margin-bottom:15px;">
                                <h6 class="title">Trade Detail</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group label-inline">
                                <label class="strong">Delta</label>
                                <div class="value-wrapper">
                                    <input type="text" class="form-control bg-primary" placeholder="Put Strike"
                                        [(ngModel)]="delta" [disabled]="true">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label class="strong">Premium Client Pays</label>
                                <div class="value-wrapper">
                                    <input type="text" class="form-control text-primary" [(ngModel)]="delta"
                                        [disabled]="true">

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div class="inline-element d-block text-center">


                        <button id="btnQuote" type="button" class="btn btn-success disabled" value="Quote"
                            tabindex="19">Request Quote</button>

                    </div>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="graph-block text-center">
                <div id="divProductRateChart">
                    <div class="tradingview-widget-container" #tradingViewContainer style="height: 100%;width: 100%;">
                        <div id="tradingview_702f2_light" class="tradingview-widget-container__widget"
                            style="height: calc(100% - 32px); width: 100% "></div>
                        <div class="tradingview-widget-copyright">
                            <a href="https://www.tradingview.com/symbols/{{selectedCurrencyPair}}/?exchange=FX"
                                rel="noopener nofollow" target="_blank">
                                <span class="blue-text">{{selectedCurrencyPair}}</span>
                            </a>
                            <span>On TradingView</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal fade secondary backdrop" id="divRevenueSharingDetailsModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">

                    <button type="button" class="close" (click)="close()" data-dismiss="modal"><img
                            src="../../../../assets/cross.svg" alt=""></button>
                </div>
                <!-- Modal body -->
                <div id="divRevenueSharingDetailsData" class="modal-body">
                    <div class="compress-input-pages">
                        <div class="">
                            <div class="row">
                                <div class="col-sm-6">
                                    <label class="strong">Expiry Date</label>
                                </div>
                                <div class="col-sm-6">
                                    <label class="strong">Delivery Date</label>
                                </div>
                            </div>
                            <div class="row" *ngFor="let data of expiryData">
                                
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" [disabled]="true"
                                        [value]="data?.expiryDate | date:'dd/MM/yyyy'">
                                </div>
                                <div class="col-sm-6">
                                    <input class="col-sm-6" type="text" class="form-control" [disabled]="true"
                                        [value]="data?.deliveryDate | date:'dd/MM/yyyy'">
                                </div>
                                    
            
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>