<h2 mat-dialog-title>Email
    <button mat-icon-button tabindex="-1" (click)="closeModal()" style="float: right;" class="ms-auto mdc-icon-button" type="button">
        <mat-icon role="img" class="mat-icon" aria-hidden="true" data-mat-icon-type="font">
            close
        </mat-icon>
    </button>
</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label>Mail To<em style="color:red;">*</em></label>
                <input type="text" id="SendMail" name="SendMail" class="form-control" [(ngModel)]="emailString" />
                <span style="font-size: smaller;">(Use Comma(,) to add multiple email address )</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <input type="hidden" value="@Model.Item1.TradeID" id="hdnTradeID" />
    <input type="button" name="Submit" value="Submit" (click)="submit()" id="btnAttachmentsMail"
        class="btn btn-success" />
</mat-dialog-actions>