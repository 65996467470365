import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-referral-prtner-tabnavigation',
  templateUrl: './referral-prtner-tabnavigation.component.html',
  styleUrl: './referral-prtner-tabnavigation.component.css'
})
export class ReferralPrtnerTabnavigationComponent {
  editMode=false;
  referralPartnerId:number | undefined;
  isActive=true;
  activeArray: any = [];
  
  constructor(private route:ActivatedRoute, private router: Router){}

  ngOnInit(): void {
    this.activeTab();
    this.route.paramMap.subscribe(parameterMap => {
       this.referralPartnerId = +parameterMap.get('ReferralPartnerId')!;
       
      if (this.referralPartnerId) {
        this.editMode = true;
      }
    });    
  }

  activeTab(){
    var activaTabPath = this.route.snapshot.children[0].routeConfig?.path?.toString().split('/')[0];
    if(activaTabPath == 'referralPartnerGeneral'){
      this.activeArray[0] = true;
      this.activeArray[1] = false;
    }
    else{
      this.activeArray[1] = true;
      this.activeArray[0] = false;
    }
  }

  toggleActive(){
    this.isActive=!this.isActive;
  }
  
  isGeneralTabActive: boolean = true; 

  
  handleTabClick(tabName: string): void { 
    this.isGeneralTabActive = tabName === this.route.snapshot.children[0].routeConfig?.path?.toString().split('/')[0];
  }

  backReferralPartner(){
    this.router.navigate(['/Admin/ReferralPartner']);
  }

}
