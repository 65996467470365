import { Component, ElementRef, OnInit } from '@angular/core';
import { DeltaLimitService } from '../../services/deltaLimit/delta-limit.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ClientGeneralService } from '../../services/ClientGeneral/client-general.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { ErrorMessages } from '../../global/global.constant';
import { DeltaLimitResponse } from './models/deltalimit.model';

@Component({
  selector: 'app-deltalimit',
  templateUrl: './deltalimit.component.html',
  styleUrl: './deltalimit.component.css',
})
export class DeltalimitComponent implements OnInit {
  clientId: number = 0;
  dataLoaded: boolean = false;
  clientName: string = '';
  clientCode: string = '';
  deltaLimits: DeltaLimitResponse[] = [];
  sortedColumn: string = '';
  isAscending: boolean = true;
  pageSize = 5;
  pageIndex = 0;
  length = 0;
  paginatedData: any[] = [];

  selectedrow: any = {
    id: 0,
    currencyPairFormed: '',
    deltaBaseCurrencyName: '',
    spotsForwards: true,
    options: true,
    iceBook: '',
    deltaLimit: 0,
  };
  updatedDeltaLimit: number = 0;
  pageEvent: PageEvent | undefined;
  tabPermission: any;
  canAddEdit: boolean=false;

  constructor(
    private deltaLimitServices: DeltaLimitService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private clientgenralServices: ClientGeneralService,
    private sweetAlert:SweetalertService,
    private encryptPermission: EncryptRolePermissionService

  ) {
    this.paginatedData = [];
  }
  ngOnInit(): void {        
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientId = +parameterMap.get('ClientID')!;
    });
    const tabPermissions=this.encryptPermission.getDecryptedPermissions('roleNgp').tabPermissions;
    this.tabPermission=tabPermissions.find((x: { tabID: number; })=> x.tabID==2);
    this.canAddEdit=this.tabPermission.canInsert ?true :false;
    this.clientName = this.clientgenralServices.clientName;
    this.clientCode = this.clientgenralServices.clientCode;
    this.getDeltaLimits(this.clientId);
    this.length = this.deltaLimits.length;
    this.paginatedData = this.deltaLimits.slice(0, this.pageSize);
  }

  getDeltaLimits(clientID: number) {
    this.loadingMessage="Loading";
    this.dataLoaded = false;
    this.deltaLimitServices.getDeltaLimits(clientID).subscribe({
      next: (data: DeltaLimitResponse[]) => {
        this.deltaLimits = data;
        this.length = this.deltaLimits.length;
        this.paginatedData = this.deltaLimits.slice(0, this.pageSize);
        this.dataLoaded = true;
      },
      error: () => {
      
      },
    });
  }

  sortTable(columnName: keyof DeltaLimitResponse) {
    this.dataLoaded = false;

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.deltaLimits.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.deltaLimits.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.deltaLimits.slice(startIndex, endIndex);
  }
  openModal(row: any): void {
    this.selectedrow = row;
    this.updatedDeltaLimit = this.selectedrow.deltaLimit;
    const modal = this.elementRef.nativeElement.querySelector(
      '#updateCurrencyPair-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      // Show the modal
      modal.classList.add('show');
      modal.style.display = 'block';

      // Append the backdrop behind the modal
      document.body.appendChild(backdrop);

      // Set focus on the modal
      modal.focus();
    }
  }
  close() {
    const modal = this.elementRef.nativeElement.querySelector(
      '#updateCurrencyPair-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  loadingMessage='Loading'
  updateDeltalimit() {
    this.loadingMessage='Updating';
    this.dataLoaded=false;
    this.deltaLimitServices
      .updateDeltaLimit(this.selectedrow.id, this.updatedDeltaLimit)
      .subscribe({
        next: () => {
          this.dataLoaded=true;
          this.sweetAlert.fireAlert('Updated').then(() => {
            this.close();
            this.getDeltaLimits(this.clientId);
          });
        },
        error: () => {
          this.dataLoaded = true;
          this.sweetAlert.showError('error',ErrorMessages.Delta_limit_valid);
        },
      });
  }

  maxDeltaLimit(): void {
    if (this.updatedDeltaLimit && this.updatedDeltaLimit.toString().length > 10) {
        this.sweetAlert.showError("error", ErrorMessages.Max_delta_limit);
        this.updatedDeltaLimit = parseFloat(this.updatedDeltaLimit.toString().substring(0, 10)); 
    }
  }

}
