import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/signin/signin.component';
import {
  FormsModule,
  NG_VALIDATORS,
  ReactiveFormsModule,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavigationComponent } from './components/Home/navigation/navigation.component';
import { HeaderComponent } from './components/Home/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { DeltalimitComponent } from './components/deltalimit/deltalimit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClientAccountGridComponent } from './components/client-account-grid/client-account-grid.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ManageclientsComponent } from './components/manageclients/manageclients.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ClientGeneralComponent } from './components/client-general/client-general.component';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { HttpRequestInterceptor } from './Interceptors/HttpRequestInterceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManageContactsComponent } from './components/manage-contacts/manage-contacts.component';
import { AddcontactComponent } from './components/addcontact/addcontact.component';
import { ClientcrmComponent } from './components/clientcrm/clientcrm.component';
import { ClientContactsComponent } from './components/client-contacts/client-contacts.component';
import { TabnavigationComponent } from './components/tabnavigation/tabnavigation.component';
import { AddressComponent } from './components/address-tab/address.component';
import { QuickQuoteComponent } from './components/quick-quote/quick-quote.component';
import { ClientSalesMarginTierComponent } from './components/client-sales-margin-tier/client-sales-margin-tier.component';
import { ValidateOtpComponent } from './components/validate-otp/validate-otp.component';
import { AddusersComponent } from './components/addUpdateUsers/addusers.component';
import { DealHistoryComponent } from './components/deal-history/deal-history.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ManageRolesComponent } from './components/manage-roles/manage-roles.component';

import { AddeditRoleComponent } from './components/addedit-role/addedit-role.component';
import { ClientaccountComponent } from './components/clientaccount/clientaccount.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { ManageSalesMarginComponent } from './components/manage-sales-margin/manage-sales-margin/manage-sales-margin.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ManageTenantsComponent } from './components/manage-tenants/manage-tenants.component';
import { ReferralPartnerComponent } from './components/Referral-Partner/referral-partner/referral-partner.component';
import { AddEditTenantComponent } from './components/add-edit-tenant/add-edit-tenant.component';
import { ManageCARPartnerComponent } from './components/manage-carpartner/manage-carpartner.component';
import { ReferralPrtnerTabnavigationComponent } from './components/referral-prtner-tabnavigation/referral-prtner-tabnavigation.component';
import { ReferralPartnerGeneralTabComponent } from './components/referral-partner-general-tab/referral-partner-general-tab.component';
import { DashboardComponent } from './components/Home/dashboard/dashboard.component';
import { HomeComponent } from './components/Home/home/home.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';

import { AddEditCarPartnerComponent } from './components/add-edit-car-partner/add-edit-car-partner.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ReferredClientComponent } from './components/referred-client/referred-client.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { OptionHedgeComponent } from './common/components/option-hedge/option-hedge.component';
import { SpotDealComponent } from './common/components/spot-deal/spot-deal.component';
import { MatSliderModule } from '@angular/material/slider';
import { RHSCollarComponent } from './common/components/rhscollar/rhscollar.component';
import { ForwardHedgeComponent } from './common/components/forward-hedge/forward-hedge.component';
import { ClientModule } from './client/client.module';
import { RHSknockOutCollarComponent } from './common/components/rhsknock-out-collar/rhsknock-out-collar.component';
import { FormatInputNumberDirective } from './common/directives/format-input-number.directive';
import { MinMaxValidatorDirective } from './common/directives/min-max-validator.directive';
import { VanillaOptionHedgeComponent } from './common/components/vanilla-option-hedge/vanilla-option-hedge.component';
import { LHSCollarComponent } from './common/components/lhscollar/lhscollar.component';
import { CommonCollarComponent } from './common/components/common-collar/common-collar.component';
import { CommonKOCollarComponent } from './common/components/common-ko-collar/common-ko-collar.component';
import { LHSKnockOutCollarComponent } from './common/components/lhsknock-out-collar/lhsknock-out-collar.component';
import { MinMaxBarrierDirective } from './common/directives/min-max-barrier.directive';
import { TwoDigitDecimaNumberDirective } from './common/directives/two-digit-decima-number.directive';
import { ViewBeneficiaryComponent } from './common/components/view-beneficiary/view-beneficiary.component';
import { ManageCurrencyPairsComponent } from './components/manage-currency-pairs/manage-currency-pairs.component';
import { CommonDealDetailComponent } from './common/components/common-deal-detail/common-deal-detail.component';
import { SpotDealDetailComponent } from './common/components/spot-deal-detail/spot-deal-detail.component';
import { ForwardDealDetailComponent } from './common/components/forward-deal-detail/forward-deal-detail.component';
import { AddBeneficiaryComponent } from './common/components/add-beneficiary/add-beneficiary.component';
import { AddEditCurrencyPairsComponent } from './components/add-edit-currency-pairs/add-edit-currency-pairs.component';
import { SummaryDealDetailsComponent } from './common/components/summary-deal-details/summary-deal-details.component';
import { VerifyEmailAdminComponent } from './components/verify-email-admin/verify-email-admin.component';
import { ManageCurrenciesComponent } from './components/manage-currencies/manage-currencies.component';
import { ManageCurrenciesService } from './services/ManageCurrencies/manage-currencies.service';
import { ManageCurrencyComponent } from './components/manage-currency/manage-currency.component';
import { ReportsService } from './services/Reports/reports.service';
import { ReportsComponent } from './common/components/reports/reports.component';
import { OpenDealSFComponent } from './common/components/open-deal-sf/open-deal-sf.component';
import { ConfirmationReportsComponent } from './common/components/confirmation-reports/confirmation-reports.component';
import { ConfirmationReportsService } from './common/services/confirmation-reports/confirmation-reports.service';
import { TestcomponentComponent } from './common/components/testComponent/testcomponent/testcomponent.component';
import { OnlyNumberDirective } from './common/directives/only-number.directive';
import { AllowCharacterSpaceDirective } from './common/directives/allow-character-space.directive';
import { AllowAlphanumericSpaceDirective } from './common/directives/allow-alphanumeric-space.directive';
import { ClientTradingComponent } from './components/client-trading/client-trading.component';
import { CommaSepraterDirective } from './directive/comma-seprater.directive';
import { NumberOnlyDirective } from './directive/number-only.directive';
import { NumberRangeDirective } from './directive/number-range.directive';
import { AttachmentsTabComponent } from './components/attachments-tab/attachments-tab.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AttachmentsTabService } from './services/CRMAttachmentsTab/attachments-tab.service';
import { AcceptEmailIdsModalComponent } from './common/components/accept-email-ids-modal/accept-email-ids-modal.component';
import { MatDialogModule, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle, } from '@angular/material/dialog';
import { NineDigitDirective } from './directive/nine-digit.directive';
import { ElevenDigitDirective } from './directive/eleven-digit.directive';
import { BankingTabComponent } from './components/banking-tab/banking-tab.component';
import { AddEditBankingTabComponent } from './components/banking-tab/add-edit-banking-tab/add-edit-banking-tab.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClientNoteTabComponent } from './components/client-note-tab/client-note-tab.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlphaNumericDirective } from './directive/alpha-numeric.directive';
import { PhoneNumberDirective } from './directive/phone-number.directive';
import { ClientBeneficiaryComponent } from './components/client-beneficiary/client-beneficiary.component';
import { DecimalNumberDirective } from './directives/decimal-number.directive';
import { FourDigitDecimalNumberDirective } from './directive/four-digit-decimal-number.directive';

import { PercentageDirective } from './directive/percentage.directive';
import { DecimalnumberDirective } from './directive/decimalnumber.directive';
import { BeneficiaryPercentDirective } from './directive/beneficiary-percent.directive';
import { AuditLogComponent } from './components/auditLog/audit-log.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, provideMomentDateAdapter} from '@angular/material-moment-adapter';
import { MY_DATE_FORMAT } from './global/global.constant';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    NavigationComponent,
    HeaderComponent,
    DeltalimitComponent,
    ClientAccountGridComponent,
    ClientGeneralComponent,
    ManageclientsComponent,
    ManageContactsComponent,
    AddcontactComponent,
    ClientcrmComponent,
    ClientContactsComponent,
    TabnavigationComponent,
    AddressComponent,
    QuickQuoteComponent,
    ClientSalesMarginTierComponent,
    ValidateOtpComponent,
    AddusersComponent,
    DealHistoryComponent,
    ManageRolesComponent,
    AddeditRoleComponent,
    ClientaccountComponent,
    ManageUsersComponent,
    ManageSalesMarginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ManageTenantsComponent,
    ReferralPartnerComponent,
    AddEditTenantComponent,
    ManageCARPartnerComponent,
    ReferralPrtnerTabnavigationComponent,
    ReferralPartnerGeneralTabComponent,
    DashboardComponent,
    HomeComponent,
    AddEditCarPartnerComponent,
    ReferredClientComponent,
    NotAuthorizedComponent,
    OptionHedgeComponent,
    SpotDealComponent,
    RHSCollarComponent,
    ForwardHedgeComponent,
    RHSknockOutCollarComponent,
    FormatInputNumberDirective,
    MinMaxValidatorDirective,
    VanillaOptionHedgeComponent,
    LHSCollarComponent,
    CommonCollarComponent,
    CommonKOCollarComponent,
    LHSKnockOutCollarComponent,
    MinMaxBarrierDirective,
    ClientNoteTabComponent,
    TwoDigitDecimaNumberDirective,
    FourDigitDecimalNumberDirective,
    ViewBeneficiaryComponent,
    ManageCurrencyPairsComponent,
    CommonDealDetailComponent,
    SpotDealDetailComponent,
    ForwardDealDetailComponent,
    AddBeneficiaryComponent,
    AddEditCurrencyPairsComponent,
    SummaryDealDetailsComponent,
    VerifyEmailAdminComponent,
    ManageCurrenciesComponent,
    ManageCurrencyComponent,
    ReportsComponent,
    OpenDealSFComponent,
    ConfirmationReportsComponent,
    TestcomponentComponent,
    OnlyNumberDirective,
    AllowCharacterSpaceDirective,
    AllowAlphanumericSpaceDirective,
    ClientTradingComponent,
    CommaSepraterDirective,
    NumberOnlyDirective,
    NumberRangeDirective,
    AttachmentsTabComponent,
    BankingTabComponent,
    UploadFileComponent,
    NineDigitDirective,
    ElevenDigitDirective,
    AcceptEmailIdsModalComponent,
    AddEditBankingTabComponent,
    AlphaNumericDirective, 
    PhoneNumberDirective,
    ClientBeneficiaryComponent, DecimalNumberDirective,
    PercentageDirective,
    DecimalnumberDirective,
    BeneficiaryPercentDirective,
    AuditLogComponent    
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatTooltipModule,
    MatPaginatorModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    HttpClientModule,
    NgSelectModule,
    MatRadioModule,
    MatRadioModule,
    MatFormFieldModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    CdkDropList,
    CdkDrag,
    NgSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MatCardModule,
    NgIdleKeepaliveModule.forRoot(),
    MatSliderModule,
    ClientModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatSlideToggleModule    
  ],
  providers: [
    ManageCurrenciesService,
    ReportsService,
    ConfirmationReportsService,
    AttachmentsTabService,
    provideClientHydration(),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
