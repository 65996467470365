
<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="new-login-wrapper">
    <div class="login">
        <div class="login-left" style="background-image: url('../../../assets/login-bg.png')">
        </div>
        <div class="login-right">
            <div *ngIf="validToken else elseBlock">
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form ">

                    <em><img src="../../../assets/Navinci BlackBG Logo.png" alt=""></em>
                    <div class="form-group" >
                        <label for="username">Username</label>
                        <div [ngClass]=" {'newTooltip expand has-error':form.controls['UserName'].invalid && (form.controls['UserName'].dirty || form.controls['UserPassword'].touched)}" datatitle="Username is required">
                            <input formControlName="UserName" id="username" type="text"  placeholder="Username" class="form-control">

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <div class="showhide" [ngClass]=" {'newTooltip expand has-error':form.controls['UserPassword'].invalid && (form.controls['UserPassword'].dirty || form.controls['UserPassword'].touched)}" datatitle="Password is required">
                            <input formControlName="UserPassword" id="password" [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                            placeholder="Password">
                            <div class="eye-icon">
                                <span>
                                  <img [src]="fieldTextType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                    class="bi"
                                    (click)="toggleFieldTextType()"
                                  >
                                </span>
                              </div>
                        </div>
                        
                    </div>
                    <div class="forgot-block">
                        <div class="custom-checkbox">

                            <input type="checkbox" formControlName="Remember" class="checkbox-input">
                            <span class="checkbox-label">Remember Me</span>
                        </div>
                        <a routerLink="/forgotpassword" class="forgot-link">Forgot Password?</a>
                    </div>

                    <input type='submit' value='Login' title="Login" class="btn btn-success" id="button"
                        [disabled]="form.invalid" />

                    <div *ngIf="Error" class="alert alert-danger" style="margin-top: 10px;">{{this.errormsg}}
                    </div>
                </form>
            </div>
            <ng-template #elseBlock>
                <p>Link has been expired or it is invalid</p>
                <p>                   
                    <a  (click)="regenerateLink()" class="underline"  >Click here</a> 
                    to regenerate the link.
                  </p>
            </ng-template>
        </div>
    </div>
</div>


