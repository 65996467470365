<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            {{loadingMessage}}...
        </div>
    </div>
</div>
<div class="compress-inputs-page">
    <div class="tab-content">
        <!-- General Body -->
        <div class="tab-pane active" id="CARPartnerGeneral">
            <div class="tab-body-wrapper">
                <div class="title">
                    <h3>&nbsp;</h3>

                    <a class="btn btn-success" (click)="openModalTierDetails()">
                        View Tier Details
                    </a>
                </div>
                <div class="custom__container">
                    <div class="table-responsive">
                        <h3>Sales Margin Tier</h3>
                        <table class="table custom-grid">
                            <thead>
                                <tr class="Row123">
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="0">Currency Pair<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="1">Tier<span class="ts-sord"></span> </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="2">Spot(%)<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="3">Forward(%)<span class="ts-sord"></span> </a>
                                    </th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="4">Predelivery(%)<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="5">Extension(%)<span class="ts-sord"></span> </a>
                                    </th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="6">Options(%)<span class="ts-sord"></span>
                                        </a></th>

                                    <th *ngIf="canAddEdit" style="width: 10%;"><a class="sort  active  ts-asc"
                                            unselectable="on" data-i="8">Action<span class="ts-sord"></span>
                                        </a></th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of paginatedTierData">
                                    <td class="wrapword">{{ row.currencyPairFormed }}</td>
                                    <td class="wrapword">{{ row.smTierID==null ? "-":row.tierName}}</td>
                                    <td class="wrapword">{{ row.spot }}</td>
                                    <td class="wrapword">{{ row.forward }}</td>
                                    <td class="wrapword">{{ row.predelivery }}</td>
                                    <td class="wrapword">{{ row.extension }}</td>
                                    <td class="wrapword">{{ row.options}}</td>


                                    <td *ngIf="canAddEdit">
                                        <div class="action-btns">
                                            <a (click)="openModalEdit(row)" title="Edit"><img
                                                    src="../../../assets/edit.svg" alt=""></a>
                                        </div>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-paginator #paginator (page)="handleTierPageEvent($event)" [length]="TierDataLength" [pageSize]="tierPageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="tierPageIndex" aria-label="Select page">
                    </mat-paginator>
                    <div class="table-responsive">
                        <h3 class="mt-3">Sales Margin Threshold</h3>
                        <table class="table custom-grid">
                            <thead>
                                <tr class="Row123">
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="0">Currency Pair<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="1">Tier<span class="ts-sord"></span> </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="2">Spot(%)<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="3">Forward(%)<span class="ts-sord"></span> </a>
                                    </th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="4">Predelivery(%)<span class="ts-sord"></span>
                                        </a></th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="5">Extension(%)<span class="ts-sord"></span> </a>
                                    </th>
                                    <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                            data-i="6">Options(%)<span class="ts-sord"></span>
                                        </a></th>

                                    <th *ngIf="canAddEdit" style="width: 10%;"><a class="sort  active  ts-asc"
                                            unselectable="on" data-i="8">Action<span class="ts-sord"></span>
                                        </a></th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of paginatedThresholdData">
                                    <td class="wrapword">{{ row.currencyPairFormed }}</td>
                                    <td class="wrapword">{{ row.smTierID==null ? "-":row.tierName}}</td>
                                    <td class="wrapword">{{ row.spotMarginThreshold }}</td>
                                    <td class="wrapword">{{ row.forwardMarginThreshold }}</td>
                                    <td class="wrapword">{{ row.predeliveryMarginThreshold }}</td>
                                    <td class="wrapword">{{ row.extensionMarginThreshold }}</td>
                                    <td class="wrapword">{{ row.optionsMarginThreshold}}</td>


                                    <td *ngIf="canAddEdit">
                                        <div class="action-btns">
                                            <a (click)="openModalEdit(row)" title="Edit"><img
                                                    src="../../../assets/edit.svg" alt=""></a>
                                        </div>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <mat-paginator #paginator (page)="handleThresholdPageEvent($event)" [length]="ThresholdDataLength" [pageSize]="thresholdPageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="thresholdPageIndex" aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>


    <div class="modal secondary backdrop" id="clientSalesTier-modal">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title" style="margin:15px;color:dodgerblue;">
                        <h4 style="font-size: 26px;">Update Sales Margin Tier and Threshold</h4>
                    </div>

                </div>

                <form class="form" [formGroup]="clientSalesMarginForm" #f>
                    <input type="text" formControlName="id" [hidden]="true">
                    <div class="card">
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="currency">Currency Pair</label>
                                <input type="text" id="currency" class="form-control"
                                    [value]="selectedRow?.currencyPairFormed" readonly="readonly">
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="tier">Tier Applied</label>
                                <input type="text" id="tier" class="form-control"
                                    [value]="selectedRow?.smTierID==null ? '-':selectedRow?.smTierID"
                                    readonly="readonly">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="newtier">Select New Tier</label>
                                <ng-select class="w-100" [items]="NewtierList" (change)="onChangeOfTier()"
                                    bindLabel="tierName" bindValue="id" formControlName="smTierID" [searchable]="true"
                                    [closeOnSelect]="true"></ng-select>
                            </div>
                            <div class="col-6"></div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="spot">Spots Margin (%)</label>
                                <input type="text" id="spot" class="form-control" formControlName="spot" appFourDigitDecimalNumber
                                    [value]="selectedRow?.spot" [readOnly]="!(clientSalesMarginForm.value.smTierID==7)">
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="spotMarginThreshold">Spots Margin Threshold (%)</label>
                                <input (keyup)="checkValidation('spot','spotMarginThreshold')" type="text" id="spotMarginThreshold" class="form-control" formControlName="spotMarginThreshold"
                                    [value]="selectedRow?.spotMarginThreshold" appFourDigitDecimalNumber
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="forward">Forwards Margin (%)</label>
                                <input type="text" id="forward" class="form-control" formControlName="forward" appFourDigitDecimalNumber
                                    [value]="selectedRow?.forward" [readOnly]="!(clientSalesMarginForm.value.smTierID==7)"
                                >
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="forwardMarginThreshold">Forwards Margin Threshold (%)</label>
                                <input (keyup)="checkValidation('forward','forwardMarginThreshold')" type="text" id="forwardMarginThreshold" class="form-control" formControlName="forwardMarginThreshold"
                                    [value]="selectedRow?.forwardMarginThreshold" appFourDigitDecimalNumber
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="Predelivery">Predelivery Margin (%)</label>
                                <input type="text" id="Predelivery" class="form-control" formControlName="predelivery" appFourDigitDecimalNumber 
                                    [value]="selectedRow?.predelivery" readOnly
                                >
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="predeliveryMarginThreshold">Predelivery Margin Threshold (%)</label>
                                <input (keyup)="checkValidation('predelivery','predeliveryMarginThreshold')" type="text" id="predeliveryMarginThreshold" class="form-control" formControlName="predeliveryMarginThreshold"
                                    [value]="selectedRow?.predeliveryMarginThreshold" appFourDigitDecimalNumber readOnly
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="Extension">Extension Margin (%)</label>
                                <input type="text" id="Extension" class="form-control" formControlName="extension" appFourDigitDecimalNumber
                                    [value]="selectedRow?.extension" readOnly
                                >
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="extensionMarginThreshold">Extension Margin Threshold (%)</label>
                                <input (keyup)="checkValidation('extension','extensionMarginThreshold')" type="text" id="extensionMarginThreshold" class="form-control" formControlName="extensionMarginThreshold"
                                    [value]="selectedRow?.extensionMarginThreshold" appFourDigitDecimalNumber readOnly
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="Options">Options Margin (%)</label>
                                <input type="text" id="Options" class="form-control" formControlName="options" appFourDigitDecimalNumber
                                    [value]="selectedRow?.options" readOnly
                                >
                            </div>
                            <div class="col-6 form-group label-inline">
                                <label class="strong" for="optionsMarginThreshold">Options Margin Threshold (%)</label>
                                <input (keyup)="checkValidation('options','optionsMarginThreshold')" type="text" id="optionsMarginThreshold" class="form-control" formControlName="optionsMarginThreshold"
                                    [value]="selectedRow?.optionsMarginThreshold" appFourDigitDecimalNumber readOnly
                                > 
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="inline-element d-block text-center">
                                <button type="button" (click)="closeEdit()" class="btn btn-secondary">Return</button>

                                <button type="button" (click)="onSave()" class="btn btn-success">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Second Modal for View Tier Details -->
    <div class="modal secondary backdrop" id="viewTierDetails-modal">
        <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable" style="max-width: 618px;">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="title" style="margin:15px;color:dodgerblue;">
                        <h4 style="font-size: 26px;">Sales Margin Tiers</h4>
                    </div>

                </div>
                <div class="table-responsive modal-body">


                    <table class="table custom-grid">

                        <thead>
                            <tr class="Row123">

                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="1">Tier<span class="ts-sord"></span> </a></th>
                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="2">Spot(%)<span class="ts-sord"></span>
                                    </a></th>
                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="3">Forward(%)<span class="ts-sord"></span> </a>
                                </th>
                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="4">Predelivery(%)<span class="ts-sord"></span>
                                    </a></th>
                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="5">Extension(%)<span class="ts-sord"></span> </a>
                                </th>
                                <th style="width: 10%;"><a class="sort  active  ts-asc" unselectable="on"
                                        data-i="6">Options(%)<span class="ts-sord"></span>
                                    </a></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of SalesMarginTierData">
                                <td class="wrapword">{{ row.tierName }}</td>

                                <td class="wrapword">{{ row.spot }}</td>
                                <td class="wrapword">{{ row.forward }}</td>
                                <td class="wrapword">{{ row.predelivery }}</td>
                                <td class="wrapword">{{ row.extension }}</td>
                                <td class="wrapword">{{row.options}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-12">
                        <div class="inline-element d-block text-center">
                            <button type="button" (click)="closeModalTierDetails()"
                                class="btn btn-secondary">Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>