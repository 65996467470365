<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">{{ loadingMessage }}...</div>
  </div>
</div>
<div>
  <div class="modal-header">
    <h4 class="modal-title">&nbsp;</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
      <img src="../../../assets/cross.svg" alt="">
    </button>
  </div>
  <form [formGroup]="bankForm" (ngSubmit)="submit()">
    <mat-dialog-content class="mat-typography bankingModel">
      <div class="div">
        <h6 class="heading-color">Banking and Settlement Information</h6>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Bank Name <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['bankName'].touched) && bankForm.controls['bankName'].invalid }"
                datatitle="Bank Name Is Required">
                <input 
                  matInput 
                  type="text" 
                  class="form-control" 
                  formControlName="bankName" 
                  required
                  [maxlength]="bankNameMaxLength"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Account Name <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['accountName'].touched) && bankForm.controls['accountName'].invalid }"
                datatitle="Account Name Is Required">
                <input matInput type="text" [maxlength]="accountNameMaxLength" class="form-control" formControlName="accountName" required />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>BSB No. <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['bsbNo'].touched) && bankForm.controls['bsbNo'].invalid }"
                datatitle="BSB No. Is Required and should follow the pattern XXX-XXX">
                <input matInput type="text" [maxlength]="bsbNoMaxLength" class="form-control" formControlName="bsbNo" required />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Account Number<em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['accountNo'].touched) && bankForm.controls['accountNo'].invalid }"
                datatitle="Account Number Is Required">
                <input matInput type="text" [maxlength]="accountNoMaxLength" class="form-control" formControlName="accountNo" required />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Currency Account <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['currencyAccountID'].touched) && bankForm.controls['currencyAccountID'].invalid }"
                datatitle="Currency Account Is Required">
                <ng-select
                  class="dropdown errorSelect" 
                  [items]="currencyAccountList"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [multiple]="false"
                  bindLabel="currencyName"
                  bindValue="currencyID"
                  placeholder="Select"
                  style="width: 100%"
                  formControlName="currencyAccountID">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Settlement Method <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['settlementMethodID'].touched) && bankForm.controls['settlementMethodID'].invalid }"
                datatitle="Settlement Method Is Required">
                <ng-select
                  class="dropdown errorSelect" 
                  [items]="settlementMethodList"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [multiple]="false"
                  bindLabel="settlementMethodName"
                  bindValue="settlementMethodID"
                  placeholder="Select"
                  style="width: 100%"
                  formControlName="settlementMethodID">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline d-flex">
              <label class="strong mr-2">Is Active</label>
              <div>
                <label class="switch">
                  <input [(ngModel)]="bankIsActive" type="checkbox" formControlName="isActive" />
                  <span class="slider round" style="width: 46px"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h6 class="heading-color">Bank Address Information</h6>
      <hr>
      <div class="address-block">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Street Address <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['streetAddress'].touched) && bankForm.controls['streetAddress'].invalid }"
                datatitle="Street Address Is Required">
                <input matInput type="text" [maxlength]="streetAddressMaxLength" class="form-control" formControlName="streetAddress" required />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Suburb <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['suburb'].touched) && bankForm.controls['suburb'].invalid }"
                datatitle="Suburb Is Required">
                <input matInput type="text" [maxlength]="suburbMaxLength" class="form-control" formControlName="suburb" required />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>State <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['state'].touched) && bankForm.controls['state'].invalid }"
                datatitle="State Is Required">
                <input matInput type="text" [maxlength]="stateMaxLength" class="form-control" formControlName="state" required />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Post code <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['postCode'].touched) && bankForm.controls['postCode'].invalid }"
                datatitle="Post Code Is Required">
                <input matInput type="text" class="form-control" [maxlength]="postCodeMaxLength" formControlName="postCode" required />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group label-inline">
              <label>Country <em style="color:red;">*</em></label>
              <div class="showhide"
                [ngClass]="{'newTooltip expand has-error': (bankForm.controls['countryID'].touched) && bankForm.controls['countryID'].invalid }"
                datatitle="Country Is Required">
                <ng-select
                  class="dropdown errorSelect" 
                  [items]="countryList"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [multiple]="false"
                  bindLabel="countryName"
                  bindValue="countryID"
                  placeholder="Select"
                  style="width: 100%"
                  formControlName="countryID">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div class="inline-element d-block text-center pb-3">
      <button class="btn btn-success mr-2" type="submit" *ngIf="!bankingTabData.rowClicked">Save</button>
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-secondary"
        data-dismiss="modal"
        id="aCancelClientBank"
        *ngIf="!bankingTabData.rowClicked"
      >Cancel</button>
      <button class="btn btn-success mr-2" type="button" *ngIf="bankingTabData.rowClicked" (click)="closeModal()">Back</button>
    </div>
  </form>
</div>
