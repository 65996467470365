import { Component, ElementRef, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ClientsalestierService } from '../../services/ClientSalesTier/clientsalestier.service';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { data } from 'jquery';
import Swal from 'sweetalert2';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { SalesMargin, SalesMarginList, SalesMarginThresholdData, SalesMarginTierData, SalesMarginTierList } from './models/client-sales-margin-tier.model';

@Component({
  selector: 'app-client-sales-margin-tier',
  templateUrl: './client-sales-margin-tier.component.html',
  styleUrl: './client-sales-margin-tier.component.css',
})
export class ClientSalesMarginTierComponent implements OnInit {
  tabPermission: any;
  canAddEdit: boolean = false;
  constructor(
    private elementRef: ElementRef,
    private clientSalesService: ClientsalestierService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alert: SweetalertService,
    private encryptPermission: EncryptRolePermissionService
  ) {}
  clientID!: number;
  pageSizeOptions = [5, 10, 20];
  tierPageSize: number = 5;
  thresholdPageSize: number = 5;
  tierPageIndex: number = 0;
  thresholdPageIndex: number = 0;
  TierDataLength: number = 0;
  ThresholdDataLength: number = 0;
  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  showFirstLastButtons: boolean = true;
  paginatedTierData: SalesMarginTierData[] = [];
  paginatedThresholdData: SalesMarginThresholdData[] = [];
  pageEvent: PageEvent | undefined;
  disabled = false;
  selectedRow: any;
  clientSalesMarginTierData: SalesMarginTierData[] = [];
  clientSalesMarginThresholdData: SalesMarginThresholdData[] = [];
  SalesMarginTierData: SalesMarginTierList[] = [];
  NewtierList: SalesMarginTierList[] = [];
  dataLoaded: boolean = false;
  loadingMessage = 'Loading';

  clientSalesMarginForm: FormGroup = this.fb.group({
    id: [],
    clientID: [],
    smTierID: [],
    spot: [],
    forward: [],
    predelivery: [],
    extension: [],
    options: [],
    spotMarginThreshold: [],
    forwardMarginThreshold: [],
    predeliveryMarginThreshold: [],
    extensionMarginThreshold: [],
    optionsMarginThreshold: [],
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
    });
    const tabPermissions =
      this.encryptPermission.getDecryptedPermissions('roleNgp').tabPermissions;
    this.tabPermission = tabPermissions.find(
      (x: { tabID: number }) => x.tabID == 6
    );

    this.canAddEdit = this.tabPermission.canInsert ? true : false;
    this.getClientSalesMarginData();
    this.viewSalesMarginTierData();
    this.TierDataLength = this.clientSalesMarginTierData?.length;
    this.ThresholdDataLength = this.clientSalesMarginThresholdData?.length;
    this.paginatedTierData = this.clientSalesMarginTierData?.slice(
      0,
      this.tierPageSize
    );
    this.paginatedThresholdData = this.clientSalesMarginThresholdData?.slice(
      0,
      this.thresholdPageSize
    );
  }

  checkValidation = (fieldName:string = '',thresholdField: string = ''): boolean=> {
    const value: number = this.clientSalesMarginForm.get(fieldName)?.value;
    const threshold: number = this.clientSalesMarginForm.get(thresholdField)?.value;

    if (value != null && threshold != null && threshold > value / 2) {
      const formattedValueField: string = this.formatFieldName(fieldName);
      const formattedThresholdField: string = this.formatFieldName(thresholdField);
      this.alert.showError('error', `${formattedThresholdField} cannot exceed half of the ${formattedValueField} value.`);
      this.clientSalesMarginForm.controls[thresholdField].setValue(0);
      return true;
    }
    else {
      return false;
    }
  }

  formatFieldName = (fieldName: string): string => {
    return fieldName.replace(/([A-Z])/g, ' $1').toLowerCase();
  }

  getClientSalesMarginData = (): void => {
    this.dataLoaded = false;
    this.clientSalesService.ClientSalesTierData(this.clientID).subscribe({
      next: (result: SalesMarginList) => {
        this.clientSalesMarginTierData = result.salesMarginTier;
        this.clientSalesMarginThresholdData = result.salesMarginThreshold;
        this.TierDataLength = this.clientSalesMarginTierData?.length;
        this.ThresholdDataLength = this.clientSalesMarginThresholdData?.length;
        this.paginatedTierData = this.clientSalesMarginTierData?.slice(0,this.tierPageSize);
        this.paginatedThresholdData =
          this.clientSalesMarginThresholdData?.slice(0, this.thresholdPageSize);
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  viewSalesMarginTierData = (): void => {
    this.dataLoaded = false;
    this.clientSalesService.SalesMarginTierData().subscribe({
      next: (result: SalesMarginTierList[]) => {
        this.SalesMarginTierData = result;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  handleTierPageEvent = (e: PageEvent): void => {
    this.pageEvent = e;
    this.tierPageSize = e.pageSize;
    this.tierPageIndex = e.pageIndex;
    let startIndex: number = 0;
    let endIndex: number = this.tierPageSize;
    if (this.tierPageIndex != 0) {
      startIndex = this.tierPageIndex * this.tierPageSize;
      endIndex = startIndex + this.tierPageSize;
    }
    this.paginatedTierData = this.clientSalesMarginTierData.slice(
      startIndex,
      endIndex
    );
  }

  handleThresholdPageEvent = (e: PageEvent): void => {
    this.pageEvent = e;
    this.thresholdPageSize = e.pageSize;
    this.thresholdPageIndex = e.pageIndex;
    let startIndex: number = 0;
    let endIndex: number = this.thresholdPageSize;
    if (this.thresholdPageIndex != 0) {
      startIndex = this.thresholdPageIndex * this.thresholdPageSize;
      endIndex = startIndex + this.thresholdPageSize;
    }
    this.paginatedThresholdData = this.clientSalesMarginThresholdData.slice(
      startIndex,
      endIndex
    );
  }

  openModalEdit = (row?: any): void => {
    this.clientSalesService.newTierListData().subscribe({
      next: (result: SalesMarginTierList[]) => {
        this.NewtierList = result;
      },
    });
    this.dataLoaded = false;
    this.clientSalesService
      .SalesMarginTierAndThresholdData(row.id, row.clientID)
      .subscribe({
        next: (result: SalesMargin) => {
          this.selectedRow = result;
          // this.NewtierList = result;
          this.clientSalesMarginForm.patchValue(this.selectedRow);
          this.dataLoaded = true;
        },
      });

    const modal = this.elementRef.nativeElement.querySelector(
      '#clientSalesTier-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      // Show the modal
      modal.classList.add('show');
      modal.style.display = 'block';

      // Append the backdrop behind the modal
      document.body.appendChild(backdrop);

      // Set focus on the modal
      modal.focus();
    }
  }

  closeEdit = (): void => {
    this.loadingMessage = 'Loading';
    const modal = this.elementRef.nativeElement.querySelector(
      '#clientSalesTier-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  onChangeOfTier = (): void => {
    const dataForTier = this.NewtierList.find(
      (tier: { id: number }) =>
        tier.id === this.clientSalesMarginForm.value.smTierID
    );
    if(dataForTier){
      this.clientSalesMarginForm.patchValue({
        smTierID: dataForTier.id,
        spot: dataForTier.spot,
        forward: dataForTier.forward,
        predelivery: dataForTier.predelivery,
        extension: dataForTier.extension,
        options: dataForTier.options,
        spotMarginThreshold: 0,
        forwardMarginThreshold: 0,
        predeliveryMarginThreshold: 0,
        extensionMarginThreshold: 0,
        optionsMarginThreshold: 0,
      });
    }
  }

  openModalTierDetails = (): void => {
    const modal = this.elementRef.nativeElement.querySelector(
      '#viewTierDetails-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      // Show the modal
      modal.classList.add('show');
      modal.style.display = 'block';

      // Append the backdrop behind the modal
      document.body.appendChild(backdrop);

      // Set focus on the modal
      modal.focus();
    }
  }

  closeModalTierDetails = (): void => {
    const modal = this.elementRef.nativeElement.querySelector(
      '#viewTierDetails-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';
  }

  onSave = (): void => {
    var payload: SalesMargin = this.clientSalesMarginForm.value;
    this.loadingMessage = 'Updating';
    this.dataLoaded = false;
    this.clientSalesService.updateSalesMarginTier(payload).subscribe({
      next: () => {
        this.dataLoaded = true;
        this.alert.fireAlert('Updated').then(() => {
          this.closeEdit();
          this.getClientSalesMarginData();
        });
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }
}
