<div *ngIf="ACCOUNT_DATA.length>0; else notFound"  class="table-responsive">


  <table class="table custom-grid">
    <colgroup>
      <col style="width:1%">
      <col style="width:1%">
      <col style="width:1%">
      <col style="width:1%">
      <col style="width:4%">

    </colgroup>
    <thead>
      <tr class="Row123">
        <th>
          <a (click)="sortTable('currencyName')" class="sort  active  ts-asc" unselectable="on"
            data-i="0">Currency<span class="ts-sord"></span> 
          </a>
        </th>
        <th>
          <a (click)="sortTable('description')" class="sort  active  ts-asc" unselectable="on"
            data-i="0">Account<span class="ts-sord"></span> 
          </a>
        </th>
        <th>
          <a (click)="sortTable('isActive')" class="sort  active  ts-asc" unselectable="on"
            data-i="0">Status<span class="ts-sord"></span> 
          </a>
        </th>
        <th>
          <a (click)="sortTable('accountTypeName')" class="sort  active  ts-asc" unselectable="on" data-i="1">Account
            Type<span class="ts-sord"></span> 
          </a>
        </th>
        <th>
          <a (click)="sortTable('balance')" class="sort  active  ts-asc" unselectable="on" data-i="2">Balance<span
              class="ts-sord"></span> 
          </a>
        </th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of paginatedData">
        <td>{{ row.currencyName }}</td>
        <td>{{ row.description }}</td>
        <td >{{ row.isActive ? 'Active' : 'Inactive' }}</td>
        <td >{{ row.accountTypeName }}</td>
        <td>{{ row.balance | number: '1.2-2'}}</td>


      </tr>
    </tbody>
  </table>
  


  <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
    [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="pageIndex"
    aria-label="Select page">
  </mat-paginator>
</div>
<ng-template #notFound>
  <div  class="alert alert-info">
    <span>No record found</span>
  </div>
</ng-template>
