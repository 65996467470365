import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CARPartner } from '../../../components/add-edit-car-partner/models/CarPartner.model';
import { Tenant } from '../../../components/add-edit-tenant/models/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiUrl = environment.apiUrl;
  loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router,

    @Inject(DOCUMENT) private document: Document
  ) {}
  clientLogin(payload: any): Observable<any> {
    let url = this.apiUrl + 'ContactAccount/login';
    return this.http.post<any>(url, payload).pipe(
      tap((response: any) => {
        if (response.status) {
          this.document.defaultView?.localStorage.setItem(
            'sessionTimeout',
            response.sessionTimeoutInMinutes
          );
          this.loggedIn.next(true);
        }
      })
    );
  }
  isAuthenticated() {
    const token = this.document.defaultView?.localStorage?.getItem('jwtToken');

    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp * 1000;
      if (new Date().getTime() < expiry) {
        return true;
      }
    }

    this.document.defaultView?.localStorage?.removeItem('jwtToken');
    return false;
  }
  logout(): Observable<any> {
    this.loggedIn.next(false);

    this.router.navigate(['/Client']);
    let url = this.apiUrl + 'ContactAccount/logout';
    return this.http.post<any>(url, null);
  }
  regenerateURL(hashtoken: string): Observable<any> {
    let payload = {
      token: hashtoken,
    };
    let url = this.apiUrl + 'ContactAccount/regenerateLinkNewContact';
    return this.http.post<any>(url, payload);
  }
  validateToken(token: any): Observable<any> {
    let url =
      this.apiUrl +
      'ContactAccount/validatetoken?token=' +
      encodeURIComponent(token);
    return this.http.post(url, null);
  }
  get isLoggedIn(): Observable<boolean> {
    if (this.document.defaultView?.localStorage?.getItem('jwtToken')) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }
  refreshToken() {
    const previousToken =
      this.document.defaultView?.localStorage?.getItem('jwtToken');

    const expiry = JSON.parse(atob(previousToken!.split('.')[1])).exp * 1000;

    const sessionTimeout: number =
      +this.document.defaultView?.localStorage?.getItem('sessionTimeout')!;
    const earlyTimeoutForRefresh = expiry - sessionTimeout * 60000;
    if (new Date().getTime() >= earlyTimeoutForRefresh) {
      const params = new HttpParams().set('token', previousToken!);
      this.http
        .post(this.apiUrl + 'ContactAccount/RefreshToken', null, {
          params,
          responseType: 'text',
        })
        .subscribe({
          next: (response: any) => {
            this.document.defaultView?.localStorage?.setItem(
              'jwtToken',
              response
            );
            this.loggedIn.next(true);
          },
          error: () => {
            Swal.fire({
              icon: 'error',
              title: 'Error Occurrred!!',
              html: 'some error occurred please login again.',
              showConfirmButton: true,
            });
            this.logout();
          },
        });
    }
  }

  getTenant = (tenantId: number): Observable<Tenant> => {
    return this.http.get<Tenant>(this.apiUrl + `Tenant/${tenantId}`);
  }

  getCarPartner = (carPartnerId: number): Observable<CARPartner> => {
    return this.http.get<CARPartner>(this.apiUrl + "CARPartner?carPartnerID="+carPartnerId);
  }
}
