<div *ngIf="isLoading">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>
<div *ngIf="!isNavinciUser" class="card notAccesscss margincss">
    <h1 class="mt-19">Sorry, you don't have permission to view this page.</h1> <br>
    <p>Please contact our support to review your role permissions.</p>
</div>
<div *ngIf="isNavinciUser" [ngClass]="{'collapseNav':!isNavBarOpen}">
    <div class="inner user-page">
        <div *ngIf="canInsert > 0" class="title">
            <h3>&nbsp;</h3>

            <a  (click)="addManageCurrencies()" id="btnClientAddNew" class="btn btn-success">
                <em><img src="../../../assets/plus.svg" alt=""></em>
                Add
            </a>
        </div>

        <div class="card maindiv">
            <form>
                <div class="row search-criteria">
                    <div class="col">
                        <div class="form-group ">
                            <label class="textstyle">Currency Name</label>
                            <input 
                                name="currencyName" 
                                [(ngModel)]="searchParams.currencyName" 
                                type="text"  
                                class="form-control"
                                (keydown.enter)="searchManageCurrencies()"
                            >
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group ">
                            <label class="textstyle">Currency Abbreviation</label>
                            <input 
                                name="currencyAbbreviation" 
                                [(ngModel)]="searchParams.currencyAbbreviation" 
                                type="text" 
                                class="form-control"
                                (keydown.enter)="searchManageCurrencies()"
                            >
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <div class="inline-element" style="padding-top:25px;display: flex;">
                            <a (click)="searchManageCurrencies()" class="btn btn-success" id="btnSearch">Search</a>
                            <a (click)="resetSearchParams()" class="btn btn-secondary" >Reset</a>
                        </div>
                    </div>
                </div>
            </form>
            <div class="nth-last-child-2-center">
            </div>
            <div *ngIf="manageCurrenciesList.length>0;else notFound" class="GridClass custom-container">
                <div class="table-responsive">
                    <table class="table custom-grid">
                        <thead>
                            <tr>
                                <th>Currency Name</th>
                                <th>Currency Abbreviation</th>
                                <th>Currency Symbol</th>
                                <th>Country Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td>{{row.currencyName}}</td>
                                <td>{{row.currencyAbbreviation}}</td>
                                <td>{{row.currencySymbol}}</td>
                                <td>{{row.countryName}}</td>
                                <td>
                                    <div *ngIf="canUpdate > 0" class="action-btns"><a style="cursor: pointer;" (click)="openEditManageCurrency(row.currencyID)" title="Edit"><img
                                                src="../../../assets/edit.svg" alt=""></a></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-paginator #paginator (page)="handlePageEvent($event)"  [length]="manageCurrenciesListLength" [pageSize]="pageSize"
                [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"    
                [pageIndex]="pageIndex" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>

    </div>
</div>
<ng-template #notFound>
    <div class="alert alert-info">
        <span>No record found</span>
    </div>
</ng-template>