import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ManageClientService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getClientList(clientSearchForm: any): Observable<any> {
    if (
      clientSearchForm.selectedtenantCARPartnerName === null ||
      clientSearchForm.selectedtenantCARPartnerName === undefined ||
      clientSearchForm.selectedtenantCARPartnerName === ''
    ) {
      clientSearchForm.selectedtenantCARPartnerName = "";
    }
    if (
      clientSearchForm.selectedClientType === null ||
      clientSearchForm.selectedClientType === undefined ||
      clientSearchForm.selectedClientType === ''
    ) {
      clientSearchForm.selectedClientType = "";
    }
    if (
      clientSearchForm.selectedstatus === undefined ||
      clientSearchForm.selectedstatus === '' ||
      clientSearchForm.selectedstatus === 'All'
    ) {
      clientSearchForm.selectedstatus = 'both';
    }
    if (
      clientSearchForm.searchString === null ||
      clientSearchForm.searchString === undefined ||
      clientSearchForm.searchString === ''
    ) {
      clientSearchForm.searchString = '';
    }
   
   
    let payload = {
      tenantCarPartner: clientSearchForm.selectedtenantCARPartnerName,
      clientType: clientSearchForm.selectedClientType,
      status: clientSearchForm.selectedstatus,
      searchString: clientSearchForm.searchString,
    };
    let url = this.apiUrl + 'Client/search';
    return this.http.post<any>(url, payload);
  }
  getDropDownLists(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    let url = this.apiUrl + 'Client/manageclients/getlists';
    return this.http.get<any>(url, httpOptions);
  }

  changeStaus(id: number, Activestatus: boolean) {
    let payload = {
      clientID: id,
      status: Activestatus,
    };
    let url = this.apiUrl + 'Client/updatestatus';
    return this.http.patch<any>(url, payload);
  }
}
