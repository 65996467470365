import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accept-email-ids-modal',
  templateUrl: './accept-email-ids-modal.component.html',
  styleUrl: './accept-email-ids-modal.component.css'
})
export class AcceptEmailIdsModalComponent {

  emailString: string = "";

  constructor(
    private dialogRef: MatDialogRef<AcceptEmailIdsModalComponent>,
  ) {  }

  submit = (): void => {
    this.dialogRef.close({ emailIds: this.emailString });
  }

  closeModal = (): void => {
    this.dialogRef.close();
  }
}
