import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { clientContactData } from '../../components/client-contacts/models/client-contacts.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ClientContactsService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getClientContact(contactID: number): Observable<clientContactData> {
    return this.http.get<clientContactData>(this.apiUrl + 'Client/contact/' + contactID);
  }

  getClientContacts(clientID: number) {
    return this.http.get(this.apiUrl + 'Client/contact/GridList/' + clientID);
  }

  getClientContactLists(clientID: number) {
    return this.http.get(this.apiUrl + 'Client/contacts/lists/' + clientID);
  }

  updateClientContact(payload: any) {
    return this.http.patch(this.apiUrl + 'Client/contacts', payload);
  }

  addClientContact(payload: any) {
    return this.http.post(this.apiUrl + 'Client/contacts/add', payload);
  }
}
