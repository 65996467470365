<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">Loading...</div>
    </div>
  </div>
  
  <mat-sidenav-container id="sidenavContainer" fullscreen>
    <mat-sidenav
      [mode]="mode"
      class="sidebarTag"
      #sidenav
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
      id="sidenav"
      [class.menu-open]="isMenubarOpen"
      [class.menu-close]="!isMenubarOpen"
      opened
    >
      <nav class="main-navigation">
        <div class="stickyImage">
          <img
            [src]="sidePanelLogo"
            class="logo centerLogo"
            [ngClass]="{
              hidden:
                (mode == 'over' && !openSidebarover) ||
                (!isMenubarOpen && mode == 'side')
            }"
          />
          <div
            class="centerLogo"
            style="height: 72px"
            [ngClass]="{
              hidden: !(
                (mode == 'over' && !openSidebarover) ||
                (!isMenubarOpen && mode == 'side')
              )
            }"
          >
            <span>&nbsp;</span>
          </div>
  
          <!-- <img
            src="../../../../assets/Content/Navigation/Logo2.png"
            class="logo"
            [ngClass]="{
                hidden: !(
                  (mode == 'over' && !openSidebarover) ||
                  (!isMenubarOpen && mode == 'side')
                )
              }"
          /> -->
        </div>
  
        <ul class="nav-menu maxheight" style="height: 100%" id="myscrl">
          <ng-container *ngFor="let item of tempDataValue; index as i">
            <ng-container *ngIf="item.singleElement; else expandedMenu">
              <!-- [ngClass]="{ selectedMenu: activationFlag[item.elements.pageID] }" -->
              <!-- [ngClass]="{hidden: viewFlags[item.elements.pageID]}" -->
              <!-- [class.hidden]="viewFlags[item.elements.pageID]" -->
              <li
                id="{{ item.elements.pageID }}"
                [class.selectedMenu]="activationFlag[item.elements.pageID]"              
              >
                <a
                  (click)="
                    activationRedirection(item.elements.pageID, -1, 'direct', false, '')
                  "
                  title="{{ item.elements.moduleName }}"
                >
                  <em
                    ><img
                      class="png"
                      src="../../../../assets/{{item.elements.moduleID}}.svg"
                      alt=""
                  /></em>
                  <span
                    [ngClass]="{
                      hidden:
                        (mode == 'over' && !openSidebarover) ||
                        (!isMenubarOpen && mode == 'side')
                    }"
                    >{{ item.elements.moduleName }}</span
                  >
                </a>
              </li>
            </ng-container>
  
            <!-- <ng-container *ngIf="!item.singleElement"> -->
            <ng-template #expandedMenu>
              <!-- hidden: viewFlags[item.elements.pageID], -->
              <li
                id="{{ item.id }}"
                class="has-menu"
                [ngClass]="{                        
                          active: isMenuOpen[item.id]
                        }"
              >
                <a title="{{ item.ParentName }}" (click)="toggleMenu(item.id)">
                  <em
                    ><img
                      class="png"
                      src="../../../../assets/{{item.ParentId}}.svg"
                      alt=""
                  /></em>
                  <span
                    [ngClass]="{
                      hidden:
                        (mode == 'over' && !openSidebarover) ||
                        (!isMenubarOpen && mode == 'side')
                    }"
                    >{{ item.ParentName }}</span
                  >
                  <em
                    class="arrow-icon"
                    [class.rotate]="isMenuOpen[item.id]"
                    [ngClass]="{
                      hidden:
                        (mode == 'over' && !openSidebarover) ||
                        (!isMenubarOpen && mode == 'side')
                    }"
                  ></em>
                </a>
                <ul
                  [ngClass]="{ expanded: isMenuOpen[item.id] }"
                  [ngClass]="{
                    hidden:
                      (mode == 'over' && !openSidebarover) ||
                      (!isMenubarOpen && mode == 'side')
                  }"
                >
                  <ng-container *ngFor="let data of item.elements">
                    <li
                      [ngClass]="{ hidden: viewFlags[data.pageID] }"
                      [ngClass]="{ selectedMenu: activationFlag[data.pageID] }"
                    >
                      <a
                        (click)="
                          activationRedirection(data.pageID, item.id, 'direct', false, '')
                        "
                        title="{{ data.pageName }}"
                        id="{{ data.pageID }}"
                        >{{ data.pageName }}</a
                      >
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </nav>
    </mat-sidenav>
  
    <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
      <!-- Header Component -->
      <mat-toolbar id="mainToolbar" class="header stickyHeader">
        <div class="flexbetween">  
          <em
            class="hamburger"
            [class.hidden]="hidehamburger"
            id="toolbarMenu"
            (click)="onToolbarMenuToggle()"
          >
            <span [ngClass]="{ active: !isMenubarOpen }"></span>
            <span [ngClass]="{ active: !isMenubarOpen }"></span>
            <span [ngClass]="{ active: !isMenubarOpen }"></span>
          </em>
        </div>
  
        <div class="flexbetween">
          <div class="right-header">
            <div class="theme-button"></div>
          </div>
  
          <!-- <div
            class="user-profile-block dropdown"
            style="cursor: pointer"
            [ngClass]="{ show: isProfileMenuOpen }"
          >
            <a
              title="User Profile"
              data-toggle="dropdown"
              (click)="toggleProfileMenu()"
            >
              <em class="dp-dark"
                ><img
                  src="../../../../assets/Content/Admin/images/profile-pic.svg"
              /></em>
              <span class="username">{{ Username }}</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              [ngClass]="{ expanded: isProfileMenuOpen }"
              [style.display]="isProfileMenuOpen ? 'block' : 'none'"
            >
              <a (click)="changePassword()" class="dropdown-item dropdown-font"
                ><span class="icon"
                  ><img
                    src="../../../../assets/Content/Admin/images/change-password.svg"
                    alt="Logout" /></span
                >Change Password</a
              >
              <a class="dropdown-item dropdown-font"
                ><span class="icon"
                  ><img
                    src="../../../../assets/Content/Admin/images/logout-icon.svg"
                    alt="Logout" /></span
                >Logout</a
              >
            </div>
          </div> -->


          <div class="btn-group profileBlock">
            <a role="button" class="dropdown-toggle profileBar" title="User Profile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <em class="dp-dark"
                ><img
                  src="../../../../assets/profile-pic.svg"
              /></em>
              <span class="username" style="margin-left: 5px;">
                {{ Username }}
                <img src="../../../../../assets/drop-arrow.svg" class="drop-arrow" alt="">
              </span>
            </a>
            
            <div class="dropdown-menu dropdown-menu-right">
              <button (click)="changePassword()" class="dropdown-item dropdown-font" type="button">
                <div class="icon-profile">
                    <img src="../../../../assets/change-password.svg" alt="Change Password" />
                    Change Password

                    <!-- <span><img src="../../../../assets/Content/Admin/images/change-password.svg" alt="Change Password" /></span>
                    <span>Change Password</span>   -->
                </div>
              </button>

              <button class="dropdown-item dropdown-font" (click)="logout()" type="button">
                <span class="icon-profile">
                    <img src="../../../../assets/logout-icon.svg" alt="Logout" width="18px" height="18px" />
                    Logout
                </span>
              </button>
            </div>
          </div>
        </div>
      </mat-toolbar>
  
      <!-- Main Content -->
      <div class="maincontent" *ngIf="dataLoaded">
        <router-outlet></router-outlet>
      </div>
  
      <!-- Website Footer -->
      <!-- <mat-toolbar class="copyright"> 
            <div>
                Copyright © {{currentYear}} Navigate Global Payments Pty Ltd and/or its affiliates. All rights reserved.
            </div>
        </mat-toolbar> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
  