import { HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ManageClientService } from '../../services/MangeClientservices/manage-client.service';
import { Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { CurrencyPairService } from '../../services/CurrencyPair/currency-pair.service';

@Component({
  selector: 'app-manage-currency-pairs',
  templateUrl: './manage-currency-pairs.component.html',
  styleUrl: './manage-currency-pairs.component.css'
})
export class ManageCurrencyPairsComponent {

  dataLoaded: boolean = false;
  searchString: string = '';
  activeClient: boolean = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  TenanCarPartner: string = '';
  isActive: string = 'Active';
  tenantCARs: any[] = [];
  selectedTenantCAR: any;
  clientTypes: any[] = [];
  selectedClientType: any;
  selectedStatus: string = 'All';
  tenantID: string = '';
  sortedColumn: string = '';
  isAscending: boolean = true;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  currencyData: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  
  isNavBarOpen: boolean = true;
  hideAddButton = true;
  disableActiveSlider = false;
  currencyPairForm: FormGroup = this.fb.group({
    baseTermCurrency: [''],
    currencyPairFormed: [''],
    
    status: ['All'],
  });
  constructor(
    private fb: FormBuilder,
    private currencyService: CurrencyPairService,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService
  ) {
    
  }

  ngOnInit(): void {
  
    this.search();
    this.length = this.currencyData.length;
    this.paginatedData = this.currencyData.slice(0, this.pageSize);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.currencyData.slice(startIndex, endIndex);
  }

  search() {
    this.dataLoaded = false;
    this.currencyService.searchCurrencyPair(this.currencyPairForm.value).subscribe({
      next: (data: any) => {        
        this.currencyData = data;
        this.dataLoaded = true;
        this.length = this.currencyData.length;
        this.paginatedData = this.currencyData.slice(0, this.pageSize);
      },
      error: (error:any) => {
        this.dataLoaded = true;
      },
    });
  }

  

  sort(columnName: string): void {
    this.currencyData.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }
  onRadioChange(status: string) {
    this.selectedStatus = status;
  }
  toggleSwitch(currency: any) {
   
      this.dataLoaded = false;

      currency.isActive = !currency.isActive;
      this.currencyService
        .changeStatus(currency.currencyPairID, currency.isActive)
        .subscribe({
          next: () => {
            this.dataLoaded = true;
          },
          error: () => {
            this.dataLoaded = true;
          },
        });
   
  }
  
  sortTable(columnName: string) {
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.currencyData.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.currencyData.slice(startIndex, endIndex);
  }
  reset() {
    this.currencyPairForm.reset();
    this.search();
  }
  addCurrencyPair() {
   
    this.router.navigate(['/Admin/addeditcurrencypair']);
  }
  editCurrencyPair(id: number) {
  
    this.router.navigate([
      '/Admin/addeditcurrencypair',
      id,
      
    ]);
  }

}
