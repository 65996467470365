<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="new-login-wrapper">
    <div class="login">
        <div class="login-left" style="background-image: url('../../../assets/login-bg.png')">
        </div>
        <div class="login-right">
            <div *ngIf="!successFull;else emailTemplate">
                <form class="form" [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">

                    <em><img src="../../../assets/Navinci BlackBG Logo.png" alt=""></em>
                    <div class="form-group">
                        <label for="username">Username</label>
                        <div class="showhide"
                            [ngClass]=" {'newTooltip expand has-error': (forgetPasswordForm.controls['username'].touched) && (forgetPasswordForm.controls['username'].invalid) }"
                            datatitle="Username is required">

                            <input type="text" class="form-control" formControlName="username" placeholder="Username"
                                id="username">

                        </div>
                    </div>
                    <div class="form-group">

                        <div #recaptchaContainer></div>
                        <br />

                    </div>

                    <div class="forgot-block pad-bot-0">
                        <button type='submit' value='Submit' title="Submit"
                            [disabled]="!isValid || forgetPasswordForm.invalid " class="btn btn-success">Submit</button>
                        <a routerLink="/admin" class="forgot-link" title="Back to Login"><i><img
                                    src="../../../assets/back-green.svg" alt=""></i> Back
                            to Login</a>
                    </div>

                    <div *ngIf="error" class="alert alert-danger" style="margin-top: 10px;">
                        Some Error Occurred. Please Try Again.
                    </div>

                </form>
            </div>
            <ng-template #emailTemplate>
                
                <p>Reset Password Link has bees sent to registered mail.</p>
                <a style="text-align: center;" routerLink="/Admin" class="forgot-link" title="Back to Login"> Back
                to Login</a>
                
            </ng-template>
        </div>
    </div>
</div>