import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SigninService } from '../../services/signin/signin.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';

export const authGuard: CanActivateFn = (route, state) => {
  const signInService = inject(SigninService);
  const router = inject(Router);
  const rolePermission = inject(EncryptRolePermissionService);

  if (signInService.isAuthenticated()) {
    const pagePermission = rolePermission
      .getDecryptedPermissions('roleNgp')
      ?.modulePermission?.find(
        (permission: { pageID: number }) =>
          permission.pageID === route.data['pageID']
      );
    if (pagePermission && !pagePermission.canView) {
      return router.createUrlTree(['/', 'Unauthorized']);
    }
    return true;
  }
  return router.createUrlTree(['/', 'admin']);
};
