import { Component, ElementRef } from '@angular/core';
import {
  DefaultPageOptions,
  DefaultPageSize,
  SpinnerMessage,
} from '../../global/global.constant';
import { ClientBeneficiaryResponse } from './models/ClientBeneficiary.model';
import { ClientBeneficiaryService } from '../../services/ClientBeneficiary/client-beneficiary.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { RolePermissonService } from '../../services/Permission/role-permisson.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-client-beneficiary',
  templateUrl: './client-beneficiary.component.html',
  styleUrl: './client-beneficiary.component.css',
})
export class ClientBeneficiaryComponent {
  dataLoaded: boolean = false;
  loadingMessage = SpinnerMessage.Loading;
  clientBeneficiaryData: ClientBeneficiaryResponse[] = [];
  paginatedData: ClientBeneficiaryResponse[] = [];
  roleID: number = 0;
  clientMode: boolean = false;
  clientID: number = 0;
  length: number = 0;

  pageEvent: PageEvent | undefined;
  pageSizeOptions: number[] = DefaultPageOptions;
  pageSize: number = DefaultPageSize;
  pageIndex: number = 0;
  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  disabled: boolean = false;
  showFirstLastButtons = true;

  constructor(
    private clientBeneficiaryService: ClientBeneficiaryService,
    private route: ActivatedRoute,    
    private encryptPermission: EncryptRolePermissionService
  ) {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      this.roleID =
        this.encryptPermission.getDecryptedPermissions('roleNgp').roleID;
    }
  }

  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.clientMode = false;
    } else if (user === 'Client') {
      this.clientMode = true;
    }

    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
    });

    this.getClientBeneficiaryData();
    this.dataLoaded = false;
    this.length = this.clientBeneficiaryData.length;
    this.paginatedData = this.clientBeneficiaryData.slice(0, this.pageSize);
  }

  getClientBeneficiaryData() {
    this.dataLoaded = false;

    if (this.clientMode == false) {
      this.clientBeneficiaryService
        .getClientBeneficiariesAdmin(this.clientID)
        .subscribe({
          next: (result: any) => {
            this.clientBeneficiaryData = result;
            this.length = this.clientBeneficiaryData.length;
            this.paginatedData = this.clientBeneficiaryData.slice(
              0,
              this.pageSize
            );
            this.dataLoaded = true;
          },
          error: () => {
            this.dataLoaded = true;
          },
        });
    } else if (this.clientMode == true) {
      this.clientBeneficiaryService.getClientBeneficiariesClient().subscribe({
        next: (result: any) => {
          this.clientBeneficiaryData = result;
          this.length = this.clientBeneficiaryData.length;
          this.paginatedData = this.clientBeneficiaryData.slice(
            0,
            this.pageSize
          );
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clientBeneficiaryData.slice(startIndex, endIndex);
  }

  statusClass(row: any): string {
    var statusClass: string = '';
    switch (row.status) {
      case 'Inactive': {
        statusClass = 'badge-danger';
        break;
      }
      case 'Active': {
        statusClass = 'badge-success';
        break;
      }
    }
    return statusClass;
  }

  sortTable(str: string) {}
}
