import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditRoleService } from '../../services/addeditRole/add-edit-role.service';
import { SweetalertService } from '../../../services/SweetAlert/sweetalert.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-roles',
  templateUrl: './add-edit-roles.component.html',
  styleUrl: './add-edit-roles.component.css'
})
export class AddEditRolesComponent {
  rolePermissionForm:FormGroup;
  distinctModule: any[] = [];  
  responsedata: any[] = [];
  modulePermissions: any[] = [];
  selectedModulePermission: any[] = [];
  checkbox: boolean = true;
  editMode: boolean = false;
  dataLoaded: boolean = true;
  roleID: number=0;
  loadingMessage: string='Loading..';
  canViewCounter:number=0;
  canEditCounter:number=0;
  canAddCounter:number=0;
  isActive :boolean=false;
  errormsg: any;
  constructor(   private fb: FormBuilder,
    private route: ActivatedRoute,
    private addEditRoleservice: AddEditRoleService,
    private router: Router,
    private alert:SweetalertService){
      this.rolePermissionForm = this.fb.group({
        isActive:false,
        roleName: [null, [Validators.required]],
        description: '',   
        chkEditAll: false,
        chkAddAll: false,
        chkViewAll: false,
        
      });
  }
  ngOnInit(): void {
    this.dataLoaded=false;
    this.getPages().then(()=>{
      
      this.route.paramMap.subscribe((parameterMap) => {
        const roleId = +parameterMap.get('roleID')!;
        if (roleId) {
          this.roleID = roleId;
          this.editMode = true;
          this.getroledetails(roleId);
        }
        else{
          this.dataLoaded=true;
        }
      });
    });   
    
  }
  getroledetails(roleID: number) {
    this.dataLoaded = false;
    this.addEditRoleservice.getRoledetails(roleID).subscribe({
      next: (data: any) => {
        this.modulePermissions = data.modulePermissions;       
        this.rolePermissionForm.get('roleName')?.setValue(data.roleName);
        this.rolePermissionForm.get('description')?.setValue(data.description);   
        this.rolePermissionForm.get('isActive')?.setValue(data.isActive);    
        this.isActive=data.isActive;     
        this.setRoleDetails();
        this.dataLoaded = true;
      },
      error: (error) => {
      },
    });
  }
  getPages(){
    return new Promise<void>((resolve,reject)=>{
      this.addEditRoleservice.getPages().subscribe({
        next: (data: any) => {
          this.responsedata = data;
          this.responsedata.sort((a, b) => a.clientModuleID - b.clientModuleID);
          this.responsedata.forEach((module, index) => {
            this.rolePermissionForm.addControl(
              `chkView_${index}`,
              new FormControl(false)            
            );
            this.rolePermissionForm.addControl(
              `chkAdd_${index}`,
              new FormControl(false)
            );
            this.rolePermissionForm.addControl(
              `chkEdit_${index}`,
              new FormControl(false)
            );
          });
          data.forEach((item: { clientModuleName: any }) => {
            if (!this.distinctModule.includes(item.clientModuleName)) {
              this.distinctModule.push(item.clientModuleName);
            }
          });
          resolve();
        },
        error: (error) => {
        },
      });
     
    

    })
  
  }
  setRoleDetails() {
    this.modulePermissions.forEach((module, index) => {
      let Moduleindex = this.responsedata.findIndex(
        (item) => item.clientPageID === module.clientPageID
      );
      if (module.canView) {
        let controlername = 'chkView_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
        this.canViewCounter++;
      }
      if (module.canInsert) {
        let controlername = 'chkAdd_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
        this.canAddCounter++;
      }
      if (module.canUpdate) {
        let controlername = 'chkEdit_' + Moduleindex;
        this.rolePermissionForm.get(controlername)?.setValue(true);
        this.canEditCounter++;
      }
    });   
    if(this.canViewCounter==this.responsedata.length){
      this.rolePermissionForm.get('chkViewAll')?.setValue(true);
    }
    if(this.canEditCounter==this.responsedata.length){
      this.rolePermissionForm.get('chkEditAll')?.setValue(true);
    }
    if(this.canAddCounter==this.responsedata.length){
      this.rolePermissionForm.get('chkAddAll')?.setValue(true);
    }

  }
  checkAllViews(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkView_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
   
    this.changeAllAdd();
    this.changeAllEdit();
  }
  checkAllAdd(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkAdd_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
   
    this.changeAllView();
    
  }
  checkAllEdit(checkboxEvent: Event) {
    const isChecked = (checkboxEvent.target as HTMLInputElement).checked;

    this.responsedata.forEach((module, index) => {
      let controlername = 'chkEdit_' + index;
      this.rolePermissionForm.get(controlername)?.setValue(isChecked);
    });
    this.changeAllView();
  }
  changeAllView(){
    if(this.rolePermissionForm.get('chkAddAll')?.value || this.rolePermissionForm.get('chkEditAll')?.value){
      this.rolePermissionForm.get('chkViewAll')?.setValue(true);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkView_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(true);
      });     
    }
  }
  

  changeAllAdd(){
    if(!this.rolePermissionForm.get('chkViewAll')?.value){
      this.rolePermissionForm.get('chkAddAll')?.setValue(false);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkAdd_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
    
    }

  }
  changeAllEdit(){
    if(!this.rolePermissionForm.get('chkViewAll')?.value){
      this.rolePermissionForm.get('chkEditAll')?.setValue(false);
      this.responsedata.forEach((module, index) => {
        let controlername = 'chkEdit_' + index;
        this.rolePermissionForm.get(controlername)?.setValue(false);
      });
     
    }}
    save() {
      if(this.rolePermissionForm.valid){
      this.selectedModulePermission = [];
      
      this.responsedata.forEach((module, index) => {
        let canViewValue = 0;
        let canInsertValue = 0;
        let canUpdateValue = 0;
        if (this.rolePermissionForm.get('chkView_' + index)?.value) {
          canViewValue = 1;
        }
        if (this.rolePermissionForm.get('chkAdd_' + index)?.value) {
          canInsertValue = 1;
        }
        if (this.rolePermissionForm.get('chkEdit_' + index)?.value) {
          canUpdateValue = 1;
        }
        if (canViewValue || canInsertValue || canUpdateValue) {
          this.selectedModulePermission.push({
            clientPageID: module.clientPageID,
            canView: canViewValue,
            canInsert: canInsertValue,
            canUpdate: canUpdateValue,
            canDelete:1
          });
        }
      });
      
      if (this.editMode) {
        this.dataLoaded=false;
        this.loadingMessage='Updating...';
        let payload = {
          clientRoleID: this.roleID,
          roleName: this.rolePermissionForm.get('roleName')?.value,
          description: this.rolePermissionForm.get('description')?.value,
          isActive: this.rolePermissionForm.get('isActive')?.value,
          modulePermissions: this.selectedModulePermission       
        };
        this.addEditRoleservice.updateRole(payload).subscribe({
          next: (data: any) => {
            this.dataLoaded=true;
            this.alert.fireAlert('Updated').then(()=>{
              this.back();
            })
          },
          error: (error) => {
            this.dataLoaded=true;   
            if (error.error && Array.isArray(error.error)) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = 'Edit role failed';
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });         
          },
        });
      } else {
        this.dataLoaded=false;
        this.loadingMessage='Saving...';
        let payload = {   
          roleName: this.rolePermissionForm.get('roleName')?.value,
          description: this.rolePermissionForm.get('description')?.value,
          isActive: this.rolePermissionForm.get('isActive')?.value,
          modulePermissions: this.selectedModulePermission,
        }; 
     
        this.addEditRoleservice.addRole(payload).subscribe({
          next: (data: any) => {
            this.dataLoaded=true;
            this.alert.fireAlert('Saved').then(()=>{
              this.back();
            })
          },
          error: (error) => {
            this.dataLoaded=true;
            if (error.error && Array.isArray(error.error)) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = 'Add role failed';
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });

          },
        });
      }
    }else {
      this.markFormGroupTouched(this.rolePermissionForm);
    }
    }
    markFormGroupTouched(formGroup: FormGroup) {
      Object.values(formGroup.controls).forEach((control) => {
        control.markAsTouched();
  
        if (control instanceof FormGroup) {
          this.markFormGroupTouched(control);
        }
      });
    }
    changeView(index: number) {
      if (
        this.rolePermissionForm.get('chkAdd_' + index)?.value ||
        this.rolePermissionForm.get('chkEdit_' + index)?.value
      ) {
        this.rolePermissionForm.get('chkView_' + index)?.setValue(true);
      }
    }
    getLength(id: string): number {
      return this.responsedata.filter((module) => module.clientModuleName === id)
        .length;
    }
    back() {
      this.router.navigate(['/Client/userroles']);
    }
    changeModuleAddEdit(index: number) {
      if (
        this.rolePermissionForm.get('chkView_' + index)?.value === false &&
        this.rolePermissionForm.get('chkAdd_' + index)?.value
      ) {
        this.rolePermissionForm.get('chkAdd_' + index)?.setValue(false);
      }
      if (
        this.rolePermissionForm.get('chkEdit_' + index)?.value &&
        !this.rolePermissionForm.get('chkView_' + index)?.value
      ) {
        this.rolePermissionForm.get('chkEdit_' + index)?.setValue(false);
      }
    }
    cancel() {
      if (this.editMode) {
        this.getroledetails(this.roleID);
        this.setRoleDetails;
      } else {
        this.rolePermissionForm.reset();
      }
    }
}
