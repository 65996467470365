import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SummaryDealDeatilsService } from '../../services/summaryDealDetails/summary-deal-deatils.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-summary-deal-details',
  templateUrl: './summary-deal-details.component.html',
  styleUrl: './summary-deal-details.component.css',
})
export class SummaryDealDetailsComponent {
  paymentPayload: any = {};
  product: string = '';
  isAdmin: boolean = false;
  dataLoaded: boolean = false;
  tradeId: any;
  cuurencypair: any = '';
  direction: any;
  summarydata: any;
  emailString: string = '';
  errormsg: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,

    private summaryServices: SummaryDealDeatilsService,
    private elementRef: ElementRef
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      this.paymentPayload = navigation.extras.state['data'];
      this.isAdmin = navigation.extras.state['admin'];
      this.direction = navigation.extras.state['direction'];
      this.getPaymentdeatils();
    } else {
      this.getPaymentdeatils();
    }
  }
  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
    } else if (user === 'Client') {
      this.isAdmin = false;
    }
  }
  getPaymentdeatils() {
    this.summaryServices.getpaymentInfo(this.paymentPayload).subscribe({
      next: (data: any) => {
        this.summarydata = data;

        this.product = data.productName;
        this.product = this.product.toUpperCase();
        this.tradeId = data.tradeID;
        if (data.currencyPairName) this.cuurencypair = data.currencyPairName;
        this.dataLoaded = true;
      },
      error: (error) => {
      },
    });
  }
  openModal() {
    const modal = this.elementRef.nativeElement.querySelector(
      '#QuoteConfirmMail-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.appendChild(backdrop);
      modal.focus();
    }
  }

  close() {
    const modal = this.elementRef.nativeElement.querySelector(
      '#QuoteConfirmMail-modal'
    );
    modal.classList.remove('show');
    modal.style.display = 'none';
  }
  validateEmails(emailString: string): boolean {
    const emailRegex =
      /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}(?:, ?[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;
    return emailRegex.test(emailString);
  }
  submit() {
    if (this.validateEmails(this.emailString)) {
      let payload = {
        tradeID: this.tradeId,
        emailIDs: this.emailString,
      };
      this.summaryServices.sendEmail(payload).subscribe({
        next: (data: any) => {
          swal.fire({
            icon: 'success',
            title: 'Email sent sucessfully',
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          });
          this.close();
        },
        error: (error) => {
          if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
          } else {
            this.errormsg = 'Email sent failed';
          }
          swal.fire({
            icon: 'warning',
            title: this.errormsg,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          });
        },
      });
    } else {
      swal.fire({
        icon: 'warning',
        title: 'Please enter correct email',
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500,
      });
    }
  }
  back() {
    if (this.product == 'FORWARD') {
      this.isAdmin
        ? this.router.navigate(['/Admin/ForwardHedge'])
        : this.router.navigate(['Client/ForwardHedge']);
    } else
      this.isAdmin
        ? this.router.navigate(['/Admin/SpotDeal'])
        : this.router.navigate(['/Client/SpotDeal']);
  }
  home() {
    if (this.product == 'FORWARD')
      this.isAdmin
        ? this.router.navigate(['/Admin/ForwardHedge'])
        : this.router.navigate(['Client/ForwardHedge']);
    else
      this.isAdmin
        ? this.router.navigate(['/Admin/SpotDeal'])
        : this.router.navigate(['/Client/SpotDeal']);
  }
  print() {
    this.dataLoaded = false;
    this.summaryServices.downloadPdf(this.tradeId).subscribe({
      next: (data) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.target = '_blank';
        link.click();
        this.dataLoaded = true;
        link.onload = () => {
          window.open(downloadURL, '_blank');
        };
      },
    });
  }
  download() {
    this.summaryServices.downloadPdf(this.tradeId).subscribe({
      next: (data) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${this.tradeId}.pdf`;
        link.click();
      },
    });
  }
}
