import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { HomeComponent } from './components/Home/home/home.component';
import { ClientManagementComponent } from './components/client-management/client-management.component';
import { AddressComponent } from '../components/address-tab/address.component';
import { ClientContactsComponent } from '../components/client-contacts/client-contacts.component';
import { ClientaccountComponent } from '../components/clientaccount/clientaccount.component';
import { LoginComponent } from './components/login/login.component';
import { ValidateClientOtpComponent } from './components/validate-client-otp/validate-client-otp.component';
import { OptionHedgeComponent } from '../common/components/option-hedge/option-hedge.component';
import { RHSCollarComponent } from '../common/components/rhscollar/rhscollar.component';
import { LHSCollarComponent } from '../common/components/lhscollar/lhscollar.component';
import { LHSKnockOutCollarComponent } from '../common/components/lhsknock-out-collar/lhsknock-out-collar.component';
import { VanillaOptionHedgeComponent } from '../common/components/vanilla-option-hedge/vanilla-option-hedge.component';
import { RHSknockOutCollarComponent } from '../common/components/rhsknock-out-collar/rhsknock-out-collar.component';
import { ResetPasswardComponent } from './components/reset-passward/reset-passward.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SpotDealComponent } from '../common/components/spot-deal/spot-deal.component';
import { ViewBeneficiaryComponent } from '../common/components/view-beneficiary/view-beneficiary.component';

import { SpotDealDetailComponent } from '../common/components/spot-deal-detail/spot-deal-detail.component';
import { AddBeneficiaryComponent } from '../common/components/add-beneficiary/add-beneficiary.component';
import { ForwardDealDetailComponent } from '../common/components/forward-deal-detail/forward-deal-detail.component';
import { ForwardHedgeComponent } from '../common/components/forward-hedge/forward-hedge.component';
import { UserRolesComponent } from './components/user-roles/user-roles.component';

import { AddEditRolesComponent } from './components/add-edit-roles/add-edit-roles.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ReportsComponent } from '../common/components/reports/reports.component';
import { OpenDealSFComponent } from '../common/components/open-deal-sf/open-deal-sf.component';
import { ConfirmationReportsComponent } from '../common/components/confirmation-reports/confirmation-reports.component';
import { SummaryDealDetailsComponent } from '../common/components/summary-deal-details/summary-deal-details.component';

import { clientAuthGuard } from './Guard/auth.guard';
import { DashboardComponent } from '../components/Home/dashboard/dashboard.component';
import { IndexComponent } from './components/Dashboard/index/index.component';
import { TestcomponentComponent } from '../common/components/testComponent/testcomponent/testcomponent.component';
import { DealHistoryComponent } from '../components/deal-history/deal-history.component';
import { ClientBeneficiaryComponent } from '../components/client-beneficiary/client-beneficiary.component';


const routes: Routes = [
  {path: '', redirectTo: '/Client', pathMatch: 'full'},  
  {path:'Client',component:LoginComponent, data: { isAdmin: false }}, 
  {path:'Client/Account/ResetPassword/:tokenID',component:ResetPasswardComponent },
  {path:'Account/SignIn/:id',component:LoginComponent, data: { isAdmin: false }},
  {path:':urlCode/Account/SignIn/:id',component:LoginComponent, data: { isAdmin: false }},
  {path:'VerifyEmail/:id',component:VerifyEmailComponent},    
  {
    path: 'Client',
    component: HomeComponent,
    title: 'NaviHedge - Home Page', 
    canActivate: [clientAuthGuard],   
    canActivateChild: [clientAuthGuard],

    children: [
      {
        path:'accountbalances',component:ClientaccountComponent
      },
      {
        path:'addEditRole',
        component:AddEditRolesComponent
      },
      {
        path:'addEditRole/:roleID',
        component:AddEditRolesComponent
      },
      {
        path: 'Clientmanagement',
        component: ClientManagementComponent,

        children: [
          {
            path: '',
            component: AddressComponent,
            outlet: 'tabnavigation',
          },
        
          {
            path: 'clientcontacts/:ClientID',
            component: ClientContactsComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientaddress/:ClientID',
            component: AddressComponent,
            outlet: 'tabnavigation',
          },
          {
            path: 'clientBeneficiaries/:ClientID',
            component: ClientBeneficiaryComponent,
            outlet: 'tabnavigation',
          },
        ],
      },
      
      {
        path:'optionhedge',
        component:OptionHedgeComponent,
        title:'Navihedge'
      },
      {
        path:'RHSCollar',
        component:RHSCollarComponent
      },
      
      {
        path:'LHSCollar',
        component:LHSCollarComponent
      },
      {
        path:'RHSKnockOutCollar',
        component:RHSknockOutCollarComponent,
        title:'Navihedge'
      },
      {
        path:'Vanilla',
        component:VanillaOptionHedgeComponent,
        title:'Navihedge'
      },
      {
        path:'LHSKnockOutCollar',
        component:LHSKnockOutCollarComponent,
        title:'Navihedge'
      },
      {
        path:'changepassword',
        component:ChangePasswordComponent,
        title:'Navihedge'
      },
      {
        path: 'userprofile',
        component: UserProfileComponent,
        title: 'Navihedge'
      },
      {
        path: 'viewBeneficiaries',
        component: ViewBeneficiaryComponent,
        title: 'NaviHedge - View Beneficiaries',
        
      },
      {
        path: 'addBeneficiary',
        component: AddBeneficiaryComponent,
        title: 'NaviHedge - Add Beneficiay',
        
      },
      {
        path:'SpotDeal',
        component:SpotDealComponent,
        title:'Navihedge'
      },
      {
        path:'SpotDeal/DealDetail',
        component:SpotDealDetailComponent,
        title:'Navihedge',
        pathMatch:'full'
      },
      {
        path:'ForwardHedge',
        component:ForwardHedgeComponent,
        title:'Navihedge'
      },
      {
        path:'ForwardHedge/DealDetail',
        component:ForwardDealDetailComponent,
        title:'Navihedge',
        pathMatch:'full'
      },
      {
        path:'userroles',
        component:UserRolesComponent,
        title:'NaviHedge'
      },
      {
        path:'reports',
        component: ReportsComponent,
        title:'NaviHedge'
      },
      {
        path:'openDealSF',
        component:OpenDealSFComponent,
        title:'NaviHedge'
      },
      {
        path: 'confirmation-reports',
        component: ConfirmationReportsComponent,
        title: 'NaviHedge - Confirmation Reports'
      },
      {
        path:'SummaryDealDeatils',
        component:SummaryDealDetailsComponent       
        
      },
      {
        path:'Dashboard',
        component:IndexComponent
      },
      {
        path:'test',
        component:TestcomponentComponent       
      },
      {
        path:'dealhistory',
        component: DealHistoryComponent       
      }
      
    ],
  },
  {
    path:'validateclientotp',
    component:ValidateClientOtpComponent,
    
  },
  {
    path:'forgotclientpassword',
    component:ForgotPasswordComponent
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[provideRouter(routes,withComponentInputBinding())]
})
export class ClientRoutingModule {}
