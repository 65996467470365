import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { response } from 'express';
@Injectable({
  providedIn: 'root'
})
export class OptionHedgeService {
  apiUrl=environment.apiUrl;
  clientName!:Observable<string>;
  constructor(private http:HttpClient) { }

  getOptionHedgeRM(){
    return this.http.get(this.apiUrl+'Quote/GetRMs');
  }
  
  getClientsOfRM(managerID:number){
    return this.http.get(this.apiUrl+'Quote/GetClientsOfRM?managerID='+managerID);
  }

  getConactOfClient(clientID:number){
    return this.http.get(this.apiUrl+'OptionHedge/GetOptionHedgeLists?clientID='+clientID);
  }

  getProductListForClient(clientID:number){
    return this.http.get(this.apiUrl+'OptionHedge/GetOptionHedgeLists?clientID='+clientID);
  }
  
  
  
}
