import { Component, OnInit, Renderer2 } from '@angular/core';
import { Clients, CreatedByData, Products, TenantCars, Trade, TradeList, TradeSearchParams, TradeStatus, TradeStatusObject } from './models/confirmation-reports.models';
import { ConfirmationReportsService } from '../../services/confirmation-reports/confirmation-reports.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Swal from 'sweetalert2';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemConstant } from '../../../global/global.constant';
import { AdminInfo } from '../../../components/models/common.model';
import { ClientInfo } from '../../../client/models/client-info.model';

@UntilDestroy()
@Component({
  selector: 'app-confirmation-reports',
  templateUrl: './confirmation-reports.component.html',
  styleUrl: './confirmation-reports.component.css'
})
export class ConfirmationReportsComponent implements OnInit {
  tenantCarList: TenantCars[] = [];
  clientList: Clients[] = [];
  productList: Products[] = [];
  createdByList: CreatedByData[] = [];
  tradeStatusList: TradeStatus[] = [];
  isLoading: boolean = false;
  isInnerLoading: boolean = false;
  tenantCar: string = "";
  clientId: number | undefined = undefined;
  showFirstLastButtons = true;
  paginatedData: Trade[] = [];
  pageSizeOptions = [5, 10, 20];
  pageSize = 10;
  pageIndex = 1;
  pageEvent: any;
  showPageSizeOptions = true;
  tradeList: Trade[] = [];
  tradeListLength: number = 0;
  tenantCarName: string = "";
  isSearchSeactionEnabled: boolean = false;
  tradeNumber: number | undefined = undefined;
  clientUserClientId: number = 0;
  tradeStartDate: string = "";
  tradeEndDate: string = "";
  productValue: string = "";
  createdByValue: string = "";
  tradeStatus: string = "";
  isAdmin: boolean = false;
  isNavinciUser: boolean = false;
  searchParamsClientId: number | undefined = 0;

  constructor(
    private confiramtionReportService: ConfirmationReportsService,
    private encryptPermission: EncryptRolePermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.checkForUser();
  }

  ngOnInit(): void {
    this.dropdownTradeStatus();
  }

  private checkForUser = (): void => {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === SystemConstant.Admin) {
      this.isAdmin = true;
      this.checkForNavinciUser();    
    } 
    else if (user === SystemConstant.Client) {
      this.isAdmin = false;
      this.isSearchSeactionEnabled = true;

      const clientInfo: ClientInfo = this.encryptPermission.getDecryptedPermissions('usercrd');
      if (clientInfo) {
        this.dropdownProduct(clientInfo.clientID);
        this.dropdownCreatedBy(clientInfo.clientID);
        this.clientUserClientId = clientInfo.clientID;
        this.searchTradeData();
      }
    }
  }

  private checkForNavinciUser = (): void => {
    var savedLogin: AdminInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    if (savedLogin && savedLogin.userType == SystemConstant.Navinci) {
      this.isNavinciUser = true;
      this.dropdownTenantCar();
    }
    else {
      this.isNavinciUser = false;
      const clientInfo: ClientInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
      if (clientInfo) {
        this.tenantCarName =  clientInfo.tenantCARPartnerName;
        this.dropdownClient(clientInfo.tenantCARPartnerName);
      }
    }
  }

  private dropdownTenantCar = (): void => {
    this.isLoading = true;
    this.confiramtionReportService.getTenantList().pipe((untilDestroyed(this))).subscribe({
      next: (tenantData: TenantCars[] ) => {
        this.isLoading = false;
        this.tenantCarList = tenantData;
      },
      error: (response) => {
        this.isLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  tenant_carpartnerChanged = (event: TenantCars): void => {
    if (event) {
      this.tenantCarName = event.name
      this.dropdownClient(event.name);
    }
  }

  enableSearchSection = (): void => {
    if (this.isNavinciUser) {
      this.searchParamsClientId = this.clientId;
    }
    this.isSearchSeactionEnabled = true;
    this.dropdownProduct(this.clientId);
    this.dropdownCreatedBy(this.clientId);
    this.searchTradeData();
  }

  private dropdownClient = (tenantCARPartnerName: string): void => {
    this.isLoading = true;
    this.confiramtionReportService.getClientList(tenantCARPartnerName).pipe((untilDestroyed(this))).subscribe({
      next: (clientData: Clients[] ) => {
        this.isLoading = false;
        this.clientList = clientData;
      },
      error: (response) => {
        this.isLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  searchTradeData = (): void => {
    
    const params: TradeSearchParams = {
      tenantCarName: this.tenantCarName,
      clientID: this.isAdmin? this.clientId : this.clientUserClientId,     
      pageNumber: this.pageIndex,
      pageSize: this.pageSize
    }
    if (this.tradeNumber) {
      params.tradeNumber = this.tradeNumber;
    }
    if (this.tradeStartDate) {
      params.startDate = this.tradeStartDate;
    }
    if (this.tradeEndDate) {
      params.endDate = this.tradeEndDate;
    }
    if (this.productValue) {
      params.productID = this.productValue;
    }
    if (this.createdByValue) {
      params.createdBy = this.createdByValue;
    }
    if (this.tradeStatus) {
      params.tradeStatus = this.tradeStatus;
    }
    this.searchTradeAPICall(params);
  }

  searchTradeAPICall = (params: TradeSearchParams): void => {
    this.isInnerLoading = true;
    this.confiramtionReportService.searchTrade(this.isAdmin, params).pipe((untilDestroyed(this))).subscribe({
      next: (tradeData: TradeList ) => {
        if (tradeData && tradeData.trades && tradeData.trades.length > 0) {
          this.tradeList = tradeData.trades;
          this.paginatedData = this.tradeList.slice(0, this.pageSize);
          this.tradeListLength = tradeData.totalRecords;
          this.isInnerLoading = false;
        } else {
          this.isInnerLoading = false;
          this.tradeList = [];
          this.paginatedData = [];
          this.tradeListLength = 0;
        }
      },
      error: (response) => {
        this.isInnerLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageEvent = pageEvent;
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex == 0 ? 1 : pageEvent.pageIndex;
    this.searchTradeData();
  }

  resetSearchResult = (): void => {
    this.tradeNumber = undefined;
    this.tradeStartDate = "";
    this.tradeEndDate = "";
    this.productValue = "";
    this.createdByValue = "";
    this.tradeStatus = "";
    this.searchTradeData();
  }

  backToReportPage = (): void => {
    if (this.isAdmin) {
      this.router.navigate(['Admin/reports']);
    } else {
      this.router.navigate(['Client/reports']);
    }
  }

  private dropdownProduct = (clientId: number | undefined): void => {
    this.isLoading = true;
    this.confiramtionReportService.getProductList(clientId).pipe((untilDestroyed(this))).subscribe({
      next: (productData: Products[] ) => {
        this.isLoading = false;
        this.productList = productData;
      },
      error: (response) => {
        this.isLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  private dropdownCreatedBy = (clientId: number | undefined): void => {
    this.isLoading = true;
    this.confiramtionReportService.getCreatedByList(clientId).pipe((untilDestroyed(this))).subscribe({
      next: (createdByData: CreatedByData[] ) => {
        this.isLoading = false;
        this.createdByList = createdByData;
      },
      error: (response) => {
        this.isLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  private dropdownTradeStatus = (): void => {
    this.isLoading = true;
    this.confiramtionReportService.getTradeStatusList().pipe((untilDestroyed(this))).subscribe({
      next: (tradeStatusResponse: TradeStatusObject ) => {
        this.isLoading = false;
        this.tradeStatusList = tradeStatusResponse.tradeStatuses;
      },
      error: (response) => {
        this.isLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }
  

  downloadDocument = (tradeID: number): void => {
    this.isInnerLoading = true;
    this.confiramtionReportService.dealHistoryGetTradeConfo(tradeID).subscribe({
      next: (data) => {
        var blob = new Blob([data], { type: 'application/pdf' });

        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${tradeID}.pdf`;
        link.click();
        this.isInnerLoading = false;
      },
      error: (response) => {
        this.isInnerLoading = false;
        if (response && response.error && response.error[0] && response.error[0].description) {
          Swal.fire({
            icon: 'warning',
            title: response.error[0].description+".",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
        if (response && response.status == 404) {
          Swal.fire({
            icon: 'warning',
            title: "Document not found.",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      }
    });
  }

  closeDropdwon = (event: any): void => {
    if (event.key === SystemConstant.Enter) { 
      this.openCloseDropdwon("0");
    } 
    else {
      this.openCloseDropdwon("1");
    }
  }

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
      if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
        this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
      }
  }
}