import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ValidateClientOtpService } from '../../services/validate-client-otp.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-validate-client-otp',
  templateUrl: './validate-client-otp.component.html',
  styleUrl: './validate-client-otp.component.css',
})
export class ValidateClientOtpComponent {
  loading = false;
  error = false;
  userOtp = '';
  otpSendTo: any;
  constructor(
    private validateClientOtpService: ValidateClientOtpService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router
  ) {}

  interval: any;
  display: any;
  timeoutExpired = false;
  userInfo: any = null;
  remainingTime!: number;
  minutes!: number;
  seconds!: number;
  timer: any;
  otpExpiresAt: any;
  message = '';

  ngOnInit(): void {
    this.timeoutExpired = false;
    this.message = 'This code is valid for ';
    this.userInfo = this.encryptPermission.getDecryptedPermissions('usercrd');
    this.otpExpiresAt = this.userInfo?.otpExpiresAt;
    this.otpSendTo=this.userInfo?.otpSendTo;
    if (this.otpExpiresAt) {
      this.calculateRemainingTime();
      this.otpExpireTimer();
    }
  }

  calculateRemainingTime() {
    const now = new Date().getTime();
    const expiration = new Date(this.otpExpiresAt).getTime();

    this.remainingTime = Math.max(0, (expiration - now) / 1000); // Remaining time in seconds

    this.minutes = Math.floor(this.remainingTime / 60);
    const prefixForMinute = this.minutes < 10 ? '0' : '';
    this.seconds = Math.floor(this.remainingTime % 60);
    const prefixForSeconds = this.seconds < 10 ? '0' : '';

    if (this.minutes > 0) {
      this.display = `${prefixForMinute}${this.minutes}:${prefixForSeconds}${this.seconds}`;
    } else {
      this.display = `${prefixForSeconds}${this.seconds}`;
    }
    if (this.seconds == 0 && this.minutes == 0) {
      this.message = 'Click on Resend button to generate new OTP';
      this.timeoutExpired = true;
      clearInterval(this.timer);
    }
  }
  ngOnDestroy() {
    clearInterval(this.timer);
  }

  otpExpireTimer() {
    this.timer = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  }

  onSubmit() {
    this.error = false;

    var payload = {
      clientCode: this.userInfo?.clientCode,
      userName: this.userInfo?.userName,
      otp: this.userOtp,
    };
    this.validateClientOtpService.validateOtp(payload).subscribe({
      next: (result) => {
        Swal.fire({
          title: 'OTP Successful, logging in',
          timer: 1000,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        }).then((_) => {
          this.router.navigateByUrl('Client/accountbalances');
        });
      },
      error: (response) => {
        this.error = true;
      },
    });
  }

  resendOtp() {
    this.error = false;
    this.timeoutExpired = false;
    this.message = 'This code is valid for ';
    var payload = {
      clientCode: this.userInfo?.clientCode,
      userName: this.userInfo?.userName,
    };
    this.validateClientOtpService.resendOtp(payload).subscribe({
      next: (response: any) => {
        this.otpExpiresAt = response.otpExpiresAt;
        this.otpExpireTimer();
      },
    });
  }

  backToLogin() {
    this.router.navigate(['Client']);
  }
}
