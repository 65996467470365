import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  apiUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }


  changePassword(payload:any){
    return this.http.post(this.apiUrl+'Account/changepassword',payload);
  }

  changePasswordForClient(payload:any){
    return this.http.post(this.apiUrl+'ContactAccount/changepassword',payload);
  }
}
