import { Component, ElementRef, OnInit } from '@angular/core';
import { AddressService } from '../../services/addressTab/address.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EMPTY, switchMap } from 'rxjs';
import Swal from 'sweetalert2';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { AddressTab, ErrorMessages } from '../../global/global.constant';
import { Address, Countries, Country } from './models/address.model';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrl: './address.component.css',
})
export class AddressComponent implements OnInit {
  clientMode: boolean = false;
  countryList: Country[] = [];
  address: any = {};
  clientId: number = 2;
  editmode: boolean = false;
  addressForm: any;

  addressMaxLength: number = AddressTab.addressMaxLength;
  suburbMaxLength: number = AddressTab.suburbMaxLength;
  stateMaxLength: number = AddressTab.stateMaxLength;
  postcodeMaxLength: number = AddressTab.postcodeMaxLength;

  ppbAddressMaxLength: number = AddressTab.ppbAddressMaxLength;
  ppbSuburbMaxLength: number = AddressTab.ppbSuburbMaxLength;
  ppbStateMaxLength: number = AddressTab.ppbStateMaxLength;
  ppbPostcodeMaxLength: number = AddressTab.ppbPostcodeMaxLength;

  sameAsRegAddress: boolean = false;
  ppbStreetAddress: string = '';
  ppbState: string = '';
  ppbSuburb: string = '';
  ppbPostCode: string = '';
  ppbCountryID: number = 0;

  dataLoaded: boolean = false;
  tabPermission: any;
  canAddEdit: boolean = false;

  constructor(
    private addressService: AddressService,
    private route: ActivatedRoute,
    private alert: SweetalertService,
    private encryptPermission: EncryptRolePermissionService

  ) {
    this.addressControl();
  }

  addressControl() {
    this.addressForm = new FormGroup({
      clientID: new FormControl(),
      regStreetAddress: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.addressMaxLength),
      ]),
      regSuburb: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.suburbMaxLength),
      ]),
      regState: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.stateMaxLength),
      ]),
      regPostCode: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.postcodeMaxLength),
      ]),
      regCountryID: new FormControl(+'', [Validators.required]),
      ppbStreetAddress: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.addressMaxLength),
      ]),
      ppbSuburb: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.suburbMaxLength),
      ]),
      ppbState: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.stateMaxLength),
      ]),
      ppbPostCode: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.postcodeMaxLength),
      ]),
      ppbCountryID: new FormControl(+'', [Validators.required]),
      sameAsRegAddress: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.errorArray = ['', '', '', '', '', '', '', '', '', '', '', ''];
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    
    if (user === 'Admin') {
      const tabPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).tabPermissions;
      this.tabPermission = tabPermissions.find(
        (x: { tabID: number }) => x.tabID == 3
      );
      this.canAddEdit = this.tabPermission.canInsert ? true : false;
    } else if (user === 'Client') {
      this.clientMode = true;
      this.canAddEdit = false;
    }

    this.addressControl();
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientId = +parameterMap.get('ClientID')!;
    });
    this.addressForm.reset();
    this.addressForm.value.clientID = this.clientId;
    this.getAddress();
    this.getCountryList();

    if (this.clientMode || !this.canAddEdit) {
      this.addressForm.disable();
    }
  }

  getAddress() {
    const promise = new Promise<void>((resolve, reject) => {
      this.loadingMessage = 'Loading';
      this.dataLoaded = false;
      this.addressService.getAddress(this.clientId).subscribe({
        next: (data: Address) => {
          this.address = data;
          this.dataLoaded = true;
          this.addressForm.patchValue(this.address);
          resolve();
        },
        error: (error) => {
          this.dataLoaded = true;
        },
      });
    });
    return promise;
  }

  toggleSwitch(address: any) {
    const controls = this.addressForm.controls;
    const error = controls['regStreetAddress']?.errors || controls['regSuburb']?.errors 
      || controls['regState']?.errors || controls['regPostCode']?.errors || controls['regCountryID']?.errors;

    if (address.target.checked === true && error === null) {
      this.ppbStreetAddress = this.addressForm.value.regStreetAddress;
      this.ppbState = this.addressForm.value.regState;
      this.ppbPostCode = this.addressForm.value.regPostCode;
      this.ppbSuburb = this.addressForm.value.regSuburb;
      this.ppbCountryID = this.addressForm.value.regCountryID;
      this.sameAsRegAddress = true;
      this.addressForm.controls['ppbStreetAddress'].valid;
    } else if(address.target.checked === true && error) {
      address.target.checked = false;
      this.sameAsRegAddress = false;
      this.alert.showError(error,ErrorMessages.sameAddressError);
    } else {
      this.sameAsRegAddress = false;
    }
  }

  submitted: boolean = false;
  loadingMessage = 'Loading';
  onSaveOrUpdate() {
    this.submitted = true;
    this.errorChecking();
    if (this.addressForm.valid) {
      this.addressForm.value.clientID = this.clientId;
      this.dataLoaded = false;
      this.loadingMessage = 'Updating';
      this.dataLoaded = false;

      this.addressService
        .saveOrUpdateAddress(this.addressForm.value)
        .subscribe({
          next: (data: Address) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then(() => {
              this.getAddress().then((_) => {
                this.dataLoaded = true;
              });
            });
          },
          error: (error) => {
            this.alert.showError(error, ErrorMessages.UpdateFailed);
            setTimeout(() => {
              this.dataLoaded = true;
            }, 1000);
          },
        });
    } else {
    }
  }

  getCountryList() {
    this.addressService.getCountryList().subscribe({
      next: (data: Countries) => {
        this.countryList = data.countries;
      },
      error: () => {
      },
    });
  }

  cancel() {
    this.getAddress();
  }

  errorArray: any = Array(12).fill('');
  errorChecking() {
    if (
      this.addressForm.controls['regStreetAddress'].touched ||
      this.submitted ||
      this.addressForm.controls['regStreetAddress'].dirty
    ) {
      if (this.addressForm.controls['regStreetAddress'].errors?.required) {
        this.errorArray[0] = 'Address is required.';
      } else if (
        this.addressForm.value.regStreetAddress != null &&
        this.addressForm.value.regStreetAddress.length > this.addressMaxLength
      ) {
        this.errorArray[0] =
          'Maximum Length of address is ' +
          this.addressMaxLength +
          ' characters.';
      } else {
        this.errorArray[0] = '';
      }
    }

    if (
      this.addressForm.controls['ppbStreetAddress'].touched ||
      this.submitted ||
      this.addressForm.controls['ppbStreetAddress'].dirty
    ) {
      if (this.addressForm.controls['ppbStreetAddress'].errors?.required) {
        this.errorArray[5] = 'Address is required.';
      } else if (
        this.addressForm.value.ppbStreetAddress != null &&
        this.addressForm.value.ppbStreetAddress.length >
          this.ppbAddressMaxLength
      ) {
        this.errorArray[5] =
          'Maximum Length of Address is ' +
          this.ppbAddressMaxLength +
          ' characters.';
      } else {
        this.errorArray[5] = '';
      }
    }

    if (
      this.addressForm.controls['regSuburb'].touched ||
      this.submitted ||
      this.addressForm.controls['regSuburb'].dirty
    ) {
      if (this.addressForm.controls['regSuburb'].errors?.required) {
        this.errorArray[1] = 'Suburb is required.';
      } else if (
        this.addressForm.value.regSuburb != null &&
        this.addressForm.value.regSuburb.length > this.suburbMaxLength
      ) {
        this.errorArray[1] =
          'Maximum Length of Suburb is ' +
          this.suburbMaxLength +
          ' characters.';
      } else {
        this.errorArray[1] = '';
      }
    }

    if (
      this.addressForm.controls['ppbSuburb'].touched ||
      this.submitted ||
      this.addressForm.controls['ppbSuburb'].dirty
    ) {
      if (this.addressForm.controls['ppbSuburb'].errors?.required) {
        this.errorArray[6] = 'Suburb is required.';
      } else if (
        this.addressForm.value.ppbSuburb != null &&
        this.addressForm.value.ppbSuburb.length > this.ppbSuburbMaxLength
      ) {
        this.errorArray[6] =
          'Maximum Length of Suburb is ' +
          this.ppbSuburbMaxLength +
          ' characters.';
      } else {
        this.errorArray[6] = '';
      }
    }

    if (
      this.addressForm.controls['regState'].touched ||
      this.submitted ||
      this.addressForm.controls['regState'].dirty
    ) {
      if (this.addressForm.controls['regState'].errors?.required) {
        this.errorArray[2] = 'State is required.';
      } else if (
        this.addressForm.value.regState != null &&
        this.addressForm.value.regState.length > this.stateMaxLength
      ) {
        this.errorArray[2] =
          'Maximum Length of State is ' + this.stateMaxLength + ' characters.';
      } else {
        this.errorArray[2] = '';
      }
    }

    if (
      this.addressForm.controls['ppbState'].touched ||
      this.submitted ||
      this.addressForm.controls['ppbState'].dirty
    ) {
      if (this.addressForm.controls['ppbState'].errors?.required) {
        this.errorArray[7] = 'State is required.';
      } else if (
        this.addressForm.value.ppbState != null &&
        this.addressForm.value.ppbState.length > this.ppbStateMaxLength
      ) {
        this.errorArray[7] =
          'Maximum Length of State is ' +
          this.ppbStateMaxLength +
          ' characters.';
      } else {
        this.errorArray[7] = '';
      }
    }

    if (
      this.addressForm.controls['regPostCode'].touched ||
      this.submitted ||
      this.addressForm.controls['regPostCode'].dirty
    ) {
      if (this.addressForm.controls['regPostCode'].errors?.required) {
        this.errorArray[3] = 'PostCode is required.';
      } else if (
        this.addressForm.value.regPostCode != null &&
        this.addressForm.value.regPostCode.length > this.postcodeMaxLength
      ) {
        this.errorArray[3] =
          'Maximum Length of PostCode is ' +
          this.postcodeMaxLength +
          ' characters.';
      } else {
        this.errorArray[3] = '';
      }
    }

    if (
      this.addressForm.controls['ppbPostCode'].touched ||
      this.submitted ||
      this.addressForm.controls['ppbPostCode'].dirty
    ) {
      if (this.addressForm.controls['ppbPostCode'].errors?.required) {
        this.errorArray[8] = 'PostCode is required.';
      } else if (
        this.addressForm.value.ppbPostCode != null &&
        this.addressForm.value.ppbPostCode.length > this.ppbPostcodeMaxLength
      ) {
        this.errorArray[8] =
          'Maximum Length of PostCode is ' +
          this.ppbPostcodeMaxLength +
          ' characters.';
      } else {
        this.errorArray[8] = '';
      }
    }

    if (
      this.addressForm.controls['regCountryID'].touched ||
      this.submitted ||
      this.addressForm.controls['regCountryID'].dirty
    ) {
      if (this.addressForm.controls['regCountryID'].errors?.required) {
        this.errorArray[4] = 'Country is Required.';
      } else {
        this.errorArray[4] = '';
      }
    }

    if (
      this.addressForm.controls['ppbCountryID'].touched ||
      this.submitted ||
      this.addressForm.controls['ppbCountryID'].dirty
    ) {
      if (this.addressForm.controls['ppbCountryID'].errors?.required) {
        this.errorArray[9] = 'Country is Required.';
      } else {
        this.errorArray[9] = '';
      }
    }
  }
}
