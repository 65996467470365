import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { CreatedByData } from '../../common/components/confirmation-reports/models/confirmation-reports.models';
import { AdminTradeList, Client, Product, tenantCarPartner } from '../../components/deal-history/model/deal-history.model';

@Injectable({
  providedIn: 'root'
})
export class DealhistoryService {

  constructor(private http: HttpClient) { }

  apiUrl = environment.apiUrl;

  dealHistorySearch(payload: any, isAdmin: boolean): Observable<AdminTradeList[]> {
    if (isAdmin) {
      return this.http.post<AdminTradeList[]>(this.apiUrl + 'DealHistory/Admin/Search', payload);
    } else {
      return this.http.post<AdminTradeList[]>(this.apiUrl + 'DealHistory/Client/Search', payload);
    }
  }

  dealHistoryGetTradeConfo(tradeID: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'DealHistory/GetTradeConfo?tradeID=' + tradeID, { headers: headers, responseType: 'blob' });

  }

  tenantCarpartnerLists(): Observable<tenantCarPartner[]> {
    return this.http.get<tenantCarPartner[]>(this.apiUrl + 'Common/TenantCarList?includeInactive=true');
  }

  clientLists(tenantCarName: string): Observable<Client[]> {
    return this.http.get<Client[]>(this.apiUrl + 'Common/ClientList?tenantCar='+tenantCarName+'&includeInactive=true');
  }

  getCreatedByList = (clientId: number | undefined, tenantCarPartnerID: number, adminType: string): Observable<CreatedByData[]> => {
    let url: string = this.apiUrl + "Common/CreatedByList?clientID="+ clientId
    + "&tenantCarPartnerID="+ tenantCarPartnerID + "&type=" + adminType;
    return this.http.get<CreatedByData[]>(url);
  }

  getProductList = (): Observable<Product[]> => {
    let url: string = this.apiUrl + "Common/GetProducts";
    return this.http.get<Product[]>(url);
  }

  getTradeStatusList = (isAdmin: boolean): Observable<any> => {
    if (isAdmin) {
      let url: string = this.apiUrl + "DealHistory/Admin/getlists";
      return this.http.get<any>(url);
    } else {
      let url: string = this.apiUrl + "DealHistory/Client/getlists";
      return this.http.get<any>(url);
    }
  }
}
