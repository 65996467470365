import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminInfo } from '../models/common.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AttachmentTab, ErrorMessages, SuccessMessages } from '../../global/global.constant';
import { AttachmentsTabService } from '../../services/CRMAttachmentsTab/attachments-tab.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import {
  FileUpload,
  UploadAttachmentResponse,
} from '../attachments-tab/models/attachments.tab.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@UntilDestroy()
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.css',
})
export class UploadFileComponent implements OnInit {
  attachmentData: any;
  files: FileUpload[] = [];
  isLoading: boolean = false;
  loadingMessage:string = "Loading";
  uploadFileProgress: number = 0;
  @Input() clientId: number | undefined = undefined;
  @Input() canAddEdit: boolean = false;
  @Output() isNewFileUpload = new EventEmitter<boolean>(false);
  manageFileForm: any;
  nameMaxLength: number = AttachmentTab.nameMaxLength;
  descriptionMaxLength: number = AttachmentTab.descriptionMaxLength;
  fileUploaded: boolean = false;
  fileSize: number = 0;

  constructor(
    public attachmentsTabService: AttachmentsTabService,
    private encryptPermission: EncryptRolePermissionService,
    private sweetAlert: SweetalertService
  ) {}

  ngOnInit(): void {
    this.attachmentControl();
  }

  attachmentControl(){
    this.manageFileForm = new FormGroup({
      description: new FormControl('',[Validators.required,Validators.maxLength(this.descriptionMaxLength)]),
    });
  }

  onFileSelected(event: any) {
    this.fileSelected(event);
    const selectedFiles = event.target.files;
    this.uploadFiles(selectedFiles);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      this.uploadFiles(droppedFiles);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.files.push({ name: file.name, size: file.size, progress: 0 });
      this.uploadFile(file, this.files.length - 1);
    }
  }

  uploadFile(file: File, index: number) {
    const interval = setInterval(() => {
      if (this.files[index].progress >= 100) {
        clearInterval(interval);
      } else {
        this.files[index].progress += 20;
        this.uploadFileProgress = this.files[index].progress;
      }
    }, 500);
  }

  removeFile() {
    this.files.splice(0);
    this.uploadFileProgress = 0;
  }

  fileSelected(fileToUpload: any) {
    var savedLogin: AdminInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    const file: File = fileToUpload.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.fileSize = file.size;
    
    const payload: any = {
      clientID: this.clientId,
      fileName: file.name,
      description: this.manageFileForm.value.description,
      createdByUserId: savedLogin.userID,
      CreationDate: new Date()
    };
    for (const key of Object.keys(payload)) {
      const value: any = payload[key];
      formData.append(key, value);
    }
    this.attachmentData = formData;
  }

  uploadClicked = () => {
    if (this.manageFileForm.valid){
      if (this.fileSize > 40e+6 ) {
        this.sweetAlert.showError('Error',ErrorMessages.FileSizeValidationMessage);
        return;
      }
      else {
        this.uploadFileServiceCall();
      }
    }
    else{
      this.markFormGroupTouched(this.manageFileForm);
      this.sweetAlert.showError(null, ErrorMessages.FormValueNotValid);
    }
  }

  private uploadFileServiceCall = (): void => {
    this.loadingMessage = "Uploading..."
    this.isLoading = true;
    this.attachmentData.set('description', this.manageFileForm.value.description);
    this.attachmentsTabService
    .uploadAttachmentToS3(this.attachmentData)
    .pipe(untilDestroyed(this))
    .subscribe({
      next: (response: UploadAttachmentResponse) => {
        this.isLoading = false;
        this.isNewFileUpload.emit(true);
        if (!response.isError) {
          this.sweetAlert.messageAlert(SuccessMessages.SuccessFullyFileUploaded);
          // this.manageFileForm.controls['description'].setValue(' ');
          this.manageFileForm.get('description').reset();
          this.removeFile();
          this.uploadFileProgress = 0;
          this.fileUploaded = true;
          }
        },
        error: () => {
          this.isLoading = false;
          this.fileUploaded = false;
        },
      });
  };

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
 
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
