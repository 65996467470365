import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appAllowAlphanumericSpace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AllowAlphanumericSpaceDirective, multi: true }]
})
export class AllowAlphanumericSpaceDirective {

  constructor() { }

  validate(control: FormControl) {
    if (control.value) {
      const stringRegex: RegExp = /^[0-9a-zA-Z ]+$/;
      const valid: boolean = stringRegex.test(control.value);
      return valid ? null : { invalid: true };
    } else {
      return null;
    }
  }
}
