import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const isNumber = /^[a-zA-Z0-9]+$/.test(event.key);
    const isControlKey = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key);

    if (!(isNumber || isControlKey)) {
      event.preventDefault();
    }
  }
}
