
    <app-navigation></app-navigation>
    <header class="header" [ngClass]="{'collapseNav': isNavOpen}">
        <em class="hamburger" (click)="toggleNavigation()">
            <span [ngClass]="{ 'active': isNavOpen }"></span>
            <span [ngClass]="{ 'active': isNavOpen }"></span>
            <span [ngClass]="{ 'active': isNavOpen }"></span>
        </em>
        <div class="right-header">
            <div class="theme-button">
               
            </div>
        </div>
        <div class="user-profile-block dropdown" [ngClass]="{ 'show': isMenuOpen }">
            <a  title="User Profile" data-toggle="dropdown" (click)="toggleMenu()">
                <em class="dp-dark"><img src="../../../../assets/profile-pic.svg"></em>
                <span class="username">{{Username}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" [ngClass]="{ 'expanded': isMenuOpen }"
                [style.display]="isMenuOpen ? 'block' : 'none'">
                <a class="dropdown-item" href=#><span class="icon"><img
                            src="../../../../assets/change-password.svg" alt="Logout"></span>Change
                    Password</a>
                <a class="dropdown-item" href=#><span class="icon"><img
                            src="../../../../assets/logout-icon.svg" alt="Logout"></span>Logout</a>
            </div>
        </div>
        
       
    </header>

