import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidateotpService {
  apiUrl=environment.apiUrl;

  constructor(private http:HttpClient) { }


  validateOtp(payload:any){
    return this.http.post(this.apiUrl+'Account/validateotp',payload);

  }
  resendOtp(payload:any){
    return this.http.patch(this.apiUrl+'Account/resendotp',payload);
  }
}
