import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { SigninService } from '../../services/signin/signin.service';
import { jwtDecode } from 'jwt-decode';
import { SuccessMessages, ErrorMessages } from '../../global/global.constant';
import { VerifyEmailResponse } from '../../client/components/verify-email/verifyEmail.model';

@Component({
  selector: 'app-verify-email-admin',
  templateUrl: './verify-email-admin.component.html',
  styleUrl: './verify-email-admin.component.css',
})
export class VerifyEmailAdminComponent {
  loading: boolean = false;
  tokenID: any;
  errormsg: any;
  msg: string = '';
  verificationfailed: boolean = false;
  tokendecoded: any;
  subscription: any;

  constructor(
    private signInService: SigninService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  @Input() id: string = '';

  ngOnInit() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    } else {
      this.loading = true;
      this.tokenID = this.id;
      this.tokendecoded = jwtDecode(this.tokenID);
      if (this.tokendecoded.IsNewAdmin) {
        this.signInService.verifyEmail(this.tokenID).subscribe({
          next: (data: VerifyEmailResponse) => {
            this.verificationfailed = data.isError;
            this.msg = data.message;
            this.loading = false;
            if (this.verificationfailed) {
              swal.fire({
                icon: 'warning',
                title: 'Email Verification failed',
                confirmButtonText: 'OK',
                showConfirmButton: true,
                timer: 3500,
              });
            } else {
              swal.fire({
                icon: 'success',
                title: 'Email Verified sucessfully',
                confirmButtonText: 'OK',
                showConfirmButton: true,
                timer: 3500,
              });
            }
          },
          error: (error) => {
            this.loading = false;
            this.verificationfailed = true;
            this.msg = ErrorMessages.Verification_failed;
            if (
              error.error &&
              Array.isArray(error.error) &&
              error.error[0].errorType === 'Validation'
            ) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = 'Email Verification failed';
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });
          },
        });
      } else {
        this.signInService.verifyEmail(this.tokenID).subscribe({
          next: (data: any) => {
            this.verificationfailed = false;
            this.loading = false;
            swal
              .fire({
                icon: 'success',
                title: 'Email Verified sucessfully',
                confirmButtonText: 'OK',
                showConfirmButton: true,
                timer: 3500,
              })
              .then(() => {
                this.router.navigateByUrl('admin');
              });
          },
          error: (error) => {
            this.loading = false;
            this.verificationfailed = true;
            this.msg = ErrorMessages.Verification_failed;
            if (
              error.error &&
              Array.isArray(error.error) &&
              error.error[0].errorType === 'Validation'
            ) {
              const description = error.error[0].description;
              this.errormsg = description;
            } else {
              this.errormsg = 'Email Verification failed';
            }
            swal.fire({
              icon: 'warning',
              title: this.errormsg,
              confirmButtonText: 'OK',
              showConfirmButton: true,
              timer: 3500,
            });
          },
        });
      }
    }
  }
}
