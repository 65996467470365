import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/adminUser/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
  styleUrl: './addusers.component.css',
})
export class AddusersComponent implements OnInit {
  roles: any[] = [];
  theme: any[] = [];
  types: any[] = [];
  locations: any[] = [];
  managers: any[] = [];
  tenantList: any[] = [];
  carPartnerList: any[] = [];
  status: any[] = [];
  isUpdateMode: boolean = false;
  isEmailValid: boolean = true;
  isUserNameValid: boolean = false;
  isTenantDisable :boolean =false;
  isCarPartnerDisable :boolean=true;
  isUserNameDisable:boolean=false;
  userID :number=0;
  dataLoaded=false;
  loadingMessage='Loading...';
  pendingAuth=false;
  pendingAuthValue='';
  constructor(
    private fb: FormBuilder,
    private userservice: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private alert:SweetalertService
  ) {}
  userForm: FormGroup = this.fb.group({
    userID: [''],
    firstName: ['', [Validators.required, Validators.maxLength(75)]],
    lastName: ['', [Validators.required, Validators.maxLength(75)]],
    phoneNumber: ['', [Validators.required, Validators.maxLength(50)]],
    emailAddress: ['', [Validators.required, Validators.maxLength(255)]],
    userName: ['', [Validators.required, Validators.maxLength(50)]],
    roleID: ['', Validators.required],
    status: [''],
    themeID: ['', Validators.required],
    userType: ['', Validators.required],
    tenantID: [''],
    locationID: [''],
    managerID: [null],
    carPartnerID: [''],
  });
  ngOnInit(): void {
    this.getuserstatuses();
    this.getDropdownLists();
    this.route.paramMap.subscribe((parameterMap) => {
      const userId = +parameterMap.get('userID')!;
      if (userId) {
        this.userID=userId;
        this.getUserDetails(userId);
        this.isUpdateMode = true;
        this.isUserNameDisable=true;
      }
    });
  }
  getuserstatuses() {
    this.userservice.getuserstatuses().subscribe({
      next: (data: any) => {
        this.status = data.filter(
          (obj: any) =>
            obj.statusID != 'PendingAuthorization' &&
            obj.statusID != 'PendingEmailVerification' &&
            obj.statusID != 'All'
        );
        
      },
      error: () => {
      },
    });
  }
  getUserDetails(userID: number) {
    this.dataLoaded=false;
    this.userservice.getUserDetails(userID).subscribe({
      next: (data: any) => {
        this.userForm.patchValue({...data,
          status:data.statusID
        });
        const statusValue = this.userForm.get('status')?.value;
        
      if (
        statusValue == 'PendingAuthorization'){
          this.pendingAuth=true;
          this.pendingAuthValue ='Pending Authorization'

        }
        else if (
          statusValue == 'PendingEmailVerification'){
            this.pendingAuth=true;
            this.pendingAuthValue ='Pending Email Verification'
  
          }
        this.disableInput();
        this.dataLoaded=true;
      },
      error: () => {
        this.dataLoaded=true;
      },
    });
  }
  getDropdownLists() {
    this.dataLoaded=false;
    this.userservice.getDropdownLists().subscribe({
      next: (data: any) => {
        this.locations = data.locations;
        this.roles = data.roles;
        this.managers = data.managers;
        this.theme = data.themes;
        this.types = data.types;
        this.tenantList = data.tenants;
        this.carPartnerList = data.carPartners;
        this.dataLoaded=true;
      },
      error: () => {
        this.dataLoaded=true;
      },
    });
  }
  validateEamil() {
    var email = this.userForm.value.email;
    if (email?.length != 0) {
      this.userservice.checkEmail(email).subscribe({
        next: (result: any) => {
          this.isEmailValid = result;
        },
        error: () => {}
      });
    }
  }
  validateUsername() {
    var userName = this.userForm.value.userName;
    if (userName?.length != 0) {
      this.userservice.checkUsername(userName).subscribe({
        next: (result: any) => {
          this.isUserNameValid = result;
        },
        error: () => {}
      });
    }
  }
  addUpdateUser() {
    Object.keys(this.userForm.controls).forEach((field) => {
      const control = this.userForm.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.userForm.valid) {
      if (this.isUpdateMode) {
        this.dataLoaded=false;
        this.loadingMessage='Updating...';

        this.userservice.updateUser(this.userForm.value).subscribe({
          next: () => {   
            this.dataLoaded=true;
            this.alert.fireAlert('Updated').then(()=>{
              this.back();
            })         
          },
          error: () => {
            this.dataLoaded=true;
          },
        });
        
      } else {  
        this.dataLoaded=false;
        this.loadingMessage='Saving...';      
        this.userservice.addNewUser(this.userForm.value).subscribe({
          next: (result: any) => {   
            this.dataLoaded=true;
            this.alert.fireAlert('Saved').then(()=>{
              this.back();
            })         
          },
          error: () => {
            this.dataLoaded=true;
          }
        });
      }
    }
  }
  reset() {
    if(this.isUpdateMode){
      this.userForm.reset();
      this.getUserDetails(this.userID);
    }
    else{
      this.userForm.reset();
    }
    
  }
  disableInput(){
    
    const tenantForm = this.userForm.get('tenantID') as FormControl;
    const carPartnerForm = this.userForm.get('carPartnerID') as FormControl;
    const typeValue = this.userForm.get('userType')?.value;
      if(typeValue){
      if(typeValue === "Navinci"){        
      this.isCarPartnerDisable=true; 
      this.isTenantDisable=true;
      tenantForm.reset();
      carPartnerForm.reset();
        }
      
      if(typeValue === "Tenant"){
        carPartnerForm.reset();
        this.isTenantDisable=false;
        this.isCarPartnerDisable=true; 
      }      
      if(typeValue === "CARPartner"){
        tenantForm.reset();
        this.isTenantDisable=true;
        this.isCarPartnerDisable=false; 
      }
    }

  }

  back(){
    this.router.navigate(['/Admin/ManageUsers']);
  }
}
