import { Component } from '@angular/core';
import { OptionHedgeService } from '../../services/OptionHedge/option-hedge.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lhsknock-out-collar',
  templateUrl: './lhsknock-out-collar.component.html',
  styleUrl: './lhsknock-out-collar.component.css',
})
export class LHSKnockOutCollarComponent {
  constructor(
    private optionHedgeService: OptionHedgeService,
    private encryptPermission: EncryptRolePermissionService,
    private route:ActivatedRoute
  ) {}

  clientName: any = null;
  productID = 14;
  isAdmin!: boolean;
  clientId!: number;

  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
      this.clientName =
        this.encryptPermission.getDecryptedPermissions('selectedName');
      this.route.paramMap.subscribe((parameterMap) => {
        this.clientId = +parameterMap.get('clientId')!;
      });
    } else if (user === 'Client') {
      this.isAdmin = false;
      const clientInfo =
        this.encryptPermission.getDecryptedPermissions('usercrd');
      this.clientId = clientInfo?.clientID;
    }
  }
}
