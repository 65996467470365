import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ContactInfo } from '../../components/user-profile/models/user-profile.model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class UserProfileService {

  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {}

  getContactDetails (contactId: number): Observable<ContactInfo>  {
    let url = this.apiUrl + 'Client/contact/' + contactId;
    return this.http.get<ContactInfo>(url);
  }
}
