<!-- <app-header></app-header> -->
<div class="loader-block" *ngIf="!dataLoaded">
    <div class="inner-loader">{{loadingMessage}}</div>
  </div>

<div class="">
    <div class="compress-inputs-page">
        <div class="inner add-adminrole">
            <div class="title">
                <h3>&nbsp;</h3>
                <a (click)="back()" class="btn btn-outline-secondary" id="btnBackMangeAdminRole" btn-sp>
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>
            </div>
            <form [formGroup]="rolePermissionForm" (ngSubmit)="save()">
            <div class="card">               
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Role name <em style="color:red;">*</em></label>
                            <input type="text" formControlName="roleName"class="form-control" placeholder="Role Name" maxlength="100">

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Description</label>
                            <textarea id="txtDescription" formControlName="description" name="description" maxlength="500" class="form-control"
                                placeholder="Description"></textarea>

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Is Active </label>
                            <div class="client custom-switch">
                                <input type="checkbox" formControlName="isActive" id="chkIsActive" class="switch-control-input" />
                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group label-inline">
                            <label class="strong">Is Super Admin </label>
                            <div class="client custom-switch">
                                <input type="checkbox" (change)="supereadmin($event)" formControlName="isSuperAdmin" id="checkIsSuperAdmin" class="switch-control-input" />
                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>

                </div>

                <table class="table" id="tableRoleDetails">
                    <colgroup>
                        <col style="width:35%" />
                        <col style="width:35%" />
                        <col style="width:10%" />
                        <col style="width:10%" />
                        <col style="width:10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Module</th>
                            <th id="thPages">Pages</th>
                            <th class="text-center">
                                <div class="custom-checkbox">
                                    <input class="checkbox-input" formControlName="chkViewAll" id="chkViewAll" (change)="checkAllViews($event)" type="checkbox" />
                                    <span class="checkbox-label">View</span>
                                </div>
                            </th>
                            <th class="text-center">
                                <div class="custom-checkbox">
                                    <input class="checkbox-input chkAddAll" formControlName="chkAddAll" id="chkAddAll" (change)="checkAllAdd($event)" type="checkbox" />
                                    <span class="checkbox-label">Add</span>
                                </div>
                            </th>
                            <th class="text-center">
                                <div class="custom-checkbox">
                                    <input class="checkbox-input chkEditAll" formControlName="chkEditAll" (change)="checkAllEdit($event)" id="chkEditAll" type="checkbox" />
                                    <span class="checkbox-label">Edit</span>
                                </div>
                            </th>

                        </tr>
                    </thead>
                </table>
                <div class=" allTable">
                    <table class="table" id="tableRoleDetails">
                        <tbody>
                            <ng-container *ngFor="let module of responsedata; let i = index">
                                
                                <ng-container *ngIf="i === 0 || module.moduleName !== responsedata[i - 1].moduleName">
                                    <tr>
                                        <td [attr.rowspan]="getLength(module.moduleName)">{{ module.moduleName }}</td>
                                        <td>{{ module.pageName }}</td>
                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input class="checkbox-sub-input chkView" (change)="changeModuleAddEdit(i)" formControlName="chkView_{{i}}"  id="chkView_{{i}}"
                                                    type="checkbox" />
                                                <label for="chkView_{{i}}" class="checkbox-label"></label>
                                            </div>
                                        </td>

                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input id="chkAdd_{{i}}" formControlName="chkAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                                    type="checkbox" (change)="changeView(i)"/>
                                                <label for="chkAdd_{{i}}" class="checkbox-label"></label>
                                            </div>
                                        </td>

                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input id="chkEdit_{{i}}" (change)="changeView(i)" formControlName="chkEdit_{{i}}" class="checkbox-sub-input chkEdit"
                                                    type="checkbox" />
                                                <label for="chkEdit_{{i}}" class="checkbox-label"></label>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="i !== 0 && module.moduleName === responsedata[i - 1].moduleName">
                                    <tr>
                                        <td>{{ module.pageName }}</td>
                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input class="checkbox-sub-input chkView" (change)="changeModuleAddEdit(i)" formControlName="chkView_{{i}}" id="chkView_{{i}}"
                                                    type="checkbox" />
                                                <label for="chkView_{{i}}" class="checkbox-label"></label>

                                            </div>
                                        </td>

                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input id="chkAdd_{{i}}" (change)="changeView(i)"  formControlName="chkAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                                    type="checkbox" />
                                                <label for="chkAdd_{{i}}" class="checkbox-label"></label>
                                            </div>
                                        </td>

                                        <td class="text-center" width="10%">
                                            <div class="custom-checkbox">
                                                <input id="chkEdit_{{i}}" (change)="changeView(i)" formControlName="chkEdit_{{i}}"  class="checkbox-sub-input chkEdit"
                                                    type="checkbox" />
                                                <label for="chkEdit_{{i}}" class="checkbox-label"></label>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                    <table class="table" id="tableFieldDetails">
                        <colgroup>
                            <col style="width:70%" />
                            <col style="width:10%" />
                            <col style="width:10%" />
                            <col style="width:10%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th id="thFields">Fields</th>
                                <th class="text-center">
                                   
                                </th>
                                <th class="text-center">
                                  
                                </th>
                                <th class="text-center">
                               
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let Fields of fieldsData; let i = index">
                                <tr class="AdminFieldRow">
                                    <td width="12%">
                                        {{Fields.fieldName}}
                                        <p style='font-size: 12px;'>
                                            {{Fields.displayName}}
                                    </td>
                                    <td class="text-center" width="5%">
                                        <div class="custom-checkbox">
                                            <input class="checkbox-sub-input chkView" (change)="changeFieldAddEdit(i)" formControlName="chkFieldView_{{i}}" id="chkFieldView_{{i}}" 
                                            type="checkbox" />
                                        <label for="chkFieldView_{{i}}" class="checkbox-label"></label>
                                        </div>
                                    </td>
                                    <td class="text-center" width="5%">
                                        <div class="custom-checkbox">
                                            <input id="chkFieldAdd_{{i}}" (change)="changeFieldView(i)" formControlName="chkFieldAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                            type="checkbox" />
                                        <label for="chkFieldAdd_{{i}}" class="checkbox-label"></label>
                                        </div>
                                    </td>
                                    <td class="text-center" width="5%">
                                       
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <table class="table" id="tableTabDetails">
                        <colgroup>
                            <col style="width:70%" />
                            <col style="width:10%" />
                            <col style="width:10%" />
                            <col style="width:10%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th id="thTabs">Tabs</th>
                                <th class="text-center">                                    
                                </th>
                                <th class="text-center">                                   
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let tabs of tabsData; let i = index">
                                <tr class="AdminTabRow">

                                    <td width="12%">
                                        {{tabs.tabName}}
                                        <p style='font-size: 12px;'>
                                            {{tabs.displayName}}
                                    </td>
                                    <td class="text-center" width="5%">
                                        <div class="custom-checkbox">
                                            <input class="checkbox-sub-input chkView" (change)="changeTabAddEdit(i)" formControlName="chkTabView_{{i}}" id="chkTabView_{{i}}"
                                                    type="checkbox" />
                                                <label for="chkTabView_{{i}}" class="checkbox-label"></label>
                                        </div>
                                    </td>
                                    <td class="text-center" width="5%">
                                        <div class="custom-checkbox">
                                            <input id="chkTabAdd_{{i}}" (change)="changeTabView(i)" formControlName="chkTabAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                                    type="checkbox" />
                                                <label for="chkTabAdd_{{i}}" class="checkbox-label"></label>
                                        </div>
                                    </td>
                                    <td class="text-center" width="5%">                                       
                                    </td>

                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                
                </div>
            
                <div class="col-md-12"> <br /></div>
                <div class="col-md-12">
                    <div class="inline-element d-block text-center">
                        <button id="aSaveRoleDetails" class="btn btn-success" type="submit">Save </button>
                        <button id="aCancelRoleDetails" class="btn btn-secondary" (click)="cancel()" type="button">Cancel </button>
                    </div>
                </div>
            </div>

        </form>
        </div>
    </div>
</div>