import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpotDealService } from '../../services/spotDeal/spot-deal.service';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { SignalRService } from '../../services/signalR/signal-r.service';
import Swal from 'sweetalert2';
import { ErrorMessages, SystemConstant } from '../../../global/global.constant';
import {
  ExecuteDealResponse,
  RelationshipManager,
  RequestQuote,
  RequestResponse,
} from './models/spotdeal.model';

@Component({
  selector: 'app-spot-deal',
  templateUrl: './spot-deal.component.html',
  styleUrl: './spot-deal.component.css',
})
export class SpotDealComponent {
  relationshipManager: RelationshipManager[] = [];
  dataLoaded: boolean = false;
  rmClients: any[] = [];
  rmContacts: any[] = [];
  currencyPairs: any[] = [];
  valueDates: any[] = [];
  baseCurrency: any = {};
  termCurrency: any = {};
  selectedCurrencyPairid = 0;
  selectedCurrencyPair: string = 'AUDUSD';
  notionalCurrency: any = {};
  spotSalesmargin: any;
  direction: string = 'B';
  selectedMarginType: string = 'Percentage';
  selectedDirection: string = 'B';
  isAdmin!: boolean;
  clientId: any;
  contactID: any;
  spotReference: any = '0.0000';
  isPartner: any;
  currencyPairsInfo: any = [];
  product: string = 'Spot';
  currenCurrency: any;
  dataFromQuoteApi!: RequestQuote;
  display: any;
  selectedClient: any;
  executeDealSuccess: boolean = false;
  dealResponse!: ExecuteDealResponse;
  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private spotDealServices: SpotDealService,
    private encryptPermission: EncryptRolePermissionService,
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute,
    private router: Router,
    private signalRService: SignalRService,
    private elementRef: ElementRef
  ) {}
  quoteForm: FormGroup = this.fb.group({
    selectedRM: [null],
    selectedclientID: [null, [Validators.required]],
    selectedContactID: [null, [Validators.required]],

    salesMargin: ['', [Validators.required]],
    currencyPair: [null, [Validators.required]],
    notionalAmount: ['', [Validators.required]],
    marginAmount: [''],
    valueDate: [null, [Validators.required]],
  });

  ngOnInit() {
    this.subscribeToRate();
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    this.signalRService.startConnection().subscribe(() => {
      this.signalRService.getRates().subscribe((rate) => {
        this.spotReference = rate;
      });
    });
    this.getCurrencyPairInfo().then(() => {
      if (user === 'Admin') {
        this.isAdmin = true;
        this.isPartner = true;

        const dealerViewFlag =
          this.encryptPermission.getDecryptedPermissions(
            'userResponse'
          )?.enablePricerDealer;
        if (dealerViewFlag) {
          this.getRMS();
        } else {
          this.quoteForm.controls['selectedRM'].disable();
          this.onRMselect(null);
        }
      } else if (user === 'Client') {
        this.isAdmin = false;
        const clientInfo =
          this.encryptPermission.getDecryptedPermissions('usercrd');
        this.clientId = clientInfo?.clientID;
        this.selectedClient = clientInfo?.clientID;
        this.contactID = clientInfo?.contactID;
        this.quoteForm.get('selectedclientID')?.setValue(this.clientId);
        this.quoteForm.get('selectedContactID')?.setValue(this.contactID);
        if (this.clientId) {
          this.getClientListInfo();
          this.checkIsPartnerOrNot();
        }
        this.quoteForm
          .get('salesMargin')
          ?.removeValidators(Validators.required);
        this.quoteForm
          .get('marginAmount')
          ?.removeValidators(Validators.required);
        this.quoteForm
          .get('selectedclientID')
          ?.removeValidators(Validators.required);
        this.quoteForm
          .get('selectedContactID')
          ?.removeValidators(Validators.required);
      }
    });
  }

  getRMS() {
    this.dataLoaded = false;
    this.spotDealServices.getRelationshipmanager().subscribe({
      next: (data: RelationshipManager[]) => {
        this.dataLoaded = true;
        if (data) {
          this.relationshipManager = Array.from(
            data.reduce((m, t) => m.set(t.managerID, t), new Map()).values()
          );
        }
      },
      error: () => {},
    });
  }
  onRMselect(event: any) {
    if (event) {
      this.dataLoaded = false;
      this.spotDealServices.getClients(event.managerID).subscribe({
        next: (data: any) => {
          this.rmClients = data;
          this.dataLoaded = true;
        },
        error: (error) => {
          Swal.fire({
            text: 'Client list Fetch Failed',
            timer: 2000,
            timerProgressBar: true,
            icon: 'warning',
          });
          this.dataLoaded = true;
        },
      });
    } else {
      this.dataLoaded = false;
      this.spotDealServices.getClients(null).subscribe({
        next: (data: any) => {
          this.rmClients = data;
          this.dataLoaded = true;
        },
        error: (error) => {
          Swal.fire({
            text: 'Client list Fetch Failed',
            timer: 2000,
            timerProgressBar: true,
            icon: 'warning',
          });
          this.dataLoaded = true;
        },
      });
    }
  }

  getCurrencyPairInfo() {
    return new Promise<void>((resolve, reject) => {
      this.spotDealServices.getCurrencyPairId().subscribe({
        next: (response) => {
          this.currencyPairsInfo = response;

          resolve();
        },
      });
    });
  }

  getClientListInfo() {
    this.dataLoaded = false;
    this.spotDealServices
      .getForwardListForClient(this.isAdmin ? 'Admin' : 'Client')
      .subscribe({
        next: (data: any) => {
          this.currencyPairs = data.currencyPairs;
          this.valueDates = data.valueDates;

          if (!this.isAdmin) {
            if (!this.quoteForm.get('currencyPair')?.value) {
              this.quoteForm
                .get('currencyPair')
                ?.patchValue(this.currencyPairs[0]);
              this.onCurrencyPairSelect(this.currencyPairs[0]);
            }
          }
          this.dataLoaded = true;
        },
        error: () => {},
      });
  }

  checkIsPartnerOrNot() {
    this.spotDealServices.checkIsPartner(this.clientId).subscribe({
      next: (response: any) => {
        this.isPartner = response.isPartner;
      },
    });
  }

  onClientSelect(event: any) {
    if (event) {
      this.dataLoaded = false;
      this.selectedClient = event.clientID;
      this.spotDealServices.getForwardList(event.clientID).subscribe({
        next: (data: any) => {
          this.rmContacts = data.contacts;
          this.currencyPairs = data.currencyPairs;
          this.valueDates = data.valueDates;
          this.dataLoaded = true;
        },
        error: () => {},
      });
    } else {
      this.quoteForm.controls['selectedContactID'].setValue(null);
      this.rmContacts = [];
    }
  }

  onCurrencyPairSelect(event: any) {
    this.dataLoaded = false;
    this.selectedCurrencyPair = event.currencyCode;
    const currencyInfo = this.currencyPairsInfo.find(
      (currency: { currencyPairID: number }) =>
        currency.currencyPairID === event.id
    );
    this.tredingviewChart();

    this.baseCurrency = {
      id: currencyInfo?.baseCurrencyID,
      name: event.currencyCode.substring(0, 3),
    };

    this.notionalCurrency = this.baseCurrency;

    this.termCurrency = {
      id: currencyInfo?.termCurrencyID,
      name: event.currencyCode.substring(3, 6),
    };
    this.spotSalesmargin = event.spotSalesMarginPerc;
    const salesMargin = this.decimalPipe.transform(
      this.spotSalesmargin,
      '1.2-2'
    )!;
    this.quoteForm.get('salesMargin')?.setValue(salesMargin);
    this.invokeSpotRate();

    if (this.selectedMarginType == 'Tics') {
      this.pecentageChecked();
    }

    this.calculateMarginAmount();

    this.dataLoaded = true;
  }
  currencySelect(selectedCurrency: string) {
    if (selectedCurrency == 'base') {
      this.notionalCurrency = this.baseCurrency;
    } else {
      this.notionalCurrency = this.termCurrency;
    }
    this.invokeSpotRate();

    this.calculateMarginAmount();
  }

  requestQuote = (): void => {
    this.requestQuotePricer();
    this.subscribeRequestQuoteStatus();
    this.dataLoaded = false;
    var payload = {};
    if (this.isAdmin) {
      if (this.quoteForm.valid) {
        const quoteformValue = this.quoteForm.getRawValue();
        payload = {
          dealerID: quoteformValue.selectedRM,
          clientID: quoteformValue.selectedclientID,
          contactID: quoteformValue.selectedContactID,
          currencyPair: quoteformValue.currencyPair.currencyCode,
          valueDate: quoteformValue.valueDate,
          direction: this.selectedDirection == 'B' ? 'Buy' : 'Sell',
          notionalCurrency: this.notionalCurrency.name,
          notionalAmount: parseFloat(
            this.quoteForm.get('notionalAmount')?.value.replace(/,/g, '')
          ),
          salesMarginValue: parseFloat(quoteformValue.salesMargin),
          salesMarginType: this.selectedMarginType,
          product: this.product,
        };
        this.requestQuoteApicall(payload, SystemConstant.Admin);
      }
    } else {
      if (this.quoteForm.valid) {
        const quoteformValue = this.quoteForm.value;
        payload = {
          currencyPair: quoteformValue.currencyPair.currencyCode,
          valueDate: quoteformValue.valueDate,
          direction: this.selectedDirection == 'B' ? 'Buy' : 'Sell',
          notionalCurrency: this.notionalCurrency.name,
          notionalAmount: parseFloat(
            this.quoteForm.get('notionalAmount')?.value.replace(/,/g, '')
          ),
          salesMarginValue: parseFloat(quoteformValue.salesMargin),
          salesMarginType: this.selectedMarginType,
          product: this.product,
        };
        this.requestQuoteApicall(payload, SystemConstant.Client);
      }
    }
  };

  private requestQuoteApicall = (payload: any, userType: string): void => {
    this.spotDealServices.requestQuote(payload, userType).subscribe({
      next: (response) => {
        this.dataFromQuoteApi = response;
        this.openModal();
      },
    });
  };

  ngAfterViewInit() {
    this.tredingviewChart();
  }

  tredingviewChart() {
    const container = this.el.nativeElement.querySelector(
      '.tradingview-widget-container'
    );
    if (!container) {
      return;
    }

    // Clear existing content
    container.innerHTML = '';
    const script = document.createElement('script');
    let symbol = this.selectedCurrencyPair;
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    {
      "width": "100%",
      "height": "400",
      "autosize": true,
      "symbol": "${symbol}",
      "interval": "5",
      "timezone": "Australia/Sydney",
      "theme": "light",
      "style": "1",
      "locale": "en",
      "allow_symbol_change": true
     
    }`;
    this.el.nativeElement
      .querySelector('.tradingview-widget-container')
      .appendChild(script);
  }
  pecentageChecked() {
    this.dataLoaded = false;

    if (this.quoteForm.get('notionalAmount')?.value) {
      const notional = this.decimalPipe.transform(
        this.quoteForm.get('notionalAmount')?.value?.replace(/,/g, ''),
        '1.2-2'
      );
      this.quoteForm.get('notionalAmount')?.setValue(notional);
    }

    let payload = {
      percentageOrTics: this.selectedMarginType,
      percentageOrTicsAmount: parseFloat(
        this.quoteForm.get('salesMargin')?.value
      ),
      direction: this.selectedDirection,
      currencyPairID:
        this.quoteForm.get('currencyPair')?.value != undefined
          ? this.quoteForm.get('currencyPair')?.value.id
          : 0,
      notionalAmount:
        this.quoteForm.get('notionalAmount')?.value != ''
          ? parseFloat(
              this.quoteForm.get('notionalAmount')?.value.replace(/,/g, '')
            )
          : 0,
    };

    if (payload.currencyPairID > 0) {
      this.spotDealServices.convertPercentageTics(payload).subscribe({
        next: (data: any) => {
          if (this.selectedMarginType == 'Percentage') {
            const salesMargin = this.decimalPipe.transform(data, '1.2-2')!;
            this.quoteForm.get('salesMargin')?.setValue(salesMargin);
          } else {
            const salesMargin = this.decimalPipe.transform(data, '1.4-4')!;
            this.quoteForm.get('salesMargin')?.setValue(salesMargin);
          }
          this.calculateMarginAmount();
          this.dataLoaded = true;
        },
        error: () => {},
      });
    } else {
      this.dataLoaded = true;
      Swal.fire({
        icon: 'warning',
        title: ErrorMessages.CurrencyPairSelect,
        confirmButtonText: 'OK',
        showConfirmButton: true,
        timer: 3500,
      });
    }
  }

  calculateMarginAmount() {
    if (this.quoteForm.value.notionalAmount) {
      const quoteVAlue = this.quoteForm.value;

      const payload = {
        currencyPairID: this.quoteForm.value.currencyPair.id,
        notionalCurrencyID: this.notionalCurrency.id,
        productID: 15,
        notionalAmount: parseFloat(
          this.quoteForm.value.notionalAmount?.replace(/,/g, '')
        ),
        percentageOrTics: this.selectedMarginType,
        percentageOrTicsAmount: parseFloat(this.quoteForm.value.salesMargin),
        direction: this.selectedDirection,
      };
      if (payload.currencyPairID > 0) {
        this.dataLoaded = false;
        this.spotDealServices.calculateSalesMarginAmount(payload).subscribe({
          next: (data) => {
            this.quoteForm.get('marginAmount')?.setValue(data);
            this.dataLoaded = true;
          },
        });
      } else {
        this.dataLoaded = true;
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.CurrencyPairSelect,
          confirmButtonText: 'OK',
          showConfirmButton: true,
          timer: 3500,
        });
      }
    }
  }

  formatSalesMargin() {
    if (this.selectedMarginType == 'Percentage') {
      const salesMargin = this.decimalPipe.transform(
        this.quoteForm.get('salesMargin')?.value,
        '1.2-2'
      )!;

      this.quoteForm.get('salesMargin')?.setValue(salesMargin);
    } else {
      const salesMargin = this.decimalPipe.transform(
        this.quoteForm.get('salesMargin')?.value,
        '1.4-4'
      )!;

      this.quoteForm.get('salesMargin')?.setValue(salesMargin);
    }
    this.calculateMarginAmount();
  }

  formatMarginAmount() {
    const marginAmount = this.decimalPipe.transform(
      this.quoteForm.get('marginAmount')?.value,
      '1.2-2'
    )!;

    this.quoteForm.get('marginAmount')?.setValue(marginAmount);
  }

  directionchange(direction: string) {
    this.direction = direction;
  }

  CheckPercentOrtics() {
    if (this.quoteForm.get('notionalAmount')?.value) {
      const notional = this.decimalPipe.transform(
        this.quoteForm.get('notionalAmount')?.value?.replace(/,/g, ''),
        '1.2-2'
      );
      this.quoteForm.get('notionalAmount')?.setValue(notional);
    }
    this.invokeSpotRate();

    this.calculateMarginAmount();
  }

  close = (): void => {
    const modal = this.elementRef.nativeElement.querySelector(
      '#confirmpopup-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';

    this.dataLoaded = false;
    if (this.dataFromQuoteApi.isValidQuote) {
      this.signalRService.cancelQuote();
      this.subscribeCancelRequest();
    }
  };

  execute() {
    this.executeDealPricer();
    this.dataLoaded=false;
    this.subscribeExecuteDealStatus().then(() => {
      this.dataLoaded=true;
      if (this.executeDealSuccess) {
        this.dataFromQuoteApi;
        if (this.isAdmin) {
          this.router.navigate(['Admin/SpotDeal/DealDetail'], {
            state: {
              data: this.dataFromQuoteApi,
              direction: this.direction,
              admin: true,
            },
          });
        } else {          
          this.router.navigate(['Client/SpotDeal/DealDetail'], {
            state: {
              data: this.dataFromQuoteApi,
              direction: this.direction,
              admin: false,
            },
          });
        }
      } else {
        this.close();
        Swal.fire({
          text: 'Execute Deal Failed',
          timer: 2000,
          timerProgressBar: true,
          icon: 'warning',
        });
      }
    });
  }
  openModal() {
    const modal = this.elementRef.nativeElement.querySelector(
      '#confirmpopup-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';

      document.body.appendChild(backdrop);

      modal.focus();
    }
  }

  subscribeToRate() {
    this.signalRService.getRates().subscribe((rate: string) => {
      this.spotReference = rate;
    });
  }

  invokeSpotRate() {
    const payload = {
      currencyPair: this.quoteForm.value.currencyPair.currencyCode,
      notionalCurrency: this.notionalCurrency.name,
      notionalAmount: parseFloat(
        this.quoteForm.value.notionalAmount?.replace(/,/g, '')
      )
        ? parseFloat(this.quoteForm.value.notionalAmount?.replace(/,/g, ''))
        : 0.0,
      direction: this.selectedDirection == 'B' ? 'Buy' : 'Sell',
      valueDate: this.quoteForm.value.valueDate,
      clientID: this.selectedClient,
      percentageOrTics: this.selectedMarginType,
      salesMarginValue: parseFloat(this.quoteForm.value.salesMargin),
    };
    if (
      payload.currencyPair &&
      payload.direction &&
      payload.notionalAmount &&
      payload.valueDate &&
      payload.notionalCurrency &&
      payload.percentageOrTics
          
    ) {
      this.signalRService.sendMessage('Rate', payload);
    }
  }
  requestQuotePricer() {
    const payload = {
      currencyPair:
        this.quoteForm.value.currencyPair.currencyCode.substring(0, 3) +
        '/' +
        this.quoteForm.value.currencyPair.currencyCode.substring(3, 6),
      Direction: this.selectedDirection == 'B' ? 1 : 2,
      Amount: parseFloat(this.quoteForm.value.notionalAmount?.replace(/,/g, ''))
        ? parseFloat(this.quoteForm.value.notionalAmount?.replace(/,/g, ''))
        : 0.0,
      TradeDate: this.quoteForm.value.valueDate,
      NotionalCurrency: this.notionalCurrency.name,
    };
    if (
      payload.currencyPair &&
      payload.Direction &&
      payload.Amount &&
      payload.TradeDate &&
      payload.NotionalCurrency
    ) {
      this.signalRService.requestQuote(payload);
    }
  }
  subscribeRequestQuoteStatus() {
    this.signalRService
      .getRequestQuoteStatus()
      .subscribe((RequestQuoteResponse: RequestResponse) => {
        if (RequestQuoteResponse.isError) {
          this.dataFromQuoteApi.errorMessage =
            RequestQuoteResponse.errorMessage;
          this.dataFromQuoteApi.isValidQuote = false;
        } else {
          this.dataFromQuoteApi.isValidQuote = true;
          this.dataFromQuoteApi.rate = RequestQuoteResponse.rate;
          this.dataFromQuoteApi.valueDate = RequestQuoteResponse.valueDate;
          this.dataFromQuoteApi.clientBuyCurrency =
            RequestQuoteResponse.buyCurrency;
          this.dataFromQuoteApi.clientBuyAmount =
            RequestQuoteResponse.buyAmount;
          this.dataFromQuoteApi.clientSellCurrency =
            RequestQuoteResponse.sellCurrency;
          this.dataFromQuoteApi.clientSellAmount =
            RequestQuoteResponse.sellAmount;
        }
        this.dataLoaded = true;
      });
  }

  executeDealPricer() {
    this.signalRService.executeDeal();
  }

  subscribeExecuteDealStatus() {
    return new Promise<void>((resolve, reject) => {
      this.signalRService
        .getDealStatus()
        .subscribe((executeDealResponse: ExecuteDealResponse) => {
          this.dealResponse = executeDealResponse;
          if (executeDealResponse.isError) {
            this.executeDealSuccess = false;
          } else {
            this.dataFromQuoteApi.clientBuyAmount =
              executeDealResponse.BuyAmount;
            this.dataFromQuoteApi.clientSellAmount =
              executeDealResponse.SellAmount;
            this.dataFromQuoteApi.valueDate = new Date(
              executeDealResponse.ValueDate
            );
            this.dataFromQuoteApi.rate = executeDealResponse.rate;
            this.dataFromQuoteApi.clientBuyCurrency =
              executeDealResponse.BuyCurrency;
            this.dataFromQuoteApi.clientSellCurrency =
              executeDealResponse.SellCurrency;
            this.executeDealSuccess = true;
          }
          resolve();
        });
    });
  }

  subscribeCancelRequest() {
    return new Promise<void>((resolve, reject) => {
      this.signalRService
        .cancelQuoteResponse()
        .subscribe((cancelQuoteResponse: boolean) => {
          this.dataLoaded = true;
          resolve();
        });
    });
  }
}
