<div class="div">
  <form *ngIf="canAddEdit" [formGroup]="manageFileForm">
    <div class="row">
      <div class="col-md-8">
        <div class="address-block">
          <h6>File Upload Form</h6>
          <div class="form-group label-inline">
            <label>Description<em style="color: red">*</em></label>
            <div class="showhide"
              [ngClass]="{'newTooltip expand has-error': (manageFileForm.controls['description'].touched) && manageFileForm.controls['description'].invalid && canAddEdit}"
              datatitle="Description is required">
              <input type="text"  id="txtDescription" maxlength="50" class="form-control" placeholder="Description"
                formControlName="description" [readonly]="!canAddEdit"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="upload-container border">
          <h3 class="mt-2">Upload Your File</h3>
          <div class="pb-2 dropAndBrowse">
            <!-- <div
              class="drop-zone"
              (drop)="onDrop($event)"
              (dragover)="onDragOver($event)"
            >
              Drop Your Files Here
            </div>
            <p class="text-center pt-2 p-0">or</p> -->
            <button
              class="btn btn-success w-100"
              (click)="fileInput.click()"
              [disabled]="files.length > 0 || !canAddEdit"
              #tooltip="matTooltip"
              matTooltip="User can upload one file at a time."
            >
              Browse
            </button>
            <input
              type="file"
              (change)="onFileSelected($event)"
              hidden
              #fileInput
            />
          </div>
          <div *ngFor="let file of files; let i = index" class="file-item mt-3">
            <div class="file-details">
              <span class="file-name">{{ file.name }}</span>
              <button
                (click)="removeFile()"
                type="button"
                class="close"
                data-dismiss="modal"
              >
                <span aria-hidden="true" class="modal_button">&times;</span
                ><span class="sr-only">Close</span>
              </button>
              <!-- <button
              *ngIf="file.progress == 100" 
                type="button"
                class="close"
                data-dismiss="modal"
              >
                yes
              </button> -->
            </div>
            <mat-progress-bar
              mode="determinate"
              [value]="file.progress"
            ></mat-progress-bar>
          </div>
          <div class="d-flex justify-content-end w-100 mt-2">
            <button
              (click)="uploadClicked()"
              class="btn btn-success"
              [disabled]="uploadFileProgress != 100 || !canAddEdit || !manageFileForm.controls['description'].valid"
            >
              <em><img src="../../../../assets/plus.svg" alt="" /></em> Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="isLoading">
  <div class="loader-block">
    <div class="inner-loader">
      {{loadingMessage}}
    </div>
  </div>
</div>
