export const SystemConstant = {
  Admin: 'Admin',
  Navinci: 'Navinci',
  Client: 'Client',
  Enter: 'Enter',
  Tenant: 'Tenant',
  CARPartner: 'CARPartner',
  SuccessMessages: 'Successfully saved.',
  SuccessFullyDetach: 'Successfully Detach Files',
  AcnTitle: 'Australian Comapany Number',
  AbnTitle: 'Australian Business Number',
  Delete_warning: 'Are you sure you want to Detach these attachments?',
  DOBTitle: 'Date of Birth.',
};
export const SuccessMessages = {
  Verification_Success:
    'Your email has been successfully verified! Please check your email inbox for the login link and credentials.',
  EmailSendSuccessfully: 'Email Sent Successfully',
  BankDataAdded: 'Banking Data Added',
  BankDataUpdated: 'Banking Data Updated',
  SuccessFullyFileUploaded: 'File Uploaded',
};

export const AdminUser_ById = {
  Tenant: 1,
  CARPArtner: 2,
};
export const ErrorMessages = {
  Please_fill_required_inputs: 'Please fill required inputs.',
  Something_goes_wrong: 'Something goes wrong.',
  Error_occurred_while_processing_your_request:
    'Error occurred while processing your request.',
  Token_Is_not_valid: 'Token Is not valid',
  Reset_password_Link_Invalid_Expired: 'Reset password Link Invalid/Expired',
  New_Password_and_Confirm_Password_should_match:
    'New Password and Confirm Password should match',
  Password_Rules:
    'New Password must contain at least 8 characters having 1 digit, 1 special character,1 lowercase and 1 capital letter',
  Verification_failed:
    'It looks like something went wrong with the verification. Please contact our support team for further assistance',
  PDF_Not_Fount: 'PDF Not Found',
  Update_trade_Failed: 'Update trade Failed',
  Trade_data: 'Unable to fetch Trade Data',
  UpdateFailed: 'Update Failed',
  CreditLimitMaxLengthMessage: 'Credit Limit Maximum length is 30',
  CreditLimitScaleMaxLengthMessage:
    'Credit Limit Maximum length for scale number is 4',
  ExpectedVolumePerAnnumMaxLengthMessage:
    'Expected Volume Per Annum Maximum length is 22',
  ExpectedVolumePerAnnumScaleMaxLengthMessage:
    'Expected Volume Per Annum Maximum length for scale number is 4',
  AvoidDuplicateDealer: 'Please select different dealer 2 then dealer 1',
  AvoidDuplicateAE: 'Please select different AE 2 then AE 1',
  AvoidDuplicateSE: 'Please select different SE 2 then SE 1',
  AvoidDuplicateBDM: 'Please select different BDM 2 then BDM 1',
  CurrencyPairSelect: 'Please select Currency Pair.',
  sameAddressError: 'Please fill all required fields of Registered Address',
  DownloadFailed: 'Download Failed',
  NotDetached: 'Your file is not Detach',
  SelectAtleastOneFile: 'Select at least one file.',
  EnterCorrectEmail: 'Please enter correct email',
  Client_Data: 'Client data fetch failed',
  Unknown_error: 'An unknown error occurred.',
  Valid_Date: 'Enter a Valid Date',
  Future_Date: 'Future Dates are not allowed',
  Past_Date: 'Past Date are not allowed',
  UniqueClientCode: 'Please Enter Unique Client Code',
  UniqueAlias: 'Please Enter Unique Alias',
  FormValueNotValid: 'Form value is not valid, Please check again.',
  FileSizeValidationMessage: 'File size should not be greater than 40 Mb.',
  ACN_require: 'ACN is required.',
  ABN_require: 'ABN is required.',
  ACN_Valid: 'Please enter a valid ACN',
  ABN_Valid: 'Please enter a valid ABN',
  Select_role: 'Please select atleast one role for contact.',
  Delta_limit_valid: 'Please enter a valid Delta Limit',
  Max_delta_limit: 'Reached maximum limit',
  FormDateToDate: 'From Date cannot be greater than To Date.',
  SelectBothDate: 'Please select both From Date and To Date.',
};

export const FieldConstant = {
  Position_Summary_Report: 19,
  Delta_Ladder: 20,
  Factsheets: 21,
  Confirmation_Reports: 22,
  Sales_Revenue_Dash: 24,
  Sales_NGP_Org_Dashboard: 25,
  Settlements_Report: 26,
};

export const CRMFieldPermission = {
  firstFieldId: 34,
  lastFieldId: 56,
  canView: 1,
};

export const Percent = {
  percentMin: 0,
  percentMax: 100,
};

export const TradingTabConstant = {
  tabId: 9,
  firstFieldId: 7,
  lastFieldId: 13,
  firstFieldId2: 57,
  lastFieldId2: 60,
  fieldID2: 16,
  fieldID3: 27,
  fieldID4: 30,
  canView: 1,
  disable: 0,
};
export const GeneralTabConstant = {
  tabId: 10,
  ClientNameFiledID: 1,
  businessLeagalOtherid: 6,
  firstFieldId: 61,
  lastFieldId: 73,
  fieldID1: 17,
  fieldID2: 18,
  fieldID3: 9,
  canView: 1,
  disable: 0,
};

export const ContactTabConstant = {
  tabId: 15,
  canView: 1,
  disable: 0,
  clientName: 1,
  contactRoleID: 75,
  contactStatusID: 76,
  contactDOB: 77,
  contactNationality: 78,
  contactAddress: 79,
  contactTitle: 80,
  contactFirstName: 81,
  contactMiddleName: 82,
  contactLastName: 83,
  contactPosition: 84,
  contactEmail: 85,
  contactPhone: 86,
  contactUserName: 87,
  contactAlias: 88,
  contactTheme: 89,
  primaryContact: 90,
  contactType: 91,
};

export const SpinnerMessage = {
  Loading: 'Loading...',
  Saving: 'Saving...',
  Update: 'Updating...',
  Delete: 'Deleting...',
  Download: 'Downloading...',
  SendMail: 'Sending Email...',
};

export const AddressTab = {
  addressMaxLength: 255,
  suburbMaxLength: 255,
  stateMaxLength: 45,
  postcodeMaxLength: 10,
  ppbAddressMaxLength: 255,
  ppbSuburbMaxLength: 255,
  ppbStateMaxLength: 45,
  ppbPostcodeMaxLength: 10,
};

export const BankingTabConstant = {
  accountNameMaxLength: 100,
  accountNoMaxLength: 20,
  bankNameMaxLength: 100,
  bsbNoMaxLength: 7,
  streetAddressMaxLength: 250,
  suburbMaxLength: 50,
  stateMaxLength: 50,
  postCodeMaxLength: 10,
};

export const AttachmentTab = {
  nameMaxLength: 50,
  descriptionMaxLength: 50,
};
export const TabRoutingArray = [
  { tabID: 10, route: 'clientgeneral' },
  { tabID: 3, route: 'clientaddress' },
  { tabID: 15, route: 'clientcontacts' },
  { tabID: 9, route: 'trading' },
  { tabID: 2, route: 'DeltaLimit' },
  { tabID: 11, route: 'banking' },
  { tabID: 1, route: 'clientcrm' },
  { tabID: 6, route: 'clientsalesmargin' },
  { tabID: 4, route: 'clientaccountgrid' },
  { tabID: 5, route: 'clientXpresssHedge' },
  { tabID: 7, route: 'clientOrdersMargin' },
  { tabID: 14, route: 'clientBeneficiaries' },
  { tabID: 13, route: 'attachments' },
  { tabID: 12, route: 'clientnotes' },
];

export const DefaultPageOptions = [5, 10, 20, 50, 100];
export const DefaultPageSize = 20;
export const ClientGeneral_TabID = 10;
export const ClientNotes_TabID = 12;
export const ClientAttachments_TabID = 13;
export const ClientBeneficiaries_TabID = 14;
export const ClientOrdersMargin_TabID = 7;
export const ClientContact_TabID = 15;
export const ExpectedVolumnPerAnnumMaxLength = 22;
export const CreditLimitScaleMaxLength = 4;

export const POPDescription =
  'Transaction is an advance payment to the supplier';

export const RoutingTypesAndLabels = [
  { routingtype: 6, rotuingLable: 'Routing No' },
  { routingtype: 5, rotuingLable: 'Routing No' },
  { routingtype: 1, rotuingLable: 'SWIFT' },
  { routingtype: 3, rotuingLable: 'SWIFT' },
  { routingtype: 2, rotuingLable: 'IBAN' },
  { routingtype: 7, rotuingLable: 'BSB No' },
];

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};