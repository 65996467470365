import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ClientGeneralService } from '../../services/ClientGeneral/client-general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import {
  ErrorMessages,
  GeneralTabConstant,
  SpinnerMessage,
  SystemConstant,
} from '../../global/global.constant';
import {
  BusinessLeagalStructure,
  ClientData,
  ClientListData,
  ClientTypes,
  FieldPermission,
  TabPermission,
} from './clientGeneral.model';
import Swal from 'sweetalert2';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-client-general',
  templateUrl: './client-general.component.html',
  styleUrl: './client-general.component.css',
})
export class ClientGeneralComponent implements OnInit {
  clientId: any;
  clientTypes: ClientTypes[] = [];
  businessLegalStructures: BusinessLeagalStructure[] = [];
  uniqueClientCode: boolean = true;
  uniqueAlias: boolean = true;
  uniqueClientAccount: boolean = true;
  fieldPermissions: FieldPermission[] = [];
  tabPermissions: TabPermission[] = [];
  editMode = false;
  loading = false;
  loaderMessage = SpinnerMessage.Loading;
  tabCanInsert: boolean = false;
  generalTabFP: FieldPermission[] = [];
  generalFildsViewFlag: boolean[] = [];
  maxDate: Date = new Date();
  expirationMinDate: Date = new Date();
  businessOtherFlag: boolean = true;
  DateValidationToolTip: string = ErrorMessages.Valid_Date;
  LieDateValidationToolTip: string = ErrorMessages.Valid_Date;
  acnErrorTitile: string = ErrorMessages.ACN_require;
  abnErrorTitile: string = SystemConstant.AbnTitle;
  isBLSDisabled: boolean=false;
  constructor(
    private fb: FormBuilder,
    private clientGeneralService: ClientGeneralService,
    private route: ActivatedRoute,
    private encryptPermission: EncryptRolePermissionService,
    private router: Router,
    private alert: SweetalertService
  ) {
    this.expirationMinDate=this.getTomorrowDate();
    this.getrolePermission();
  }
  getTomorrowDate(): Date {
    const today : Date = new Date();
    const tomorrow : Date= new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  }
  clientGeneralForm: FormGroup = this.fb.group({
    clientID: [],
    clientName: ['', [Validators.required]],
    clientCode: [null, [Validators.required]],
    tradingName: [null],
    iceAlias: ['', [Validators.required]],
    clientType: [[], [Validators.required]],
    isActive: [false],
    acn: [null, [Validators.required]],
    abn: [null, [Validators.pattern('/^d{3} d{3} d{3}$/g')]],
    acnabnRegDate: [
      null,
      [Validators.required, this.pastDateValidator.bind(this)],
    ],
    email: [null, [Validators.required, Validators.email]],
    natureOfBusiness: [null, [Validators.required]],
    phone: [null, [Validators.required]],
    businessLegalStructureID: [[], [Validators.required]],
    mobile: [null],
    businessLegalStructureOther: [null],
    website: [null, [Validators.required]],
    leiNumber: [null],
    leiExpirationDate: [null],
  });
  pastDateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const selectedDate = new Date(control.value);
    if (selectedDate > this.maxDate) {
      return { futureDateNotAllowed: true };
    }
    return null;
  }

  getTooltipTitle() {
    const control = this.clientGeneralForm.controls['acnabnRegDate'];
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (control.touched && control.invalid) {
      if (control.errors?.['matDatepickerParse']) {
        if (!dateRegex.test(control.errors?.['matDatepickerParse'].text)) {
          this.DateValidationToolTip = ErrorMessages.Valid_Date;
        } else {
          const [day, month, year] = control.errors?.['matDatepickerParse'].text
            .split('/')
            .map(Number);
          const inputDate = new Date(year, month - 1, day);
          if (inputDate > this.maxDate) {
            this.DateValidationToolTip = ErrorMessages.Future_Date;
          }
          if (inputDate < this.maxDate) {
            this.clientGeneralForm.get('acnabnRegDate')?.setErrors(null);
            this.clientGeneralForm.get('acnabnRegDate')?.setValue(inputDate);
          }
        }
      }
    }
    return '';
  }

  ngOnInit() {
    this.clientGeneralForm.reset();
    this.loading = true;
    if (this.businessOtherFlag) {
      this.clientGeneralForm.controls['businessLegalStructureOther'].disable();
    }
    this.getAllListData().then(() => {
      this.route.paramMap.subscribe((parameterMap) => {
        this.clientId = +parameterMap.get('ClientID')!;
        if (this.clientId) {
          this.clientGeneralForm.controls['clientID'].setValue(this.clientId);
          this.getClientDetailsForEdit(this.clientId);
          this.editMode = true;
        }
        else{
          this.editMode=false;
          this.generalFildsViewFlag=Array(17).fill(true);
          this.clientGeneralForm.enable();
          this.checkDisableOther();

        }
      });
    
      this.loading = false;
    });
  }

  checkValidAcn() {
    const acnRegex: RegExp = new RegExp(/^\d{3} \d{3} \d{3}$/g);
    let inputValue: string = this.clientGeneralForm.controls['acn'].value;
    if (inputValue == null || inputValue == '') {
      this.clientGeneralForm.get('acn')?.setErrors({ require: true });
      this.acnErrorTitile = ErrorMessages.ACN_require;
    } else if (!acnRegex.test(inputValue)) {
      this.clientGeneralForm.get('acn')?.setErrors({ invalidAcn: true });
      this.acnErrorTitile = ErrorMessages.ACN_Valid;
    } else {
      this.acnErrorTitile = SystemConstant.AbnTitle;
    }
  }
  checkValidAbn() {
    const abnRegex: RegExp = new RegExp(/^\d{2} \d{3} \d{3} \d{3}$/g);
    let inputValue: string = this.clientGeneralForm.controls['abn'].value;
    if (inputValue == null || inputValue == '') {
      this.clientGeneralForm.get('abn')?.setErrors(null);
      this.abnErrorTitile = 'Australian Business Number';
    } else if (!abnRegex.test(inputValue)) {
      this.clientGeneralForm.get('abn')?.setErrors({ invalidAbn: true });
      this.abnErrorTitile = ErrorMessages.ABN_Valid;
    } else {
      this.clientGeneralForm.get('abn')?.setErrors(null);
      this.abnErrorTitile = SystemConstant.AbnTitle;
    }
  }

  checkDisableOther() {
    if(!this.isBLSDisabled){
    if (
      this.clientGeneralForm.controls['businessLegalStructureID'].value ==
      GeneralTabConstant.businessLeagalOtherid 
    ) {
      this.clientGeneralForm.controls['businessLegalStructureOther'].enable();
    } else {
      this.clientGeneralForm.controls['businessLegalStructureOther'].reset();
      this.clientGeneralForm.controls['businessLegalStructureOther'].disable();
    }
    }
  }
  getAllListData() {
    const promise = new Promise<void>((resolve) => {
      this.clientGeneralService.getAllListData().subscribe({
        next: (result: ClientListData) => {
          this.clientTypes = result.clientTypes;
          this.businessLegalStructures = result.businessLegalStructures;
          resolve();
        },
        error: (e) => {},
      });
    });

    return promise;
  }
  isSaveButtonDisabled(): boolean {
    if (this.tabCanInsert) {
      return !this.clientGeneralForm.valid;
    } else {
      return true;
    }
  }
  setRolePermission() {
    let permission = this.tabPermissions.find(
      (tab: TabPermission) => tab.tabID == GeneralTabConstant.tabId
    );
    if (permission && permission.canInsert == GeneralTabConstant.disable) {
      this.tabCanInsert = false;
      this.clientGeneralForm.reset();
      this.clientGeneralForm.setErrors({ noPermission: true });
      this.clientGeneralForm.disable();
    } else {
      this.tabCanInsert = true;
    }
    this.generalTabFP = this.fieldPermissions.filter(
      (item: { fieldID: number }) =>
        (item.fieldID >= GeneralTabConstant.firstFieldId &&
          item.fieldID <= GeneralTabConstant.lastFieldId) ||
        [
          GeneralTabConstant.ClientNameFiledID,
          GeneralTabConstant.fieldID1,
          GeneralTabConstant.fieldID2,
          GeneralTabConstant.fieldID3,
        ].includes(item.fieldID)
    );
    this.generalTabFP.forEach(
      (field: { fieldID: number; canView: number; canInsert: number }) => {
        switch (field.fieldID) {
          case 1:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[16] = true)
              : (this.generalFildsViewFlag[16] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['clientName'].disable();
            }
            break;

          case 9:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[0] = true)
              : (this.generalFildsViewFlag[0] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['isActive'].disable();
            }
            break;
          case 17:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[1] = true)
              : (this.generalFildsViewFlag[1] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['iceAlias'].disable();
            }
            break;

          case 18:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[2] = true)
              : (this.generalFildsViewFlag[2] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['clientCode'].disable();
            }
            break;
          case 61:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[3] = true)
              : (this.generalFildsViewFlag[3] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['tradingName'].disable();
            }
            break;
          case 62:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[4] = true)
              : (this.generalFildsViewFlag[4] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['clientType'].disable();
            }
            break;

          case 63:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[5] = true)
              : (this.generalFildsViewFlag[5] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['acn'].disable();
            }
            break;
          case 64:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[6] = true)
              : (this.generalFildsViewFlag[6] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['abn'].disable();
            }
            break;
          case 65:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[7] = true)
              : (this.generalFildsViewFlag[7] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['acnabnRegDate'].disable();
            }
            break;
          case 66:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[8] = true)
              : (this.generalFildsViewFlag[8] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['natureOfBusiness'].disable();
            }
            break;
          case 67:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[9] = true)
              : (this.generalFildsViewFlag[9] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.isBLSDisabled=true;
              this.clientGeneralForm.controls[
                'businessLegalStructureID'
              ].disable();
              this.clientGeneralForm.controls[
                'businessLegalStructureOther'
              ].disable();
            }
            else{
              this.isBLSDisabled=false;

            }
            break;
          case 68:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[10] = true)
              : (this.generalFildsViewFlag[10] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['leiNumber'].disable();
            }
            break;
          case 69:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[11] = true)
              : (this.generalFildsViewFlag[11] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['leiExpirationDate'].disable();
            }
            break;
          case 70:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[12] = true)
              : (this.generalFildsViewFlag[12] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['email'].disable();
            }
            break;
          case 71:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[13] = true)
              : (this.generalFildsViewFlag[13] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['phone'].disable();
            }
            break;
          case 72:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[14] = true)
              : (this.generalFildsViewFlag[14] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['mobile'].disable();
            }
            break;
          case 73:
            field.canView == GeneralTabConstant.canView
              ? (this.generalFildsViewFlag[15] = true)
              : (this.generalFildsViewFlag[15] = false);
            if (field.canInsert == GeneralTabConstant.disable) {
              this.clientGeneralForm.controls['website'].disable();
            }
            break;
        }
      }
    );
  }

  getrolePermission(): void {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
        this.fieldPermissions =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).fieldPermissions;
        this.tabPermissions =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).tabPermissions;
        this.setRolePermission();
      }
    }
  }

  getClientDetailsForEdit(id: number) {
    this.loading=true;
    this.clientGeneralService.getClientDetails(id).subscribe({
      next: (result: ClientData) => {
        result.acnabnRegDate = new Date(result.acnabnRegDate).toISOString();
        this.clientGeneralForm.patchValue(result);
        this.clientGeneralForm.get('abn')?.setErrors(null);
        this.checkDisableOther();
        this.loading=false;
      },
      error: (error) => {
        this.alert.showError(error, ErrorMessages.Client_Data);
      },
    });
  }

  validateCLientCode() {
    var clientcode = this.clientGeneralForm.value.clientCode;
    var clientid = this.clientGeneralForm.value.clientID;
    if (clientcode?.length != 0) {
      this.clientGeneralService
        .checkUniqueClientCode(clientcode, this.editMode ? clientid : 0)
        .subscribe({
          next: (result: boolean) => {
            this.uniqueClientCode = result;
            if (!this.uniqueClientCode) {
              Swal.fire({
                icon: 'warning',
                title: ErrorMessages.UniqueClientCode,
                confirmButtonText: 'OK',
                showConfirmButton: true,
                timer: 3500,
              });
            }
          },
          error: (e) => {},
        });
    }
  }

  validateAlias() {
    var alias = this.clientGeneralForm.value.iceAlias;
    var clientid = this.clientGeneralForm.value.clientID;
    if (alias?.length != 0) {
      this.clientGeneralService
        .checkUniqueAlias(alias, this.editMode ? clientid : 0)
        .subscribe({
          next: (result: boolean) => {
            this.uniqueAlias = result;
            if (!this.uniqueAlias) {
              Swal.fire({
                icon: 'warning',
                title: ErrorMessages.UniqueAlias,
                confirmButtonText: 'OK',
                showConfirmButton: true,
                timer: 3500,
              });
            }
          },
          error: (e) => {},
        });
    }
  }

  onSave() {
    if (this.clientGeneralForm.valid) {
      var formValue: ClientData = this.clientGeneralForm.getRawValue();
      let lieExpDateForPayload=null;
      if(formValue.leiExpirationDate){
      let lieExpDate: Date = new Date(formValue.leiExpirationDate);
       lieExpDateForPayload= new Date(
        lieExpDate.getFullYear(),
        lieExpDate.getMonth(),
        lieExpDate.getDate(),
        lieExpDate.getHours(),
        lieExpDate.getMinutes() - lieExpDate.getTimezoneOffset()
      ).toISOString();
      }
      else{
        lieExpDateForPayload=null;
      }
      let acnabnRegDate: Date = new Date(formValue.acnabnRegDate);     

      if (this.editMode) {
        this.loaderMessage = SpinnerMessage.Update;
        this.loading = true;
        var payload = {
          clientID: formValue.clientID,
          clientName: formValue.clientName,
          clientCode: formValue.clientCode,
          tradingName: formValue.tradingName,
          iceAlias: formValue.iceAlias,
          clientType: formValue.clientType,
          isActive: formValue.isActive? true : false,
          acn: formValue.acn,
          abn: formValue.abn,
          acnabnRegDate: new Date(
            acnabnRegDate.getFullYear(),
            acnabnRegDate.getMonth(),
            acnabnRegDate.getDate(),
            acnabnRegDate.getHours(),
            acnabnRegDate.getMinutes() - acnabnRegDate.getTimezoneOffset()
          ).toISOString(),
          email: formValue.email,
          natureOfBusiness: formValue.natureOfBusiness,
          phone: formValue.phone,
          businessLegalStructureID: formValue.businessLegalStructureID,
          mobile: formValue.mobile,
          businessLegalStructureOther: formValue.businessLegalStructureOther,
          website: formValue.website,
          leiNumber: formValue.leiNumber,
          leiExpirationDate: lieExpDateForPayload,
        };

        this.clientGeneralService.updateClient(payload).subscribe({
          next: (result: any) => {
            this.loading = false;
            this.alert.fireAlert('Updated').then((_) => {
              this.router.navigate(['Admin/manageclient']);
            });
          },
          error: (e) => {
            this.alert.showError(e, ErrorMessages.UpdateFailed);
            this.loading = false;
          },
        });
      } else {
        this.loaderMessage = SpinnerMessage.Saving;
        this.loading = true;
        var payloadAdd = {
          clientID: 0,
          clientName: formValue.clientName,
          clientCode: formValue.clientCode,
          tradingName: formValue.tradingName,
          iceAlias: formValue.iceAlias,
          clientType: formValue.clientType,
          isActive: formValue.isActive? true : false,
          acn: formValue.acn,
          abn: formValue.abn,
          acnabnRegDate: new Date(
            acnabnRegDate.getFullYear(),
            acnabnRegDate.getMonth(),
            acnabnRegDate.getDate(),
            acnabnRegDate.getHours(),
            acnabnRegDate.getMinutes() - acnabnRegDate.getTimezoneOffset()
          ).toISOString(),
          email: formValue.email,
          natureOfBusiness: formValue.natureOfBusiness,
          phone: formValue.phone,
          businessLegalStructureID: formValue.businessLegalStructureID,
          mobile: formValue.mobile,
          businessLegalStructureOther: formValue.businessLegalStructureOther,
          website: formValue.website,
          leiNumber: formValue.leiNumber,
          leiExpirationDate: lieExpDateForPayload,
        };
        this.clientGeneralService.addClient(payloadAdd).subscribe({
          next: (result: any) => {
            this.loading = false;
            this.alert.fireAlert('Saved').then((_) => {
              this.router.navigate(['Admin/manageclient']);
            });
          },
          error: (e) => {
            this.loading = false;
          },
        });
      }
    }
  }
  checkLeiExpirationDate() {
    const control = this.clientGeneralForm.controls['leiExpirationDate'];
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (control.touched && control.invalid) {
      if (control.errors?.['matDatepickerParse']) {
        if (!dateRegex.test(control.errors?.['matDatepickerParse'].text)) {
          this.LieDateValidationToolTip = ErrorMessages.Valid_Date;
        } else {
          const [day, month, year] = control.errors?.['matDatepickerParse'].text
            .split('/')
            .map(Number);
          const inputDate = new Date(year, month - 1, day);
          if (inputDate < this.maxDate) {
            this.LieDateValidationToolTip = ErrorMessages.Past_Date;
          }
          if (inputDate > this.maxDate) {
            this.clientGeneralForm.get('leiExpirationDate')?.setErrors(null);
            this.clientGeneralForm
              .get('leiExpirationDate')
              ?.setValue(inputDate);
          }
        }
      }
    }
  }
  cancel() {
    if(this.editMode){
      this.clientGeneralForm.reset();
      this.getClientDetailsForEdit(this.clientId);  
    }
    this.clientGeneralForm.reset();
  }
}
