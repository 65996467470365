import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  EmailClientAttachments,
  GetAttachmentsResponse,
  UploadAttachmentResponse,
} from '../../components/attachments-tab/models/attachments.tab.models';

@Injectable()
export class AttachmentsTabService {
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl;

  uploadAttachmentToS3(file: any): Observable<UploadAttachmentResponse> {
    return this.http.post<UploadAttachmentResponse>(this.apiUrl + 'Client/uploadattachment', file);
  }

  getAttachments(clientId: number): Observable<GetAttachmentsResponse[]> {
    return this.http.get<GetAttachmentsResponse[]>(this.apiUrl + `Client/getattachments?clientID=${clientId}`);
  }

  detachAttachment(clientFileIds: number[]): Observable<UploadAttachmentResponse> {
    return this.http.patch<UploadAttachmentResponse>(this.apiUrl + `Client/detachattachment`, clientFileIds);
  }

  downloadClientAttachment(clientFileIds: number[]): Observable<any> {
    let headers = new HttpHeaders().set('Accept', 'application/pdf');
    return this.http.post(this.apiUrl + 'Client/downloadattachment', clientFileIds, {
        headers: headers,
        responseType: 'blob' as 'json'
    });
  }

  sendEmail(payload :EmailClientAttachments):Observable<EmailClientAttachments>{
    return this.http.post<EmailClientAttachments>(this.apiUrl+'Client/emailclientattachment',payload);
  }
}
