import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientBeneficiaryService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getClientBeneficiariesAdmin(clientID: number) {
    return this.http.get(this.apiUrl + 'Client/Beneficiaries/Admin/' + clientID);
  }

  getClientBeneficiariesClient() {
    return this.http.get(this.apiUrl + 'Client/Beneficiaries/Client');
  }
}
