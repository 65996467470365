import { Component, ElementRef } from '@angular/core';
import {
  ClientNotes_TabID,
  DefaultPageOptions,
  DefaultPageSize,
  ErrorMessages,
  SpinnerMessage,
} from '../../global/global.constant';
import {
  ClientNote,
  ClientNoteRequest,
} from '../client-note-tab/models/ClientNote.model';
import { ClientNoteService } from '../../services/ClientNote/client-note.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { RolePermissonService } from '../../services/Permission/role-permisson.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-client-note-tab',
  templateUrl: './client-note-tab.component.html',
  styleUrl: './client-note-tab.component.css',
})
export class ClientNoteTabComponent {
  // DECLARE VARIABLE
  dataLoaded: boolean = false;
  loadingMessage = SpinnerMessage.Loading;
  addButtonHidden: boolean = false;
  editButtonHidden: boolean = false;
  selectedRow: ClientNote | undefined;
  pageSizeOptions = DefaultPageOptions;
  pageSize = DefaultPageSize;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  noteEditMode: boolean=false;
  pageEvent: PageEvent | undefined;

  clientMode = false;
  clientNoteForm!: FormGroup;
  clientID: number = 0;
  clientNoteData: ClientNote[] = [];
  paginatedData: ClientNote[] = [];
  tabPermissions!: any;
  editMode: boolean = false;
  disabled = false;
  roleID: number = 0;

  constructor(
    private clientNoteService: ClientNoteService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private alert: SweetalertService,
    private roleService: RolePermissonService,
    private encryptPermission: EncryptRolePermissionService
  ) {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      this.roleID =
        this.encryptPermission.getDecryptedPermissions('roleNgp').roleID;
    }
  }

  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.clientMode = false;
    } else if (user === 'Client') {
      this.clientMode = true;
    }

    this.clientNoteForm = this.fb.group({
      clientNoteID: [0],
      clientID: [],
      title: [null, [Validators.required]],
      details: ['', [Validators.required]],
    });

    if (this.clientMode) {
      this.clientNoteForm.disable();
    }

    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
    });

    this.clientNoteForm.value.clientID = this.clientID;
    this.getClientNoteData();
    this.getPermission();
    this.dataLoaded = false;
    this.length = this.clientNoteData.length;
    this.paginatedData = this.clientNoteData.slice(0, this.pageSize);
  }

  getClientNoteData() {
    this.dataLoaded = false;

    this.clientNoteService.getClientNotes(this.clientID).subscribe({
      next: (result: any) => {
        this.clientNoteData = result;
        this.length = this.clientNoteData.length;
        this.paginatedData = this.clientNoteData.slice(0, this.pageSize);
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  openModal(noteEditMode:boolean,row?: ClientNote): void {
    this.selectedRow = row;
    this.noteEditMode =noteEditMode;
    if (!this.selectedRow) {
      this.editMode = false;
      this.clientNoteForm.reset();
    } else {
      this.editMode = true;
      this.clientNoteForm.patchValue({
        clientNoteID: row?.clientNoteID,
        clientID: row?.clientID,
        title: row?.title,
        details: row?.details,
        createdByUserID: row?.createdByUserID,
        creationDate: row?.creationDate,
        modifiedByUserID: row?.modifiedByUserID,
        modificationDate: row?.modificationDate,
      });
    }

    const modal = this.elementRef.nativeElement.querySelector(
      '#updateClientContact-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.appendChild(backdrop);
      modal.focus();
    }
  }

  close() {
    this.loadingMessage = 'Loading';
    const modal = this.elementRef.nativeElement.querySelector(
      '#updateClientContact-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';
    this.getClientNoteData();
  }

  sortTable(str: string) {}

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clientNoteData.slice(startIndex, endIndex);
  }

  onSave() {
    if (this.clientNoteForm.valid) {
      var formValue = this.clientNoteForm.value;

      var payload: ClientNoteRequest = {
        clientNoteID: 0,
        clientID: this.clientID,
        title: formValue.title,
        details: formValue.details,
      };

      if (this.editMode) {
        this.loadingMessage = SpinnerMessage.Update;
        this.dataLoaded = false;

        payload.clientNoteID = this.selectedRow?.clientNoteID;
        this.clientNoteService.updateClientNote(payload).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.alert.fireAlert('Updated').then((_) => {
              this.close();
            });
          },
          error: (response: any) => {
            this.dataLoaded = true;
            this.displayBackEndErrorMessage(response);
          },
        });
      } else {
        this.loadingMessage = SpinnerMessage.Saving;
        this.dataLoaded = false;
        this.clientNoteService.addClientNote(payload).subscribe({
          next: (result: any) => {
            this.dataLoaded = true;
            this.alert.fireAlert('Saved').then((_) => {
              this.close();
            });
          },
          error: (response: any) => {
            this.dataLoaded = true;
            this.displayBackEndErrorMessage(response);
          },
        });
      }
    } else {
      this.markFormGroupTouched(this.clientNoteForm);
    }
  }

  private displayBackEndErrorMessage = (response: any): void => {
    if (response && response.error && response.error.errors) {
      if (response.error.errors.FirstName) {
        this.showMessage(response.error.errors.FirstName[0]);
      } else if (response.error.errors.LastName) {
        this.showMessage(response.error.errors.LastName[0]);
      } else if (response.error.errors.PhoneNumber) {
        this.showMessage(response.error.errors.PhoneNumber[0]);
      } else if (response.error.errors.EmailAddress) {
        this.showMessage(response.error.errors.EmailAddress[0]);
      } else if (response.error.errors.UserName) {
        this.showMessage(response.error.errors.UserName[0]);
      } else {
        this.showMessage(
          ErrorMessages.Error_occurred_while_processing_your_request
        );
      }
    }
  };

  private showMessage = (message: string): void => {
    Swal.fire({
      icon: 'warning',
      title: message,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
  };

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Swal.fire({
      icon: 'warning',
      title: ErrorMessages.Please_fill_required_inputs,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  };

  displayRowDetails(rowDetails: string) {
    return rowDetails.length > 30
      ? rowDetails.substring(0, 30) + '...'
      : rowDetails;
  }

  getPermission(): void {
    this.roleService.getRolePermissionData(this.roleID).subscribe({
      next: (data: any) => {
        this.tabPermissions = data.tabPermissions;
        if (this.tabPermissions) {
          let permission = this.tabPermissions.filter(
            (data: { tabID: number }) => data.tabID == ClientNotes_TabID
          );
          if (permission.length > 0) {
            this.addButtonHidden = permission[0].canInsert ? false : true;
            this.editButtonHidden = permission[0].canInsert ? false : true;
          }
        }
      },
      error: () => {},
    });
  }

  convertUTCToLocalWithTimezone(utcDateStr: Date | null): Date | null {
    if (utcDateStr != null) {
      let sDate = new Date(utcDateStr);
      const timezoneOffset = sDate.getTimezoneOffset();
      return new Date(sDate.getTime() - timezoneOffset * 60 * 1000);
    } else {
      return null;
    }
  }
}
