import { Component, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ReferralPartnerService } from '../../../services/referralPartner/referral-partner.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { HeaderComponent } from '../../Home/header/header.component';

@Component({
  selector: 'app-referral-partner',
  templateUrl: './referral-partner.component.html',
  styleUrl: './referral-partner.component.css'
})
export class ReferralPartnerComponent {
  searchString: string = '';

  sortedColumn: string = '';
  isAscending: boolean = true;
  clients: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  @ViewChild(HeaderComponent, { static: false })
  headerComponent!: HeaderComponent;
  isNavBarOpen: boolean = true;
  hideAddButton = false;

  /* Default Table options */
  pageSizeOptions= [5, 10, 20];
  pageSize= 20;
  pageIndex= 0;
  length= 0;
  hidePageSize= false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled= false;
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private referralPartnerService: ReferralPartnerService,
    private encryptPermission: EncryptRolePermissionService
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 31
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
    }
  }

  ngOnInit(){
    this.getreferralPartnerData();
  }

  getreferralPartnerData(){
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.referralPartnerService.getreferralPartnerList(this.searchString).subscribe({
        next: (data: any) => {
          this.clients = data;
          this.length = this.clients.length;
          this.paginatedData = this.clients.slice(0, this.pageSize);
          this.dataLoaded = true;
          resolve();
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    });
    return promise;
  }

  sort(columnName: string): void {
    this.clients.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    this.dataLoaded = false;

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clients.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.clients.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  search(){
    this.getreferralPartnerData();
  }

  reset(){
    this.searchString = "";
    this.ngOnInit();
  }

  addReferralPartner() {
    // this.router.navigate(['/referralPartnertab']);
    this.router.navigate(['/Admin/referralPartnertab']);
  }

  editReferralPartner(referralPartnerID: number) {
    
    this.router.navigate(['/Admin/referralPartnertab', referralPartnerID, { outlets: { 'referralPartner': ['referralPartnerGeneral',referralPartnerID] } }]);
  }
}