import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferredClientService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getReferredCliendData(referralPartnerID: number): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner/ReferredClients/Getall/' + referralPartnerID;
    return this.http.get<any>(url, httpOptions);
  }

  getClientData(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner/ReferredClients/GetLists';
    return this.http.get<any>(url, httpOptions);
  }

  getSpecificReferredClient(referralPartnerClientId: number){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    let url = this.apiUrl + 'ReferralPartner/ReferredClients/' + referralPartnerClientId;
    return this.http.get<any>(url, httpOptions);
  }

  addReferredClientData(referredClientForm: any){
    let payload = {
      ReferralPartnerID: referredClientForm.ReferralPartnerID,
      ReferredClientID: referredClientForm.ReferredClientID,
      PeriodInMonths: referredClientForm.PeriodInMonths,
    };
    let url = this.apiUrl + 'ReferralPartner/ReferredClients';
    return this.http.post<any>(url, payload);
  }

  updateReferredClientData(referredClientForm: any){
    let payload = {
      ReferralPartnerClientID: referredClientForm.ReferralPartnerClientID,
      ReferralPartnerID: referredClientForm.ReferralPartnerID,
      PeriodInMonths: referredClientForm.PeriodInMonths,
    };
    let url = this.apiUrl + 'ReferralPartner/ReferredClients';
    return this.http.patch<any>(url, payload);
  }

  deleteReferredClient(referralPartnerClientId: number){
    const payloadData = {
      referralPartnerClientID: referralPartnerClientId,
    };
    let url = this.apiUrl + 'ReferralPartner/ReferredClients';
    return this.http.delete<any>(url, {headers: new HttpHeaders({'Content-Type': 'application/json',}), params : payloadData, withCredentials: true});
  }
}
