import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientacccountgridService } from '../../services/ClientAccountGrid/clientacccountgrid.service';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-account-grid',
  templateUrl: './client-account-grid.component.html',
  styleUrl: './client-account-grid.component.css'
})
export class ClientAccountGridComponent implements OnInit {

  clientID = 1;

  ACCOUNT_DATA: clientAccount[] = [];


  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  disabled = false;

  searchString: string = '';
  sortedColumn: string = '';
  isAscending: boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngOnInit(): void {
    this.route.paramMap.subscribe(parameterMap => {
      this.clientID = +parameterMap.get('ClientID')!;
      
      
    });
    this.getClientAccountData();
    this.length = this.ACCOUNT_DATA.length;
    this.paginatedData = this.ACCOUNT_DATA.slice(0, this.pageSize);
  }

  ngAfterViewInit() {

  }
  constructor(private clientgridservice: ClientacccountgridService,private route:ActivatedRoute) {

  }


  getClientAccountData() {
    this.clientgridservice.getClientAccount(this.clientID).subscribe({
      next: (result: any) => {
        this.ACCOUNT_DATA = result;
        this.length = this.ACCOUNT_DATA.length;
        this.paginatedData = this.ACCOUNT_DATA.slice(0, this.pageSize);

      }
    })
  }

  sort(columnName: string): void {
    this.ACCOUNT_DATA.sort((a: any, b: any) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }
  sortTable(columnName: string) {

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.ACCOUNT_DATA.sort((a: any, b: any) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {

      startIndex = (this.pageIndex - 1) * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.ACCOUNT_DATA.slice(startIndex, endIndex);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {

      startIndex = (this.pageIndex) * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.ACCOUNT_DATA.slice(startIndex, endIndex);
  }
}

export interface clientAccount {

  clientName: string,
  currencyName: string
  accountTypeName: string
  balance: number,

}








