<!-- Tab panes -->
<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">{{ loadingMessage }}...</div>
  </div>
</div>
<div class="compress-inputs-page">
  <div class="tab-content">
    <!-- Client Note Body -->
    <div class="tab-pane active" id="ClientNote">
      <div class="tab-body-wrapper">
        <div class="title">
          <h3>&nbsp;</h3>
          <a [hidden]="addButtonHidden" class="btn btn-success" (click)="openModal(true)">
            <em><img src="../../../assets/plus.svg" alt="" /></em>
            Add
          </a>
        </div>

        <div *ngIf="clientNoteData.length > 0; else notFound" class="table-responsive">
          <table class="table custom-grid">
            <thead>
              <tr class="Row123">
                <th style="width: 10%">
                  <a (click)="sortTable('title')" class="sort active ts-asc" unselectable="on" data-i="0">Title<span
                      class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 15%">
                  <a (click)="sortTable('details')" class="sort active ts-asc" unselectable="on" data-i="1">Details<span
                      class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 5%">
                  <a (click)="sortTable('createdByName')" class="sort active ts-asc" unselectable="on" data-i="2">Added
                    By<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 7%">
                  <a (click)="sortTable('creationDate')" class="sort active ts-asc" unselectable="on" data-i="3">Added
                    Date<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 5%">
                  <a (click)="sortTable('modifiedByName')" class="sort active ts-asc" unselectable="on"
                    data-i="4">Modified By<span class="ts-sord"></span>
                  </a>
                </th>
                <th style="width: 7%">
                  <a (click)="sortTable('modificationDate')" class="sort active ts-asc" unselectable="on"
                    data-i="5">Modified Date<span class="ts-sord"></span>
                  </a>
                </th>
                <th *ngIf="!editButtonHidden" style="width: 5%">
                  <a (click)="sortTable('balance')" class="sort active ts-asc" unselectable="on" data-i="8">Action<span
                      class="ts-sord"></span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of paginatedData">
                <td class="wrapword underline" title="Click to view notes details" (click)="openModal(false,row)">{{ row.title }} </td>
                <td class="wrapword" title="Click to view notes details" (click)="openModal(false,row)">
                  {{ displayRowDetails(row.details) }}
                </td>
                <td class="wrapword" title="Click to view notes details" (click)="openModal(false,row)">{{ row.createdByName
                  }}</td>
                <td class="wrapword" title="Click to view notes details" (click)="openModal(false,row)">
                  {{ convertUTCToLocalWithTimezone(row.creationDate) | date : "dd/MM/yyyy h:mm a"  }}
                </td>
                <td class="wrapword" title="Click to view notes details" (click)="openModal(false,row)">{{ row.modifiedByName
                  }}</td>
                <td class="wrapword" title="Click to view notes details" (click)="openModal(false,row)">
                  {{ convertUTCToLocalWithTimezone(row.modificationDate) | date : "dd/MM/yyyy h:mm a" }}
                </td>
                <td *ngIf="!editButtonHidden">
                  <div class="action-btns">
                    <a (click)="openModal(true,row)" title="Edit"><img src="../../../assets/edit.svg" alt="" /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
            [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndex" aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <ng-template #notFound>
    <div class="alert alert-info">
      <span>No record found</span>
    </div>
  </ng-template>

  <div class="modal secondary backdrop" id="updateClientContact-modal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-dark">Note</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="close()">
            <img src="../../../assets/cross.svg" alt="" />
          </button>
        </div>

        <div class="compress-input-pages modal-body padding-top-40">
          <form class="form" [formGroup]="clientNoteForm" (ngSubmit)="onSave()">
            <div class="row">
              <!-- Title -->
              <div class="col-sm-12">
                <div class="form-group label-inline">
                  <label for="" class="strong">Title<em style="color: red">*</em></label>
                  <div class="showhide" [ngClass]="{
                      'newTooltip expand has-error':
                        clientNoteForm.controls['title'].touched &&
                        clientNoteForm.controls['title'].invalid
                    }" datatitle="Title is required">
                    <input type="text" class="form-control" formControlName="title" [readOnly]="editButtonHidden" />
                  </div>
                </div>
              </div>

              <!-- Details -->
              <div class="col-sm-12">
                <div class="form-group label-inline">
                  <label for="" class="strong">Details<em style="color: red">*</em></label>
                  <div class="showhide" [ngClass]="{
                      'newTooltip expand has-error':
                        clientNoteForm.controls['details'].touched &&
                        clientNoteForm.controls['details'].invalid
                    }" datatitle="Details is required">
                    <textarea type="text" class="form-control" maxlength="100" formControlName="details" [readOnly]="editButtonHidden"></textarea>
                  </div>
                </div>
              </div>

              <div  class="col-md-12">
                <div *ngIf="!clientMode" class="inline-element d-block text-center">
                  <button *ngIf="!addButtonHidden && noteEditMode" type="submit" class="btn btn-success" >Save</button>
                  <button *ngIf="!addButtonHidden && noteEditMode" type="button" (click)="close()" class="btn btn-secondary">
                    Cancel
                  </button>
                  <button *ngIf="!noteEditMode" type="button" (click)="close()" class="btn btn-success">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>