import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Countries } from '../../components/manage-currencies/models/manage-currencies.model';
import { Address, AddressPatchPaylod } from '../../components/address-tab/models/address.model';
@Injectable({
  providedIn: 'root',
})
export class AddressService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getCountryList(): Observable<Countries> {
    let url: string = this.apiUrl + 'Client/address/getList';
    return this.http.get<Countries>(url);
  }

  getAddress(clientID: number): Observable<Address> {
    let url = this.apiUrl + 'Client/address/' + clientID;
    return this.http.get<Address>(url);
  }

  getList(listClientId: number): Observable<any> {
    let url = this.apiUrl + 'Client/address/lists/' + listClientId;
    return this.http.get<any>(url);
  }

  saveOrUpdateAddress(addressForm: any): Observable<AddressPatchPaylod> {
    for (const key in addressForm) {
      if (addressForm.hasOwnProperty(key) && addressForm[key] === '') {
        addressForm[key] = null; // Replacing empty string with null
      }
    }
    let payload = {
      clientID: addressForm.clientID,
      regStreetAddress: addressForm.regStreetAddress,
      regSuburb: addressForm.regSuburb,
      regState: addressForm.regState,
      regPostCode: addressForm.regPostCode,
      regCountryID: addressForm.regCountryID,
      sameAsRegAddress: addressForm.sameAsRegAddress,
      ppbStreetAddress: addressForm.ppbStreetAddress,
      ppbSuburb: addressForm.ppbSuburb,
      ppbState: addressForm.ppbState,
      ppbPostCode: addressForm.ppbPostCode,
      ppbCountryID: addressForm.ppbCountryID,
    };
    let url = this.apiUrl + 'Client/address';
    return this.http.patch<AddressPatchPaylod>(url, payload);
  }
}
