import { HttpClient } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { DashboardService } from '../../../services/DashboardService/dashboard-service.service';
import { text } from 'stream/consumers';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrl: './news-feed.component.css'
})
export class NewsFeedComponent {

  newsItems: any[] = [];
  newsItemsToShow:any[]=[];
  endIndex=4;
  count=1;

  constructor(private dashboardService: DashboardService,private el:ElementRef) { }

  ngOnInit(): void {
    this.getNewsfeed();
   
  }



 

  getNewsfeed(){
    this.dashboardService.getNews().subscribe((data: string) => {
      // Parse XML using DOMParser
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');

      // Extract necessary data from XML DOM
      
      const items = xmlDoc.querySelectorAll('item');
      items.forEach(item => {
        const title = item.querySelector('title')?.textContent || '';
        const description = item.querySelector('description')?.textContent || '';
        const link = item.querySelector('link')?.textContent || '';
        const pubDate = item.querySelector('pubDate')?.textContent || '';

        // Push extracted data as objects into newsItems array
        this.newsItems.push({ title, description, link, pubDate });
      });
      
      this.newsItemsToShow = this.newsItems.slice(0,this.endIndex);
      
    });

  }

  viewMore(){
    
    if(this.endIndex*this.count<=this.newsItems.length-1){
      this.count+=1;
      this.newsItemsToShow=this.newsItems.slice(0,this.endIndex*this.count)
    }
  }
  viewLess(){
    this.count-=1;
    if(this.count>0){

      this.newsItemsToShow=this.newsItems.slice(0,this.endIndex*this.count)

    }

  }

}
