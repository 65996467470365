import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardService } from '../../../services/DashboardService/dashboard-service.service';

@Component({
  selector: 'app-rate-charts',
  templateUrl: './rate-charts.component.html',
  styleUrl: './rate-charts.component.css'
})
export class RateChartsComponent {

  @Input('SpotRateList') SpotRateList!: Observable<any>;
  @Output() toggleSize = new EventEmitter<boolean>(false);
  symbols = '';
  maximize=false;
  dataLoaded: boolean=false;
  SpotRateShowList:any;
  constructor(private el: ElementRef,private dashboardService:DashboardService) {}

  ngAfterViewInit() {
    // this.tradingviewChart();
  }

  selectedSymbol:any;

  ngOnChanges(){
    this.SpotRateList?.subscribe({
      next:(data)=>{
        this.SpotRateShowList=data;
        this.selectedSymbol=this.SpotRateShowList[0];
        this.tradingviewChart();
      }
    });

  }
  onCurrencyPairSelect(event: any) {
    this.dataLoaded = false;
    this.selectedSymbol = event;
    
    this.tradingviewChart();
    this.dataLoaded=true;
  }

  tradingviewChart() {
   
    const container = this.el.nativeElement.querySelector(
      '.tradingview-widget-container'
    );
    if (!container) {
      return;
    }
    // Clear existing content
    container.innerHTML = '';
    const script = document.createElement('script');
    let symbol = this.selectedSymbol.currencyPair;
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    {
      "width": "100%",
      "height": "300",
      "autosize": true,
      "symbol": "${symbol}",
      "interval": "5",
      "timezone": "Australia/Sydney",
      "theme": "light",
      "style": "1",
      "locale": "en",
      "allow_symbol_change": true
     
    }`;
    this.el.nativeElement
      .querySelector('.tradingview-widget-container')
      .appendChild(script);
  }


  sizeChange(){
    this.maximize=!this.maximize;
    this.toggleSize.emit(this.maximize);

  }

}
