import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BankAccount, BankAddUpdateRequest, BankDetails, BankTabDropDownList } from '../../components/banking-tab/models/banking.model';

@Injectable({
  providedIn: 'root'
})
export class BankingService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getDropDownList(): Observable<BankTabDropDownList> {
    let url = this.apiUrl + 'Client/banking/getLists';
    return this.http.get<BankTabDropDownList>(url);
  }

  getBankList(clientId: number) : Observable<BankAccount[]>{
    let url = this.apiUrl + 'Client/Banking/Lists/' + clientId
    return this.http.get<BankAccount[]>(url);
  }

  getBank(clientBankID: number) : Observable<BankDetails>{
    let url = this.apiUrl + 'Client/Banking/' + clientBankID;
    return this.http.get<BankDetails>(url);
  }

  addClientBank(bankData: BankAddUpdateRequest) : Observable<BankAddUpdateRequest>{
    let url = this.apiUrl + 'Client/Banking';
    return this.http.post<BankAddUpdateRequest>(url, bankData);
  }

  updateClientBank(bankData: any) : Observable<any>{
    let url = this.apiUrl + 'Client/Banking';
    return this.http.patch(url, bankData);
  }  

  setActiveFlag(clientBankID: number, isActive: boolean) : Observable<boolean>{
    let payload = {
      clientBankID: clientBankID,
      isActive: isActive,
    }
    let url: string = this.apiUrl + 'Client/Banking/SetActiveFlag';
    return this.http.patch<boolean>(url, payload);
  }
}
