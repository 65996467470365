import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UserRoleService } from '../../services/UserRole/user-role.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

export interface ContactRole  {
  clientRoleID: number,
  roleName: string,
  description: string,
  isActive: boolean,
  isSystemGenerated: boolean
}

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrl: './user-roles.component.css',
})
export class UserRolesComponent {
  selectedStatus: string = 'Both';
  sortedColumn: string = '';
  isAscending: boolean = true;
  loadingMessage = 'Loading...';
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  paginatedData: ContactRole[] = [];
  pageEvent: PageEvent | undefined;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  roleName: any = null;
  userRoleData: any = [];
  dataLoaded: boolean = false;
  constructor(
    private userRoleService: UserRoleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.search();
    this.length = this.userRoleData.length;
    this.paginatedData = this.userRoleData.slice(0, this.pageSize);
  }
  onRadioChange(status: string) {
    this.selectedStatus = status;
  }
  toggleSwitch(role: any) {
    this.dataLoaded = false;

    role.isActive = !role.isActive;
    this.userRoleService
      .changeStatus(role.clientRoleID, role.isActive)
      .subscribe({
        next: () => {
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
  }

  reset() {
    this.selectedStatus = 'Both';
    this.roleName = '';
    this.search();
  }

  editRole(id: any) {
    this.router.navigate(['/Client/addEditRole', id]);
  }
  Add() {
    this.router.navigate(['/Client/addEditRole']);
  }

  delete(id: any) {
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.userRoleService.deleteRole(id).subscribe({
        next: (data: any) => {
          if (data) {
            this.search().then((_) => {
              this.dataLoaded = true;
              Swal.fire({
                icon: 'success',
                title: 'Data Deleted Successfully.',
                showConfirmButton: false,
                timer: 1500,
              });
            });
          }
          resolve();
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    });
    return promise;
  }

  search() {
    const payload = {
      roleName: this.roleName,
      status: this.selectedStatus,
    };
    return new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.userRoleService.getContactRole(payload).subscribe({
        next: (data) => {
          this.userRoleData = data;
          this.length = this.userRoleData.length;
          this.paginatedData = this.userRoleData.slice(0, this.pageSize);
          this.dataLoaded = true;
          resolve();
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.userRoleData.slice(startIndex, endIndex);
  }

  deleteData(deleteData: any) {
    Swal.fire({
      title:
        'Are you sure you want to remove the client role ' +
        deleteData.roleName +
        ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(deleteData.clientRoleID);
      }
    });
  }
}
