import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../services/userprofile/user-profile.service';
import { ContactInfo, UserProfileFormType } from './models/user-profile.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { ClientInfo } from '../../models/client-info.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.css'
})
export class UserProfileComponent implements OnInit {
  
  isLoading: boolean = false;

  userProfileForm = new FormGroup<UserProfileFormType>({
    contactID: new FormControl<number>(0),
    clientID: new FormControl<number>(0),
    clientName: new FormControl<string>(""),
    isActive: new FormControl<boolean>(false),
    salutation: new FormControl<string>(""),
    firstName: new FormControl<string>(""),
    lastName: new FormControl<string>(""),
    alias: new FormControl<string>(""),
    initial: new FormControl<string>(""),
    designation: new FormControl<string>(""),
    emailAddress: new FormControl<string>(""),
    phoneNumber: new FormControl<number>(0),
    status: new FormControl<string>(""),
    userName: new FormControl<string>(""),
    isPrimaryContact: new FormControl<boolean>(false),
    clientRoleID: new FormControl<number>(0),
    themeID: new FormControl<string>("")
  });
  constructor(
    public userProfileService: UserProfileService,
    private encryptPermission: EncryptRolePermissionService
  ) { }

  ngOnInit(): void {
    const clientInfo: ClientInfo = this.encryptPermission.getDecryptedPermissions('usercrd');
    this.getContactDetails(clientInfo.contactID);
  }

  private getContactDetails (contactId: number): void  {
    if (contactId > 0) {
      this.isLoading = true;
      this.userProfileService.getContactDetails(contactId).pipe(untilDestroyed(this)).subscribe({
        next: (response: ContactInfo) => {
          this.userProfileForm.patchValue(response);
          this.userProfileForm.disable();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;     
        }
      });
    }
  }
}

