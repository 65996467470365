<!-- <app-header></app-header> -->

<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>
<div [ngClass]="{'collapseNav':!isNavBarOpen}">
    <div class="inner user-page">
        <div class="title">
            <h3>&nbsp;</h3>

            <a *ngIf="!hideAddButton" id="btnClientAddNew" (click)="addClient()" class="btn btn-success">
                <em><img src="../../../assets/plus.svg" alt=""></em>
                Add
            </a>
        </div>

        <div class="card maindiv">
            <form [formGroup]="clientSearchForm" (ngSubmit)="search()">
                <div class="row search-criteria">
                    <div class="col-md-2 col-sm-4">
                        <div class="form-group ">
                            <label class="textstyle">Tenant/CAR Partner</label>
                            <ng-select 
                            [items]="tenantCARs" 
                            formControlName="selectedtenantCARPartnerName"
                            bindLabel="tenantCARPartnerName" 
                            bindValue="tenantCARPartnerName" 
                            placeholder="Select"
                            (keydown.enter)="search()"
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-2 col-sm-4">
                        <div class="form-group ">
                            <label class="textstyle">Client Type</label>
                            <ng-select 
                            [items]="clientTypes" 
                            formControlName="selectedClientType" 
                            bindLabel="typeName"
                            bindValue="typeName" 
                            (keydown.enter)="search()"
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                            placeholder="Select"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <div class="form-group ">
                            <label class="textstyle">Status</label>
                            <div class="checkbox-block">
                                <div class="form-check-inline">
                                    <div class="custom-radio">
                                        <input type="radio" formControlName="selectedstatus" id="rbStatusActive"
                                            name="selectedstatus" value="All" class="radio-input"
                                            (change)="onRadioChange('All')" [tabindex]="1"
                                            (keydown.enter)="search()"
                                        >
                                        <span class="radio-label">All</span>
                                    </div>
                                </div>
                                <div class="custom-radio">
                                    <div class="form-check-inline">
                                        <input type="radio" id="rbStatusActive" formControlName="selectedstatus"
                                            name="selectedstatus" value="Active" class="radio-input"
                                            (change)="onRadioChange('Active')" [tabindex]="2"
                                            (keydown.enter)="search()"
                                        >
                                        <span class="radio-label">Active</span>
                                    </div>
                                </div>
                                <div class="custom-radio">
                                    <div class="form-check-inline">
                                        <input type="radio" id="rbStatusActive" formControlName="selectedstatus"
                                            name="selectedstatus" value="Inactive" class="radio-input"
                                            (change)="onRadioChange('Inactive')" [tabindex]="3"
                                            (keydown.enter)="search()"
                                        >
                                        <span class="radio-label">Inactive</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-2 col-sm-6">
                        <div class="form-group textstyle">
                            <label>Client Code/Name/Alias/RM</label>
                            <input type="text" formControlName="searchString" class="form-control" id="txtClientName"
                                tabindex="3" (keydown.enter)="search()"
                            >
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <div class="inline-element" style="padding-top:25px;">
                            <a class="btn btn-success" id="btnSearch" (click)="search()">Search</a>
                            <a class="btn btn-secondary" (click)="reset()" id="btnReset">Reset</a>
                        </div>
                    </div>
                </div>
            </form>
            <div class="nth-last-child-2-center">
            </div>
            <div *ngIf="clients.length>0;else notFound" class="GridClass custom-container">
                <div  class="table-responsive">

                    <table class="table custom-grid">
                        <thead>
                            <tr class="Row123">
                                <th><a (click)="sortTable('tenantCARPartnerName')" class="sort  active  ts-asc"
                                        unselectable="on" data-i="0">Tenant&nbsp;Name/CAR Partner<span
                                            class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('clientName')" class="sort  active  ts-asc" unselectable="on"
                                        data-i="1">Client&nbsp;Name<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('bdmcamDealerUserName')" class="sort  active  ts-asc"
                                        unselectable="on" data-i="2">BDM/CAM/Dealer<span class="ts-sord"></span> </a>
                                </th>
                                <th><a (click)="sortTable('alias')" class="sort  active  " unselectable="on"
                                        data-i="3">Alias<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('clientCode')" class="sort  active  " unselectable="on"
                                        data-i="4">Client&nbsp;Code<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('clientAccountNumber')" class="sort  active  "
                                        unselectable="on" data-i="5">Client&nbsp;Account Number<span
                                            class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  " (click)="sortTable('clientType')" unselectable="on"
                                        data-i="6">Client&nbsp;Type<span class="ts-sord"></span> </a></th>
                                <th><a class="sort  active  " unselectable="on" data-i="7">Active/Inactive<span
                                            class="ts-sord"></span>
                                    </a></th>
                                <th><a class="sort"  unselectable="on" data-i="8">
                                        <span class="action">Action</span>
                                    </a></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td>{{ row.tenantCarPartner }}</td>
                                <td>{{ row.clientName }}</td>
                                <td>{{ row.bdmCamDealer }}</td>
                                <td>{{row.alias}}</td>
                                <td>{{ row.clientCode }}</td>
                                <td>{{row.clientAccountNumber}}</td>
                                <td>{{row.clientType}}</td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="row.isActive" (change)="toggleSwitch(row)" [disabled]="disableActiveSlider">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td>
                                    <div class="action-btns"  (click)="editClient(row.clientID)"><a title="Edit"><img
                                                src="../../../assets/edit.svg" alt=""></a></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  
                </div>
                  <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>
            </div>
        </div>

    </div>
</div>
<ng-template #notFound>
    <div class="alert alert-info">
        <span>No record found</span>
    </div>
</ng-template>