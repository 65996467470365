import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DealDetailService {

  constructor(private http:HttpClient) { }

  apiUrl=environment.apiUrl;
  executeQuote(payload:any){
    return this.http.post(this.apiUrl+'Quote/ExecuteDeal',payload);
  }

  dealHistoryGetTradeConfo(tradeID: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'Common/GetSSI', { headers: headers, responseType: 'blob' });

  }
}
