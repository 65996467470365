<div class="loader-block" *ngIf="loading">
    <div class="inner-loader">
        {{loaderMessage}}...
    </div>
</div>

<form [formGroup]="clientGeneralForm" class="form" (ngSubmit)="onSave()">
    <div class="row">
        <input type="hidden" formControlName="clientID">
        <div *ngIf="generalFildsViewFlag[16]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Client Name <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]="{'newTooltip expand has-error': (clientGeneralForm.controls['clientName'].touched) && clientGeneralForm.controls['clientName'].invalid }"
                    title="Name Is Required">
                    <input type="text" formControlName="clientName" class="form-control" placeholder="Client Name"
                        maxlength="255">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[2]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Client Code <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['clientCode'].touched) && !uniqueClientCode }"
                    title="Client Code exists!!!">
                    <input type="text" formControlName="clientCode" (blur)="validateCLientCode()" class="form-control"
                        placeholder="Client Code" maxlength="10">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[3]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Trading Name</label>
                <div class="showhide">
                    <input type="text" formControlName="tradingName" class="form-control" placeholder="Trading Name"
                        maxlength="255">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[1]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">ICE Alias <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['iceAlias'].touched) && !uniqueAlias }"
                    title="Alias Already Exists">
                    <input type="text" formControlName="iceAlias" class="form-control" (blur)="validateAlias()"
                        placeholder="Alias" maxlength="30">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[4]" class="col-sm-6 ">
            <div class="form-group label-inline">
                <label class="strong">Client Type <em style="color:red;">*</em></label>
                <div class="value-wrapper">
                    <div class="showhide"
                        [ngClass]="{'newTooltip expand has-error': (clientGeneralForm.controls['clientType'].touched) && clientGeneralForm.controls['clientType'].invalid }"
                        title="Type Is Required">
                        <ng-select [items]="clientTypes" bindLabel="typeName" bindValue="typeID"
                            formControlName="clientType" placeholder="Select">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="generalFildsViewFlag[0]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Is Active </label>
                <div class="client custom-switch">
                    <input type="checkbox" formControlName="isActive" class="switch-control-input">
                    <span class="switch-control-label"></span>
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[5]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">ACN <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['acn'].touched)  && clientGeneralForm.controls['acn'].invalid }"
                    [title]="acnErrorTitile">
                    <input (change)="checkValidAcn()" type="text" formControlName="acn" class="form-control"
                        placeholder="Australian Company Number" appNineDigit>
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[6]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong" 
                >ABN</label>
                <div class="showhide" [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['abn'].touched)  && clientGeneralForm.controls['abn'].invalid }"
                [title]="abnErrorTitile">
                    <input (change)="checkValidAbn()" type="text" formControlName="abn" class="form-control"
                        placeholder="Australian Business Number" appElevenDigit>
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[7]" class="col-sm-6" style="margin-right: 20px;">
            <div class="form-group label-inline">
                <label class="strong">ACN/ABN Reg. Date <em style="color:red;">*</em></label>
                <div class="input-icons showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['acnabnRegDate'].touched) && clientGeneralForm.controls['acnabnRegDate'].invalid }"
                    [title]="DateValidationToolTip">
                    <input formControlName="acnabnRegDate" [max]="maxDate" [matDatepicker]="picker" id="datepicker"
                        (blur)="getTooltipTitle()" chang placeholder="DD/MM/YYYY" class="input-field form-control">
                    <span class="icon">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </span>

                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[8]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Nature of Business<em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['natureOfBusiness'].touched)&& clientGeneralForm.controls['natureOfBusiness'].invalid }"
                    title="Nature of Business is require">
                    <input type="text" maxlength="255" formControlName="natureOfBusiness" class="form-control"
                        placeholder="Nature of Business">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[12]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Email <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['email'].touched) && clientGeneralForm.controls['email'].invalid}"
                    title="Email is require">
                    <input type="text" formControlName="email" class="form-control" placeholder="Email">
                </div>
            </div>
        </div>
    
    
        <div *ngIf="generalFildsViewFlag[9]" class="col-sm-6 ">
            <div class="form-group label-inline">
                <label class="strong">Business Legal Structure<em style="color:red;">*</em></label>
                <div class="value-wrapper">
                    <div class="showhide"
                        [ngClass]="{'newTooltip expand has-error': (clientGeneralForm.controls['businessLegalStructureID'].touched) && clientGeneralForm.controls['businessLegalStructureID'].invalid }"
                        title="Business legal structure is required">
                        <ng-select [items]="businessLegalStructures" bindLabel="businessLegalStructureName"
                            bindValue="businessLegalStructureID" formControlName="businessLegalStructureID"
                            placeholder="Select" (change)="checkDisableOther()">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[13]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Phone<em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['phone'].touched) && clientGeneralForm.controls['phone'].invalid }"
                    title="Phone Number is require">
                    <input type="text" formControlName="phone" maxlength="15"   class="form-control" placeholder="Business Phone" >
                </div>
            </div>
        </div>
       
        <div *ngIf="generalFildsViewFlag[9]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">For Other Please specify:</label>
                <div class="showhide">
                    <input type="text" formControlName="businessLegalStructureOther" class="form-control"
                        placeholder="Other Business Legal Structure">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[14]" class="col-sm-6">
            <div class="form-group label-inline">
                <label class="strong">Mobile <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['mobile'].touched) && clientGeneralForm.controls['mobile'].invalid }"
                    title="Mobile Number is require">
                    <input type="text" formControlName="mobile" maxlength="15" class="form-control" placeholder="Business Mobile " >
                </div>
            </div>
        </div>
       
        <div class="col-sm-6">
            <div *ngIf="generalFildsViewFlag[10]" class="form-group label-inline">
                <label class="strong">LEI Number</label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['leiNumber'].touched) && clientGeneralForm.controls['leiNumber'].invalid }"
                    title="LEI Number is require">
                    <input type="text" maxlength="20" formControlName="leiNumber" class="form-control"
                        placeholder="Legal Entity Identifier No." appAlphaNumeric>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div *ngIf="generalFildsViewFlag[15]" class="form-group label-inline">
                <label class="strong">Website<em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': (clientGeneralForm.controls['website'].touched) && clientGeneralForm.controls['website'].invalid }"
                    title="Website is require">
                    <input type="text" formControlName="website" class="form-control" placeholder="Business Website ">
                </div>
            </div>
        </div>
        <div *ngIf="generalFildsViewFlag[11]" class="col-sm-6">
            <div class="form-group label-inline">
                <label>LEI Expiration Date</label>
                <div class="input-icons showhide"
                    [ngClass]=" {'newTooltip expand has-error':clientGeneralForm.controls['leiExpirationDate'].invalid }"
                    [title]="LieDateValidationToolTip">
                    <input formControlName="leiExpirationDate" (blur)="checkLeiExpirationDate()" [min]="expirationMinDate" [matDatepicker]="pickerLei"
                        id="leiExpirationDate" placeholder="DD/MM/YYYY" class="input-field form-control">
                    <span class="icon">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerLei"></mat-datepicker-toggle>
                        <mat-datepicker #pickerLei></mat-datepicker>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="inline-element d-block text-center">
                <button type="submit" class="btn btn-success" [disabled]="isSaveButtonDisabled()">Save</button>
                <button type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button>
            </div>
        </div>
    </div>
</form>