import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ViewBeneficiaryService {
  apiUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getBeneficiarylist():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Admin/GetLists';
    return this.http.get(url);
  }

  search(payload :any):Observable<any>{
    let url=this.apiUrl+'Beneficiary/Admin/Search';
    return this.http.post(url,payload);
  }

  getClientBeneficiarylist():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Client/GetLists';
    return this.http.get(url);
  }
  searchClient(payload :any):Observable<any>{
    let url=this.apiUrl+'Beneficiary/Client/Search';
    return this.http.post(url,payload);
  }
  getClientList(tenantCarPartner :string):Observable<any>{
    let url=this.apiUrl+'Common/ClientList?tenantCar='+tenantCarPartner+'&includeInactive=false';
    return this.http.get(url);
  }

}
