import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appElevenDigit]',
})
export class ElevenDigitDirective {
  private regex: RegExp = new RegExp(/^\d{2} \d{3} \d{3} \d{3}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value.replace(/\s/g, '');
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === ' ' ? '' : event.key,
      current.slice(position),
    ].join('');

    if (
      !String(next)
        .replace(/\s/g, '')
        .match(/^\d{0,11}$/)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    let value = this.el.nativeElement.value;
    value = value.replace(/\D/g, '');

    if (value.length > 2) {
      value = value.substring(0, 2) + ' ' + value.substring(2);
    }
    if (value.length > 6) {
      value = value.substring(0, 6) + ' ' + value.substring(6);
    }
    if (value.length > 10) {
      value = value.substring(0, 10) + ' ' + value.substring(10);
    }

    // Set the formatted value back to the input element
    this.el.nativeElement.value = value;
  }
}
