import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemConstant, ErrorMessages } from '../../../global/global.constant';
import { ResetPasswordService } from '../../services/resetPassword/reset-password.service';

@Component({
  selector: 'app-reset-passward',
  templateUrl: './reset-passward.component.html',
  styleUrl: './reset-passward.component.css',
})
export class ResetPasswardComponent {
  loading:boolean=false;
  tokenFlag: boolean = false;
  confirmFieldTextType: boolean = false;
  typeNewPassword: boolean = false;
  errormsg: string = '';
  validationflag: boolean = false;
  passwordSuccessfullyChanged: boolean = false;
  user: string = "";
  // tokeniD: any;
  constructor(
    private fb: FormBuilder,
    private resetService: ResetPasswordService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  form: FormGroup = this.fb.nonNullable.group({
    newpassword: ['', [Validators.required, Validators.maxLength(50)]],
    Confirmpassword: ['', [Validators.required, Validators.maxLength(50)]],
  });

  @Input() tokenID :string='';
  ngOnInit() {
    this.loading=true;
    this.user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (this.user == SystemConstant.Admin) {
      this.checkTokenValidity('Account/validatetoken?token='); //endpoint is passed as argument
    } else {
      this.checkTokenValidity('ContactAccount/validatetoken?token=');
    }
  }

  private checkTokenValidity = (endPoint: string): void => {
    if (this.tokenID) {
      this.resetService.validateToken(endPoint ,this.tokenID).subscribe({
        next: (data: any) => {
          this.loading=false
        },
        error: (error) => {
          if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
          } else {
            this.errormsg = ErrorMessages.Token_Is_not_valid;
          }
          this.tokenFlag = true;
          this.passwordSuccessfullyChanged = false;
          this.loading=false;
        },
      });
    } else {
      this.errormsg = ErrorMessages.Reset_password_Link_Invalid_Expired;
      this.tokenFlag = true;
      this.passwordSuccessfullyChanged = false;
      this.loading=false;
    }
    this.validationflag = false;
  }

  toggleFieldTextType(type: string) {
    if (type === 'new') this.typeNewPassword = !this.typeNewPassword;
    else if ('confirm') this.confirmFieldTextType = !this.confirmFieldTextType;
  }
  submitNewPassword() {
    this.validationflag = false;
    let endPoint: string = "";
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
    const formValue = this.form.value;
    if (formValue.newpassword != formValue.Confirmpassword) {
      this.errormsg = ErrorMessages.New_Password_and_Confirm_Password_should_match;
      this.validationflag = true;
    } else if (!passwordRegex.test(formValue.newpassword)) {
      this.errormsg = ErrorMessages.Password_Rules;
      this.validationflag = true;
    } else {
      let payload = {
        token: this.tokenID,
        newPassword: formValue.newpassword,
      };

      if (this.user == SystemConstant.Admin) {
        endPoint = "Account/resetpassword";
      } else {
        endPoint = "ContactAccount/resetpassword";
      }
      this.resetService.resetPassword(endPoint, payload).subscribe({
        next: () => {
          this.tokenFlag = true;
          this.passwordSuccessfullyChanged = true;
          if (this.user == SystemConstant.Admin) {
            this.router.navigateByUrl('/Admin/managecontact');
          } else {
            this.router.navigateByUrl('/Client/accountbalances');
          }
        
        },
        error: (error) => {
          if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
            this.validationflag = true;
          } else {
            this.errormsg = ErrorMessages.Token_Is_not_valid;
            this.tokenFlag = true;
            this.passwordSuccessfullyChanged = false;
          }
        },
      });
    }
  }

  backToLogin = (): void => {
    if (this.user == SystemConstant.Admin) {
      this.router.navigateByUrl('/admin');
    } else {
      this.router.navigateByUrl('/Client');
    }
  }
}
