import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrl: './client-management.component.css'
})
export class ClientManagementComponent {
  clientID: number=1;
  isaddressActive:boolean=true;
  constructor(
    private router: Router,
    private encryptPermission:EncryptRolePermissionService
  ){}
  ngOnInit(){
    const clientinfo=this.encryptPermission.getDecryptedPermissions('usercrd');
    if (clientinfo && clientinfo.clientID) {
      this.clientID = clientinfo.clientID; 
    }
    this.router.navigate(['Client/Clientmanagement/',{ outlets: { 'tabnavigation': ['clientaddress',clientinfo.clientID] } }]);
  }
  handleTabClick(tabName: string): void {
    this.isaddressActive = tabName === 'address';
  }

}
