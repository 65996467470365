<div *ngIf="loading" class="loader-block ">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="login-wrapper">
    <div class="login">
        <div class="login-left">
            <em>
                <img src="../../../../assets/logo.png" alt="">
                <img src="../../../../assets/logo.png" alt="" class="sm-logo">
            </em>
        </div>
        <div class="login-right">
            <div >
                <p [class.error]="verificationfailed">{{msg}}</p>
                
            </div>
         
        </div>
    </div>
</div>