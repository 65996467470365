import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RhsCollarService {
  apiurl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getList(){
    return this.http.get(this.apiurl+'OptionHedge/GetLists');
  }

  getExpiryData(payload:any){
    return this.http.post(this.apiurl+'OptionHedge/GetExpirySettings',payload);
  }


  getCurrencyPairId(){
    return this.http.get(this.apiurl+'CurrencyPair');
  }

  getSalesMarginAmount(payload:any){
    return this.http.post(this.apiurl+'OptionHedge/GetSalesMarginAmount',payload);
  }

  getBarrierList(payload:any){
    return this.http.post(this.apiurl+'OptionHedge/GetBarriers',payload);
  }

  
}
