import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { inject } from '@angular/core';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';

export const clientAuthGuard: CanActivateFn = (route, state) => {
  const signInService = inject(LoginService);
  const router = inject(Router);
  const rolePermission = inject(EncryptRolePermissionService);

  if (signInService.isAuthenticated()) {
    const pagePermission = rolePermission
      .getDecryptedPermissions('crole')
      ?.modulePermission?.find(
        (permission: { clientPageID: number }) =>
          permission.clientPageID === route.data['pageID']
      );
    if (pagePermission && !pagePermission.canView) {
      return router.createUrlTree(['/', 'Unauthorized']);
    }
    return true;
  }
  return router.createUrlTree(['/','Client']);
};
