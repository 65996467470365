import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { RhsCollarService } from '../../services/RHSCollar/rhs-collar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionHedgeService } from '../../services/OptionHedge/option-hedge.service';
import { Observable, of } from 'rxjs';
import { response } from 'express';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { event } from 'jquery';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
const productID = 5;
@Component({
  selector: 'app-rhscollar',
  templateUrl: './rhscollar.component.html',
  styleUrl: './rhscollar.component.css',
})
export class RHSCollarComponent implements OnInit {
  isAdmin!: boolean;
  clientId!: number;
  constructor(
    private optionHedgeService: OptionHedgeService,
    private encryptPermission: EncryptRolePermissionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  clientName: any = null;
  productID = 5;
  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
      this.clientName =
        this.encryptPermission.getDecryptedPermissions('selectedName');
      this.route.paramMap.subscribe((parameterMap) => {
        this.clientId = +parameterMap.get('clientId')!;
      });
    } else if (user === 'Client') {
      this.isAdmin = false;
      const clientInfo =
        this.encryptPermission.getDecryptedPermissions('usercrd');
      this.clientId = clientInfo?.clientID;
    }
  }
}
