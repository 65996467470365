import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, delay, tap } from 'rxjs';
import { response } from 'express';
import { AddClientPayload, ClientData, ClientListData } from '../../components/client-general/clientGeneral.model';
@Injectable({
  providedIn: 'root'
})
export class ClientGeneralService {
  apiUrl=environment.apiUrl;

  constructor(private http:HttpClient) { }

  clientName: string = '';
  clientCode: string = '';

  checkUniqueClientCode(clientCode: string, clientID: number): Observable<boolean> {
    return this.http.post<boolean>(this.apiUrl+'Client/validateclientcode',{clientID,clientCode});
  }

  checkUniqueAlias(alias:string, clientID: number): Observable<boolean>{
    return this.http.post<boolean>(this.apiUrl+'Client/validatealias',{clientID,alias});
  }

  checkUniqueAccount(clientAccountNumber:any, clientID: number): Observable<boolean>{
    return this.http.post<boolean>(this.apiUrl+'Client/validateclientaccount',{clientID, clientAccountNumber});
  }


  getAllListData(): Observable<ClientListData>{
    return this.http.get<ClientListData>(this.apiUrl+'Client/general/lists');
  }

  getClientDetails(id: number) :Observable<ClientData>{
    return this.http.get<ClientData>(this.apiUrl+'Client/'+id).pipe(tap((response:any)=>{
      if(response){
        this.clientName=response.clientName;
        this.clientCode=response.clientCode;
      }
    }));
  }

  addClient(clientData: AddClientPayload): Observable<AddClientPayload>{
    return this.http.post<AddClientPayload>(this.apiUrl+'Client/add',clientData);
  }

  updateClient(clientData: ClientData){
    return this.http.patch(this.apiUrl+'Client/update',clientData);
  }

  

}
