<!-- <app-header></app-header> -->
<div class="">
    <div class="compress-inputs-page">
        <div class="inner secondary">
            <form action="" class="form" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                <div class="card">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Current password</label>
                                <div class="value-wrapper">
                                    <div class="showhide"
                                        [ngClass]="{'newTooltip expand has-error': (changePasswordForm.controls['oldPassword'].touched) && changePasswordForm.controls['oldPassword'].invalid }"
                                        title="{{changePasswordForm.controls['oldPassword'].errors?.['required'] ? 'Current Password is required'
                                                    :(changePasswordForm.controls['oldPassword'].errors?.['minlength'] ?'Password should be minimum of 8 characters'
                                                    :'')}}">
                                        <input class="form-control" formControlName="oldPassword"
                                            placeholder="Old Password" [type]="currentPassType ? 'text' : 'password'">
                                        <div class="eye-icon">
                                            <span>
                                                <img [src]="currentPassType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                                    class="bi" (click)="togglecurrentPassType()">
                                            </span>
                                        </div>

                                    </div>
                                </div>



                            </div>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>New password</label>
                                <div class="value-wrapper">
                                    <div class="showhide"
                                        [ngClass]="{'newTooltip expand has-error': (changePasswordForm.controls['newPassword'].touched) && (changePasswordForm.controls['newPassword'].invalid) }"
                                        title="{{changePasswordForm.controls['newPassword'].errors?.['required'] ? 'New Password is required'
                                        :(changePasswordForm.controls['newPassword'].errors?.['pattern'] ?'The password must contain at least 8 characters having 1 uppercase, 1 lowercase, 1 digit and 1 special character.'
                                        :'')}}">
                                        <input [type]="newPassType ? 'text' : 'password'" class="form-control"
                                            formControlName="newPassword" placeholder="New Password">
                                        <div class="eye-icon">
                                            <span>
                                                <img [src]="newPassType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                                    class="bi" (click)="togglenewPassType()">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group label-inline">
                                <label>Confirm password</label>
                                <div class="value-wrapper">
                                    <div class="showhide"
                                        [ngClass]="{'newTooltip expand has-error': (changePasswordForm.controls['confirmPassword'].touched) && (changePasswordForm.controls['confirmPassword'].invalid) }"
                                        title="{{changePasswordForm.controls['confirmPassword'].errors?.['required'] ? 'Confirm Password is required'
                                        :(changePasswordForm.controls['confirmPassword'].errors?.['pattern'] ?'The password must contain at least 8 characters having 1 uppercase, 1 lowercase, 1 digit and 1 special character.'
                                        :'')}}">
                                        <input [type]="confirmPassType ? 'text' : 'password'" class="form-control"
                                            formControlName="confirmPassword" placeholder="Confirm Password">
                                        <div class="eye-icon">
                                            <span>
                                                <img [src]="confirmPassType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                                    class="bi" (click)="toggleconfirmPassType()">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="changePasswordForm?.hasError('mismatch')">
                        <p class="alert alert-danger">Password and Confirm Password do not match.</p>
                    </div>
                    <div *ngIf="changePasswordResponse"
                        [ngClass]="changePasswordResponse?.isChanged ? 'alert alert-success':'alert alert-danger'">
                        {{changePasswordResponse?.message}}
                    </div>
                    <div class="row"><br /></div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="inline-element d-block text-center">

                                <button type='submit' value='Save' title="Save" class="btn btn-success"
                                    [disabled]="changePasswordForm.invalid" style="margin-right: 10px;">Submit</button>


                                <button type="button" value="Cancel" class="btn btn-secondary"
                                    (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>



    </div>
</div>