import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PathMapping } from '../../../Constants/constants';
import { RolePermissonService } from '../../../services/Permission/role-permisson.service';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { SigninService } from '../../../services/signin/signin.service';
import { AdminInfo, JWTTokenInfo } from '../../models/common.model';
import { jwtDecode } from 'jwt-decode';
import { SystemConstant } from '../../../global/global.constant';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tenant } from '../../add-edit-tenant/models/tenant.model';
import { CARPartner } from '../../add-edit-car-partner/models/CarPartner.model';

@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  sidePanelLogo: string = "../../../../assets/logo-dashboard.png";
  dataLoaded: boolean = false;
  mode: any = 'side';
  isMenubarOpen = true;
  contentMargin = 300;
  openSidebarover: boolean = true;
  backupMenuFlag: boolean = true;
  currentYear = new Date().getFullYear();

  @ViewChild('sidenav') sidenav!: any;
  roleData: any;
  roleID: number = 0;
  hide: boolean = true;
  viewFlags: boolean[] = new Array(40).fill(true);
  hasMenuflag: boolean[] = new Array(7).fill(false);
  activationFlag: boolean[] = new Array(100).fill(false);

  sidebarData: any[] = [];
  tempDataValue: any[] = [];

  currentSelected: number = this.activationFlag.findIndex((x) => x == true) == undefined || null ? -1 : this.activationFlag.findIndex((x) => x == true);
  previousSelected: number = this.activationFlag.findIndex((x) => x == true) == undefined || null ? -1 : this.activationFlag.findIndex((x) => x == true);

  childrenActivatedId: number = -1;

  isCollapsed: boolean = false;
  isPayee: boolean = true;
  isMenuOpen: boolean[] = Array(7).fill(false);
  isNavOpen: boolean = false;
  currentToggleId: any;
  prevToggleId: any;
  hidehamburger: boolean = false;

  constructor(
    private roleService: RolePermissonService,
    private signInService: SigninService,
    private encryptPermission: EncryptRolePermissionService,
    private route:ActivatedRoute,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    if(this.encryptPermission.getDecryptedPermissions('roleNgp')){
      this.roleID = this.encryptPermission.getDecryptedPermissions('roleNgp').roleID;
    }
  }

  ngOnInit(): void {
    this.Username=this.signInService.Username;
    if(this.encryptPermission.getDecryptedPermissions('roleNgp')){
      this.roleID = this.encryptPermission.getDecryptedPermissions('roleNgp').roleID;      
      this.dataLoaded = false;      
      this.getAllRoles().then((_) => {
        this.getSidebarData().then((_) => {
          const urlPath = this.router.url.toString();
          let activatedLink = PathMapping.Path.filter((path) => path.path == urlPath)[0]?.pageID;
          let openMenuId = PathMapping.Path.filter((path) => path.path == urlPath)[0]?.menuId;
          let extraField = PathMapping.Path.filter((path) => path.path == urlPath)[0]?.extraModule;  

          if(activatedLink == undefined || activatedLink == null){
            const tempPath = this.route.snapshot.children[0].routeConfig?.path?.toString();
            activatedLink = PathMapping.Path.filter((path) => path.path == tempPath)[0]?.pageID;
            openMenuId = PathMapping.Path.filter((path) => path.path == tempPath)[0]?.menuId;
            extraField = PathMapping.Path.filter((path) => path.path == tempPath)[0]?.extraModule;
          }

          if (activatedLink != undefined && openMenuId != undefined) {
            this.currentSelected = activatedLink;
            this.activationRedirection(activatedLink,Number(openMenuId),'indirect', extraField, urlPath);
          }
          else{

          }
        });
      });
    }   
    
    this.getTenant_CARPartner();
  }

  getAllRoles() {
    const promise = new Promise<void>((resolve, reject) => {
      this.roleService.getRolePermissionData(this.roleID).subscribe({
        next: (data: any) => {
          this.roleData = data;
          resolve();
        },
        error: () => {
        },
      });
    });
    return promise;
  }

  private getTenant_CARPartner = (): void => {
    var savedLogin: AdminInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    const tokenDecoded: JWTTokenInfo = jwtDecode(savedLogin.jwtToken);
    if (tokenDecoded && tokenDecoded.tenantID) {
      this.signInService.getTenant(tokenDecoded.tenantID).pipe(untilDestroyed(this)).subscribe({
        next: (result: Tenant) => {
          this.sidePanelLogo = result.sidePanelLogoLight;
        },
        error: (error) => {

        }
      });
    }
    else if (tokenDecoded && tokenDecoded.carPartnerID){
      this.signInService.getCarPartner(tokenDecoded.carPartnerID).pipe(untilDestroyed(this)).subscribe({
        next: (result: CARPartner) => {
          this.sidePanelLogo = result.sidePanelLogoLight;
        },
        error: (error) => {

        }
      });
    }
    else {
      this.sidePanelLogo = "../../../../assets/logo-dashboard.png";
    }
  }

  activationRedirection(
    index: number,
    toggleId: number,
    routeDirection: string,
    hasSubField: any,
    subPath: string
  ) {
    if (toggleId != -1) {
      this.tempDataValue.map((item) => {
        for (var i = 0, l = item.elements.length; i < l; i++) {
          if (item.elements[i].pageID == index) {
            this.childrenActivatedId = item.id;
          }
        }
      });
      const active = this.document.getElementById(String(this.childrenActivatedId));
      active?.classList.add('highlightActivated');
    } else {
      const prevactive = this.document.getElementById(String(this.prevToggleId));
      prevactive?.classList.remove('highlightActivated');
    }

    this.previousSelected = this.currentSelected;
    this.currentSelected = index;

    if (this.activationFlag.filter((x) => x == false).length != 100) {
      this.activationFlag[this.activationFlag.findIndex((x) => x == true)] =
        false;
    }
    this.activationFlag[index] = true;

    if (toggleId != -1) {
      this.toggleMenu(toggleId);
    }

    if (routeDirection == 'direct') {
      this.toggleMenu(toggleId);
    }


    if(!hasSubField){
      const pathValue = PathMapping.Path.filter((path) => path.pageID == index)[0]?.path;
      if ((pathValue != undefined || pathValue != null)) {
        this.router.navigate([pathValue]);
      }
    }
    else{
      const pathValue = subPath;
      this.router.navigate([pathValue]);
    }   
  }

  getSidebarData() {
    const promise = new Promise<void>((resolve, reject) => {
      this.dataLoaded = false;
      this.roleService.getSidebarData().subscribe({
        next: (data: any) => {
          let toggleCount = 0;
          this.sidebarData = data;

          this.sidebarData
            .sort((a, b) => a.moduleOrderNo - b.moduleOrderNo)
            .forEach((element: any) => {
              if (
                this.tempDataValue.filter(
                  (j) => j.ParentName == element.moduleName
                ).length == 0
              ) {
                let check = this.sidebarData.filter(
                  (x) => x.moduleName == element.moduleName
                );
                for (var i = 0; i < check.length; i++) {
                  if (
                    this.roleData.modulePermissions
                      .filter((ele: any) => ele.canView == 1)
                      .findIndex((m: any) => m.pageID == check[i].pageID) == -1
                  ) {
                    var deletedElementIndex = i;
                    check.splice(i, 1);
                    i = deletedElementIndex - 1;
                  }
                }

                if (
                  check.length > 0 &&
                  element.moduleName != element.pageName
                ) {
                  let child: any = [];
                  check.forEach((data: any) => {
                    child.push(data.pageName);
                  });

                  if (
                    child.length > 0 &&
                    element.moduleName != element.pageName
                  ) {
                    let temp = {
                      id: toggleCount,
                      singleElement: false,
                      ParentName: element.moduleName,
                      ParentId: element.moduleID,
                      child: child,
                      elements: check,
                    };
                    toggleCount++;
                    this.tempDataValue.push(temp);
                  }
                } else {
                  if (
                    check.length == 1 &&
                    element.moduleName == element.pageName
                  ) {
                    let temp = {
                      id: -1,
                      singleElement: true,
                      ParentName: element.moduleName,
                      ParentId: element.moduleID,
                      child: [],
                      elements: element,
                    };
                    this.tempDataValue.push(temp);
                  }
                }
              }
            });
          this.dataLoaded = true;
          resolve();
        },
        error: () => {
        },
      });
    });
    return promise;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  toggleMenu(index: number): void {
    const tempPrev = this.prevToggleId;
    this.prevToggleId = this.currentToggleId;
    this.currentToggleId = index;

    const prevactive = this.document.getElementById(String(this.prevToggleId));
    prevactive?.classList.remove('highlightActivated');

    const active = this.document.getElementById(String(this.currentToggleId));
    active?.classList.add('highlightActivated');

    if (index == this.childrenActivatedId) {
      const active = this.document.getElementById(String(this.currentToggleId));
      active?.classList.add('highlightActivated');
    } else {
      const prevactive = this.document.getElementById(String(this.prevToggleId));
      prevactive?.classList.remove('highlightActivated');
    }

    this.isMenuOpen[index] = !this.isMenuOpen[index];
    if (this.currentToggleId != this.prevToggleId) {
      this.isMenuOpen[this.prevToggleId] = false;
    }
  }

  setRolePermission() {
    if (this.roleData != null) {
      if (
        this.roleData.modulePermissions &&
        this.roleData.modulePermissions.length > 0
      ) {
        for (const permission of this.roleData.modulePermissions) {
          const pageID = permission.pageID;
          const canView = permission.canView;
          if (canView == 1) {
            this.viewFlags[pageID] = false;
          }
        }

        if (this.viewFlags[3] && this.viewFlags[4]) {
          this.hasMenuflag[0] = true;
        }
        if (
          this.viewFlags[5] &&
          this.viewFlags[6] &&
          this.viewFlags[7] &&
          this.viewFlags[8] &&
          this.viewFlags[10] &&
          this.viewFlags[11] &&
          this.viewFlags[12] &&
          this.viewFlags[14] &&
          this.viewFlags[13] &&
          this.viewFlags[15] &&
          this.viewFlags[18] &&
          this.viewFlags[19] &&
          this.viewFlags[22] &&
          this.viewFlags[26] &&
          this.viewFlags[36]
        ) {
          this.hasMenuflag[1] = true;
        }
        if (this.viewFlags[1] && this.viewFlags[2]) {
          this.hasMenuflag[2] = true;
        }
        if (this.viewFlags[30] && this.viewFlags[31] && this.viewFlags[33]) {
          this.hasMenuflag[3] = true;
        }
        if (this.viewFlags[9] && this.viewFlags[20]) {
          this.hasMenuflag[4] = true;
        }
        if (this.viewFlags[23] && this.viewFlags[24] && this.viewFlags[27]) {
          this.hasMenuflag[5] = true;
        }
        if (this.viewFlags[32] && this.viewFlags[34]) {
          this.hasMenuflag[6] = true;
        }
      }
    }
  }

  visitLink(routerPath: string) {
    this.router.navigate([routerPath]);
  }

  ngOnDestroy(): void {}

  onToolbarMenuToggle() {
    this.isMenubarOpen = !this.isMenubarOpen;

    const active = this.document.getElementById(String(this.currentToggleId));
    const prevactive = this.document.getElementById(String(this.prevToggleId));

    if (!this.isMenubarOpen) {
      prevactive?.classList.remove('highlightActivated');
      active?.classList.add('highlightActivated');

      this.contentMargin = 50;
      this.backupMenuFlag = this.isMenuOpen[this.currentToggleId];
      this.isMenuOpen[this.currentToggleId] = false;
    } else {
      this.contentMargin = 300;
      this.isMenuOpen[this.currentToggleId] = this.backupMenuFlag;
    }
  }

  mouseEnter() {
    if (!this.isMenubarOpen) {
      let element = this.document.getElementById('sidenav');
      if (element) {
        const active = this.document.getElementById(
          String(this.currentToggleId)
        );
        const prevactive = this.document.getElementById(
          String(this.prevToggleId)
        );

        prevactive?.classList.remove('highlightActivated');
        active?.classList.add('highlightActivated');

        this.isMenuOpen[this.currentToggleId] = this.backupMenuFlag;
        this.openSidebarover = true;
        this.mode = 'over';
        this.contentMargin = 300;
        element.classList.add('menu-open');
        element.classList.remove('menu-close');
      }
    }
  }

  mouseLeave() {
    if (!this.isMenubarOpen) {
      let element = this.document.getElementById('sidenav');
      if (element) {
        const active = this.document.getElementById(
          String(this.currentToggleId)
        );
        const prevactive = this.document.getElementById(
          String(this.prevToggleId)
        );

        prevactive?.classList.remove('highlightActivated');
        active?.classList.add('highlightActivated');

        this.isMenuOpen[this.currentToggleId] = false;
        this.openSidebarover = false;
        this.contentMargin = 50;
        element.classList.remove('menu-open');
        element.classList.add('menu-close');
        this.mode = 'side';
      }
    }
  }

  //Toggel navigation bar based on screen width
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 1024) {
      this.isMenubarOpen = true;
      this.hidehamburger = true;
    } else {
      this.isMenubarOpen = false;
      this.hidehamburger = false;
    }
    this.onToolbarMenuToggle();
  }

  /* Header Module */
  Username: string = '';
  isProfileMenuOpen: boolean = false;
  isProfileNavOpen: boolean = false;

  toggleProfileMenu() {
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  changePassword(){
    this.activationFlag[this.currentSelected] = false;
    this.router.navigate(['/Admin/changepassword']);
  }
  logout()
  {
   
    this.signInService.logout().subscribe({
    next(data:any){
    },
    error(){      
    }
  })
  this.router.navigate(['/admin']) ;
  }
 
}


