import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordService } from '../../services/ChangePassword/change-password.service';
import { ActivatedRoute } from '@angular/router';
import { response } from 'express';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private changeaPasswordService: ChangePasswordService,
    private route:ActivatedRoute
  ) {

  }

  isAdmin:any;
  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if(user==='Admin'){
      this.isAdmin=true;
    }
    else if(user==='Client'){
      this.isAdmin=false;
    }
    
  }

  passwordPattern = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  currentPassType: boolean = false;
  newPassType: boolean = false;
  confirmPassType: boolean = false;
  changePasswordResponse: any;
  changePasswordForm: FormGroup = this.fb.group({
    oldPassword: [null, [Validators.required]],
    newPassword: [null, [Validators.required, Validators.pattern(this.passwordPattern)]],
    confirmPassword: [null, [Validators.required, Validators.pattern(this.passwordPattern)]]
  },
    { validator: this.passwordMatchValidator }
  );



  passwordMatchValidator(group: FormGroup) {
    const password = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    if (group.controls['newPassword'].dirty && group.controls['confirmPassword'].dirty) {
      return password === confirmPassword ? null : { mismatch: true };
    }
    else {
      return null;
    }

  }

  togglecurrentPassType() {
    this.currentPassType = !this.currentPassType;
  }

  togglenewPassType() {
    this.newPassType = !this.newPassType;
  }

  toggleconfirmPassType() {
    this.confirmPassType = !this.confirmPassType;
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const payload = {
        currentPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.newPassword
      }
      if(this.isAdmin){
        this.changeaPasswordService.changePassword(payload).subscribe({
          next: (response) => {
            this.changePasswordResponse = response;
  
          },
          error: (error) => {
  
          }
  
        })
      }
      else if(!this.isAdmin){
        this.changeaPasswordService.changePasswordForClient(payload).subscribe({
          next:(response)=>{
            this.changePasswordResponse=response;
          },
          error:(error)=>{

          }
        })

      }
      

    }
  }

  cancel() {
    this.changePasswordForm.reset();

  }

}
