import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ManageCurrencies, SearchParams, Countries } from '../../components/manage-currencies/models/manage-currencies.model';

@Injectable()
export class ManageCurrenciesService {

  private apiUrl = environment.apiUrl;
  
  constructor(
    private http: HttpClient
  ) {}

  getManageCurrencyList = (searchParams: SearchParams): Observable<ManageCurrencies[]> => {
    let url = this.apiUrl + 'Currency/Search';   
    return this.http.post<ManageCurrencies[]>(url, searchParams);
  }

  getCountryList = (): Observable<Countries> => {
    let url = this.apiUrl + 'Currency/GetLists';   
    return this.http.get<Countries>(url);
  }

  getManageCurrencyDetails = (currencyId: number): Observable<ManageCurrencies> => {
    let url = this.apiUrl + 'Currency/' + currencyId;
    return this.http.get<ManageCurrencies>(url);
  }

  saveManageCurrency = (manageCurrencies: ManageCurrencies) :Observable<ManageCurrencies> => {   
    let url = this.apiUrl + "Currency/Add";
    return this.http.post<ManageCurrencies>(url, manageCurrencies);
  }

  updateManageCurrency = (manageCurrencies: ManageCurrencies) :Observable<ManageCurrencies> => {   
    let url = this.apiUrl + "Currency/Update";
    return this.http.patch<ManageCurrencies>(url, manageCurrencies);
  }
}
