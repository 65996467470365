<!-- <app-header></app-header> -->
<div *ngIf="loading">
    <div class="loader-block">
        <div class="inner-loader">Loading...</div>
    </div>
</div>


<div [ngClass]="{ collapseNav: !isNavBarOpen }">
    <div class="inner user-page">
        <div class="title">

            <h3>&nbsp;</h3>
            <a *ngIf="!hideAddButton" (click)="addTenant()" id="btnClientAddNew" class="btn btn-success">
                <em><img src="../../../assets/plus.svg" alt="" /></em>
                Add
            </a>
        </div>
        <div class="card maindiv">
            <div class="row" style="font-size: 18px;">
                <div class="col-md-4 col-sm-6">
                    <div class="form-group ">
                        <label class="textstyle">Status</label>
                        <div class="checkbox-block">
                            <div class="form-check-inline">
                                <div class="custom-radio">
                                    <input type="radio" id="rbStatusActive" name="All" value="Both"
                                        (change)="onRadioChange('Both')" [(ngModel)]="selectedStatus"
                                        class="radio-input" [tabindex]="1"
                                        (keydown.enter)="search()"
                                    >
                                    <span class="radio-label">All</span>
                                </div>
                            </div>
                            <div class="custom-radio">
                                <div class="form-check-inline">
                                    <input type="radio" id="rbStatusActive" name="Active" value="Active"
                                        (change)="onRadioChange('Active')" [(ngModel)]="selectedStatus"
                                        class="radio-input" [tabindex]="2"
                                        (keydown.enter)="search()"
                                    >
                                    <span class="radio-label">Active</span>
                                </div>
                            </div>
                            <div class="custom-radio">
                                <div class="form-check-inline">
                                    <input 
                                        type="radio" id="rbStatusActive" name="Inactive" value="Inactive"
                                        (change)="onRadioChange('Inactive')" [(ngModel)]="selectedStatus"
                                        class="radio-input" [tabindex]="3"
                                        (keydown.enter)="search()"
                                    >
                                    <span class="radio-label">Inactive</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-3 col-sm-6">
                    <div class="form-group ">
                        <label class="textstyle">Tenant Name</label>
                        <input 
                            type="text" class="form-control" id="txtRoleName" tabindex="1"
                            [(ngModel)]="searchTenantName" (keydown.enter)="search()"
                        />
                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="form-group ">
                        <label class="textstyle">Phone</label>
                        <input 
                            type="text" [(ngModel)]="searchPhone" class="form-control" 
                            (keydown.enter)="search()"
                        />

                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="form-group ">
                        <label class="textstyle">Email</label>
                        <input type="text" 
                            [(ngModel)]="searchEmail" 
                            class="form-control" 
                            (keydown.enter)="search()"
                        />

                    </div>
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="inline-element" style="padding-top: 25px;">
                        <a class="btn btn-success" id="btnSearch" (click)="search()">Search</a>
                        <a class="btn btn-secondary" id="btnReset" (click)="reset()">Reset</a>
                    </div>
                </div>
            </div>

            <div class="nth-last-child-2-center"></div>

            <div class="GridClass custom-container">
                <div class="table-responsive">
                    <table class="table custom-grid">
                        <colgroup>
                            <col style="width: 35%" />
                            <col style="width: 15%" />
                            <col style="width: 15%" />
                            <col style="width: 10%" />
                            <col style="width: 10%" />

                        </colgroup>

                        <thead>
                            <tr class="Row123 ">
                                <th>
                                    <a class="sort active ts-asc" (click)="sortTable('tenantName')" unselectable="on"
                                        data-i="0">Tenant Name<span class="ts-sord"></span>
                                    </a>
                                </th>
                                <th>
                                    <a class="sort active ts-asc" (click)="sortTable('phone')" unselectable="on"
                                        data-i="1">Phone<span class="ts-sord"></span>
                                    </a>
                                </th>
                                <th>
                                    <a class="sort active" (click)="sortTable('email')" unselectable="on" data-i="3">
                                        Email<span class="ts-sord"></span>
                                    </a>
                                </th>
                                <th>
                                    <a class="sort active" unselectable="on" data-i="4">Active/InActive<span
                                            class="ts-sord"></span>
                                    </a>
                                </th>


                                <th *ngIf="!hideEditButton">
                                    <a class="sort" unselectable="on" data-i="8">
                                        <span class="action">Action</span>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td>{{ row.tenantName }}</td>
                                <td>{{ row.phone }}</td>
                                <td>{{ row.email }}</td>

                                <td>
                                    <label class="switch">
                                        <input type="checkbox" [checked]="row.isActive" (change)="toggleSwitch(row)">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td *ngIf="!hideEditButton">
                                    <div class="action-btns">
                                        <a style="cursor: pointer;" title="Edit" (click)="editTenant(row.tenantID)"><img
                                                src="../../../assets/edit.svg" alt="" /></a>
                                    </div>
                                </td>
                            </tr>


                        </tbody>
                    </table>
                    <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>