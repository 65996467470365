<div *ngIf="loading" class="loader-block ">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="new-login-wrapper">
    <div class="login">
        <div class="login-left" style="background-image: url('../../../../assets/login-bg.png')">
        </div>
        <div class="login-right">
            <div *ngIf="!tokenFlag">
                <form [formGroup]="form" (ngSubmit)="submitNewPassword()" class="form ">

                    <em><img src="../../../../assets/Navinci BlackBG Logo.png" alt=""></em>
                    <div class="form-group"
                        [ngClass]=" {'newTooltip expand has-error':form.controls['newpassword'].invalid && (form.controls['newpassword'].dirty || form.controls['newpassword'].touched )}"
                        datatitle="New Password is required">
                        <label>New Password</label>
                        <div class="showhide">
                            <input formControlName="newpassword" id="Newpassword" [type]="typeNewPassword ? 'text' : 'password'"
                                class="form-control" placeholder="New Password">
                            <div class="eye-icon">
                                <span>
                                    <img [src]="typeNewPassword ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                        class="bi" (click)="toggleFieldTextType('new')">
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="form-group"
                    [ngClass]=" {'newTooltip expand has-error':form.controls['Confirmpassword'].invalid && (form.controls['Confirmpassword'].dirty || form.controls['Confirmpassword'].touched )}"
                    datatitle="Conirm Password is required">
                    <label>Confirm Password</label>
                    <div class="showhide">
                        <input formControlName="Confirmpassword" id="Confirmpassword" [type]="confirmFieldTextType ? 'text' : 'password'"
                            class="form-control" placeholder="Password">
                        <div class="eye-icon">
                            <span>
                                <img [src]="confirmFieldTextType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                    class="bi" (click)="toggleFieldTextType('confirm')">
                            </span>
                        </div>
                    </div>

                </div>
                    <input type='submit' value='Submit' title="Submit" class="btn btn-success" id="button"
                        [disabled]="form.invalid" />

                        <div *ngIf="validationflag" class="alert alert-danger" style="margin-top: 10px;">{{errormsg}}

                        </div>
                </form>
            </div>
            <div *ngIf="tokenFlag"> 
                <div *ngIf="!passwordSuccessfullyChanged" class="alert alert-danger" style="margin-top: 10px;">{{errormsg}}

                </div>
                <div *ngIf="passwordSuccessfullyChanged">
                    
                    <p>Your password has been changed Successfully.</p>
                    <a style="text-align: center; cursor: pointer;" (click)="backToLogin()" class="forgot-link" title="Back to Login"> Back
                    to Login</a>
                    
                </div>
            </div>
        </div>
    </div>
</div>


