import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuditlogService } from '../../services/auditLog/auditlog.service';
import {
  FieldName,
  FieldNameResponse,
  auditLog,
  auditLogList,
  auditLogResponse,
  auditLogpayload,
  dates,
  module,
} from './models/auditLog.model';
import { PageEvent } from '@angular/material/paginator';
import {
  DefaultPageOptions,
  DefaultPageSize,
  ErrorMessages,
} from '../../global/global.constant';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.css',
})
export class AuditLogComponent {
  dataLoaded: boolean = true;
  auditLogForm: FormGroup = this.fb.group({
    moduleName: [null],
    fieldName: [null],
    date: [null],
    dateValue: [null],
    fromDate: [null],
    toDate: [null],
  });
  length: number = 0;
  pageEvent: PageEvent | undefined;
  pageSizeOptions: number[] = DefaultPageOptions;
  pageSize: number = DefaultPageSize;
  pageIndex: number = 0;
  hidePageSize: boolean = false;
  showPageSizeOptions: boolean = true;
  disabled: boolean = false;
  showFirstLastButtons = true;
  modules: module[] = [];
  dates: dates[] = [];
  fieldNames: FieldName[] = [];
  auditLog: auditLog[] = [];
  paginatedData: any;
  sortedColumn: string = '';
  isAscending: boolean = true;
  today: string = '';
  dateValidaflag: boolean = false;
  customDateFlag: boolean = true;

  constructor(
    private fb: FormBuilder,
    private auditLogServices: AuditlogService,
    private alert: SweetalertService,
  ) {
    const currentDate = new Date();
    this.today = currentDate.toISOString().split('T')[0];
    this.getdropDownList();
  }

  getdropDownList(): void {
    this.dataLoaded = false;
    this.auditLogServices.getDropdownList().subscribe({
      next: (result: auditLogList) => {
        this.modules = result.moduleName;
        this.dates = result.date;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  getFields() {
    this.dataLoaded = false;
    this.auditLogServices
      .getFields(this.auditLogForm.value.moduleName)
      .subscribe({
        next: (result: FieldNameResponse) => {
          this.fieldNames = result.fieldName;
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
  }
  checkDate() {
    if (!this.customDateFlag) {
      const fromDateValue = this.auditLogForm.get('fromDate')?.value;
      const toDateValue = this.auditLogForm.get('toDate')?.value;
      if (fromDateValue && toDateValue) {
        const fromDate = new Date(fromDateValue);
        const toDate = new Date(toDateValue);
        if (fromDate > toDate) {
          this.dateValidaflag = false;
          this.alert.showError(null, ErrorMessages.FormDateToDate);
        } else {
          this.search();
        }
      } else {
        this.alert.showError(null, ErrorMessages.SelectBothDate);
      }
    } else {
      this.search();
    }
  }
  search() {
    const payload: auditLogpayload = {
      moduleName: this.auditLogForm.value.moduleName,
      fieldName: this.auditLogForm.value.fieldName,
      date: this.auditLogForm.value.date,
      fromDate: this.auditLogForm.value.fromDate,
      toDate: this.auditLogForm.value.toDate,
    };
    this.dataLoaded = false;
    this.auditLogServices.getAuditLogs(payload).subscribe({
      next: (result: auditLogResponse) => {
        this.auditLog = result.auditLogSearches;
        this.length = this.auditLog.length;
        this.paginatedData = this.auditLog.slice(0, this.pageSize);
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }
  checkDateCustom() {
    const dateDropDown = this.auditLogForm.get('date')?.value;
    if (dateDropDown == 'Custom') {
      this.customDateFlag = false;
    } else {
      this.customDateFlag = true;
      this.auditLogForm.controls['fromDate'].setValue(null);
      this.auditLogForm.controls['toDate'].setValue(null);
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.auditLog.slice(startIndex, endIndex);
  }
  sortTable(columnName: keyof auditLog) {
    this.dataLoaded = false;
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.auditLog.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      }
    });

    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.auditLog.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  getLocalTimeDate(utcDateStr: Date | null): Date | null {
    if (utcDateStr != null) {
      let sDate :Date = new Date(utcDateStr);
      const timezoneOffset : number= sDate.getTimezoneOffset();
      return new Date(sDate.getTime() - timezoneOffset * 60 * 1000);
    } else {
      return null;
    }
  }

  actionClass(row: any): string {
    var statusClass: string = '';
    switch (row) {
      case 'Delete': {
        statusClass = 'badge-danger';
        break;
      }
      case 'Insert': {
        statusClass = 'badge-success';
        break;
      }
      case 'Update': {
        statusClass = 'badge-primary';
        break;
      }
    }
    return statusClass;
  }

  ResetForm = (): void => {
    this.auditLogForm.reset();
    this.paginatedData = null;
    this.auditLog = [];
    this.fieldNames = [];
    this.auditLogForm.controls['fromDate'].disable();
    this.auditLogForm.controls['toDate'].disable();
  };
}
