import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EncryptRolePermissionService {
  private encryptionKey = environment.encryptionKey;
  
  constructor(private http: HttpClient,@Inject(PLATFORM_ID) private platformId: Object) { }
  
  encryption(permissions :any,keyName :string){

    try {
      const encryptedPermissions = CryptoJS.AES.encrypt(
        JSON.stringify(permissions),
        this.encryptionKey
      ).toString();
      localStorage.setItem(keyName, encryptedPermissions);
    } catch (error) {
     
    }
  }
  getDecryptedPermissions(keyName:string): any {
    if (isPlatformBrowser(this.platformId)) {
    try {
      const encryptedPermissions = localStorage.getItem(keyName);     
      if (encryptedPermissions) {
        const decryptedBytes = CryptoJS.AES.decrypt(
          encryptedPermissions,
          this.encryptionKey
        );
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);        
        return JSON.parse(decryptedData);
      }
    } catch (error) {
    }
  }
    return null;
  }
}
