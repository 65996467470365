<div *ngIf="loading" class="loader-block ">
    <div class="inner-loader">
        Loading...
    </div>
</div>
<div class="login-wrapper">
    <div class="login">
        <div class="login-left">
            <em>
                <img src="../../../../assets/logo.png" alt="">
                <img src="../../../../assets/logo.png" alt="" class="sm-logo">
            </em>
        </div>
        <div class="login-right">
            <div *ngIf="!validToken ; else elseBlock">
                <p>Link has been expired or it is invalid</p>
                <p>                   
                    <a  (click)="regenerateLink()" class="underline"  >Click here</a> 
                    to regenerate the link.
                  </p>
            </div>
            <ng-template #elseBlock>
            <form [formGroup]="loginForm" (ngSubmit)="loginClient()" class="form">
                <div id="signinDiv">
                    <h2>Login</h2>

                    <div class="form-group"
                        [ngClass]=" {'newTooltip expand has-error':loginForm.controls['clientID'].invalid && (loginForm.controls['clientID'].dirty || loginForm.controls['clientID'].touched )}"
                        datatitle="ClientID is required">
                        <label>Client ID</label>
                        <input type="text" formControlName="clientID" maxLength="10" class="form-control placeholder-color"
                            placeholder="Client ID">
                    </div>
                    <div class="form-group"
                        [ngClass]=" {'newTooltip expand has-error':loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)}"
                        datatitle="Username is required">
                        <label>Username</label>
                        <input type="text" formControlName="username" maxLength="50" class="form-control placeholder-color"
                            placeholder="Username">

                    </div>
                    <div class="form-group"
                        [ngClass]=" {'newTooltip expand has-error':loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched )}"
                        datatitle="Password is required">
                        <label>Password</label>
                        <div class="showhide">
                            <input formControlName="password" id="loginpassword" [type]="fieldTextType ? 'text' : 'password'"
                                class="form-control placeholder-color" placeholder="Password">
                            <div class="eye-icon">
                                <span>
                                    <img [src]="fieldTextType ? '../../../assets/eye.svg':'../../../assets/eye-slash.svg'"
                                        class="bi" (click)="toggleFieldTextType()">
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="forgot-block">
                        <div class="custom-checkbox">
                            <input type="checkbox" formControlName="rememberMe" class="checkbox-input">
                            <span class="checkbox-label">Remember Me</span>
                        </div>
                        <a style="cursor: pointer;" (click)="routeToForgotPassword()" class="forgot-link">Forgot Password?</a>
                    </div>

                    <input type='submit' value='Login' title="Login" class="btn btn-success" />
                    <div *ngIf="validationflag" class="alert alert-danger" style="margin-top: 10px;">{{errormsg}}

                    </div>
                </div>

            </form>
            </ng-template>
        </div>
    </div>
</div>