import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Clients, CreatedByData, Products, TenantCars, TradeList, TradeStatus, TradeStatusObject } from '../../components/confirmation-reports/models/confirmation-reports.models';

@Injectable()
export class ConfirmationReportsService {
  private apiUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  getTenantList = (): Observable<TenantCars[]> => {
    let url: string = this.apiUrl + "Common/TenantCarList";
    return this.http.get<TenantCars[]>(url);
  }

  getClientList = (tenantCar: string): Observable<Clients[]> => {
    let url: string = this.apiUrl + "Common/ClientList?tenantCar="+ tenantCar;
    return this.http.get<Clients[]>(url);
  }

  getProductList = (clientId: number | undefined): Observable<Products[]> => {
    let url: string = this.apiUrl + "Common/GetClientProducts?clientID="+ clientId;
    return this.http.get<Products[]>(url);
  }

  getCreatedByList = (clientId: number | undefined): Observable<CreatedByData[]> => {
    let url: string = this.apiUrl + "Common/CreatedByList?clientID="+ clientId;
    return this.http.get<CreatedByData[]>(url);
  }

  getTradeStatusList = (): Observable<TradeStatusObject> => {
    let url: string = this.apiUrl + "Reports/Confirmation/GetLists";
    return this.http.get<TradeStatusObject>(url);
  }

  searchTrade = (isAdmin: boolean, searchParams: any): Observable<TradeList> => {
    let endPoint: string = isAdmin ? "Reports/Admin/Confirmation" : "Reports/Client/Confirmation";
    let url: string = this.apiUrl + endPoint;
    return this.http.post<TradeList>(url, searchParams);
  }

  dealHistoryGetTradeConfo(tradeID: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'DealHistory/GetTradeConfo?tradeID=' + tradeID, { headers: headers, responseType: 'blob' });

  }

}
