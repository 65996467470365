import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageRolesService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getRoleList(
    status: string,
    roleName: string
  ): Observable<any>
  {
    if(status == 'All'){
      status = 'Both';
    }

    const payload = {
      status: status,
      roleName: roleName,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'AdminRole/search';
    return this.http.post<any>(url, payload, httpOptions);
  }

  setroleActiveInActive(roleId: number, activationStatus: boolean):Observable<any>{
    let payload = {
      roleId: roleId,
      isActive: activationStatus,
    };
    let url = this.apiUrl + 'AdminRole/updatestatus';
    return this.http.patch<any>(url, payload);
  }

}
