import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BankDetails, BankTabDropDownList, Country, CurrencyAccount, SettlementMethod } from '../models/banking.model';
import { BankingService } from '../../../services/bankingTab/banking.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BankingTabConstant, ErrorMessages, SuccessMessages } from '../../../global/global.constant';
import { SweetalertService } from '../../../services/SweetAlert/sweetalert.service';

@Component({
  selector: 'app-add-edit-banking-tab',
  templateUrl: './add-edit-banking-tab.component.html',
  styleUrl: './add-edit-banking-tab.component.css',
})
export class AddEditBankingTabComponent implements OnInit {
  countryList: Country[] = [];
  currencyAccountList: CurrencyAccount[] = [];
  settlementMethodList: SettlementMethod[] = [];
  clientId: number = 0;
  dataLoaded: boolean = true;
  loadingMessage = 'Loading';
  bankId: number = 0;
  bankIsActive: boolean = false;
  canAddEdit: boolean = false;

  bankNameMaxLength: number = BankingTabConstant.bankNameMaxLength;
  accountNameMaxLength: number = BankingTabConstant.accountNameMaxLength;
  accountNoMaxLength: number = BankingTabConstant.accountNoMaxLength;
  bsbNoMaxLength: number = BankingTabConstant.bsbNoMaxLength;
  streetAddressMaxLength: number = BankingTabConstant.streetAddressMaxLength;
  suburbMaxLength: number = BankingTabConstant.suburbMaxLength;
  stateMaxLength: number = BankingTabConstant.stateMaxLength;
  postCodeMaxLength: number = BankingTabConstant.postCodeMaxLength;

  bankForm: FormGroup = this.formBuilder.group({
    bankId: new FormControl<number>(0),
    bankName: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(BankingTabConstant.bankNameMaxLength),
    ]),
    accountName: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(BankingTabConstant.accountNameMaxLength),
    ]),
    bsbNo: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(/^\d{3}-\d{3}$/),
      Validators.maxLength(BankingTabConstant.bsbNoMaxLength),
    ]),
    accountNo: new FormControl<string>('', [
      Validators.required,
      // Validators.pattern('^[a-zA-Z0-9]*$'),
      Validators.maxLength(BankingTabConstant.accountNoMaxLength),
    ]),
    currencyAccountID: new FormControl<number>(0,[Validators.required]),
    settlementMethodID: new FormControl<number>(0, [Validators.required]),
    isActive: new FormControl<boolean>(false),
    streetAddress: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(BankingTabConstant.streetAddressMaxLength),
    ]),
    suburb: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z ]*$'),
      Validators.maxLength(BankingTabConstant.suburbMaxLength),
    ]),
    state: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z ]*$'),
      Validators.maxLength(BankingTabConstant.stateMaxLength),
    ]),
    postCode: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9 ]*$'),
      Validators.maxLength(BankingTabConstant.postCodeMaxLength),
    ]),
    countryID: new FormControl<number>(0, [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddEditBankingTabComponent>,
    private bankingService: BankingService,
    private sweetAlertService: SweetalertService,
    @Optional() @Inject(MAT_DIALOG_DATA) public bankingTabData: any
  ) {}

  ngOnInit(): void {
    this.clientId = this.bankingTabData.clientId;
    this.bankId = this.bankingTabData.bankId;
    this.getList();
    if(this.bankId > 0){
      this.getBank();
    }
    else{
      this.bankForm.reset();
    }
  }

  getList() {
    this.dataLoaded = false;
    this.bankingService.getDropDownList().subscribe({
      next: (data: BankTabDropDownList) => {
        this.countryList = data.countries;
        this.currencyAccountList = data.currencyAccount;
        this.settlementMethodList = data.settlementMethod;
        this.dataLoaded = true;
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  private getBank = (): void => {
    if(this.bankId > 0){
      this.dataLoaded = false;
      this.bankingService.getBank(this.bankId).subscribe({
        next: (data : BankDetails) => {
          if(data){
            this.bankForm.patchValue(data);
            if(this.bankingTabData.rowClicked){
              this.bankForm.disable();
            }
            this.dataLoaded = true;
          }
        },
        error: () => {
          this.dataLoaded = true;          
        }
      });
    }
  }

  submit = (): void => {
    this.bankForm.controls["isActive"].setValue(this.bankIsActive);
    const formValue = this.bankForm.getRawValue();
    if(this.bankForm.valid){
      if(this.bankId > 0){
        this.dataLoaded = false;

        //PATCH call
        formValue.clientBankID = this.bankId;
        this.bankingService.updateClientBank(formValue).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.sweetAlertService.messageAlert(SuccessMessages.BankDataUpdated);
            this.dialogRef.close({ isUpdate: true });
          },
          error: (error) => {
            this.dataLoaded = true;
            this.sweetAlertService.showError(error,error.errorMessage);
          }
        });
      }
      else{
        this.dataLoaded = false;

        //POST call
        formValue.clientId = this.clientId;
        this.bankingService.addClientBank(formValue).subscribe({
          next: () => {
            this.dataLoaded = true;
            this.sweetAlertService.messageAlert(SuccessMessages.BankDataAdded);
            this.dialogRef.close({ isUpdate: false });
          },
          error: (error) => {
            this.dataLoaded = true;
            this.sweetAlertService.showError(error,error.errorMessage);
          }
        });
      }
    }
    else{
      this.markFormGroupTouched(this.bankForm);
    }
  };

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
    this.sweetAlertService.showError('error',ErrorMessages.Please_fill_required_inputs);
  }

  closeModal = (): void => {
    this.dataLoaded = true;
    this.dialogRef.close();
  };
}
