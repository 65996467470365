import { Component, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ManageContactsService } from '../../services/managecontacts/manage-contacts.service';
import { ManageUsersService } from '../../services/ManageUsers/manage-users.service';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { HeaderComponent } from '../Home/header/header.component';
import { NavigationComponent } from '../Home/navigation/navigation.component';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrl: './manage-users.component.css',
})
export class ManageUsersComponent {
  status: any[] = [];
  roleList: any = [];
  roleFilterForm: any;
  searchString: string = '';
  filterRoleId: any = undefined;
  filterStatus: any = undefined;

  @ViewChild('roleIdComponent') roleIdComponent: any;
  @ViewChild('statusComponent') statusComponent: any;

  @ViewChild(HeaderComponent, { static: false })
  headerComponent!: HeaderComponent;
  isNavBarOpen: boolean = true;
  hideAddButton = false;

  /* Pagination parameters */
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  disableSuperAdmin: boolean = true;
  dataLoaded: boolean = false;
  pageEvent: PageEvent | undefined;

  sortedColumn: string = '';
  isAscending: boolean = true;

  baseStatus: string = 'All';

  clients: any[] = [];
  paginatedData: any[] = [];

  @ViewChild(NavigationComponent) childComponent!: NavigationComponent;

  constructor(
    private manageUsersService: ManageUsersService,
    private router: Router,
    private renderer: Renderer2,
    private encryptPermission: EncryptRolePermissionService
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 2
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
    }
  }

  ngOnInit() {
    this.dataLoaded = false;
    this.getRoleStatusFilterData();
    this.getAdminUsersList('', 0, this.baseStatus);
  }

  getRoleStatusFilterData(){
    const promise = new Promise<void>((resolve, reject) => {
      this.manageUsersService
        .getRoleStatusFilter()
        .subscribe({
          next: (data: any) => { 
            this.status = data.statuses.sort((a: any, b: any) => a.statusName.toString() - b.statusID.toString());
            this.roleList = data.roles.sort((a: any, b: any) => a.roleName.toString() - b.roleID.toString());  
            resolve();
          },
          error: (error) => {
          },
        });
    });
    return promise;
  }

  getAdminUsersList(searchString: string, roleID: number, status: string) {
    const promise = new Promise<void>((resolve, reject) => {
      if (roleID == undefined || roleID == null) {
        roleID = 0;
      } else if (status == undefined || status == null) {
        status = this.baseStatus;
      }

      this.manageUsersService
        .getAdminUsersList(searchString, roleID, status)
        .subscribe({
          next: (data: any) => {
            
            this.clients = data;
            this.length = this.clients.length;
            this.paginatedData = this.clients.slice(0, this.pageSize);
            this.dataLoaded = true;
            resolve();
          },
          error: () => {
          },
        });
    });
    return promise;
  }

  sort(columnName: string): void {
    this.clients.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }

  sortTable(columnName: string) {
    this.dataLoaded = false;

    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clients.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }

    this.paginatedData = this.clients.slice(startIndex, endIndex);
    this.dataLoaded = true;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  search() {
    this.dataLoaded = false;
    

    if (this.filterRoleId == undefined || this.filterRoleId == null) {
      this.filterRoleId = 0;
    } else if (this.filterStatus == undefined || this.filterStatus == null) {
      this.filterStatus = this.baseStatus;
    }

    this.getAdminUsersList(
      this.searchString,
      this.filterRoleId,
      this.filterStatus
    );
  }

  reset() {
    this.dataLoaded = false;
    this.searchString = '';
    this.roleIdComponent.handleClearClick();
    this.statusComponent.handleClearClick();
    this.getAdminUsersList('', 0, this.baseStatus);
  }

  editManageUser(userID:number){
    // this.router.navigate(['/addEditUser', userID]);
    this.router.navigate(['/Admin/addEditUser', userID]);

  }

  addManageUser(){
    // this.router.navigate(['/addEditUser']);
    this.router.navigate(['/Admin/addEditUser']);
  }

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') { 
      this.openCloseDropdwon("0");
    } 
    else {
      this.openCloseDropdwon("1");
    }
  }

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
      if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
        this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
      }
  }
}
