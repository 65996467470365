<div class="card secondary">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs">        
           

            <li  id="liAddress" class="nav-item">
                <a class="nav-link" data-toggle="tab" 
                    routerLinkActive="active"
                    [ngClass]="{ 'active': isaddressActive }"
                    (click)="handleTabClick('address')" 
                    [routerLink]="[{ outlets: { 'tabnavigation': ['clientaddress',this.clientID] } }]"  >Address</a>
            </li>
            <li id="liContacts" class="nav-item">
                <a class="nav-link" data-toggle="tab" routerLinkActive="active" 
                (click)="handleTabClick('contact')"              
                [routerLink]="[{ outlets: { 'tabnavigation': ['clientcontacts',this.clientID] } }]">Contacts</a>
            </li>
            <li id="liBeneficiaries" class="nav-item">
                <a class="nav-link" data-toggle="tab" routerLinkActive="active" 
                (click)="handleTabClick('beneficiaries')"              
                [routerLink]="[{ outlets: { 'tabnavigation': ['clientBeneficiaries',this.clientID] } }]">Beneficiaries</a>
            </li>
    </ul>
    <div class="tab-content">
                <!-- General Body -->
        <div class="tab-pane active" id="CARPartnerGeneral">
            <div class="tab-body-wrapper">
                <router-outlet name="tabnavigation"></router-outlet>
            </div>
        </div>
    </div>
</div>
