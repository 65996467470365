import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralPartnerService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getreferralPartnerList(
    search: string,
  ): Observable<any>
  {
    const payload = {
      partnerContactEmailSearchString: search
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner/Search';
    return this.http.post<any>(url, payload, httpOptions);
  }
}
