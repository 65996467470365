import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { VerifyEmailResponse } from '../../components/verify-email/verifyEmail.model';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  resetPassword(endPoint: string, payload: any): Observable<any> {
    let url = this.apiUrl + endPoint;
    return this.http.post<any>(url, payload);
  }

  validateToken(endpoint: string, token: any): Observable<any> {    
    let url = this.apiUrl + endpoint +encodeURIComponent(token);
    return this.http.post(url,null);
  }

  verifyEmail(token: string): Observable<VerifyEmailResponse> {    
    let url = this.apiUrl + 'Client/contact/verifyEmail?token='+encodeURIComponent(token);
    return this.http.post<VerifyEmailResponse>(url,null);
  }
}
