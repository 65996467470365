import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ManageTenantsService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  tenantSearch(payload: any) {
    return this.http.post(this.apiUrl + 'tenant/search', payload);
  }

  tenantList() {
    return this.http.get(this.apiUrl + 'Tenant/General/GetLists');
  }

  tenantDetails(tenantId: number) {
    return this.http.get(this.apiUrl + `Tenant/${tenantId}`);
  }

  addTenant(payload: any) {
    return this.http.post(this.apiUrl + 'Tenant', payload);

  }
  updateTenant(payload: any) {
    return this.http.patch(this.apiUrl + 'Tenant', payload);
  }


  validatecfcp(tenantId: number, cfcp: string) {
    const payload = {
      tenantID: tenantId,
      cashflowCounterparty: cfcp
    };
    return this.http.post(this.apiUrl + 'Tenant/ValidateCfCp', payload);
  }
  setPrimaryTenantFlag(tenantid :number,status :boolean):Observable<any>{
    let payload = {
      tenantID: tenantid,
      status: status,
    };
    let url = this.apiUrl + 'Tenant/setstatusflag';
    return this.http.patch<any>(url, payload);
}
}