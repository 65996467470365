import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalesMargin, SalesMarginList, SalesMarginTierList } from '../../components/client-sales-margin-tier/models/client-sales-margin-tier.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsalestierService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  ClientSalesTierData(clientID: number): Observable<SalesMarginList> {
    return this.http.get<SalesMarginList>(this.apiUrl + 'Client/salesmargins/' + clientID);
  }

  SalesMarginTierData(): Observable<SalesMarginTierList[]> {
    return this.http.get<SalesMarginTierList[]>(this.apiUrl + 'Client/salesmargins');
  }

  SalesMarginTierAndThresholdData(id: number, clientID: number): Observable<SalesMargin> {
    return this.http.get<SalesMargin>(this.apiUrl + `Client/SalesMargin?id=${id}&clientID=${clientID}`);
  }

  updateSalesMarginTier(payload: SalesMargin) {
    return this.http.patch<SalesMargin>(this.apiUrl + 'Client/salesmargin', payload);
  }

  newTierListData(): Observable<SalesMarginTierList[]> {
    return this.http.get<SalesMarginTierList[]>(this.apiUrl + 'SalesMargin');
  }
}
