import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ErrorMessages } from '../../global/global.constant';

@Injectable({
  providedIn: 'root',
})
export class SweetalertService {
  constructor() {}

  fireAlert(name: string) {
    return new Promise<void>((resolve, reject) => {
      Swal.fire({
        html: `
        <div style="">
        <img src="../../../assets/circle-check-solid.svg" style="vertical-align:middle;height:30px;width:30px;color:green"> 
        <span style='margin-left:10px'>Record ${name} Successfully</span>
        
        </div>
        `,

        showConfirmButton: false,
        customClass: 'custome-size',
        heightAuto: false,
        width: 'fit-content',
        timer: 1500,
      }).then(() => {
        resolve();
      });
    });
  }

  showError = (error: any, errorMessage: string): void => {
    var errormsg: string = '';
    if (error && error.error && error.error.errors) {
      const errors = error.error.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          const errorMessages = errors[key];
          errormsg = `${errorMessages.join(', ')}`;
        }
      }
    } else {
      errormsg = errorMessage;
    }
    Swal.fire({
      icon: 'warning',
      title: errormsg,
      confirmButtonText: 'OK',
      showConfirmButton: true,
    });

  }

  messageAlert(message:  string) {
    return new Promise<void>((resolve, reject) => {
      Swal.fire({
        html: `
        <div style="">
        <img src="../../../assets/circle-check-solid.svg" style="vertical-align:middle;height:30px;width:30px;color:green">
        <span style='margin-left:10px'> ${message} </span>
       
        </div>
        `,
 
        showConfirmButton: false,
        customClass: 'custome-size',
        heightAuto: false,
        width: 'fit-content',
        timer: 1500,
      }).then(() => {
        resolve();
      });
    });
  }


}
