import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageSalesMarginService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getAllSalesMargin(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'SalesMargin';
    return this.http.get<any>(url, httpOptions);
  }

  getSalesMargibById(id: number): Observable<any>{
    // let headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'SalesMargin/' + id;

    const parameters = new HttpParams().set("id", id);
    // return this.http.get<any>(url, { headers: headers, params: parameters, withCredentials: true });
    return this.http.get<any>(url, httpOptions);
  }

  addSalesMargin(payload: any): Observable<any>{
    
    const payloadData = {
      payload: payload,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'SalesMargin';
    return this.http.post<any>(url, payload, httpOptions);
  }

  editSalesMargin(payload: any){
    const payloadData = {
      payload: payload,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url: string = this.apiUrl + 'SalesMargin';
    return this.http.patch<any>(url, payload);
  }
}
