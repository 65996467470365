<!-- <app-header></app-header> -->

<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">Loading...</div>
  </div>
</div>

<div >
  <div class="inner user-page">
    <div class="title">
      <span class="titleHeader mt-2">Referral Partners</span>
      <h3>&nbsp;</h3>
      <a (click)="addReferralPartner()" *ngIf="!hideAddButton" id="btnClientAddNew" class="btn btn-success">
        <em
          ><img src="../../../assets/plus.svg" alt=""
        /></em>
        Add
      </a>
    </div>

    <div class="card maindiv">
      <div class="row search-criteria">
        <div class="col-md-4 col-sm-4 mt-2">
          <div class="form-group textstyle">
            <label>Referral Partner/ Contact Name/ Email Address</label>
            <input
              type="text"
              class="form-control"
              id="txtreferralPartnerName"
              tabindex="1"
              [(ngModel)]="searchString"
              (keydown.enter)="search()"
            />
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="inline-element" style="padding-top: 25px">
            <a class="btn btn-success" id="btnSearch" (click)="search()">Search</a>
            <a class="btn btn-secondary" id="btnReset" (click)="reset()">Reset</a>
          </div>
        </div>
      </div>

      <div class="nth-last-child-2-center"></div>

      <div *ngIf="clients.length>0;else notFound" class="GridClass custom-container">
        <div class="table-responsive" >
          <table class="table custom-grid mt-4">
            <thead>
              <tr class="Row123">
                <th>
                  <a
                    class="sort active ts-asc"
                    (click)="sortTable('referralPartnerName')"
                    unselectable="on"
                    data-i="0">Referral Partner<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active ts-asc"
                    (click)="sortTable('contactName')"
                    unselectable="on"
                    data-i="1">Referral Partner Contact Name<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('contactEmail')"
                    unselectable="on"
                    data-i="3">Contact Email Address<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('revenueSharingSpot')"
                    unselectable="on"
                    data-i="4">Revenue Sharing % Spot<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('revenueSharingForward')"
                    unselectable="on"
                    data-i="4">Revenue Sharing % Forward<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('revenueSharingOptions')"
                    unselectable="on"
                    data-i="4">Revenue Sharing % Options<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a
                    class="sort active"
                    (click)="sortTable('relationshipManager')"
                    unselectable="on"
                    data-i="4">Navigate Relationship Manager<span class="ts-sord"></span>
                  </a>
                </th>
                <th>
                  <a class="sort" unselectable="on" data-i="8">
                    <span class="action">Action</span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of paginatedData">
                <td>{{row.referralPartnerName}}</td>
                <td>{{row.contactName}}</td>
                <td>{{row.contactEmail}}</td>
                <td>{{row.revenueSharingSpot}}</td>
                <td>{{row.revenueSharingForward}}</td>
                <td>{{row.revenueSharingOptions}}</td>
                <td>{{row.relationshipManager}}</td>
                <td>
                  <div class="action-btns">
                    <a title="Edit"
                      ><img
                        (click)="editReferralPartner(row.referralPartnerID)"
                        src="../../../assets/edit.svg"
                        alt=""
                    /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>       
        </div>
        <mat-paginator *ngIf="clients.length>0"
          #paginator
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #notFound>
    <div *ngIf="dataLoaded" class="alert alert-info mt-4">
        <span>No record found</span>
    </div>
</ng-template>
