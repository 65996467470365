
<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            {{loadingMessage}}...
        </div>
    </div>
</div>
<div *ngIf="deltaLimits.length>0;else notFound" class="GridClass">
    <div class="title paraspace">
        <p>Clinent:{{clientName}}<span>({{clientCode}})</span> </p>
    </div>
    <div class="custom__container">
    <div   class="table-responsive">
        <table class="table custom-grid">
            <colgroup>
                <col style="width:20%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:15%">
                <col style="width:10%">
            </colgroup>
            <thead>
                <tr class="Row123">
                    <th><a (click)="sortTable('currencyPairFormed')" class="sort  active  ts-asc" unselectable="on"
                            data-i="0">Currency&nbsp;Pair&nbsp;Formed<span class="ts-sord"></span> </a></th>
                    <th><a (click)="sortTable('deltaBaseCurrencyName')" class="sort  active  ts-asc" unselectable="on"
                            data-i="1">Delta&nbsp;Currency<span class="ts-sord"></span> </a></th>
                    <th><a (click)="sortTable('spotsForwards')" class="sort  active  ts-asc" unselectable="on"
                            data-i="2">Spots/Forwards<span class="ts-sord"></span> </a></th>
                    <th><a (click)="sortTable('options')" class="sort  active  " unselectable="on"
                            data-i="3">Options<span class="ts-sord"></span> </a></th>
                    <th><a (click)="sortTable('iceBook')" class="sort  active  " unselectable="on"
                            data-i="4">ICE&nbsp;Book Name<span class="ts-sord"></span> </a></th>
                    <th><a (click)="sortTable('deltaLimit')" class="sort  active  " unselectable="on"
                            data-i="5">Delta&nbsp;Limit<span class="ts-sord"></span> </a></th>
                    <th *ngIf="canAddEdit"><a class="sort" unselectable="on" data-i="8">
                            <span class="action">Action</span>

                        </a></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of paginatedData">
                    <td>{{ row.currencyPairFormed }}</td>
                    <td>{{ row.deltaBaseCurrencyName }}</td>
                    <td>{{ row.spotsForwards }}</td>
                    <td>{{row.options}}</td>
                    <td>{{ row.iceBook }}</td>
                    <td>{{row.deltaLimit}}</td>
                    <td *ngIf="canAddEdit">
                        <div class="action-btns"><a (click)="openModal(row)" title="Edit"><img
                                    src="../../../assets/edit.svg" alt=""></a></div>
                    </td>
                </tr>
            </tbody>
        </table>        
    </div>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25]"
            (page)="handlePageEvent($event)">
        </mat-paginator>
    </div>
</div>
<div class="modal fade secondary backdrop" id="updateCurrencyPair-modal">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div id="divUpdateCurrencyPair">
                <div style="padding:50px;">
                    <input type="hidden" id="hdnclientTenantCurrencyPairID" />
                    <div class="title" style="margin-bottom:20px;margin-left:-5px;">
                        <h4>Update Delta Limit</h4>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> Client Currency Pair </label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;">
                            <span class="badge secondery" style="font-size:16px !important;"
                                id="spnCurrencyPairFormed">{{selectedrow.currencyPairFormed}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> Delta in Base </label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;margin-left:-15px;">
                            <span class="badge secondery" style="font-size:16px !important;"
                                id="spnDeltaInBase">{{selectedrow.deltaBaseCurrencyName}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> Spots/Forwards </label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;margin-left:-15px;">
                            <span class="badge secondery" style="font-size:16px !important;" id="spnSpotsForwards">{{
                                selectedrow.spotsForwards ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> Options </label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;margin-left:-15px;">
                            <span class="badge secondery" style="font-size:16px !important;" id="spnOptions">{{
                                selectedrow.options ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> ICE Book Name </label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;">
                            <span class="badge secondery" style="font-size:16px !important;"
                                id="spnICEBookName">{{selectedrow.iceBook}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" style="padding :10px 11px;">
                            <label> Delta Limit <em style="color:red;">*</em></label>
                        </div>
                        <div class="col-md-5" style="padding :10px 11px;">
                            <input type="text" class="form-control number" id="txtUpdatedDeltaLimit"
                                [(ngModel)]="updatedDeltaLimit" (ngModelChange)="maxDeltaLimit()" appTwoDigitDecimaNumber/>
                        </div>
                    </div>

                    <div class="inline-element" id="dvUpdateClientCurrencyPair" style="margin-top:10px;">
                        <hr />
                        <button id="btnReturn" type="button" (click)="close()" class="btn btn-secondary"
                            value="ClientCurrencyPair">Return</button>
                        <button id="btnUpdateClientCurrencyPair" type="button" class="btn btn-success float-right"
                            (click)="updateDeltalimit()" [disabled]="!updatedDeltaLimit" value="ClientCurrencyPair">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #notFound>
    <div  class="alert alert-info">
      <span>No record found</span>
    </div>
</ng-template>