<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            Loading...
        </div>
    </div>
</div>
<div class=" inner manage-Contact " >
    <div class="title" style="display: flex; justify-content: flex-end;">
       
            <a  *ngIf="!hideAddButton" (click)="addContact()"  class="btn btn-success" >
                <em><img src="../../../../assets/plus.svg" alt=""></em>
                Add
            </a>     
    </div>
    <div class="card" >
        <form [formGroup]="contactsSearchForm" (ngSubmit)="searchContact()">
                <div class="row search-criteria">               
                    <div class="col-md-3 col-sm-6">
                        <div class="form-group">
                            <label>Tenant/CAR Partner</label>
                            <ng-select
                            [items]="tenantList"
                            [searchable]="true"
                            bindLabel="tenantCARPartnerName"
                            bindValue="tenantCARPartnerID"
                            placeholder="Select"
                            (keydown.enter)="searchContact()"
                            formControlName="selectedTenatCarpartner"
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                            >
                        </ng-select> 
                        </div>
                    </div>
                
                <div class="col-lg-3  col-sm-6">
                    <div class="form-group">
                        <label>Contact Name</label>
                        <input type="text" 
                        class="form-control" 
                        (keydown.enter)="searchContact()"
                        formControlName="selectedContacttName">
                    </div>
                </div>
                <div class="col-lg-3  col-sm-6">
                    <div class="form-group">
                        <label>Client Name</label>
                        <input type="text" 
                        (keydown.enter)="searchContact()"
                        class="form-control" 
                        formControlName="selectedclinetName">
                    </div>
                </div>
                <div class="col-lg-3  col-sm-6">
                    <div class="form-group">
                        <label>Status</label>
                        <ng-select
                            [items]="status"
                            [searchable]="true"
                            bindLabel="contactStatusValue"
                            bindValue="contactStatusId"
                            placeholder="Select"
                            formControlName="selectedstatus"
                            (keydown.enter)="searchContact()"
                            (keydown)="closeDropdwon($event)"
                            (click)="closeDropdwon($event)"
                            >
                        </ng-select> 
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="inline-element" style="padding-top:25px;">
                        <button class="btn btn-success" type="submit">Search</button>                        
                        <a  class="btn btn-secondary"  (click)="reset()">Reset</a>
                    </div>
                </div>      
            </div>
        
        <div class="nth-last-child-2-center nth-last-child-3-center">
            <div *ngIf="contacts.length>0;else notFound"  class="GridClass custom-container">
                <div class="table-responsive">
                    <div class="custom__container">
                    <table class="table custom-grid">
                        <thead>
                            <tr class="Row123">
                                <th><a (click)="sortTable('clientName')" class="textstyle sort  active  ts-asc"
                                        unselectable="on" data-i="0">Client&nbsp;Name<span class="ts-sord"></span>
                                    </a></th>
                                <th><a (click)="sortTable('firstName')" class="textstyle sort  active  ts-asc" unselectable="on"
                                        data-i="1">Contact&nbsp;Name<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('emailAddress')" class=" textstyle sort active  ts-asc"
                                        unselectable="on" data-i="2">Email<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('userName')" class="textstyle sort  active  " unselectable="on"
                                        data-i="3">Username<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('status')" class=" textstyle sort  active  " unselectable="on"
                                        data-i="4">Status<span class="ts-sord"></span> </a></th>
                                <th><a (click)="sortTable('')" class="textstyle sort  active  " unselectable="on"
                                        data-i="5">Is&nbsp;Primary<span class="ts-sord"></span> </a></th>                               
                                <th *ngIf="canUpdate"><a class="sort textstyle" unselectable="on" data-i="8">
                                        <span class="action">Action</span>
                                    </a></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginatedData">
                                <td (click)="editContact(row)">{{row.clientName}}</td>
                                <td (click)="editContact(row)">{{(row.salutation ? row.salutation:'')+' '+row.firstName+' '+row.lastName}}</td>
                                <td (click)="editContact(row)">{{row.emailAddress}}</td>
                                <td (click)="editContact(row)">{{row.userName}}</td>
                                <td (click)="editContact(row)"><span class="badge" [ngClass]="statusClass(row)">{{getstatus(row.status)}}</span></td>
                                <td>
                                    <label class="switch">
                                        <input type="checkbox" [disabled]="combineFP" [checked]="row.isPrimaryContact" (change)="toggleSwitch(row)">
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                
                                <td *ngIf="canUpdate"><div class="action-btns" (click)="editContact(row)"><a   title="Edit"><img
                                    src="../../../assets/edit.svg" alt=""></a></div>
                                </td>                        
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
                        [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
            <ng-template #notFound>
            <div class="alert alert-info">
                <span>No record found</span>
            </div>
            </ng-template>
        </div>
    </form>
        </div>
</div>



