import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { HomeComponent } from './components/Home/home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ClientManagementComponent } from './components/client-management/client-management.component';
import { ValidateClientOtpComponent } from './components/validate-client-otp/validate-client-otp.component';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswardComponent } from './components/reset-passward/reset-passward.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { UserRolesComponent } from './components/user-roles/user-roles.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddEditRolesComponent } from './components/add-edit-roles/add-edit-roles.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserProfileService } from './services/userprofile/user-profile.service';
import { IndexComponent } from './components/Dashboard/index/index.component';
import { NewsFeedComponent } from './components/Dashboard/news-feed/news-feed.component';
import { RateChartsComponent } from './components/Dashboard/rate-charts/rate-charts.component';
import { SpotRatesComponent } from './components/Dashboard/spot-rates/spot-rates.component';
import {  NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    HomeComponent,
    ClientManagementComponent,
    ValidateClientOtpComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswardComponent,
    VerifyEmailComponent,
    UserRolesComponent,
    AddEditRolesComponent,
    UserProfileComponent,
    IndexComponent,
    NewsFeedComponent,
    RateChartsComponent,
    SpotRatesComponent,
    
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatToolbarModule,
    FormsModule,
    MatSidenavModule,
    ReactiveFormsModule,
    BrowserModule,
    ClientRoutingModule,MatInputModule,
    MatIconModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule
    
  ],
  providers: [UserProfileService]
})
export class ClientModule { }
