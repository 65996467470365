<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">
            {{loadingMessage}}
        </div>
    </div>
</div>

<form [formGroup]="addReferralPartnerForm" class="form" (ngSubmit)="onSave()">
    <div class="row">        
        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Referral Partner <em style="color:red;">*</em></label>
                <div class="showhide" [ngClass]="{'newTooltip expand has-error': errorArray[0] != '' }"
                    title="{{errorArray[0]}}">
                    <input type="text" formControlName="partnerName" (input)="errorChecking()" (blur)="errorChecking()" class="form-control" placeholder="Partner Name"
                        maxlength="200" minlength="-1">
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Referral Partner Contact Name <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': errorArray[1] != '' }"
                    title="{{errorArray[1]}}">
                    <input type="text" formControlName="contactName" (input)="errorChecking()" (blur)="errorChecking()" maxlength="200" minlength="-1" class="form-control"
                        placeholder="Referral Partner Contact Name">
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Contact Email Address <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]="{'newTooltip expand has-error': errorArray[2] != '' }"
                    title="{{errorArray[2]}}">
                    <input type="text" (input)="errorChecking()" (blur)="errorChecking()" formControlName="contactEmail" class="form-control" maxlength="200" minlength="-1" placeholder="Contact Email Address">
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Revenue Sharing % Spot  <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': errorArray[3] != '' }"
                    title="{{errorArray[3]}}">
                    <input type="text" formControlName="revenueSharingSpot" min="0" max="100" class="form-control"
                        placeholder="Revenue Sharing % Spot" (input)="errorChecking()" (blur)="errorChecking()">
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Revenue Sharing % Forward <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]="{'newTooltip expand has-error': errorArray[4] != '' }"
                    title={{errorArray[4]}}>
                    <input type="text" formControlName="revenueSharingForward" (input)="errorChecking()" (blur)="errorChecking()" min="0" max="100" class="form-control" placeholder="Revenue Sharing % Forward">
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Revenue Sharing % Options  <em style="color:red;">*</em></label>
                <div class="showhide"
                    [ngClass]=" {'newTooltip expand has-error': errorArray[5] != '' }"
                    title="{{errorArray[5]}}">
                    <input type="text" formControlName="revenueSharingOptions" min="0" max="100" class="form-control" (blur)="errorChecking()" (input)="errorChecking()"
                        placeholder="Revenue Sharing % Options">
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Contract End Date</label>
                <div class="showhide">
                    <input type="date" min="{{todayMinDate}}" formControlName="contractEndDate" autocomplete="off" class="form-control datepicker">
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group label-inline">
                <label class="boldLabel">Navigate Relationship Manager <em style="color:red;">*</em></label>
                <div class="showhide" [ngClass]=" {'newTooltip expand has-error': errorArray[6] != '' }"
                    title="{{errorArray[6]}}">
                    <ng-select [items]="relationshipManagerList" class="errorSelect" bindLabel="managerName" bindValue="managerID" [multiple]="false"
                        [closeOnSelect]="true" [searchable]="true" [clearable]="false" placeholder="Select" (blur)="errorChecking()" (change)="errorChecking()"
                        formControlName="relationshipManagerID">
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="col-md-12 mt-3">
            <div class="inline-element d-block text-center">
                <button type="submit" class="btn btn-success">Save</button>
                <button type="button" class="btn btn-secondary" style="margin-left: 15px;" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</form>