import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberRange]'
})
export class NumberRangeDirective {

 
  constructor(private el: ElementRef,private ngControl: NgControl) { }

  @HostListener('input', ['$event'])  onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    let newValue = initialValue.replace(/[^0-9.]/g, ''); 
    newValue = parseFloat(newValue); 
    if (isNaN(newValue)) {
      newValue = '';
    }
    if (newValue < 0) {
      newValue = 0;
    }
    else if (newValue > 100) {
      newValue = 100;
    }
    newValue = newValue.toString();

    if (newValue !== parseFloat(initialValue).toString()) {
      this.el.nativeElement.value = newValue;
      this.ngControl.control?.setValue(newValue); 
    }
  }

}
