import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from '../../services/forgot-password/forgot-password.service';

declare var grecaptcha: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  loading = false;
  recaptchaKey = environment.recaptchaKey;
  isValid = false;
  error = false;
  successFull=false;
  errorMessage='';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private forgotPasswordService: ForgotPasswordService,
    private fb: FormBuilder
  ) {}

  @ViewChild('recaptchaContainer') recaptchaContainer!: ElementRef;

  forgetPasswordForm: FormGroup = this.fb.group({
    clientID:['',[Validators.required]],
    username: ['', [Validators.required]],
  });
  ngOnInit() {
    this.renderReCAPTCHA();
  }
  loadReCAPTCHAScript() {
    return new Promise<void>((resolve, reject) => {
      const script = this.document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/enterprise.js';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = reject;
      this.document.body.appendChild(script);
    });
  }
  renderReCAPTCHA() {
    this.loadReCAPTCHAScript().then((_) => {
      grecaptcha?.enterprise?.render(this.recaptchaContainer.nativeElement, {
        sitekey: environment.recaptchaKey,
        callback: this.verifyCallback.bind(this),
        'expired-callback': this.onExpired.bind(this),
        action: 'password_reset',

        theme: 'light', // or 'dark'
      });
    });
  }

  verifyCallback(response: any) {
    

    this.isValid = true;
  }
  onExpired() {
    this.isValid = false;
  }
  onSubmit() {
    this.error=false;
    if (this.forgetPasswordForm.valid && this.isValid) {
      
      const payload={
        clientCode:this.forgetPasswordForm.value.clientID,
        userName:this.forgetPasswordForm.value.username
      }
      this.loading = true;
      this.forgotPasswordService
        .forgotPassword(payload)
        .subscribe({
          next: (reponse) => {
            this.loading = false;
            this.successFull=true;
          },
          error: (response) => {
            this.loading = false;
            this.error = true;
            
            this.errorMessage=response.error[0].description;
          },
        });
    }
  }

}
