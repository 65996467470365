<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">{{ loadingMessage }}...</div>
  </div>
</div>

<div class="compress-inputs-page">
  <div class="GridClass">
    <div *ngIf="canAddEdit" class="title banking-title">
      <h1></h1>
      <a *ngIf="tabPermission.canInsert"
        data-toggle="modal"
        data-target="#banking-modal"
        class="btn btn-success"
        (click)="addBank()"
      >
        <em><img src="../../../../assets/plus.svg" alt="" /></em>
        Add
      </a>
    </div>
    <div  *ngIf="bankList.length > 0; else notFound" class="GridClass custom-container">
      <div  class="table-responsive">
        <table class="table custom-grid">
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 15%" />
            <col style="width: 20%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 15%" />
            <col style="width: 5%" />
            <col style="width: 5%" />
          </colgroup>
          <thead>
            <tr class="Row123">
              <th>
                <a
                  class="sort active ts-asc"
                  unselectable="on"
                  data-i="0"
                  >Bank Name<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a
                  class="sort active ts-asc"
                  unselectable="on"
                  data-i="1"
                  >Current Account<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a
                  class="sort active ts-asc"
                  unselectable="on"
                  data-i="2"
                  >Account Name<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a
                  class="sort active"
                  unselectable="on"
                  data-i="3"
                >
                  BSB No.<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a
                  class="sort active"
                  unselectable="on"
                  data-i="4"
                  >Account No.<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a
                  class="sort active"
                  unselectable="on"
                  data-i="5"
                  >Settlement Method<span class="ts-sord"></span>
                </a>
              </th>
              <th>
                <a class="sort active" unselectable="on" data-i="5"
                  >Is Active<span class="ts-sord"></span>
                </a>
              </th>
              <th *ngIf="canAddEdit">
                <a class="sort" unselectable="on" data-i="8">
                  <span class="action">Action</span>
                </a>
              </th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let row of paginatedData">
              <td (click)="viewBank(row.clientBankID)" title="Click to view bank details" class="underline">{{ row.bankName }}</td>
              <td (click)="viewBank(row.clientBankID)" title="Click to view bank details">{{ row.currencySymbol }}</td>
              <td class="accountName" (click)="viewBank(row.clientBankID)" title="Click to view bank details">{{ row.accountName }}</td>
              <td (click)="viewBank(row.clientBankID)" title="Click to view bank details">{{ row.bsbNo }}</td>
              <td (click)="viewBank(row.clientBankID)" title="Click to view bank details">{{ row.accountNo }}</td>
              <td (click)="viewBank(row.clientBankID)" title="Click to view bank details">{{ row.settlementMethodName }}</td>
              <td>
                 <label class="switch">
                    <input (change)="toggleSwitch(row, $event)" type="checkbox" 
                      [disabled]="!canAddEdit" name="isActive" [checked]="row.isActive" />
                    <span class="slider round" style="width: 46px"></span>
                  </label>
              </td>
              <td *ngIf="tabPermission.canInsert" title="Click to view bank details">
                <div class="action-btns">
                  <a title="Edit" (click)="editBank(row.clientBankID)"
                    ><img src="../../../assets/edit.svg" alt=""
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25]"
        (page)="handlePageEvent($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
  <ng-template #notFound>
    <div class="alert alert-info">
      <span>No record found</span>
    </div>
  </ng-template>
