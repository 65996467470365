<!-- <app-header></app-header> -->
<div class="">
    <div class="compress-inputs-page">
        <div class="inner secondary">
            <div class="title">
                <h3>&nbsp;</h3>

                <a (click)="backReferralPartner()" id="backButton" class="btn btn-outline-secondary btn-sp">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    <!-- <em class="arrow-back-white"><img src="../../../assets/left-arrow-white.svg"
                            alt=""></em> -->
                    Back
                </a>
            </div>
            <div class="card secondary">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li id="liGeneral" class="nav-item ">
                        <a *ngIf="editMode" class="nav-link" routerLinkActive="active" data-toggle="tab"
                            [routerLink]="[{ outlets: { 'referralPartner': ['referralPartnerGeneral',this.referralPartnerId] } }]"
                            [ngClass]="{ 'active': this.activeArray[0] }" (click)="handleTabClick('referralPartnerGeneral')">General</a>
                        <a *ngIf="!editMode" class="nav-link active" routerLinkActive="active" data-toggle="tab"
                            [routerLink]="[{ outlets: { 'referralPartner': [''] } }]">General</a>
                    </li>

                    <div *ngIf="editMode" class="nav">
                        <li id="liCRM" class="nav-item">
                            <a class="nav-link" data-toggle="tab"
                                [routerLink]="[{ outlets: { 'referralPartner': ['referredClients',this.referralPartnerId] } }]"
                                routerLinkActive="active" (click)="handleTabClick('referredClients')">Referred Clients</a>
                        </li>                        
                    </div>

                </ul>
                <div class="tab-content mt-2">
                    <!-- General Body -->
                    <div class="tab-pane active" id="">
                        <div class="tab-body-wrapper">
                            <router-outlet name="referralPartner"></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>