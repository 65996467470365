import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFourDigitDecimalNumber]'
})
export class FourDigitDecimalNumberDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const re = new RegExp(',', 'g');

    // matching the pattern
    const count = this.el.nativeElement.value.match(re)?.length ? this.el.nativeElement.value.match(re)?.length:0;

    

    let current: string = this.el.nativeElement.value.replace(/,/g, '');
    
    const position = this.el.nativeElement.selectionStart - count;
    
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

}
