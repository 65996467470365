import { Component, ViewChild } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  Username :string ="NaviHedge Admin"
  isMenuOpen :boolean =false;
  isNavOpen :boolean=false;
  @ViewChild(NavigationComponent) childComponent!: NavigationComponent;
  toggleMenu(){
    this.isMenuOpen = !this.isMenuOpen;
  };
  toggleNavigation(){   
    this.isNavOpen = !this.isNavOpen;    
    this.childComponent.toggleNav();
  };
}
