import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  apiUrl=environment.apiUrl;

  constructor(private http:HttpClient) { }

  getSpotRateList(){
    return this.http.get(this.apiUrl+'Dashboard/GetCurrencyPairsForSpotRates');
  }

  getNews(){
    return this.http.get('https://www.dailyfx.com/feeds/market-news', { responseType: 'text' })
  }
}
