import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ClientContactsService } from '../../services/ClientContacts/client-contacts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolePermissonService } from '../../services/Permission/role-permisson.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContactsService } from '../../services/managecontacts/manage-contacts.service';
import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SweetalertService } from '../../services/SweetAlert/sweetalert.service';
import {
  ClientContact_TabID,
  ContactTabConstant,
  ErrorMessages,
  SpinnerMessage,
  SystemConstant,
} from '../../global/global.constant';
import {
  ContactData,
  TabPermission,
  clientContactData,
} from './models/client-contacts.model';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { FieldPermission } from '../client-general/clientGeneral.model';


@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrl: './client-contacts.component.css',
})
export class ClientContactsComponent implements OnInit {
  dataLoaded: boolean = true;
  clientID = 1;
  clientContactData: any[] = [];
  pageSizeOptions = [5, 10, 20];
  pageSizeOfAS = 5;
  pageSizeOfAT = 5;
  pageSizeOfAU = 5;
  pageSizeOfBO = 5;
  pageIndexOfAS = 0;
  pageIndexOfAT = 0;
  pageIndexOfAU = 0;
  pageIndexOfBO = 0;
  lengthOfAS = 0;
  lengthOfAT = 0;
  lengthOfAU = 0;
  lengthOfBO = 0;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginatedDataOfAS: any[] = [];
  paginatedDataOfAT: any[] = [];
  paginatedDataOfAU: any[] = [];
  paginatedDataOfBO: any[] = [];
  pageEvent: PageEvent | undefined;
  disabled = false;
  selectedrowUserName: string = '';
  selectedRow!: ContactData;
  salutationList = [];
  themeList = [];
  roleList = [];
  statusList = [];
  countryList = [];
  editMode = true;
  statusName: string = '';
  roleID = 13;
  modulePermission!: any;
  addButtonHidden = false;
  editButtonhidden = true;
  clientMode: boolean = false;
  statusListForlabel = [];
  authorisedSignatoryList: any[] = [];
  authorisedToTradeList: any[] = [];
  authorisedUserList: any[] = [];
  beneficialOwnerList: any[] = [];
  isShowAuthorisedSignatory: boolean = true;
  isShowAuthorisedToTrade: boolean = true;
  isShowAuthorisedUser: boolean = true;
  isShowBeneficiaryOwner: boolean = true;
  clientContactStatus: string = '';
  loadingMessage = SpinnerMessage.Loading;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pendingAuth!: boolean;
  pendingAuthValue!: string;
  dobValidationTooltip: string = SystemConstant.DOBTitle;
  maxDate: Date = new Date();
  tabPermission: any;
  canAddEdit: boolean = false;
  fieldPermissions: FieldPermission[] = [];
  tabPermissions: TabPermission[] = [];
  tabCanInsert: boolean = false;
  contactTabFP: FieldPermission[] = [];
  contactFieldsViewFlag: boolean[] = [];
  isAscending: boolean = true;
  sortedColumn: string = '';
  pageIndex: number = 0;
  primaryContactFP: boolean = false;
  combineFP: boolean = false;
  contactType: boolean = true;

  constructor(
    private clientContactsService: ClientContactsService,
    private elementRef: ElementRef,
    private contactService: ManageContactsService,
    private fb: FormBuilder,
    private roleService: RolePermissonService,
    private route: ActivatedRoute,
    private alert: SweetalertService,
    private encryptPermission: EncryptRolePermissionService
  ) {
    this.contactFieldsViewFlag = Array(19).fill(false);
  }

  contactForm: FormGroup = this.fb.group({
    contactID: [],
    clientID: [],
    isAuthorisedSignatory: [false],
    isAuthorisedUser: [false],
    isAuthorisedToTrade: [false],
    isBeneficialOwner: [false],
    title: [null, [Validators.required]],
    firstName: ['', [Validators.required]],
    middleName: [''],
    lastName: ['', [Validators.required]],
    alias: [],
    position: [],
    dateOfBirth: [null],
    beneficiaryPercentage: [null],
    address: [],
    emailAddress: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    status: [null],
    userName: [null, [Validators.required]],
    isPrimaryContact: [false],
    clientRoleID: [null, [Validators.required]],
    themeID: [null, [Validators.required]],
    nationalityID: [null],
  });

  ngOnInit(): void {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      const tabPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).tabPermissions;
      this.fieldPermissions =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).fieldPermissions;

      this.tabPermission = tabPermissions.find(
        (x: { tabID: number }) => x.tabID == ClientContact_TabID
      );

      if (
        this.tabPermission &&
        this.tabPermission.canInsert == ContactTabConstant.disable
      ) {
        this.tabCanInsert = false;
        this.contactForm.reset();
        this.contactForm.setErrors({ noPermission: true });
        this.contactForm.disable();
      } else {
        this.tabCanInsert = true;
      }

      this.primaryContactFP = this.fieldPermissions.find(
        (FieldPermission) =>
          FieldPermission.fieldID === ContactTabConstant.primaryContact
      )?.canInsert
        ? true
        : false;
      this.canAddEdit = this.tabPermission.canInsert ? true : false;
      this.editButtonhidden = this.canAddEdit;
      this.combineFP =
        !this.canAddEdit || !this.primaryContactFP ? true : false;

      this.clientMode = false;
    } else if (user === 'Client') {
      this.clientMode = true;
      this.canAddEdit = false;
      this.combineFP = true;
    }

    if (this.clientMode || !this.canAddEdit) {
      this.contactForm.disable();
    }

    this.contactForm
      .get('isBeneficialOwner')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.contactForm
            .get('beneficiaryPercentage')
            ?.setValidators([
              Validators.required,
              Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/),
            ]);
        } else {
          this.contactForm.get('beneficiaryPercentage')?.clearValidators();
        }
        this.contactForm.get('beneficiaryPercentage')?.updateValueAndValidity();
      });

    if (this.clientMode) {
      this.contactForm.disable();
    }
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
    });

    this.getClientContactData();
    this.getPermission();
    this.dataLoaded = false;
    this.lengthOfAS = this.authorisedSignatoryList.length;
    this.lengthOfAT = this.authorisedToTradeList.length;
    this.lengthOfAU = this.authorisedUserList.length;
    this.lengthOfBO = this.beneficialOwnerList.length;
    this.paginatedDataOfAS = this.authorisedSignatoryList.slice(
      0,
      this.pageSizeOfAS
    );
    this.paginatedDataOfAT = this.authorisedToTradeList.slice(
      0,
      this.pageSizeOfAT
    );
    this.paginatedDataOfAU = this.authorisedUserList.slice(
      0,
      this.pageSizeOfAU
    );
    this.paginatedDataOfBO = this.beneficialOwnerList.slice(
      0,
      this.pageSizeOfBO
    );
    this.clientContactsService.getClientContactLists(this.clientID).subscribe({
      next: (data: any) => {
        this.salutationList = data.salutation;
        this.roleList = data.role;
        this.statusList = data.status;
        this.statusListForlabel = data.status;
        this.themeList = data.theme;
        this.countryList = data.country;
        this.dataLoaded = true;
      },
    });
  }

  getrolePermission(): void {
    if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
      if (this.encryptPermission.getDecryptedPermissions('roleNgp')) {
        this.fieldPermissions =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).fieldPermissions;
        this.tabPermissions =
          this.encryptPermission.getDecryptedPermissions(
            'roleNgp'
          ).tabPermissions;
        this.setRolePermission();
      }
    }
  }

  setRolePermission() {
    let permission = this.tabPermissions.find(
      (tab: TabPermission) => tab.tabID == ContactTabConstant.tabId
    );
    if (permission && permission.canInsert == ContactTabConstant.disable) {
      this.tabCanInsert = false;
      this.contactForm.reset();
      this.contactForm.setErrors({ noPermission: true });
      this.contactForm.disable();
    } else {
      this.tabCanInsert = true;
    }
    this.contactTabFP = this.fieldPermissions.filter(
      (item: { fieldID: number }) =>
        item.fieldID >= ContactTabConstant.contactRoleID &&
        item.fieldID <= ContactTabConstant.contactType
    );

    this.contactTabFP.forEach(
      (field: { fieldID: number; canView: number; canInsert: number }) => {
        switch (field.fieldID) {
          case ContactTabConstant.contactRoleID:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[0] = true)
              : (this.contactFieldsViewFlag[0] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['clientRoleID'].disable();
            }
            break;

          case ContactTabConstant.contactAddress:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[1] = true)
              : (this.contactFieldsViewFlag[1] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['address'].disable();
            }
            break;

          case ContactTabConstant.contactAlias:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[2] = true)
              : (this.contactFieldsViewFlag[2] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['alias'].disable();
            }
            break;

          case ContactTabConstant.contactDOB:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[3] = true)
              : (this.contactFieldsViewFlag[3] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['dateOfBirth'].disable();
            }
            break;

          case ContactTabConstant.contactEmail:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[4] = true)
              : (this.contactFieldsViewFlag[4] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['emailAddress'].disable();
            }
            break;

          case ContactTabConstant.contactFirstName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[5] = true)
              : (this.contactFieldsViewFlag[5] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['firstName'].disable();
            }
            break;

          case ContactTabConstant.contactLastName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[6] = true)
              : (this.contactFieldsViewFlag[6] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['lastName'].disable();
            }
            break;

          case ContactTabConstant.contactMiddleName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[7] = true)
              : (this.contactFieldsViewFlag[7] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['middleName'].disable();
            }
            break;

          case ContactTabConstant.contactNationality:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[8] = true)
              : (this.contactFieldsViewFlag[8] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['nationalityID'].disable();
            }
            break;

          case ContactTabConstant.contactPhone:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[9] = true)
              : (this.contactFieldsViewFlag[9] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['phoneNumber'].disable();
            }
            break;

          case ContactTabConstant.contactPosition:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[10] = true)
              : (this.contactFieldsViewFlag[10] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['position'].disable();
            }
            break;

          case ContactTabConstant.contactStatusID:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[12] = true)
              : (this.contactFieldsViewFlag[12] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['status'].disable();
            }
            break;

          case ContactTabConstant.contactTheme:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[13] = true)
              : (this.contactFieldsViewFlag[13] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['themeID'].disable();
            }
            break;

          case ContactTabConstant.contactTitle:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[15] = true)
              : (this.contactFieldsViewFlag[15] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['title'].disable();
            }
            break;
          case ContactTabConstant.primaryContact:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[17] = true)
              : (this.contactFieldsViewFlag[17] = false);

            if (field.canInsert == ContactTabConstant.disable) {
              this.primaryContactFP = false;
              this.contactForm.controls['isPrimaryContact'].disable();
            } else {
              this.primaryContactFP = true;
            }
            break;

          case ContactTabConstant.contactUserName:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[16] = true)
              : (this.contactFieldsViewFlag[16] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactForm.controls['userName'].disable();
            }
            break;
          case ContactTabConstant.contactType:
            field.canView == ContactTabConstant.canView
              ? (this.contactFieldsViewFlag[18] = true)
              : (this.contactFieldsViewFlag[18] = false);
            if (field.canInsert == ContactTabConstant.disable) {
              this.contactType = false;

              this.contactForm.get('isAuthorisedUser')?.disable();
              this.contactForm.get('isAuthorisedSignatory')?.disable();
              this.contactForm.get('isBeneficialOwner')?.disable();
              this.contactForm.get('isAuthorisedToTrade')?.disable();
              this.contactForm.get('beneficiaryPercentage')?.disable();
            } else {
              this.contactType = true;
            }
            break;
        }
      }
    );
  }

  checkRoleOfContact() {
    if (this.contactType) {
      if (this.tabCanInsert) {
        let firstTimeFlag: boolean = true;
        let isAuthorisedUser: boolean = this.contactForm
          .get('isAuthorisedUser')
          ?.getRawValue()
          ? this.contactForm.get('isAuthorisedUser')?.getRawValue()
          : false;
        let isAuthorisedSignatory: boolean = this.contactForm
          .get('isAuthorisedSignatory')
          ?.getRawValue()
          ? this.contactForm.get('isAuthorisedSignatory')?.getRawValue()
          : false;
        let isBeneficialOwner: boolean = this.contactForm
          .get('isBeneficialOwner')
          ?.getRawValue()
          ? this.contactForm.get('isBeneficialOwner')?.getRawValue()
          : false;
        let isAuthorisedToTrade: boolean = this.contactForm
          .get('isAuthorisedToTrade')
          ?.getRawValue()
          ? this.contactForm.get('isAuthorisedToTrade')?.getRawValue()
          : false;
        if (!isBeneficialOwner) {
          this.contactForm.get('beneficiaryPercentage')?.reset();
        }
        if (
          !isAuthorisedSignatory &&
          !isAuthorisedUser &&
          !isAuthorisedToTrade &&
          !isBeneficialOwner
        ) {
          this.contactForm.get('isAuthorisedUser')?.enable();
          this.contactForm.get('isAuthorisedSignatory')?.enable();
          this.contactForm.get('isBeneficialOwner')?.enable();
          this.contactForm.get('isAuthorisedToTrade')?.enable();
          firstTimeFlag = true;
        } else {
          if (isAuthorisedUser && firstTimeFlag) {
            this.contactForm.get('isAuthorisedUser')?.enable();
            this.contactForm.get('isAuthorisedToTrade')?.enable();
            this.contactForm.get('isAuthorisedSignatory')?.reset();
            this.contactForm.get('isBeneficialOwner')?.reset();
            this.contactForm.get('isAuthorisedSignatory')?.disable();
            this.contactForm.get('isBeneficialOwner')?.disable();
            firstTimeFlag = false;
          }
          if (isAuthorisedSignatory && firstTimeFlag) {
            this.contactForm.get('isAuthorisedSignatory')?.enable();
            this.contactForm.get('isBeneficialOwner')?.enable();
            this.contactForm.get('isAuthorisedToTrade')?.enable();
            this.contactForm.get('isAuthorisedUser')?.reset();
            this.contactForm.get('isAuthorisedUser')?.disable();
            firstTimeFlag = false;
          }
          if (isBeneficialOwner && firstTimeFlag) {
            this.contactForm.get('isAuthorisedSignatory')?.enable();
            this.contactForm.get('isBeneficialOwner')?.enable();
            this.contactForm.get('isAuthorisedToTrade')?.reset();
            this.contactForm.get('isAuthorisedToTrade')?.disable();
            this.contactForm.get('isAuthorisedUser')?.reset();
            this.contactForm.get('isAuthorisedUser')?.disable();
            firstTimeFlag = false;
          }
          if (isAuthorisedToTrade && firstTimeFlag) {
            this.contactForm.get('isAuthorisedUser')?.enable();
            this.contactForm.get('isAuthorisedToTrade')?.enable();
            this.contactForm.get('isAuthorisedSignatory')?.reset();
            this.contactForm.get('isAuthorisedSignatory')?.disable();
            this.contactForm.get('isBeneficialOwner')?.reset();
            this.contactForm.get('isBeneficialOwner')?.disable();
            firstTimeFlag = false;
          }
        }
      } else {
        this.contactForm.get('isAuthorisedUser')?.disable();
        this.contactForm.get('isAuthorisedSignatory')?.disable();
        this.contactForm.get('isBeneficialOwner')?.disable();
        this.contactForm.get('isAuthorisedToTrade')?.disable();
      }
    } else {
      this.contactForm.get('isAuthorisedUser')?.disable();
      this.contactForm.get('isAuthorisedSignatory')?.disable();
      this.contactForm.get('isBeneficialOwner')?.disable();
      this.contactForm.get('isAuthorisedToTrade')?.disable();
      this.contactForm.get('beneficiaryPercentage')?.disable();
    }
  }

  getToday(): string {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today.toISOString().split('T')[0];
  }

  disableManualInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  showhide = (showHide: string) => {
    if (showHide === 'Authorised Signatory') {
      this.isShowAuthorisedSignatory = !this.isShowAuthorisedSignatory;
    } else if (showHide === 'Authorised To Trade') {
      this.isShowAuthorisedToTrade = !this.isShowAuthorisedToTrade;
    } else if (showHide === 'Authorised User') {
      this.isShowAuthorisedUser = !this.isShowAuthorisedUser;
    } else if (showHide === 'Beneficial Owner') {
      this.isShowBeneficiaryOwner = !this.isShowBeneficiaryOwner;
    }
  };

  sortTable(columnName: string) {
    this.dataLoaded = false;
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.authorisedSignatoryList.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    this.authorisedToTradeList.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    this.authorisedUserList.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    this.beneficialOwnerList.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSizeOfAS;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSizeOfAS;
      endIndex = startIndex + this.pageSizeOfAS;
    }
    this.paginatedDataOfAS = this.authorisedSignatoryList.slice(
      startIndex,
      endIndex
    );
    this.paginatedDataOfAU = this.authorisedUserList.slice(
      startIndex,
      endIndex
    );
    this.paginatedDataOfBO = this.beneficialOwnerList.slice(
      startIndex,
      endIndex
    );
    this.paginatedDataOfAT = this.authorisedToTradeList.slice(
      startIndex,
      endIndex
    );
    this.dataLoaded = true;
  }
  getPermission(): void {
    this.roleService.getRolePermissionData(this.roleID).subscribe({
      next: (data: any) => {
        this.modulePermission = data.modulePermissions;
        if (this.modulePermission) {
          var permission = this.modulePermission[3].canInsert;
          this.addButtonHidden = permission ? false : true;

          var permission = this.modulePermission[3].canUpdate;
        }
      },
      error: () => {},
    });
  }

  checkDOB() {
    const control = this.contactForm.controls['dateOfBirth'];
    if (control.touched && control.invalid) {
      if (control.errors?.['matDatepickerParse']) {
        this.dobValidationTooltip = ErrorMessages.Valid_Date;
      } else if (control.errors?.['matDatepickerMax']) {
        this.dobValidationTooltip = ErrorMessages.Future_Date;
      } else {
        this.contactForm.get('dateOfBirth')?.setErrors(null);
        this.dobValidationTooltip = SystemConstant.DOBTitle;
      }
    }
  }

  getClientContactData() {
    this.dataLoaded = false;

    this.clientContactsService.getClientContacts(this.clientID).subscribe({
      next: (result: any) => {
        this.authorisedSignatoryList = result.authorisedSignatory;
        this.authorisedToTradeList = result.authorisedToTrade;
        this.authorisedUserList = result.authorisedUser;
        this.beneficialOwnerList = result.beneficialOwner;
        this.clientContactData = result;
        this.lengthOfAS = this.authorisedSignatoryList.length;
        this.lengthOfAT = this.authorisedToTradeList.length;
        this.lengthOfAU = this.authorisedUserList.length;
        this.lengthOfBO = this.beneficialOwnerList.length;
        this.paginatedDataOfAS = this.authorisedSignatoryList.slice(
          0,
          this.pageSizeOfAS
        );
        this.paginatedDataOfAT = this.authorisedToTradeList.slice(
          0,
          this.pageSizeOfAT
        );
        this.paginatedDataOfAU = this.authorisedUserList.slice(
          0,
          this.pageSizeOfAU
        );
        this.paginatedDataOfBO = this.beneficialOwnerList.slice(
          0,
          this.pageSizeOfBO
        );
        this.dataLoaded = true;
      },

      error: () => {
        this.dataLoaded = true;
      },
    });
    this.dataLoaded = true;
  }

  handlePageEventOfAS(e: PageEvent) {
    this.pageEvent = e;
    this.pageSizeOfAS = e.pageSize;
    this.pageIndexOfAS = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSizeOfAS;
    if (this.pageIndexOfAS != 0) {
      startIndex = this.pageIndexOfAS * this.pageSizeOfAS;
      endIndex = startIndex + this.pageSizeOfAS;
    }
    this.paginatedDataOfAS = this.authorisedSignatoryList.slice(
      startIndex,
      endIndex
    );
  }

  handlePageEventOfAT(e: PageEvent) {
    this.pageEvent = e;
    this.pageSizeOfAT = e.pageSize;
    this.pageIndexOfAT = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSizeOfAT;
    if (this.pageIndexOfAT != 0) {
      startIndex = this.pageIndexOfAT * this.pageSizeOfAT;
      endIndex = startIndex + this.pageSizeOfAT;
    }
    this.paginatedDataOfAT = this.authorisedSignatoryList.slice(
      startIndex,
      endIndex
    );
  }

  handlePageEventOfAU(e: PageEvent) {
    this.pageEvent = e;
    this.pageSizeOfAU = e.pageSize;
    this.pageIndexOfAU = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSizeOfAU;
    if (this.pageIndexOfAU != 0) {
      startIndex = this.pageIndexOfAU * this.pageSizeOfAU;
      endIndex = startIndex + this.pageSizeOfAU;
    }
    this.paginatedDataOfAU = this.authorisedSignatoryList.slice(
      startIndex,
      endIndex
    );
  }

  handlePageEventOfBO(e: PageEvent) {
    this.pageEvent = e;
    this.pageSizeOfBO = e.pageSize;
    this.pageIndexOfBO = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSizeOfBO;
    if (this.pageIndexOfBO != 0) {
      startIndex = this.pageIndexOfBO * this.pageSizeOfBO;
      endIndex = startIndex + this.pageSizeOfBO;
    }
    this.paginatedDataOfBO = this.authorisedSignatoryList.slice(
      startIndex,
      endIndex
    );
  }

  private enableFormControls(): void {
    this.contactForm.get('userName')?.enable();
    this.contactForm.get('status')?.enable();
    this.contactForm.get('isPrimaryContact')?.enable();
    this.contactForm.get('emailAddress')?.enable();
    this.contactForm.get('phoneNumber')?.enable();
    this.contactForm.get('clientRoleID')?.enable();
    this.contactForm.get('nationalityID')?.enable();
    this.contactForm.get('themeID')?.enable();
    this.contactForm.get('position')?.enable();
    this.contactForm.get('middleName')?.enable();
    this.contactForm.get('address')?.enable();
    this.contactForm.get('beneficiaryPercentage')?.enable();
    this.contactForm.get('dateOfBirth')?.enable();
  }

  openModal(row?: any): void {
    this.selectedRow = row;
    if (!this.selectedRow) {
      this.editMode = false;
      this.contactFieldsViewFlag = Array(19).fill(true);
      this.contactForm.enable();
      this.contactForm.reset();
      this.contactType = true;
      this.contactForm.get('userName')?.enable();
      this.contactForm.patchValue({
        status: this.statusList.find(
          (obj: any) => obj.contactStatusId == 'PendingAuthorization'
        ),
        isPrimaryContact: false,
        isAuthorisedSignatory: false,
        isAuthorisedToTrade: false,
        isAuthorisedUser: false,
        isBeneficialOwner: false,
      });
      this.contactForm.get('status')?.disable();
    } else {
      this.editMode = true;
      this.contactType = this.fieldPermissions.find(
        (field) => field.fieldID == ContactTabConstant.contactType
      )?.canInsert
        ? true
        : false;
      if (!this.contactType) {
        this.contactForm.get('isAuthorisedUser')?.disable();
        this.contactForm.get('isAuthorisedSignatory')?.disable();
        this.contactForm.get('isBeneficialOwner')?.disable();
        this.contactForm.get('isAuthorisedToTrade')?.disable();
        this.contactForm.get('beneficiaryPercentage')?.disable();
      }
      if (this.clientMode) {
        this.contactForm.disable();
        this.contactFieldsViewFlag = Array(19).fill(true);
      } else {
        this.contactForm.enable();
        this.contactFieldsViewFlag = Array(19).fill(false);
        this.getrolePermission();
      }
      this.dataLoaded = false;
      this.clientContactsService.getClientContact(row.contactID).subscribe({
        next: (response: clientContactData) => {
          this.contactForm.patchValue(response);
          this.clientContactStatus = response.status;
          const statusValue = this.clientContactStatus;
          if (statusValue == 'PendingAuthorization') {
            this.pendingAuth = true;
            this.pendingAuthValue = 'Pending Authorization';
          } else if (statusValue == 'PendingEmailVerification') {
            this.pendingAuth = true;
            this.pendingAuthValue = 'Pending Email Verification';
          } else {
            this.pendingAuth = false;
          }

          this.dataLoaded = true;
          if (!this.clientMode) this.checkRoleOfContact();
        },
        error: () => {
          this.dataLoaded = true;
        },
      });

      this.statusList = this.statusList.filter(
        (obj: any) =>
          obj.contactStatusId != 'PendingAuthorization' &&
          obj.contactStatusId != 'PendingEmailVerification' &&
          obj.contactStatusId != 'All'
      );
    }

    const modal = this.elementRef.nativeElement.querySelector(
      '#updateClientContact-modal'
    );
    const backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');

    if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.appendChild(backdrop);
      modal.focus();
    }
  }

  close() {
    this.loadingMessage = 'Loading';
    const modal = this.elementRef.nativeElement.querySelector(
      '#updateClientContact-modal'
    );

    modal.classList.remove('show');
    modal.style.display = 'none';
    this.contactForm.get('isAuthorisedUser')?.enable();
    this.contactForm.get('isAuthorisedToTrade')?.enable();
    this.contactForm.get('isAuthorisedSignatory')?.enable();
    this.contactForm.get('isBeneficialOwner')?.enable();
    this.contactForm.get('beneficiaryPercentage')?.clearValidators();
    this.contactForm.get('beneficiaryPercentage')?.updateValueAndValidity();
    this.getClientContactData();
  }

  getstatus(id: number): string {
    const statusLable = (this.statusListForlabel as any).find(
      (status: any) => status.contactStatusId === id
    );
    return statusLable ? statusLable.contactStatusValue : undefined;
  }

  statusClass(row: any): string {
    var statusClass: string = '';
    switch (row.status) {
      case 'Inactive': {
        statusClass = 'badge-primary';
        this.statusName = 'Inactive';
        break;
      }
      case 'Active': {
        statusClass = 'badge-success';
        this.statusName = 'Active';
        break;
      }
      case 'Locked': {
        statusClass = 'badge-warning';
        this.statusName = 'Locked';
        break;
      }
      case 'PendingAuthorization': {
        statusClass = 'badge-secondary';
        this.statusName = 'Pending Authorization';
        break;
      }
      case 'blocked': {
        statusClass = 'badge-danger';
        this.statusName = 'blocked';
        break;
      }
    }
    return statusClass;
  }

  toggleSwitch(contact: any) {
    this.dataLoaded = false;
    contact.isPrimaryContact = !contact.isPrimaryContact;
    this.contactService
      .setPrimaryContactFlag(contact.contactID, contact.isPrimaryContact)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          this.getClientContactData();
          this.dataLoaded = true;
        },
        error: () => {
          this.dataLoaded = true;
        },
      });
  }
  convert(str: string) {
    var date = new Date(str),
      month = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  onSave() {
    if (this.contactForm.valid) {
      let isAuthorisedUser: boolean = this.contactForm.get('isAuthorisedUser')
        ?.value
        ? this.contactForm.get('isAuthorisedUser')?.value
        : false;
      let isAuthorisedSignatory: boolean = this.contactForm.get(
        'isAuthorisedSignatory'
      )?.value
        ? this.contactForm.get('isAuthorisedSignatory')?.value
        : false;
      let isBeneficialOwner: boolean = this.contactForm.get('isBeneficialOwner')
        ?.value
        ? this.contactForm.get('isBeneficialOwner')?.value
        : false;
      let isAuthorisedToTrade: boolean = this.contactForm.get(
        'isAuthorisedToTrade'
      )?.value
        ? this.contactForm.get('isAuthorisedToTrade')?.value
        : false;
      if (
        !isAuthorisedSignatory &&
        !isAuthorisedUser &&
        !isAuthorisedToTrade &&
        !isBeneficialOwner
      ) {
        Swal.fire({
          icon: 'warning',
          title: ErrorMessages.Select_role,
          confirmButtonText: 'OK',
          showConfirmButton: true,
        });
      } else {
        var formValue = this.contactForm.getRawValue();
        let dateOFBirth: Date | null = new Date();
        if (formValue.dateOfBirth) {
          if (formValue.dateOfBirth._d) {
            dateOFBirth = new Date(this.convert(formValue.dateOfBirth._d));
          } else {
            dateOFBirth = formValue.dateOfBirth;
          }
        } else {
          dateOFBirth = null;
        }
        var payload: any = {
          clientID: this.clientID,
          isAuthorisedSignatory: formValue.isAuthorisedSignatory
            ? formValue.isAuthorisedSignatory
            : false,
          isAuthorisedUser: formValue.isAuthorisedUser
            ? formValue.isAuthorisedUser
            : false,
          isAuthorisedToTrade: formValue.isAuthorisedToTrade
            ? formValue.isAuthorisedToTrade
            : false,
          isBeneficialOwner: formValue.isBeneficialOwner
            ? formValue.isBeneficialOwner
            : false,
          title: formValue.title.salutaionName
            ? formValue.title.salutaionName
            : formValue.title,
          firstName: formValue.firstName,
          middleName: formValue.middleName,
          lastName: formValue.lastName,
          alias: formValue.alias,
          position: formValue.position,
          nationalityID: formValue.nationalityID,
          address: formValue.address,
          emailAddress: formValue.emailAddress,
          phoneNumber: formValue.phoneNumber,
          userName: formValue.userName
            ? formValue.userName
            : this.selectedRow.userName,
          isPrimaryContact: formValue.isPrimaryContact,
          clientRoleID: formValue.clientRoleID,
          themeID: formValue.themeID,
          dateOfBirth: dateOFBirth,
          beneficiaryPercentage:
            formValue.beneficiaryPercentage == ''
              ? null
              : formValue.beneficiaryPercentage,
        };

        if (this.editMode) {
          this.loadingMessage = 'Updating';
          this.dataLoaded = false;

          payload.status = formValue.status;
          payload.contactID = this.selectedRow.contactID;
          this.clientContactsService.updateClientContact(payload).subscribe({
            next: () => {
              this.dataLoaded = true;
              this.alert.fireAlert('Updated').then((_) => {
                this.close();
              });
            },
            error: (response) => {
              this.dataLoaded = true;
              this.displayBackEndErrorMessage(response);
            },
          });
        } else {
          this.loadingMessage = 'Saving';
          this.dataLoaded = false;
          this.clientContactsService.addClientContact(payload).subscribe({
            next: (result) => {
              this.dataLoaded = true;
              this.alert.fireAlert('Saved').then((_) => {
                this.close();
              });
            },
            error: (response) => {
              this.dataLoaded = true;
              this.displayBackEndErrorMessage(response);
            },
          });
        }
      }
    } else {
      this.markFormGroupTouched(this.contactForm);
    }
  }

  private markFormGroupTouched = (formGroup: FormGroup) => {
    Swal.fire({
      icon: 'warning',
      title: ErrorMessages.Please_fill_required_inputs,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  };

  private displayBackEndErrorMessage = (response: any): void => {
    if (response && response.error && response.error.errors) {
      if (response.error.errors.FirstName) {
        this.showMessage(response.error.errors.FirstName[0]);
      } else if (response.error.errors.LastName) {
        this.showMessage(response.error.errors.LastName[0]);
      } else if (response.error.errors.PhoneNumber) {
        this.showMessage(response.error.errors.PhoneNumber[0]);
      } else if (response.error.errors.EmailAddress) {
        this.showMessage(response.error.errors.EmailAddress[0]);
      } else if (response.error.errors.UserName) {
        this.showMessage(response.error.errors.UserName[0]);
      } else {
        this.showMessage(
          ErrorMessages.Error_occurred_while_processing_your_request
        );
      }
    } else if (response && response.error && response.error[0].description) {
      this.showMessage(response.error[0].description);
    }
  };

  private showMessage = (message: string): void => {
    Swal.fire({
      icon: 'warning',
      title: message,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
  };
}
