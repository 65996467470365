<div *ngIf="!dataLoaded">
  <div class="loader-block">
    <div class="inner-loader">{{ loadingMessage }}...</div>
  </div>
</div>

<div class="compress-inputs-page">
  <form class="form" [formGroup]="addressForm" (ngSubmit)="onSaveOrUpdate()">
    <div class="address-block">
      <div class="row">
        <div class="col-sm-12">
          <h6>Registered Address</h6>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Street Address <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[0] != '' }"
              title="{{ errorArray[0] }}"
            >
              <input
                type="text"
                id="txtClientStreetAddress"
                maxlength="255"
                class="form-control"
                placeholder="Address"
                formControlName="regStreetAddress"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Suburb <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[1] != '' }"
              title="{{ errorArray[1] }}"
            >
              <input
                type="text"
                id="txtClientSuburb"
                maxlength="255"
                class="form-control"
                placeholder="Suburb"
                formControlName="regSuburb"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>State <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[2] != '' }"
              title="{{ errorArray[2] }}"
            >
              <input
                type="text"
                id="txtClientState"
                maxlength="50"
                class="form-control"
                placeholder="State"
                formControlName="regState"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Postcode <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[3] != '' }"
              title="{{ errorArray[3] }}"
            >
              <input
                type="text"
                id="txtClientPostcode"
                maxlength="50"
                class="form-control"
                placeholder="Postcode"
                formControlName="regPostCode"
                (input)="errorChecking()"
                (blur)="errorChecking()"
                maxlength="10"
                
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Country <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[4] != '' }"
              title="{{ errorArray[4] }}"
            >
              <ng-select
                [items]="countryList"
                [closeOnSelect]="true"
                [searchable]="true"
                [multiple]="false"
                bindLabel="countryName"
                bindValue="countryID"
                placeholder="Select"
                style="width: 100%"
                class="errorSelect"
                formControlName="regCountryID"
                (blur)="errorChecking()"
                (change)="errorChecking()"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="address-block">
      <div class="row">
        <div class="col-sm-12">
          <h6>Principal Place of Business</h6>

          <div class="form-group label-inline">
            <label>Same as Registered Address </label>
            <div class="client custom-switch">
              <input
                type="checkbox"
                formControlName="sameAsRegAddress"
                class="switch-control-input"
                (change)="toggleSwitch($event)"
              />
              <span class="switch-control-label"></span>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Street Address <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[5] != '' }"
              title="{{ errorArray[5] }}"
            >
              <input
                type="text"
                id="txtBusinessStreetAddress"
                maxlength="255"
                class="form-control"
                placeholder="Address"
                formControlName="ppbStreetAddress"
                [(ngModel)]="ppbStreetAddress"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Suburb <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[6] != '' }"
              title="{{ errorArray[6] }}"
            >
              <input
                type="text"
                id="txtBusinessSuburb"
                maxlength="255"
                class="form-control"
                placeholder="Suburb"
                formControlName="ppbSuburb"
                [(ngModel)]="ppbSuburb"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>State <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[7] != '' }"
              title="{{ errorArray[7] }}"
            >
              <input
                type="text"
                id="txtBusinessState"
                maxlength="50"
                class="form-control"
                placeholder="State"
                formControlName="ppbState"
                [(ngModel)]="ppbState"
                (input)="errorChecking()"
                (blur)="errorChecking()"
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Postcode <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[8] != '' }"
              title="{{ errorArray[8] }}"
            >
              <input
                type="text"
                id="txtBusinessPostcode"
                maxlength="50"
                class="form-control"
                placeholder="Postcode"
                formControlName="ppbPostCode"
                [(ngModel)]="ppbPostCode"                
                (input)="errorChecking()"
                (blur)="errorChecking()"
                maxlength="10"
                
              />
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group label-inline">
            <label>Country <em style="color: red">*</em></label>
            <div
              class="showhide"
              [ngClass]="{ 'newTooltip expand has-error': errorArray[9] != '' }"
              title="{{ errorArray[9] }}"
            >
              <ng-select
                [items]="countryList"
                [closeOnSelect]="true"
                [searchable]="true"
                [multiple]="false"
                bindLabel="countryName"
                bindValue="countryID"
                placeholder="Select"
                style="width: 100%"
                class="errorSelect"
                formControlName="ppbCountryID"
                [(ngModel)]="ppbCountryID"
                (blur)="errorChecking()"
                (change)="errorChecking()"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inline-element d-block text-center">
      <button class="btn btn-success" type="submit" *ngIf="!clientMode && canAddEdit">
        Save
      </button>
      <a
        *ngIf="!clientMode && canAddEdit"
        (click)="cancel()"
        class="btn btn-secondary"
        data-dismiss="modal"
        id="aCancelClientAddress"
        >Cancel
      </a>
    </div>
  </form>
</div>
