<!-- <app-header></app-header> -->

<div class="loader-block" *ngIf="!dataLoaded">
    <div class="inner-loader">{{loadingMessage}}</div>
</div>
<div class="">
    <div class="compress-inputs-page">
        <div class="inner add-user">
            <div class="title">
                <h3>&nbsp;</h3>
                <a class="btn btn-outline-secondary btn-sp" (click)="back()">
                    <em class="arrow-back"><img src="../../../assets/left-arrow.svg" alt=""></em>
                    Back
                </a>
            </div>

            <div class="card secondary">
                <form [formGroup]="userForm" (ngSubmit)="addUpdateUser()">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['firstName'].touched) && userForm.controls['firstName'].invalid }"
                                datatitle="First Name is Required">
                                <label class="strong">First Name <em style="color:red;">*</em></label>
                                <input class="form-control" formControlName="firstName" maxlength="75">

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['lastName'].touched) && userForm.controls['lastName'].invalid }"
                                datatitle="Last Name is Required">
                                <label class="strong">Last Name <em style="color:red;">*</em></label>
                                <input class="form-control" formControlName="lastName" maxlength="75">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['phoneNumber'].touched) && userForm.controls['phoneNumber'].invalid }"
                                datatitle="Phone is Required">
                                <label class="strong">Phone <em style="color:red;">*</em></label>
                                <input class="form-control" formControlName="phoneNumber" maxlength="75">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['emailAddress'].touched) && userForm.controls['emailAddress'].invalid }"
                                datatitle="Email is Required">
                                <label class="strong">Email <em style="color:red;">*</em></label>
                                <input class="form-control" formControlName="emailAddress" maxlength="255">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['userName'].touched) && userForm.controls['userName'].invalid }"
                                datatitle="Username is Required">
                                <label class="strong">Username<em style="color:red;">*</em></label>
                                <div *ngIf="!isUserNameDisable" class="dropdown">
                                    <input class="form-control" formControlName="userName" maxlength="75">
                                </div>
                                <div *ngIf="isUserNameDisable" class="dropdown">
                                    <input formControlName="userName" class="form-control dropdown" type="text"
                                        readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['roleID'].touched) && userForm.controls['roleID'].invalid }"
                                datatitle="Role is Required">
                                <label class="strong">Role <em style="color:red;">*</em></label>
                                <ng-select [items]="roles" [closeOnSelect]="true" bindLabel="roleName"
                                    bindValue="roleID" [searchable]="true" formControlName="roleID"
                                    class="dropdown"></ng-select>
                            </div>
                        </div>

                        <div class="col-sm-6" >
                            <div class="form-group label-inline">
                                <label class="strong">Status</label>
                                <ng-select *ngIf="isUpdateMode && !pendingAuth" [items]="status" [closeOnSelect]="true" bindLabel="statusName"
                                    bindValue="statusID" [searchable]="true" formControlName="status"
                                    class="dropdown"></ng-select>
                                <input *ngIf="pendingAuth" type="text" [value]="pendingAuthValue" class="form-control"
                                    readonly>

                                <input *ngIf="!isUpdateMode" type="text" value="Pending Authorization"
                                    class="form-control" readonly>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline"
                                [ngClass]="{'newTooltip expand has-error': (userForm.controls['themeID'].touched) && userForm.controls['themeID'].invalid }"
                                datatitle="Theme is Required">
                                <label class="strong">Theme <em style="color:red;">*</em></label>
                                <ng-select [items]="theme" [closeOnSelect]="true" bindLabel="themeName"
                                    bindValue="themeID" [searchable]="true" formControlName="themeID" class="dropdown"
                                    style="color: black;"></ng-select>
                            </div>
                        </div>
                        <div class="col-sm-6"
                            [ngClass]="{'newTooltip expand has-error': (userForm.controls['userType'].touched) && userForm.controls['userType'].invalid }"
                            datatitle="Type is Required">
                            <div class="form-group label-inline">
                                <label class="strong">Type <em style="color:red;">*</em></label>
                                <ng-select [items]="types" (blur)="disableInput()" [closeOnSelect]="true"
                                    bindLabel="userTypeName" bindValue="userTypeID" [searchable]="true"
                                    formControlName="userType" class="dropdown"></ng-select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group label-inline">
                                <label class="strong">Tenant</label>
                                <div *ngIf="!isTenantDisable" class="dropdown">
                                    <ng-select [items]="tenantList" [closeOnSelect]="true" bindLabel="tenantName"
                                        bindValue="tenantID" [searchable]="true" formControlName="tenantID"
                                        class="dropdown"></ng-select>
                                </div>
                                <div *ngIf="isTenantDisable" class="dropdown">
                                    <input formControlName="tenantID" class="form-control dropdown" type="text"
                                        readonly>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group label-inline">
                                <label class="strong">Location</label>
                                <ng-select [items]="locations" [closeOnSelect]="true" bindLabel="locationName"
                                    bindValue="locationID" [searchable]="true" formControlName="locationID"
                                    class="dropdown"></ng-select>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group label-inline">
                                <label class="strong">Manager</label>
                                <ng-select [items]="managers" [closeOnSelect]="true" bindLabel="managerName"
                                    bindValue="managerID" [searchable]="true" formControlName="managerID"
                                    class="dropdown"></ng-select>
                            </div>
                        </div>


                        <div class="col-sm-6">
                            <div class="form-group label-inline">
                                <label class="strong">CAR Partner</label>
                                <div *ngIf="!isCarPartnerDisable" class="dropdown">
                                    <ng-select [items]="carPartnerList" [closeOnSelect]="true"
                                        bindLabel="carPartnerName" bindValue="carPartnerID" [searchable]="true"
                                        formControlName="carPartnerID" class="dropdown"></ng-select>
                                </div>
                                <div *ngIf="isCarPartnerDisable" class="dropdown">
                                    <input formControlName="carPartnerID" class="dropdown form-control " type="text"
                                        readonly>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="inline-element d-block text-center">
                                <button type="submit" class="btn btn-success">Save</button>
                                <a (click)="reset()" id="aCancelDetails" class="btn btn-secondary">Cancel</a>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>