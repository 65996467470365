import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNineDigit]',
})
export class NineDigitDirective {
  private regex: RegExp = new RegExp(/^\d{3} \d{3} \d{3}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {    
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === ' ' ? '' : event.key,
      current.slice(position),
    ].join('');

    if (
      !String(next)
        .replace(/\s/g, '')
        .match(/^\d{0,9}$/)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    let value = this.el.nativeElement.value;
    value = value
      .replace(/\s/g, '')
      .replace(/(\d{3})(?=\d)/g, '$1 ')
      .trim();
    this.el.nativeElement.value = value;
  }
}
