<div class="card-wrapper">
    <div class="card">
        <div class="title">
            <h4>Rate Charts</h4>
            <div class="right-card-title">
                <ng-select [items]="SpotRateShowList" [clearable]="false" [closeOnSelect]="true"
                    bindLabel="currencyPair" (change)="onCurrencyPairSelect($event)"
                    [(ngModel)]="selectedSymbol"></ng-select>
                <a *ngIf="!maximize" (click)="sizeChange()" title="Maximize"
                    class="btn btn-secondary icon-btn expandBtn" id="aMaximize">
                    <img src='../../../../../assets/maximize.svg' class="light-mode" alt="">

                </a>
                <a *ngIf="maximize" (click)="sizeChange()" title="Minimize" class="btn btn-secondary icon-btn expandBtn"
                    id="aMinimize">
                    <img src='../../../../../assets/restore.svg' class="light-mode" alt="">

                </a>
                <!-- <a id="btnExpand" href="#" title="Maximize" class="btn btn-secondary icon-btn"><img id="imgMaximie" src='@(ConfigUtility.SiteUrl + "/Content/Client/images/maximize.svg")' alt=""></a>*@ -->
            </div>
        </div>


        <!-- TradingView Widget BEGIN -->
        <div class="tradingview-widget-container tradingview-widget-light">
            <div id="tradingview_702f2_light"></div>
            <div class="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/symbols/{{selectedSymbol}}?exchange=FX" rel="noopener"
                    target="_blank"><span class="blue-text">{{selectedSymbol}} Chart</span></a> by TradingView
            </div>

        </div>


        <!-- TradingView Widget END -->

    </div>
</div>