import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OpenDealSFService {
  apiUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }
  
  getTenantCarList():Observable<any>{
    let url=this.apiUrl+'Common/TenantCarList?includeInactive=true';
    return this.http.get(url);
  }
  getClientList(tenantId :number):Observable<any>{
    let url=this.apiUrl+'Common/ClientList?tenantCar='+tenantId+'&includeInactive=false';
    return this.http.get(url);
  }
  getCreatedByList(clientId :number):Observable<any>{
    let url=this.apiUrl+'Common/CreatedByList?clientID='+clientId+'&includeInactive=false';
    return this.http.get(url);
  }
  search(tenantCar:string,clientID:number,createdby:number,pagenumber:number,pageSize:number):Observable<any>{
    let url=this.apiUrl+'OpenDeals/Admin/SpotForward/Search?tenantCar='+tenantCar+'&clientID='+clientID+'&pageNumber='+pagenumber+'&pageSize='+pageSize+'&createdByID='+createdby;
    return this.http.get(url);
  }
  gettradeDetails(tradeID: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'DealHistory/GetTradeConfo?tradeID=' + tradeID, { headers: headers, responseType: 'blob' });

  }
  getSSI() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'Common/GetSSI', { headers: headers, responseType: 'blob' });

  }
  getEditTradeDetails(tradeID :number):Observable<any>{
    let url=this.apiUrl+'Quote/GetSpotDealBenePaymentDetails?tradeID='+tradeID;
    return this.http.get(url);
  }
  saveChangesTrade(payload:any):Observable<any>{
    let url=this.apiUrl+'Quote/Payment'
    return this.http.post(url,payload);
  }
  searchClientTrade(pagenumber:number,pageSize:number):Observable<any>{
    let url=this.apiUrl+'OpenDeals/Client/SpotForward/Search?&pageNumber='+pagenumber+'&pageSize='+pageSize;
    return this.http.get(url);
  }
}
