import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldNameResponse, auditLogList, auditLogResponse, auditLogpayload } from '../../components/auditLog/models/auditLog.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuditlogService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getDropdownList(): Observable<auditLogList> {
    return this.http.get<auditLogList>(
      this.apiUrl + 'Support/AuditLog/GetLists'
    );
  }
  getFields(moduleName:string):Observable<FieldNameResponse>{
    return this.http.get<FieldNameResponse>(
      this.apiUrl + 'Support/AuditLog/FieldName?moduleName='+moduleName
    );
  }
  getAuditLogs(payload:auditLogpayload):Observable<auditLogResponse>{
    return this.http.post<auditLogResponse>(this.apiUrl+'Support/AuditLog/Search',payload);
  }
 
}
