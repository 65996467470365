import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddBeneficiaryService } from '../../services/addBeneficiary/add-beneficiary.service';
import swal from 'sweetalert2';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import Swal from 'sweetalert2';
import { ErrorMessages, POPDescription, RoutingTypesAndLabels, SystemConstant } from '../../../global/global.constant';
import { AdminInfo } from '../../../components/models/common.model';
import {
  BeneficiaryDetails,
  BeneficiaryList,
  Client,
  Country,
  Currency,
  PurposeOfPayments,
  RoutingType,
  RoutingTypeAndLabel,
  TenentCarPartners,
} from './models/add-beneficiary.model';

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrl: './add-beneficiary.component.css',
})
export class AddBeneficiaryComponent {
  dataLoaded: boolean = true;
  user: string = 'Beneficiary';
  isAdmin: boolean = true;
  tenentCarPartners: TenentCarPartners[] = [];
  clientLists: Client[] = [];
  purposeOfPayments: PurposeOfPayments[] = [];
  popDescription: string | null = POPDescription;
  selectedType: string = 'In';
  status: string = '';
  swiftCodeLable: string | null = 'SWIFT';
  currencyList: Currency[] = [];
  routingType: RoutingType[] = [];
  editMode: boolean = false;
  swiftCodelables: RoutingTypeAndLabel[] = RoutingTypesAndLabels;
  errormsg: string = '';
  isApproved: string = 'N';
  @Input() beneficiaryID: number = -1;
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  clientName: string = '';
  stausValue: string = 'Inactive';
  clientinfo: any;
  rolePermission: any;
  canInsert: boolean = false;
  canUpdate: boolean = false;
  userResponse: any = {};
  isApprovedByNavinci: boolean = false;
  countryList: Country[] = [];

  constructor(
    private fb: FormBuilder,
    private addBeneficiaryService: AddBeneficiaryService,
    private route: ActivatedRoute,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService
  ) {}

  addBeneficiaryForm: FormGroup = this.fb.group({
    tenantOrCARPartner: [null],
    routeTypeID: [null, [Validators.required]],
    clients: [null],
    purposeOfPayments: [null, [Validators.required]],
    beneficiaryName: [null, [Validators.required, Validators.maxLength(50)]],
    nickName: [null, [Validators.maxLength(50)]],
    email: [null, [Validators.email]],
    popdescription: [null],
    currency: [null, [Validators.required]],
    address: [null, [Validators.required, Validators.maxLength(255)]],
    city: [null, [Validators.required, Validators.maxLength(45)]],
    state: [null, [Validators.required, Validators.maxLength(45)]],
    countryID: [null, [Validators.required, Validators.maxLength(45)]],
    postcode: [null, [Validators.required, Validators.maxLength(10)]],
    reference: [null],
    bankAccountNo: [null, [Validators.required, Validators.maxLength(40)]],
    bankAccountName: [null, [Validators.required, Validators.maxLength(45)]],
    swiftCode: [null, [Validators.required, Validators.maxLength(20)]],
    bankName: [null, [Validators.required, Validators.maxLength(45)]],
    bankAddress: [null, [Validators.required, Validators.maxLength(255)]],
    bankCity: [null, [Validators.required, Validators.maxLength(45)]],
    bankState: [null, [Validators.required, Validators.maxLength(45)]],
    bankCountryID: [null, [Validators.required, Validators.maxLength(45)]],
    bankPostcode: [null, [Validators.required, Validators.maxLength(10)]],
  });

  ngOnInit() {
    const user = this.route.snapshot.pathFromRoot[1].url[0].path;
    if (user === 'Admin') {
      this.isAdmin = true;
      var savedLogin: AdminInfo =
        this.encryptPermission.getDecryptedPermissions('userResponse');
      if (savedLogin && savedLogin.userType == SystemConstant.Navinci) {
        this.isApprovedByNavinci = true;
      } else {
        this.isApprovedByNavinci = false;
      }
      this.addBeneficiaryForm.controls['clients'].addValidators(
        Validators.required
      );
      const modulePermission =
        this.encryptPermission.getDecryptedPermissions(
          'roleNgp'
        ).modulePermissions;

      if (modulePermission) {
        this.rolePermission = modulePermission.find(
          (x: { pageID: number }) => x.pageID == 23
        );
        if (this.rolePermission) {
          this.canInsert = this.rolePermission.canInsert == 1 ? true : false;
          this.canUpdate = this.rolePermission.canUpdate == 1 ? true : false;
        } else {
          this.canInsert = false;
          this.canUpdate = false;
        }
      }
      this.userResponse =
        this.encryptPermission.getDecryptedPermissions('userResponse');
    } else if (user === 'Client') {
      const rolePermissions =
        this.encryptPermission.getDecryptedPermissions(
          'crole'
        ).modulePermissions;
      if (rolePermissions) {
        this.rolePermission = rolePermissions.find(
          (x: { clientPageID: number }) => x.clientPageID == 56
        );
        if (this.rolePermission) {
          this.canInsert = this.rolePermission.canInsert == 1 ? true : false;
          this.canUpdate = this.rolePermission.canUpdate == 1 ? true : false;
        } else {
          this.canInsert = false;
          this.canUpdate = false;
        }
      }
      this.clientinfo =
        this.encryptPermission.getDecryptedPermissions('usercrd');
      if (this.clientinfo) {
        this.clientName = this.clientinfo.clientCode.toString();
      }
      this.isAdmin = false;
    }
    const currentMode = this.route.snapshot.url.join('/');
    if (currentMode == 'viewBeneficiaries') {
      this.editMode = true;
      this.dataLoaded = false;
      this.addBeneficiaryService.init$.subscribe((init) => {
        if (init) {
          this.dataLoaded = true;
          this.beneficiaryID = this.addBeneficiaryService.beneficiaryId;
          this.editMode = true;
          this.getbeneficiaryDetails();
        }
      });
    } else if (currentMode == 'addBeneficiary') {
      this.editMode = false;
      this.getBenficiaryLists();
    }
  }
  getBenficiaryLists() {
    if (this.isAdmin) {
      this.dataLoaded = false;
      this.addBeneficiaryService.getBeneficiaryList().subscribe({
        next: (data: BeneficiaryList) => {
          this.currencyList = data.currencyList;
          this.countryList = data.countryList;
          this.routingType = data.routingType;
          this.tenentCarPartners = data.tenantCARs;
          if (this.userResponse) {
            let tenantCarname = this.tenentCarPartners.find(
              (t) =>
                t.tenantCARPartnerName ===
                this.userResponse.tenantCARPartnerName
            );
            if (tenantCarname) {
              this.addBeneficiaryForm
                .get('tenantOrCARPartner')
                ?.setValue(tenantCarname.tenantCARPartnerName);
            }
          }
          this.getClientList();
          this.purposeOfPayments = data.purposeOfPayments;
          this.dataLoaded = true;
        },
        error: (error) => {},
      });
    } else {
      this.addBeneficiaryService.getClientBeneficiaryList().subscribe({
        next: (data: BeneficiaryList) => {
          this.countryList = data.countryList;
          this.currencyList = data.currencyList;
          this.routingType = data.routingType;
          this.purposeOfPayments = data.purposeOfPayments;
          this.dataLoaded = true;
        },
        error: (error) => {},
      });
    }
  }
  getClientList() {
    this.dataLoaded = false;
    const selectedTenant =
      this.addBeneficiaryForm.get('tenantOrCARPartner')?.value;
    this.addBeneficiaryService.getClientList(selectedTenant).subscribe({
      next: (data: any) => {
        this.clientLists = data;
        this.dataLoaded = true;
      },
      error: (error) => {},
    });
  }

  getEditBeneficiaryList() {
    return new Promise<void>((resolve, reject) => {
      this.addBeneficiaryService.getEditBeneficiaryList().subscribe({
        next: (data: BeneficiaryList) => {
          this.countryList = data.countryList;
          this.currencyList = data.currencyList;
          this.routingType = data.routingType;
          this.tenentCarPartners = data.tenantCARs;
          this.purposeOfPayments = data.purposeOfPayments;
          resolve();
        },
        error: (error) => {},
      });
    });
  }
  getClientEditBeneficiaryList() {
    return new Promise<void>((resolve, reject) => {
      this.addBeneficiaryService.getClientEditBeneficiaryList().subscribe({
        next: (data: BeneficiaryList) => {
          this.countryList = data.countryList;
          this.currencyList = data.currencyList;
          this.routingType = data.routingType;
          this.purposeOfPayments = data.purposeOfPayments;
          resolve();
        },
        error: (error) => {},
      });
    });
  }

  getbeneficiaryDetails() {
    if (this.isAdmin) {
      this.dataLoaded = false;
      this.getEditBeneficiaryList().then(() => {
        this.addBeneficiaryService
          .getBeneficiaryDetail(this.beneficiaryID)
          .subscribe({
            next: (data: BeneficiaryDetails) => {
              this.clientName = data.clientName;
              this.isApproved = data.isApproved;
              this.stausValue = data.status;
              this.selectedType = data.type;
              let currency = this.currencyList.find(
                (p) => p.currency === data.currency
              );
              let currencyID = currency ? currency.currencyID : null;

              this.addBeneficiaryForm.patchValue(data);
              this.addBeneficiaryForm.get('currency')?.setValue(currencyID);
              this.addBeneficiaryForm
                .get('purposeOfPayments')
                ?.setValue(data.popDetailID);
              if (this.purposeOfPayments && data.popDetailID) {
                let pop = this.purposeOfPayments.find(
                  (p) => p.popDetailID === data.popDetailID
                );
                this.popDescription = pop ? pop.description : null;
              }
              this.addBeneficiaryForm
                .get('routeTypeID')
                ?.setValue(data.fundtypeId);

              this.dataLoaded = true;
            },
            error: (error) => {
              this.dataLoaded = true;
            },
          });
      });
    } else {
      this.dataLoaded = false;
      this.getClientEditBeneficiaryList().then(() => {
        this.addBeneficiaryService
          .getBeneficiaryDetail(this.beneficiaryID)
          .subscribe({
            next: (data: BeneficiaryDetails) => {
              this.clientName = data.clientName;
              this.isApproved = data.isApproved;

              this.stausValue = data.status;
              this.selectedType = data.type;
              let currency = this.currencyList.find(
                (p) => p.currency === data.currency
              );
              let currencyID = currency ? currency.currencyID : null;
              this.addBeneficiaryForm.patchValue(data);
              this.addBeneficiaryForm.get('currency')?.setValue(currencyID);
              this.addBeneficiaryForm
                .get('purposeOfPayments')
                ?.setValue(data.popDetailID);
              if (this.purposeOfPayments && data.popDetailID) {
                let pop = this.purposeOfPayments.find(
                  (p) => p.popDetailID === data.popDetailID
                );
                this.popDescription = pop ? pop.description : null;
              }
              this.addBeneficiaryForm
                .get('routeTypeID')
                ?.setValue(data.fundtypeId);
              this.dataLoaded = true;
            },
            error: (error) => {
              this.dataLoaded = true;
            },
          });
      });
    }
  }
  popdescriptionvalue() {
    let id = this.addBeneficiaryForm.get('purposeOfPayments')?.value;
    let pop = this.purposeOfPayments.find(
      (p) => p.popDetailID === id
    );
    this.popDescription = pop ? pop.description : null;
  }
  chnageCodeLable() {
    let id = this.addBeneficiaryForm.get('routeTypeID')?.value;
    let swiftCode = this.swiftCodelables.find(
      (p) => p.routingtype === id
    );
    this.swiftCodeLable = swiftCode ? swiftCode.rotuingLable : null;
    this.swiftCodeLable = this.swiftCodeLable ? this.swiftCodeLable : 'SWIFT';
  }
  saveForm() {
    if (this.isAdmin && this.editMode) {
      this.addBeneficiaryForm.controls['clients'].setValue(this.clientName);
    }

    let formValue = this.addBeneficiaryForm.value;

    if (this.addBeneficiaryForm.valid) {
      this.dataLoaded = false;
      if (this.isAdmin) {
        if (this.editMode) {
          let payload = {
            beneficiaryID: this.beneficiaryID,
            isApproved: this.isApproved == 'Y' ? true : false,
            popDetailID: formValue.purposeOfPayments,
            beneficiaryName: formValue.beneficiaryName,
            nickName: formValue.nickName,
            email: formValue.email,
            type: this.selectedType == 'In' ? 'Incoming' : 'Outgoing',
            address: formValue.address,
            city: formValue.city,
            state: formValue.state,
            countryID: formValue.countryID,
            postcode: formValue.postcode,
            reference: formValue.reference,
            currencyID: formValue.currency,
            routeTypeID: formValue.routeTypeID,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            bankCity: formValue.bankCity,
            bankState: formValue.bankState,
            bankCountryID: formValue.bankCountryID,
            bankPostcode: formValue.bankPostcode,
            bankAccountNo: formValue.bankAccountNo,
            bankAccountName: formValue.bankAccountName,
            swiftCode: formValue.swiftCode,
          };
          this.saveBeneficiaryAPICall(
            payload,
            'Beneficiary/Admin/Update',
            'Beneficiary Updated sucessfully'
          );
        } else {
          let payload = {
            clientId: formValue.clients,
            popDetailID: formValue.purposeOfPayments,
            beneficiaryName: formValue.beneficiaryName,
            nickName: formValue.nickName,
            email: formValue.email,
            type: this.selectedType == 'In' ? 'Incoming' : 'Outgoing',
            address: formValue.address,
            city: formValue.city,
            state: formValue.state,
            countryID: formValue.countryID,
            postcode: formValue.postcode,
            reference: formValue.reference,
            currencyID: formValue.currency,
            routeTypeID: formValue.routeTypeID,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            bankCity: formValue.bankCity,
            bankState: formValue.bankState,
            bankCountryID: formValue.bankCountryID,
            bankPostcode: formValue.bankPostcode,
            bankAccountNo: formValue.bankAccountNo,
            bankAccountName: formValue.bankAccountName,
            swiftCode: formValue.swiftCode,
          };
          this.saveBeneficiaryAPICall(
            payload,
            'Beneficiary/Admin/Add',
            'Beneficiary Added sucessfully'
          );
        }
      } else {
        if (this.editMode) {
          let payload = {
            beneficiaryID: this.beneficiaryID,
            popDetailID: formValue.purposeOfPayments,
            beneficiaryName: formValue.beneficiaryName,
            nickName: formValue.nickName,
            email: formValue.email,
            type: this.selectedType == 'In' ? 'Incoming' : 'Outgoing',
            address: formValue.address,
            city: formValue.city,
            state: formValue.state,
            countryID: formValue.countryID,
            postcode: formValue.postcode,
            reference: formValue.reference,
            currencyID: formValue.currency,
            routeTypeID: formValue.routeTypeID,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            bankCity: formValue.bankCity,
            bankState: formValue.bankState,
            bankCountryID: formValue.bankCountryID,
            bankPostcode: formValue.bankPostcode,
            bankAccountNo: formValue.bankAccountNo,
            bankAccountName: formValue.bankAccountName,
            swiftCode: formValue.swiftCode,
          };
          this.saveBeneficiaryAPICall(
            payload,
            'Beneficiary/Client/Update',
            'Beneficiary Updated sucessfully'
          );
        } else {
          let payload = {
            popDetailID: formValue.purposeOfPayments,
            beneficiaryName: formValue.beneficiaryName,
            nickName: formValue.nickName,
            email: formValue.email,
            type: this.selectedType == 'In' ? 'Incoming' : 'Outgoing',
            address: formValue.address,
            city: formValue.city,
            state: formValue.state,
            countryID: formValue.countryID,
            postcode: formValue.postcode,
            reference: formValue.reference,
            currencyID: formValue.currency,
            routeTypeID: formValue.routeTypeID,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            bankCity: formValue.bankCity,
            bankState: formValue.bankState,
            bankCountryID: formValue.bankCountryID,
            bankPostcode: formValue.bankPostcode,
            bankAccountNo: formValue.bankAccountNo,
            bankAccountName: formValue.bankAccountName,
            swiftCode: formValue.swiftCode,
          };

          this.saveBeneficiaryAPICall(
            payload,
            'Beneficiary/Client/Add',
            'Beneficiary Added sucessfully'
          );
        }
      }
    } else {
      this.markFormGroupTouched(this.addBeneficiaryForm);
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Swal.fire({
      icon: 'warning',
      title: ErrorMessages.Please_fill_required_inputs,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private saveBeneficiaryAPICall = (
    payload: any,
    APIURL: string,
    sucessfullyMessage: string
  ): void => {
    this.addBeneficiaryService
      .saveBeneficiary(payload, APIURL, this.editMode)
      .subscribe({
        next: () => {
          this.dataLoaded = true;
          swal.fire({
            icon: 'success',
            title: sucessfullyMessage,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          });

          if (this.editMode) {
            this.closeModal();
          } else {
            if (this.isAdmin) {
              this.router.navigate(['/Admin/viewBeneficiaries']);
            } else {
              this.router.navigate(['/Client/viewBeneficiaries']);
            }
          }
        },
        error: (response: any) => {
          this.dataLoaded = true;
          this.displayBackEndErrorMessage(response);
        },
      });
  };

  closeModal() {
    this.onCloseModal.emit();
  }

  private displayBackEndErrorMessage = (response: any): void => {
    if (response && response.error && response.error.errors) {
      if (response.error.errors.BankAccountNo) {
        this.showMessage(response.error.errors.BankAccountNo[0]);
      } else if (response.error.errors.BankCity) {
        this.showMessage(response.error.errors.BankCity[0]);
      } else if (response.error.errors.BankCountry) {
        this.showMessage(response.error.errors.BankCountry[0]);
      } else if (response.error.errors.BankName) {
        this.showMessage(response.error.errors.BankName[0]);
      } else if (response.error.errors.BankPostcode) {
        this.showMessage(response.error.errors.BankPostcode[0]);
      } else if (response.error.errors.BeneficiaryName) {
        this.showMessage(response.error.errors.BeneficiaryName[0]);
      } else if (response.error.errors.City) {
        this.showMessage(response.error.errors.City[0]);
      } else if (response.error.errors.Country) {
        this.showMessage(response.error.errors.Country[0]);
      } else if (response.error.errors.Postcode) {
        this.showMessage(response.error.errors.Postcode[0]);
      } else if (response.error.errors.State) {
        this.showMessage(response.error.errors.State[0]);
      } else if (response.error.errors.SwiftCode) {
        var message = response.error.errors.SwiftCode[0].replace(
          'Swift Code',
          ''
        );
        this.showMessage(this.swiftCodeLable + ' ' + message);
      } else {
        this.showMessage(
          ErrorMessages.Error_occurred_while_processing_your_request
        );
      }
    }
  };

  private showMessage = (message: string): void => {
    Swal.fire({
      icon: 'warning',
      title: message,
      confirmButtonText: 'OK',
      showConfirmButton: true,
      timer: 3500,
    });
  };
}
