import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appAllowCharacterSpace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AllowCharacterSpaceDirective, multi: true }]
})
export class AllowCharacterSpaceDirective {

  constructor() { }

  validate(control: FormControl) {
    if (control.value) {
      const stringRegex: RegExp = /^[a-zA-Z ]+$/;
      const valid: boolean = stringRegex.test(control.value);
      return valid ? null : { invalid: true };
    } else {
      return null;
    }
  }

}
