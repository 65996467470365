<!-- Tab panes -->
<div *ngIf="!dataLoaded">
    <div class="loader-block">
      <div class="inner-loader">{{ loadingMessage }}...</div>
    </div>
  </div>
  <div class="compress-inputs-page">
    <div class="tab-content">
      <!-- Client Beneficiary Body -->
      <div class="tab-pane active" id="ClientBeneficiary">
        <div class="tab-body-wrapper">
          <div *ngIf="clientBeneficiaryData.length > 0; else notFound" class="table-responsive">
            <table class="table custom-grid">
              <thead>
                <tr class="Row123">
                  <th style="width: 7%">
                    <a (click)="sortTable('title')" class="sort active ts-asc" unselectable="on" data-i="0">Name<span
                        class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 5%">
                    <a (click)="sortTable('details')" class="sort active ts-asc" unselectable="on" data-i="1">Currency<span
                        class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 10%">
                    <a (click)="sortTable('createdByName')" class="sort active ts-asc" unselectable="on" data-i="2">Bank Name
                        <span class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 5%">
                    <a (click)="sortTable('creationDate')" class="sort active ts-asc" unselectable="on" data-i="3">Type
                        <span class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 8%">
                    <a (click)="sortTable('modifiedByName')" class="sort active ts-asc" unselectable="on"
                      data-i="4">Account No<span class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 5%">
                    <a (click)="sortTable('modificationDate')" class="sort active ts-asc" unselectable="on"
                      data-i="5">Swift / IBAN<span class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 5%">
                    <a (click)="sortTable('balance')" class="sort active ts-asc" unselectable="on" data-i="8">Internal Reference
                        <span class="ts-sord"></span>
                    </a>
                  </th>
                  <th style="width: 5%">
                    <a (click)="sortTable('balance')" class="sort active ts-asc" unselectable="on" data-i="8">Status
                        <span class="ts-sord"></span>
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of paginatedData">
                  <td class="wrapword">{{ row.name }} </td>
                  <td class="wrapword">{{ row.currency }}</td>
                  <td class="wrapword">{{ row.bankName }}</td>
                  <td class="wrapword">{{ row.type }}</td>
                  <td class="wrapword">{{ row.bankAccountNo }}</td>
                  <td class="wrapword">{{ row.swiftCode }}</td>
                  <td class="wrapword">{{ row.internalReference }}</td>
                  <td class="wrapword">
                    <span class="badge" [ngClass]="statusClass(row)">{{ row.status }}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
            <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
              [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
              [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
              [pageIndex]="pageIndex" aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
    <ng-template #notFound>
      <div class="alert alert-info">
        <span>No record found</span>
      </div>
    </ng-template>
 
  </div>