import { Component, ElementRef, Input } from '@angular/core';
import { DashboardService } from '../../../services/DashboardService/dashboard-service.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spot-rates',
  templateUrl: './spot-rates.component.html',
  styleUrl: './spot-rates.component.css',
})
export class SpotRatesComponent {
  @Input('SpotRateList') SpotRateList!: Observable<any>;
  symbols = '';
  symbolsToPass: { [key: string]: string }[] = [];
  dataLoaded: boolean = false;
  SpotRateShowList: any;
  constructor(
    private el: ElementRef,
    private dashboardService: DashboardService
  ) {}
  ngOnInit() {
    // this.getSpotRatesListForClient();
  }
  ngOnChanges() {
    this.SpotRateList?.subscribe({
      next: (data) => {
        this.SpotRateShowList = data;
        this.getSymbolList();
      },
    });
  }

  // getSpotRatesListForClient() {
  //   this.dataLoaded = false;
  //   this.dashboardService.getSpotRateList().subscribe({
  //     next: (data) => {
  //       this.SpotRateList = data;
  //       this.dataLoaded = true;
  //       this.getSymbolList();
  //     },
  //     error: () => {
  //       this.dataLoaded = true;
  //     },
  //   });

  // }

  getSymbolList() {
    for (var i = 0; i < this.SpotRateShowList.length; i++) {
      // this.symbols = "{ " + "\"" + "s" + "\"" + ": " + "\"" + "FX_IDC:" + this.SpotRateShowList[i].currencyPair + "\"" + " }"
      var sym = 'FX_IDC:' + this.SpotRateShowList[i].currencyPair;
      let key: { [key: string]: string } = {
        "s": sym,
      };
      this.symbolsToPass.push(key);
    }
    this.tredingviewChart();
  }
  tredingviewChart() {
    const container = this.el.nativeElement.querySelector(
      '.tradingview-widget-container'
    );
    if (!container) {
      return;
    }
    // Clear existing content
    container.innerHTML = '';
    const script = document.createElement('script');

    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    {
      "colorTheme": "light",
      "dateRange": "12M",
      "showChart": false,
      "locale": "en",
      "largeChartUrl": "",
      "isTransparent": false,
      "showSymbolLogo": true,
      "width": "99%",
      "height": "300",
      "tabs": [
        {
          "title": "Forex",
          "symbols": 
            ${JSON.stringify(this.symbolsToPass)}
          
          
        }
      ]
  }`;
    this.el.nativeElement
      .querySelector('.tradingview-widget-container')
      .appendChild(script);
  }
}
