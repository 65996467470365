import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralPartnerGeneralService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getAllRelationshipManagerList(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner/General/GetLists';
    return this.http.get<any>(url, httpOptions);
  }

  getReferralPartnerById(referralpartnerId: number): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner/' + referralpartnerId;
    return this.http.get<any>(url, httpOptions);
  }

  saveReferralPartner(payload: any): Observable<any>{
    const payloadData = {
      payload: payload,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner';
    return this.http.post<any>(url, payload, httpOptions);
  }

  updateReferralPartner(payload: any): Observable<any>{
    const payloadData = {
      payload: payload,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    let url = this.apiUrl + 'ReferralPartner';
    return this.http.patch<any>(url, payload, httpOptions);
  }
}
