import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CarPartnerService } from '../../services/carPartner/car-partner.service';

@Component({
  selector: 'app-manage-carpartner',
  templateUrl: './manage-carpartner.component.html',
  styleUrl: './manage-carpartner.component.css',
})
export class ManageCARPartnerComponent implements OnInit {
  dataLoaded:boolean=true;
  carPartners :any []=[];
  isAscending: boolean = true;
  sortedColumn: string = '';
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  hideAddButton = true;
  searchString :string="";
  constructor( private carPartnerServices: CarPartnerService, private router: Router) {
   
  }
  ngOnInit(): void {
    this.getcarPartner("");
  }
  editCarPartner(carPartnerId:number){
    this.router.navigate(['/Admin/saveCarPartner',carPartnerId]);
  }

  addCarPartner(){
    this.router.navigate(['/Admin/saveCarPartner']);
  }

  getcarPartner(searchString:string){
    this.dataLoaded=false
    this.carPartnerServices.getcarPartner(searchString).subscribe({
      next: (data: any) => {
        this.carPartners=data;
        this.length=this.carPartners.length;
        this.paginatedData = this.carPartners.slice(0, this.pageSize);
        this.dataLoaded=true;
      },
      error: (error) => {
      },
    });
  }
  sort(columnName: string): void {
    this.carPartners.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }
  sortTable(columnName: string) {
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.carPartners.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.carPartners.slice(startIndex, endIndex);
  }
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.carPartners.slice(startIndex, endIndex);
  }
  search(){
    this.getcarPartner(this.searchString);
  }
  reset(){
    this.searchString="";
    this.getcarPartner("");
  }
}
