import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login/login.service';
import { DOCUMENT } from '@angular/common';
import { EncryptRolePermissionService } from '../../../services/rolePermissions/role-permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { AddEditRoleService } from '../../services/addeditRole/add-edit-role.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  loading: boolean = false;
  password: string = '';
  fieldTextType: boolean = false;
  hide: boolean = true;
  clientInfo: any;
  errormsg: string = '';
  validationflag: boolean = false;
  hashToken: any;
  decodedToken: any;
  validToken: boolean = true;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginservice: LoginService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private encryptPermission: EncryptRolePermissionService,
    private rolePermission: AddEditRoleService
  ) {}
  loginForm: FormGroup = this.fb.group({
    clientID: ['', [Validators.required]],
    username: ['', Validators.required],
    password: ['', [Validators.required]],
    rememberMe: [false],
  });
  @Input() id: string = '';
  @Input() urlCode: string = '';
  ngOnInit() {
    const localStorage = this.document.defaultView?.localStorage;

    this.hashToken = this.id;
    if (this.hashToken) {
      this.loading = true;
      this.loginservice.validateToken(this.hashToken).subscribe({
        next: (data: any) => {
          this.validToken = true;
          this.loading = false;
        },
        error: (error) => {
          this.validToken = false;
          this.loading = false;
        },
      });
    } 

    var savedLogin = this.encryptPermission.getDecryptedPermissions('logcred');
    if (savedLogin) {
      this.loginForm.patchValue(savedLogin);
    }

  }
  loginClient() {
    const localStorage = this.document.defaultView?.localStorage;
    if (this.loginForm.value.rememberMe) {
      this.encryptPermission.encryption(this.loginForm.value, 'logcred');
    } else {
      localStorage?.removeItem('logcred');
    }
    const loginformValue = this.loginForm.value;

    let payload = {
      clientCode: loginformValue.clientID,
      username: loginformValue.username,
      urlCode: this.urlCode == '' ? null : this.urlCode,
      password: loginformValue.password,
    };
    if (this.loginForm.valid) {
      this.loading = true;
      this.loginservice.clientLogin(payload).subscribe({
        next: (data: any) => {
          this.clientInfo = data;
          localStorage?.setItem('jwtToken', data.jwtToken);
          this.encryptPermission.encryption(data, 'usercrd');
          this.getRolesPermission(data.clientRoleID);
          
        },
        error: (error) => {
          this.loading = false;

          if (
            error.error &&
            error.error.errors &&
            error.error.errors.Password
          ) {
            const passwordError = error.error.errors.Password[0];
            this.errormsg = passwordError;
          } else if (error.error && Array.isArray(error.error)) {
            const description = error.error[0].description;
            this.errormsg = description;
          } else {
            this.errormsg = 'Invalid Username or Password';
          }
          this.validationflag = true;
        },
      });
    }
  }

  routeToForgotPassword() {
    this.router.navigate(['forgotclientpassword']);
  }

  regenerateLink() {
    this.loginservice.regenerateURL(this.hashToken).subscribe({
      next: (data: any) => {
        swal
          .fire({
            icon: 'success',
            title: 'A new link has been sent to your email',
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/Client']);
            }
          });
      },
      error: (error) => {
        if (
          error.error &&
          Array.isArray(error.error) &&
          error.error[0].errorType === 'Validation'
        ) {
          const description = error.error[0].description;
          this.errormsg = description;
        } else {
          this.errormsg = 'Unable to send mail invalid tokken or Url';
        }
        swal
          .fire({
            icon: 'warning',
            title: this.errormsg,
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/Client']);
            }
          });
      },
    });
  }

  getRolesPermission(roleId: number) {
    this.rolePermission.getRoledetails(roleId).subscribe({
      next: (data: any) => {
        this.encryptPermission.encryption(data, 'crole');
        this.loading = false;
          if (this.clientInfo.changePasswordAtNextLogon) {
            this.hashToken = encodeURIComponent(this.hashToken);
            this.router.navigateByUrl(
              `Client/Account/ResetPassword/${this.hashToken}`
            );
          } else if (this.clientInfo.is2FAEnabled) {
            this.router.navigateByUrl('validateclientotp');
          } else {
            this.router.navigateByUrl('Client/Dashboard');
          }
      },
      error: (error) => {
        this.loading = false;
        if (error && error.status && error.status == 400) {
          Swal.fire({
            icon: 'warning',
            title: "Your account is currently inactive. Please contact support for assistance.",
            confirmButtonText: 'OK',
            showConfirmButton: true,
            timer: 3500
          });
        }
      },
    });
  }
}
