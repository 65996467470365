import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBeneficiaryPercent]'
})
export class BeneficiaryPercentDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,1}$/g); // Allow up to 12 decimal places
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value.replace('%', '');
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position)
    ].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    } else if (parseFloat(next) > 100) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text');
    if (pastedInput) {
      const pasted = pastedInput.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
      if (!pasted.match(this.regex) || parseFloat(pasted) > 100) {
        event.preventDefault();
      }
    }
  }

  @HostListener('blur')
  onBlur() {
    let value: string = this.el.nativeElement.value.replace('%', '');
    if (value && !isNaN(value as any) && parseFloat(value) <= 100) {
      this.el.nativeElement.value = `${value}%`;
    }
  }

  @HostListener('focus')
  onFocus() {
    let value: string = this.el.nativeElement.value.replace('%', '');
    this.el.nativeElement.value = value;
  }


}
