import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ValidateotpService } from '../../services/validateotp/validateotp.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';

@Component({
  selector: 'app-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrl: './validate-otp.component.css',
})
export class ValidateOtpComponent implements OnInit {
  loading = false;
  error = false;
  userOtp = '';
  otpExpiresAt: any;
  adminUserInfo: any;
  interval: any;
  display: any;
  timeoutExpired = false;
  remainingTime!: number;
  minutes!: number;
  seconds!: number;
  message = '';
  timer: any;
  otpSendTo: any;

  constructor(
    private validateotpservice: ValidateotpService,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService,

    
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

 

  ngOnInit(): void {
    this.timeoutExpired = false;
    this.message = 'This code is valid for ';
    this.adminUserInfo = this.encryptPermission.getDecryptedPermissions('userResponse');
    this.otpExpiresAt = this.adminUserInfo?.otpExpiresAt;   
    this.otpSendTo=this.adminUserInfo?.otpSendTo;
    if (this.otpExpiresAt) {
      this.calculateRemainingTime();
      this.otpExpireTimer();
    }    
  }
  
  calculateRemainingTime() {
    const now = new Date().getTime();
    const expiration = new Date(this.otpExpiresAt).getTime();
    this.remainingTime = Math.max(0, (expiration - now) / 1000); // Remaining time in seconds
    this.minutes = Math.floor(this.remainingTime / 60);
    const prefixForMinute = this.minutes < 10 ? '0' : '';
    this.seconds = Math.floor(this.remainingTime % 60);
    const prefixForSeconds = this.seconds < 10 ? '0' : '';
    if (this.minutes > 0) {
      this.display = `${prefixForMinute}${this.minutes}:${prefixForSeconds}${this.seconds}`;
    } else {
      this.display = `${prefixForSeconds}${this.seconds}`;
    }
    if (this.seconds == 0 && this.minutes == 0) {
      this.message = 'Click on Resend button to generate new OTP';
      this.timeoutExpired = true;
      clearInterval(this.timer);
    }
  }
  otpExpireTimer() {
    this.timer = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  }
  

  onSubmit() {
    var username = this.adminUserInfo.userName;
    var payload = {
      userName: username,
      otp: this.userOtp,
    };
    this.validateotpservice.validateOtp(payload).subscribe({
      next: (result) => {
        this.router.navigateByUrl('Admin/managecontact');
      },
      error: () => {
        this.error = true;
      },
    });
  }
  
  resendOtp() {
    this.error = false;
    this.timeoutExpired = false;
    this.message = 'This code is valid for ';
    var payload = {     
      userName: this.adminUserInfo?.userName,
    };
    this.validateotpservice.resendOtp(payload).subscribe({
      next: (response: any) => {
        this.otpExpiresAt = response.otpExpiresAt;
        this.otpExpireTimer();
      },
    });
  }

  backToLogin() {
    this.router.navigate(['admin']);
  }
  ngOnDestroy() {
    clearInterval(this.timer);
  }

  
}
