import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EncryptRolePermissionService } from '../rolePermissions/role-permission.service';
import { Tenant } from '../../components/add-edit-tenant/models/tenant.model';
import { CARPartner } from '../../components/add-edit-car-partner/models/CarPartner.model';
import { VerifyEmailResponse } from '../../client/components/verify-email/verifyEmail.model';
import { SystemConstant } from '../../global/global.constant';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService
  ) {}

  baseUrl: string = environment.apiUrl;
  loggedIn = new BehaviorSubject<boolean>(false);
  Username: string = '';

  signIn(username: string, password: string): Observable<any> {
    return this.http
      .post(this.baseUrl + 'Account/signin', { username, password })
      .pipe(
        tap((response: any) => {
          if (response.status) {
            this.Username = response.userName;
            this.document.defaultView?.localStorage.setItem( 'sessionTimeout', response.sessionTimeoutInMinutes);
            this.loggedIn.next(true);
          }
        })
      );
  }

  isAuthenticated() {
    const token = this.document.defaultView?.localStorage?.getItem('jwtToken');
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp * 1000;
      if(new Date().getTime() < expiry){
       
        return true;
      }
      
    }
    this.document.defaultView?.localStorage?.removeItem('jwtToken');
    return false;
  }

  get isLoggedIn(): Observable<boolean> {
    if (this.document.defaultView?.localStorage?.getItem('jwtToken')) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }

  logout():Observable<any>{
    const localStorage = this.document.defaultView?.localStorage;  
    var savedLogin = this.encryptPermission.getDecryptedPermissions('logadmincred');
    localStorage?.clear();
    this.encryptPermission.encryption(savedLogin, 'logadmincred');    
    this.loggedIn.next(false);  
    this.router.navigate(['/admin']);
    let url = this.baseUrl + 'Account/signout';
    return this.http.post<any>(url,null);
  }

  refreshToken() {
    const previousToken = this.document.defaultView?.localStorage?.getItem('jwtToken');
    var loggedTimeValue: string | null = localStorage.getItem("loggedInTime");
    
    if (loggedTimeValue) {
      let currentDateTime: Date = new Date();
      var dateSent: Date = new Date(loggedTimeValue);
      const timeDifference: number = currentDateTime.getTime() - dateSent.getTime(); // return miliseconds
      const minitue: number = timeDifference/60000; // convert miliseconds to minitue
      const sessionTimeout: number = +this.document.defaultView?.localStorage?.getItem('sessionTimeout')!;
      const idleTimer = sessionTimeout * 3;

      if (minitue >= idleTimer) {
        const params = new HttpParams().set('token', previousToken!);
        this.http
          .post(this.baseUrl + 'Account/RefreshToken', null, { params,responseType:'text' })
          .subscribe({
            next: (response: any) => {
              this.document.defaultView?.localStorage?.setItem('jwtToken', response);
              this.loggedIn.next(true);
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error Occurrred!!',
                html: 'some error occurred please login again.',
                showConfirmButton: true,
              });
              this.logout();
            },
          });
      }
    }
  }

  validateToken(token:any):Observable<any>{
    let url = this.baseUrl + 'Account/validatetoken?token='+encodeURIComponent(token);
    return this.http.post(url,null);
  }


  regenerateURL(hashtoken:string):Observable<any>{
    let payload={
      token :hashtoken
    }
    let url = this.baseUrl + 'Account/regenerateLinkNewAdmin';
    return this.http.post<any>(url,payload);
  }

  verifyEmail(token: string): Observable<VerifyEmailResponse> {    
    let url = this.baseUrl + 'ManageUsers/verifyEmail?token='+encodeURIComponent(token);
    return this.http.post<VerifyEmailResponse>(url,null);
  }

  getTenant = (tenantId: number): Observable<Tenant> => {
    return this.http.get<Tenant>(this.apiUrl + `Tenant/${tenantId}`);
  }

  getCarPartner = (carPartnerId: number): Observable<CARPartner> => {
    return this.http.get<CARPartner>(this.apiUrl + "CARPartner?carPartnerID="+carPartnerId);
  }
}
