import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../Home/header/header.component';

@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrl: './quick-quote.component.css',
})
export class QuickQuoteComponent implements OnInit {
  isNavBarOpen: boolean = true;

  ngOnInit(): void {}
}
