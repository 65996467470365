import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import {
  ClientAttachments_TabID,
  ClientBeneficiaries_TabID,
  ClientContact_TabID,
  ClientGeneral_TabID,
  ClientNotes_TabID,
  ClientOrdersMargin_TabID,
  TabRoutingArray,
} from '../../global/global.constant';

@Component({
  selector: 'app-tabnavigation',
  templateUrl: './tabnavigation.component.html',
  styleUrl: './tabnavigation.component.css',
})
export class TabnavigationComponent implements OnInit {
  editMode = false;
  clientID: number | undefined;
  isActive = true;
  tabPermissions: any = [];
  uiTabpermission: boolean[] = [];

  clientGeneral_TabID: number = ClientGeneral_TabID;
  clientNote_TabID: number = ClientNotes_TabID;
  clientAttachments_TabID: number = ClientAttachments_TabID;
  clientBeneficiaries_TabID: number = ClientBeneficiaries_TabID;
  clientOrdersMargin_TabID: number = ClientOrdersMargin_TabID;
  clientContact_TabID: number = ClientContact_TabID;
  tabWithCanView: any;
  generalCanView: any;
  CurrentRoute: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService
  ) {
    this.tabPermissions =
      encryptPermission.getDecryptedPermissions('roleNgp').tabPermissions;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((parameterMap) => {
      this.clientID = +parameterMap.get('ClientID')!;
      if (this.clientID) {
        this.editMode = true;
      }
    });
    this.canViewTabOrNot();
  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

  isGeneralTabActive: boolean = true;

  handleTabClick(tabName: string): void {
    this.isGeneralTabActive = tabName === 'General';
  }

  back() {
    this.router.navigate(['/Admin/manageclient']);
  }

  canViewTabOrNot() {
    this.tabPermissions.forEach((tab: any) => {
      this.uiTabpermission[tab.tabID] = tab.canView;
    });
    this.generalCanView = this.tabPermissions.find(
      (tab: { tabID: number; canView: number; canInsert: number }) =>
        tab.tabID === ClientGeneral_TabID
    );
    if (this.generalCanView && this.generalCanView.canView == 1) {
      this.CurrentRoute = TabRoutingArray.find(
        (tab: { tabID: number; route: string }) =>
          tab.tabID === ClientGeneral_TabID
      );
      this.router.navigate([
        '/Admin/getclienttab',
        this.clientID,
        {
          outlets: { tabnavigation: [this.CurrentRoute.route, this.clientID] },
        },
      ]);
    } else {
      this.tabWithCanView = this.tabPermissions.find(
        (tab: { tabID: number; canView: number; canInsert: number }) =>
          tab.canView === 1
      );

      if (this.tabWithCanView) {
        this.CurrentRoute = TabRoutingArray.find(
          (tab: { tabID: number; route: string }) =>
            tab.tabID === this.tabWithCanView.tabID
        );
        this.router.navigate([
          '/Admin/getclienttab',
          this.clientID,
          {
            outlets: {
              tabnavigation: [this.CurrentRoute.route, this.clientID],
            },
          },
        ]);
      } else {
        this.router.navigate(['Admin/Unauthorized']);
      }
    }
  }
}
