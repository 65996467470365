<div *ngIf="!dataLoaded">
    <div class="loader-block">
        <div class="inner-loader">Loading...</div>
    </div>
</div>
<div *ngIf="!canInsert" class="card notAccesscss margincss">
    <h1 class="mt-19">Sorry, you don't have permission to view this page.</h1> <br>
    <p>Please contact our support to review your role permissions.</p>
</div>
<div id="Npbendiv" *ngIf="canInsert">
    <form clas="form-group" [formGroup]="addBeneficiaryForm" (ngSubmit)="saveForm()">
        <div class="inner secondary">
            <div class="card-wrapper">
                <span id="viewbeneficiaryModeid" class="hidden"></span>
                <div class="card">
                    <div class="row">
                        <div class="col-md-2" *ngIf="!editMode && isAdmin">
                            <div class="form-group">
                                <label>Tenant/CAR Partner</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary" style="width:80%;">
                                        <ng-select [items]="tenentCarPartners" [closeOnSelect]="true"
                                            [clearable]="false" [searchable]="true" bindLabel="tenantCARPartnerName"
                                            bindValue="tenantCARPartnerName" placeholder="Select"
                                            formControlName="tenantOrCARPartner" (change)="getClientList()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Client
                                    <em *ngIf="isAdmin && !editMode" style="color:red;">*</em>
                                </label>
                                <div class="checkbox-currency" *ngIf="isAdmin;else elseblock2 ">
                                    <div class="custom-radio secondary" *ngIf="editMode; else elseBlock">
                                        <span> {{clientName}}</span>
                                    </div>
                                    <ng-template class="custom-radio secondary" #elseBlock>
                                        <ng-select style="width:100%;" [items]="clientLists" [closeOnSelect]="true"
                                            [clearable]="false" [searchable]="true" bindLabel="name"
                                            bindValue="clientID" placeholder="Select" formControlName="clients"
                                            [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['clients'].touched) && addBeneficiaryForm.controls['clients'].invalid } "
                                            datatitle="Client is Required">
                                        </ng-select>
                                    </ng-template>
                                </div>
                                <ng-template class="checkbox-currency" #elseblock2>
                                    <div class="custom-radio secondary" style="width:80%;">
                                        <span> {{clientName}}</span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Purpose Of Payment <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary" style="width:100%;">
                                        <ng-select
                                            [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['purposeOfPayments'].touched) && addBeneficiaryForm.controls['purposeOfPayments'].invalid } "
                                            datatitle="Purpose of Paymentis Required" [items]="purposeOfPayments"
                                            [closeOnSelect]="true" [searchable]="true" bindLabel="name"
                                            bindValue="popDetailID" placeholder="Select" [clearable]="false"
                                            formControlName="purposeOfPayments" (change)="popdescriptionvalue()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>POP Description</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <span class="radio-label">{{popDescription}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                                <label> {{ isAdmin && !editMode ? 'Account' : user }} Status</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <input type="radio" [disabled]="isAdmin" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="status" [value]="stausValue" class="radio-input" name="status">
                                        <span class="radio-label">{{stausValue=='Y'?'Active':'Inactive'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2" *ngIf="editMode && isAdmin">
                            <div class="form-group">
                                <label>Approved By Navinci</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <input type="radio" [ngModelOptions]="{standalone: true}"
                                            [disabled]="!isApprovedByNavinci" [(ngModel)]="isApproved" value="Y"
                                            class="radio-input" name="approved">
                                        <span class="radio-label">Yes</span>
                                    </div>
                                    <div class="custom-radio secondary">
                                        <input type="radio" [ngModelOptions]="{standalone: true}"
                                            [disabled]="!isApprovedByNavinci" [(ngModel)]="isApproved" value="N"
                                            class="radio-input" name="approved">
                                        <span class="radio-label">No</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="col-md-2" *ngIf="!isAdmin">
                            <div class="form-group">
                                <label>Approved By Navinci</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <span class="radio-label">{{isApproved=='Y' ?'Yes':'No'}}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="has-sub-title">
                        <h4 class="title"> {{user}} Details</h4>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Type </label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <input type="radio" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="selectedType" value="In" class="radio-input" name="type">
                                        <span class="radio-label">Incoming</span>
                                    </div>
                                    <div class="custom-radio secondary">
                                        <input type="radio" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="selectedType" value="Out" class="radio-input" name="type">
                                        <span class="radio-label">Outgoing</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Name <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['beneficiaryName'].touched) && addBeneficiaryForm.controls['beneficiaryName'].invalid } "
                                        datatitle="Name is Required">
                                        <input maxlength="100" class="form-control" [placeholder]="user + ' Name'"
                                            formControlName="beneficiaryName" appAllowCharacterSpace>
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['beneficiaryName'].errors?.['invalid']">Beneficiary
                                            Name should only contain character.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['beneficiaryName'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['beneficiaryName'].hasError('maxlength')">Beneficiary
                                            Name should not be greater then 50 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Nick Name</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['nickName'].touched) && addBeneficiaryForm.controls['nickName'].invalid } "
                                        datatitle="Nick Name is Required">
                                        <input maxlength="100" class="form-control" [placeholder]="user + ' Nick Name'"
                                            formControlName="nickName" appAllowCharacterSpace>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Email (Optional)</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['email'].touched) && addBeneficiaryForm.controls['email'].invalid } "
                                        datatitle="Enter Valid Email">
                                        <input type="text" maxlength="100" class="form-control" [placeholder]="user + ' Email'"
                                            formControlName="email">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Address <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['address'].touched) && addBeneficiaryForm.controls['address'].invalid } "
                                        datatitle="Address is Required">
                                        <input maxlength="500" class="form-control" [placeholder]="user + ' Address'"
                                            formControlName="address">
                                        <!-- <small class="custom-errormessage" *ngIf="addBeneficiaryForm.controls['address'].errors?.['pattern']">Address should only contain Alphanumeric.</small><br *ngIf="addBeneficiaryForm.controls['address'].errors?.['pattern']"> -->
                                        <!-- <small class="custom-errormessage" *ngIf="addBeneficiaryForm.controls['address'].hasError('maxlength')">Address should not be greater then 255 character.</small> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>City <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['city'].touched) && addBeneficiaryForm.controls['city'].invalid } "
                                        datatitle="City is Required">
                                        <input maxlength="45" class="form-control" placeholder="City"
                                            appAllowCharacterSpace formControlName="city">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['city'].errors?.['invalid']">City should
                                            only contain character.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['city'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['city'].hasError('maxlength')">City
                                            should not be greater then 45 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>State <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['state'].touched) && addBeneficiaryForm.controls['state'].invalid } "
                                        datatitle="State is Required">
                                        <input maxlength="45" class="form-control" placeholder="State"
                                            appAllowCharacterSpace formControlName="state">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['state'].errors?.['invalid']">State
                                            should only contain character.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['state'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['state'].hasError('maxlength')">State
                                            should not be greater then 45 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Country <em style="color:red;">*</em></label>
                                <ng-select [clearable]="false"
                                    [ngClass]="{'newTooltip expand has-error ngselect-error': (addBeneficiaryForm.controls['countryID'].touched) && addBeneficiaryForm.controls['countryID'].invalid } "
                                    datatitle="Country is Required" [items]="countryList" [closeOnSelect]="true"
                                    [searchable]="true" bindLabel="countryName" bindValue="countryID"
                                    placeholder="Select" formControlName="countryID">
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Postcode<em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['postcode'].touched) && addBeneficiaryForm.controls['postcode'].invalid } "
                                        datatitle="postcode is Required">
                                        <input maxlength="45" class="form-control" placeholder="Postcode"
                                            formControlName="postcode" appOnlyNumber>
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['postcode'].errors?.['invalid']">Postcode
                                            should only contain numeric values.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['postcode'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['postcode'].hasError('maxlength')">Postcode
                                            should not be greater then 10 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Reference</label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary">
                                        <input maxlength="250" class="form-control" placeholder="Reference note"
                                            formControlName="reference">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div class="has-sub-title">
                        <h4 class="title">Bank Details</h4>
                    </div>
                    <div class="row">

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Currency <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary" style="width:40%;">
                                        <ng-select [clearable]="false"
                                            [ngClass]="{'newTooltip expand has-error ngselect-error': (addBeneficiaryForm.controls['currency'].touched) && addBeneficiaryForm.controls['currency'].invalid } "
                                            datatitle="Currency is Required" [items]="currencyList"
                                            [closeOnSelect]="true" [searchable]="true" bindLabel="currency"
                                            bindValue="currencyID" placeholder="Select" formControlName="currency">
                                        </ng-select>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Routing Type <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary" style="width:69%;"
                                        [ngClass]="{'newTooltip redborder expand has-error': (addBeneficiaryForm.controls['routeTypeID'].touched) && addBeneficiaryForm.controls['routeTypeID'].invalid } "
                                        datatitle="Routing Type is Required">
                                        <ng-select [items]="routingType" [clearable]="false" [closeOnSelect]="true"
                                            [searchable]="true" bindLabel="fundtype" bindValue="fundtypeId"
                                            placeholder="Select" formControlName="routeTypeID"
                                            (change)="chnageCodeLable()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="dvbeneficiarySwiftcode" class="col-md-4">
                            <div class="form-group">
                                <label><span id="routingLabel"></span>{{swiftCodeLable}} <em
                                        style="color:red;">*</em></label>
                                <div>
                                    <div class="custom-radio secondary" style="width:200px;"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['swiftCode'].touched) && addBeneficiaryForm.controls['swiftCode'].invalid } "
                                        datatitle="Identifier/Code Number is Required">
                                        <input maxlength="20" class="form-control" appAllowAlphanumericSpace
                                            placeholder="0" formControlName="swiftCode">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['swiftCode'].errors?.['invalid']">{{swiftCodeLable}}
                                            should only contain alphanumerical.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['swiftCode'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['swiftCode'].hasError('maxlength')">{{swiftCodeLable}}
                                            should not be greater then 20 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label id="DynamicBankNamelabel">Bank Name</label> <em style="color:red;">*</em>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankName'].touched) && addBeneficiaryForm.controls['bankName'].invalid } "
                                        datatitle="Bankname is Required">
                                        <input maxlength="45" class="form-control" placeholder="Bank Name"
                                            appAllowCharacterSpace formControlName="bankName">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankName'].errors?.['invalid']">Bank
                                            name should only contain character.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankName'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankName'].hasError('maxlength')">Bank
                                            name should not be greater then 45 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Address <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankAddress'].touched) && addBeneficiaryForm.controls['bankAddress'].invalid } "
                                        datatitle="BankAddress is Required">
                                        <input maxlength="500" class="form-control" placeholder="Bank Address"
                                            formControlName="bankAddress">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankAddress'].errors?.['pattern']">Address
                                            should only contain Alphanumeric.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankAddress'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankAddress'].hasError('maxlength')">Address
                                            should not be greater then 255 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>                     

                        <div class="col-md-4">
                            <div class="form-group">
                                <label> City <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankCity'].touched) && addBeneficiaryForm.controls['bankCity'].invalid } "
                                        datatitle="Bank City is Required">
                                        <input maxlength="45" class="form-control" placeholder="Bank City"
                                            appAllowCharacterSpace formControlName="bankCity">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankCity'].errors?.['invalid']">City
                                            should only contain character</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankCity'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankCity'].hasError('maxlength')">City
                                            should not be greater then 45 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> State <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankState'].touched) && addBeneficiaryForm.controls['bankState'].invalid } "
                                        datatitle="Bank State is Required">
                                        <input maxlength="45" class="form-control" placeholder="Bank State"
                                            appAllowCharacterSpace formControlName="bankState">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankState'].errors?.['invalid']">City
                                            should only contain character</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankState'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankState'].hasError('maxlength')">City
                                            should not be greater then 45 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Postcode <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankPostcode'].touched) && addBeneficiaryForm.controls['bankPostcode'].invalid } "
                                        datatitle="Bank Postcode is Required">
                                        <input maxlength="45" class="form-control" placeholder="Bank Postcode"
                                            formControlName="bankPostcode" appOnlyNumber>
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankPostcode'].errors?.['invalid']">Postcode
                                            should only contain numeric values.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankPostcode'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankPostcode'].hasError('maxlength')">Postcode
                                            should not be greater then 10 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Country <em style="color:red;">*</em></label>
                                <ng-select [clearable]="false"
                                    [ngClass]="{'newTooltip expand has-error ngselect-error': (addBeneficiaryForm.controls['bankCountryID'].touched) && addBeneficiaryForm.controls['bankCountryID'].invalid } "
                                    datatitle="Country is Required" [items]="countryList" [closeOnSelect]="true"
                                    [searchable]="true" bindLabel="countryName" bindValue="countryID"
                                    placeholder="Select" formControlName="bankCountryID">
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Bank Account Name <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankAccountName'].touched) && addBeneficiaryForm.controls['bankAccountName'].invalid } "
                                        datatitle="Bank Account Name is Required">
                                        <input maxlength="45" class="form-control" placeholder="Cardholder Name"
                                            formControlName="bankAccountName">
                                        <br *ngIf="addBeneficiaryForm.controls['bankAccountName'].errors?.['pattern']">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Account Number <em style="color:red;">*</em></label>
                                <div class="checkbox-currency">
                                    <div class="custom-radio secondary"
                                        [ngClass]="{'newTooltip expand has-error': (addBeneficiaryForm.controls['bankAccountNo'].touched) && addBeneficiaryForm.controls['bankAccountNo'].invalid } "
                                        datatitle="Account number is Required">
                                        <input maxlength="45" class="form-control" placeholder="--xxxxx--"
                                            formControlName="bankAccountNo" appOnlyNumber>
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankAccountNo'].errors?.['invalid']">Account
                                            Number should only contains Numeric values.</small>
                                        <br *ngIf="addBeneficiaryForm.controls['bankAccountNo'].errors?.['pattern']">
                                        <small class="custom-errormessage"
                                            *ngIf="addBeneficiaryForm.controls['bankAccountNo'].hasError('maxlength')">Account
                                            Number should not be greater then 40 character.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                    </div>

                    <div class="inline-element">
                        <button *ngIf="editMode" id="btnAddBeneficiaryreturn" (click)="closeModal()" type="button"
                            class="btn btn-secondary" value="Client">Return</button>
                        <button id="btnAddBeneficiarysubmit" type="submit" class="btn btn-success"
                            value="Client">{{editMode?'Save Changes':'Submit'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>