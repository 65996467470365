<div>
    <h1 class="reportsHome">Reports Home</h1>
</div>
<div class="card h-450">
    <div class="container" style="margin-top: 20px;">
        <div class="row mt-2 ml-250">
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isPosition_Summary_Report)}" class="mr-2 btn btn-success">Position Summary Report </button> -->
            </div>
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isDelta_Ladder)}" class="btn btn-success">Delta Ladder</button> -->
            </div>
        </div>
        <div class="row mt-2 ml-250">
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isFactsheets)}" class="mr-2 btn btn-success">Factsheets </button> -->
            </div>
            <div class="col-md-5">
                <button (click)="goToConfirmationReportPage()"  class="btn btn-success">Confirmation Reports</button>
            </div>
        </div>
        <div class="row mt-2 ml-250">
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isSales_Revenue_Dash)}"  class="mr-2 btn btn-success">Sales Revenue Dash </button> -->
            </div>
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isSales_NGP_Org_Dashboard)}" class="btn btn-success">Sales NGP Org Dashboard</button> -->
            </div>
        </div>
        <div class="row mt-2 ml-250">
            <div class="col-md-5">
                <!-- <button [ngClass]="{'disabledbtncss': (!isSettlements_Report)}" class="mr-2 btn btn-success">Settlements Report </button> -->
            </div>
            <div class="col-md-5">
                <!-- <button class="btn btn-success">Sales Revenue By Category</button> -->
            </div>
        </div>
    </div>
</div>