import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddBeneficiaryService {
  beneficiaryId:number=-1;
  apiUrl=environment.apiUrl;
  constructor(private http:HttpClient) { }

  getBeneficiaryList():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Admin/Add/GetLists';
    return this.http.get(url);
  }
  getEditBeneficiaryList():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Admin/Edit/GetLists';
    return this.http.get(url);
  }
  getBeneficiaryDetail(id:number):Observable<any>{
    let url=this.apiUrl+'Beneficiary/GetBeneficiary/'+id;
    return this.http.get(url);
  }
  
  
  saveBeneficiary(payload: any, APIURL: string, editMode: boolean):Observable<any>{
    let url = this.apiUrl + APIURL;
    if (editMode) {
      return this.http.patch(url, payload);
    }
    else {
      return this.http.post(url,payload );
    }
  }

  private initSubject = new BehaviorSubject<boolean>(false);
  init$ = this.initSubject.asObservable();
  triggerInit(id :number) {
    if(id){
    this.beneficiaryId=id;
    this.initSubject.next(true);}
    else{
      this.initSubject.next(false)
    }
  }
  getClientList(tenantCarPartner :string):Observable<any>{
    let url=this.apiUrl+'Common/ClientList?tenantCar='+tenantCarPartner+'&includeInactive=false';
    return this.http.get(url);
  }
  //Clients
  getClientBeneficiaryList():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Client/Add/GetLists';
    return this.http.get(url);
  }
  getClientEditBeneficiaryList():Observable<any>{
    let url=this.apiUrl+'Beneficiary/Client/Edit/GetLists';
    return this.http.get(url);
  }


}
