
<div class="loader-block" *ngIf="!dataLoaded">
    <div class="inner-loader">{{loadingMessage}}</div>
  </div>
<div class="compress-inputs-page">
    <div class="inner">
        <div class="title">
            <h3>&nbsp;</h3>
            <a (click)="back()" class="btn btn-outline-secondary" id="btnBackManageClientRole" >
                <em class="arrow-back"><img src="../../../../assets/left-arrow.svg" alt=""></em>
              
                Back
            </a>
        </div>        
            <div class="card">
                <form [formGroup]="rolePermissionForm" (ngSubmit)="save()">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline"   [ngClass]="{'newTooltip expand has-error': (rolePermissionForm.controls['roleName'].touched) && rolePermissionForm.controls['roleName'].invalid } "
                        datatitle="Role Name is Required">
                            <label>Role name <em style="color:red;">*</em></label>
                            <input type="text" formControlName="roleName"class="form-control" placeholder="Role Name" maxlength="100">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label>Description</label>
                            <textarea id="txtDescription" formControlName="description" name="description" maxlength="500" class="form-control"
                                placeholder="Description"></textarea>
              
                        </div>  
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group label-inline">
                            <label>Is Active </label>
                            <div class="client custom-switch">
                                <input type="checkbox" 
                                [(ngModel)]="isActive" formControlName="isActive" class="switch-control-input" />
                                <span class="switch-control-label"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row"><br /></div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table" id="tableClientRoleDetails">
                            <colgroup>
                                <col style="width:35%" />
                                <col style="width:35%" />
                                <col style="width:10%" />
                                <col style="width:10%" />
                                <col style="width:10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Module</th>
                                    <th>Pages</th>
                                    <th class="text-center">
                                        <div class="custom-checkbox">
                                            <input class="checkbox-input" formControlName="chkViewAll" id="chkViewAll" (change)="checkAllViews($event)" type="checkbox" />
                                    <span class="checkbox-label">View</span>
                                        </div>
                                    </th>
                                    <th class="text-center">
                                        <div class="custom-checkbox">
                                            <input class="checkbox-input chkAddAll" formControlName="chkAddAll" id="chkAddAll" (change)="checkAllAdd($event)" type="checkbox" />
                                    <span class="checkbox-label">Add</span>
                                        </div>
                                    </th>
                                    <th class="text-center">
                                        <div class="custom-checkbox">
                                            <input class="checkbox-input chkEditAll" formControlName="chkEditAll" (change)="checkAllEdit($event)" id="chkEditAll" type="checkbox" />
                                            <span class="checkbox-label">Edit</span>
                                        </div>
                                    </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let module of responsedata; let i = index">                                    
                                    <ng-container *ngIf="i === 0 || module.clientModuleName !== responsedata[i - 1].clientModuleName">
                                        <tr>
                                            <td [attr.rowspan]="getLength(module.clientModuleName)">{{ module.clientModuleName }}</td>
                                            <td>{{ module.pageName }}</td>
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input class="checkbox-sub-input chkView" (change)="changeModuleAddEdit(i)" formControlName="chkView_{{i}}"  id="chkView_{{i}}"
                                                        type="checkbox" />
                                                    <label for="chkView_{{i}}" class="checkbox-label"></label>
                                                </div>
                                            </td>
    
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input id="chkAdd_{{i}}" formControlName="chkAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                                        type="checkbox" (change)="changeView(i)"/>
                                                    <label for="chkAdd_{{i}}" class="checkbox-label"></label>
                                                </div>
                                            </td>
    
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input id="chkEdit_{{i}}" (change)="changeView(i)" formControlName="chkEdit_{{i}}" class="checkbox-sub-input chkEdit"
                                                        type="checkbox" />
                                                    <label for="chkEdit_{{i}}" class="checkbox-label"></label>
                                                </div>
                                            </td>
                                        </tr>
                                  

                                    </ng-container>
                                    <ng-container *ngIf="i !== 0 && module.clientModuleName === responsedata[i - 1].clientModuleName">
                                        <tr>
                                            <td>{{ module.pageName }}</td>
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input class="checkbox-sub-input chkView" (change)="changeModuleAddEdit(i)" formControlName="chkView_{{i}}" id="chkView_{{i}}"
                                                        type="checkbox" />
                                                    <label for="chkView_{{i}}" class="checkbox-label"></label>    
                                                </div>
                                            </td>
    
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input id="chkAdd_{{i}}" (change)="changeView(i)"  formControlName="chkAdd_{{i}}" class="checkbox-sub-input chkAdd"
                                                        type="checkbox" />
                                                    <label for="chkAdd_{{i}}" class="checkbox-label"></label>
                                                </div>
                                            </td>
    
                                            <td class="text-center" width="10%">
                                                <div class="custom-checkbox">
                                                    <input id="chkEdit_{{i}}" (change)="changeView(i)" formControlName="chkEdit_{{i}}"  class="checkbox-sub-input chkEdit"
                                                        type="checkbox" />
                                                    <label for="chkEdit_{{i}}" class="checkbox-label"></label>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                           
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12">
                        <div class="inline-element">
                            <button id="aSaveRoleDetails" class="btn btn-success" type="submit">Save </button>
                            <button id="aCancelRoleDetails" class="btn btn-secondary" (click)="cancel()" type="button">Cancel </button>
                        </div>
                    </div>
                 
                </div>
            </form>
            </div>
          
    </div>
</div>