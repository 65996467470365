import { Component, EventEmitter, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ManageClientService } from '../../services/MangeClientservices/manage-client.service';
import { HttpResponse } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { HeaderComponent } from '../Home/header/header.component';
import { EncryptRolePermissionService } from '../../services/rolePermissions/role-permission.service';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-manageclients',
  templateUrl: './manageclients.component.html',
  styleUrl: './manageclients.component.css',
})
export class ManageclientsComponent implements OnInit {
  dataLoaded: boolean = false;
  searchString: string = '';
  activeClient: boolean = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  TenanCarPartner: string = '';
  isActive: string = 'Active';
  tenantCARs: any[] = [];
  selectedTenantCAR: any;
  clientTypes: any[] = [];
  selectedClientType: any;
  selectedStatus: string = 'All';
  tenantID: string = '';
  sortedColumn: string = '';
  isAscending: boolean = true;
  pageSizeOptions = [5, 10, 20];
  pageSize = 20;
  pageIndex = 0;
  length = 0;
  clients: any[] = [];
  paginatedData: any[] = [];
  pageEvent: PageEvent | undefined;
  @ViewChild(HeaderComponent, { static: false })
  headerComponent!: HeaderComponent;
  isNavBarOpen: boolean = true;
  hideAddButton = true;
  disableActiveSlider = false;
  clientSearchForm: FormGroup = this.fb.group({
    selectedtenantCARPartnerName: [null],
    selectedClientType: [null],
    searchString: [''],
    selectedstatus: ['All'],
  });
  constructor(
    private fb: FormBuilder,
    private clientService: ManageClientService,
    private router: Router,
    private encryptPermission: EncryptRolePermissionService,
    private renderer: Renderer2
  ) {
    if (encryptPermission.getDecryptedPermissions('roleNgp')) {
      const pagePermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .modulePermissions.find(
          (permission: { pageID: number }) => permission.pageID === 3
        );
      if (pagePermission && pagePermission.canInsert === 0) {
        this.hideAddButton = true;
      } else {
        this.hideAddButton = false;
      }
      const fieldPermission = encryptPermission
        .getDecryptedPermissions('roleNgp')
        .fieldPermissions.find(
          (permission: { fieldID: number }) => permission.fieldID === 9
        );
      if (fieldPermission && fieldPermission.canInsert === 0) {
        this.disableActiveSlider = true;
      } else {
        this.disableActiveSlider = false;
      }
    }
  }

  ngOnInit(): void {
    this.getDropdownList();
    this.search();
    this.length = this.clients.length;
    this.paginatedData = this.clients.slice(0, this.pageSize);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }

  search() {
    this.dataLoaded = false;
    this.clientService.getClientList(this.clientSearchForm.value).subscribe({
      next: (data: any) => {        
        this.clients = data;
        this.dataLoaded = true;
        this.length = this.clients.length;
        this.paginatedData = this.clients.slice(0, this.pageSize);
      },
      error: () => {
        this.dataLoaded = true;
      },
    });
  }

  getDropdownList() {
    this.clientService.getDropDownLists().subscribe({
      next: (data: any) => {
        this.clientTypes = data[0].clientTypes;
        this.tenantCARs = data[0].tenantCARs;
      },
      error: (error) => {
      },
    });
  }

  sort(columnName: string): void {
    this.clients.sort((a, b) => {
      if (a[columnName] > b[columnName]) return 1;
      if (a[columnName] < b[columnName]) return -1;
      return 0;
    });
  }
  onRadioChange(status: string) {
    this.selectedStatus = status;
  }
  toggleSwitch(client: any) {
    if (!this.disableActiveSlider) {
      this.dataLoaded = false;

      client.isActive = !client.isActive;
      this.clientService
        .changeStaus(client.clientID, client.isActive)
        .subscribe({
          next: (response: HttpResponse<any>) => {
            this.dataLoaded = true;
          },
          error: (error) => {
            this.dataLoaded = false;
          },
        });
    }
  }
  getClientTypeName(typeName: string): string {
    const clientType = this.clientTypes.find(
      (type) => type.typeName === typeName
    );
    return clientType ? clientType.typeName : 'Unknown';
  }
  sortTable(columnName: string) {
    if (this.sortedColumn === columnName) {
      this.isAscending = !this.isAscending;
    } else {
      this.sortedColumn = columnName;
      this.isAscending = true;
    }
    this.clients.sort((a, b) => {
      const valueA = a[columnName];
      const valueB = b[columnName];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return this.isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return this.isAscending ? valueA - valueB : valueB - valueA;
      }
    });
    let startIndex = 0;
    let endIndex = this.pageSize;
    if (this.pageIndex != 0) {
      startIndex = this.pageIndex * this.pageSize;
      endIndex = startIndex + this.pageSize;
    }
    this.paginatedData = this.clients.slice(startIndex, endIndex);
  }
  reset() {
    this.clientSearchForm.reset();
    this.search();
  }
  addClient() {
    this.router.navigate(['/Admin/getclienttab']);
  }
  editClient(clientID: number) {
    this.router.navigate([
      '/Admin/getclienttab',
      clientID,
      { outlets: { tabnavigation: ['clientgeneral', clientID] } },
    ]);
  }

  closeDropdwon = (event: any): void => {
    if (event.key === 'Enter') { 
      this.openCloseDropdwon("0");
    } 
    else {
      this.openCloseDropdwon("1");
    }
  }

  private openCloseDropdwon = (opacity: string) => {
    const dropdowns = document.querySelectorAll('.ng-dropdown-panel');
      if (dropdowns && dropdowns[0] && dropdowns[0].classList) {
        this.renderer.setStyle(dropdowns[0], 'opacity', opacity);
      }
  }
}
