import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SummaryDealDeatilsService {

  constructor(private http:HttpClient) { }

  apiUrl=environment.apiUrl;
  
  dealHistoryGetTradeConfo(tradeID: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'DealHistory/GetTradeConfo?tradeID=' + tradeID, { headers: headers, responseType: 'blob' });
  }
  

  getpaymentInfo(payload :any):Observable<any>{
    let url=this.apiUrl+'Quote/Payment';
    return this.http.post(url,payload);
  }
  sendEmail(payload :any):Observable<any>{
    let url=this.apiUrl+'Common/EmailTradeConfo';
    return this.http.post(url,payload);
  }
  downloadPdf(tradeID: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.apiUrl + 'DealHistory/GetTradeConfo?tradeID=' + tradeID, { headers: headers, responseType: 'blob' });

  }
}
