import { Injectable, Inject } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { SigninService } from '../signin/signin.service';
import Swal from 'sweetalert2';
import { DOCUMENT } from '@angular/common';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { ElementSchemaRegistry } from '@angular/compiler';
import { LoginService } from '../../client/services/login/login.service';
import { SystemConstant } from '../../global/global.constant';

@Injectable({
  providedIn: 'root',
})
export class CheckIdleService {
  logOutChoice = false;
  isAdmin: boolean = false;
  constructor(
    private idle: Idle,
    private signInService: SigninService,
    private clientLoginServices: LoginService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const rootRoute = this.router.routerState.snapshot.root;
        this.isAdmin = this.checkIfAdminRoute(rootRoute);
      }
    });
  }

  setLoginState(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.initializeIdleTimer();
    } else {
      this.stopIdleTimer();
    }
  }

  private initializeIdleTimer(): void {
    const sessionTimeout: number = +this.document.defaultView?.localStorage?.getItem('sessionTimeout')!;
    const idleTimer = sessionTimeout * 60;
    const timeOut = 60;
    this.idle.setIdle(idleTimer); // 15 minutes
    this.idle.setTimeout(timeOut); // Countdown warning time in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe({
      next: () => this.showWarning(),
    });

    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });

    this.idle.watch();
  }

  private stopIdleTimer(): void {
    this.idle.stop();
    this.idle.clearInterrupts();
  }

  private showWarning(): void {
    let timerInterval: any;
    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      html: `You will be logged out in <b></b> seconds due to inactivity.`,
      showConfirmButton: true,
      confirmButtonText: 'Stay Logged In',
      showDenyButton: true,
      denyButtonText: 'Logout',
      denyButtonColor: 'grey',
      timer: 60 * 1000,
      didOpen: () => {
        const timer = Swal.getPopup()?.querySelector('b');
        timerInterval = setInterval(() => {
          timer!.textContent = `${(Swal.getTimerLeft()! / 1000).toFixed(0)}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.isAdmin) {
          this.signInService.refreshToken();
        } else {
          this.clientLoginServices.refreshToken();
        }
        this.reset();
      } else if (result.isDenied) {
        this.logOutChoice = true;

        this.logout();
      }
    });
  }

  private logout(): void {
    Swal.fire({
      icon: 'info',
      title: 'Logout',
      text: this.logOutChoice
        ? 'You Have Been Logged Out Successfully'
        : 'We have logged you out due to inactivity to protect your privacy.',
      showConfirmButton: true,
    });
    if (this.isAdmin) this.signInService.logout();
    else this.clientLoginServices.logout();
    this.stopIdleTimer();
  }

  reset() {
    this.idle.watch();
  }
  private checkIfAdminRoute(route: ActivatedRouteSnapshot): boolean {
    while (route.firstChild) {
      route = route.firstChild;
      const routeConfig = route.routeConfig;
      if (routeConfig && routeConfig.data && routeConfig.data['isAdmin'] 
         || (routeConfig && routeConfig.path === SystemConstant.Admin)) {
        return true;
      }
      
    }
    return false;
  }
}
